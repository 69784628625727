
import React, {  useEffect, useState } from 'react'
import {  Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {TRFFiyatList,DirekFiyatList,TraversFiyatList,HavaiIletkenFiyatList,XLPEFiyatList,SeksiyonerFiyatList,IzalatorFiyatList,IzalatorDemirFiyatList,NYYFiyatList,TopraklamaIletken,ParafudrFiyatList,AGPanoFiyatList,TehlikeFiyatList} from '../Data/FiyatList'
import {Iletken} from '../Data/Malzeme'
import { UpdateSecim } from '../reducers/cizimOzellik'
import { Update_Kesif } from '../reducers/GenelBilgilerSlice'


let X=0;
let Y=0;
let  denemeCizim=[]
let kesifDurum=false


export default function Kesif(props) {
    const genelBilgiler = useSelector((state) => state.genelBilgiler)

    const [showSayacBolme, setShowSayacBolme] = useState(false);
    const [bgcolor, setBgcolor] = useState("white");
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const KompDetaylar = useSelector((state) => state.KompDetay)
  
   
  
    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
     

    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
    const Trafo= useSelector((state) => state.trafoDetay)
    const direk= useSelector((state) => state.direkDetay)
    const pano= useSelector((state) => state.PanoDetay)
    const OGHat= useSelector((state) => state.OGHatDetay)
    const CetvelOzellik = useSelector((state) => state.RapCetCiz)

    let textRenk=CetvelOzellik.K_TextRenk
    let cizimRenk=CetvelOzellik.K_Renk

    let kesifdetay = []
    let Kesif=[]
    let kesifH=0

 

    function artirW(val){

        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:880*Scl,  
                h:kesifH,                          
            },
            cizim: KesifDraw(0,0,Scl,{
                textRenk:textRenk,
                cizimRenk:cizimRenk
            })
        }));  
        KesifHazirla() 
     }
     const [toolshow, setToolShow] = useState(false);
     function Show() { setToolShow(!toolshow) }
     const dispatch = useDispatch()
 
    useEffect(() => {       
        artirW(400)
        
    }, [cizimOzellik ,CetvelOzellik,direk,Trafo,OGHat]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
    let inisiletkenNo=0;
    let trfGuc=50
   
    function KesifDraw(X,Y,Scl,veri){

        if(kesifDurum===false){
            Kesif=[]
    let resume=[]
    let satirH=0*Scl
    let sw=30;
    
    let satirW=sw 
    Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
    [
        X + Scl * (5), Y +  (satirH), 
        X + Scl * (880), Y + (satirH), 
        X + Scl * (880), Y + (satirH+40*Scl), 
        X + Scl * (5), Y + (satirH+40*Scl)
    ] })
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl*(0.00), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"KEŞİF", TextStyleName: "  1", TextArc: 0,width:880*Scl }) 
    satirH+=40*Scl;

    Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
    [
        X + Scl * (5), Y +  (satirH), 
        X + Scl * (880), Y + (satirH), 
        X + Scl * (880), Y + (satirH+50*Scl), 
        X + Scl * (5), Y + (satirH+50*Scl)
    ] })
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl*(0.00), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"No", TextStyleName: "  1", TextArc: 0,width:sw*Scl }) 
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Yapılan İş", TextStyleName: "  1", TextArc: 0,width:430*Scl }) 
    sw=430
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Miktar", TextStyleName: "  1", TextArc: 0,width:50*Scl }) 
    sw=480
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Malzeme Fiyat", TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
    sw=560
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Mal. Top. Fiyat", TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
    sw=640
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Montaj Fiyat", TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
    sw=720
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:"Mont.Top. Fiyat", TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
    sw=800
    satirW=sw
    //------------------------------------------------
    Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X+Scl*(sw), y: Y+ satirH+5, TextH: Scl * 15,align:"center", TextString:"Toplam Fiyat", TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
    satirH+=50*Scl;

    kesifH=kesifdetay.length*30 +90
        kesifdetay.map((kesif,k)=>{
            Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (5), Y +  (satirH), 
                X + Scl * (880), Y + (satirH), 
                X + Scl * (880), Y + (satirH+30*Scl), 
                X + Scl * (5), Y + (satirH+30*Scl)
            ] })
            
            sw=30
            satirW=sw
             Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(satirW), y1:Y+Scl*(40), x2:X+Scl*(satirW), y2:Y+Scl*(kesifH)}) 
         
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl*(0.00), y: Y + satirH+5, TextH: Scl * 15,align:"center", TextString:(k+1), TextStyleName: "  1", TextArc: 0,width:sw*Scl }) 
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.malzeme, TextStyleName: "  1", TextArc: 0,width:430*Scl }) 
            sw=430
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH)})             

            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.miktar, TextStyleName: "  1", TextArc: 0,width:50*Scl }) 
            sw=480
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH)})             
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.malF, TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
            sw=560
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH)})             
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.malTopF, TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
            sw=640
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH)})             
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.MontF, TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
            sw=720
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH+90)})             
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.monTopF, TextStyleName: "  1", TextArc: 0,width:80*Scl }) 
            sw=800
            satirW=sw
            Kesif.push({Ad:"Line", layer:"0", Color:veri.cizimRenk, Color24:"", LineScale:1, LineType:"ByLayer", Kalinlik:40, x1:X+Scl*(sw), y1:Y+Scl*(40), x2:X+Scl*(sw), y2:Y+Scl*(kesifH)})             
            //------------------------------------------------
            Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (sw+5), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:kesif.ToplamFiyat, TextStyleName: "  1", TextArc: 0,width:100*Scl }) 
         
            //---------------------------------------------------



            satirH+=30*Scl;

        
        })

        Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (560), Y +  (satirH), 
                X + Scl * (880), Y + (satirH), 
                X + Scl * (880), Y + (satirH+30*Scl), 
                X + Scl * (560), Y + (satirH+30*Scl)
            ] })
        Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (560), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:"Toplam                            "+kesifdetay[kesifdetay.length-1].ToplamFiyat+"TL", TextStyleName: "  1", TextArc: 0,width:320*Scl }) 
        satirH+=30*Scl;
        Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
        [
            X + Scl * (560), Y +  (satirH), 
            X + Scl * (880), Y + (satirH), 
            X + Scl * (880), Y + (satirH+30*Scl), 
            X + Scl * (560), Y + (satirH+30*Scl)
        ] })
        Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (560), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:"KDV (%18)                      "+(kesifdetay[kesifdetay.length-1].ToplamFiyat*0.18).toFixed(2)+"TL", TextStyleName: "  1", TextArc: 0,width:320*Scl }) 
        satirH+=30*Scl;
        Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
        [
            X + Scl * (560), Y +  (satirH), 
            X + Scl * (880), Y + (satirH), 
            X + Scl * (880), Y + (satirH+30*Scl), 
            X + Scl * (560), Y + (satirH+30*Scl)
        ] })
        Kesif.push({ Ad: "Polyline", layer: "0", Color: veri.cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
        [
            X + Scl * (5), Y +  (satirH-60*Scl), 
            X + Scl * (880), Y + (satirH-60*Scl), 
            X + Scl * (880), Y + (satirH+30*Scl), 
            X + Scl * (5), Y + (satirH+30*Scl)
        ] })
        Kesif.push({ Ad: "Text", layer: "0", Color: veri.textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (560), y: Y + satirH+5, TextH: Scl * 15,align:"justify", TextString:"Genel Toplam                  "+(kesifdetay[kesifdetay.length-1].ToplamFiyat*1.18).toFixed(2)+"TL", TextStyleName: "  1", TextArc: 0,width:320*Scl }) 
        satirH+=30*Scl;
        kesifH=satirH
        return Kesif
    }
    kesifDurum=true
    }
    function KesifHazirla() {
        kesifdetay = []
    let InisIletkenleriCU=0
    let InisIletkenleriAL=0
        inisiletkenNo = 5//document.getElementById("P_TRF_AG_Iletken1").selectedIndex
        var AgPanoNo;
        switch (trfGuc) {
            case 50: AgPanoNo = 0; break;
            case 100: AgPanoNo = 1; break;
            case 160: AgPanoNo = 2; break;
            case 200: AgPanoNo = 3; break;
            case 250: AgPanoNo = 3; break;
            case 315: AgPanoNo = 4; break;
            case 400: AgPanoNo = 4; break;
            default:
        }
        //var Kesif=[];
        var KesifSatir1 = { malzeme: "", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 }; 	//Trafo
        var KesifSatir2 = { malzeme: "", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//direk
        var KesifSatir3 = { malzeme: "", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Travers
        var KesifSatir4 = { malzeme: "", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//OG iletken
        var KesifSatir5 = { malzeme: "", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Seksiyoner
        var KesifSatir6 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//mesnet İzalator
        var KesifSatir7 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//İzalatör demiri 
        var KesifSatir8 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//gergi İzalatör demiri 
        var KesifSatir9 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//NYY demiri 
        var KesifSatir10 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Seksiyoner Topraklama iletkeni
        var KesifSatir11 = { malzeme: "", miktar: 20, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Parafur Topraklama iletkeni
        var KesifSatir12 = { malzeme: "", miktar: 20, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Trafo Koruma Topraklama iletkeni
        var KesifSatir13 = { malzeme: "", miktar: 20, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//işletme topraklama iletkeni 
        var KesifSatir14 = { malzeme: "", miktar: 3, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Parafudr
        var KesifSatir15 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//Harici Tip AG Panosu
        var KesifSatir16 = { malzeme: "", miktar: 6, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 0 };	//ÖTL
        var KesifSatir17 = { malzeme: "PROJE BEDELİ", miktar: 1, brm: "adet", malF: 9708.0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 9000 };	//ÖTL
        var KesifSatir18 = { malzeme: "TOPLAM", miktar: 0, brm: "adet", malF: 0, MontF: 0, malTopF: 0, monTopF: 0, ToplamFiyat: 9000 };	// TOPLAM SATIRI
    
        var OgIletNo, SeksiyonerNo, SeksiyonerInisNo, ParafudrNo, ParafudrInisNo, OTLNo;
    
        OgIletNo = 0//document.getElementById("P_DirekHatIletken1").selectedIndex;
        SeksiyonerNo =0// document.getElementById("P_Seksiyoner").selectedIndex;
        ParafudrNo = 0//document.getElementById("P_Parafudr").selectedIndex;
    let  SeksiyonerIletkenNo=0
    
        switch (SeksiyonerIletkenNo) {
            case 0:
                SeksiyonerInisNo = 3;
                break;
            case 1:
                SeksiyonerInisNo = 9;
                break;
                default:
        }
        let ParafudrInisIletken1No=0
    
        switch (ParafudrInisIletken1No) {
            case 0:
                ParafudrInisNo = 3;
                break;
            case 1:
                ParafudrNo = 9;
                break;
                default:
        }
    
    
        var TRFmontajFiyat, DirekmontajFiyat, TrvrsmontajFiyat, OGIletkenMontajFiyat, MesIzMontajFiyat, GerIzMontajFiyat, IzalatorDemirMonFiyat, SeksiyonerMontajFiyat, SeksiyonerInisMontajFiyat, ParafudrMontajFiyat, ParafudrrInisMontajFiyat, AGPanoMontajFiyat, OTLMontajFiyat;
        var AGIletkenMontajFiyat, TRFKorTopMontajIletFiyat, IsletmeTopMontajIletFiyat, OTLMontajFiyat;
        var MalzemeToplamFiyat = 0
        var MontajToplamFiyat = 0
        var MalzemeMontajToplamFiyat = 0
        var KDV = 0
        var GenelToplam = 0
        var bolge = "1.bolge"
        let traversKesifNo=1
        let DirekKesifNo=0;
        let OGHatNo=0

if(direk.tur==="demir"){
    switch(direk.direk){
        case "T15":      DirekKesifNo=0;     break;
        case "T25":      DirekKesifNo=1;     break;
        case "T35":      DirekKesifNo=2;     break;
        case "T50":      DirekKesifNo=3;     break;
        case "TK15":      DirekKesifNo=4;     break;
        case "TK25":      DirekKesifNo=5;     break;
        case "TK35":      DirekKesifNo=6;     break;
        case "TK50":      DirekKesifNo=7;     break;
        
        default:
    }
}
if(OGHat.OGhatTur==="havai")
switch(OGHat.OGhat){
    case "3 AWG Swallow":      OGHatNo=1;     break;
    case "1/0 AWG Raven":      OGHatNo=4;     break;
    case "3/0 AWG Pigeon":      OGHatNo=5;     break;   
    default:
}

        OTLNo = 0;
        //TRF KESİF SATIR
         switch (bolge) {
            case "1.bolge":
                TRFmontajFiyat = TRFFiyatList[Trafo.KesifNo].Montaj1
                DirekmontajFiyat = DirekFiyatList[DirekKesifNo].Montaj1
                TrvrsmontajFiyat = TraversFiyatList[traversKesifNo].Montaj1
                if ( direk.HatTip == "Havai") {
                    OGIletkenMontajFiyat = HavaiIletkenFiyatList[OgIletNo].Montaj1
                } else {
                    OGIletkenMontajFiyat = XLPEFiyatList[OgIletNo].Montaj1
                }
                MesIzMontajFiyat = IzalatorFiyatList[0].Montaj1
                GerIzMontajFiyat = IzalatorFiyatList[1].Montaj1
                IzalatorDemirMonFiyat = IzalatorDemirFiyatList[0].Montaj1
                 

                if ( direk.seksiyoner!== "yok") {
                    SeksiyonerMontajFiyat = SeksiyonerFiyatList[SeksiyonerNo].Montaj1
                    SeksiyonerInisMontajFiyat = TopraklamaIletken[SeksiyonerInisNo].Montaj1
                }
                AGIletkenMontajFiyat = NYYFiyatList[inisiletkenNo].Montaj1
                if ( direk.parafudr!== "yok") {
                    ParafudrMontajFiyat = ParafudrFiyatList[ParafudrNo].Montaj1
                    ParafudrrInisMontajFiyat = TopraklamaIletken[ParafudrInisNo].Montaj1
                }
    
                AGPanoMontajFiyat = AGPanoFiyatList[AgPanoNo].Montaj1
                OTLMontajFiyat = AGPanoFiyatList[OTLNo].Montaj1
                TRFKorTopMontajIletFiyat = TopraklamaIletken[3].Montaj1
                IsletmeTopMontajIletFiyat = TopraklamaIletken[3].Montaj1
                OTLMontajFiyat = TehlikeFiyatList[0].Montaj1
    
    
                break;
            case "2.bolge":
                TRFmontajFiyat = TRFFiyatList[ Trafo.KesifNo].Montaj2
                DirekmontajFiyat = DirekFiyatList[ direk.KesifNo].Montaj2
                TrvrsmontajFiyat = TraversFiyatList[ Trafo.KesifNo].Montaj2
                if ( direk.HatTip == "Havai") {
                    OGIletkenMontajFiyat = HavaiIletkenFiyatList[OgIletNo].Montaj2
                } else {
                    OGIletkenMontajFiyat = XLPEFiyatList[OgIletNo].Montaj2
                }
                break;
            case "3.bolge":
                TRFmontajFiyat = TRFFiyatList[ Trafo.KesifNo].Montaj3
                DirekmontajFiyat = DirekFiyatList[ direk.KesifNo].Montaj3
                TrvrsmontajFiyat = TraversFiyatList[ Trafo.KesifNo].Montaj3
                if ( direk.HatTip == "Havai") {
                    OGIletkenMontajFiyat = HavaiIletkenFiyatList[OgIletNo].Montaj3
                } else {
                    OGIletkenMontajFiyat = XLPEFiyatList[OgIletNo].Montaj3
                }
                break;
                default:
        }
    
    
        KesifSatir1.malzeme = TRFFiyatList[Trafo.KesifNo].malzeme;
        KesifSatir1.miktar = 1;
        KesifSatir1.brm = "adet";
        KesifSatir1.malF = TRFFiyatList[Trafo.KesifNo].malzemeTL
        KesifSatir1.MontF = TRFmontajFiyat;
        KesifSatir1.malTopF = TRFFiyatList[Trafo.KesifNo].malzemeTL;
        KesifSatir1.monTopF = TRFmontajFiyat;
        KesifSatir1.ToplamFiyat = (TRFmontajFiyat + TRFFiyatList[Trafo.KesifNo].malzemeTL).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir1.malTopF * 1;
        MontajToplamFiyat += KesifSatir1.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir1.ToplamFiyat * 1;
        kesifdetay.push(KesifSatir1)	//TRF KESİF SATIR EKLENDİ
    
        //DİREK KESİF SATIR
        KesifSatir2.malzeme = DirekFiyatList[DirekKesifNo].malzeme;
        KesifSatir2.miktar = 1;
        KesifSatir2.brm = "adet";
        KesifSatir2.malF = DirekFiyatList[DirekKesifNo].malzemeTL
        KesifSatir2.MontF = DirekmontajFiyat;
        KesifSatir2.malTopF = DirekFiyatList[DirekKesifNo].malzemeTL;
        KesifSatir2.monTopF = DirekmontajFiyat;
        KesifSatir2.ToplamFiyat = (DirekmontajFiyat + DirekFiyatList[DirekKesifNo].malzemeTL).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir2.malTopF * 1;
        MontajToplamFiyat += KesifSatir2.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir2.ToplamFiyat * 1;
        kesifdetay.push(KesifSatir2)	//DİREK KESİF SATIR EKLENDİ
    
        //Travers KESİF SATIR
        KesifSatir3.malzeme = TraversFiyatList[0].malzeme;
        KesifSatir3.miktar = 1;
        KesifSatir3.brm = "adet";
        KesifSatir3.malF = TraversFiyatList[0].malzemeTL
        KesifSatir3.MontF = TrvrsmontajFiyat;
        KesifSatir3.malTopF = TraversFiyatList[0].malzemeTL;
        KesifSatir3.monTopF = TrvrsmontajFiyat;
        KesifSatir3.ToplamFiyat = (TrvrsmontajFiyat + TraversFiyatList[0].malzemeTL).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir3.malTopF * 1;
        MontajToplamFiyat += KesifSatir3.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir3.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir3)	//Travers KESİF SATIR EKLENDİ
        //---------------------------------------------------------------------------------
        //OG ILETKEN  KESİF SATIR
        KesifSatir4.miktar = OGHat.OGhatMetre;
        if (OGHat.OGhatTur == "havai") {
            KesifSatir4.malzeme = HavaiIletkenFiyatList[OGHatNo].malzeme
            KesifSatir4.brm = "kg";
            KesifSatir4.malF = HavaiIletkenFiyatList[OGHatNo].malzemeTL
            KesifSatir4.malTopF = (HavaiIletkenFiyatList[OGHatNo].malzemeTL * KesifSatir4.miktar).toFixed(2);
        } else {
            KesifSatir4.malzeme = XLPEFiyatList[OGHatNo].malzeme
            KesifSatir4.brm = "m";
            KesifSatir4.malF = XLPEFiyatList[OGHatNo].malzemeTL
            KesifSatir4.malTopF = (XLPEFiyatList[OGHatNo].malzemeTL * KesifSatir4.miktar).toFixed(2);
        }
        KesifSatir4.MontF = OGIletkenMontajFiyat;
        KesifSatir4.monTopF = (OGIletkenMontajFiyat * KesifSatir4.miktar).toFixed(2);
        KesifSatir4.ToplamFiyat = (XLPEFiyatList[0].malzemeTL * KesifSatir4.miktar + OGIletkenMontajFiyat * KesifSatir4.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir4.malTopF * 1;
        MontajToplamFiyat += KesifSatir4.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir4.ToplamFiyat * 1;
        kesifdetay.push(KesifSatir4)	//OG ILETKEN  KESİF SATIR EKLENDİ
        //---------------------------------------------------------------------------------
        //Seksiyoner KESİF SATIR
        if (direk.seksiyoner!== "yok") {
            KesifSatir5.miktar = 1;
            KesifSatir5.malzeme = SeksiyonerFiyatList[0].malzeme
            KesifSatir5.brm = "adet";
            KesifSatir5.malF = SeksiyonerFiyatList[0].malzemeTL
            KesifSatir5.malTopF = (SeksiyonerFiyatList[0].malzemeTL * KesifSatir5.miktar).toFixed(2);
            KesifSatir5.MontF = SeksiyonerMontajFiyat;
            KesifSatir5.monTopF = (SeksiyonerMontajFiyat * KesifSatir5.miktar).toFixed(2);
            KesifSatir5.ToplamFiyat = (SeksiyonerFiyatList[0].malzemeTL * KesifSatir5.miktar + SeksiyonerMontajFiyat * KesifSatir5.miktar).toFixed(2);
            //--------------------------------------------------
            MalzemeToplamFiyat += KesifSatir5.malTopF * 1;
            MontajToplamFiyat += KesifSatir5.monTopF * 1;
            MalzemeMontajToplamFiyat += KesifSatir5.ToplamFiyat * 1;
    
            kesifdetay.push(KesifSatir5)	//Seksiyoner  KESİF SATIR EKLENDİ
            //--------------------------------------------------
        }
        if (OGHat.OGhatTur == "havai") {
     
            //İzalatör KESİF SATIR
            KesifSatir6.miktar = 3;		//mesnet İzalator 
            KesifSatir6.malzeme = IzalatorFiyatList[0].malzeme
            KesifSatir6.brm = "adet";
            KesifSatir6.malF = IzalatorFiyatList[0].malzemeTL
            KesifSatir6.malTopF = (IzalatorFiyatList[0].malzemeTL * KesifSatir6.miktar).toFixed(2);
            KesifSatir6.MontF = SeksiyonerMontajFiyat;
            KesifSatir6.monTopF = (SeksiyonerMontajFiyat * KesifSatir6.miktar).toFixed(2);
            KesifSatir6.ToplamFiyat = (IzalatorFiyatList[0].malzemeTL * KesifSatir6.miktar + SeksiyonerMontajFiyat * KesifSatir6.miktar).toFixed(2);
            //--------------------------------------------------
            MalzemeToplamFiyat += KesifSatir6.malTopF * 1;
            MontajToplamFiyat += KesifSatir6.monTopF * 1;
            MalzemeMontajToplamFiyat += KesifSatir6.ToplamFiyat * 1;    
            kesifdetay.push(KesifSatir6)	//gergi İzalatör   KESİF SATIR EKLENDİ

            //gergi İzalatör  KESİF SATIR
            KesifSatir7.miktar = 3;		//gergi İzalator
            KesifSatir7.malzeme = IzalatorFiyatList[1].malzeme
            KesifSatir7.brm = "adet";
            KesifSatir7.malF = IzalatorFiyatList[1].malzemeTL
            KesifSatir7.malTopF = (IzalatorFiyatList[1].malzemeTL * KesifSatir7.miktar).toFixed(2);
            KesifSatir7.MontF = SeksiyonerMontajFiyat;
            KesifSatir7.monTopF = (SeksiyonerMontajFiyat * KesifSatir7.miktar).toFixed(2);
            KesifSatir7.ToplamFiyat = (IzalatorFiyatList[1].malzemeTL * KesifSatir7.miktar + SeksiyonerMontajFiyat * KesifSatir7.miktar).toFixed(2);
            //--------------------------------------------------
            MalzemeToplamFiyat += KesifSatir8.malTopF * 1;
            MontajToplamFiyat += KesifSatir8.monTopF * 1;
            MalzemeMontajToplamFiyat += KesifSatir8.ToplamFiyat * 1;    
            kesifdetay.push(KesifSatir7)	//gergi İzalatör   KESİF SATIR EKLENDİ

            KesifSatir8.miktar = 3;		//gergi İzalator
            KesifSatir8.malzeme = IzalatorFiyatList[1].malzeme
            KesifSatir8.brm = "adet";
            KesifSatir8.malF = IzalatorFiyatList[1].malzemeTL
            KesifSatir8.malTopF = (IzalatorFiyatList[1].malzemeTL * KesifSatir7.miktar).toFixed(2);
            KesifSatir8.MontF = SeksiyonerMontajFiyat;
            KesifSatir8.monTopF = (SeksiyonerMontajFiyat * KesifSatir8.miktar).toFixed(2);
            KesifSatir8.ToplamFiyat = (IzalatorFiyatList[1].malzemeTL * KesifSatir8.miktar + SeksiyonerMontajFiyat * KesifSatir8.miktar).toFixed(2);
            //--------------------------------------------------
            MalzemeToplamFiyat += KesifSatir8.malTopF * 1;
            MontajToplamFiyat += KesifSatir8.monTopF * 1;
            MalzemeMontajToplamFiyat += KesifSatir8.ToplamFiyat * 1;
    
            kesifdetay.push(KesifSatir8)	//gergi İzalatör   KESİF SATIR EKLENDİ
       
    //********************************************************************* */ 
    }else       if (OGHat.OGhatTur == "yeralti") {
     
        //İzalatör KESİF SATIR
        KesifSatir6.miktar = 6;		//mesnet İzalator 
        KesifSatir6.malzeme = IzalatorFiyatList[0].malzeme
        KesifSatir6.brm = "adet";
        KesifSatir6.malF = IzalatorFiyatList[0].malzemeTL
        KesifSatir6.malTopF = (IzalatorFiyatList[0].malzemeTL * KesifSatir6.miktar).toFixed(2);
        KesifSatir6.MontF = SeksiyonerMontajFiyat;
        KesifSatir6.monTopF = (SeksiyonerMontajFiyat * KesifSatir6.miktar).toFixed(2);
        KesifSatir6.ToplamFiyat = (IzalatorFiyatList[0].malzemeTL * KesifSatir6.miktar + SeksiyonerMontajFiyat * KesifSatir6.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir6.malTopF * 1;
        MontajToplamFiyat += KesifSatir6.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir6.ToplamFiyat * 1;    
        kesifdetay.push(KesifSatir6)	//gergi İzalatör   KESİF SATIR EKLENDİ

        //gergi İzalatör  KESİF SATIR
        KesifSatir7.miktar = 6;		//gergi İzalator
        KesifSatir7.malzeme = IzalatorFiyatList[1].malzeme
        KesifSatir7.brm = "adet";
        KesifSatir7.malF = IzalatorFiyatList[1].malzemeTL
        KesifSatir7.malTopF = (IzalatorFiyatList[1].malzemeTL * KesifSatir7.miktar).toFixed(2);
        KesifSatir7.MontF = SeksiyonerMontajFiyat;
        KesifSatir7.monTopF = (SeksiyonerMontajFiyat * KesifSatir7.miktar).toFixed(2);
        KesifSatir7.ToplamFiyat = (IzalatorFiyatList[1].malzemeTL * KesifSatir7.miktar + SeksiyonerMontajFiyat * KesifSatir7.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir8.malTopF * 1;
        MontajToplamFiyat += KesifSatir8.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir8.ToplamFiyat * 1;    
        kesifdetay.push(KesifSatir7)	//gergi İzalatör   KESİF SATIR EKLENDİ      
   
//********************************************************************* */ 
}

        //--------------------------------------------------
        //AG İletken KESİF SATIR
        var InisIletkenleri, iNo, iletAdet, InisIletkenFiyat;
         
        if (Iletken[pano.InisIletken.No].Malzeme=== "Cu") {
            InisIletkenleri = InisIletkenleriCU
        }
        else {
            InisIletkenleri = InisIletkenleriAL
        }
        iNo = IletkenNoBul(inisiletkenNo)[0]
        iletAdet = IletkenNoBul(inisiletkenNo)[1]
        KesifSatir9.malzeme = NYYFiyatList[iNo].malzeme
        KesifSatir9.miktar = 12 * iletAdet;
        KesifSatir9.brm = "m";
        KesifSatir9.malF = NYYFiyatList[iNo].malzemeTL
        KesifSatir9.malTopF = (NYYFiyatList[iNo].malzemeTL * KesifSatir9.miktar).toFixed(2);
        KesifSatir9.MontF = AGIletkenMontajFiyat;
        KesifSatir9.monTopF = (AGIletkenMontajFiyat * KesifSatir9.miktar).toFixed(2);
        KesifSatir9.ToplamFiyat = (NYYFiyatList[iNo].malzemeTL * KesifSatir9.miktar + AGIletkenMontajFiyat * KesifSatir9.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir9.malTopF * 1;
        MontajToplamFiyat += KesifSatir9.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir9.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir9)	//AG İletken KESİF SATIR EKLENDİ
    
        //SEKSİYONER TOPRAKLAMA İLETKENİ  KESİF SATIR
        KesifSatir10.malzeme = TopraklamaIletken[SeksiyonerInisNo].malzeme + "  Seksiyoner iletkeni"
        KesifSatir10.miktar = 20;
        KesifSatir10.brm = "m";
        KesifSatir10.malF = TopraklamaIletken[SeksiyonerInisNo].malzemeTL
        KesifSatir10.malTopF = (TopraklamaIletken[SeksiyonerInisNo].malzemeTL * KesifSatir10.miktar).toFixed(2);
        KesifSatir10.MontF = SeksiyonerInisMontajFiyat;
        KesifSatir10.monTopF = (SeksiyonerInisMontajFiyat * KesifSatir10.miktar).toFixed(2);
        KesifSatir10.ToplamFiyat = (TopraklamaIletken[SeksiyonerInisNo].malzemeTL * KesifSatir10.miktar + SeksiyonerInisMontajFiyat * KesifSatir10.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir10.malTopF * 1;
        MontajToplamFiyat += KesifSatir10.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir10.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir10)	//SEKSİYONER TOPRAKLAMA İLETKENİ KESİF SATIR EKLENDİ
        if (direk.parafudr!== "yok") {
    
        //PARAFUDR TOPRAKLAMA İLETKENİ  KESİF SATIR
        KesifSatir11.malzeme = TopraklamaIletken[ParafudrInisNo].malzeme + "  Parafudr iletkeni"
        KesifSatir11.miktar = 20;
        KesifSatir11.brm = "m";
        KesifSatir11.malF = TopraklamaIletken[ParafudrInisNo].malzemeTL
        KesifSatir11.malTopF = (TopraklamaIletken[ParafudrInisNo].malzemeTL * KesifSatir11.miktar).toFixed(2);
        KesifSatir11.MontF = ParafudrrInisMontajFiyat;
        KesifSatir11.monTopF = (ParafudrrInisMontajFiyat * KesifSatir11.miktar).toFixed(2);
        KesifSatir11.ToplamFiyat = (TopraklamaIletken[ParafudrInisNo].malzemeTL * KesifSatir11.miktar + ParafudrrInisMontajFiyat * KesifSatir11.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir11.malTopF * 1;
        MontajToplamFiyat += KesifSatir11.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir11.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir11)	//PARAFUDR TOPRAKLAMA İLETKENİ KESİF SATIR EKLENDİ
        }
        //TRAFO KORUMA TOPRAKLAMA İLETKENİ  KESİF SATIR
        KesifSatir12.malzeme = TopraklamaIletken[3].malzeme + "  Topraklama İletkeni"
        KesifSatir12.miktar = 20;
        KesifSatir12.brm = "m";
        KesifSatir12.malF = TopraklamaIletken[3].malzemeTL
        KesifSatir12.malTopF = (TopraklamaIletken[3].malzemeTL * KesifSatir12.miktar).toFixed(2);
        KesifSatir12.MontF = TRFKorTopMontajIletFiyat;
        KesifSatir12.monTopF = (TRFKorTopMontajIletFiyat * KesifSatir12.miktar).toFixed(2);
        KesifSatir12.ToplamFiyat = (TopraklamaIletken[3].malzemeTL * KesifSatir12.miktar + TRFKorTopMontajIletFiyat * KesifSatir12.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir12.malTopF * 1;
        MontajToplamFiyat += KesifSatir12.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir12.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir12)	//TRAFO KORUMA  TOPRAKLAMA İLETKENİ KESİF SATIR EKLENDİ
    
        //İŞLETME KORUMA TOPRAKLAMA İLETKENİ  KESİF SATIR
        KesifSatir13.malzeme = TopraklamaIletken[3].malzeme + "  İşletme iletkeni"
        KesifSatir13.miktar = 20;
        KesifSatir13.brm = "m";
        KesifSatir13.malF = TopraklamaIletken[3].malzemeTL
        KesifSatir13.malTopF = (TopraklamaIletken[3].malzemeTL * KesifSatir13.miktar).toFixed(2);
        KesifSatir13.MontF = IsletmeTopMontajIletFiyat;
        KesifSatir13.monTopF = (IsletmeTopMontajIletFiyat * KesifSatir13.miktar).toFixed(2);
        KesifSatir13.ToplamFiyat = (TopraklamaIletken[3].malzemeTL * KesifSatir13.miktar + IsletmeTopMontajIletFiyat * KesifSatir13.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir13.malTopF * 1;
        MontajToplamFiyat += KesifSatir13.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir13.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir13)	//İŞLETME KORUMA TOPRAKLAMA İLETKENİ KESİF SATIR EKLENDİ
        if (direk.parafudr!== "yok") {
    
        //PARAFUDR  KESİF SATIR
        KesifSatir14.malzeme = ParafudrFiyatList[0].malzeme
        KesifSatir14.brm = "adet";
        KesifSatir14.malF = ParafudrFiyatList[0].malzemeTL
        KesifSatir14.malTopF = (ParafudrFiyatList[0].malzemeTL * KesifSatir14.miktar).toFixed(2);
        KesifSatir14.MontF = ParafudrMontajFiyat;
        KesifSatir14.monTopF = (ParafudrMontajFiyat * KesifSatir14.miktar).toFixed(2);
        KesifSatir14.ToplamFiyat = (ParafudrFiyatList[0].malzemeTL * KesifSatir14.miktar + ParafudrMontajFiyat * KesifSatir14.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir14.malTopF * 1;
        MontajToplamFiyat += KesifSatir14.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir14.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir14)	//PARAFUDR KESİF SATIR EKLENDİ
        }
        //AGPANO  KESİF SATIR
        KesifSatir15.malzeme = AGPanoFiyatList[AgPanoNo].malzeme + "  AG Pano"
        KesifSatir15.brm = "adet";
        KesifSatir15.malF = AGPanoFiyatList[AgPanoNo].malzemeTL
        KesifSatir15.malTopF = (AGPanoFiyatList[AgPanoNo].malzemeTL * KesifSatir15.miktar).toFixed(2);
        KesifSatir15.MontF = AGPanoMontajFiyat;
        KesifSatir15.monTopF = (AGPanoMontajFiyat * KesifSatir15.miktar).toFixed(2);
        KesifSatir15.ToplamFiyat = (AGPanoFiyatList[AgPanoNo].malzemeTL * KesifSatir15.miktar + AGPanoMontajFiyat * KesifSatir15.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir15.malTopF * 1;
        MontajToplamFiyat += KesifSatir15.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir15.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir15)	//AGPANO KESİF SATIR EKLENDİ
        //OTL  KESİF SATIR
        KesifSatir16.malzeme = TehlikeFiyatList[0].malzeme
        KesifSatir16.brm = "adet";
        KesifSatir16.malF = TehlikeFiyatList[0].malzemeTL
        KesifSatir16.malTopF = (TehlikeFiyatList[0].malzemeTL * KesifSatir16.miktar).toFixed(2);
        KesifSatir16.MontF = OTLMontajFiyat;
        KesifSatir16.monTopF = (OTLMontajFiyat * KesifSatir16.miktar).toFixed(2);
        KesifSatir16.ToplamFiyat = (TehlikeFiyatList[0].malzemeTL * KesifSatir16.miktar + OTLMontajFiyat * KesifSatir16.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir16.malTopF * 1;
        MontajToplamFiyat += KesifSatir16.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir16.ToplamFiyat * 1;
    
        kesifdetay.push(KesifSatir16)	//OTL KESİF SATIR EKLENDİ
        //PROJE  KESİF SATIR
        //KesifSatir17.malzeme = TehlikeFiyatList[0].malzeme
        //KesifSatir17.brm = "adet";
        //KesifSatir17.malF = TehlikeFiyatList[0].malzemeTL
        //KesifSatir17.malTopF = (TehlikeFiyatList[0].malzemeTL * KesifSatir17.miktar).toFixed(2);
        //KesifSatir17.MontF = OTLMontajFiyat;
        //KesifSatir17.monTopF = (OTLMontajFiyat * KesifSatir17.miktar).toFixed(2);
        //KesifSatir17.ToplamFiyat = (TehlikeFiyatList[0].malzemeTL * KesifSatir17.miktar + OTLMontajFiyat * KesifSatir17.miktar).toFixed(2);
        //--------------------------------------------------
        MalzemeToplamFiyat += KesifSatir17.malTopF * 1;
        MontajToplamFiyat += KesifSatir17.monTopF * 1;
        MalzemeMontajToplamFiyat += KesifSatir17.ToplamFiyat * 1;
    
        KesifSatir18.malTopF = MalzemeToplamFiyat.toFixed(2);
        KesifSatir18.monTopF = MontajToplamFiyat.toFixed(2);
        KesifSatir18.ToplamFiyat = MalzemeMontajToplamFiyat.toFixed(2);
    
        kesifdetay.push(KesifSatir17)	//PROJE KESİF SATIR EKLENDİ
        kesifdetay.push(KesifSatir18)	//TOPLAM EKLENDİ
    dispatch(Update_Kesif(kesifdetay))
    
    }
    function IletkenNoBul(iletkenNo) {
        var sonuc = [0, 1];
        if (Iletken[pano.InisIletken.No].Malzeme == "Cu") {
            switch (iletkenNo) {
                case 0: sonuc = [3, 1]; break;
                case 1: sonuc = [4, 1]; break;
                case 2: sonuc = [5, 1]; break;
                case 3: sonuc = [6, 1]; break;
                case 4: sonuc = [7, 1]; break;
                case 5: sonuc = [8, 1]; break;
                case 6: sonuc = [6, 2]; break;
                case 7: sonuc = [5, 3]; break;
                case 8: sonuc = [9, 1]; break;
                case 9: sonuc = [7, 2]; break;
                case 10: sonuc = [5, 4]; break;
                case 11: sonuc = [10, 1]; break;
                case 12: sonuc = [0, 3]; break;
                case 13: sonuc = [6, 3]; break;
                case 14: sonuc = [11, 3]; break;
                case 15: sonuc = [8, 2]; break;
                case 16: sonuc = [6, 4]; break;
                case 17: sonuc = [7, 3]; break;
                case 18: sonuc = [9, 2]; break;
                case 19: sonuc = [12, 3]; break;
                case 20: sonuc = [9, 3]; break;
                default:
            }
        }
        else {
            switch (iletkenNo) {
                case 0: sonuc = [17, 1]; break;
                case 1: sonuc = [18, 1]; break;
                case 2: sonuc = [19, 1]; break;
                case 3: sonuc = [20, 1]; break;
                case 4: sonuc = [21, 1]; break;
                case 5: sonuc = [19, 2]; break;
                case 6: sonuc = [18, 3]; break;
                case 7: sonuc = [22, 3]; break;
                case 8: sonuc = [20, 2]; break;
                case 9: sonuc = [18, 4]; break;
                case 10: sonuc = [23, 3]; break;
                case 11: sonuc = [13, 3]; break;
                case 12: sonuc = [19, 3]; break;
                case 13: sonuc = [14, 3]; break;
                case 14: sonuc = [21, 2]; break;
                case 15: sonuc = [19, 4]; break;
                case 16: sonuc = [20, 3]; break;
                case 17: sonuc = [22, 2]; break;
                case 18: sonuc = [15, 3]; break;
                case 19: sonuc = [22, 3]; break;
                case 20: sonuc = [23, 3]; break;
                default:
            }
        }
    
        return sonuc
    } 
    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }
 
 
    return (

        <React.Fragment>

<Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
            style={{ width: "98%", background: bgcolor }}
          >
         <thead> 
            <tr>
                <th colSpan="2" className='pe-1' onClick={Show}> KEŞİF GÖRSEL AYARLARI</th>
            </tr>
            </thead>
            <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "60px"}:{ display: "none" }}> 
                        
                <tr style={{ width:"100%" }}><td style={{ width:"100%" }}>Kenarlar Renk<Button  size="sm" style={{width:"30px",height:"20px", background: cizimRenk ,float:"right" }} onClick={()=>Sec("K_KenarRenk","Kenarlar Renk",cizimRenk)}></Button></td><td></td>
              </tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Text Renk<Button  size="sm" style={{width:"30px",height:"20px",background: textRenk ,float:"right" }} onClick={()=>Sec("K_TextRenk","Text Renk",textRenk)}></Button></td><td></td></tr> 
  
  
      
               
              </tbody>    
          </Table>

        </React.Fragment>
    )

}
