  
import React, {  useEffect, useState } from 'react'
import {   Button, Modal, Row,Col } from 'react-bootstrap'
import ToolTrafoVeriDetay from './Tool_TrafoVeriDetay'
  
 
import { DataGrid } from '@mui/x-data-grid';


export default function YuklemeCetveliModal(props) {
     const [show, setShow] = useState(props.show);
    const [secim, setSecim] = useState(0);
    let yuklist=props.list

 useEffect(()=>{
    setShow(!show)
 },[props.show])
useEffect(()=>{ 
 },[secim,yuklist])

const columns = [
{ field: 'No', headerName: 'No', width: 30 },
{ field: 'Faz', headerName: 'Faz', width: 30 },
{ field: 'Guc', headerName: 'Güç (w)', width: 80 },
{ field: 'Aciklama', headerName: 'Açıklama', width: 150 },
{ field: 'Akim', headerName: 'Akim (A)', width: 70 },
{ field: 'AkimKap', headerName: 'Akim Kap (A)', width: 40 },
{ field: 'Iletken', headerName: 'İletken', width: 150 },
{ field: 'Mesafe', headerName: 'Mesafe', width: 10,hide: true  },
{ field: 'Kesici', headerName: 'Kesici', width: 100 },

];

 function sil(num) {
      let yenilist=[]
     let no=1;
    yuklist.map((list,l)=>{        
            if(l!==num[0]-1){   
                let list1=list;
                //list1.id=no         
              //  list1.No=no         
                //yenilist.push(list1)
                yenilist.push({Aciklama:list.Aciklama,Akim:list.Akim,Faz:list.Faz,Guc:list.Guc,HavaAkimKap:list.HavaAkimKap,Iletken:list.Iletken,Kesici:list.Kesici,KesiciOb:list.KesiciOb,Mesafe:list.Mesafe,No:no,TopAkimKap:list.TopAkimKap,id:no,iletkenKesit:list.iletkenKesit})
                no++
            }    
    })
    
    yuklist=yenilist
    props.yuklergerial(yenilist);     
  }

     return (

        <>
    
        <Modal
          show={show}
          onHide={() => setShow(false)}
          style={{width:"100%",height:"100%"}}
          className="me-1"
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
          fullscreen={false}
        >
          <Modal.Header closeButton  >
            <Modal.Title id="example-custom-modal-styling-title">
              YÜKLEME CETVELİ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="outline-primary"size="sm"className='me-1'>Yukarı</Button>
              <Button variant="outline-primary"size="sm"className='me-1'>Aşağı</Button>
              <Button variant="outline-danger" size="sm"className='me-1' onClick={function(e) {sil(secim)}}>Sil</Button>
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  >
            <Row>
            <Col xs={3}>
                <ToolTrafoVeriDetay />        
                </Col>
                <Col xs={9}>
    
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={yuklist}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection 
        onSelectionModelChange={itm => setSecim(itm)} 
        disableColumnFilter
      />
    </div>
        </Col>
            </Row>    
        </Modal.Body>
        </Modal>
      </>
    )

}
