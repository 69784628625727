import { createSlice } from '@reduxjs/toolkit'

export const sayacbolmeSlice = createSlice({
  name: 'sayacbolme',
  initialState: {
    value: 3,
   
  },
  reducers: {
   
    sayacdata: (state, action) => { state.value = action.payload   },    
   
  },
})

export const { sayacdata } = sayacbolmeSlice.actions

export default sayacbolmeSlice.reducer