import React, { useState } from 'react'
import { Container, Row, Col, Card, Collapse } from 'react-bootstrap'

import { useSelector, useDispatch } from 'react-redux'
 export default function Home() {

 


  const lisan = useSelector((state) => state.admin.dil)
 

  return (
    <div  >


      <div className='Home' style={{padding:"40px"}} >
  

        <Row >
          <Col xs={4} >
          {lisan === 'ENG' ? <>
        <br />
          <h4 style={{backgroundColor:"rgb(200,200,200,0.55)",padding:"40px",borderRadius:"10px"}}>

          <b>ProjeHUB</b>, inşaat Sektöründe proje hazırlayan, proje kontrol eden ve proje onaylayan tüm taraflara hizmet sunmayı planlayan, otomatik proje çizimi ve topluluk alt yapısı olan bir teknoloji girişimidir.<br />
            <br />ProjeHUB gelişim sürecini hızlandırmak için Yatırımcı arayışındadır.<br />
            <br /> Bu sayfa   Yatırımcılar için hazırlanmıştır.<br />
            <br />TrafoProje Menüsünden ilk prototip olan TrafoProjesi Çizim Editörünü inceleyebilirsiniz.
          </h4>
        </> : <>
        <h4 style={{backgroundColor:"rgb(200,200,200,0.55)",padding:"30px",borderRadius:"10px"}}>

            ProjeHUB is a technology initiative with automatic project drawing and community infrastructure that plans to provide services to all parties who prepare projects, control projects and approve projects in the construction industry.
            <br />ProjeHUB is looking for investors to accelerate the development process.
            <br /> This page has been prepared for   Investors.
            <br />You can examine the first prototype, TrafoProject Drawing Editor, from the TrafoProject Menu.
          </h4>
        </>}
          </Col>
          <Col xs={4} >
          
          {lisan === 'ENG' ? <h2>Proje  </h2> : <h2>Proje  </h2>}
                  
          {lisan === 'ENG' ? <>
          Farklı mühendislik Disiplinlerine ait pek çok farklı tipte projelerin otomatik olarak hazırlanmasını sağlayan, birbirleri ile etkileşim halinde olabilen <b>Otomatik Çizim Editör</b>leri 
            <ul>
              <li>Elektrik</li>
              <li>Mimari</li>
              <li>Mekanik</li>
              <li>İnşaat</li>
            </ul>
          </> : <>
          <b>Automatic Drawing Editor</b>s that enable the automatic preparation of many different types of projects belonging to different engineering disciplines and can interact with each other.
          <ul>
              <li>Electrical</li>
              <li>Architects</li>
              <li>Mechanical</li>
              <li>Civil</li>
            </ul>
          </>}





          </Col>
          <Col xs={4}>
                    
          <h2>HUB  </h2>
                  
          {lisan === 'ENG' ? <>
          HUB bir yapının proje süreci ile başlayarak yapı varlığı boyunca süren proje sürecinin tüm taraflarını bir araya getirerek yapının tüm projelerini aynı ortamda hazırlayabilmelerini sağlayan. Çok farklı ve gelişmiş özellikleri olan bir topluluk alt yapısıdır.

            <ul>
              <li>Oluştur, bulutta tut, paylaş</li>
              <li>Süreç Yönetimi Sistemi</li>
              <li>DEPO</li>
              <li>Şablonlar</li>
            </ul>
          </> : <>
          <b>HUB</b> brings together all parties of the project process, starting with the project process of a building and continuing throughout the existence of the building, and enables them to prepare all projects of the building in the same environment. It is a community infrastructure with very different and advanced features.
          <ul>
               <li>Create, keep in the cloud, share</li>
               <li>Process Management System</li>
               <li>WAREHOUSE</li>
               <li>Templates</li>
            </ul>
          </>
          }


          </Col>
        </Row>




      </div>
    </div>
  )
}
