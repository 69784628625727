
import React, {  useEffect, useState } from 'react'
import {  Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
//import { sayacdata } from '../reducers/sayacbolmeSlice'
import { UpdateSecim } from '../reducers/cizimOzellik'

let  denemeCizim=[ ]
export default function AGTekHatSema(props) {
    const panoDetaylar = useSelector((state) => state.PanoDetay)
    const kompDetaylar = useSelector((state) => state.KompDetay)
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const SemalOzellik = useSelector((state) => state.SemaCizim)
    let Scl=cizimOzellik.Scl;
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:1200*Scl,h:1555*Scl},cizim:denemeCizim})

    const [bgcolor, setBgcolor] = useState("white");
    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }
    const dispatch = useDispatch() 
 
    let textRenk=SemalOzellik.AGTHTextRenk
   // let cizimRenk=SemalOzellik.AGTHIletRenk
    let cerceveAGRenk=SemalOzellik.OGTHCerRenkAG
    let KesiciRenk=SemalOzellik.AGTHKesiciRenk
    let KesiciTeRenk=SemalOzellik.OGTHKesiciTRenk 
    let iletkenTeRenk=SemalOzellik.AGTHIletTeRenk
    let iletkenRenk=SemalOzellik.AGTHIletRenk
    let YukTeRenk=SemalOzellik.AGTHYukTeRenk
    let NoTeRenk=SemalOzellik.AGTHNoTeRenk
    let BaraRenk=SemalOzellik.AGTHBaraRenk
    let BaraTeRenk=SemalOzellik.AGTHBaraTeRenk
    let KutuRenk=SemalOzellik.AGTHKutuRenk
    let GirisIletTeRenk=SemalOzellik.AGTHGirisIletTeRenk
    let KompIletTeRenk=SemalOzellik.AGTHKompIletTeRenk
     
    function artir(){}   
    useEffect(() => {       
        artir()        
    }, [ panoDetaylar,kompDetaylar,cizimOzellik,SemalOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]);

    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }
 
    return (

        <React.Fragment>
     <Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
            style={{ width: "98%", background: bgcolor }}
          >
         <thead>  
            <tr> 
                <th colSpan="2" className='pe-1' onClick={Show}> AG TEK HAT ŞEMASI GÖRSEL AYARLARI</th>
            </tr> 
            </thead>
            <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "360px"}:{ display: "none" }}>                         
                <tr style={{ width:"100%" }}><td style={{ width:"100%" }}>Çerçeve Renk<Button  size="sm" style={{width:"30px",height:"20px", background: cerceveAGRenk ,float:"right" }} onClick={()=>Sec("OGTHCerRenkAG","AG Şema Çerçeve Renk",cerceveAGRenk)}></Button></td><td></td>
              </tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Text Renk<Button  size="sm" style={{width:"30px",height:"20px",background: textRenk ,float:"right" }} onClick={()=>Sec("AGTHTextRenk","Text ",textRenk)}></Button></td><td></td></tr>  
{/*               <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Çizgiler<Button  size="sm" style={{width:"30px",height:"20px",background: cizimRenk ,float:"right" }} onClick={()=>Sec("OGTHCizimRenk","Çizgiler",cizimRenk)}></Button></td><td></td></tr>  
 */}           
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >No Text<Button  size="sm" style={{width:"30px",height:"20px",background: NoTeRenk ,float:"right" }} onClick={()=>Sec("AGTHNoTeRenk","No ",NoTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kesici<Button  size="sm" style={{width:"30px",height:"20px",background: KesiciRenk ,float:"right" }} onClick={()=>Sec("AGTHKesiciRenk","Kesici ",KesiciRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kesici Text<Button  size="sm" style={{width:"30px",height:"20px",background: KesiciTeRenk ,float:"right" }} onClick={()=>Sec("AGTHKesiciTeRenk","Kesici Text ",KesiciTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İletken<Button  size="sm" style={{width:"30px",height:"20px",background: iletkenRenk ,float:"right" }} onClick={()=>Sec("AGTHIletRenk","İletken ",iletkenRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: iletkenTeRenk ,float:"right" }} onClick={()=>Sec("AGTHIletTeRenk","İletken Text ",iletkenTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Açıklama Text<Button  size="sm" style={{width:"30px",height:"20px",background: YukTeRenk ,float:"right" }} onClick={()=>Sec("AGTHYukTeRenk","Açıklama Text",YukTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Açıklama Kutu<Button  size="sm" style={{width:"30px",height:"20px",background: KutuRenk ,float:"right" }} onClick={()=>Sec("AGTHKutuRenk","Açıklama Kutu",KutuRenk)}></Button></td><td></td></tr>  

              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Bara<Button  size="sm" style={{width:"30px",height:"20px",background: BaraRenk ,float:"right" }} onClick={()=>Sec("AGTHBaraRenk","Bara ",BaraRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Bara Text<Button  size="sm" style={{width:"30px",height:"20px",background: BaraTeRenk ,float:"right" }} onClick={()=>Sec("AGTHBaraTeRenk","Bara Text Renk",BaraTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Giriş İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: GirisIletTeRenk ,float:"right" }} onClick={()=>Sec("AGTHGirisIletTeRenk","Giriş İletken ",GirisIletTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kompanz. İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: KompIletTeRenk ,float:"right" }} onClick={()=>Sec("AGTHKompIletTeRenk","Giriş İletken Text",KompIletTeRenk)}></Button></td><td></td></tr>  
              </tbody>    
          </Table>
    

        </React.Fragment>
    )

}
  