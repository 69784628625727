import React from 'react'
import {  Row, Col } from 'react-bootstrap' 
export default function badur() {   

  return (
    <div className='text-center'  ><br />
      <h3>GELİŞTİRİCİ:</h3>
      <Row>
        <Col xs={2}>
        </Col>
        <Col xs={8}>
          Bu uygulama E.E. Müh. İrfan BADUR tarafından geliştirilmiştir.
          <br/> mail : irfanbadur@outlook.com
          <br/> Tel : 0 535 372 66 15
        </Col>
        <Col xs={2}>
        </Col>
      </Row>
    </div>
  )
}
