import React, { useEffect, useState } from 'react'
import { Form, Button, Table, Modal, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {  UpdateTalepFak,UpdateTalepGuc,UpdateCosFi,UpdateTalepGuckVA,UpdateTrafoGuckVA } from '../reducers/trafoDetaySlice'
import { trafoGucGuncelle} from '../Fonksiyonlar/Elektricals'

import { DataGrid } from '@mui/x-data-grid';

export default function TrafoVeriDetay(props) {
  const OGHatDetay = useSelector((state) => state.OGHatDetay)  
  const trafoDetaylar = useSelector((state) => state.trafoDetay) 

  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");
  const [talepFak, setTalepFak] = useState( trafoDetaylar.talepFak);


  const dispatch = useDispatch()
  function Show() { setToolShow(!toolshow) }
  
  useEffect(() => {
      talepFakGuncelle(talepFak)
  }, [OGHatDetay])
  function talepFakGuncelle(val){
    setTalepFak(val)
    dispatch(UpdateTalepFak(val))
    let kuruluguc=trafoDetaylar.topKuruluGuc         
    let talepGuc=kuruluguc*val/100
    let cosFi=OGHatDetay.cosFi
    let talepGuckVA=(talepGuc/cosFi).toFixed(2)
    let trafoGuckVA=trafoGucGuncelle(talepGuckVA).trafoGuckVA     
    dispatch(UpdateTalepGuc(talepGuc))
    dispatch(UpdateTalepGuckVA(talepGuckVA))
    dispatch(UpdateTrafoGuckVA(trafoGuckVA))
     

  }

  
  return (
    <React.Fragment >
      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Trafo Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>
          <tr>
            <td>
              Toplam Kurulu Güç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            {trafoDetaylar.topKuruluGuc}
            </td>
          </tr>
          <tr>
            <td>
              Talep Faktörü
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' min="60" max="100" onChange={e=>talepFakGuncelle(e.target.value*1)} placeholder={trafoDetaylar.talepFak}/>
            </td>
          </tr>

          <tr>
            <td>
              Toplam Talep Güç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            {trafoDetaylar.talepGuc}
            </td>
          </tr>


          <tr>
            <td>
            Talep Güç kVA
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            {trafoDetaylar.talepGuckVA}
            </td>
          </tr>

          <tr>
            <td>
              Trafo Güc(kVA)
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            {trafoDetaylar.trafoGuckVA}
            </td>
          </tr>
 

        </tbody>
      </Table>




    </React.Fragment>





  )

}
