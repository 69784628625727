import { Nav, Navbar, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
  
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import icerik from './icerikler'
import { UpdateDil  } from '../reducers/adminSlice'
import flagTurkish from '../Pictures/Turkish3.png'
import flagEng from '../Pictures/english.png'

export function NavigationBar() {

     
   
    const [dil,setDil]=useState("TR")
    const [flag, setFlag] = useState(flagTurkish);

    const dispatch = useDispatch()
   
    const dilDegis=()=>{
      if(dil==="TR"){
        setDil("ENG")
        setFlag(flagEng);
        dispatch(UpdateDil("ENG"))
      }else{
        setDil("TR")
        setFlag(flagTurkish);
        dispatch(UpdateDil("TR"))
      }
    }




  return (

    <Navbar className='h4' bg="white"   expand="lg" style={{ position :"fixed ", width:"100%" }}  >

      <Navbar.Brand as={Link} to="/" className="fs-1 ms-5" style={{color:"black",fontWeight:"bold"  }}
      >ProjeHUB</Navbar.Brand>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" >
        <Nav className="mx-auto color ">
          <Nav.Link as={Link} to="/" >
            {dil==="ENG"?icerik.menu.HomePage.tr:icerik.menu.HomePage.eng}</Nav.Link>
   
          <Nav.Link as={Link} to="/overview">{dil==="ENG"?icerik.menu.OverView.tr:icerik.menu.OverView.eng}</Nav.Link>
      
           <Nav.Link as={Link} to="/pitchdeck"  >{dil==="ENG"?<>Yatırımcı Sunumu</>: <>Pick Deck  </> }</Nav.Link>
          
           <Nav.Link as={Link} to="/executivesummary"  >{dil==="ENG"?<>Yönetim Özeti</>: <>Executive Summary</> }</Nav.Link>
{/*            <Nav.Link as={Link} to="/financials"  >{dil==="ENG"? "Finans" :  "Financials"}</Nav.Link>
 */}          
           <Nav.Link as={Link} to="/contact"  >{dil==="ENG"?"İletişim":"Contact"}</Nav.Link>

 
          <Nav.Link as={Link} to="/direktipitrafoeditor" style={{color:"blue",fontWeight:"bold" ,opacity: 0.75}} >TrafoProje
     
       
       
          </Nav.Link>
        </Nav>
        <Button style={{border:"2px solid black" ,opacity: 0.95}} onClick={ dilDegis }>{dil}</Button>
        <div style={{ width: "50px", height: "30px", backgroundImage: `url(${flag})`,backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>

          </Navbar.Collapse>
      
 

    </Navbar>
  );
}

export default NavigationBar;