
import React, {  useEffect, useState } from 'react'
import {  Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {TekHatSemaBos,TekHatAGDagitimSema,TekHatKOMP_Sema} from '../Data/SemaCizimler'
import { UpdateSecim } from '../reducers/cizimOzellik'

let X=0;
let Y=0; 
let  denemeCizim=[]   

export default function OGTekHatSema(props) {   
     
    const direkDetaylar = useSelector((state) => state.direkDetay)
    const trafoDetaylar = useSelector((state) => state.trafoDetay)  
    const panoDetaylar = useSelector((state) => state.PanoDetay) 
    const cizimOzellik = useSelector((state) => state.cizimOzellik) 
    const kompDetaylar = useSelector((state) => state.KompDetay)   
    const SemaOzellik = useSelector((state) => state.SemaCizim) 
    const dispatch = useDispatch() 
 
    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }
    let Scl=cizimOzellik.Scl;
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:1461*Scl},cizim:denemeCizim})
    
    const [bgcolor, setBgcolor] = useState("white");     
    let Scl2 =1.87
    let textRenk=SemaOzellik.OGTHCizimTextRenk 
    let cizimRenk=SemaOzellik.OGTHCizimRenk  
    let cerceveOGRenk=SemaOzellik.OGTHCerRenkOG
    let direkRenk=SemaOzellik.OGTHdirekRenk
    let direkTeRenk=SemaOzellik.OGTHdirekTextRenk
    let trafoRenk=SemaOzellik.OGTHtrafoRenk
    let trafoTeRenk=SemaOzellik.OGTHtrafoTextRenk 
    let trafoGirisRenk=SemaOzellik.OGTHtrafoGirisRenk
    let trafoInisRenk=SemaOzellik.OGTHtrafoInisRenk
    let prfdrRenk=SemaOzellik.OGTHparafRenk
    let prfdrTeRenk=SemaOzellik.OGTHparafTextRenk
    let prfdrGirisRenk=SemaOzellik.OGTHparafGirisRenk
    let prfdrInisRenk=SemaOzellik.OGTHparafInisRenk 
    let SekRenk=SemaOzellik.OGTHSekRenk
    let SekTeRenk=SemaOzellik.OGTHSekTextRenk
    let SekGirisRenk=SemaOzellik.OGTHSekGirisRenk
    let SekIletRenk=SemaOzellik.OGTHSekIletkenRenk
    let SayacRenk=SemaOzellik.OGTHSayacRenk
    let SayacTextRenk=SemaOzellik.OGTHSayacTRenk
    let IcIhtRenk=SemaOzellik.OGTHIcIhtRenk
    let IcIhtTeRenk=SemaOzellik.OGTHIcIhtTRenk
    let SbtGrpRenk=SemaOzellik.OGTHSGrpRenk
    let SbtGrpTeRenk=SemaOzellik.OGTHSGrpTRenk
    let SinyalRenk=SemaOzellik.OGTHSinyalRenk 
    let SinyalTeRenk=SemaOzellik.OGTHSinyalTRenk
    let KesiciRenk=SemaOzellik.OGTHKesiciRenk  
    let KesiciTeRenk=SemaOzellik.OGTHKesiciTRenk
    let AkimTrfRenk=SemaOzellik.OGTHAkimTrfRenk 
    let AkimTrfTeRenk=SemaOzellik.OGTHAkimTrfTextRenk 
    let TopRenk=SemaOzellik.OGTHtopRenk
    let TopTeRenk=SemaOzellik.OGTHtopTextRenk     

    let CikisSayi=1;
    let Cikislar=[{id: 1, No: 1, Faz: '3', Guc: 5500, Akim: '10.46',AkimKap:34,
    Iletken  :"5x4mm² NYY", Kesici:"25kA 40A 3P",Mesafe:5,KesiciOb:{Akim: 40, KisaDevreAkim: 25, Tip: 'C', Kutup: '3P', Tur: 'T.M.Ş.'}}]
    let KompVarlik=kompDetaylar.KompVarlik
    let KomKademeSayi=kompDetaylar.Kademeler.length 
    let cikisAra=100*Scl*Scl2;
    let CizimW=800*Scl*Scl2;
    let CizimSemaW=1600*Scl*Scl2;
    let KompSemaX=825*Scl*Scl2 
 
    if(panoDetaylar.Cikislar.length>0){ 
        CikisSayi=panoDetaylar.Cikislar.length  
        Cikislar=panoDetaylar.Cikislar 
    }
     if(KompVarlik===true){
        CikisSayi++ 
        
    }  
    if(KompVarlik==="true"){
        CizimSemaW=1600*Scl*Scl2;
        if(CikisSayi>13){  
            CizimSemaW=1520*Scl*Scl2+(CikisSayi-13)*cikisAra  
        }
    }  else{
        CizimSemaW=1000*Scl*Scl2;
        if(CikisSayi>8){  
            CizimSemaW=1520*Scl*Scl2+(CikisSayi-13)*cikisAra  
        }
    }

    if((CikisSayi+1)*cikisAra>2*CizimW){     
        CizimW=cikisAra*CikisSayi-600*Scl 
    }  
    
   
  
    let cizimTam=[]  
    cizimTam=TekHatSemaBos(0,0,Scl*Scl2 ,{
        direk:direkDetaylar.direk,
        seksiyoner:direkDetaylar.seksiyoner,
        seksiyonergiris:direkDetaylar.seksiyonergiris, 
        parafudr:direkDetaylar.parafudr,
        parafudrGiris:direkDetaylar.parafudrGiris,
        parafudrInis:direkDetaylar.parafudrInis,
        trafoGiris:direkDetaylar.trafoGiris,
        trafoInis:direkDetaylar.trafoInis,
        trafoOran:trafoDetaylar.trafoOran,
        trafoGuc:trafoDetaylar.trafoGuckVA, 
        trafoisletmeinis:direkDetaylar.trafoisletmeinis,
        parafudrTopraklama:direkDetaylar.parafudrTopraklama,
        parafudrKonum:direkDetaylar.parafudrKonum,
        trafoTopraklama:direkDetaylar.trafoTopraklama,
        anaKesici:panoDetaylar.AnaKesici,
        kesici1Tur:panoDetaylar.Kesici1Tur,
        kesici1:panoDetaylar.Kesici1,  
        kesici2Tur:panoDetaylar.Kesici2Tur,
        kesici2:panoDetaylar.Kesici2,  
        sayac:panoDetaylar.Sayac,   
        sayacDizi:panoDetaylar.sayacDizi, 
        bara:panoDetaylar.Bara,
        akimtrafolar:panoDetaylar.AkimTrafolari,
        tmsAkim:panoDetaylar.TMSAkim, 
        sabitGrup:panoDetaylar.SabitGrup,
        SabitGrupkVAR:panoDetaylar.SabitGrupkVAR,
        icIhtiyac:panoDetaylar.IcIhtiyac,    
        gostergeler:panoDetaylar.Gostergeler,   
        //--------------------------------------
        textRenk:textRenk ,
        cerceveRenk:cerceveOGRenk ,
        cizimRenk:cizimRenk    ,
        direkRenk:direkRenk,
        direkTeRenk:direkTeRenk,
        trafoRenk:trafoRenk,
        trafoTeRenk:trafoTeRenk,
        trafoGirisRenk:trafoGirisRenk,
        trafoInisRenk:trafoInisRenk,
        prfdrRenk:prfdrRenk, 
        prfdrTeRenk:prfdrTeRenk, 
        prfdrGirisRenk:prfdrGirisRenk,
        prfdrInisRenk:prfdrInisRenk,
        SekRenk:SekRenk,
        SekTeRenk:SekTeRenk,
        SekGirisRenk:SekGirisRenk,
        SekIletRenk:SekIletRenk,
        SayacRenk:SayacRenk,
        SayacTextRenk:SayacTextRenk,
        IcIhtRenk:IcIhtRenk,
        IcIhtTeRenk:IcIhtTeRenk,
        SbtGrpRenk:SbtGrpRenk,
        SbtGrpTeRenk:SbtGrpTeRenk,
        SinyalRenk:SinyalRenk, 
        SinyalTeRenk:SinyalTeRenk,
        KesiciRenk:KesiciRenk,
        KesiciTeRenk:KesiciTeRenk,
        AkimTrfRenk:AkimTrfRenk,
        AkimTrfTeRenk:AkimTrfTeRenk,
        TopRenk:TopRenk,  
        TopTeRenk:TopTeRenk,
      
   
        }  )

    cizimTam=cizimTam.concat(TekHatAGDagitimSema(0,3000*Scl,Scl*Scl2,{
            cikisSayi:CikisSayi,
            cikisAra:cikisAra,
            Cikislar:Cikislar,
            KomKademeSayi:KomKademeSayi,
            KompVarlik:KompVarlik,
            KompKesici:kompDetaylar.Kesici, 
            KompIletken:kompDetaylar.Iletken,
            KompX:kompDetaylar.X,  
            KompY:kompDetaylar.Y,
            Bara:panoDetaylar.Bara,  
            AnaHat:panoDetaylar.InisIletken,       
            textRenk:textRenk,  
            cerceveRenk:SemaOzellik.OGTHCerRenkAG,              
            cizimRenk:cizimRenk,
            

            cerceveAGRenk:SemaOzellik.OGTHCerRenkAG,
            AGTextRenk:SemaOzellik.AGTHTextRenk,
            AGKesiciRenk:SemaOzellik.AGTHKesiciRenk,
            AGKesiciTeRenk:SemaOzellik.AGTHKesiciTeRenk,
            AGiletkenTeRenk:SemaOzellik.AGTHIletTeRenk,
            AGiletkenRenk:SemaOzellik.AGTHIletRenk,
            AGYukTeRenk:SemaOzellik.AGTHYukTeRenk,    
            AGNoTeRenk:SemaOzellik.AGTHNoTeRenk,
            AGBaraRenk:SemaOzellik.AGTHBaraRenk,
            AGBaraTeRenk:SemaOzellik.AGTHBaraTeRenk,
            AGKutuRenk:SemaOzellik.AGTHKutuRenk,
            AGGirisIletTeRenk:SemaOzellik.AGTHGirisIletTeRenk,
            AGKompIletTeRenk:SemaOzellik.AGTHKompIletTeRenk,
            AnaHatRenk:SemaOzellik.KAnaHatRenk,
            AnaHatTeRenk:SemaOzellik.KAnaHatTeRenk, 
  
        }) )  
 if(kompDetaylar.KompVarlik==="true"){
    cizimTam=cizimTam.concat(TekHatKOMP_Sema( KompSemaX,0,Scl*Scl2, 
            { 
                TalepGuc:trafoDetaylar.TalepGuc, 
                CosFi1:trafoDetaylar.cosFi ,    
                CosFi2:kompDetaylar.CosFi2 ,  
                K:kompDetaylar.K, 
                KademeSayi:kompDetaylar.KademeSayi,
                GrupSayi:kompDetaylar.GrupSayi,
                Kademeler:kompDetaylar.Kademeler,
                Iletken:kompDetaylar.Iletken,
                Kesici:kompDetaylar.Kesici,
                KompGuc:kompDetaylar.KompGuc,
                SeciliKompGuc:kompDetaylar.SeciliKompGuc,  
                Bara:kompDetaylar.Bara, 
                CikisAra:cikisAra,
                DagCikisSayi:panoDetaylar.Cikislar.length,
                NHSinir:kompDetaylar.NHSinir,    
                 
                cerceveRenk:SemaOzellik.OGTHCerRenkKomp,                 
                textRenk:SemaOzellik.KTextRenk,
                cizimRenk:SemaOzellik.KIletRenk,
                AOSRenk:SemaOzellik.KAOSRenk,
                AOSTeRenk:SemaOzellik.KAOSTeRenk ,
                KontRenk:SemaOzellik.KKontRenk,
                KontTeRenk:SemaOzellik.KKontTeRenk ,
                iletkenTeRenk:SemaOzellik.KIletTeRenk,
                iletkenRenk:SemaOzellik.KIletRenk,
                YukTeRenk:SemaOzellik.KYukTeRenk,
                NoTeRenk:SemaOzellik.KNoTeRenk,
                BaraRenk:SemaOzellik.KBaraRenk,
                BaraTeRenk:SemaOzellik.KBaraTeRenk,
                KondRenk:SemaOzellik.KKondRenk, 
                KondTeRenk:SemaOzellik.KKondTeRenk,
                GirisIletTeRenk:SemaOzellik.KGirisIletTeRenk,
                KompIletTeRenk:SemaOzellik.KKompIletTeRenk,
                AkimTrfRenk:SemaOzellik.KAkimTrfRenk,
                AkimTrfTeRenk:SemaOzellik.KAkimTrfTeRenk,
                AkimTrfIletRenk:SemaOzellik.KAkimTrfIletRenk,
                akimtrafolar:panoDetaylar.AkimTrafolari
                  
            }
        )  )  
     }
    function artirW( ){   
        setCizim(prmt => ({    
            ...prmt,  
            attr:{   
                x:0,y:0,     
                w:CizimSemaW,         
                h:1461*Scl *Scl2                                
            },         
            cizim:cizimTam,             
        }));           
         
    }  

    useEffect(() => {          
        artirW( )    
    }, [direkDetaylar,trafoDetaylar,panoDetaylar,cizimOzellik,kompDetaylar,SemaOzellik]);
    useEffect(() => {
        props.cizim(cizim)  
    }, [ cizim]);  

    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }
 
 
    return (

        <React.Fragment>
     
     <Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
            style={{ width: "98%", background: bgcolor }}
          >
         <thead>
            <tr>
                <th colSpan="2" className='pe-1' onClick={Show}> TEK HAT ŞEMASI GÖRSEL AYARLARI</th>
            </tr>
            </thead>
            <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "360px"}:{ display: "none" }}>                         
                <tr style={{ width:"100%" }}><td style={{ width:"100%" }}>Çerçeve Renk<Button  size="sm" style={{width:"30px",height:"20px", background: cerceveOGRenk ,float:"right" }} onClick={()=>Sec("OGTHCerRenkOG","OG Şema Çerçeve Renk",cerceveOGRenk)}></Button></td><td></td>
              </tr>  

              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Text Renk<Button  size="sm" style={{width:"30px",height:"20px",background: textRenk ,float:"right" }} onClick={()=>Sec("OGTHCizimTextRenk","Text Renk",textRenk)}></Button></td><td></td></tr>  

              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Çizgiler<Button  size="sm" style={{width:"30px",height:"20px",background: cizimRenk ,float:"right" }} onClick={()=>Sec("OGTHCizimRenk","Text Renk",cizimRenk)}></Button></td><td></td></tr>  

              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Direk<Button  size="sm" style={{width:"30px",height:"20px",background: direkRenk ,float:"right" }} onClick={()=>Sec("OGTHdirekRenk","Direk Renk",direkRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Direk Text<Button  size="sm" style={{width:"30px",height:"20px",background: direkTeRenk ,float:"right" }} onClick={()=>Sec("OGTHdirekTextRenk","Direk Text Renk",direkTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Trafo<Button  size="sm" style={{width:"30px",height:"20px",background: trafoRenk ,float:"right" }} onClick={()=>Sec("OGTHtrafoRenk","Trafo Text Renk",trafoRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Trafo Text<Button  size="sm" style={{width:"30px",height:"20px",background: trafoTeRenk ,float:"right" }} onClick={()=>Sec("OGTHtrafoTextRenk","Trafo Text Renk",trafoTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Parafudr<Button  size="sm" style={{width:"30px",height:"20px",background: prfdrRenk ,float:"right" }} onClick={()=>Sec("OGTHparafRenk","Parafudr Renk",prfdrRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Parafudr Text<Button  size="sm" style={{width:"30px",height:"20px",background: prfdrTeRenk ,float:"right" }} onClick={()=>Sec("OGTHparafTextRenk","Parafudr Text Renk",prfdrTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Seksiyoner<Button  size="sm" style={{width:"30px",height:"20px",background: SekRenk ,float:"right" }} onClick={()=>Sec("OGTHSekRenk","Seksiyoner Renk",SekRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Seksiyoner Text<Button  size="sm" style={{width:"30px",height:"20px",background: SekTeRenk ,float:"right" }} onClick={()=>Sec("OGTHSekTextRenk","Seksiyoner Text Renk",SekTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Sayaç<Button  size="sm" style={{width:"30px",height:"20px",background: SayacRenk ,float:"right" }} onClick={()=>Sec("OGTHSayacRenk","Sayaç Renk",SayacRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Sayaç Text<Button  size="sm" style={{width:"30px",height:"20px",background: SayacTextRenk ,float:"right" }} onClick={()=>Sec("OGTHSayacTRenk","Sayaç Text Renk",SayacTextRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İç İhtiyaç  <Button  size="sm" style={{width:"30px",height:"20px",background: IcIhtRenk ,float:"right" }} onClick={()=>Sec("OGTHIcIhtRenk","İç İhtiyaç Renk",IcIhtRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İç İhtiyaç Text<Button  size="sm" style={{width:"30px",height:"20px",background: IcIhtTeRenk ,float:"right" }} onClick={()=>Sec("OGTHIcIhtTRenk","İç İhtiyaç Text Renk",IcIhtTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Sabit Grup<Button  size="sm" style={{width:"30px",height:"20px",background: SbtGrpRenk ,float:"right" }} onClick={()=>Sec("OGTHSGrpRenk","Sabit Grup Renk",SbtGrpRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Sabit Grup Text<Button  size="sm" style={{width:"30px",height:"20px",background: SbtGrpTeRenk ,float:"right" }} onClick={()=>Sec("OGTHSGrpTRenk","Sabit Grup Text Renk",SbtGrpTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Göstergeler<Button  size="sm" style={{width:"30px",height:"20px",background: SinyalRenk ,float:"right" }} onClick={()=>Sec("OGTHSinyalRenk","Göstergeler Text Renk",SinyalRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Göstergeler Text<Button  size="sm" style={{width:"30px",height:"20px",background: SayacTextRenk ,float:"right" }} onClick={()=>Sec("OGTHSinyalTRenk","Göstergeler Text Renk",SayacTextRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kesici<Button  size="sm" style={{width:"30px",height:"20px",background: KesiciRenk ,float:"right" }} onClick={()=>Sec("OGTHKesiciRenk","Sayaç Text Renk",KesiciRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kesici Text<Button  size="sm" style={{width:"30px",height:"20px",background: KesiciTeRenk ,float:"right" }} onClick={()=>Sec("OGTHKesiciTRenk","Kesici Text Renk",KesiciTeRenk)}></Button></td><td></td></tr>  
            
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İşletme Topraklaması<Button  size="sm" style={{width:"30px",height:"20px",background: TopRenk ,float:"right" }} onClick={()=>Sec("OGTHtopRenk","Kesici Text Renk",TopRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İşletme Topraklaması Text<Button  size="sm" style={{width:"30px",height:"20px",background: TopTeRenk ,float:"right" }} onClick={()=>Sec("OGTHtopTextRenk","Kesici Text Renk",TopTeRenk)}></Button></td><td></td></tr>  
              </tbody>    
          </Table>
    
           

        </React.Fragment>
    )

}
