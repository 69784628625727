
import React, { useEffect, useState } from 'react'
import { Form, Table, Modal, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {  UpdateCosFi1,UpdateCosFi2,UpdateK,UpdateKademeSayi,UpdateGrupSayi,UpdateKademeler,UpdateIletken,UpdateKesici,UpdateKompGuc,UpdateSecKompGuc,UpdateBara,UpdateCikisAra,UpdateKompVarlik,UpdateNHSinir} from '../reducers/KompDetaySlice'
import {KompMalSec} from '../Data/Ayarlar'

import { Iletken } from '../Data/Malzeme'
 

export default function TrafoDirekDetay(props) {

  const PanoDetaylar = useSelector((state) => state.PanoDetay)
  const trafoDetaylar = useSelector((state) => state.trafoDetay)
  const KompDetaylar = useSelector((state) => state.KompDetay)

  const dispatch = useDispatch()
   


  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");
  const [talepGuc, setTalepGuc] = useState(trafoDetaylar.talepGuc);
  const [cosFi1, setCosFi1] = useState(trafoDetaylar.cosFi);
  const [cosFi2, setCosFi2] = useState(0.99);
  const [k, setK] = useState(0.61);
  const [kompGuc, setKomGuc] = useState(parseInt(trafoDetaylar.talepGuc * k));
  const [kompVarlik, setKompVarlik] = useState(KompDetaylar.KompVarlik);
  const [grupSayi, setGrupSayi] = useState(4);
  const [kademeSayi, setKademeSayi] = useState(12);
  const [nHSinir, setNHSinir] = useState(32);
  const [grup1, setGrup1]     = useState(kompGuc*0.55);
  const [grup2, setGrup2]     = useState(kompGuc*0.25);
  const [grup3, setGrup3]     = useState(kompGuc*0.15);
  const [grup4, setGrup4]     = useState(kompGuc*0.5);
  const [grup1ks, setGrup1ks] = useState(3);
  const [grup2ks, setGrup2ks] = useState(3);
  const [grup3ks, setGrup3ks] = useState(3);
  const [grup4ks, setGrup4ks] = useState(3);
  const [bara, setBara] = useState(KompDetaylar.Bara);
  
  const [secilenKomGuc, setSecilenKomGuc] = useState( 555);


  const [iletken, setIletken] = useState({ No: 2, Adet: 1, Etiket: "3x2.5 mm² NYY", Kesit: 2.5 });
  const [kesici, setKesici] = useState(KompDetaylar.Kesici);

  var KondansatorDeger = [0.5, 1, 1.5, 2, 2.5, 5, 7.5, 10, 15, 20, 25, 30, 40, 50, 60, 80, 100];
  let KADEMELER=[]
  function K_Guncelle() {
    var ArcKos1 = Math.acos(cosFi1) * (180 / Math.PI);
    var Tan1 = Math.tan(ArcKos1 * Math.PI / 180);
    var ArcKos2 = Math.acos(cosFi2) * (180 / Math.PI);
    var Tan2 = Math.tan(ArcKos2 * Math.PI / 180);
    var K = Math.abs(Tan2 - Tan1).toFixed(2);

    return K
  }
  function Show() { setToolShow(!toolshow) }
  function KompanzasyonGuncelle() {
    if (kompGuc <= 5000) {
      setIletken({ No:101, Adet: 1, Etiket: "3x2.5 mm² NYY", Kesit: 2.5 })
      setKesici({ No: 0, Etiket: "3x16 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 10000) {
      setIletken({ No: 102, Adet: 1, Etiket: "3x4 mm² NYY", Kesit: 4 })
      setKesici({ No: 8, Etiket: "3x25 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 15000) {
      setIletken({ No: 103, Adet: 1, Etiket: "3x6 mm² NYY", Kesit: 6 })
      setKesici({ No: 24, Etiket: "3x40 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 20000) {
      setIletken({ No: 103, Adet: 1, Etiket: "3x6 mm² NYY", Kesit: 6 })
      setKesici({ No: 40, Etiket: "3x63 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 25000) {
      setIletken({ No: 103, Adet: 1, Etiket: "3x6 mm² NYY", Kesit: 6 })
      setKesici({ No: 56, Etiket: "3x100 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 30000) {
      setIletken({ No: 103, Adet: 1, Etiket: "3x6 mm² NYY", Kesit: 6 })
      setKesici({ No: 56, Etiket: "3x100 A TMŞ" })
      setBara("20x3 mm² Cu")
    } else if (kompGuc <= 40000) {
      setIletken({ No: 104, Adet: 1, Etiket: "3x10 mm² NYY", Kesit: 10 })
      setKesici({ No: 56, Etiket: "3x100 A TMŞ" })
      setBara("25x3 mm² Cu")
    } else if (kompGuc <= 50000) {
      setIletken({ No: 105, Adet: 1, Etiket: "3x16 mm² NYY", Kesit: 16 })
      setKesici({ No:66, Etiket: "3x125 A TMŞ" })
      setBara("25x3 mm² Cu")
    } else if (kompGuc <= 60000) {
      setIletken({ No: 106, Adet: 1, Etiket: "3x25 mm² NYY", Kesit: 25 })
      setKesici({ No:66, Etiket: "3x125 A TMŞ" })
      setBara("25x3 mm² Cu")

    } else if (kompGuc <= 80000) {
      setIletken({ No:107, Adet: 1, Etiket: "3x35 mm² NYY", Kesit: 35 })
      setKesici({ No: 74, Etiket: "3x160 A TMŞ" })
      setBara("25x3 mm² Cu")
    } else if (kompGuc <= 100000) {
      setIletken({ No: 108, Adet: 1, Etiket: "3x50 mm² NYY", Kesit: 50 })
      setKesici({ No: 84, Etiket: "3x200 A TMŞ" })
      setBara("25x3 mm² Cu")

    } else if (kompGuc <= 125000) {
      setIletken({ No:109, Adet: 1, Etiket: "3x70 mm² NYY", Kesit: 70 })
      setKesici({ No: 102, Etiket: "3x250 A TMŞ" })
      setBara("30x5 mm² Cu")
    } else if (kompGuc <= 150000) {
      setIletken({ No: 2, Adet: 1, Etiket: "3x95 mm² NYY", Kesit: 95 })
      setKesici({ No: 2, Etiket: "3x320 A TMŞ" })
      setBara("30x5 mm² Cu")
    } else if (kompGuc <= 200000) {
      setIletken({ No: 2, Adet: 2, Etiket: "2x(3x50) mm² NYY", Kesit: 100 })
      setKesici({ No: 120, Etiket: "3x400 A TMŞ" })
      setBara("30x5 mm² Cu")

    } else if (kompGuc <= 250000) {
      setIletken({ No: 2, Adet: 2, Etiket: "2x(3x70) mm² NYY", Kesit: 140 })
      setKesici({ No: 128, Etiket: "3x400 A TMŞ" })
      setBara("40x5 mm² Cu")
    }
  

  
    gruplarHesap();
    dispatch(UpdateIletken(iletken))
    dispatch(UpdateKesici(kesici))
    dispatch(UpdateKompGuc(kompGuc))
    dispatch(UpdateSecKompGuc(secilenKomGuc))
    dispatch(UpdateKademeSayi(kademeSayi))
    dispatch(UpdateKademeler(KADEMELER))
    dispatch(UpdateBara(bara))
    dispatch(UpdateNHSinir(nHSinir))


  }
  function KompKademeOlustur(Kap) {
    var Kompankademe = { Kondansator: Kap, Sigorta: 0, Kontaktor: 0, iletken: 0,iletkenEtiket:"" };
    var kondSay = KompMalSec.length - 1;
    Object.keys(KompMalSec).forEach(function (a) {
      if (KompMalSec[a].Kond == Kap) {
        Kompankademe.Kondansator    = Kap;
        Kompankademe.Sigorta        = KompMalSec[a].Sigorta;
        Kompankademe.Kontaktor      = KompMalSec[a].Kontaktor;
        Kompankademe.iletken        = KompMalSec[a].iletken;  
        Kompankademe.iletkenNo      = KompMalSec[a].iletkenNo;  
        Kompankademe.iletkenEtiket  = Iletken[KompMalSec[a].iletkenNo].Etiket;  
      }
    });
    return Kompankademe;
  
  }
  function gruplarHesap(){
    let GucGrup1;
    let GucGrup2;
    let GucGrup3;
    let GucGrup4;
    switch (grupSayi) {
      case 1:
        GucGrup1 = kompGuc / 100000 * 100 / grup1ks;
        setGrup1(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup1) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));        
        setSecilenKomGuc((grup1 * grup1ks));
        setKademeSayi(parseInt(grup1ks));
        KADEMELER=[]     
        for(var g1 =0;g1<=grup1ks;g1++){KADEMELER.push(KompKademeOlustur(grup1)) } 
        break;
      case 2:
        GucGrup1 = kompGuc / 100000 * 65 / grup1ks;
        GucGrup2 = kompGuc / 100000 * 35 / grup2ks;
        setGrup1(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup1) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setGrup2(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup2) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setSecilenKomGuc((grup1 * grup1ks + grup2 * grup2ks));
        setKademeSayi(parseInt(grup1ks)+parseInt(grup2ks) );
        KADEMELER=[]     
        for(var g1 =0;g1<=grup1ks;g1++){KADEMELER.push(KompKademeOlustur(grup1)) }        
        for(var g2 =0;g2<=grup2ks;g2++){KADEMELER.push(KompKademeOlustur(grup2)) } 
        break;
      case 3:
        GucGrup1 = kompGuc / 100000 * 60 / grup1ks;
        GucGrup2 = kompGuc / 100000 * 25 / grup2ks;
        GucGrup3 = kompGuc / 100000 * 15 / grup3ks;
        setGrup1(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup1) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setGrup2(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup2) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setGrup3(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup3) < Math.abs(prev - GucGrup3) ? curr : prev);
        }));
        setSecilenKomGuc((grup1 * grup1ks + grup2 * grup2ks + grup3 * grup3ks ));
        setKademeSayi(parseInt(grup1ks)+parseInt(grup2ks)+parseInt(grup3ks) )
        KADEMELER=[]     
        for(var g1 =0;g1<=grup1ks;g1++){KADEMELER.push(KompKademeOlustur(grup1)) }        
        for(var g2 =0;g2<=grup2ks;g2++){KADEMELER.push(KompKademeOlustur(grup2)) }        
        for(var g3 =0;g3<=grup3ks;g3++){KADEMELER.push(KompKademeOlustur(grup3)) }  
       break;
      case 4:
        GucGrup1 = kompGuc / 100000 * 55 / grup1ks;
        GucGrup2 = kompGuc / 100000 * 25 / grup2ks;
        GucGrup3 = kompGuc / 100000 * 15 / grup3ks;
        GucGrup4 = kompGuc / 100000 * 5 / grup4ks;
        setGrup1(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup1) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setGrup2(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup2) < Math.abs(prev - GucGrup1) ? curr : prev);
        }));
        setGrup3(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup3) < Math.abs(prev - GucGrup3) ? curr : prev);
        }));
        setGrup4(KondansatorDeger.reduce((prev, curr) => {
          return (Math.abs(curr - GucGrup4) < Math.abs(prev - GucGrup4) ? curr : prev);
        })); 
        setSecilenKomGuc((grup1 * grup1ks + grup2 * grup2ks + grup3 * grup3ks + grup4 * grup4ks));
        setKademeSayi(parseInt(grup1ks)+parseInt(grup2ks)+parseInt(grup3ks)+parseInt(grup4ks))
        KADEMELER=[]     
        for(var g1 =0;g1<=grup1ks-1;g1++){KADEMELER.push(KompKademeOlustur(grup1)) }        
        for(var g2 =0;g2<=grup2ks-1;g2++){KADEMELER.push(KompKademeOlustur(grup2)) }        
        for(var g3 =0;g3<=grup3ks-1;g3++){KADEMELER.push(KompKademeOlustur(grup3)) }        
        for(var g4 =0;g4<=grup4ks-1;g4++){KADEMELER.push(KompKademeOlustur(grup4)) }
        break;
      default:
        
    }
  }
  useEffect(() => {
    setK(K_Guncelle())
    dispatch(UpdateCosFi1(cosFi1));
    dispatch(UpdateCosFi2(cosFi2));
  }, [cosFi1, cosFi2])

  useEffect(() => {
    setTalepGuc(trafoDetaylar.talepGuc)
    setKomGuc(parseInt(talepGuc*k))
    KompanzasyonGuncelle()
    
  }, [k, trafoDetaylar])
  useEffect(() => {    
    gruplarHesap()
   
  }, [kompGuc])

  useEffect(() => {    
    KompanzasyonGuncelle()   
  }, [grup1ks, grup2ks, grup3ks, grup4ks,grupSayi])
  
  useEffect(() => {
    KompanzasyonGuncelle() 
  }, [])

  useEffect(() => {

  }, [kademeSayi])


  useEffect(() => {   
    dispatch(UpdateKompVarlik(kompVarlik))
  }, [kompVarlik])
   
  useEffect(() => {   
    dispatch(UpdateNHSinir(nHSinir))
  }, [nHSinir])
   




  return (

    <React.Fragment >
      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Kompanzasyon Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>
        <tr>
              <td>
                  Kompanzasyon
              </td>
              <td>
              <Form.Select aria-label="example" defaultValue={KompDetaylar.KompVarlik} onChange={(e)=>setKompVarlik(e.target.value)} > 
                      <option value={true}>Var</option> 
                      <option value={false}>Yok</option> 
                  </Form.Select>
              </td>
          </tr>
          {kompVarlik==="true"||kompVarlik===true?          
            <tr>
            <td>
              Talep Güç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {trafoDetaylar.talepGuc + "kW"}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true? <tr>
            <td>
              Cos Fi1
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="0.94" min="0.50"
                placeholder={cosFi1} step="0.01"
                onChange={(e) => setCosFi1(e.target.value)} />
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true? <tr>
            <td>
              Cos Fi2
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="0.99" min="0.70"
                placeholder={cosFi2} step="0.01"
                onChange={(e) => setCosFi2(e.target.value)}
              />
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?   <tr>
            <td>
              K Faktörü
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {k}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?  <tr>
            <td>
              Kompanzasyon Güç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {kompGuc + "kVA"}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true? 
          <tr>
            <td>
              Seçilen Komp. Güç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {secilenKomGuc + "VA"}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true? 
           <tr>
            <td>
              Kademe Sayisi
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {kademeSayi}

            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true? 
          <tr> 
            <td>
              Komp. İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {iletken.Etiket}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?  
          <tr>
            <td>
              Komp. Kesici
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {kesici.Etiket}
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?  
          <tr>
            <td>
              NH sigorta Sınır 
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={nHSinir} onChange={(e) => setNHSinir(e.target.value)}   >
                <option value={6}>6</option>
                <option value={10}>10</option>
                <option value={16}>16</option>
                <option value={25}>25</option>
                <option value={32}>32</option>
                <option value={40}>40</option>
                <option value={50}>50</option>
                <option value={63}>63</option>
                <option value={80}>80</option>
                <option value={100}>100</option>
                <option value={125}>125</option>
                <option value={160}>160</option>
               </Form.Select>
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?  <tr>
            <td>
              Grup Sayısı
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <Form.Select aria-label="example" defaultValue={4} onChange={(e) => setGrupSayi(e.target.value)}   >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
               </Form.Select>
            </td>
          </tr>:null}
          {kompVarlik==="true"||kompVarlik===true?  <tr>
            <td>
              Grup1     &nbsp; &nbsp; &nbsp; {grup1ks}x{grup1}  kVA
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="5" min="1"
                placeholder={grup1ks} step="1"
                onChange={(e) => setGrup1ks(e.target.value)} />
            </td>
          </tr>:null}
          {(kompVarlik==="true"||kompVarlik===true)&& 
          grupSayi>1?
          <tr>
            <td>
              Grup2     &nbsp; &nbsp; &nbsp; {grup2ks}x{grup2}  kVA
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="5" min="1"
                placeholder={grup2ks} step="1"
                onChange={(e) => setGrup2ks(e.target.value)} />
            </td>
          </tr> :null}
            
          {kompVarlik==="true"&& grupSayi>2?
          <tr>
            <td>
              Grup3     &nbsp; &nbsp; &nbsp; {grup3ks}x{grup3}  kVA
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="5" min="1"
                placeholder={grup3ks} step="1"
                onChange={(e) => setGrup3ks(e.target.value)} />
            </td>
          </tr> :null} 
          {kompVarlik==="true"&& grupSayi>3?
          <tr>
            <td>
              Grup4     &nbsp; &nbsp; &nbsp; {grup4ks}x{grup4}  kVA
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="5" min="1"
                placeholder={grup4ks} step="1"
                onChange={(e) => setGrup4ks(e.target.value)} />
            </td>
          </tr>:null}

        </tbody>
      </Table>




    </React.Fragment>





  )

}
