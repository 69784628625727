
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateSecim } from '../reducers/cizimOzellik'
import { UpdatePDolcuGoster } from '../reducers/PanoDetaySlice'

import { AkimTrafo3adetView, UcFazsayacView, Kompakt4PView1, Kompakt4PView2, Kompakt4PView3, Kompakt4PView4, Kompakt3PView1, Kompakt3PView2, Kompakt3PView3, Kompakt3PView4, AOS1PView, AOS2PView, AOS3PView, AOS4PView, AOS5PView, Kompakt1PView1, Kompakt1PView2, Kompakt1PView3, Kompakt1PView4, panoKabloGiris, NH3PView0, NH3PView2, NH1PView0, NH1PView1, NH1PView2, RGKRView, KontoktorView, olcu } from '../Data/testCizimler'
import { EMO_KOMPAKT, KAKKOMPAKT, TMS } from '../Data/Malzeme'

let denemeCizim = []

export default function PanoDetay(props) {
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const kompDetaylar = useSelector((state) => state.KompDetay)
    const panoDetaylar = useSelector((state) => state.PanoDetay)
    const dispatch = useDispatch()
    
    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }

    let olcuGoster = panoDetaylar.PDolcuGoster;
    let olcuRenk = panoDetaylar.PDolcuRenk;
    let cerceveRenk = panoDetaylar.PDcerceveRenk;
    let textRenk = panoDetaylar.PDtextRenk;
    let panoRenk = panoDetaylar.PDpanoRenk;
    let sayacRenk = panoDetaylar.PDsayacRenk;
    let kesiciRenk = panoDetaylar.PDkesiciRenk;
    let kesiciTextRenk = panoDetaylar.PDkesiciTextRenk;
    let kesiciTextGoster = panoDetaylar.PDkesiciTextGoster;
    let kontaktorRenk = panoDetaylar.PDkontaktorRenk;
    let RGKRoleRenk = panoDetaylar.PDRGKRoleRenk;
    let aosRenk = panoDetaylar.PDaosRenk;
    let aosTextRenk = panoDetaylar.PDaosTextRenk;
    let aosTextGoster = panoDetaylar.PDaosTextGoster;
    let akimTrafoRenk = panoDetaylar.PDakimTrafoRenk;
    let akimTrfTextRenk = panoDetaylar.PDakimTrfTextRenk;
    let akimTrfTextGoster = panoDetaylar.PDakimTrfTextGoster;
    let baraRenk = panoDetaylar.PDbaraRenk;
    let mesafeRenk = panoDetaylar.PDmesafeRenk;
    let klavuzRenk = panoDetaylar.PDklavuzRenk;

    let Scl = cizimOzellik.Scl;
    let Scl2 = 4
    Scl *= Scl2
    let cerW=0
    let cerH=0
    let DagW = 0
    let DagMinW = 45 * Scl
    let KompW = 45
    let KompMinW = 45 * Scl
    let KompMaxW = 145 * Scl
    let KompH = 0
    let DagH = 0
    let BaraH = 25 * Scl
    let KompKondBatH = 30 * Scl
    let KesPakNo = 3;
    let OlcuDisBoy = 0
    let AOSPakEn = [0, 17.8, 35.6, 53.4, 71.2, 89]
    let AOSBoy = 80.6 * Scl
    const [bgcolor, setBgcolor] = useState("white");
    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 800 * Scl, h: 555 * Scl }, cizim: denemeCizim })
     function artirW( ) {
        setCizim(prmt => ({
            ...prmt,
            attr: {
                x: 0, y: 0,
                w: cerW,
                h: 500 * Scl,
            },
            cizim: Pano1(0, 0, Scl)
        }));
    }
    useEffect(() => {
        artirW()
    }, [cizimOzellik, panoDetaylar, kompDetaylar]);

    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);

    function Sec(val, val2, val3) {
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }



    function dortgenEkle(X, Y, W, H, Scl, renk) {
        return [{ Ad: "Polyline", layer: "TekHatSema", Color: renk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 5, PolyLineFlag: "     1", Noktalar: [X + Scl * (0), Y + Scl * (0), X + Scl * (W), Y + Scl * (0), X + Scl * (W), Y + Scl * (H), X + Scl * (0), Y + Scl * (H)] }]
    }

if(panoDetaylar.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
            KompW = 45
        }else{KompW=0 }
function Pano1(X, Y, Scl) {
        let panoTip = "Ölçüm+Dağıtım"
        let cikislar = panoDetaylar.Cikislar
        let cikislarTur = panoDetaylar.CikislarTur
        let AkimTrafoOran = panoDetaylar.AkimTrafoOran;
        let resume = []
        //let KesPakNo=3;
        let Kes3PPakEn = [0, 7.4, 9, 9, 13.8]
        let Kes4PPakEn = [0, 9.8, 12, 12, 18]
        let Kes1PPakEn = [0, 1.37, 1.53, 1.5, 2.3]
        let KesPakBoy = [0, 12, 15, 18, 26]
        let icAralik = 5 * Scl
        let citaAralik = 2 * Scl
        let dagitimBoy = 0;
        let Kesici1Amperaj = 0
        let Kesici1Kutup = ""

        if (panoDetaylar.Kesici1Tur === "Kompakt1") {
            Kesici1Amperaj = EMO_KOMPAKT[panoDetaylar.Kesici1].Akim
            Kesici1Kutup = EMO_KOMPAKT[panoDetaylar.Kesici1].Kutup
        } else if (panoDetaylar.Kesici1Tur === "KAKKompakt1") {
            Kesici1Amperaj = KAKKOMPAKT[panoDetaylar.Kesici1].Akim
            Kesici1Kutup = KAKKOMPAKT[panoDetaylar.Kesici1].Kutup
        }

        if (Kesici1Amperaj <= 125) {
            KesPakNo = 1
        } else if (Kesici1Amperaj > 125 && Kesici1Amperaj <= 200) {
            KesPakNo = 2
        } else if (Kesici1Amperaj > 200 && Kesici1Amperaj <= 400) {
            KesPakNo = 3
        } else if (Kesici1Amperaj > 400 && Kesici1Amperaj <= 630) {
            KesPakNo = 4
        }
        let icEn = icAralik * 3 + Kes4PPakEn[KesPakNo] * Scl + 18 * Scl;
        let icBoy = 60 * Scl;


        let disEn = icEn + citaAralik * 2
        OlcuDisBoy = icBoy + citaAralik * 2 + dagitimBoy
        let ofsetX = 50 * Scl;
        let ofsetY = 60 * Scl;
        let panoX = ofsetX;
        let panoY = ofsetY * 2
        let catiEn = disEn + citaAralik * 2
        let catiBoy = 20 * Scl
        let OlcKlavuzBoy1 = 10 * Scl
        let OlcKlavuzBoy2 = 25 * Scl
        cerW = ofsetX + panoX + catiEn + OlcKlavuzBoy2 * 2
        let Kompakt1 = [];

        let kompakt1X = 0

        if (Kesici1Kutup === "3P") {
            kompakt1X = panoX + citaAralik + (DagW - 18 * Scl - icAralik) / 2 - Kes3PPakEn[KesPakNo] / 2 * Scl

            switch (KesPakNo) {
                case 1:
                    Kompakt1 = Kompakt3PView1(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 2:
                    Kompakt1 = Kompakt3PView2(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 3:
                    Kompakt1 = Kompakt3PView3(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 4:
                    Kompakt1 = Kompakt3PView4(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                default:
            }
        } else if (Kesici1Kutup === "4P") {
            kompakt1X = panoX + citaAralik + (DagW - 18 * Scl - icAralik) / 2 - Kes4PPakEn[KesPakNo] / 2 * Scl

            switch (KesPakNo) {
                case 1:
                    Kompakt1 = Kompakt4PView1(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 2:
                    Kompakt1 = Kompakt4PView2(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 3:
                    Kompakt1 = Kompakt4PView3(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                case 4:
                    Kompakt1 = Kompakt4PView4(kompakt1X, panoY + OlcuDisBoy / 2 - KesPakBoy[KesPakNo] / 2 - icAralik, 0.1 * Scl, Kesici1Amperaj + "A", kesiciRenk, kesiciTextRenk)
                    break;
                default:
            }
        }
  

        resume = resume.concat([{ Ad: "Text", layer: "TekHatSema", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (0.00), y: Y + Scl * (20.00), TextH: Scl * 22, TextString: "PANO DETAYLARI", TextStyleName: "times", TextArc: 0, width: cerW, align: "center" }]) //Cerceve 

        let AkimTrf3lu = AkimTrafo3adetView(kompakt1X, panoY + OlcuDisBoy / 2 + KesPakBoy[KesPakNo] * Scl, 0.1, AkimTrafoOran, akimTrafoRenk, akimTrfTextRenk)
        if(panoDetaylar.PanoTuru==="Ölçüm+Dağıtım"||panoDetaylar.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
        resume = resume.concat(dagitimBolumu(panoX, panoY, icBoy, citaAralik, Scl, cikislar))//panoX,panoY+icBoy+citaAralik*3,Scl,cikislar  
        resume = resume.concat(dortgenEkle(panoX, panoY + DagH + citaAralik * 2 + icBoy, DagW + citaAralik * 2, BaraH, 1, panoRenk)) // Bara Bölümü dış
        resume = resume.concat(dortgenEkle(panoX + citaAralik, panoY + DagH + citaAralik * 3 + icBoy, DagW, BaraH - 2 * citaAralik, 1, panoRenk)) // Bara Bölümü iç
        resume = resume.concat(dortgenEkle(panoX + citaAralik * 2, panoY + DagH + citaAralik * 5 + icBoy, DagW - 2 * citaAralik, 5, 1, baraRenk)) // Bara   

        cerW = DagW + KompW + citaAralik * 4 + OlcKlavuzBoy2 * 2 + ofsetX
        cerH = ofsetY + panoY + DagH + OlcuDisBoy + BaraH + catiBoy
        resume = resume.concat(dortgenEkle(X, Y, cerW, cerH, 1, cerceveRenk)) //Cerceve 

        }else{
            DagW=80
        }

        resume = resume.concat(dortgenEkle(panoX, panoY, DagW + citaAralik * 2, OlcuDisBoy, 1, panoRenk)) //dış 
        resume = resume.concat(dortgenEkle(panoX + citaAralik, panoY + citaAralik, DagW
            , icBoy, 1, panoRenk)) // iç
        resume = resume.concat(dortgenEkle(panoX - citaAralik, panoY - catiBoy, DagW + citaAralik * 4 + KompW, catiBoy, 1, panoRenk)) // Cati
            if(panoDetaylar.Sayac!=="yok"){
        resume = resume.concat(UcFazsayacView(panoX + DagW + citaAralik - 18 * Scl - icAralik, panoY + citaAralik + icAralik * 2, Scl)) // Sayaç 
            }
        resume = resume.concat(Kompakt1) // C 
        resume = resume.concat(AkimTrf3lu) // C  
        resume = resume.concat(panoKabloGiris(panoX, panoY + 22, Scl, panoRenk))
            if(panoDetaylar.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
        resume = resume.concat(kompBolumu(panoX + DagW + 2 * citaAralik, panoY, Scl, "Kademeler"))
            }
        //-----------------------------------------------------------------------
        //------------------------ ÖLÇÜLER --------------------------------------
        if (olcuGoster === true) {
            resume = resume.concat(olcu(panoX - citaAralik, panoY - catiBoy, Scl, (DagW + citaAralik * 4 + KompW).toFixed(2), 3, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));// üst

            resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW, panoY + OlcuDisBoy + DagH + BaraH, Scl, (DagW + 2 * citaAralik).toFixed(2), 9, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//alt 

            if(panoDetaylar.PanoTuru==="Ölçüm+Dağıtım"||panoDetaylar.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
            resume = resume.concat(olcu(panoX, panoY + DagH + OlcuDisBoy, Scl, (DagH).toFixed(2), 12, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sol
            resume = resume.concat(olcu(panoX, panoY + DagH + OlcuDisBoy + BaraH, Scl, (BaraH).toFixed(2), 12, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sol
            }
            
            resume = resume.concat(olcu(panoX, panoY + OlcuDisBoy, Scl, (OlcuDisBoy).toFixed(2), 12, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sol
            
            if(panoDetaylar.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
                resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW + KompW, panoY - catiBoy, Scl, (KompH + catiBoy).toFixed(2), 6, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sağ     
                resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW + KompW, panoY, Scl, (KompH - KompKondBatH - citaAralik).toFixed(2), 6, OlcKlavuzBoy1, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sağ     
                resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW + KompW, panoY + KompH - KompKondBatH - citaAralik, Scl, (KompKondBatH).toFixed(2), 6, OlcKlavuzBoy1, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sağ     
                resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW + KompW, panoY - catiBoy, Scl, (catiBoy).toFixed(2), 6, OlcKlavuzBoy1, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//sağ
            resume = resume.concat(olcu(panoX + 2 * citaAralik + DagW + KompW, panoY + OlcuDisBoy + DagH + BaraH, Scl, (KompW).toFixed(2), 9, OlcKlavuzBoy2, 5, 5, "cm", 5, 2, mesafeRenk, klavuzRenk));//alt

            }
        }
        return resume
    }

    function dagitimBolumu(X, Y, icBoy, citaAralik, Scl, cikislar) {
        let Kes3PPakEn = [0, 7.5, 9, 9, 13.8]
        let Kes4PPakEn = [0, 9.8, 12, 12, 18]
        let Kes1PPakEn = [0, 2.745, 1.53, 1.5, 2.3]
        Y += (icBoy + citaAralik * 2)
        let KesPakBoy = [0, 12, 15, 18, 26]
        let NHKesPak1PBoy = [0, 12, 20, 22.5, 23.2]
        let NHKesPak3PBoy = [0, 18, 20.2, 41.2]
        let NHKes1PPakEn = [0, 3.5, 5.9, 5.9, 7.5]
        let NHKes3PPakEn = [0, 0, 11, 18.5, 25.2]
        let NHKesP1sayi = 0;
        let NHKesP2sayi = 0;
        let NHKesP3sayi = 0;
        let NHKesP4sayi = 0;
        let KesP1sayi = 0;
        let KesP2sayi = 0;
        let KesP3sayi = 0;
        let KesP4sayi = 0;
        let KesP5sayi = 0;
        let DagSatSay = 1
        let DagSatAra = 20 * Scl
        let NHKesP1 = []
        let NHKesP2 = []
        let NHKesP3 = []
        let NHKesP4 = []
        let KesP1 = []
        let KesP2 = []
        let KesP3 = []
        let KesP4 = []
        let KesP5 = []
        let NHsatir1 = 0;
        let NHsatir2 = 0;
        let NHsatir3 = 0;
        let NHsatir4 = 0;
        let satir1 = 0;
        let satir2 = 0;
        let satir3 = 0;
        let satir4 = 0;
        let satir5 = 0;
        let icAralik = 10 * Scl
        let Kesici2Amperaj = EMO_KOMPAKT[panoDetaylar.Kesici2].Akim
        let KompKesAmperaj = TMS[kompDetaylar.Kesici.No].Akim

        let icW = icAralik * 2;
        let icH = icAralik * 2;
        let dagMaxW = 45 * Scl;
        let dagMaxWdurum = false

        let resume = [];
        let dagX = X
        let dagY = Y

        if (kompDetaylar.KompVarlik === "true") {
            KesP5sayi++;
            KesP5.push(TMS[kompDetaylar.Kesici.No]);
            if (panoDetaylar.AnaKesici === "Kesici+Sayaç") {
                icH += KesPakBoy[1];
            }
        }
        if (panoDetaylar.AnaKesici === "Kesici+Sayaç+Kesici") {
            KesP5sayi++;
            KesP5.push(EMO_KOMPAKT[panoDetaylar.Kesici2]);
            icH += KesPakBoy[1];
        }
        cikislar.map((cikis, c) => {
            if ((cikis.KesiciOb.Tur === "TMŞ") || (cikis.KesiciOb.Tur === "KOMPAKT")) {
                if (cikis.KesiciOb.Akim <= 125) {
                    KesP1sayi++
                    KesP1.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 125 && cikis.KesiciOb.Akim <= 200) {
                    KesP2sayi++
                    KesP2.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 200 && cikis.KesiciOb.Akim <= 400) {
                    KesP3sayi++
                    KesP3.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 400 && cikis.KesiciOb.Akim <= 630) {
                    KesP4sayi++
                    KesP4.push(cikis.KesiciOb)
                }
            } else if (cikis.KesiciOb.Tur === "AOS") {
                KesP1sayi++
                KesP1.push(cikis.KesiciOb)
            } else if (cikis.KesiciOb.Tur === "NH") {
                if (cikis.KesiciOb.Akim <= 160) {
                    NHKesP1sayi++
                    NHKesP1.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 160 && cikis.KesiciOb.Akim <= 250) {
                    NHKesP2sayi++
                    NHKesP2.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 250 && cikis.KesiciOb.Akim <= 400) {
                    NHKesP3sayi++
                    NHKesP3.push(cikis.KesiciOb)
                } else if (cikis.KesiciOb.Akim > 400 && cikis.KesiciOb.Akim <= 630) {
                    NHKesP4sayi++
                    NHKesP4.push(cikis.KesiciOb)
                }
            }
        }
        )

        if (KesP5sayi > 0) {
            let kX = 0
            satir5++
            KesP5.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[4] + icAralik;
                    satir5++
                }
                let kes = []
                let kesiciAralik = 6
                switch (KesPakNo) {
                    case 1:
                        switch (kesici.Tur) {
                            case "TMŞ":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            case "KOMPAKT":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            default:
                        }
                        break;
                    case 2:
                        switch (kesici.Tur) {
                            case "TMŞ":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            case "KOMPAKT":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            default:
                        }
                        break;
                    case 3:
                        switch (kesici.Tur) {
                            case "TMŞ":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView3(dagX + icAralik + kX * Scl * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView3(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            case "KOMPAKT":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            default:
                        }
                        break;
                    case 4:
                        switch (kesici.Tur) {
                            case "TMŞ":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            case "KOMPAKT":
                                if (kesici.Kutup === "3P") {
                                    kes = Kompakt3PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes3PPakEn[4] + kesiciAralik
                                } else if (kesici.Kutup === "4P") {
                                    kes = Kompakt4PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                                    kX += Kes4PPakEn[4] + kesiciAralik
                                }
                                break;
                            default:
                        }
                        break;
                    default:
                }

                if ((icW + kX * Scl > icW) && (dagMaxWdurum === false)) { icW = kX * Scl + icAralik * 2 }
                resume = resume.concat(kes)
            })
            dagY += KesPakBoy[KesPakNo] * Scl + DagSatAra
        }
        if (KesP4sayi > 0) {
            let kX = 0
            satir4++
            KesP4.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[4] * Scl + icAralik;
                    satir4++
                }
                let kes = []
                let kesiciAralik = 6
                switch (kesici.Tur) {
                    case "TMŞ":
                        if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[4] + kesiciAralik
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[4] + kesiciAralik
                        }
                        break;
                    case "KOMPAKT":
                        if (kesici.Kutup === "1P") {
                            kes = Kompakt1PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes1PPakEn[4] + kesiciAralik
                        } else if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[4] + kesiciAralik
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView4(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[4] + kesiciAralik
                        }
                        break;
                    case "NH":
                        kes = NH1PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 2 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                        break;
                    default:
                }
                if ((icW + kX * Scl > icW) && (dagMaxWdurum === false)) { icW = kX * Scl + icAralik * 2 }
                resume = resume.concat(kes)
            })
            dagY += KesPakBoy[4] + DagSatAra
        }
        if (KesP3sayi > 0) {
            let kX = 0
            satir3++
            KesP3.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[3] * Scl + icAralik;
                    satir3++
                }
                let kes = []
                switch (kesici.Tur) {
                    case "TMŞ":
                        if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[3] + 1
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[3] + 1
                        }
                        break;
                    case "KOMPAKT":
                        if (kesici.Kutup === "1P") {
                            kes = Kompakt1PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes1PPakEn[3] + 1
                        } else if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[3] + 1
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView3(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[3] + 1
                        }
                        break;
                    case "NH":
                        kes = NH1PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 2, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                        break;
                    default:
                }
                if ((icW + kX * Scl > icW) && (dagMaxWdurum === false)) { icW = kX * Scl + icAralik * 2 }
                resume = resume.concat(kes)
            })


            dagY += KesPakBoy[3] * Scl + DagSatAra
        }
        if (KesP2sayi > 0) {
            let kX = 0
            //icH+=KesPakBoy[2] 
            satir2++
            KesP2.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[2] * Scl + icAralik;
                    //icH+=KesPakBoy[2]+DagSatAra;  
                    satir2++
                }
                let kes = []
                switch (kesici.Tur) {
                    case "TMŞ":
                        if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[2] + 1
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[2] + 1
                        }
                        break;
                    case "KOMPAKT":
                        if (kesici.Kutup === "1P") {
                            kes = Kompakt1PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes1PPakEn[2] + 1
                        } else if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[2] + 1
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView2(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[2] + 1
                        }
                        break;
                    case "NH":
                        kes = NH1PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 2 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                        break;
                    default:
                }
                if ((icW + kX * Scl > icW) && (dagMaxWdurum === false)) { icW = kX * Scl + icAralik * 2 }
                resume = resume.concat(kes)
            })

            dagY += KesPakBoy[2] * Scl + DagSatAra

        }
        if (KesP1sayi > 0) {
            let kX = 0
            satir1++
            KesP1.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[1] * Scl + icAralik;
                    satir1++
                }
                let kes = []
                let grupY = (KesPakBoy[1] - AOSBoy * 0.1) / 2
                switch (kesici.Tur) {
                    case "TMŞ":
                        if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[1] + 2
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[1] + 2
                        }
                        break;
                    case "KOMPAKT":
                        if (kesici.Kutup === "1P") {
                            kes = Kompakt1PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes1PPakEn[1]
                        } else if (kesici.Kutup === "3P") {
                            kes = Kompakt3PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes3PPakEn[1]
                        } else if (kesici.Kutup === "4P") {
                            kes = Kompakt4PView1(dagX + icAralik + kX * Scl, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                            kX += Kes4PPakEn[1]
                        }

                        break;
                    case "AOS":
                        if (kesici.Kutup === "1P") {
                            kes = AOS1PView(dagX + icAralik + kX * Scl, dagY + DagSatAra + grupY, 0.1 * Scl, kesici.Akim + "A", aosRenk, aosTextRenk);
                            kX += AOSPakEn[1] * 0.1 + 0.1
                        } else if (kesici.Kutup === "2P") {
                            kes = AOS2PView(dagX + icAralik + kX * Scl, dagY + DagSatAra + grupY, 0.1 * Scl, kesici.Akim + "A", aosRenk, aosTextRenk);
                            kX += AOSPakEn[2] * 0.1 + 0.1
                        } else if (kesici.Kutup === "3P") {
                            kes = AOS3PView(dagX + icAralik + kX * Scl, dagY + DagSatAra + grupY, 0.1 * Scl, kesici.Akim + "A", aosRenk, aosTextRenk);
                            kX += AOSPakEn[3] * 0.1 + 0.1
                        } else if (kesici.Kutup === "4P") {
                            kes = AOS4PView(dagX + icAralik + kX * Scl, dagY + DagSatAra + grupY, 0.1 * Scl, kesici.Akim + "A", aosRenk, aosTextRenk);
                            kX += AOSPakEn[4] * 0.1 + 0.1
                        } else if (kesici.Kutup === "5P") {
                            kes = AOS5PView(dagX + icAralik + kX * Scl, dagY + DagSatAra + grupY, 0.1 * Scl, kesici.Akim + "A", aosRenk, aosTextRenk);
                            kX += AOSPakEn[5] * 0.1 + 0.1
                        }
                        break;
                    default:
                }
                if ((icW + kX * Scl > icW) && (dagMaxWdurum === false)) { icW = kX * Scl + icAralik * 2 }
                resume = resume.concat(kes)
            })
            dagY += KesPakBoy[2] + DagSatAra

        }
        if (NHKesP3sayi > 0) {
            let kX = 0
            NHsatir3++
            NHKesP3.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[3] * Scl + icAralik;
                    NHsatir3++
                }
                let kes = []
                if (kesici.Kutup === "1P") {
                    kes = NH1PView2(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes1PPakEn[3] + 0.1
                } else if (kesici.Kutup === "3P") {
                    kes = NH3PView2(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes3PPakEn[3] + 0.1
                }
                if ((icW + kX > icW) && (dagMaxWdurum === false)) { icW = kX + icAralik * 2 }
                resume = resume.concat(kes)
            })
        }
        if (NHKesP2sayi > 0) {
            let kX = 0
            NHsatir2++
            NHKesP2.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[2] * Scl + icAralik;
                    NHsatir2++
                }
                let kes = []
                if (kesici.Kutup === "1P") {
                    kes = NH1PView2(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes1PPakEn[2] + 0.1
                } else if (kesici.Kutup === "3P") {
                    kes = NH3PView2(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes3PPakEn[2] + 0.1
                }
                if ((icW + kX > icW) && (dagMaxWdurum === false)) { icW = kX + icAralik * 2 }
                resume = resume.concat(kes)
            })
        }
        if (NHKesP1sayi > 0) {
            let kX = 0
            NHsatir1++
            NHKesP1.map((kesici, k) => {
                if (kX > dagMaxW) {
                    dagMaxWdurum = true
                    kX = 0;
                    dagY += KesPakBoy[1] * Scl + icAralik;
                    NHsatir1++
                }
                let kes = []
                if (kesici.Kutup === "1P") {
                    let NH_Y = dagY + DagSatAra + (NHKesPak3PBoy[1] - NHKesPak1PBoy[1]) / 2
                    kes = NH1PView0(dagX + icAralik + kX, NH_Y, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes1PPakEn[1] + 0.1
                }
                else if (kesici.Kutup === "3P") {
                    kes = NH3PView0(dagX + icAralik + kX, dagY + DagSatAra, 0.1 * Scl, kesici.Akim + "A", kesiciRenk, kesiciTextRenk);
                    kX += NHKes3PPakEn[2] + 0.1
                }

                if ((icW + kX > icW) && (dagMaxWdurum === false)) { icW = kX + icAralik * 2 }
                resume = resume.concat(kes)
            })
        }

        icH = (NHsatir1 * NHKesPak3PBoy[1] + NHsatir2 * NHKesPak3PBoy[2] + NHsatir3 * NHKesPak3PBoy[3] + satir1 * KesPakBoy[1] + satir2 * KesPakBoy[2] + satir3 * KesPakBoy[3] + satir4 * KesPakBoy[4] + satir5 * KesPakBoy[KesPakNo] + (NHsatir1 + NHsatir2 + NHsatir3 + satir1 + satir2 + satir3 + satir4 + satir5 + 0.5) * icAralik) * Scl
        
        if (DagMinW < icW) {
            DagW = icW
        } else {
            DagW = DagMinW
        }

        DagH = icH + citaAralik * 2
        resume = resume.concat(dortgenEkle(X + citaAralik, Y + citaAralik, DagW, icH, 1, panoRenk)) //iç 
        resume = resume.concat(dortgenEkle(X, Y, DagW + citaAralik * 2, icH + citaAralik * 2, 1, panoRenk)) //dış 

        return resume

    }
    function kompBolumu(X, Y, Scl, Kademeler) {
        let kademeler = kompDetaylar.Kademeler
        let resume = [];
        let KontW = 60 * Scl
        let KontH = 82 * Scl
        KompH = DagH + OlcuDisBoy + BaraH
        let RgkrW = 15 * Scl
        let icAralik = 10 * Scl

        let citaAralik = 2 * Scl;
        let KompH1 = Y + icAralik * 2 + RgkrW
        let kadX = X + citaAralik * 1
        let kadY = Y + icAralik * 2 + RgkrW
        KompW = KompMinW
        let KadTopH = KompH - (icAralik * 2 + RgkrW + KompKondBatH + citaAralik)
        let KadTopW = KompW - 2 * citaAralik
        let kh = (AOSBoy + KontH) * 0.1 + icAralik * 2
        let kw = ((KontW + 1) * 0.1)
        let KademeSay = kademeler.length
        let kadTopWTeksatir = (KademeSay * (KontW + 1) * 0.1)

        let satir = Math.ceil(KadTopH / kh)
        let SatirKadSay = Math.ceil(KademeSay / satir)



        if ((KadTopH >= 36.26 * 2) && (KadTopH < 36.26 * 3)) {
            satir = 2
        } else if ((KadTopH >= 36.26 * 3) && (KadTopH < 36.26 * 4)) {
            satir = 3
        } else if ((KadTopH >= 36.26 * 4) && (KadTopH < 36.26 * 5)) {
            satir = 4
        }
        SatirKadSay = Math.ceil(KademeSay / satir)
        if (SatirKadSay * kw + 3 * citaAralik > KompW) { KompW = SatirKadSay * kw + 3 * citaAralik }


        resume = resume.concat(dortgenEkle(X, Y, KompW, KompH, 1, panoRenk)) // diş çerçeve
        resume = resume.concat(dortgenEkle(X + citaAralik, Y + citaAralik, KompW - 2 * citaAralik, KompH - 2 * citaAralik, 1, panoRenk)) // iç çerçeve
        resume = resume.concat(RGKRView(X + KompW - RgkrW - icAralik, Y + icAralik, 0.1 * Scl, RGKRoleRenk)) // iç çerçeve

        kademeler.map((kademe, k) => {
            resume = resume.concat(AOS3PView(kadX + (KontW - AOSPakEn[3]) * 0.1 / 2, kadY, 0.1 * Scl, "AOS", aosRenk, aosTextRenk))
            resume = resume.concat(KontoktorView(kadX + citaAralik, kadY + AOSBoy * 0.1 + icAralik, 0.1 * Scl, kontaktorRenk))

            kadX += kw
            if (kadX - X + kw > KompW - 2 * citaAralik) {
                kadX = X + citaAralik * 1;
                kadY += (AOSBoy + KontH) * 0.1 + icAralik * 2
            }
        })
        resume = resume.concat(dortgenEkle(X + citaAralik, Y + KompH - KompKondBatH - citaAralik, KompW - 2 * citaAralik, KompKondBatH, 1, panoRenk)) // kondansatör çerçeve
        resume = resume.concat([{ Ad: "Text", layer: "TekHatSema", Color: panoRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (2.00), y: Y + KompH - KompKondBatH - citaAralik + Scl * 10, TextH: Scl * 10, TextString: "KOND.", TextStyleName: "times", TextArc: 0 }])


        return resume
    }

    return (

        <React.Fragment>


            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
                style={{ width: "98%", background: bgcolor }}
            >
                <thead>
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={Show}> PANO DETAYI GÖRSEL AYARLARI</th>
                    </tr>
                </thead>
                <tbody style={toolshow ? { display: "block", overflowY: "scroll", height: "250px" } : { display: "none" }}>

                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }}>Çerçeve<Button size="sm" style={{ width: "30px", height: "20px", background: cerceveRenk, float: "right" }} onClick={() => Sec("PDcerceveRenk", "Çerçeve Rengi", cerceveRenk)}></Button></td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }} >Başlık<Button size="sm" style={{ width: "30px", height: "20px", background: textRenk, float: "right" }} onClick={() => Sec("PDtextRenk", "Başlık", textRenk)}></Button></td><td></td></tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Pano<Button size="sm" style={{ width: "30px", height: "20px", background: panoRenk, float: "right" }} onClick={() => Sec("PDpanoRenk", "Pano Rengi", panoRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Kesici<Button size="sm" style={{ width: "30px", height: "20px", background: kesiciRenk, float: "right" }} onClick={() => Sec("PDkesiciRenk", "Kesiciler", kesiciRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }}>kesici Text
                            <Button size="sm" style={{ width: "30px", height: "20px", background: kesiciTextRenk, float: "right" }} onClick={() => Sec("PDkesiciTextRenk", "Kesici text", kesiciTextRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Kontaktör<Button size="sm" style={{ width: "30px", height: "20px", background: kontaktorRenk, float: "right" }} onClick={() => Sec("PDkontaktorRenk", "Kontaktörler")}></Button>
                        </td><td></td>
                    </tr>

                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Reak. Güç Kont.Rolesi<Button size="sm" style={{ width: "30px", height: "20px", background: RGKRoleRenk, float: "right" }} onClick={() => Sec("PDRGKRoleRenk", "Reaktif Güç Kontrol Rölesi", RGKRoleRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >AOS<Button size="sm" style={{ width: "30px", height: "20px", background: aosRenk, float: "right" }} onClick={() => Sec("PDaosRenk", "Anahtarlı Ot. Sig.", aosRenk)}></Button>
                        </td><td></td>
                    </tr>

                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >AOS Koruma Text
                            <Button size="sm" style={{ width: "30px", height: "20px", background: aosTextRenk, float: "right" }} onClick={() => Sec("PDaosTextRenk", "Anahtarlı Ot. Sig. Text", aosTextRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Akım Trafo<Button size="sm" style={{ width: "30px", height: "20px", background: akimTrafoRenk, float: "right" }} onClick={() => Sec("PDakimTrafoRenk", "Akım Trafo", akimTrafoRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Akım Trafo TextGöster
                            <Button size="sm" style={{ width: "30px", height: "20px", background: akimTrfTextRenk, float: "right" }} onClick={() => Sec("PDakimTrfTextRenk", "Akım Trafo Text", akimTrfTextRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }}>Bara{baraRenk}<Button size="sm" style={{ width: "30px", height: "20px", background: baraRenk, float: "right" }} onClick={() => Sec("PDbaraRenk", "Bara", baraRenk)}></Button></td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Ölçü Çizgisi
                            <input type="checkbox" defaultChecked={olcuGoster} onChange={() => dispatch(UpdatePDolcuGoster(!olcuGoster))} />
                            <Button size="sm" style={{ width: "30px", height: "20px", background: mesafeRenk, float: "right" }} onClick={() => Sec("PDmesafeRenk", "Ölçü Çizgisi", mesafeRenk)}></Button>
                        </td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}>
                        <td style={{ width: "100%" }} >Ölçü Klavuz<Button size="sm" style={{ width: "30px", height: "20px", background: klavuzRenk, float: "right" }} onClick={() => Sec("PDklavuzRenk", "Ölçü Klavuz", klavuzRenk)}></Button>
                        </td><td></td>
                    </tr>

                </tbody>
            </Table>



        </React.Fragment>
    )

}
