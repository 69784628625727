import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value:"",
    activeUser:  {  
      "fullname":"",
      "email":"",
      "password":"",
      "userType":'USER',
      "phoneNumber":"",
      "MuellifAdiSoyad":"",
      "OdaNo":"",
      "DiplomaNo":"",
      "BuroNo":"",
      "MuellifTelefonNo":"",
      "Firma":"",
      "vergiDairesi":"",
      "vergiNo":"",
      "Adresi":"",
      "FirmaTelefonNo":"",
      "myprojects":"",
      "activeuser":"",
     "_id":""}
  },
  reducers: {   
    nameChange: (state, action) => {      state.value = action.payload    },
    UpdateActiveUser: (state, action) => {      state.activeUser = action.payload    },
  },
})
 


export const {  nameChange ,UpdateActiveUser} = userSlice.actions

export default userSlice.reducer