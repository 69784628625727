import React from 'react';

const Contact = () => {
  return (
    <div style={{backgroundColor:"rgb(200,200,200,0.55)",padding:"30px",borderRadius:"10px"}}>  {/* Added a class for styling */}
      <div className="contact-info" >  {/* Added a class for styling */}
        <h2>İletişim Bilgileri</h2>  {/* Header for Contact Information */}
        <ul>
          <li>
            <a href="mailto:irfanbadur@outlook.com">irfanbadur@outlook.com</a>
          </li>
          <li>
            <a href="mailto:irfanbadur1864@gmail.com">irfanbadur1864@gmail.com</a>
          </li>
          <li>
            Telefon: <a  >0 535 372 66 15</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
