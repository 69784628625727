import { createSlice } from '@reduxjs/toolkit'

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    deger:1,
    Gsaktif:false,
    Ksaktif:false,
    dil:"TR"
   
  },
  reducers: {   
  
    
    UpdateGsaktif: (state, action) => {state.Gsaktif = action.payload  },
    UpdateKsaktif: (state, action) => {state.Ksaktif = action.payload    },
    UpdateDeger: (state, action) => {state.deger = action.payload    },
    UpdateDil: (state, action) => {state.dil = action.payload    },
  },
})
 


export const {  UpdateGsaktif,UpdateKsaktif,UpdateDeger,UpdateDil} = adminSlice.actions

export default adminSlice.reducer