
import React, { useEffect, useState } from 'react'
import { Form, Button, Table, InputGroup, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateGostergeler, UpdateIcIhtiyac, UpdateSabitGrup, UpdatePanoTur, UpdateAnaKesici, UpdateKesici1Tur, UpdateKesici1, UpdateKesici2Tur, UpdateBara, UpdateSayac, UpdateInisMesafe, UpdateTMSAkim, UpdateKesici2,UpdateInisIletken } from '../reducers/PanoDetaySlice'
import { TMS, EMO_KOMPAKT, KAKKOMPAKT } from '../Data/Malzeme'
import { InisIletkenleriCU, InisIletkenleriAL } from '../Data/Ayarlar'

import { DataGrid } from '@mui/x-data-grid';

export default function PanoVeriDetay(props) {
  const PanoDetaylar = useSelector((state) => state.PanoDetay)
  const KompDetaylar = useSelector((state) => state.KompDetay)
  const dispatch = useDispatch()
 
  const [sabitGrup, setSabitGrup] = useState(PanoDetaylar.SabitGrup)
  const [icIhtiyac, setIcIhtiyac] = useState(PanoDetaylar.IcIhtiyac)
  const [gostergeler, setGostergeler] = useState(PanoDetaylar.IcIhtiyac)
  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");
  const [sayac, setSayac] = useState(PanoDetaylar.Sayac);
  const [kesici1tur, setKesici1tur] = useState(PanoDetaylar.Kesici1Tur);
  const [kesici1, setKesici1] = useState(PanoDetaylar.Kesici1);
  const [kesici1KompNo, setKesici1KompNo] = useState(PanoDetaylar.Kesici1KompNo);
  const [kesici1KAKNo, setKesici1KAKNo] = useState(PanoDetaylar.Kesici1KAKNo);

  const [kesici2tur, setKesici2tur] = useState(PanoDetaylar.Kesici2Tur);
  const [kesici2, setKesici2] = useState(PanoDetaylar.Kesici2);
  const [kesici2KompNo, setKesici2KompNo] = useState(PanoDetaylar.Kesici2KompNo);
  const [kesici2KAKNo, setKesici2KAKNo] = useState(PanoDetaylar.Kesici2KAKNo);
  const [panoTur, setPanoTur] = useState(PanoDetaylar.PanoTuru);
  const [bara, setBara] = useState(PanoDetaylar.Bara);
  const [akimTrafo, setAkimTrafo] = useState(PanoDetaylar.AkimTrafolari);
  const [anaKesici, setAnaKesici] = useState(PanoDetaylar.AnaKesici);
  const [tmsAkim, setTmsAkim] = useState(PanoDetaylar.TMSAkim);
  const [sayacDizi, setSayacDizi] = useState(PanoDetaylar.SayacDizi);
  const [inisMesafe, setInisMesafe] = useState( 12);
  const [inisIletkenDizi, setInisIletkenDizi] = useState(PanoDetaylar.InisIletkenDizi);
  const [inisIletkenTur, setInisIletkenTur] = useState("cu");
  const [inisIletkenKesit, setInisIletkenKesit] = useState(PanoDetaylar.InisIletken.Kesit);
  const [inisIletkenNo, setInisIletkenNo] = useState(PanoDetaylar.InisIletken.No);

  const [kompVarlik, setKompVarlik] = useState(KompDetaylar.KompVarlik);

   function Show() { setToolShow(!toolshow) }
  useEffect(() => {
  }, [kesici1tur, kesici2tur])

  useEffect(() => {
    setInisIletkenDizi(PanoDetaylar.InisIletkenDizi)
    setInisIletkenNo( inisIletkenDizi[0])
  }, [])
 
  useEffect(() => {
    dispatch(UpdateSayac(sayac))
  }, [sayacDizi, sayac])

  useEffect(() => {
    dispatch(UpdateAnaKesici(anaKesici))
    dispatch(UpdatePanoTur(panoTur))
    dispatch(UpdateKesici1Tur(kesici1tur))
    dispatch(UpdateKesici2Tur(kesici2tur))


    if (kesici1tur === "Kompakt1") {
      setKesici1(PanoDetaylar.Kompakt[kesici1KompNo])
    }
    if (kesici1tur === "KAKKompakt1") {
      setKesici1(PanoDetaylar.KAKKompakt[kesici1KAKNo])
    }

    if (kesici2tur === "Kompakt2") {
      setKesici2(PanoDetaylar.Kompakt[kesici2KompNo])
    }
    if (kesici2tur === "KAKKompakt2") {
      setKesici2(PanoDetaylar.KAKKompakt[kesici2KAKNo])
    }


    //dispatch(UpdateBara(bara))
    dispatch(UpdateSayac(PanoDetaylar.Sayac))
    dispatch(UpdateTMSAkim(tmsAkim))
    dispatch(UpdateSabitGrup(sabitGrup))
    dispatch(UpdateIcIhtiyac(icIhtiyac))
    dispatch(UpdateGostergeler(gostergeler))
    setSayacDizi(PanoDetaylar.SayacDizi) 
    setSayac(PanoDetaylar.Sayac)
    setInisIletkenDizi(PanoDetaylar.InisIletkenDizi)    
    setBara(PanoDetaylar.Bara)
    setKompVarlik(KompDetaylar.KompVarlik)
    }, [PanoDetaylar,KompDetaylar])

   
  useEffect(() => {
    if(inisIletkenTur==="cu"){
      dispatch(UpdateInisIletken(InisIletkenleriCU[inisIletkenNo]))
    }else if(inisIletkenTur==="al"){
      dispatch(UpdateInisIletken(InisIletkenleriAL[inisIletkenNo]))
    } 

  }, [inisIletkenNo,inisIletkenTur])
 
  useEffect(() => {
    setInisIletkenNo( inisIletkenDizi[0])
  }, [inisIletkenDizi])
  useEffect(() => {
    dispatch(UpdateAnaKesici(anaKesici))
  }, [anaKesici])

  useEffect(() => {
    dispatch(UpdateSabitGrup(sabitGrup))
  }, [sabitGrup])

  useEffect(() => {
    dispatch(UpdateIcIhtiyac(icIhtiyac))
  }, [icIhtiyac])

  useEffect(() => {
    dispatch(UpdateGostergeler(gostergeler))
  }, [gostergeler])


  useEffect(() => {
    dispatch(UpdateKesici1(kesici1))
  }, [kesici1])
  useEffect(() => {
    setKesici1(PanoDetaylar.Kompakt[kesici1KompNo])
  }, [kesici1KompNo])
  useEffect(() => {
    setKesici1(PanoDetaylar.Kompakt[kesici1KompNo])
  }, [kesici1KAKNo])
  useEffect(() => {
    dispatch(UpdateKesici1Tur(kesici1tur))
  }, [kesici1tur])


  useEffect(() => {
    dispatch(UpdateKesici2(kesici2))
  }, [kesici2])
  useEffect(() => {
    setKesici2(PanoDetaylar.Kompakt[kesici2KompNo])
  }, [kesici2KompNo])
  useEffect(() => {
    setKesici2(PanoDetaylar.Kompakt[kesici2KompNo])
  }, [kesici2KAKNo])
  useEffect(() => {
    dispatch(UpdateKesici2Tur(kesici2tur))
  }, [kesici2tur])
 
  useEffect(() => {
     setAnaKesici("Kesici+Sayaç") 
  }, [sayac])
 
  useEffect(() => {  
    dispatch(UpdatePanoTur(panoTur))
  }, [panoTur])
  useEffect(() => {
    dispatch(UpdateInisMesafe(inisMesafe)) 
  }, [inisMesafe])
 
 
  return (
    <React.Fragment >
      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Pano Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>

          <tr>
            <td>
              Pano Türü
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <Form.Select aria-label="example" defaultValue={panoTur} onChange={(e) => setPanoTur(e.target.value)}   >
               
                {kompVarlik==="true"? <option value="Ölçüm+Dağıtım+Kompanzasyon">Ölçüm+Dağıtım+Kompanzasyon</option>:null}
                <option value="Ölçüm+Dağıtım">Ölçüm+Dağıtım</option>
                <option value="Ölçüm">Ölçüm</option>
                 
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>
              Sayaç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
             
 
                 
            <Form.Select aria-label="example" defaultValue={sayac} onChange={(e) => setSayac(e.target.value)} >
              {sayacDizi.map((sayactip, s) => (
                  <option key={s} value={sayactip}>{sayactip}</option>
              ))}        
            </Form.Select> 
              
                 
            </td>
          </tr>
          <tr>
            <td>
              Akım Trafoları
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {akimTrafo}
            </td>
          </tr>
          <tr>
            <td>
              İniş İletkeni Türü
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <Form.Select aria-label="example" defaultValue="cu" onChange={(e) => setInisIletkenTur(e.target.value)}  >

                <option value="cu">Bakır</option>
                <option value="al">Alüminyum</option>


              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>
              İniş İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {inisIletkenTur === "cu" ? 
              <Form.Select aria-label="example"  onChange={(e) => setInisIletkenNo(e.target.value)}  >
                {inisIletkenDizi.map((iletken, i) => (
                  <option key={i} value={iletken}>{InisIletkenleriCU[iletken].Etiket}</option>
                ))}
              </Form.Select>
                : 
              <Form.Select aria-label="example"  onChange={(e) => setInisIletkenNo(e.target.value)}  >
                  {inisIletkenDizi.map((iletken, i) => (
                    <option key={i} value={iletken}>{InisIletkenleriAL[iletken].Etiket}</option>
                  ))}
                </Form.Select>
              }



            </td>
          </tr>

          <tr>
            <td>
              İniş İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            Mesafe(m)
                <input style={{ width: "50px", background: bgcolor }} className="my-auto border border-0" type='number' min="10" placeholder={inisMesafe} onChange={e=>setInisMesafe(e.target.value)} />

            </td>
          </tr>
          <tr>
            <td>
              Bara
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              {bara}
            </td>
          </tr>
          {sayac !== "yok" ?
            <tr>
              <td>
                Ana Kesici
              </td>
              <td style={{ width: "50%", background: bgcolor }} >
                <Form.Select aria-label="example" defaultValue={anaKesici} onChange={(e) => setAnaKesici(e.target.value)} >
                  <option value="Kesici+Sayaç+Kesici">Kesici+Sayaç+Kesici</option>
                  <option value="Kesici+Sayaç">Kesici+Sayaç</option>
                  <option value="Sayaç+Kesici">Sayaç+Kesici</option>
                </Form.Select>
              </td>
            </tr>
            : null
          }
          <tr>
            <td>
              Kesici 1 Tip
            </td>
            <td style={{ width: "50%", background: bgcolor }}   >
              <Form.Select aria-label="example" defaultValue={kesici1tur} onChange={(e) => setKesici1tur(e.target.value)} >
                <option value="Kompakt1">Kompakt Şalter</option>
                <option value="KAKKompakt1">K.A.K.R li Kompakt Ş.</option>
              </Form.Select>

            </td>
          </tr>
          <tr>
            <td>
              Kesici 1
            </td>
            <td style={{ width: "50%", background: bgcolor }} >

              {kesici1tur === "Kompakt1" ? <Form.Select aria-label="example" onChange={(e) => setKesici1KompNo(e.target.value)}  >
                {PanoDetaylar.Kompakt.map((kompakt, t) => (
                  <option key={t} value={t} >{EMO_KOMPAKT[kompakt].Etiket}</option>
                ))}
              </Form.Select> : null}
              {kesici1tur === "KAKKompakt1" ? <Form.Select aria-label="example" onChange={(e) => setKesici1KAKNo(e.target.value)}  >
                {PanoDetaylar.KAKKompakt.map((kakkomp, t) => (
                  <option key={t} value={kakkomp}>{KAKKOMPAKT[kakkomp].Etiket}</option>
                ))}
              </Form.Select> : null}


            </td>
          </tr>

          {anaKesici === "Kesici+Sayaç+Kesici" ?
            <tr>
              <td>
                Kesici 2 Tip
              </td>
              <td style={{ width: "50%", background: bgcolor }}  >

                <Form.Select aria-label="example" defaultValue={kesici2tur} onChange={(e) => setKesici2tur(e.target.value)} >
                  <option value="Kompakt2">Kompakt Şalter</option>
                  <option value="KAKKompakt2">K.A.K.R li Kompakt Ş.</option>
                </Form.Select>

              </td>
            </tr>
            : null}

          {anaKesici === "Kesici+Sayaç+Kesici" ?
            <tr>
              <td>
                Kesici 2
              </td>
              <td style={{ width: "50%", background: bgcolor }} >
                {kesici2tur === "Kompakt2" ? <Form.Select aria-label="example" onChange={(e) => setKesici2KompNo(e.target.value)}  >
                  {PanoDetaylar.Kompakt.map((kompakt, t) => (
                    <option key={t} value={t} >{EMO_KOMPAKT[kompakt].Etiket}</option>
                  ))}
                </Form.Select> : null}
                {kesici2tur === "KAKKompakt2" ? <Form.Select aria-label="example" onChange={(e) => setKesici2KAKNo(e.target.value)}  >
                  {PanoDetaylar.KAKKompakt.map((kakkomp, t) => (
                    <option key={t} value={kakkomp}>{KAKKOMPAKT[kakkomp].Etiket}</option>
                  ))}
                </Form.Select> : null}
              </td>
            </tr>

            : null}
          <tr>
            <td>
              Sabit Grup
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input type="checkbox" defaultChecked={sabitGrup} onChange={(e) => setSabitGrup(!sabitGrup)} />
            </td>
          </tr>
          <tr>
            <td>
              İç ihtiyaç
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input type="checkbox" defaultChecked={icIhtiyac} onChange={(e) => setIcIhtiyac(!icIhtiyac)} />
            </td>
          </tr>
          <tr>
            <td>
              Göstergeler
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input type="checkbox" defaultChecked={gostergeler} onChange={(e) => setGostergeler(!gostergeler)} />
            </td>
          </tr>

        </tbody>
      </Table>




    </React.Fragment>





  )

}
