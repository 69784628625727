
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0;
let Scl=0.5;
let  denemeCizim=[{ Ad: "Polyline", layer: "0", Color: "#FF00FF ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: [X + Scl * (0), Y + Scl * (0), X + Scl * (800), Y + Scl * (0), X + Scl * (800), Y + Scl * (700), X + Scl * (0), Y + Scl * (700)] }]

export default function AciklamaRaporu(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const GenelBilgiler = useSelector((state) => state.genelBilgiler)
    
    let proYap=GenelBilgiler.A_AdSoyad
    let il=GenelBilgiler.A_Il
    let ilce=GenelBilgiler.A_Ilce
    let Mah=GenelBilgiler.A_Mahalle
    let Sokak=GenelBilgiler.A_Sokak
    let No=GenelBilgiler.A_No
    let TrfGuc=trafoDetaylar.trafoGuckVA
  
    let dilekceText1="PROJE AÇIKLAMA RAPORU"
    let dilekceText2= "     Projenin amacı, "+il+" ili " +ilce+" ilçesi "+Mah+" Mahallesi "+Sokak+ " sokakta "+proYap+" tarafından kuruluacak olan tesisin enerji ihtiyacını karşılamak amacıyla "+TrfGuc+"kVA trafo gücünde elektrik temin etmektir.Tesis edilecek trafo direk tipidir. Trafo Panosu harici tipde olup ADP çıkışları NYY tipde olacakdır."  
    let dilekceText3= "     Proje, Elektrik Kuvvetli Akım ve İç Tesisat Yönetmeliklerine uygun olarak hazırlanmış ve gerekli hesaplar ve çizimler ekte sunulmuştur."
    let dilekceText4= "     Bilgilerinize arz ederiz. "
   
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
    const H=500
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
    
    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:H*Scl,                          
            },
            cizim:[{ Ad: "Polyline", layer: "0", Color:CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (0), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (H), 
                X + Scl * (0), Y + Scl * (H)
            ] },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl *15,align:"center", TextString:dilekceText1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl *15,align:"justify", TextString:dilekceText2, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (250), TextH: Scl *15,align:"justify", TextString:dilekceText3, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (350), TextH: Scl *15,align:"justify", TextString:dilekceText4, TextStyleName: "  1", TextArc: 0,width:400*Scl }]
            
        })); 
    }
    useEffect(() => {       
        artirW(400)
        
    }, [cizimOzellik,trafoDetaylar,GenelBilgiler]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]);     
 
    return (
        <>   
        </>
    )

}
