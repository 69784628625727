
import React, {  useEffect, useState } from 'react'
import {  Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
//import { sayacdata } from '../reducers/sayacbolmeSlice'
//import {BaslikView} from '../Data/cizimlerGecici'
import {BaslikView} from '../Data/testCizimler'
 

 
let X=0;
let Y=0;
 
let  denemeCizim=[ ]

export default function Baslik(props) {
    
    
 
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const GenelBilgiler = useSelector((state) => state.genelBilgiler)
    const MuellifBilgiler = useSelector((state) => state.user).activeUser
     
    let proYap=GenelBilgiler.A_AdSoyad
    let il=GenelBilgiler.A_Il
    let ilce=GenelBilgiler.A_Ilce
    let Mah=GenelBilgiler.A_Mahalle
    let Sokak=GenelBilgiler.A_Sokak
    let No=GenelBilgiler.A_No
    let TrfGuc=trafoDetaylar.trafoGuckVA
    let Scl=cizimOzellik.Scl;

   
 
    let Scl2=1.85  ; Scl*=Scl2;
    let Muellif={ 
 
        AdSoyAd:GenelBilgiler.M_AdSoyad, 
        OdaSicilNo:GenelBilgiler.M_OdaSiciNo,
        DiplomaNo:GenelBilgiler.M_DiplomaNo,
        BuroNo:GenelBilgiler.M_BuroNo,
        TelNo:GenelBilgiler.M_TelNo,
        FirmaAd:GenelBilgiler.M_FirmaAdi,
        FirmaAdresi:GenelBilgiler.M_FirmaAdresi,
        VergiDairesi:GenelBilgiler.M_VergiDairesi, 
        VergiNo:GenelBilgiler.M_VergiNo 
    
    }

    let Yaptiran={AdSoyAd:GenelBilgiler.PY_AdSoyad, TelNo:GenelBilgiler.PY_Telefon,Adres:GenelBilgiler.PY_Adres,Mail:GenelBilgiler.PY_Mail,VergiDairesi:GenelBilgiler.PY_VergiDairesi,VergiNo:GenelBilgiler.PY_VergiNo } 

    let Arsa={AdSoyAd:GenelBilgiler.A_AdSoyad,Il:GenelBilgiler.A_Il,Ilce:GenelBilgiler.A_Ilce,Mah:GenelBilgiler.A_Mahalle,Sokak:GenelBilgiler.A_Sokak,No:GenelBilgiler.A_No,Adres:GenelBilgiler.A_Adres,Ada:GenelBilgiler.A_Ada,Pafta:GenelBilgiler.A_Pafta,Parsel:GenelBilgiler.A_Parsel,Alan:GenelBilgiler.A_Alan  }
    
    let Yapi={Kullanim:GenelBilgiler.YO_Kullanim,Alan:GenelBilgiler.YO_Alan,KatSayi:GenelBilgiler.YO_KatSayi,BagBolSay:GenelBilgiler.YO_BagBolSay,CizimTarih:GenelBilgiler.YO_CizimTarih,Cizen:GenelBilgiler.YO_Cizen,Sure:GenelBilgiler.YO_YapimSuresi,Sinif:GenelBilgiler.YO_YapiSinifi,ProjeNo:GenelBilgiler.YO_ProjeNo,TopKuruluGuc:trafoDetaylar.topKuruluGuc,TalepFak:trafoDetaylar.talepFak,TalepGuc:trafoDetaylar.talepGuckVA,TrfGuc:trafoDetaylar.trafoGuckVA,Olcek:GenelBilgiler.YO_Olcek }
    let Kurum={Kurum:GenelBilgiler.O_Kurum }  
   
    const [showSayacBolme, setShowSayacBolme] = useState(false); 
 
    const [bgcolor, setBgcolor] = useState("white");
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
    function sayacBolmeShow() { setShowSayacBolme(!showSayacBolme) }

    function artirW(val){  
        setCizim(prmt => ({ 
            ...prmt,  
            attr:{  
                x:0,y:0,
                w:1046*Scl,  
                h:1399*Scl,                           
            },
            cizim:BaslikView(0,0,Scl,Muellif,Yaptiran,Arsa,Yapi,Kurum,{cizimRenk:"#FFFFFF",textRenk:"#FFFF00"}) 
        }));    
    } 
    useEffect(() => {       
        artirW(400) 
    }, [cizimOzellik,GenelBilgiler,trafoDetaylar,MuellifBilgiler ]); 
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
 
    return ( 
        <React.Fragment>
            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1  '
                style={{ width: "98%", background: bgcolor }}
            >
                <thead >
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={sayacBolmeShow}>Başlık Ayarları</th>
                    </tr>
                </thead>

                <tbody style={showSayacBolme ? { display: "block" } : { display: "none" }}>
                    <tr  >
                        <td >Üç Faz Sayaç Sayısı</td>
                        <td >
                            <input style={{ width: "75px", background: bgcolor }} onClick={e=>artirW(parseInt(e.target.value))}    className="my-auto border border-0" type='number'  min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td  >Tek Faz Sayaç Sayısı</td>
                        <td  >
                            <input  style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number'   min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum ÜçFaz Satır</td>
                        <td>
                            <input  style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number'   min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum TekFaz Satır</td>
                        <td>
                            <input  style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number'   min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td >Pano Kapak Maksimum Genişlik</td>
                        <td><input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number'   min="0" />
                        </td>
                    </tr>
                     
                </tbody>

            </Table>

        </React.Fragment>
    )

}
