import React, { useEffect, useState } from 'react'
import { Tab, Row, Col, Nav, Collapse } from 'react-bootstrap'
 
import { useSelector, useDispatch } from 'react-redux'
import icerik from './icerikler'

export default function Overview() {

  

  const lisan = useSelector((state) => state.admin.dil)

  useEffect(() => {

  }, [lisan])


  return (
    <div  ><br />
      {lisan === 'TR' ?
       <>  
       <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
          <Nav   className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first" style={{color:"black",borderBottom:"1px solid #678"}}><h2>Company & Founder</h2></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second"  style={{color:"black",borderBottom:"1px solid #678"}}><h2>The Story Behind ProjeHUB</h2></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} style={{ marginLeft: '25%' }}>
          <Tab.Content>
            <Tab.Pane eventKey="first" style={{margin:"30px"}}>
            <h2>Company</h2>
            <>  Nef Aydınlatma Enerji Mühendislik was established in Samsun in 2013. It has been constantly preparing Electrical Projects since its establishment. It prepares electrical projects such as Internal Installation Projects, Network Projects, Transformer Projects. It was involved in lighting activities for about 4 years during its establishment. He also operated in Network Construction works. It has decreased since the end of 2019.
            <>
            <br />
            <br />
            <h2>Founder</h2>
                            <b>E-posta:</b>irfanbadur@outlook.com
                            <br />
                            <b>E-posta:</b>irfanbadur1864@gmail.com
                            <br />
                            <b>Phone:</b> +90 535 372 66 15
                            <br />
                            <b>Summary:</b>
                            <br />
                            Electrical engineer with over 15 years of experience. It specializes in lighting, energy and low voltage/medium voltage networks. He has experience in project management, design, implementation and customer relations.
                            <br />
                            <b>Experience:</b>                     
                            Nef Aydınlatma Energy Engineering (2013 - Present)                       
                            <ul>
                            <li>Founder and Independent Consultant Engineer</li>
                               <li>Design and implementation of lighting and energy projects</li>
                               <li>Low voltage and medium voltage networks construction works</li>
                               <li>Project management, customer relations and coordination</li>
                            </ul>                  
                            BADUR Architecture and Engineering (2009 - 2013)
                            <br />
                            <ul>
                               <li>Founder and Independent Consultant Engineer</li>
                               <li>Design and implementation of electrical projects</li>
                               <li>Customer relations and coordination</li>
                             </ul>
                            <br />
                            <b>Education:</b>
                            <br />
                            Istanbul University (2007)
                             Electrical electronics Engineering
                            <br />
                            <b>Skills:</b>
                            <br />
                            <ul>
                               <li>Project management</li>
                               <li>Technical design and implementation</li>
                               <li>AutoCAD</li>
                               <li>HTML,CSS,JavaScript,React,KonvaJS,ThreeJS</li>
                               <li>Communication and organization</li>
                             </ul>
                             <b>Language Skills:</b>
                             <br />
                             Turkish (Native)
                             English (Intermediate)
                             <br />
                             <b>Additional Information:</b>
                             <br />
                             Member of TMMOB Chamber of Electrical Engineers
                             <br />
                             <b>Note:</b>
                             <br />
                             The above resume summarizes Irfan Badur's experiences and skills. Please contact us for more detailed information.
                          </>

</>
            </Tab.Pane>
            <Tab.Pane eventKey="second" style={{margin:"30px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;The project was born with the idea of preparing electrical projects not only with CAD programs but also by making them more automated. By having some parts of the electrical projects drawn in the VBA programming editor in AutoCAD, it was seen that this idea was possible and more serious work was started. The aim was to prepare the projects with a web-based application, independent of the location and desktop applications. The first prototypes started to be developed with the web-based CAD infrastructure prepared for this purpose, and successful results were obtained in these prototypes. These results proved that the established infrastructure can be used in all other disciplines and project types. Preparing projects on the web with editors designed together with a community infrastructure will offer a wide range of opportunities that have seemed like a dream to project developers until now. There are many applications and developments to be made in this field.
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
      
       </> : 
      
      <>
 
 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
          <Nav   className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first" style={{color:"black",borderBottom:"1px solid #678"}}><h2>Firma ve Kurucu</h2></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second"  style={{color:"black",borderBottom:"1px solid #678"}}><h2>ProjeHUB'ın hikayesi</h2></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} style={{ marginLeft: '25%' }}>
          <Tab.Content>
            <Tab.Pane eventKey="first" style={{margin:"30px"}}>
            <h2>Firma</h2>
            <>  Nef Aydınlatma Enerji Mühendislik 2013 yılında Samsun'da kuruldu. Kuruluşundan itibaren sürekli olarak Elektrik Projeleri hazırlamaktadır. İç Tesisat Projeleri, Şebeke Projeleri, Trafo Projeleri gibi elektrik projeleri hazırlıyor. Kuruluşunda 4 yıl kadar aydınlatma faaliyetlerinde bulundu. Aynı zamanda Şebeke Yapım işlerinde de faaliyet göstermiştir. 2019 yılı sonundan itibaren küçülmeye gitmiştir.
            <>
            <br />
            <br />
            <h2>Kurucu</h2>
                            <b>E-posta:</b>irfanbadur@outlook.com
                            <br />
                            <b>E-posta:</b>irfanbadur1864@gmail.com
                            <br />
                            <b>Telefon:</b> +90 535 372 66 15
                            <br />
                            <b>Özet:</b>
                            <br />
                            15 yılı aşkın deneyime sahip elektrik mühendisi. Aydınlatma, enerji ve alçak gerilim/orta gerilim şebekeleri alanlarında uzmanlaşmıştır. Proje yönetimi, tasarım, uygulama ve müşteri ilişkileri deneyimine sahiptir.
                            <br />
                            <b>Deneyim:</b>                     
                            Nef Aydınlatma Enerji Mühendislik (2013 - Günümüz)                         
                            <ul>
                              <li>Kurucusu ve Serbest Müşavir Mühendis</li>
                              <li>Aydınlatma ve enerji projelerinin tasarımı ve uygulaması</li>
                              <li>Alçak gerilim ve orta gerilim şebekeleri yapım işleri</li>
                              <li>Proje yönetimi, müşteri ilişkileri ve koordinasyon</li>
                            </ul>                  
                            BADUR Mimarlık ve Mühendislik (2009 - 2013)
                            <br />
                            <ul>
                              <li>Kurucusu ve Serbest Müşavir Mühendis</li>
                              <li>Elektrik projelerinin tasarımı ve uygulaması</li>
                              <li>Müşteri ilişkileri ve koordinasyon</li>
                            </ul>
                            <br />
                            <b>Eğitim:</b>
                            <br />
                            İstanbul Üniversitesi (2007)
                            Elektrik-Elektronik Mühendisliği
                            <br />
                            <b>Beceriler:</b>
                            <br />
                            <ul>
                              <li>Proje yönetimi</li>
                              <li>Teknik tasarım ve uygulama</li>
                              <li>AutoCAD</li>
                              <li>HTML,CSS,JavaScript,React,KonvaJS,ThreeJS</li>
                              <li>İletişim ve organizasyon</li>
                            </ul>
                            <b>Dil Becerileri:</b>
                            <br />
                            Türkçe (Anadil)
                            İngilizce (Orta Seviye)
                            <br />
                            <b>Ek Bilgiler:</b>
                            <br />
                            TMMOB Elektrik Mühendisleri Odası Üyesi
                            <br />
                            <b>Not:</b>
                            <br />
                            Yukarıdaki özgeçmiş, İrfan Badur'un deneyimlerini ve becerilerini özetlemektedir. Daha detaylı bilgi için lütfen iletişime geçiniz.
                          </>

</>
            </Tab.Pane>
            <Tab.Pane eventKey="second" style={{margin:"30px"}}>
            &nbsp;&nbsp;&nbsp;&nbsp;Proje, elektrik Projelerinin sadece CAD programları ile değilde aynı zamanda daha fazla otomatize edilerek hazırlanması fikri ile doğdu. Elektrik projlerinin bazı kısımlarının AutoCAD içindeki VBA programlama editörüne projenin bazı kısımlarının çizdirilmesi ile bu fikrin mümkün olduğu görüldü ve daha ciddi çalışmalara başlanıldı. Projelerin web tabanlı bir uygulama ile hazırlanarak mekandan ve masa üstü uygulamalardan  bağımsız bir şekilde proje hazırlanması amaçlandı. Bunun için hazırlanan web Tabanlı CAD alt yapısı ile ilk prototipler geliştirilmeye başlandı bu prototiplerde başarılı sonuçlar alındı. Bu sonuçlar kurulan alt yapının tüm diğer disiplinlerde ve proje türlerinde de kullanılabileceğini kanıtladı.  Projelerin web üzerinden bir topluluk alt yapısı ile birlikte tasarlanan editörler ile hazırlanması şimdiye kadar projecilere rüya gibi gelen çok geniş imkanları sunacaktır. Bu alanda yapılacak uygulama ve geliştirmeler çok fazladır.
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>

        </>}
 
    </div>
  )
}
