
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateSecim } from '../reducers/cizimOzellik'


let X = 0;
let Y = 0;
let denemeCizim = []



export default function YukelemeCetveli(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetay = useSelector((state) => state.PanoDetay)
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const CetvelOzellik = useSelector((state) => state.RapCetCiz)
    const dispatch = useDispatch()

    let textRenk = CetvelOzellik.YCTextRenk
    let cizimRenk = CetvelOzellik.YCRenk

    let Scl = cizimOzellik.Scl;
    let Scl2 = 2.25; Scl *= Scl2;

    let hh = 400

    let U = 200
    let KuruluGuc = trafoDetaylar.topKuruluGuc
    let TalepFak = trafoDetaylar.talepFak
    let TalepGuc = trafoDetaylar.talepGuc
    let CetvelSatir = []
    let Text1 = "YÜKLEME CETVELİ"

    let satirH = 120 * Scl
    let cikislar = panoDetay.Cikislar

    for (var a in cikislar) {
        let Faz = cikislar[a].Faz;
        let Akim = cikislar[a].Akim;
        let Guc = cikislar[a].Guc;
        let Kesici = cikislar[a].Kesici;
        let Iletken = cikislar[a].Iletken
        let IletkenKap = cikislar[a].TopAkimKap
        let Aciklama = cikislar[a].Aciklama

        let mesafeCikis = cikislar[a].Mesafe

        let No = (parseInt(a) + 1)
        CetvelSatir.push({
            Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                [
                    X + Scl * (5), Y + (satirH),
                    X + Scl * (840), Y + (satirH),
                    X + Scl * (840), Y + (satirH + 30 * Scl),
                    X + Scl * (5), Y + (satirH + 30 * Scl)
                ]
        })

        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (15.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: No, TextStyleName: "  1", TextArc: 0, width: 30 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (45.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Faz, TextStyleName: "  1", TextArc: 0, width: 30 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (75.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Akim, TextStyleName: "  1", TextArc: 0, width: 65 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (140.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Kesici, TextStyleName: "  1", TextArc: 0, width: 120 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (260.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: IletkenKap, TextStyleName: "  1", TextArc: 0, width: 50 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (310.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Iletken, TextStyleName: "  1", TextArc: 0, width: 150 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (460.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: mesafeCikis, TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (510.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Guc, TextStyleName: "  1", TextArc: 0, width: 50 * Scl })
        CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (600.00), y: Y + satirH + 5, TextH: Scl * 15, align: "justify", TextString: Aciklama, TextStyleName: "  1", TextArc: 0, width: 200 * Scl })
        satirH += 30 * Scl;
    }

    //-----------------------------------------------------------------------------------

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (10.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "No", TextStyleName: "  1", TextArc: 0, width: 100 * Scl })
    //------------------------------------------------------------------------------
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (35), Y + (55 * Scl),
                X + Scl * (65), Y + (55 * Scl),
                X + Scl * (65), Y + (satirH),
                X + Scl * (35), Y + (satirH)
            ]
    })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (37.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "Faz", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    //------------------------------------------------------------------------------

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (80.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "Akim", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    //------------------------------------------------------------------------------
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (130), Y + (55 * Scl),
                X + Scl * (250), Y + (55 * Scl),
                X + Scl * (250), Y + (satirH),
                X + Scl * (130), Y + (satirH)
            ]
    })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (170.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "Kesici", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })

    //------------------------------------------------------------------------------

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (260.00), y: Y + 95 * Scl, TextH: Scl * 15, align: "justify", TextString: "Kap.", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    //------------------------------------------------------------------------------
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (300), Y + (90 * Scl),
                X + Scl * (450), Y + (90 * Scl),
                X + Scl * (450), Y + (satirH),
                X + Scl * (300), Y + (satirH)
            ]
    })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (340.00), y: Y + 65 * Scl, TextH: Scl * 15, align: "justify", TextString: "İletken", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (340.00), y: Y + 95 * Scl, TextH: Scl * 15, align: "justify", TextString: "Etiket", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    //------------------------------------------------------------------------------
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (460.00), y: Y + 95 * Scl, TextH: Scl * 15, align: "justify", TextString: "(m)", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })
    //------------------------------------------------------------------------------
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (500), Y + (55 * Scl),
                X + Scl * (580), Y + (55 * Scl),
                X + Scl * (580), Y + (satirH),
                X + Scl * (500), Y + (satirH)
            ]
    })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (510.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "Güç(W)", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (600.00), y: Y + 80 * Scl, TextH: Scl * 15, align: "justify", TextString: "Açıklama", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })

    //--------------ÖZET KISMI-------------------------------------------
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (5), Y + (satirH),
                X + Scl * (840), Y + (satirH),
                X + Scl * (840), Y + (satirH + 25 * Scl),
                X + Scl * (5), Y + (satirH + 25 * Scl)
            ]
    })
    CetvelSatir.push({
        Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
            [
                X + Scl * (300), Y + (satirH),
                X + Scl * (500), Y + (satirH),
                X + Scl * (500), Y + (satirH + 25 * Scl),
                X + Scl * (300), Y + (satirH + 25 * Scl)
            ]
    })
    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (10.00), y: Y + satirH + 6 * Scl, TextH: Scl * 15, align: "justify", TextString: "Toplam Kurulu Güç    : " + KuruluGuc + " W", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (305.00), y: Y + satirH + 6 * Scl, TextH: Scl * 15, align: "justify", TextString: "Talep Faktörü   : %" + TalepFak, TextStyleName: "  1", TextArc: 0, width: 900 * Scl })

    CetvelSatir.push({ Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (505.00), y: Y + satirH + 6 * Scl, TextH: Scl * 15, align: "justify", TextString: "Talep Güç     : " + TalepGuc + " W", TextStyleName: "  1", TextArc: 0, width: 900 * Scl })



    if (cikislar.length >= 2) {
        hh = 180 * Scl + (cikislar.length) * 30 * Scl
    }
    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 800 * Scl, h: 555 * Scl }, cizim: denemeCizim })

    const [bgcolor, setBgcolor] = useState("white");

    let Cizim = [
        {
            Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                [
                    X + Scl * (5), Y + (5),
                    X + Scl * (840), Y + (5),
                    X + Scl * (840), Y + (55 * Scl),
                    X + Scl * (5), Y + (55 * Scl)
                ]
        },
        {
            Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                [
                    X + Scl * (250), Y + (55 * Scl),
                    X + Scl * (500), Y + (55 * Scl),
                    X + Scl * (500), Y + (90 * Scl),
                    X + Scl * (250), Y + (90 * Scl)
                ]
        },
        {
            Ad: "Polyline", layer: "0", Color: cizimRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                [
                    X + Scl * (5), Y + (55 * Scl),
                    X + Scl * (840), Y + (55 * Scl),
                    X + Scl * (840), Y + (120 * Scl),
                    X + Scl * (5), Y + (120 * Scl)
                ]
        },

        { Ad: "Text", layer: "0", Color: textRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + (0.00), y: Y + Scl * (20), TextH: Scl * 15, align: "center", TextString: Text1, TextStyleName: "  1", TextArc: 0, width: 835 * Scl }]

    Cizim = Cizim.concat(CetvelSatir)

    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }
    function artirW(val) {
        setCizim(prmt => ({
            ...prmt,
            attr: {
                x: 0, y: 0,
                w: 880 * Scl,
                h: hh,
            },
            cizim: Cizim


        }));

    }
    useEffect(() => {
        artirW(400)
    }, [cizimOzellik, trafoDetaylar, panoDetay, CetvelOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);

    function Sec(val, val2, val3) {
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }


    return (

        <React.Fragment>


            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
                style={{ width: "98%", background: bgcolor }}
            >
                <thead>
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={Show}> YÜKLEME CETVELİ GÖRSEL AYARLARI</th>
                    </tr>
                </thead>
                <tbody style={toolshow ? { display: "block", overflowY: "scroll", height: "60px" } : { display: "none" }}>

                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }}>Kenarlar Renk<Button size="sm" style={{ width: "30px", height: "20px", background: cizimRenk, float: "right" }} onClick={() => Sec("YCKenarRenk", "Kenarlar Renk", cizimRenk)}></Button></td><td></td>
                    </tr>
                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }} >Text Renk<Button size="sm" style={{ width: "30px", height: "20px", background: textRenk, float: "right" }} onClick={() => Sec("YCTextRenk", "Text Renk", textRenk)}></Button></td><td></td></tr>

                </tbody>
            </Table>

        </React.Fragment>
    )

}
