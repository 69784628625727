import {Iletken,TMS,EMO_KOMPAKT,AOS,NH} from '../Data/Malzeme'
import {TrafoSecim_EMO} from '../Data/Ayarlar'
export function Akim(guc,faz){
    let akim;
    switch(parseInt(faz)){
        case 1:
        akim=(guc/220).toFixed(2);
        break;
        case 3:
        akim=(guc/(380*0.8*1.73)).toFixed(2);
        break;
        default:
    }
    return akim
}

export function iletkenBul(akim,faz,toprak,tur,kullanim){    
    let kesitSay=1;
    if(parseInt(faz)===3){
        kesitSay=4;
    }else if(parseInt(faz)===1){
        kesitSay=2;
    }
    if(toprak==="toprak-true"){
        kesitSay++
    }
    let akim1=akim;
     
    let iletkenler=Iletken.filter((t)=>t.Tur===tur)
        iletkenler=iletkenler.filter((t)=>t.KesitSayi===kesitSay)
    let adet=1
    let res="";
        while(res===""){ 
            akim1=akim/adet
            //iletkenler=iletkenKullanim(kullanim,akim1,iletkenler)
            for(var i in iletkenler){
                switch(kullanim){            
                    case "ToprakAK":
                         if(iletkenler[i].ToprakAK>=akim1){
                            res=iletkenler[i];
                            return {adet:adet,iletken:iletkenler[i]}                             
                        }
                     break;
                    case "BoruAK":
                         if(iletkenler[i].BoruAK>=akim1){
                            res=iletkenler[i];
                            return {adet:adet,iletken:iletkenler[i]}                             
                        }
                     break;
                    case "HavaAK":
                         if(iletkenler[i].HavaAK>=akim1){
                            res=iletkenler[i];
                             return {adet:adet,iletken:iletkenler[i]}                             
                        }
                     break;
                    default:            
                }
  
            }
             
        adet++    
 
     }
        
   
      
     
}
 
export function kesiciTMS(akim,faz,kisaDevreAkim,notr){
     let Kutup="";
    if((parseInt(faz)===3)||(faz==="3")){
        Kutup="3P";
        if(notr===true){Kutup="4P"; }
    }else if((parseInt(faz)===1)||(faz==="1")){
        Kutup="4P";
      //  if(notr===true){Kutup="2P"; }
    }

    let kesici=TMS.filter((t)=> ((t.Kutup===Kutup))&&(t.Akim>=akim))

    return  kesici[0] 
}
export function kesiciEMO_KOMPAKT(akim,faz,kisaDevreAkim,notr){
     let Kutup="";
   if((parseInt(faz)===3)||(faz==="3")){
       Kutup="3P";
        if(notr===true){Kutup="4P"; }
   }else if((parseInt(faz)===1)||(faz==="1")){
       Kutup="1P";  
   }
   let kesici=EMO_KOMPAKT.filter((t)=> ((t.Kutup===Kutup))&&(t.Akim>=akim))
   return  kesici[0] 
}
export function kesiciAOS(akim,faz,kisaDevreAkim,notr){
    let Kutup="";
    if((parseInt(faz)===3)||(faz==="3")){
        Kutup="3P";
        if(notr===true){Kutup="4P"; }
    }else if((parseInt(faz)===1)||(faz==="1")){
        Kutup="1P";
        if(notr===true){Kutup="2P"; }
    }

    let kesici=AOS.filter((t)=> ((t.Kutup===Kutup))&&(t.Akim>=akim))

    return  kesici[0] 
}
export function kesiciNH(akim,faz ){
    let Kutup="";
    if((parseInt(faz)===3)||(faz==="3")){
        Kutup="3P";
     }else if((parseInt(faz)===1)||(faz==="1")){
        Kutup="1P";
     }
    let kesici=NH.filter((t)=>((t.Kutup===Kutup))&&(t.Akim>=akim))
    return  kesici[0] 
}
export function trafoGucGuncelle(talepGuckVA){

    let trafoGuckVA=25;
    let trafoTMS=[32,40,48,56]
    let trafoKompakt=[10,11,27,28]
    let trafoKAKKompakt=[6,12,15,18,19,21,24,27,30,33,36,39,42]

    if(talepGuckVA>=25000){
        trafoGuckVA=50
        trafoTMS=[56,66,74]
        trafoKompakt=[12,13,29,30]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]
    }
    if(talepGuckVA>=50000){
        trafoGuckVA=100
        trafoTMS=[74,84,94,102]
        trafoKompakt=[14,15,31,32]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    if(talepGuckVA>=100000){
        trafoGuckVA=160
        trafoTMS=[102,112,120]
        trafoKompakt=[16,1733,34]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    if(talepGuckVA>=160000){
        trafoGuckVA=200
        trafoTMS=[120,128,140]
        trafoKompakt=[17,18,34,35]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    if(talepGuckVA>=200000){
        trafoGuckVA=250
        trafoTMS=[128,140,152]
        trafoKompakt=[18,19,35,36]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    if(talepGuckVA>=250000){
        trafoGuckVA=315
        trafoTMS=[140,152,164]
        trafoKompakt=[19,20,36,37]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    if(talepGuckVA>=315000){
        trafoGuckVA=400
        trafoTMS=[152,164]
        trafoKompakt=[21,38]
        trafoKAKKompakt=[12,15,18,19,21,24,27,30,33,36,39,42]

    }
    
    return {trafoGuckVA:trafoGuckVA,TMS:trafoTMS,Kompakt:trafoKompakt,KAKKompakt:trafoKAKKompakt}
}
export function trafoGucGuncelle_EMO(talepGuckVA){

    let trafo=TrafoSecim_EMO.TRF50;   
    if(talepGuckVA>=50000){
        trafo=TrafoSecim_EMO.TRF100;      
    }
    if(talepGuckVA>=100000){
        trafo=TrafoSecim_EMO.TRF160;    }
    if(talepGuckVA>=160000){
        trafo=TrafoSecim_EMO.TRF200;
    }
    if(talepGuckVA>=200000){
        trafo=TrafoSecim_EMO.TRF250;
    }
    if(talepGuckVA>=250000){
        trafo=TrafoSecim_EMO.TRF315;    }
    if(talepGuckVA>=315000){
        trafo=TrafoSecim_EMO.TRF400;   
     }  

    return trafo
}