import { createSlice } from '@reduxjs/toolkit'

export const direkTipiTrafoSlice = createSlice({
  name: 'direktrafo',
  initialState: [
     
    { name: "Yükleme Cetveli",          section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
       { name: "OG Tek Hat Şeması",     section: "semalar",   durum: true, yer: 1, w: 800, h: 1461, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
    { name: "AG Tek Hat Şeması",        section: "semalar",   durum: false, yer: 1, w: 1800, h: 1800, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
   // { name: "OG ve AG Tek Hat Şeması", section: "semalar",  durum: false, yer: 1, w: 700, h: 1000, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
    { name: "Kompanzasyon Şeması",      section: "semalar",   durum: false, yer: 1, w: 800, h: 800, x: 600, y: 900, color: "#ffff00" , kolonlar: []},
   // { name: "AG-OG ve Komp. Tek Hat Şeması", section: "semalar", durum: false, yer: 1, w: 700, h: 400, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
    { name: "OG Gerilim Düşümü Hesabı", section: "semalar",   durum: true, yer: 1, w: 700, h: 301, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
    { name: "AG Gerilim Düşümü Hesabı", section: "semalar",   durum: true, yer: 1, w: 700, h: 1400, x: 600, y: 900, color: "#ffff00", kolonlar: [] },
    { name: "OG Akım Hesabı",           section: "raporlar",  durum: false, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "AG Akım Hesabı",           section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Kısa Devre Akım Hesabı",   section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Trafo Gücü Hesabı",        section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Keşif",                    section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Kompanzasyon Hesabı",      section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000" , kolonlar: []},
    { name: "Aciklama Raporu",          section: "raporlar",  durum: true, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Dilekce",                  section: "raporlar",  durum: true, yer: 2, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Enerji Musaadesi",         section: "raporlar",  durum: false, yer: 1, w: 400, h: 400, x: 600, y: 900, color: "#ff0000", kolonlar: [] },
    { name: "Kat Planı",                section: "planlar",   durum: false, yer: 1, w: 1, h: 1400, x: 600, y: 900, color: "#0000ff", kolonlar: [] },
    { name: "Vaziyet Planı",            section: "planlar",   durum: false, yer: 1, w: 1, h: 1400, x: 600, y: 900, color: "#0000ff", kolonlar: [] },
    { name: "Direk Detayları",          section: "detaylar",  durum: true, yer: 1, w: 500, h: 400, x: 600, y: 900, color: "#0fffff0", kolonlar: [] },
    { name: "Pano Detayları",           section: "detaylar",  durum: true, yer: 1, w: 500, h: 750, x: 600, y: 900, color: "#0fffff0", kolonlar: [] },
    { name: "Sembol Listesi",           section: "baslık",    durum: true, yer: 1, w: 750, h: 750, x: 600, y: 900, color: "#f0000f", kolonlar: [] },
    { name: "Baslik",                   section: "baslık",    durum: true, yer: 2, w: 500, h: 750, x: 600, y: 900, color: "#f0000f" , kolonlar: []},
    { name: "AG Akim Trafo Hesabı",     section: "raporlar",  durum: true, yer: 2, w: 500, h: 750, x: 600, y: 900, color: "#f0000f" , kolonlar: []},
    { name: "son",                      section: "semalar",   durum: true, yer: 1, w: 1, h: 1, x: 700, y: 900, color: "#ffff00" , kolonlar: []}
      
       
 
    
  ],
  reducers: {
   
    direkTrafoAct: (state, action) => {
      state.value = action.payload
    },   
     enAct:(state,action)=>{
      const { name, w } = action.payload
      const icerik = state.find((m) => m.name === name)
       icerik.w=w
     },
       durumAct:(state,action)=>{
      const { name, durum } = action.payload
      const icerik = state.find((m) => m.name === name)
         icerik.durum=durum
     }
    }
})

export const { direkTrafoAct,enAct,durumAct } = direkTipiTrafoSlice.actions

export default direkTipiTrafoSlice.reducer