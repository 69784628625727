
import React, { useEffect, useState } from 'react'
import { Form, Table, Modal, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {UpdateOGHatMetre,UpdateOGHat,UpdateOGHatNo,UpdateOGHatTur,UpdateCosFi,UpdateSinFi} from '../reducers/ogHatDetaySlice'
 

export default function OGHatDetay(props) {
  const OGHatDetay=useSelector((state)=>state.OGHatDetay)
  const dispatch=useDispatch()
  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");

  const [hattipi,setHattipi]=useState("havai");
  const [oGHat,setOGHat]=useState(OGHatDetay.OGhat);
  const [oGHatNo,setOGHatNo]=useState(OGHatDetay.OGhatNo);
  const [oGHatMetre,setOGHatMetre]=useState(OGHatDetay.OGhatMetre);
  const [cosFi,setCosFi]=useState(OGHatDetay.cosFi);
  const [sinFi,setSinFi]=useState(OGHatDetay.sinFi);


 
  function Show() { setToolShow(!toolshow) }

  useEffect(() => {
    switch(oGHatNo){
      case "0":
        setOGHat("3 AWG Swallow")
        break;
      case "1":
        setOGHat("1/0 AWG Rawen")
        break;
      case "2":
        setOGHat("3/0 AWG Pigeon")
        break;
      case "5":
        setOGHat("25mm2 XLPE")
        break;
      case "6":
        setOGHat("35mm2 XLPE")
        break;
      case "7":
        setOGHat("50mm2 XLPE")
        break;
      case "8":
        setOGHat("70mm2 XLPE")
        break;
      case "9":
        setOGHat("95mm2 XLPE")
        break;
      case "10":
        setOGHat("120mm2 XLPE")
        break;
      default:
    }
    dispatch(UpdateOGHat(oGHat));
    dispatch(UpdateOGHatNo(oGHatNo))
   }, [oGHatNo])
  useEffect(() => {
    dispatch(UpdateCosFi(parseFloat(cosFi)))
    setSinFi(Math.sin(Math.acos(cosFi) ))
    dispatch(UpdateSinFi(sinFi))
   }, [cosFi])

  useEffect(() => {
    dispatch(UpdateOGHatMetre(oGHatMetre));
  }, [oGHatMetre])

  useEffect(() => {
    if(hattipi==="havai"){

      setOGHatNo(0)
      setOGHat("3 AWG Swallow") 
    }else{
      setOGHat("25mm2 XLPE")
    }
    dispatch(UpdateOGHatTur(hattipi));
  }, [hattipi])

  useEffect(() => {
    switch(oGHat){
      case "3 AWG Swallow":
        setOGHatNo(0)
        dispatch(UpdateOGHatNo(0));
        break;
      case "1/0 AWG Raven":
        setOGHatNo(1)
        dispatch(UpdateOGHatNo(1));
        break;
      case "3/0 AWG Pigeon":
        setOGHatNo(2)
        dispatch(UpdateOGHatNo(2));   
        break;
      case "25mm2 XLPE":
        setOGHatNo(5)
        dispatch(UpdateOGHatNo(5));      
        break;
      case "35mm2 XLPE":
        setOGHatNo(6)
        dispatch(UpdateOGHatNo(6));       
        break;
      case "50mm2 XLPE":
        setOGHatNo(7)
        dispatch(UpdateOGHatNo(7));      
        break;
      case "70mm2 XLPE":
        setOGHatNo(8)
        dispatch(UpdateOGHatNo(8));       
        break;
      case "95mm2 XLPE":
        setOGHatNo(9)
        dispatch(UpdateOGHatNo(9));    
        break;
      case "120mm2 XLPE":
        setOGHatNo(10)
        dispatch(UpdateOGHatNo(10));   
        break;
      default:
    }
    dispatch(UpdateOGHat(oGHat));

  }, [oGHat])

  return (
    <React.Fragment >
      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>OG Hat Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>
  
        <tr>
            <td>
              Cos Φ
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <input style={{ width: "75px" }} className="my-auto border border-0" type='number' max="1" min="0.5" placeholder={OGHatDetay.cosFi}  step="0.01" onChange={e=>setCosFi(e.target.value )}/>
            </td>
          </tr>
              
          <tr>
            <td>
              Sin Φ
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
               {sinFi.toFixed(2)}
            </td>
          </tr>
          <tr>
            <td>
              OG Hattı Tipi
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
              <Form.Select aria-label="example" defaultValue={hattipi} onChange={e=>setHattipi(e.target.value)}  >
                <option value="yeralti">Yeraltı</option>
                <option value="havai">Havai</option>            
              </Form.Select>            
            </td>
          </tr>
{hattipi==="havai"?
          <tr>
            <td>
             OG Hattı İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={oGHat} onChange={e=>setOGHat(e.target.value)} >
                <option value="3 AWG Swallow">3 AWG Swallow</option>
                <option value="1/0 AWG Raven">1/0 AWG Raven</option>            
                <option value="3/0 AWG Pigeon">3/0 AWG Pigeon</option>            
              </Form.Select>            
            </td>
          </tr>:null}
          
{hattipi==="yeralti"?
<tr>
            <td>
             OG Hattı İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={oGHat} onChange={e=>setOGHat(e.target.value)} >
                <option value="25mm2 XLPE">25mm2 XLPE</option>
                <option value="35mm2 XLPE">35mm2 XLPE</option>
                <option value="50mm2 XLPE">50mm2 XLPE</option>
                <option value="70mm2 XLPE">70mm2 XLPE</option>
                <option value="95mm2 XLPE">95mm2 XLPE</option>
                <option value="120mm2 XLPE">120mm2 XLPE</option>
               
              </Form.Select>            
            </td>
          </tr>
  :null}
  <tr>
            <td>
             OG Hattı Uzunluğu (m)
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
             <input type="number" min={10} step={10} placeholder={oGHatMetre} onChange={e=>setOGHatMetre(e.target.value)} />
            </td>
          </tr>
 
       

          
        </tbody>
      </Table>




    </React.Fragment>





  )

}
