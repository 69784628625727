 
import 'bootstrap/dist/css/bootstrap.min.css'
import { HashRouter,  Routes, Route } from "react-router-dom";
import React from "react";

import NavigationBar from "./components/Navbar";
import Home from "./components/Home";
import Hakkimizda from "./components/Hakkimizda";
import OverView from "./components/Overview";
import Iletisim from './components/Iletisim';
import Financials from './components/Financials';
import ExecutiveSummary from './components/ExecusiveSummer';
import DirekTipiTrafo from './UserCadEditors/DirekTipiTrafo4';
import Badur from './components/badur';
import PitchDeck from './components/PitchDeck';
import Contact from './components/Contact'

function App() {

  let editor=true
  const editorIndex=window.location.href.indexOf('editor');
   if(editorIndex>0){     
    editor=false
   }

  return (
    <div  >
      <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
       <HashRouter>
   
    <NavigationBar  />
         <div style={{paddingTop:"56px", paddingBottom:"140px"}}>
          <Routes>
          <Route path="" element={<Home/>} exact />
          <Route path="/hakkimizda" element={<Hakkimizda/>}   />
          <Route path="/overview" element={<OverView/>}   />
          <Route path="/projehub-financials" element={<Financials/>} />
          <Route path="/executivesummary" element={<ExecutiveSummary/>} />
          <Route path="/pitchdeck" element={< PitchDeck/>} />
          <Route path="/iletisim" element={<Iletisim/>}   />
           <Route path="/direktipitrafoeditor" element={<DirekTipiTrafo/>}   />
           <Route path="/badur" element={<Badur/>}   />
           <Route path="/contact" element={<Contact/>}   />
        
          </Routes>
         
          </div>
         <div className='Footer'    style={{height:"140px"}}> <br/>
         <h5>www.trafoproje.com </h5>
         <h5>iletisim@trafoproje.com</h5>
         <h6 style={{color:"black"}}>Tüm Hakları Saklıdır</h6>
         <span style={{color:"black"}}>Email:  <a href="mailto:irfanbadur@outlook.com">irfanbadur@outlook.com</a> cep 535 372 66 15</span>

       
    
    </div>    

          </HashRouter> 
    </div>
   
  );
}

export default App;
