
import React, { useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
//import { sayacdata } from '../reducers/sayacbolmeSlice'
import { KisaDevreAkimlariHesap } from '../Data/testCizimler'
import { UpdateSecim } from '../reducers/cizimOzellik'

let X = 0;
let Y = 0;
let denemeCizim = []

export default function KisaDevreAkimHesap(props) {
    const [bgcolor, setBgcolor] = useState("white");
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetaylar = useSelector((state) => state.PanoDetay) 


    let Scl = cizimOzellik.Scl;
    let Scl2 = 2.25; Scl *= Scl2;

    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 800 * Scl, h: 555 * Scl }, cizim: denemeCizim })
    const pano = useSelector((state) => state.PanoDetay)
    let semaH = 900
    let CikisAra = 30

    let cikislarK = pano.Cikislar
    const objectsMap = new Map();
    cikislarK.forEach((object) => {
        objectsMap.set(object.Mesafe / object.iletkenKesit, object);
    });
    const cikisdizi = Array.from(objectsMap)

    let Cikislar = []
    for (var a in cikisdizi) {
        Cikislar.push(cikisdizi[a][1])
    }

    if (Cikislar.length >= 13) {
        semaH = CikisAra * Cikislar.length * 2 + 330 * Scl
    }
    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }
    const dispatch = useDispatch()
    let textRenk = cizimOzellik.KDAHTextRenk
    let cizimRenk = cizimOzellik.KDAHCizimRenk
    let cerceveRenk = cizimOzellik.KDAHCerceveRenk
 
    function artirW(val) {
        setCizim(prmt => ({ 
            ...prmt,    
            attr: {    
                x: 0, y: 0,
                w: 880 * Scl,  
                h: semaH * Scl,  
            }, 
            cizim: KisaDevreAkimlariHesap(X, Y, Scl, pano, {
                textRenk: textRenk,
                cizimRenk: cizimRenk,
                cerceveRenk: cerceveRenk ,
                Guc:trafoDetaylar.trafoGuckVA ,
                inisMesafe:panoDetaylar.InisMesafe
            })  
        }));
    }
    useEffect(() => { 
        artirW(400)
    }, [cizimOzellik, pano]);
    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);

    function Sec(val, val2, val3) {
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }



    return (

        <React.Fragment>

            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
                style={{ width: "98%", background: bgcolor }}
            >
                <thead>
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={Show}> KISA DEVRE AKIM HESABI GÖRSEL AYARLARI</th>
                    </tr>
                </thead>
                <tbody style={toolshow ? { display: "block", overflowY: "scroll", height: "360px" } : { display: "none" }}>
                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }}>Çerçeve Renk<Button size="sm" style={{ width: "30px", height: "20px", background: cerceveRenk, float: "right" }} onClick={() => Sec("KDAHCerceveRenk", "Çerçeve Renk", cerceveRenk)}></Button></td><td></td></tr>
                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }} >Çizim <Button size="sm" style={{ width: "30px", height: "20px", background: cizimRenk, float: "right" }} onClick={() => Sec("KDAHCizimRenk", "Çizim", cizimRenk)}></Button></td><td></td></tr>
                    <tr style={{ width: "100%" }}><td style={{ width: "100%" }} >Text<Button size="sm" style={{ width: "30px", height: "20px", background: textRenk, float: "right" }} onClick={() => Sec("KDAHTextRenk", "Text ", textRenk)}></Button></td><td></td></tr>


                </tbody>
            </Table>
        </React.Fragment>
    )

}
