 const  icerik={
  menu:{
  OverView:{tr:"Genel Bakış",eng:"Over View"},
  HomePage:{tr:"Ana Sayfa",eng:"HomePage"},
  PitchDeck:{tr:"Yatırımcı Sunumu",eng:"PitchDeck"},
  ExecutiveSummary:{tr:"Yönetici Özeti",eng:"ExecutiveSummary"},
  Financials:{tr:"Finans",eng:"Financials"},
  Documents:{tr:"Doküman",eng:"Documents"},
 },
 OverView:{
  tr:"Nef Aydınlatma Enerji Mühendislik 2013 yılında Samsun'da kuruldu. Kuruluşundan itibaren sürekli olarak Elektrik Projeleri hazırlamaktadır. İç Tesisat Projeleri, Şebeke Projeleri, Trafo Projeleri gibi elektrik projeleri hazırlıyor. Kuruluşunda 4 yıl kadar aydınlatma faaliyetlerinde bulundu. Aynı zamanda Şebeke Yapım işlerinde de faaliyet göstermiştir. 2019 yılı sonundan itibaren küçülmeye gitmiştir.",
 eng:"Nef Aydınlatma Enerji Mühendislik was founded in Samsun in 2013. Since its establishment, the company has been continuously preparing electrical projects. It prepares electrical projects such as Internal Electrical Installation Projects, Network Projects, and Transformer Projects. The company was involved in lighting activities for about 4 years after its establishment. It has also been active in Network Construction works. The company has been downsizing since the end of 2019."
},




irfanBadur:{
  tr:"Ben İrfan BADUR, 01.04.1981 tarihinde Samsun'da doğdum. 2007 yılında İstanbul Üniversitesi Elektrik-Elektronik Mühendisliği bölümünden mezun oldum. 2008 yılında BADUR mimarlık ve mühendisliği kurdum ve Serbest Müşavir Mühendis olarak Elektrik Projeleri hazırlamaya başladım. 2013 yılında Mimar olan ortağımla yolları ayırınca Nef Aydınlatma Enerji Mühendisliği kurarak çalışmalarıma devam ettim. Nef Aydınlatma Enerji ile ilk 4 yıl Aydınlatma sektöründe faaliyet gösterdik. 2017-2019 arasında ise Alçak Gerilim ve Orta Gerilim Şebekeleri Yapım işlerini gerçekleştirdik. 2019 yılında personel çıkışlarını yaparak Ofisimi kapattım. O tarihten itibaren aynı firma ile home ofis olarak proje faaliyetlerime devam ediyorum. ",
 eng:"I am İrfan BADUR, I was born in Samsun on 01.04.1981. I graduated from Istanbul University, Department of Electrical and Electronics Engineering in 2007. I founded 'BADUR Architecture and Engineering' in 2008 and started preparing Electrical Projects as a Freelance Consultant Engineer. When I parted ways with my partner, who is an architect, in 2013, I continued my work by establishing Nef Aydınlatma Energy Engineering. We operated in the Lighting sector with Nef Aydınlatma Enerji for the first 4 years. Between 2017 and 2019, we carried out Low Voltage and Medium Voltage Networks Construction works. In 2019, I closed my Office by laying off staff. Since then, I have been continuing my project activities with the same company as a home office."}



}
 
//const Overview={tr:"",eng:""}
export default    icerik ;
