import { createSlice } from '@reduxjs/toolkit'

export const cizimOzellikSlice = createSlice({
  name: 'cizimOzellik',
  initialState: {
    Scl: 0.5,
    Secim: " ",
    cerceveW:0,
    cerceveH:6000,
    DDCerRenk:"#009898" ,//direkDetay çerçeve renk
    DDZemRenk:"#994C00" ,//direkDetay zemin renk
    DDdirekRenk:"#007298" ,//direkDetay direk renk
    DDpanoRenk:"#0099CC" ,//direkDetay pano renk
    DDpanoTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDpanoTextGoster:true ,//direkDetay trafo renk
    DDtrafoRenk:"#007F00" ,//direkDetay trafo renk
    DDtrafoTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDtrafoTextGoster:true ,//direkDetay trafo renk
    DDtrafoGirisRenk:"#FB9E00" ,//direkDetay trafo renk
    DDtrafoInisRenk:"#0062B1" ,//direkDetay trafo renk
    DDtrafoKorRenk:"#653294" ,//direkDetay trafo renk
    DDtrafoKorTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDtrafoKorTextGoster:true ,//direkDetay trafo renk
    DDparafRenk:"#C0C0C0" ,//direkDetay trafo renk
    DDparafTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDparafTextGoster:true ,//direkDetay trafo renk
    DDparafGirisRenk:"#C0C0C0" ,//direkDetay trafo renk    
    DDparafInisRenk:"#808080" ,//direkDetay trafo renk
    DDSekRenk:"#808080" ,//direkDetay trafo renk
    DDSekIletkenRenk:"#808080" ,//direkDetay trafo renk
    DDSekTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDSekTextGoster:true ,//direkDetay trafo renk
    DDSekKolRenk:"#C0C0C0" ,//direkDetay trafo renk
    DDSekGirisRenk:"#FB9E00" ,//direkDetay trafo renk
    DDKorkulukRenk:"#007298" ,//direkDetay trafo renk
    DDotlRenk:"#BFFF00" ,//direkDetay trafo renk    
    DDotlTextRenk:"#FFFFFF" ,//direkDetay trafo renk    
    DDhatIletkenRenk:"#808080" ,//direkDetay trafo renk
    DDHatTextRenk:"#FFFFFF" ,//direkDetay trafo renk
    DDHatTextGoster:true ,//direkDetay trafo renk  
    DDizolatorRenk:"#A50000" ,//direkDetay trafo renk
    DDIsletmeRenk:"#194D33" ,//direkDetay trafo renk
    DDtopKazRenk:"#808080" ,//direkDetay trafo renk

    KDAHCerceveRenk:"#009898" ,//direkDetay trafo renk
    KDAHCizimRenk:"#00CC99" ,//direkDetay trafo renk
    KDAHTextRenk:"#FFFFFF" ,//direkDetay trafo renk

    DOCCerceveRenk:"#009898" ,
    DOCTextRenk:"#FFFFFF" ,

  },
  reducers: {  
    UpdateScl: (state, action) =>       {state.Scl  = action.payload },
    UpdateSecim: (state, action) =>       {state.Secim  = action.payload },
    UpdateCerceveW: (state, action) =>       {state.cerceveW  = action.payload },
    UpdateCerceveH: (state, action) =>       {state.cerceveH  = action.payload },

    UpdateDDCerRenk: (state, action) => {state.DDCerRenk  = action.payload },
    UpdateDDZemRenk: (state, action) => {state.DDZemRenk  = action.payload },
    UpdateDDdirekRenk: (state, action) => {state.DDdirekRenk  = action.payload },
    UpdateDDpanoRenk: (state, action) => {state.DDpanoRenk  = action.payload },
    UpdateDDtrafoRenk: (state, action) => {state.DDtrafoRenk  = action.payload },
    UpdateDDtrafoTextRenk: (state, action) => {state.DDtrafoTextRenk  = action.payload },
    UpdateDDtrafoGirisRenk: (state, action) => {state.DDtrafoGirisRenk  = action.payload },
    UpdateDDtrafoInisRenk: (state, action) => {state.DDtrafoInisRenk  = action.payload },
    UpdateDDtrafoKorRenk: (state, action) => {state.DDtrafoKorRenk  = action.payload },
    UpdateDDtrafoKorTextRenk: (state, action) => {state.DDtrafoKorTextRenk  = action.payload },
    UpdateDDparafRenk: (state, action) => {state.DDparafRenk  = action.payload },
    UpdateDDparafTextRenk: (state, action) => {state.DDparafTextRenk  = action.payload },
    UpdateDDparafGirisRenk: (state, action) => {state.DDparafGirisRenk  = action.payload },
    UpdateDDparafInisRenk: (state, action) => {state.DDparafInisRenk  = action.payload },
    UpdateDDSekRenk: (state, action) => {state.DDSekRenk  = action.payload },
    UpdateDDSekIletkenRenk: (state, action) => {state.DDSekIletkenRenk  = action.payload },
    UpdateDDSekTextRenk: (state, action) => {state.DDSekTextRenk  = action.payload },
    UpdateDDSekKolRenk: (state, action) => {state.DDSekKolRenk  = action.payload },
    UpdateDDSekGirisRenk: (state, action) => {state.DDSekGirisRenk  = action.payload },
    UpdateDDotlRenk: (state, action) => {state.DDotlRenk  = action.payload },
    UpdateDDotlTextRenk: (state, action) => {state.DDotlTextRenk  = action.payload },
    UpdateDDhatIletkenRenk: (state, action) => {state.DDhatIletkenRenk  = action.payload },
    UpdateDDHatTextRenk: (state, action) => {state.DDHatTextRenk  = action.payload },
    UpdateDDizolatorRenk: (state, action) => {state.DDizolatorRenk  = action.payload },
    UpdateDDIsletmeRenk: (state, action) => {state.DDIsletmeRenk  = action.payload },
    UpdateDDtopKazRenk: (state, action) => {state.DDtopKazRenk  = action.payload },
    UpdateDDKorkulukRenk: (state, action) => {state.DDKorkulukRenk  = action.payload },
    UpdateDDpanoTextRenk: (state, action) => {state.DDpanoTextRenk  = action.payload },
    UpdateDDpanoTextGoster: (state, action) => {state.DDpanoTextGoster  = action.payload },
    UpdateDDtrafoTextGoster: (state, action) => {state.DDtrafoTextGoster  = action.payload },
    UpdateDDtrafoKorTextGoster: (state, action) => {state.DDtrafoKorTextGoster  = action.payload },
    UpdateDDparafTextGoster: (state, action) => {state.DDparafTextGoster  = action.payload },
    UpdateDDSekTextGoster: (state, action) => {state.DDSekTextGoster  = action.payload },
    UpdateDDHatTextGoster: (state, action) => {state.DDHatTextGoster  = action.payload },

    UpdateKDAHCerceveRenk: (state, action) => {state.KDAHCerceveRenk  = action.payload },
    UpdateKDAHCizimRenk: (state, action) => {state.KDAHCizimRenk  = action.payload },
    UpdateKDAHTextRenk: (state, action) => {state.KDAHTextRenk  = action.payload },


    UpdateDOCCerceveRenk: (state, action) => {state.DOCCerceveRenk  = action.payload },  
    UpdateDOCTextRenk: (state, action) => {state.DOCTextRenk  = action.payload },  


  }, 
})
  
export const {UpdateCerceveW,UpdateCerceveH,UpdateDDotlTextRenk,UpdateDOCTextRenk,UpdateDOCCerceveRenk,UpdateKDAHTextRenk,UpdateKDAHCizimRenk, UpdateKDAHCerceveRenk,UpdateScl,UpdateSecim,UpdateDDCerRenk,UpdateDDZemRenk,UpdateDDdirekRenk,UpdateDDpanoRenk,UpdateDDtrafoTextRenk,UpdateDDtrafoGirisRenk,UpdateDDtrafoInisRenk,UpdateDDtrafoKorRenk,UpdateDDtrafoKorTextRenk,UpdateDDparafRenk,UpdateDDparafTextRenk,UpdateDDparafGirisRenk,UpdateDDparafInisRenk,UpdateDDSekRenk,UpdateDDSekIletkenRenk,UpdateDDSekTextRenk,UpdateDDSekKolRenk,UpdateDDSekGirisRenk,UpdateDDotlRenk,UpdateDDtrafoRenk,UpdateDDhatIletkenRenk,UpdateDDHatTextRenk,UpdateDDizolatorRenk,UpdateDDIsletmeRenk ,UpdateDDtopKazRenk,UpdateDDKorkulukRenk,UpdateDDpanoTextRenk,UpdateDDpanoTextGoster,UpdateDDtrafoTextGoster,UpdateDDtrafoKorTextGoster,UpdateDDparafTextGoster,UpdateDDSekTextGoster,UpdateDDHatTextGoster} = cizimOzellikSlice.actions

export default cizimOzellikSlice.reducer    

 