
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
//import { SembolListesi } from '../Data/cizimlerGecici'
import { SembolListesi } from '../Data/testCizimler'
let denemeCizim = []
export default function SembolList(props) {

    const [showSayacBolme, setShowSayacBolme] = useState(false);
    const [bgcolor, setBgcolor] = useState("white");
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let Scl = cizimOzellik.Scl;
    let Scl2 = 1.85; Scl *= Scl2;

    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 800 * Scl, h: 555 * Scl }, cizim: denemeCizim })

    function sayacBolmeShow() { setShowSayacBolme(!showSayacBolme) }

    function artirW(val) {
        setCizim(prmt => ({
            ...prmt,
            attr: {
                x: 0, y: 0,
                w: 1046 * Scl,
                h: 1399 * Scl,
            },
            cizim: SembolListesi(0, 0, Scl)
        }));
    }
    useEffect(() => {
        artirW(400)
    }, [cizimOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);
    return (
        <React.Fragment>
            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1  '
                style={{ width: "98%", background: bgcolor }}
            >

                <thead >
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={sayacBolmeShow}>Sembol Listesi Ayarları</th>
                    </tr>
                </thead>

                <tbody style={showSayacBolme ? { display: "block" } : { display: "none" }}>
                    <tr  >
                        <td >Üç Faz Sayaç Sayısı</td>
                        <td >
                            <input style={{ width: "75px", background: bgcolor }} onClick={e => artirW(parseInt(e.target.value))} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td  >Tek Faz Sayaç Sayısı</td>
                        <td  >
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum ÜçFaz Satır</td>
                        <td>
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum TekFaz Satır</td>
                        <td>
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td >Pano Kapak Maksimum Genişlik</td>
                        <td><input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>

                </tbody>

            </Table>

        </React.Fragment>
    )

}
