
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0;
let Scl=0.5;
let  denemeCizim=[{ Ad: "Polyline", layer: "0", Color: "#FF00FF ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: [X + Scl * (0), Y + Scl * (0), X + Scl * (800), Y + Scl * (0), X + Scl * (800), Y + Scl * (700), X + Scl * (0), Y + Scl * (700)] }]



export default function Dilekce(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk
    let TrfGuc=trafoDetaylar.trafoGuckVA

    const GenelBilgiler = useSelector((state) => state.genelBilgiler)
    let proYap=GenelBilgiler.PY_AdSoyad

     let dilekceText1="  YEŞİL IRMAK ELEKTRİK DAĞITIM A.Ş. SAMSUN İL KOORDİNATÖRLÜĞÜ"
    let dilekceText2=  "      Ekte sunulan "+proYap+"’ye  ait  "+TrfGuc+"kVA  trafo projesi tasdik edilerek tarafımıza verilmesini arz ederiz."

    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
    const H=400
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
 
    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:H*Scl,                          
            },
            cizim:[{ Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (0), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (0),  
                X + Scl * (500), Y + Scl * (H), 
                X + Scl * (0), Y + Scl * (H)
            ] },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl * 15,align:"center", TextString:dilekceText1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl * 15,align:"justify", TextString:dilekceText2, TextStyleName: "  1", TextArc: 0,width:400*Scl }]
             
            
        })); 
    }
    useEffect(() => {       
        artirW(400)
        
    }, [cizimOzellik,trafoDetaylar,GenelBilgiler   ]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
 
    return (       <>       </>   )

}
