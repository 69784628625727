import { createSlice } from '@reduxjs/toolkit'

export const SemaCizimSlice = createSlice({ 
  name: 'SemaCizim',
  initialState: {
    OGTHCerRenkOG:"#0099CC" ,//oG çerçeve renk
    OGTHCerRenkAG:"#0099CC" ,//AG çerçeve renk
    OGTHCerRenkKomp:"#0099CC" ,//Kompanzasyon çerçeve renk

    OGTHCizimRenk:"#FF0000" ,//oG cizim renk
    OGTHCizimTextRenk:"#FFFFFF" ,//oG  text renk

    OGTHdirekRenk:"#FF0000" ,//oG direk renk
    OGTHdirekTextRenk:"#FFFFFF" ,//oG direk text renk

    OGTHtrafoRenk:"#FF0000" ,//oG trafo renk
    OGTHtrafoTextRenk:"#FFFFFF" ,//oG trafo text renk
    OGTHtrafoGirisRenk:"#FF0000" ,//oG trafo giriş renk
    OGTHtrafoInisRenk:"#FF0000" ,//oG trafo renk

    OGTHparafRenk:"#FF0000" ,//oG trafo renk
    OGTHparafTextRenk:"#FFFFFF" ,//oG trafo renk
    OGTHparafGirisRenk:"#FF0000" ,//oG trafo renk    
    OGTHparafInisRenk:"#FF0000" ,//oG trafo renk

    OGTHSekRenk:"#FF0000" ,//oG trafo renk
    OGTHSekIletkenRenk:"#FF0000" ,//oG trafo renk
    OGTHSekTextRenk:"#FFFFFF" ,//oG trafo renk    
    OGTHSekGirisRenk:"#FB9E00" ,//oG trafo renk

     
    OGTHSayacRenk:"#FF0000" ,//oG Sayac renk   
    OGTHSayacTRenk:"#FFFFFF" ,//oG Sayac renk   
    OGTHIcIhtRenk:"#FF0000" ,//oG iç ihtiyaç renk   
    OGTHIcIhtTRenk:"#FFFFFF" ,//oG   renk   
    OGTHSGrpRenk:"#FF0000" ,//oG   renk   
    OGTHSGrpTRenk:"#FFFFFF" ,//oG   renk   
    OGTHSinyalRenk:"#FF0000" ,//oG   renk   
    OGTHSinyalTRenk:"#FFFFFF" ,//oG   renk   
    OGTHKesiciRenk:"#FF0000" ,//oG   renk   
    OGTHKesiciTRenk:"#FFFFFF" ,//oG   renk   
    OGTHAkimTrfRenk:"#FF0000" ,//oG   renk   
    OGTHAkimTrfTextRenk:"#FFFFFF" ,//oG   renk 
     
    OGTHtopRenk:"#FF0000" ,//oG   renk
    OGTHtopTextRenk:"#FFFFFF" ,//oG   renk
    //--------------------------------------------------
    AGTHTextRenk:"#FFFFFF" ,//AG   renk
    AGTHBaraRenk:"#FFBF00" ,//aG   renk
    AGTHBaraTeRenk:"#FFFFFF" ,//aG   renk
    AGTHKutuRenk:"#FF0000" ,//aG   renk
    AGTHKesiciRenk:"#FF0000" ,//aG   renk
    AGTHKesiciTeRenk:"#FFFFFF" ,//aG   renk
    AGTHIletRenk:"#FF0000" ,//aG   renk
    AGTHIletTeRenk:"#FFFFFF" ,//aG   renk
    AGTHYukTeRenk:"#FFFFFF" ,//aG   renk
    AGTHNoTeRenk:"#FFFFFF" ,//aG   renk
    AGTHGirisIletTeRenk:"#FFFFFF" ,//aG   renk
    AGTHKompIletTeRenk:"#FFFFFF" ,//aG   renk  
    //-------------------------------------------------- 
    KTextRenk:"#FFFFFF" ,//Kompanzasyon   renk
    KBaraRenk:"#FFBF00" ,//Kompanzasyon trafo renk
    KBaraTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KKondRenk:"#FF0000" ,//Kompanzasyon trafo renk
    KKondTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KAOSRenk:"#FF0000" ,//Kompanzasyon trafo renk
    KAOSTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KKontRenk:"#FF0000" ,//Kompanzasyon trafo renk
    KKontTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KIletRenk:"#FF0000" ,//Kompanzasyon trafo renk 
    KIletTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KYukTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KNoTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KGirisIletTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KKIletTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KAkimTrfRenk:"#FF0000" ,//Kompanzasyon trafo renk
    KAkimTrfTeRenk:"#FFFFFF" ,//Kompanzasyon trafo renk
    KAnaHatTeRenk:"#FFFFFF" ,
    KAnaHatRenk:"#FF0000" ,
    KAkimTrfIletRenk:"#FF0000" ,

  },
  reducers: {  
  UpdateOGTHCerRenkOG: (state, action) => {state.OGTHCerRenkOG  = action.payload },
  UpdateOGTHCerRenkAG: (state, action) => {state.OGTHCerRenkAG  = action.payload },
  UpdateOGTHCerRenkKomp: (state, action) => {state.OGTHCerRenkKomp  = action.payload },
  UpdateOGTHdirekRenk: (state, action) => {state.OGTHdirekRenk  = action.payload },
  UpdateOGTHdirekTextRenk: (state, action) => {state.OGTHdirekTextRenk  = action.payload },
  UpdateOGTHCizimRenk: (state, action) => {state.OGTHCizimRenk  = action.payload },
  UpdateOGTHCizimTextRenk: (state, action) => {state.OGTHCizimTextRenk  = action.payload },
  UpdateOGTHtrafoRenk: (state, action) => {state.OGTHtrafoRenk  = action.payload },
  UpdateOGTHtrafoTextRenk: (state, action) => {state.OGTHtrafoTextRenk  = action.payload },
  UpdateOGTHtrafoGirisRenk: (state, action) => {state.OGTHtrafoGirisRenk  = action.payload },
  UpdateOGTHtrafoInisRenk: (state, action) => {state.OGTHtrafoInisRenk  = action.payload },
  UpdateOGTHtrafoKorRenk: (state, action) => {state.OGTHtrafoKorRenk  = action.payload },
  UpdateOGTHtrafoKorTextRenk: (state, action) => {state.OGTHtrafoKorTextRenk  = action.payload },
  UpdateOGTHparafRenk: (state, action) => {state.OGTHparafRenk  = action.payload },
  UpdateOGTHparafTextRenk: (state, action) => {state.OGTHparafTextRenk  = action.payload },
  UpdateOGTHparafGirisRenk: (state, action) => {state.OGTHparafGirisRenk  = action.payload },
  UpdateOGTHparafInisRenk: (state, action) => {state.OGTHparafInisRenk  = action.payload },
  UpdateOGTHSekRenk: (state, action) => {state.OGTHSekRenk  = action.payload },
  UpdateOGTHSekIletkenRenk: (state, action) => {state.OGTHSekIletkenRenk  = action.payload },
  UpdateOGTHSekTextRenk: (state, action) => {state.OGTHSekTextRenk  = action.payload },
  UpdateOGTHSekGirisRenk: (state, action) => {state.OGTHSekGirisRenk  = action.payload },
  UpdateOGTHSayacRenk: (state, action) => {state.OGTHSayacRenk  = action.payload },
  UpdateOGTHSayacTRenk: (state, action) => {state.OGTHSayacTRenk  = action.payload },
  UpdateOGTHIcIhtRenk: (state, action) => {state.OGTHIcIhtRenk  = action.payload },
  UpdateOGTHIcIhtTRenk: (state, action) => {state.OGTHIcIhtTRenk  = action.payload },
  UpdateOGTHSGrpRenk: (state, action) => {state.OGTHSGrpRenk  = action.payload },
  UpdateOGTHSGrpTRenk: (state, action) => {state.OGTHSGrpTRenk  = action.payload },
  UpdateOGTHSinyalRenk: (state, action) => {state.OGTHSinyalRenk  = action.payload },
  UpdateOGTHSinyalTRenk: (state, action) => {state.OGTHSinyalTRenk  = action.payload },
  UpdateOGTHKesiciRenk: (state, action) => {state.OGTHKesiciRenk  = action.payload },
  UpdateOGTHKesiciTRenk: (state, action) => {state.OGTHKesiciTRenk  = action.payload },
  UpdateOGTHAkimTrfRenk: (state, action) => {state.OGTHAkimTrfRenk  = action.payload },
  UpdateOGTHAkimTrfTextRenk: (state, action) => {state.OGTHAkimTrfTextRenk  = action.payload }, 
  UpdateOGTHtopRenk: (state, action) => {state.OGTHtopRenk  = action.payload }, 
  UpdateOGTHtopTextRenk: (state, action) => {state.OGTHtopTextRenk  = action.payload }, 
//---------------------------------------------------------------------------
  UpdateAGTHTextRenk: (state, action) => {state.AGTHTextRenk  = action.payload }, 
  UpdateAGTHBaraRenk: (state, action) => {state.AGTHBaraRenk  = action.payload }, 
  UpdateAGTHBaraTeRenk: (state, action) => {state.AGTHBaraTeRenk  = action.payload }, 
  UpdateAGTHKutuRenk: (state, action) => {state.AGTHKutuRenk  = action.payload }, 
  UpdateAGTHKesiciRenk: (state, action) => {state.AGTHKesiciRenk  = action.payload }, 
  UpdateAGTHKesiciTeRenk: (state, action) => {state.AGTHKesiciTeRenk  = action.payload }, 
  UpdateAGTHIletRenk: (state, action) => {state.AGTHIletRenk  = action.payload }, 
  UpdateAGTHIletTeRenk: (state, action) => {state.AGTHIletTeRenk  = action.payload }, 
  UpdateAGTHYukTeRenk: (state, action) => {state.AGTHYukTeRenk  = action.payload }, 
  UpdateAGTHGirisIletTeRenk: (state, action) => {state.AGTHGirisIletTeRenk  = action.payload }, 
  UpdateAGTHKompIletTeRenk: (state, action) => {state.AGTHKompIletTeRenk  = action.payload }, 
  UpdateAGTHNoTeRenk: (state, action) => {state.AGTHNoTeRenk  = action.payload },
    //------------------------------------------------
  UpdateKTextRenk: (state, action) => {state.KTextRenk  = action.payload }, 
  UpdateKBaraRenk: (state, action) => {state.KBaraRenk  = action.payload }, 
  UpdateKBaraTeRenk: (state, action) => {state.KBaraTeRenk  = action.payload }, 
  UpdateKKondRenk: (state, action) => {state.KKondRenk  = action.payload }, 
  UpdateKKondTeRenk: (state, action) => {state.KKondTeRenk  = action.payload }, 
  UpdateKAOSRenk: (state, action) => {state.KAOSRenk  = action.payload }, 
  UpdateKAOSTeRenk: (state, action) => {state.KAOSTeRenk  = action.payload }, 
  UpdateKKontRenk: (state, action) => {state.KKontRenk  = action.payload }, 
  UpdateKKontTeRenk: (state, action) => {state.KKontTeRenk  = action.payload }, 
  UpdateKIletRenk: (state, action) => {state.KIletRenk  = action.payload }, 
  UpdateKIletTeRenk: (state, action) => {state.KIletTeRenk  = action.payload }, 
  UpdateKYukTeRenk: (state, action) => {state.KYukTeRenk  = action.payload }, 
  UpdateKGirisIletTeRenk: (state, action) => {state.KGirisIletTeRenk  = action.payload }, 
  UpdateKKIletTeRenk: (state, action) => {state.KKIletTeRenk  = action.payload }, 
  UpdateKNoTeRenk: (state, action) => {state.KNoTeRenk  = action.payload },  
  UpdateKAkimTrfRenk: (state, action) => {state.KAkimTrfRenk  = action.payload },  
  UpdateKAkimTrfTeRenk: (state, action) => {state.KAkimTrfTeRenk  = action.payload },  
  UpdateKAkimTrfIletRenk: (state, action) => {state.KAkimTrfIletRenk  = action.payload },  
  UpdateKAnaHatRenk: (state, action) => {state.KAnaHatRenk  = action.payload },  
  UpdateKAnaHatTeRenk: (state, action) => {state.KAnaHatTeRenk  = action.payload }, 

  UpdateKAnaHatTeRenk: (state, action) => {state.KAnaHatTeRenk  = action.payload },  




  }  ,
  
  
})     
 

export const {UpdateKAnaHatTeRenk,UpdateKAnaHatRenk,UpdateKAkimTrfIletRenk,UpdateKAkimTrfRenk,UpdateKAkimTrfTeRenk,UpdateAGTHTextRenk,UpdateAGTHBaraRenk,UpdateAGTHNoTeRenk,UpdateAGTHBaraTeRenk,UpdateAGTHKutuRenk,UpdateAGTHKesiciRenk,UpdateAGTHKesiciTeRenk,UpdateAGTHIletRenk,UpdateAGTHIletTeRenk,UpdateAGTHYukTeRenk,UpdateAGTHGirisIletTeRenk,UpdateAGTHKompIletTeRenk,UpdateOGTHtopTextRenk, UpdateOGTHtopRenk,UpdateOGTHCerRenkOG,UpdateOGTHCerRenkAG,UpdateOGTHCerRenkKomp,UpdateOGTHdirekRenk,UpdateOGTHdirekTextRenk,UpdateOGTHCizimRenk,UpdateOGTHtrafoTextRenk,UpdateOGTHtrafoGirisRenk,UpdateOGTHparafRenk,UpdateOGTHparafTextRenk,UpdateOGTHparafGirisRenk,UpdateOGTHparafInisRenk,UpdateOGTHSekRenk,UpdateOGTHSekIletkenRenk,UpdateOGTHSekTextRenk,UpdateOGTHSekGirisRenk,UpdateOGTHtrafoRenk,UpdateOGTHtopKazRenk,UpdateOGTHKorkulukRenk,UpdateOGTHCizimTextRenk,UpdateOGTHSayacRenk,UpdateOGTHSayacTRenk,UpdateOGTHIcIhtRenk,UpdateOGTHIcIhtTRenk,UpdateOGTHSGrpRenk,UpdateOGTHSGrpTRenk,UpdateOGTHSinyalRenk,UpdateOGTHSinyalTRenk,UpdateOGTHKesiciRenk,UpdateOGTHKesiciTRenk,UpdateOGTHAkimTrfRenk,UpdateOGTHAkimTrfTextRenk,UpdateOGTHtrafoInisRenk,UpdateKTextRenk,UpdateKBaraRenk,UpdateKNoTeRenk,UpdateKBaraTeRenk,UpdateKKondRenk,UpdateKKondTeRenk,UpdateKAOSRenk,UpdateKAOSTeRenk,UpdateKKontRenk,UpdateKKontTeRenk,UpdateKIletRenk,UpdateKIletTeRenk,UpdateKYukTeRenk,UpdateKGirisIletTeRenk,UpdateKKIletTeRenk} = SemaCizimSlice.actions
 
export default SemaCizimSlice.reducer    

