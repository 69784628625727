import { createSlice } from '@reduxjs/toolkit'

export const direkDetaySlice = createSlice({
  name: 'OGHatDetay',
  initialState:  {
    cosFi:0.8,
    sinFi:0.6,
    OGhatTur:"havai",
    OGhatNo:0,
    OGhatKesit:34,
    OGhat:"3 AWG Swallow",
    OGhatMetre:100,
    OGGerilim:31.500,
  }
    
    
  ,
  reducers: {   
    UpdateOGHatMetre:       (state, action ) => {state.OGhatMetre = action.payload },    
    UpdateOGHatTur:         (state, action ) => {state.OGhatTur = action.payload },    
    UpdateOGHatNo:          (state, action ) => {state.OGhatNo = action.payload },    
    UpdateOGHat:            (state, action ) => {state.OGhat = action.payload },    
    UpdateCosFi:            (state, action ) => {state.cosFi = action.payload },    
    UpdateSinFi:            (state, action ) => {state.sinFi = action.payload },    
  
     
    }
})
 export const { UpdateOGHatTur,UpdateOGHatNo,UpdateOGHatMetre,UpdateOGHat,UpdateCosFi,UpdateSinFi } = direkDetaySlice.actions

export default direkDetaySlice.reducer 