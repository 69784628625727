import { createSlice } from '@reduxjs/toolkit'

export const direkDetaySlice = createSlice({
  name: 'direkDetay',
  initialState:  {
    tepeKuvveti:1500, //kg
    boy:1350, //cm
    tur:"demir",
    direk:"",
    seksiyoner:"36kVA 16kA 400A",
    seksiyonergiris:"Ф8",
    seksiyonerinis:"1x50mm2 NYY",
    parafudr:"36kVA 10kA",
    parafudrGiris:"Ф8",
    parafudrInis:"1x50mm2 NYY",
    parafudrTopraklama:"0.5mm2 Cu",
    parafudrKonum:"once",
    trafoGiris:"Ф8",
    trafoInis:"",
    trafoOran:"",
    trafoGuc:25,
    trafoisletmeinis:"1x50mm2 NYY",
    trafoTopraklama:"1m2Cu",
    KesifNo:2
  
  } 
  ,
  reducers: {   
    UpdateDirek:            (state, action ) => {state.direk = action.payload     },   
    UpdateSeksiyoner:       (state, action ) => {state.seksiyoner = action.payload },   
    UpdateSeksiyonergiris:  (state, action ) => {state.seksiyonergiris = action.payload},   
    UpdateSeksiyonerinis:   (state, action ) => {state.seksiyonerinis = action.payload},   
    UpdateParafudr:         (state, action ) => {state.parafudr = action.payload},   
    UpdateParafudrInis:     (state, action ) => {state.parafudrInis = action.payload },   
    UpdateParafudrGiris:    (state, action ) => {state.parafudrGiris = action.payload },   
    UpdateTrafoGiris:       (state, action ) => {state.trafoGiris = action.payload },
    UpdateTrafoOran:        (state, action ) => {state.trafoOran = action.payload },
    UpdateTrafoGuc:         (state, action ) => {state.trafoGuc = action.payload  },   
    UpdateTrafoInis:        (state, action ) => {state.trafoInis = action.payload },
    UpdateTrafoisletmeinis: (state, action ) => {state.trafoisletmeinis = action.payload  },    
    UpdateParafudrTopraklama:(state, action ) => {state.parafudrTopraklama = action.payload },    
    UpdateParafudrKonum:    (state, action ) => {state.parafudrKonum = action.payload },
    UpdateTrafoTopraklama:  (state, action ) => {state.trafoTopraklama = action.payload    },
    UpdateDirekTur:         (state, action ) => {state.tur = action.payload    }
     
    }
})
 export const { UpdateDirek,UpdateSeksiyoner,UpdateSeksiyonergiris,UpdateParafudr,UpdateParafudrInis,UpdateParafudrGiris,UpdateTrafoOran,UpdateTrafoGuc,UpdateTrafoInis,UpdateTrafoisletmeinis,UpdateParafudrTopraklama,UpdateParafudrKonum,UpdateTrafoTopraklama,UpdateSeksiyonerinis,UpdateDirekTur  } = direkDetaySlice.actions

export default direkDetaySlice.reducer 