export const TRFFiyatList = []
TRFFiyatList[0]={ malzeme: "TRANSFORMATÖR 33/0.4kV 25kVA", birim: "adet", malzemeTL: 26513.45, Montaj1: 7936.46, Montaj2: 8734.40, Montaj3: 10315.97 }
TRFFiyatList[1]={ malzeme: "TRANSFORMATÖR 33/0.4kV 40kVA", birim: "adet", malzemeTL: 30138.67, Montaj1: 8285.59, Montaj2: 9105.23, Montaj3: 10774.79 }
TRFFiyatList[2]={ malzeme: "TRANSFORMATÖR 33/0.4kV 50kVA", birim: "adet", malzemeTL: 33294.07, Montaj1: 8633.05, Montaj2: 9488.70, Montaj3: 11230.28 }
TRFFiyatList[3]={ malzeme: "TRANSFORMATÖR 33/0.4kV 63kVA", birim: "adet", malzemeTL: 35486.61, Montaj1: 8978.84, Montaj2: 9780.50, Montaj3: 11669.79 }
TRFFiyatList[4]={ malzeme: "TRANSFORMATÖR 33/0.4kV 80kVA", birim: "adet", malzemeTL: 36913.49, Montaj1: 9045.85, Montaj2: 9956.83, Montaj3: 11762.80 }
TRFFiyatList[5]={ malzeme: "TRANSFORMATÖR 33/0.4kV 100kVA", birim: "adet", malzemeTL: 41234.76, Montaj1: 9899.83, Montaj2: 10892.84, Montaj3: 12878.86 }
TRFFiyatList[6]={ malzeme: "TRANSFORMATÖR 33/0.4kV 125kVA", birim: "adet", malzemeTL: 459285.26, Montaj1: 10933.86, Montaj2: 12034.90, Montaj3: 14206.70 }
TRFFiyatList[7]={ malzeme: "TRANSFORMATÖR 33/0.4kV 160kVA", birim: "adet", malzemeTL: 55805.27, Montaj1: 11738.47, Montaj2: 13918.21, Montaj3: 15263.38 }
TRFFiyatList[8]={ malzeme: "TRANSFORMATÖR 33/0.4kV 200kVA", birim: "adet", malzemeTL: 59065.07, Montaj1: 12947.55, Montaj2: 14245.33, Montaj3: 16840.90 }
TRFFiyatList[9]={ malzeme: "TRANSFORMATÖR 33/0.4kV 250kVA", birim: "adet", malzemeTL: 68676.27, Montaj1: 13813.21, Montaj2: 15194.70, Montaj3: 17957.67 }
TRFFiyatList[10]={ malzeme: "TRANSFORMATÖR 33/0.4kV 315kVA", birim: "adet", malzemeTL: 80114.58, Montaj1: 15404.08, Montaj2: 16941.29, Montaj3: 20031.70 }
TRFFiyatList[11]={ malzeme: "TRANSFORMATÖR 33/0.4kV 400kVA", birim: "adet", malzemeTL: 86889.40, Montaj1: 16962.28, Montaj2: 18653.55, Montaj3: 22052.07 }
 
export const DirekFiyatList = [
	// Montaj fiyatları 169. satirdan eklendi
	//malzeme: 13.40 tl     montaj1:12.53tl 		montaj2:13.78tl			montaj3:16.28tl
	{ malzeme: "T15 Boyu 14 mt,Tepe Kuv.1500 Kg,Ağ.784Kg", birim: "adet", malzemeTL: 10505.0, Montaj1: 9823.52, Montaj2: 10803.52, Montaj3: 12763.52 }, // T-15      2220.Satir
	{ malzeme: "T25 Boyu 14 mt,Tepe Kuv.2500 Kg,Ağ.868Kg", birim: "adet", malzemeTL: 11631.0, Montaj1: 10876.04, Montaj2: 11961.04, Montaj3: 14131.04 }, // T-25     2230.Satir
	{ malzeme: "T35 Boyu 14 mt,Tepe Kuv.3500 Kg,Ağ.960Kg", birim: "adet", malzemeTL: 12864.0, Montaj1: 12028.80, Montaj2: 13228.80, Montaj3: 15628.80 }, // T-35     2240.Satir
	{ malzeme: "T50 Boyu 14 mt,Tepe Kuv.5000 Kg,Ağ.1080Kg", birim: "adet", malzemeTL: 14472.0, Montaj1: 13532.40, Montaj2: 14882.40, Montaj3: 17582.40 }, // T-50     2240.Satir
	{ malzeme: "TK15 Boyu 11 mt,Tepe Kuv.1500 Kg,Ağ.725Kg", birim: "adet", malzemeTL: 9715.0, Montaj1: 9084.25, Montaj2: 9990.05, Montaj3: 11803.00 }, // TK-15    2112.Satir
	{ malzeme: "TK25 Boyu 11 mt,Tepe Kuv.2500 Kg,Ağ.810Kg", birim: "adet", malzemeTL: 10854.0, Montaj1: 10149.30, Montaj2: 11161.80, Montaj3: 13186.80 }, // TK-25    2122.Satir
	{ malzeme: "TK35 Boyu 11 mt,Tepe Kuv.3500 Kg,Ağ.905Kg", birim: "adet", malzemeTL: 12127.0, Montaj1: 13339.65, Montaj2: 12470.90, Montaj3: 14733.40 }, // TK-35    2132.Satir
	{ malzeme: "TK50 Boyu 11 mt,Tepe Kuv.5000 Kg,Ağ.1025Kg", birim: "adet", malzemeTL: 13735.0, Montaj1: 12843.25, Montaj2: 14124.50, Montaj3: 16687.0 }, // TK-50    3132.Satir

]
export const TraversFiyatList = [
	{ malzeme: "DS-250  70kg", birim: "adet", malzemeTL: 938, Montaj1: 1017.8, Montaj2: 1119.3, Montaj3: 1323.7 }, // 246. satır
	{ malzeme: "DS-250  70kg", birim: "adet", malzemeTL: 938, Montaj1: 1017.8, Montaj2: 1119.3, Montaj3: 1323.7 }, // 246. satır
	{ malzeme: "DS-250  70kg", birim: "adet", malzemeTL: 938, Montaj1: 1017.8, Montaj2: 1119.3, Montaj3: 1323.7 }, // 246. satır

]
export const HavaiIletkenFiyatList = [
	{ malzeme: "Swan AWG-4 (87.31.96 Kg/Km)", birim: "kg", malzemeTL: 40.02, Montaj1: 29.25, Montaj2: 32.18, Montaj3: 38.03 }, // T-15
	{ malzeme: "Swallow AWG-3 (109.96 Kg/Km)", birim: "kg", malzemeTL: 40.02, Montaj1: 23.96, Montaj2: 26.36, Montaj3: 31.15 }, // T-15
	{ malzeme: "Sparrow AWG 2 (138.41 Kg/Km)", birim: "kg", malzemeTL: 40.02, Montaj1: 20.0, Montaj2: 21.99, Montaj3: 26.01 }, // T-25
	{ malzeme: "Robin AWG 1 (174.83 Kg/Km)", birim: "kg", malzemeTL: 40.02, Montaj1: 15.98, Montaj2: 17.58, Montaj3: 20.78 }, // T-25
	{ malzeme: "Raven AWG 1/0 (220.52 Kg/Km)", birim: "kg", malzemeTL: 40.02, Montaj1: 13.79, Montaj2: 15.15, Montaj3: 17.92 }, // T-25
	{ malzeme: "Piegon AWG 3/0 (350.78 Kg/Km)", birim: "kg", malzemeTL: 40.07, Montaj1: 9.24, Montaj2: 10.17, Montaj3: 12.01 }, // T-35
	{ malzeme: "Partridge AWG 266800 (554.68.78 Kg/Km)", birim: "kg", malzemeTL: 40.07, Montaj1: 8.48, Montaj2: 9.34, Montaj3: 11.04 }, // T-35
	{ malzeme: "Ostrich AWG 300000 (625.16 Kg/Km)", birim: "kg", malzemeTL: 40.07, Montaj1:7.71, Montaj2: 8.48, Montaj3: 10.03 }, // T-35
	{ malzeme: "Hawk AWG 477000 (992.26 Kg/Km)", birim: "kg", malzemeTL: 40.07, Montaj1:6.84, Montaj2: 7.52, Montaj3: 8.90 }, // T-35

]

export const XLPEFiyatList = [
	{ malzeme: "20.3/35 KV  1x35ş/16 mm² XLPE", birim: "m", malzemeTL: 118.90, Montaj1: 135.28, Montaj2: 148.80, Montaj3: 175.83 }, // 32.11
	{ malzeme: "20.3/35 KV  1x50ş/16 mm² XLPE", birim: "m", malzemeTL: 134.09, Montaj1: 135.69, Montaj2: 149.23, Montaj3: 176.47 }, // 32.11
	{ malzeme: "20.3/35 KV  1x70ş/16 mm² XLPE", birim: "m", malzemeTL: 155.47, Montaj1: 136.24, Montaj2: 149.98, Montaj3: 177.13 }, // 32.11
	{ malzeme: "20.3/35 KV  1x95ş/16 mm² XLPE", birim: "m", malzemeTL: 181.42, Montaj1: 137.20, Montaj2: 150.98, Montaj3: 178.23 }, // 32.11
	{ malzeme: "20.3/35 KV  1x120ş/16 mm² XLPE", birim: "m", malzemeTL: 205.72, Montaj1: 137.95, Montaj2: 151.78, Montaj3: 179.43 }, // 32.11
	{ malzeme: "20.3/35 KV  1x150ş/16 mm² XLPE", birim: "m", malzemeTL: 258.83, Montaj1: 138.90, Montaj2: 152.79, Montaj3: 180.57 }, // 32.11
	{ malzeme: "20.3/35 KV  1x185ş/16 mm² XLPE", birim: "m", malzemeTL: 296.75, Montaj1: 140.03, Montaj2: 153.99, Montaj3: 181.89 }, // 32.11
	{ malzeme: "20.3/35 KV  1x240ş/16 mm² XLPE", birim: "m", malzemeTL: 362.83, Montaj1: 141.94, Montaj2: 156.16, Montaj3: 184.46 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/16 mm² XLPE", birim: "m", malzemeTL: 501.54, Montaj1: 155.09, Montaj2: 170.55, Montaj3: 201.47 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/16 mm² XLPE", birim: "m", malzemeTL: 585.26, Montaj1: 158.68, Montaj2: 174.53, Montaj3: 206.20 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/16 mm² XLPE", birim: "m", malzemeTL: 636.85, Montaj1: 162.11, Montaj2: 178.46, Montaj3: 210.85 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/16 mm² XLPE", birim: "m", malzemeTL: 730.68, Montaj1: 166.09, Montaj2: 182.67, Montaj3: 215.98 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/16 mm² XLPE", birim: "m", malzemeTL: 809.85, Montaj1: 169.26, Montaj2: 186.19, Montaj3: 220.03 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/25 mm² XLPE", birim: "m", malzemeTL: 974.21, Montaj1: 173.04, Montaj2: 190.34, Montaj3: 224.96 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/25 mm² XLPE", birim: "m", malzemeTL: 1098.60, Montaj1: 177.58, Montaj2: 195.31, Montaj3: 230.76 }, // 32.11
	{ malzeme: "20.3/35 KV  3x35ş/25 mm² XLPE", birim: "m", malzemeTL: 1291.82, Montaj1: 184.74, Montaj2: 203.20, Montaj3: 240.11 }, // 32.11

]
export const SeksiyonerFiyatList = [
	{ malzeme: "Seksiyoner-36KV 400A  8KA", birim: "adet", malzemeTL: 6663.07, Montaj1: 2789.45, Montaj2: 3068.68, Montaj3: 3625.71 }, // 4911
	{ malzeme: "Seksiyoner-36KV 630A  12.5KA", birim: "adet", malzemeTL: 6941.26, Montaj1: 2789.45, Montaj2: 3067.65, Montaj3: 3626.38 }, // 4912


]
export const IzalatorFiyatList = [
	{ malzeme: "36KV VHD 35(20 mm/kV)   Normal Tip", birim: "adet", malzemeTL: 211.30, Montaj1: 61.63, Montaj2: 67.69, Montaj3: 80.16 }, // 
	{ malzeme: "40kN/11B", birim: "adet", malzemeTL: 89.79, Montaj1: 21.29, Montaj2: 23.36, Montaj3: 27.65 }, // Gergi izalatör
	{ malzeme: "100kN/16B", birim: "adet", malzemeTL: 134.69, Montaj1: 44.83, Montaj2: 49.23, Montaj3: 58.19 }, // Gergi izalatör
	{ malzeme: "K1 Tipi İzolatör ", birim: "adet", malzemeTL: 79.37, Montaj1: 21.29, Montaj2: 23.36, Montaj3: 27.65 }, // 
	{ malzeme: "K2 Tipi İzolatör ", birim: "adet", malzemeTL: 130.88, Montaj1: 30.98, Montaj2: 34.19, Montaj3: 40.28 }, // 
	{ malzeme: "K3 Tipi İzolatör ", birim: "adet", malzemeTL: 203.88, Montaj1: 44.83, Montaj2: 49.23, Montaj3: 58.19 }, // 

]
export const IzalatorDemirFiyatList = [
	{ malzeme: "C-35 İzalatör Demiri", birim: "adet", malzemeTL: 59.81, Montaj1: 8633.05, Montaj2: 9488.70, Montaj3: 11230.28 }, // 
	{ malzeme: "K1 Tipi İzolatör ", birim: "adet", malzemeTL: 79.37, Montaj1: 21.29, Montaj2: 23.36, Montaj3: 27.65 }, // 
	{ malzeme: "K2 Tipi İzolatör ", birim: "adet", malzemeTL: 130.88, Montaj1: 30.98, Montaj2: 34.19, Montaj3: 40.28 }, // 
	{ malzeme: "K3 Tipi İzolatör ", birim: "adet", malzemeTL: 203.88, Montaj1: 44.83, Montaj2: 49.23, Montaj3: 58.19 }, // 

]


export const NYYFiyatList = [
	{ malzeme: "1x150 mm² NYY", birim: "m", malzemeTL: 207.31, Montaj1: 143.12, Montaj2: 158.31, Montaj3: 187.00 }, //  0
	{ malzeme: "1x185 mm² NYY", birim: "m", malzemeTL: 261.67, Montaj1: 144.97, Montaj2: 159.47, Montaj3: 188.47 }, //  1
	{ malzeme: "1x240 mm² NYY", birim: "m", malzemeTL: 344.70, Montaj1: 146.77, Montaj2: 161.36, Montaj3: 190.72 }, //  2
	{ malzeme: "4x16 mm² NYY", birim: "m", malzemeTL: 98.59, Montaj1: 142.25, Montaj2: 156.42, Montaj3: 184.94 }, //    3
	{ malzeme: "3x25+16 mm² NYY", birim: "m", malzemeTL: 142.96, Montaj1: 143.40, Montaj2: 157.81, Montaj3: 186.29 }, // 4
	{ malzeme: "3x35+16 mm² NYY", birim: "m", malzemeTL: 184.14, Montaj1: 144.45, Montaj2: 158.92, Montaj3: 187.84 }, // 5
	{ malzeme: "3x50+25 mm² NYY", birim: "m", malzemeTL: 252.63, Montaj1: 145.83, Montaj2: 160.37, Montaj3: 189.47 }, // 6
	{ malzeme: "3x70+35 mm² NYY", birim: "m", malzemeTL: 353.60, Montaj1: 148.30, Montaj2: 163.14, Montaj3: 192.84 }, // 7
	{ malzeme: "3x95+50 mm² NYY", birim: "m", malzemeTL: 484.33, Montaj1: 151.32, Montaj2: 166.51, Montaj3: 196.70 }, // 8
	{ malzeme: "3x120+70 mm² NYY", birim: "m", malzemeTL: 620.70, Montaj1: 154.51, Montaj2: 170.03, Montaj3: 200.90 }, // 9
	{ malzeme: "3x150+70 mm² NYY", birim: "m", malzemeTL: 761.56, Montaj1: 157.21, Montaj2: 172.87, Montaj3: 204.39 }, // 10
	{ malzeme: "3x185+95 mm² NYY", birim: "m", malzemeTL: 936.32, Montaj1: 161.60, Montaj2: 177.87, Montaj3: 210.22 }, // 11
	{ malzeme: "3x240+120 mm² NYY", birim: "m", malzemeTL: 1225.43, Montaj1: 168.38, Montaj2: 185.19, Montaj3: 218.96 }, // 12

	{ malzeme: "1x150 mm² NYAY", birim: "m", malzemeTL: 207.31, Montaj1: 143.12, Montaj2: 158.31, Montaj3: 187.00 }, //  13
	{ malzeme: "1x185 mm² NYAY", birim: "m", malzemeTL: 261.67, Montaj1: 144.97, Montaj2: 159.47, Montaj3: 188.47 }, //  14
	{ malzeme: "1x240 mm² NYAY", birim: "m", malzemeTL: 344.70, Montaj1: 146.77, Montaj2: 161.36, Montaj3: 190.72 }, //  15
	{ malzeme: "4x16 mm² NYAY", birim: "m", malzemeTL: 98.59, Montaj1: 142.25, Montaj2: 156.42, Montaj3: 184.94 }, //    16
	{ malzeme: "3x25+16 mm² NYAY", birim: "m", malzemeTL: 142.96, Montaj1: 143.40, Montaj2: 157.81, Montaj3: 186.29 }, // 17
	{ malzeme: "3x35+16 mm² NYAY", birim: "m", malzemeTL: 184.14, Montaj1: 144.45, Montaj2: 158.92, Montaj3: 187.84 }, // 18
	{ malzeme: "3x50+25 mm² NYAY", birim: "m", malzemeTL: 252.63, Montaj1: 145.83, Montaj2: 160.37, Montaj3: 189.47 }, // 19
	{ malzeme: "3x70+35 mm² NYAY", birim: "m", malzemeTL: 353.60, Montaj1: 148.30, Montaj2: 163.14, Montaj3: 192.84 }, // 20
	{ malzeme: "3x95+50 mm² NYAY", birim: "m", malzemeTL: 484.33, Montaj1: 151.32, Montaj2: 166.51, Montaj3: 196.70 }, // 21
	{ malzeme: "3x120+70 mm² NYAY", birim: "m", malzemeTL: 620.70, Montaj1: 154.51, Montaj2: 170.03, Montaj3: 200.90 }, // 22
	{ malzeme: "3x150+70 mm² NYAY", birim: "m", malzemeTL: 761.56, Montaj1: 157.21, Montaj2: 172.87, Montaj3: 204.39 }, // 23
	{ malzeme: "3x185+95 mm² NYAY", birim: "m", malzemeTL: 936.32, Montaj1: 161.60, Montaj2: 177.87, Montaj3: 210.22 }, // 24
	{ malzeme: "3x240+120 mm² NYAY", birim: "m", malzemeTL: 1225.43, Montaj1: 168.38, Montaj2: 185.19, Montaj3: 218.96 }, // 25

]
export const TopraklamaIletken = [

	{ malzeme: "Topraklama levhası,5 m.şerit ve bunların gömülmesi ", birim: "adet", malzemeTL: 174.99, Montaj1: 801.01, Montaj2: 882.01, Montaj3: 1042.41 }, //0			 30.1
	{ malzeme: "Topraklama şeriti ve gömülmesi  ", birim: "mt", malzemeTL: 11.53, Montaj1: 54.67, Montaj2: 60.12, Montaj3: 70.98 }, // 1		30.1.1
	{ malzeme: "Topraklama levhası ve gömülmesi", birim: "adet", malzemeTL: 117.34, Montaj1: 528.74, Montaj2: 581.59, Montaj3: 687.46 }, //2		 30.2.1
	{ malzeme: "50 mm² NYY Kablo ve gömülmesi", birim: "mt", malzemeTL: 58.65, Montaj1: 28.91, Montaj2: 31.87, Montaj3: 37.66 }, //3		 30.2.
	{ malzeme: "35 mm² (319.26 Kg/Km) Bakır iletken ve gömülmesi", birim: "mt", malzemeTL: 39.49, Montaj1: 28.91, Montaj2: 31.87, Montaj3: 37.66 }, //4		 30.2.3
	{ malzeme: "50 mm² (319.26 Kg/Km) Bakır iletken ve gömülmesi", birim: "mt", malzemeTL: 57.16, Montaj1: 28.91, Montaj2: 31.87, Montaj3: 37.66 }, //5		 30.2.3
	{ malzeme: "95 mm² (319.26 Kg/Km) Bakır iletken ve gömülmesi ", birim: "mt", malzemeTL: 107.03, Montaj1: 28.91, Montaj2: 31.87, Montaj3: 37.66 }, //6		30.2.4 
	//NOT : Farklı kesitlerde bakır topraklama iletkeni kullanıldığında, kesittin malzeme bedeli 9.1pozundan montaj bedeli 30.2.4 pozundan ödenecektir.
	{ malzeme: "2 mt,galvanizli 65X65X7'lik köşebent + 5 mt şerit ve bunlarin gömülmesi", birim: "adet", malzemeTL: 227.29, Montaj1: 474.22, Montaj2: 521.65, Montaj3: 616.50 }, //7		30.3.1 
	{ malzeme: "2 mt,galvanizli 65X65X7'lik köşebent + 5 mt galvanizli örgülü çelik tel ve bunlarin gömülmesi", birim: "adet", malzemeTL: 244.93, Montaj1: 474.22, Montaj2: 521.65, Montaj3: 616.50 }, //8		30.4 
	{ malzeme: "Galvanizli örgülü çelik tel ve gömülmesi", birim: "mt", malzemeTL: 15.06, Montaj1: 54.67, Montaj2: 60.12, Montaj3: 70.98 }, //9		30.4.1 
	{ malzeme: "2 mt,galvanizli 65X65X7'lik köşebent+galvanizli toprak elektrot ve gömülmesi", birim: "adet", malzemeTL: 227.29, Montaj1: 474.22, Montaj2: 521.65, Montaj3: 616.50 }, //10			30.4.2
	{ malzeme: "2,5 mt UZUNLUĞUNDA 22mm ÇAPINDA  3mm BAKIR KAPLI ÇELİK ÇUBUK TOPRAK ELEKTRODU", birim: "mt", malzemeTL: 495.86, Montaj1: 200.86, Montaj2: 220.95, Montaj3: 251.12 }, //11			30.4.3



]

export const ParafudrFiyatList = [
	{ malzeme: "0.280 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 6.68, Montaj1: 99.40, Montaj2: 109.22, Montaj3: 129.16 }, //  0
	{ malzeme: "1 kV 2.5kA Sic Parafudr", birim: "madet", malzemeTL: 7.42, Montaj1: 99.40, Montaj2: 109.22, Montaj3: 129.16 }, //  1
	{ malzeme: "1 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 7.72, Montaj1: 99.40, Montaj2: 109.22, Montaj3: 129.16 }, //  2
	{ malzeme: "6 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 35.71, Montaj1: 173.55, Montaj2: 190.90, Montaj3: 225.62 }, //  3
	{ malzeme: "7.2 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 35.71, Montaj1: 173.55, Montaj2: 190.90, Montaj3: 225.62 }, //  4
	{ malzeme: "10 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 40.56, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  5
	{ malzeme: "12 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 48.07, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  6
	{ malzeme: "15 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 48.07, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  7
	{ malzeme: "24 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 56.36, Montaj1: 242.19, Montaj2: 266.44, Montaj3: 314.93 }, //  8
	{ malzeme: "30 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 65.51, Montaj1: 429.29, Montaj2: 472.32, Montaj3: 558.09 }, //  9
	{ malzeme: "30 kV 10kA Sic Parafudr", birim: "adet", malzemeTL: 90.63, Montaj1: 616.62, Montaj2: 678.29, Montaj3: 801.65 }, //  10
	{ malzeme: "36 kV 5kA Sic Parafudr", birim: "adet", malzemeTL: 76.96, Montaj1: 429.29, Montaj2: 472.32, Montaj3: 558.09 }, //  11
	{ malzeme: "36 kV 10kA Sic Parafudr", birim: "adet", malzemeTL: 101.65, Montaj1: 616.62, Montaj2: 678.29, Montaj3: 801.65 }, //  12

	{ malzeme: "6 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 162.39, Montaj1: 173.55, Montaj2: 190.90, Montaj3: 225.62 }, //  13
	{ malzeme: "7.2 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 162.39, Montaj1: 173.55, Montaj2: 190.90, Montaj3: 225.62 }, //  14
	{ malzeme: "10 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 182.48, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  15
	{ malzeme: "12 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 218.59, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  16
	{ malzeme: "15 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 218.59, Montaj1: 220.26, Montaj2: 242.27, Montaj3: 286.29 }, //  17
	{ malzeme: "24 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 255.96, Montaj1: 242.19, Montaj2: 266.44, Montaj3: 314.93 }, //  18
	{ malzeme: "30 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 274.02, Montaj1: 429.29, Montaj2: 472.32, Montaj3: 558.09 }, //  19
	{ malzeme: "30 kV 10kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 343.61, Montaj1: 616.62, Montaj2: 678.29, Montaj3: 801.65 }, //  20
	{ malzeme: "36 kV 5kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 322.53, Montaj1: 429.29, Montaj2: 472.32, Montaj3: 558.09 }, //  21
	{ malzeme: "36 kV 10kA Metal Oksit Parafudr", birim: "adet", malzemeTL: 385.25, Montaj1: 616.62, Montaj2: 678.29, Montaj3: 801.65 }, //  22


]
export const AGPanoFiyatList = [ //24.4.2
	{ malzeme: "50 kVA'lik  3X80 A.  Oto.Şalterli AG Pano", birim: "adet", malzemeTL: 15002.10, Montaj1: 3326.50, Montaj2: 3663.95, Montaj3: 4322.85 }, //  0     
	{ malzeme: "100 kVA'lik  3X160 A.  Oto.Şalterli AG Pano", birim: "madet", malzemeTL: 15097.21, Montaj1: 3326.50, Montaj2: 3663.95, Montaj3: 4322.85 }, //  1 
	{ malzeme: "160 kVA'lik  3X250 A.  Oto.Şalterli AG Pano", birim: "adet", malzemeTL: 15762.98, Montaj1: 3342.54, Montaj2: 3680.01, Montaj3: 3338.87 }, //  2
	{ malzeme: "250 kVA'lik  3X400 A.  Oto.Şalterli AG Pano", birim: "adet", malzemeTL: 22579.24, Montaj1: 3873.40, Montaj2: 4272.95, Montaj3: 5041.52 }, //  3
	{ malzeme: "400 kVA'lik  3X630 A.  Oto.Şalterli AG Pano", birim: "adet", malzemeTL: 26294.89, Montaj1: 3896.13, Montaj2: 4281.25, Montaj3: 5065.92 }, //  4


]
export const TehlikeFiyatList = [ //24.4.2
	{ malzeme: "Tehlike Levhası (Emaye)", birim: "adet", malzemeTL: 8.77, Montaj1: 43.92, Montaj2: 48.25, Montaj3: 57.07 }, //  0       -26.1
	{ malzeme: "Tehlike Levhası (Al-Küçük boy)", birim: "adet", malzemeTL: 3.83, Montaj1: 16.24, Montaj2: 17.83, Montaj3: 21.17 }, //  0        -26.2
	{ malzeme: "Tehlike Levhası (Al-Orta boy)", birim: "adet", malzemeTL: 4.16, Montaj1: 16.24, Montaj2: 17.83, Montaj3: 21.17 }, //  0       -26.2
	{ malzeme: "Tehlike Levhası (Al-Büyük boy)", birim: "adet", malzemeTL: 4.42, Montaj1: 16.24, Montaj2: 17.83, Montaj3: 21.17 }, //  0       -26.2
	{ malzeme: "Tehlike Levhası (Al-Yeni Şartname)", birim: "adet", malzemeTL: 4.81, Montaj1: 16.24, Montaj2: 17.83, Montaj3: 21.17 }, //  0     26.2.4


]
