
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'

let X = 0;
let Y = 0;
let denemeCizim = []

export default function VaziyetPlanı(props) {

    const [showSayacBolme, setShowSayacBolme] = useState(false);
    const [bgcolor, setBgcolor] = useState("white");
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let Scl = cizimOzellik.Scl;
    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 8 * Scl, h: 555 * Scl }, cizim: denemeCizim })
    function sayacBolmeShow() { setShowSayacBolme(!showSayacBolme) }
    let CerceveRenk=cizimOzellik.DOCCerceveRenk

    function artirW(val) {
        setCizim(prmt => ({
            ...prmt,
            attr: {
                x: 0, y: 0,
                w: 500 * Scl,
                h: 600 * Scl,
            },
            cizim: [{
                Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                    [
                        X + Scl * (0), Y + Scl * (0),
                        X + Scl * (500), Y + Scl * (0),
                        X + Scl * (500), Y + Scl * (600),
                        X + Scl * (0), Y + Scl * (600)
                    ]
            }, { Ad: "Polyline", layer: "0", Color: "#FF00FF ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: [X + Scl * (50), Y + Scl * (50), X + Scl * (350), Y + Scl * (50), X + Scl * (350), Y + Scl * (350), X + Scl * (50), Y + Scl * (350)] }, { Ad: "Text", layer: "0", Color: "#FFAA33 ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (65.00), y: Y + Scl * (150), TextH: Scl * 100, TextString: "vaziyet Planı", TextStyleName: "  1", TextArc: 0, width: 300 }]


        }));

    }
    useEffect(() => {
        artirW(400)

    }, [cizimOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);

    return (

        <React.Fragment>
{/* 
            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1  '
                style={{ width: "98%", background: bgcolor }}
            >

                <thead >
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={sayacBolmeShow}>Vaziyet Planı Ayarları</th>
                    </tr>
                </thead>

                <tbody style={showSayacBolme ? { display: "block" } : { display: "none" }}>
                    <tr  >
                        <td >Üç Faz Sayaç Sayısı</td>
                        <td >
                            <input style={{ width: "75px", background: bgcolor }} onClick={e => artirW(parseInt(e.target.value))} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td  >Tek Faz Sayaç Sayısı</td>
                        <td  >
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum ÜçFaz Satır</td>
                        <td>
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td>Maksimum TekFaz Satır</td>
                        <td>
                            <input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>
                    <tr>
                        <td >Pano Kapak Maksimum Genişlik</td>
                        <td><input style={{ width: "75px", background: bgcolor }} className="my-auto border border-0" type='number' min="0" />
                        </td>
                    </tr>

                </tbody>

            </Table>
 */}
        </React.Fragment>
    )

}
