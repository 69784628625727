import { createSlice } from '@reduxjs/toolkit'

export const trafoDetaySlice = createSlice({
  name: 'trafoDetay',
  initialState:  {topKuruluGuc:25,
  talepFak:80,
  talepGuc:0,
  cosFi:0.8,
  talepGuckVA:0,
  trafoGuckVA:100,
  trafoGiris:"Ф8",
  Korumainis:"1x50 mm² NYY",
  trafoTip:"hermetik",
  AGAkim:72,
  OGAkim:0.83,
  AkimAmperaj:100,
  KesifNo:2
} 
  ,
  reducers: {   
    UpdateKuruluGuc:(state, action ) => {state.topKuruluGuc = action.payload },   
    UpdateTalepFak: (state, action ) => { state.talepFak = action.payload },   
    UpdateTalepGuc: (state, action ) => { state.talepGuc = action.payload},   
    UpdateCosFi: (state, action ) => {state.cosFi = action.payload},   
    UpdateTalepGuckVA: (state, action ) => {state.talepGuckVA = action.payload},   
    UpdateTrafoGuckVA: (state, action ) => {state.trafoGuckVA = action.payload  },   
    UpdateTrafoTip: (state, action ) => {  state.trafoTip = action.payload },
    UpdateAGAkim: (state, action ) => {  state.AGAkim = action.payload },
    UpdateOGAkim: (state, action ) => {  state.OGAkim = action.payload },
    UpdateAkimAmperaj: (state, action ) => {  state.AkimAmperaj = action.payload },
    UpdateTrafoGiris: (state, action ) => {  state.trafoGiris = action.payload },
    UpdateKorumainis: (state, action ) => {  state.Korumainis = action.payload },
    UpdateKesifNo: (state, action ) => {  state.KesifNo = action.payload }
     
    }
})

export const { UpdateKuruluGuc,UpdateKesifNo,UpdateTalepFak,UpdateTalepGuc,UpdateCosFi,UpdateTalepGuckVA,UpdateTrafoGuckVA,UpdateTrafoTip,UpdateAGAkim,UpdateOGAkim,UpdateAkimAmperaj,UpdateTrafoGiris,UpdateKorumainis} = trafoDetaySlice.actions

export default trafoDetaySlice.reducer