
import React, {  useEffect, useState } from 'react'
import { Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
//import { sayacdata } from '../reducers/sayacbolmeSlice'
import { UpdateSecim ,UpdateDDpanoTextGoster,UpdateDDtrafoKorTextGoster,UpdateDDtrafoTextGoster,UpdateDDparafTextGoster,UpdateDDSekTextGoster,UpdateDDHatTextGoster} from '../reducers/cizimOzellik'
import {T15Bosview,DirekTrafoView,HavaiHat,MesnetIzalator,SeksiyonerView,T15TrafoCikis,T15TrafoKoruma,PanoOlcuView,PanoOlcuIsletmeView,PanoOlcuDagKompView,PanoOlcuDagKompIsletmeView,PanoOlcuDagView,PanoOlcuDagIsletmeView,KorumaTopView,IsletmeTopView,ParafudrOnce,ParafudrOnceHavai,ParafudrOnceYeralti,ParafudrSonra,SeksiyonersizTrfGiris,ParafudrTopHususi,ParafudrTopMusterek,YerAltiXLPE,OTLView,ParafudrSonraSeksiyonersiz,ParafudrSonraSeksiyonerli,T25Bosview,T35Bosview,T50Bosview,SeksiyonerTopraklama,TK25Bosview,PanoOlcuDagKompGirisView,PanoOlcuDagKompKorumaView,PanoOlcuDagKompGirisViewKD,TK15Bosview,TK50Bosview,TrafoInisKD,TrafoGirisKD,ParafudrOnceKD,ParafudrOnceKD1,YerAltiXLPEKD,ParafudrSonraKD,PanoOlcuDagKompKorumaKDView,TK35Bosview,TrafoKoumaInisKD,PanoOlcuGirisKD,PanoOlcuGiris,PanoOlcuKorumaView,PanoOlcuKorumaViewKD,BetonDirekBosView,HavaiHatDemirDirekIletken,HavaiHatBetonDirekIletken,DemirDirekSeksiyonerGiris,BetonSeksiyonersizTrfGiris,BetonDirekSeksiyonerCikis,BetonSeksiyonerTop,ParafudrOnceDemirDirekInis,BDParafudrOnceHavai,BDParafudrOnceYeralti,BDParafudrMusterekTop,BDParafudrHususiTop1,BDParafudrHususiTop2,BDParafudrHususiTop3,ParafudrSonrainis,BDParafudrSonraSeksiyonersiz,ParafudrSonraMusterekTop,BDParafudrSonraSeksiyonerli,BDTrafoInis,BDTrafoInisOlcu,BDTrafoInisDag,BDTrafoKoruma,BDPanoOlcuIsletmeView,BDPanoOlcuKorumaView,BDPanoOlcuDagKompIsletmeView,BDYerAlti,PanoOlcuDagKorumaView,AyiriciKoluView,KorkulukSol,KorkulukSag,ParafudrTopMusterekKD,TrafoGirisSeksiyonerli,HavaiHatTEXT,PanoTEXT,DirekTrafoViewTEXT,KorumaTopTEXT,T15TrafoKorumaTEXT,TrafoKorumaInisKDTEXT,ParafudrOnceTEXT,ParafudrOnceKDTEXT,ParafudrSonraTEXT,ParafudrSonraKDTEXT,SeksiyonerViewTEXT} from '../Data/testCizimler' 
 
let  denemeCizim=[]
export default function DirekDeta(props) {
 
    const Trafo = useSelector((state) => state.trafoDetay)
    const Pano = useSelector((state) => state.PanoDetay)
    const Direk = useSelector((state) => state.direkDetay)
    const OGHat = useSelector((state) => state.OGHatDetay)

    const [showSayacBolme, setShowSayacBolme] = useState(false);
    const [bgcolor, setBgcolor] = useState("white"); 
 
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
   const dispatch = useDispatch()
   const [toolshow, setToolShow] = useState(false);
 
   function Show() { setToolShow(!toolshow) }
    let Scl=cizimOzellik.Scl; 
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:916*Scl,h:1467*Scl},cizim:denemeCizim})


    let cerceveRenk=cizimOzellik.DDCerRenk; 
    let zeminRenk= cizimOzellik.DDZemRenk;
    let direkRenk=cizimOzellik.DDdirekRenk;
    let panoRenk=cizimOzellik.DDpanoRenk;
    let panoTextRenk=cizimOzellik.DDpanoTextRenk;
    let panoTextGoster=cizimOzellik.DDpanoTextGoster;
    let trafoRenk=cizimOzellik.DDtrafoRenk;
    let trafoTextRenk=cizimOzellik.DDtrafoTextRenk;
    let trafoTextGoster=cizimOzellik.DDtrafoTextGoster;
    let trfgirisRenk=cizimOzellik.DDtrafoGirisRenk
    let trfinisRenk=cizimOzellik.DDtrafoInisRenk
    let trfKorumaRenk=cizimOzellik.DDtrafoKorRenk
    let trfKorumaTextRenk=cizimOzellik.DDtrafoKorTextRenk
    let trfKorumaTextGoster=cizimOzellik.DDtrafoKorTextGoster
    let izolatorRenk=cizimOzellik.DDizolatorRenk
    let isletmeRenk=cizimOzellik.DDIsletmeRenk
    let hatdiletkenRenk=cizimOzellik.DDhatIletkenRenk
    let hatTextRenk=cizimOzellik.DDHatTextRenk
    let hatTextGoster=cizimOzellik.DDHatTextGoster
    let topKazikRenk=cizimOzellik.DDtopKazRenk
    let parafudrRenk=cizimOzellik.DDparafRenk
    let parafudrGirisRenk=cizimOzellik.DDparafGirisRenk 
    let parafudrTextRenk=cizimOzellik.DDparafTextRenk
    let parafudrTextGoster=cizimOzellik.DDparafTextGoster
    let parafudrinisRenk=cizimOzellik.DDparafInisRenk
    let seksiyonerRenk=cizimOzellik.DDSekRenk
    let seksiyonerTextRenk=cizimOzellik.DDSekTextRenk
    let seksiyonerTextGoster=cizimOzellik.DDSekTextGoster
    let SekKolRenk=cizimOzellik.DDSekKolRenk
    let SekGirisRenk=cizimOzellik.DDSekGirisRenk
    let sekiletkenRenk=cizimOzellik.DDSekIletkenRenk
    let korkulukRenk=cizimOzellik.DDKorkulukRenk
    let otlRenk=cizimOzellik.DDotlRenk 
    let otlTextRenk=cizimOzellik.DDotlTextRenk 
  
    
    let Scl2=1.866666666666
    function sayacBolmeShow() { setShowSayacBolme(!showSayacBolme) }

    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:916*Scl*Scl2,  
                h:1500*Scl*Scl2,                          
            },
            cizim:demirDirek(0,0,Scl*Scl2,Trafo.trafoGuckVA)         
        })); 
    }
    useEffect(() => { 
        artirW(400)        
    }, [ Trafo,Pano,Direk,OGHat,cizimOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]);  

    
    
    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }


    function demirDirek(X,Y,Scl,veri){ 
        let resume=[]
        let ox=0;
        let kx=0;
        let kkx=0; 
        let direkboy="normal"
        if (Direk.tur==="demir"){

       let veri={cerceveRenk:cerceveRenk,zeminRenk:zeminRenk,direkRenk:direkRenk}
        if(Direk.direk==="T15"){
            resume=resume.concat(T15Bosview(0,0,Scl,veri))
            kx=0;
        }else if(Direk.direk==="T25"){ 
            resume=resume.concat(T25Bosview(0,0,Scl,veri))   
            kx=0;     
        }else if(Direk.direk==="T35"){
            resume=resume.concat(T35Bosview(0,0,Scl,veri))
            kx=1;
        }else if(Direk.direk==="T50"){
            resume=resume.concat(T50Bosview(0,0,Scl,veri))
            kx=5;
            ox=10*Scl
        }else if(Direk.direk==="TK15"){
            resume=resume.concat(TK15Bosview(0,0,Scl,veri))
            direkboy="kısa"
            ox=0*Scl  
            kx=1;  
            kkx=9;   
        }
        else if(Direk.direk==="TK25"){
            resume=resume.concat(TK25Bosview(0,0,Scl,veri))
            ox=0*Scl
            direkboy="kısa"
            kx=1;
            kkx=9; 
        }        
        else if(Direk.direk==="TK35"){
            resume=resume.concat(TK35Bosview(0,0,Scl,veri))
            ox=0*Scl
            direkboy="kısa"
            kx=1;
            kkx=9;
        }        
        else if(Direk.direk==="TK50"){
            resume=resume.concat(TK50Bosview(0,0,Scl,veri))
            ox=10*Scl
            direkboy="kısa"
            kx=5; 
            kkx=10;
        }  
  
          
        if(direkboy==="normal"){   
            resume=resume.concat(DirekTrafoView(520.12*Scl+ox,313.84*Scl,Scl,trafoRenk,izolatorRenk))
            if(trafoTextGoster===true){
                resume=resume.concat(DirekTrafoViewTEXT(520.12*Scl+ox,313.84*Scl,Scl,Trafo.trafoGuckVA,trafoTextRenk))                
            }
            if(OGHat.OGhatTur==="havai"){
                resume=resume.concat(HavaiHat(307.83*Scl-ox,87.92*Scl,Scl,hatdiletkenRenk))

                if(hatTextGoster===true){
                    resume=resume.concat(HavaiHatTEXT(307.83*Scl-ox,87.92*Scl,Scl,OGHat.OGhat,hatTextRenk))

                }

                resume=resume.concat(HavaiHatDemirDirekIletken(307.83*Scl-ox,87.92*Scl,Scl, hatdiletkenRenk))
                resume=resume.concat(MesnetIzalator(477.01*Scl,90.34*Scl,Scl,izolatorRenk))//orta
                if(Direk.parafudr!=="yok"){ 
                    if(Direk.parafudrKonum==="once"){
                        resume=resume.concat(ParafudrOnce(437.01*Scl-ox,153.55*Scl,Scl,parafudrRenk,direkRenk))
                        if(parafudrTextGoster===true){
                        resume=resume.concat(ParafudrOnceTEXT(437.01*Scl-ox,153.55*Scl,Scl,Direk.parafudr,"Parafudr",parafudrTextRenk))                            
                        }
                        resume=resume.concat(ParafudrOnceDemirDirekInis(437.01*Scl-ox,153.55*Scl,Scl,Direk.parafudrInis ,parafudrinisRenk,parafudrTextRenk))
                        resume=resume.concat(ParafudrOnceHavai(420.76*Scl-ox,115.30*Scl,Scl,hatdiletkenRenk))
                    }else if(Direk.parafudrKonum==="sonra"){
                        resume=resume.concat(ParafudrSonra(728.94*Scl+ox,382.34*Scl,Scl,parafudrRenk))
                        if(parafudrTextGoster===true){
                            resume=resume.concat(ParafudrSonraTEXT(728.94*Scl+ox,382.34*Scl,Scl,Direk.parafudrGiris,Direk.parafudr,"Parafudr",parafudrTextRenk))
                        }
                        resume=resume.concat(ParafudrSonrainis(728.94*Scl+ox,382.34*Scl,Scl ,parafudrinisRenk))
                        if(Direk.seksiyoner!=="yok"){
                            resume=resume.concat(ParafudrSonraSeksiyonerli(728.94*Scl+ox,382.34*Scl,Scl,parafudrGirisRenk))
                        }else{ 
                            resume=resume.concat(ParafudrSonraSeksiyonersiz(728.94*Scl+ox,382.34*Scl,Scl,parafudrGirisRenk))
                        }
                    } 
            }   
  
            }else if(OGHat.OGhatTur==="yeralti"){
                resume=resume.concat(MesnetIzalator(454.26*Scl-ox,90.34*Scl,Scl ,izolatorRenk))//sol
                resume=resume.concat(MesnetIzalator(499.76*Scl+ox,90.34*Scl,Scl,izolatorRenk ))//Sağ
                resume=resume.concat(YerAltiXLPE(461.01*Scl-ox,82.34*Scl,Scl,OGHat.OGhat,OGHat.OGhatMetre,hatdiletkenRenk,hatTextRenk))

                if(Direk.parafudr!=="yok"){   
                    if(Direk.parafudrKonum==="once"){
                        console.log("parafudrTextGoster ",parafudrTextGoster)
                        resume=resume.concat(ParafudrOnce(437.01*Scl-ox,153.55*Scl,Scl, parafudrRenk,direkRenk ))
                        if(parafudrTextGoster===true){
                        resume=resume.concat(ParafudrOnceTEXT(437.01*Scl-ox,153.55*Scl,Scl, Direk.parafudr,"Parafudr",parafudrTextRenk ))}
                        resume=resume.concat(ParafudrOnceDemirDirekInis(437.01*Scl-ox,153.55*Scl,Scl,Direk.parafudrInis,parafudrinisRenk,parafudrTextRenk))
                        resume=resume.concat(ParafudrOnceYeralti(420.76*Scl-ox,116.30*Scl,Scl,parafudrGirisRenk))
                      
                    }else if(Direk.parafudrKonum==="sonra"){
                        resume=resume.concat(ParafudrSonra(728.94*Scl+ox,382.34*Scl,Scl,parafudrRenk))
                        if(parafudrTextGoster===true){
                            resume=resume.concat(ParafudrSonraTEXT(728.94*Scl+ox,382.34*Scl,Scl,Direk.parafudrGiris,Direk.parafudr,"Parafudr",parafudrTextRenk))
                        }
                        resume=resume.concat(ParafudrSonrainis(728.94*Scl+ox,382.34*Scl,Scl,parafudrinisRenk))
                        if(Direk.seksiyoner!=="yok"){
                            resume=resume.concat(ParafudrSonraSeksiyonerli(728.94*Scl+ox,382.34*Scl,Scl,parafudrGirisRenk))
                        }else{
                            resume=resume.concat(ParafudrSonraSeksiyonersiz(728.94*Scl+ox,382.34*Scl,Scl,parafudrGirisRenk))

                        }

                    }
                }    
            }
            if(Direk.parafudr!=="yok"){
            if(Direk.parafudrTopraklama==="Müşterek"){
                resume=resume.concat(ParafudrTopMusterek(454.86*Scl-ox,1043.64*Scl,Scl,parafudrinisRenk))
            }else {
                resume=resume.concat(ParafudrTopHususi(455.86*Scl-ox,1044.64*Scl,Scl,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı",parafudrinisRenk,topKazikRenk))
            }
            }
            if(Direk.seksiyoner!=="yok"){
                resume=resume.concat(SeksiyonerView(503.51*Scl+ox,120.87*Scl,Scl,seksiyonerRenk,izolatorRenk))
                if(seksiyonerTextGoster===true){
                    resume=resume.concat(SeksiyonerViewTEXT(503.51*Scl+ox,120.87*Scl,Scl,Direk.seksiyonergiris,Direk.seksiyoner,"Seksiyoner",seksiyonerTextRenk))

                }
             resume=resume.concat(AyiriciKoluView(518.82*Scl,1014.82*Scl,Scl,SekKolRenk))
 
                resume=resume.concat(DemirDirekSeksiyonerGiris(503.51*Scl+ox,120.87*Scl,Scl,SekGirisRenk)) //trafoTextRenk
                resume=resume.concat(SeksiyonerTopraklama(497.01*Scl+ox,277.37*Scl,Scl,Direk.seksiyonerinis,sekiletkenRenk,seksiyonerTextRenk))
                resume=resume.concat(TrafoGirisSeksiyonerli(530.12*Scl+ox,313.84*Scl,Scl, trfgirisRenk,Trafo.trafoGiris,trafoTextRenk))  
 
            }else{
                resume=resume.concat(SeksiyonersizTrfGiris(588.82*Scl+ox,319.84*Scl,Scl ,trfgirisRenk,Trafo.trafoGiris,trfgirisRenk))
            }
            resume=resume.concat(T15TrafoCikis(520.12*Scl+ox,313.84*Scl,Scl,Pano.InisIletken.Etiket,trfinisRenk,trafoTextRenk ))
            resume=resume.concat(T15TrafoKoruma(520.12*Scl+ox,313.84*Scl,Scl,trfKorumaRenk))  
        if(trfKorumaTextGoster===true){
            resume=resume.concat(T15TrafoKorumaTEXT(520.12*Scl+ox,313.84*Scl,Scl,Trafo.Korumainis,trfKorumaTextRenk)) 
        }

            resume=resume.concat(KorumaTopView(515.17*Scl+ox,1190.10*Scl,Scl,trfKorumaRenk,topKazikRenk))
            if(trfKorumaTextGoster===true){
            resume=resume.concat(KorumaTopTEXT(515.17*Scl+ox,1190.10*Scl,Scl,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı" ,trfKorumaTextRenk))
                
            } 
            resume=resume.concat(IsletmeTopView(790.54*Scl+ox,1272.59*Scl,Scl,Direk.trafoisletmeinis,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı" ,topKazikRenk ))
            resume=resume.concat(OTLView(253.85*Scl,1003.58*Scl,Scl ,otlRenk,otlTextRenk))

 
            if(Pano.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
            resume=resume.concat(PanoOlcuDagKompView(597.55*Scl+ox,1232.34*Scl,Scl,panoRenk )) 
            resume=resume.concat(PanoOlcuDagKompIsletmeView(597.55*Scl+ox,1232.34*Scl,Scl,isletmeRenk ))
            resume=resume.concat(PanoOlcuDagKompKorumaView(597.55*Scl+ox,1232.34*Scl,Scl, trfKorumaRenk))
            resume=resume.concat(PanoOlcuDagKompGirisView(597.55*Scl+ox,1232.34*Scl,Scl ,trfinisRenk))
              if(panoTextGoster===true)    {
            resume=resume.concat(PanoTEXT(597.55*Scl+ox,1232.34*Scl,Scl ,"A.D.P.",panoTextRenk))

              }
    
            }else  if(Pano.PanoTuru==="Ölçüm+Dağıtım"){
                resume=resume.concat(PanoOlcuDagView(597.55*Scl+ox,1232.34*Scl,Scl,panoRenk  ))
                resume=resume.concat(PanoOlcuDagIsletmeView(597.55*Scl+ox,1232.34*Scl,Scl,isletmeRenk ))
                resume=resume.concat(PanoOlcuDagKompKorumaView(597.55*Scl+ox,1232.34*Scl,Scl,trfKorumaRenk ))
                resume=resume.concat(PanoOlcuDagKompGirisView(597.55*Scl+ox,1232.34*Scl,Scl ,trfinisRenk))
                if(panoTextGoster===true)    {
                    resume=resume.concat(PanoTEXT(527.55*Scl+ox,1232.34*Scl,Scl ,"A.D.P.",panoTextRenk))
                }
       
    
            }else  if(Pano.PanoTuru==="Ölçüm"){
                resume=resume.concat(PanoOlcuView(524.29*Scl+ox,1141.52*Scl,Scl,panoRenk ))
                resume=resume.concat(PanoOlcuIsletmeView(524.29*Scl+ox,1141.52*Scl,Scl,isletmeRenk ))
                resume=resume.concat(PanoOlcuGiris(524.29*Scl+ox,1141.52*Scl,Scl,trfinisRenk ))
                resume=resume.concat(PanoOlcuKorumaView(524.29*Scl+ox,1141.52*Scl,Scl ,trfKorumaRenk))
                if(panoTextGoster===true)    {
                    resume=resume.concat(PanoTEXT(400.29*Scl+ox,1200.52*Scl,Scl ,"Ölçü Panosu",panoTextRenk))
                }
            } 
 //------------------------------KISA DİREK----------------------------------
        }else if(direkboy==="kısa"){
            //resume=resume.concat(DirekTrafoView(540.52*Scl+ox,312.0*Scl,Scl,Trafo.trafoGuckVA,Trafo.trafoGiris,trafoRenk,izolatorRenk,trafoTextRenk))
            if(OGHat.OGhatTur==="havai"){
                resume=resume.concat(HavaiHat(288.83*Scl-ox,312.14*Scl,Scl,hatdiletkenRenk))
                if(hatTextGoster===true){
                    resume=resume.concat(HavaiHatTEXT(288.83*Scl-ox,312.14*Scl,Scl,OGHat.OGhat,hatTextRenk))
                }
                resume=resume.concat(HavaiHatDemirDirekIletken(288.83*Scl-ox,312.14*Scl,Scl,  hatdiletkenRenk))
                resume=resume.concat(MesnetIzalator(458.04*Scl,314.57*Scl,Scl,izolatorRenk))//orta  
                if(Direk.parafudr!=="yok"){ 
                    if(Direk.parafudrKonum==="once"){
                        resume=resume.concat(ParafudrOnceKD(437.29*Scl,385.34*Scl,Scl,"",Direk.parafudr,"Parafudr",parafudrRenk,direkRenk,parafudrinisRenk, parafudrTextRenk))
                        if(parafudrTextGoster===true){
                            resume=resume.concat(ParafudrOnceKDTEXT(437.29*Scl,385.34*Scl,Scl,"",Direk.parafudr,"Parafudr",parafudrTextRenk))                             
                            }
                        resume=resume.concat(ParafudrOnceKD1(437.29*Scl,385.34*Scl,Scl,Direk.parafudrInis ,parafudrinisRenk, parafudrTextRenk))
                        resume=resume.concat(ParafudrOnceHavai(401.29*Scl,340.52*Scl,Scl,hatdiletkenRenk))
                    }else if(Direk.parafudrKonum==="sonra"){
                        resume=resume.concat(ParafudrSonraKD(458.04*Scl,314.57*Scl,Scl,parafudrRenk,parafudrinisRenk, parafudrTextRenk))
                        if(parafudrTextGoster===true){
                            resume=resume.concat(ParafudrSonraKDTEXT(458.04*Scl,314.57*Scl,Scl,Direk.parafudrGiris,Direk.parafudr,"Parafudr",parafudrTextRenk))                            
                            }
                        resume=resume.concat(ParafudrOnceKD1(437.29*Scl,385.34*Scl,Scl,Direk.parafudrInis ,parafudrinisRenk, parafudrTextRenk))
                       
                    }
            }           

 
            }else if(OGHat.OGhatTur==="yeralti"){
                resume=resume.concat(MesnetIzalator(435.29*Scl-ox,315.56*Scl,Scl,izolatorRenk ))//sol
                resume=resume.concat(MesnetIzalator(480.79*Scl+ox,315.56*Scl,Scl,izolatorRenk ))//Sağ

                resume=resume.concat(YerAltiXLPEKD(458.04*Scl-ox,314.27*Scl,Scl,OGHat.OGhat,OGHat.OGhatMetre,hatdiletkenRenk,hatTextRenk))

                if(Direk.parafudr!=="yok"){
                    if(Direk.parafudrKonum==="once"){
                        resume=resume.concat(ParafudrOnceKD(437.29*Scl,385.34*Scl,Scl,"",Direk.parafudr,"Parafudr",parafudrRenk,direkRenk,parafudrinisRenk, parafudrTextRenk))
                        resume=resume.concat(ParafudrOnceKD1(437.29*Scl,385.34*Scl,Scl,Direk.parafudrInis ,parafudrinisRenk, parafudrTextRenk))
                        resume=resume.concat(ParafudrOnceYeralti(401.29*Scl,340.52*Scl,Scl,parafudrGirisRenk,))

                    }else if(Direk.parafudrKonum==="sonra"){
                        resume=resume.concat(ParafudrSonraKD(458.04*Scl,314.57*Scl,Scl,parafudrRenk,parafudrinisRenk, parafudrTextRenk))
                        if(parafudrTextGoster===true){
                            resume=resume.concat(ParafudrSonraKDTEXT(458.04*Scl,314.57*Scl,Scl,Direk.parafudrGiris,Direk.parafudr,"Parafudr",parafudrTextRenk))                            
                            }
                        resume=resume.concat(ParafudrOnceKD1(437.29*Scl,385.34*Scl,Scl,Direk.parafudrInis ,parafudrinisRenk, parafudrTextRenk))
                    }
                }
     
            }

                resume=resume.concat(TrafoGirisKD(458.57*Scl+ox ,288.57*Scl,Scl,trfgirisRenk,Trafo.trafoGiris,trafoTextRenk))
 
            if(Direk.parafudr!=="yok"){
            if(Direk.parafudrTopraklama==="Müşterek"){
                resume=resume.concat(ParafudrTopMusterekKD(454.86*Scl,1043.64*Scl,Scl ,parafudrinisRenk))
            }else {
                resume=resume.concat(ParafudrTopHususi(455.86*Scl,1044.64*Scl,Scl,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı",parafudrinisRenk,topKazikRenk))
            }
            }   
         

            
            resume=resume.concat(DirekTrafoView(540.53*Scl+ox,311.84*Scl,Scl,trafoRenk,izolatorRenk))

            if(trafoTextGoster===true){
                resume=resume.concat(DirekTrafoViewTEXT(540.53*Scl+ox,311.84*Scl,Scl,Trafo.trafoGuckVA,trafoTextRenk))                
            }

            resume=resume.concat(TrafoInisKD(540.53*Scl+ox,313.84*Scl,Scl,Pano.InisIletken.Etiket,trfinisRenk,trafoTextRenk))

           // resume=resume.concat(PanoOlcuDagKompKorumaView(597.55*Scl+ox,1232.34*Scl,Scl ))

           resume=resume.concat(KorumaTopView(500.0*Scl+ox,1185.0*Scl,Scl, trfKorumaRenk,topKazikRenk ))
           if(trfKorumaTextGoster===true){
            resume=resume.concat(KorumaTopTEXT(500.0*Scl+ox,1185.0*Scl,Scl,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı" ,trfKorumaTextRenk))
                
            } 
           resume=resume.concat(IsletmeTopView(790.54*Scl+ox,1272.59*Scl,Scl,Direk.trafoisletmeinis,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı",isletmeRenk ))
           resume=resume.concat(TrafoKoumaInisKD(540.53*Scl+ox,313.84*Scl,Scl,trfKorumaRenk))

            if(trfKorumaTextGoster===true){
           resume=resume.concat(TrafoKorumaInisKDTEXT(540.53*Scl+ox,313.84*Scl,Scl,Trafo.Korumainis,trfKorumaTextRenk))

            }
        

           if(Pano.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
            resume=resume.concat(PanoOlcuDagKompView(597.55*Scl+ox,1232.34*Scl,Scl,panoRenk ))
            resume=resume.concat(PanoOlcuDagKompIsletmeView(597.55*Scl+ox,1232.34*Scl,Scl,isletmeRenk ))
            resume=resume.concat(PanoOlcuDagKompKorumaKDView(597.55*Scl+ox,1232.34*Scl,Scl, trfKorumaRenk ))
            resume=resume.concat(PanoOlcuDagKompGirisViewKD(597.55*Scl+ox,1232.34*Scl,Scl ,trfinisRenk)) 
            if(panoTextGoster===true)    {
                resume=resume.concat(PanoTEXT(597.55*Scl+ox,1232.34*Scl,Scl ,"A.D.P.",panoTextRenk))
            }

        }else  if(Pano.PanoTuru==="Ölçüm+Dağıtım"){
            resume=resume.concat(PanoOlcuDagView(597.55*Scl+ox,1232.34*Scl,Scl,panoRenk ))
            resume=resume.concat(PanoOlcuDagIsletmeView(597.55*Scl+ox,1232.34*Scl,Scl,isletmeRenk ))
            resume=resume.concat(PanoOlcuDagKompKorumaKDView(597.55*Scl+ox,1232.34*Scl,Scl,  trfKorumaRenk ))
            resume=resume.concat(PanoOlcuDagKompGirisViewKD(597.55*Scl+ox,1232.34*Scl,Scl,trfinisRenk))
            if(panoTextGoster===true)    {
                resume=resume.concat(PanoTEXT(530.55*Scl+ox,1232.34*Scl,Scl ,"A.D.P.",panoTextRenk))
            }


        }else  if(Pano.PanoTuru==="Ölçüm"){
            resume=resume.concat(PanoOlcuView(524.29*Scl+ox,1141.52*Scl,Scl,panoRenk ))
            resume=resume.concat(PanoOlcuIsletmeView(524.29*Scl+ox,1141.52*Scl,Scl,isletmeRenk ))
            resume=resume.concat(PanoOlcuGirisKD(524.29*Scl+ox,1141.52*Scl,Scl,trfinisRenk ))
            resume=resume.concat(PanoOlcuKorumaViewKD(524.29*Scl+ox,1141.52*Scl,Scl, trfKorumaRenk ))
            if(panoTextGoster===true)    {
                resume=resume.concat(PanoTEXT(400.29*Scl+ox,1200.52*Scl,Scl ,"Ölçü Panosu",panoTextRenk))
            }

        }
        }
        resume=resume.concat(KorkulukSol(441.0*Scl-kx-kkx,830.0*Scl,Scl,korkulukRenk))
        resume=resume.concat(KorkulukSag(466.0*Scl+kx-kkx,830.0*Scl,Scl,korkulukRenk))

//--------------------BETON DİREK-------------------------------------
    }else if(Direk.tur==="beton"){
       let veri={cerceveRenk:cerceveRenk,zeminRenk:zeminRenk,direkRenk:direkRenk} 
        resume=resume.concat(BetonDirekBosView(0,0,Scl,veri ))
        resume=resume.concat(DirekTrafoView(522.52*Scl,368.89*Scl,Scl,trafoRenk,izolatorRenk ))
        if(trafoTextGoster===true){
            resume=resume.concat(DirekTrafoViewTEXT(522.52*Scl,368.89*Scl,Scl,Trafo.trafoGuckVA,trafoTextRenk))                
        }
        resume=resume.concat(MesnetIzalator(477.01*Scl,145.97*Scl,Scl,izolatorRenk )) 
    if(OGHat.OGhatTur==="havai"){ 
        resume=resume.concat(HavaiHat(314.83*Scl,145.97*Scl,Scl,hatdiletkenRenk))
        if(hatTextGoster===true){
            resume=resume.concat(HavaiHatTEXT(310.83*Scl-ox,145.92*Scl,Scl,OGHat.OGhat,hatTextRenk))
        }
        resume=resume.concat(HavaiHatBetonDirekIletken(314.83*Scl,145.97*Scl,Scl,hatdiletkenRenk))
  
    }else   if(OGHat.OGhatTur==="yeralti"){
        resume=resume.concat(BDYerAlti(0*Scl,0*Scl,Scl , OGHat.OGhat,OGHat.OGhatMetre ,hatdiletkenRenk,hatTextRenk ))

    }
    if(Direk.parafudr!=="yok"){
        if(Direk.parafudrKonum==="once"){
            resume=resume.concat(ParafudrOnce(443.51*Scl,222.89*Scl,Scl,parafudrRenk,direkRenk))

            if(parafudrTextGoster===true){
                resume=resume.concat(ParafudrOnceTEXT(443.51*Scl,222.89*Scl,Scl,Direk.parafudr,"Parafudr",parafudrTextRenk))
            }
            if(OGHat.OGhatTur==="havai"){ 
                resume=resume.concat(BDParafudrOnceHavai(314.83*Scl,146.97*Scl,Scl,parafudrGirisRenk ))
            }else if(OGHat.OGhatTur==="yeralti"){
                    resume=resume.concat(BDParafudrOnceYeralti(314.83*Scl,146.97*Scl,Scl,parafudrGirisRenk ))
            }
    
            if(Direk.parafudrTopraklama==="Müşterek"){
                
                resume=resume.concat(BDParafudrMusterekTop(443.51*Scl,222.89*Scl,Scl,parafudrinisRenk))

            }else {
                resume=resume.concat(BDParafudrHususiTop1(443.51*Scl,222.89*Scl,Scl,parafudrinisRenk ))
                resume=resume.concat(BDParafudrHususiTop2(443.51*Scl,222.89*Scl,Scl,Direk.parafudrInis,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı",parafudrinisRenk,parafudrTextRenk,topKazikRenk))

                //resume=resume.concat(BDParafudrHususiTop3(443.51*Scl,222.89*Scl,Scl,parafudrinisRenk  ))     

               } 
        }else if(Direk.parafudrKonum==="sonra"){
            resume=resume.concat(ParafudrSonra(652.51*Scl,417.89*Scl,Scl ,parafudrRenk ))
            if(parafudrTextGoster===true){
                resume=resume.concat(ParafudrSonraTEXT(652.51*Scl,417.89*Scl,Scl,Direk.parafudrGiris,Direk.parafudr,"Parafudr",parafudrTextRenk))
            }
            if(Direk.seksiyoner!=="yok"){
                resume=resume.concat(BDParafudrSonraSeksiyonerli(650.81*Scl,416.89*Scl,Scl  ))
            }else{ 
                resume=resume.concat(BDParafudrSonraSeksiyonersiz(647.81*Scl,417.89*Scl,Scl  ))
            }
 
            if(Direk.parafudrTopraklama==="Müşterek"){                
                resume=resume.concat(ParafudrSonraMusterekTop(648.51*Scl,417.89*Scl,Scl,Direk.parafudrInis,Direk.parafudr,"Parafudr 2" ,parafudrinisRenk ))
            }else {
                resume=resume.concat(BDParafudrHususiTop2(443.51*Scl,222.89*Scl,Scl,Direk.parafudrInis,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı",parafudrinisRenk,parafudrTextRenk,topKazikRenk))
                resume=resume.concat(BDParafudrHususiTop3(443.51*Scl,222.89*Scl,Scl ,parafudrinisRenk ))           
             }
        } 
    }
        if(Direk.seksiyoner!=="yok"){
            resume=resume.concat(SeksiyonerView(497.01*Scl,176.42*Scl,Scl,seksiyonerRenk,izolatorRenk))
            if(seksiyonerTextGoster===true){
                resume=resume.concat(SeksiyonerViewTEXT(497.01*Scl,176.42*Scl,Scl,Direk.seksiyonergiris,Direk.seksiyoner,"Seksiyoner",seksiyonerTextRenk))

            }
            resume=resume.concat(AyiriciKoluView(497.41*Scl,973.92*Scl,Scl,SekKolRenk))
            resume=resume.concat(BetonSeksiyonerTop(477.01*Scl,141.39*Scl,Scl,sekiletkenRenk))
            resume=resume.concat(DemirDirekSeksiyonerGiris(497.01*Scl,176.42*Scl,Scl,SekGirisRenk))
            resume=resume.concat(BetonDirekSeksiyonerCikis(505.01*Scl,175.39*Scl,Scl,trfgirisRenk,Trafo.trafoGiris,trfgirisRenk)) 
        }else{
            resume=resume.concat(DemirDirekSeksiyonerGiris(497.01*Scl,176.42*Scl,Scl,trfgirisRenk ))  
            resume=resume.concat(BetonSeksiyonersizTrfGiris(477.01*Scl,141.39*Scl,Scl,trfgirisRenk,Trafo.trafoGiris,trfgirisRenk ))
        } 
  
   

        //resume=resume.concat(BetonDirekSeksiyonerGiris(477.01*Scl,142.39*Scl,Scl,"Havai hat" ))
    
        

        resume=resume.concat(BDTrafoInis(0*Scl,0*Scl,Scl,Pano.InisIletken.Etiket ,trfinisRenk,trafoTextRenk ))


        if(Pano.PanoTuru==="Ölçüm+Dağıtım+Kompanzasyon"){
        resume=resume.concat(BDTrafoInisDag(0*Scl,0*Scl,Scl ,trfinisRenk ))
        resume=resume.concat(BDPanoOlcuDagKompIsletmeView(617.01*Scl,1207.89*Scl,Scl ,isletmeRenk ))
        resume=resume.concat(PanoOlcuDagKompView(617.01*Scl,1207.89*Scl,Scl ,panoRenk ))
        resume=resume.concat(PanoOlcuDagKorumaView(617.01*Scl,1207.89*Scl,Scl ,trfKorumaRenk ))
        if(panoTextGoster===true)    {
            resume=resume.concat(PanoTEXT(617.01*Scl,1207.89*Scl,Scl ,"A.D.P.",panoTextRenk))
        }   
            
    
            }else  if(Pano.PanoTuru==="Ölçüm+Dağıtım"){
        resume=resume.concat(PanoOlcuDagView(617.01*Scl,1207.89*Scl,Scl,panoRenk,panoTextRenk  ))
        resume=resume.concat(PanoOlcuDagKorumaView(617.01*Scl,1207.89*Scl,Scl,trfKorumaRenk  ))                
        resume=resume.concat(BDTrafoInisDag(0*Scl,0*Scl,Scl ,trfinisRenk ))
        resume=resume.concat(BDPanoOlcuDagKompIsletmeView(617.01*Scl,1207.89*Scl,Scl,isletmeRenk  ))           
        if(panoTextGoster===true)    {
            resume=resume.concat(PanoTEXT(550.01*Scl,1207.89*Scl,Scl ,"A.D.P.",panoTextRenk))
        }   
    
            }else  if(Pano.PanoTuru==="Ölçüm"){
                resume=resume.concat(PanoOlcuView(497.41*Scl,1066.71*Scl,Scl ,panoRenk,panoTextRenk ))
                resume=resume.concat(BDTrafoInisOlcu(0*Scl,0*Scl,Scl,trfinisRenk  ))
                resume=resume.concat(BDPanoOlcuIsletmeView(0*Scl,0*Scl,Scl, isletmeRenk ))
                resume=resume.concat(BDPanoOlcuKorumaView(0*Scl,0*Scl,Scl ,trfKorumaRenk ))
                if(panoTextGoster===true)    {
                    resume=resume.concat(PanoTEXT(380.41*Scl,1140*Scl,Scl ,"Ölçü Panosu",panoTextRenk))
                }  
                
    
            }



        resume=resume.concat(OTLView(281.72*Scl,914.51*Scl,Scl,otlRenk,otlTextRenk ))


        resume=resume.concat(BDTrafoKoruma(0*Scl,0*Scl,Scl,trfKorumaRenk  ))
        resume=resume.concat(IsletmeTopView(790.43*Scl,1272.59*Scl,Scl ,"işletme","işletme1","işletme2","işletme3" ,isletmeRenk))
        resume=resume.concat(KorumaTopView(490.43*Scl,1180.59*Scl,Scl   ,trfKorumaRenk,trfKorumaTextRenk))     
        if(trfKorumaTextGoster===true){
            resume=resume.concat(KorumaTopTEXT(490.43*Scl,1180.59*Scl,Scl,"65x65x7mm","Galvaniz Profil","2m Top. Kazığı" ,trfKorumaTextRenk))
                
            } 
    }
    
        return resume
    }
    
    return (     
    
    
        <React.Fragment>   
  

              <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
        style={{ width: "98%", background: bgcolor }}
      >
  <thead    >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}> DİREK DETAYI GÖRSEL AYARLARI</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "250px"} : { display: "none" }}>

         <tr style={{ width:"100%" }}>
            <td style={{width:"100%" }}>Çerçeve<Button  size="sm" style={{width:"30px",height:"20px", background: cerceveRenk ,float:"right" }} onClick={()=>Sec("DDCerRenk","Çerçeve Renk ",cerceveRenk)}></Button>                         
            </td><td></td>
          </tr>  
           
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }}>Zemin<Button  size="sm" style={{width:"30px",height:"20px",  background: zeminRenk ,float:"right" }} onClick={()=>Sec("DDZemRenk","Zemin Renk",zeminRenk)}></Button>
            </td><td></td>
         </tr> 
            
          
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Direk<Button  size="sm" style={{width:"30px",height:"20px",  background: direkRenk ,float:"right" }} onClick={()=>Sec("DDdirekRenk","Direk Renk",direkRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Pano<Button  size="sm" style={{width:"30px",height:"20px",  background: panoRenk ,float:"right" }} onClick={()=>Sec("DDpanoRenk","Pano Renk ",panoRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Pano Text    Metin
                  <input type="checkbox" defaultChecked={panoTextGoster} onChange={() => dispatch(UpdateDDpanoTextGoster(!panoTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: panoTextRenk ,float:"right" }} onClick={()=>Sec("DDpanoTextRenk","Pano Text Renk ",panoTextRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo<Button  size="sm" style={{width:"30px",height:"20px",  background: trafoRenk ,float:"right" }} onClick={()=>Sec("DDtrafoRenk","Trafo Renk",trafoRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo Text       Göster
                  <input type="checkbox" defaultChecked={trafoTextGoster} onChange={() => dispatch(UpdateDDtrafoTextGoster(!trafoTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: trafoTextRenk ,float:"right" }} onClick={()=>Sec("DDtrafoTextRenk","Trafo Text Renk ",trafoTextRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo Giriş İletken Rengi<Button  size="sm" style={{width:"30px",height:"20px",  background: trfgirisRenk ,float:"right" }} onClick={()=>Sec("DDtrafoGirisRenk","Trafo Giriş İletken Rengi ",trfgirisRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo İniş<Button  size="sm" style={{width:"30px",height:"20px",  background: trfinisRenk ,float:"right" }} onClick={()=>Sec("DDtrfinisRenk","Trafo İniş İletken Rengi",trfinisRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo Koruma Top.<Button  size="sm" style={{width:"30px",height:"20px",  background: trfKorumaRenk ,float:"right" }} onClick={()=>Sec("DDtrafoKorRenk","Trafo Kor. Top. İletken Rengi",trfKorumaRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Trafo Koruma Text               Göster
                  <input type="checkbox" defaultChecked={trfKorumaTextGoster} onChange={() => dispatch(UpdateDDtrafoKorTextGoster(!trafoTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: trfKorumaTextRenk ,float:"right" }} onClick={()=>Sec("DDtrafoTextRenk","Trafo Text Renk",trfKorumaTextRenk)}></Button>                         
            </td><td></td>
          </tr> 
          
          {/* ----------------PARAFUDR------------------------------------- */}
           
          {Direk.parafudr!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Parafudr<Button  size="sm" style={{width:"30px",height:"20px",  background: parafudrRenk ,float:"right" }} onClick={()=>Sec("DDparafRenk","Parafudr Renk",parafudrRenk)}></Button>                         
            </td><td></td>
          </tr> :null}
          {Direk.parafudr!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Parafudr Text       Göster
                  <input type="checkbox" defaultChecked={parafudrTextGoster} onChange={() => dispatch(UpdateDDparafTextGoster(!parafudrTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: parafudrTextRenk ,float:"right" }} onClick={()=>Sec("DDparafTextRenk","Parafudr Text Renk",parafudrTextRenk)}></Button>                         
            </td><td></td>
          </tr> :null}
           {Direk.parafudr!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Parafudr Giriş<Button  size="sm" style={{width:"30px",height:"20px",  background: parafudrGirisRenk ,float:"right" }} onClick={()=>Sec("DDparafGirisRenk","Parafudr Giriş Renk",parafudrGirisRenk)}></Button>                         
            </td><td></td>
          </tr>:null}
          {Direk.parafudr!=="yok"? 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Parafudr İniş<Button  size="sm" style={{width:"30px",height:"20px",  background: parafudrinisRenk ,float:"right" }} onClick={()=>Sec("DDparafInisRenk","Parafudr İniş iletken Renk",parafudrinisRenk)}></Button>                         
            </td><td></td>
          </tr> :null}  

          {/* -----------------SEKSİYONER------------------------------------------- */}
            {Direk.seksiyoner!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Seksiyoner        <Button  size="sm" style={{width:"30px",height:"20px",  background: seksiyonerRenk ,float:"right" }} onClick={()=>Sec("DDSekRenk","Seksiyoner Renk ",seksiyonerRenk)}>
            </Button>                         
            </td><td></td>
          </tr>:null}
          {Direk.seksiyoner!=="yok"?
          <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Seksiyoner İletken <Button  size="sm" style={{width:"30px",height:"20px",  background: sekiletkenRenk ,float:"right" }} onClick={()=>Sec("DDSekIletkenRenk","Seksiyoner Topraklama iletken ",sekiletkenRenk)}></Button></td><td></td>
          </tr> :null}
          {Direk.seksiyoner!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Seksiyoner Text   Göster
                  <input type="checkbox" defaultChecked={seksiyonerTextGoster} onChange={() => dispatch(UpdateDDSekTextGoster(!seksiyonerTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: seksiyonerTextRenk ,float:"right" }} onClick={()=>Sec("DDSekTextRenk","Seksiyoner Text Renk ",seksiyonerTextRenk)}></Button>                         
            </td><td></td>
          </tr> :null}
          {Direk.seksiyoner!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Seksiyoner Giriş <Button  size="sm" style={{width:"30px",height:"20px",  background: SekGirisRenk ,float:"right" }} onClick={()=>Sec("DDSekGirisRenk","Seksiyoner Giriş iletken Renk ",SekGirisRenk)}></Button>                         
            </td><td></td>
          </tr> :null}
          {Direk.seksiyoner!=="yok"?
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Seksiyoner Kolu   <Button  size="sm" style={{width:"30px",height:"20px",  background: SekKolRenk ,float:"right" }} onClick={()=>Sec("DDSekKolRenk","Seksiyoner Kolu Renk ",SekKolRenk)}></Button>                         
            </td><td></td>
          </tr> :null}
          
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Korkuluk    <Button  size="sm" style={{width:"30px",height:"20px",  background: korkulukRenk ,float:"right" }} onClick={()=>Sec("DDKorkulukRenk","Korkuluk Renk ",korkulukRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Ölüm Tehlike Lev.    <Button  size="sm" style={{width:"30px",height:"20px",  background: otlRenk ,float:"right" }} onClick={()=>Sec("DDotlRenk","Ölüm Tehlike Levhası ",otlRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Ölüm Tehlike Lev.Text    <Button  size="sm" style={{width:"30px",height:"20px",  background: otlTextRenk ,float:"right" }} onClick={()=>Sec("DDotlTextRenk","Ölüm Tehlike Levhası Text ",otlTextRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >OG Hat İletkeni    <Button  size="sm" style={{width:"30px",height:"20px",  background: hatdiletkenRenk ,float:"right" }} onClick={()=>Sec("DDhatIletkenRenk","OG Hat iletkeni Renk ",hatdiletkenRenk)}></Button>                         
            </td><td></td>
          </tr> 
     
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >OG Hat İlet. Text        Göster
                  <input type="checkbox" defaultChecked={hatTextGoster} onChange={() => dispatch(UpdateDDSekTextGoster(!hatTextGoster))} />
            <Button  size="sm" style={{width:"30px",height:"20px",  background: hatTextRenk ,float:"right" }} onClick={()=>Sec("DDHatTextRenk","Hat iletkeni Text Renk ",hatTextRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >İzolatör   <Button  size="sm" style={{width:"30px",height:"20px",  background: izolatorRenk ,float:"right" }} onClick={()=>Sec("DDizolatorRenk","İzolatör Renk ",izolatorRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >İşletme   <Button  size="sm" style={{width:"30px",height:"20px",  background: isletmeRenk ,float:"right" }} onClick={()=>Sec("DDIsletmeRenk","İşletme Renk ",isletmeRenk)}></Button>                         
            </td><td></td>
          </tr> 
          <tr style={{ width:"100%" }}>
            <td style={{ width:"100%" }} >Topraklama Kazığı  <Button  size="sm" style={{width:"30px",height:"20px",  background: topKazikRenk ,float:"right" }} onClick={()=>Sec("DDtopKazRenk","Topraklama Kazıkları Renk ",topKazikRenk)}></Button>                         
            </td><td></td>
          </tr> 
          
          </tbody>

      </Table>

    {/*  
    
                
          
   

    
    */}
        </React.Fragment>
    )

}
