import { createSlice } from '@reduxjs/toolkit'
 


var d = new Date();

var datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() 
export const genelBilgilerSlice = createSlice({
  

  name: 'genelBilgiler',
  initialState: {
    

    //------------------MÜELLİF BİLGLİLERİ-------------------------
    M_AdSoyad:" " ,//Müellif Adı Soyadı
    M_OdaSiciNo:" " ,// 
    M_DiplomaNo:" " ,// 
    M_BuroNo:" " ,// 
    M_TelNo:" " ,// 
    M_FirmaAdi:" " ,// 
    M_FirmaAdresi:" " ,// 
    M_VergiDairesi:" " ,// 
    M_VergiNo:" " ,// 
    //------------------PROJEYİ YAPTIRAN------------------------------
    PY_AdSoyad:"" ,// 
    PY_Adres:"" ,// 
    PY_VergiDairesi:"" ,// 
    PY_VergiNo:"" ,// 
    PY_Telefon:"" ,// 
    PY_Mail:"" ,// 
    //--------------------ARSA BİLGİLERİ---------------------------------------
    A_AdSoyad:"" ,// 
    A_Il:"" ,// 
    A_Ilce:"" ,// 
    A_Mahalle:"" ,// 
    A_Sokak:"" ,// 
    A_No:"" ,// 
    A_Adres:"" ,// 
    A_Ada:"" ,// 
    A_Pafta:"" ,// 
    A_Parsel:"" ,// 
    A_Alan:"" ,// 
    //------------------YAPI OZELLİKLERİ--------------------------------
    YO_Kullanim:"" ,// 
    YO_Alan:"" ,// 
    YO_KatSayi:"" ,// 
    YO_BagBolSay:"" ,// 
    YO_CizimTarih:datestring ,// 
    YO_Cizen:"_" ,//  
    YO_YapimSuresi:"",// 
    YO_YapiSinifi:"" ,// 
    YO_ProjeNo:"trf-1" ,// 
    YO_Olcek:"1/50" ,// 
    //---------------ONAY KURUMU--------------------------------------
    O_Kurum:"" ,// 
    Kesif:[],// 
    },
  reducers: {  
    UpdateM_AdSoyad: (state, action) => {state.M_AdSoyad  = action.payload },
    UpdateM_OdaSiciNo: (state, action) => {state.M_OdaSiciNo  = action.payload },
    UpdateM_DiplomaNo: (state, action) => {state.M_DiplomaNo  = action.payload },
    UpdateM_BuroNo: (state, action) => {state.M_BuroNo  = action.payload },
    UpdateM_TelNo: (state, action) => {state.M_TelNo  = action.payload },
    UpdateM_FirmaAdi: (state, action) => {state.M_FirmaAdi  = action.payload },
    UpdateM_FirmaAdresi: (state, action) => {state.M_FirmaAdresi  = action.payload },
    UpdateM_VergiDairesi: (state, action) => {state.M_VergiDairesi  = action.payload },
    UpdateM_VergiNo: (state, action) => {state.M_VergiNo  = action.payload },

    UpdatePY_AdSoyad: (state, action) => {state.PY_AdSoyad  = action.payload },
    UpdatePY_Adres: (state, action) => {state.PY_Adres  = action.payload },
    UpdatePY_VergiDairesi: (state, action) => {state.PY_VergiDairesi  = action.payload },
    UpdatePY_VergiNo: (state, action) => {state.PY_VergiNo  = action.payload },
    UpdatePY_Telefon: (state, action) => {state.PY_Telefon  = action.payload },
    UpdatePY_Mail: (state, action) => {state.PY_Mail  = action.payload },
 
    UpdateA_AdSoyad: (state, action) => {state.A_AdSoyad  = action.payload },
    UpdateA_Il: (state, action) => {state.A_Il  = action.payload },
    UpdateA_Ilce: (state, action) => {state.A_Ilce  = action.payload },
    UpdateA_Mahalle: (state, action) => {state.A_Mahalle  = action.payload },
    UpdateA_Sokak: (state, action) => {state.A_Sokak  = action.payload },
    UpdateA_No: (state, action) => {state.A_No  = action.payload },
    UpdateA_Adres: (state, action) => {state.A_Adres  = action.payload },
    UpdateA_Ada: (state, action) => {state.A_Ada  = action.payload },
    UpdateA_Pafta: (state, action) => {state.A_Pafta  = action.payload },
    UpdateA_Parsel: (state, action) => {state.A_Parsel  = action.payload },
    UpdateA_Alan: (state, action) => {state.A_Alan  = action.payload },

    UpdateYO_Kullanim: (state, action) => {state.YO_Kullanim  = action.payload },
    UpdateYO_Alan: (state, action) => {state.YO_Alan  = action.payload },
    UpdateYO_KatSayi: (state, action) => {state.YO_KatSayi  = action.payload },
    UpdateYO_BagBolSay: (state, action) => {state.YO_BagBolSay  = action.payload },
    UpdateYO_CizimTarih: (state, action) => {state.YO_CizimTarih  = action.payload },
    UpdateYO_Cizen: (state, action) => {state.YO_Cizen  = action.payload },
    UpdateYO_Sure: (state, action) => {state.YO_YapimSuresi  = action.payload },
    UpdateYO_YapiSinifi: (state, action) => {state.YO_YapiSinifi  = action.payload },
    UpdateYO_ProjeNo: (state, action) => {state.YO_ProjeNo  = action.payload },
    UpdateYO_Olcek: (state, action) => {state.YO_Olcek  = action.payload },

    UpdateO_Kurum: (state, action) => {state.O_Kurum  = action.payload },
    Update_Kesif: (state, action) => {state.Kesif  = action.payload },

  }, 
})
  
export const {Update_Kesif,UpdateM_AdSoyad,UpdateM_OdaSiciNo,UpdateM_DiplomaNo,UpdateM_BuroNo,UpdateM_TelNo,UpdateM_FirmaAdi,UpdateM_FirmaAdresi,UpdateM_VergiDairesi,UpdateM_VergiNo,UpdatePY_AdSoyad,UpdatePY_Adres,UpdatePY_VergiDairesi,UpdatePY_VergiNo,UpdatePY_Telefon,UpdatePY_Mail,UpdateA_AdSoyad,UpdateA_Il,UpdateA_Ilce,UpdateA_Mahalle,UpdateA_Sokak,UpdateA_No,UpdateA_Adres,UpdateA_Ada,UpdateA_Pafta,UpdateA_Parsel,UpdateA_Alan,UpdateYO_Kullanim,UpdateYO_Alan,UpdateYO_KatSayi,UpdateYO_BagBolSay,UpdateYO_CizimTarih,UpdateYO_Cizen,UpdateYO_Sure,UpdateYO_YapiSinifi,UpdateYO_ProjeNo,UpdateYO_Olcek,UpdateO_Kurum  } = genelBilgilerSlice.actions

export default genelBilgilerSlice.reducer    

 