
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {Iletken} from '../Data/Malzeme'
let X=0;
let Y=0; 
let  denemeCizim=[]

export default function KompanzasyonHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetaylar = useSelector((state) => state.PanoDetay)
    const kompDetaylar = useSelector((state) => state.KompDetay)
    let kademeler=""
     for (var a =0;a<=kompDetaylar.Kademeler.length-1;a++){        
         kademeler+= kompDetaylar.Kademeler[a].Kondansator+"kVAr + "    
     } 
    kademeler=kademeler.slice(0,kademeler.length-2)
    let Akim=(kompDetaylar.KompGuc/400/1.73).toFixed(2)
    let iletken=kompDetaylar.Iletken
     let iletkenNo=101;
        if(iletken===0){iletkenNo=101}else{iletkenNo=iletken.No}
    let iletkenAkimKap=Iletken[iletkenNo].HavaAK
    let dilekceText1="KOMPANZASYON HESABI"
     let dilekceText2="Talep Güç                        :"+trafoDetaylar.talepGuc+" W \n CosFi1                               :"+kompDetaylar.CosFi1+"\n  CosFi2                               :"+kompDetaylar.CosFi2+"\n K                                    :"+ kompDetaylar.K+" \n Kompanzasyon Gücü    :KxTalep Güç\n Kompanzasyon Gücü    :"+kompDetaylar.K+"x"+trafoDetaylar.talepGuc+"kW\n Kompanzasyon Gücü " +kompDetaylar.KompGuc +" kVA olarak seçilmiştir. \nGrup Dizini     = "+kademeler +"\n \n Kompanzasyon iletken Bara Seçimi\n\nSeçilen Kompanzasyon gücü		="+kompDetaylar.KompGuc + "kVAr\n En Fazla  Bara Akımı			="+kompDetaylar.KompGuc + " kVAR /(400Vx√3)\n En Fazla  Bara Akımı			="+ Akim+ " A\n   Kesici olarak "+kompDetaylar.Kesici.Etiket+" seçilmiştir.\n Tüm kademeler devrede iken kompanzasyon sisteminde  "+ Akim+ "A  akım akması beklenir bunun için kullanılacak iletken akım taşıma kapasitesi 1156 amperden çok olmalıdır.\n Ana Kompanzasyon iletkeni akım taşıma kapasitesi havada "+iletkenAkimKap+"A olan  "+iletken.Etiket+" kabloda olacaktır.\nKompanzasyon akım trafosu çevirme oranı "+panoDetaylar.AkimTrafoOran+" olacaktır."
    
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
    let hh=700*Scl;
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})

    function artirW(val){
        let Cizim=[
        { Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
        [
            X + Scl * (0), Y + Scl * (0), 
            X + Scl * (500), Y + Scl * (0), 
            X + Scl * (500), Y +   (hh), 
            X + Scl * (0), Y +   (hh)
        ] }, 
        { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl *15,align:"center", TextString:dilekceText1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
        { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl *15,align:"justify", TextString:dilekceText2, TextStyleName: "  1", TextArc: 0,width:400*Scl } ]

        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:hh,                          
            },
            cizim:Cizim
        })); 

    }
    useEffect(() => {       
        artirW(panoDetaylar)
        
    }, [cizimOzellik,panoDetaylar,trafoDetaylar,kompDetaylar ]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
    return (       <>      </>   )

}
