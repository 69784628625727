const Aciklama=[];

Aciklama[0]='Aciklama=Linye Seçiniz>KısadevreAkım=3>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[1]='Aciklama=Aydınlatma Linyesi>KısadevreAkım=3>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[2]='Aciklama=Priz Linyesi>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[3]='Aciklama=Çamaşır Makinası>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[4]='Aciklama=Bulaşık Makinası>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[5]='Aciklama=Fırın>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[6]='Aciklama=Merdiven Otomatiği>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[7]='Aciklama=Yangın İhbar Santra>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[8]='Aciklama=Zil-Kapı Otomatiği>Tip=B>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
Aciklama[9]='Aciklama=Klima>Kutup=1P>Tür=AOS>Etiket=TipB,1P,3A,3kA';
export const DEMIRDIREK=[]
DEMIRDIREK[0]={Ad:"T15",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[1]={Ad:"T25",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[2]={Ad:"T35",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[3]={Ad:"T50",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[4]={Ad:"TK15",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[5]={Ad:"TK25",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[6]={Ad:"TK35",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}
DEMIRDIREK[7]={Ad:"TK50",Agirlik:5,temelBoy:1.2,dipGenislik:1.17,tepeGenislik:30,temelEn:1.2,uzunluk:13,fiyat:{malzeme:0,montaj:0,nakliye:0}}

export const BETONDİREK=[]
BETONDİREK[0]={tepekuvvet:1500, 
    m12:{temel:1.30,agirlik:2570,taban:495,tepe:315},
    m13:{temel:1.40,agirlik:2900,taban:510,tepe:315},
    m14:{temel:1.50,agirlik:3230,taban:525,tepe:315},
    m15:{temel:1.50,agirlik:3620,taban:540,tepe:315},
    m16:{temel:1.60,agirlik:3950,taban:555,tepe:315},
    m17:{temel:1.60,agirlik:4340,taban:570,tepe:315},
    m18:{temel:1.70,agirlik:4680,taban:585,tepe:315},
    m19:{temel:1.70,agirlik:5140,taban:600,tepe:315},
    m20:{temel:1.80,agirlik:5570,taban:615,tepe:315},
    m21:{temel:1.80,agirlik:5980,taban:630,tepe:315},
    m22:{temel:1.90,agirlik:6360,taban:630,tepe:315},
    m23:{temel:1.90,agirlik:6770,taban:630,tepe:315},
    m24:{temel:2.0, agirlik:7190,taban:630,tepe:315},
    m25:{temel:2.0, agirlik:7700,taban:630,tepe:315}
}
BETONDİREK[1]={tepekuvvet:1600, 
    m12:{temel:1.40,agirlik:2590,taban:495,tepe:315},
    m13:{temel:1.40,agirlik:2920,taban:510,tepe:315},
    m14:{temel:1.50,agirlik:3260,taban:525,tepe:315},
    m15:{temel:1.60,agirlik:3650,taban:540,tepe:315},
    m16:{temel:1.60,agirlik:3980,taban:555,tepe:315},
    m17:{temel:1.70,agirlik:4370,taban:570,tepe:315},
    m18:{temel:1.70,agirlik:4730,taban:585,tepe:315},
    m19:{temel:1.80,agirlik:5190,taban:600,tepe:315},
    m20:{temel:1.80,agirlik:5620,taban:615,tepe:315},
    m21:{temel:1.90,agirlik:6030,taban:630,tepe:315},
    m22:{temel:1.90,agirlik:6410,taban:630,tepe:315},
    m23:{temel:2.0, agirlik:6830,taban:630,tepe:315},
    m24:{temel:2.0, agirlik:7250,taban:630,tepe:315},
    m25:{temel:2.10,agirlik:7770,taban:630,tepe:315}
}
BETONDİREK[2]={tepekuvvet:1700, 
    m12:{temel:1.40,agirlik:2610,taban:495,tepe:315},
    m13:{temel:1.50,agirlik:2940,taban:510,tepe:315},
    m14:{temel:1.60,agirlik:3290,taban:525,tepe:315},
    m15:{temel:1.60,agirlik:3680,taban:540,tepe:315},
    m16:{temel:1.70,agirlik:4010,taban:555,tepe:315},
    m17:{temel:1.70,agirlik:4400,taban:570,tepe:315},
    m18:{temel:1.80,agirlik:4780,taban:585,tepe:315},
    m19:{temel:1.90,agirlik:5230,taban:600,tepe:315},
    m20:{temel:1.90,agirlik:5670,taban:615,tepe:315},
    m21:{temel:1.90,agirlik:6080,taban:630,tepe:315},
    m22:{temel:2.0, agirlik:6460,taban:630,tepe:315},
    m23:{temel:2.0, agirlik:7680,taban:630,tepe:360},
    m24:{temel:2.10,agirlik:8120,taban:630,tepe:360},
    m25:{temel:2.10,agirlik:8610,taban:630,tepe:360}
}
BETONDİREK[3]={tepekuvvet:1800, 
    m12:{temel:1.50,agirlik:2630,taban:495,tepe:315},
    m13:{temel:1.50,agirlik:2960,taban:510,tepe:315},
    m14:{temel:1.60,agirlik:3320,taban:525,tepe:315},
    m15:{temel:1.70,agirlik:3700,taban:540,tepe:315},
    m16:{temel:1.70,agirlik:4040,taban:555,tepe:315},
    m17:{temel:1.80,agirlik:4440,taban:570,tepe:315},
    m18:{temel:1.90,agirlik:4830,taban:585,tepe:315},
    m19:{temel:1.90,agirlik:5280,taban:600,tepe:315},
    m20:{temel:2.0, agirlik:5720,taban:615,tepe:315},
    m21:{temel:2.0, agirlik:6130,taban:630,tepe:315},
    m22:{temel:2.10,agirlik:6510,taban:630,tepe:315},
    m23:{temel:2.10,agirlik:7740,taban:630,tepe:1},
    m24:{temel:2.10,agirlik:8180,taban:630,tepe:1},
    m25:{temel:2.20,agirlik:8680,taban:630,tepe:1}
}
BETONDİREK[4]={tepekuvvet:1900, 
    m12:{temel:1.50,agirlik:2650,taban:495,tepe:315},
    m13:{temel:1.60,agirlik:2980,taban:510,tepe:315},
    m14:{temel:1.70,agirlik:3350,taban:525,tepe:315},
    m15:{temel:1.70,agirlik:3730,taban:540,tepe:315},
    m16:{temel:1.80,agirlik:4070,taban:555,tepe:315},
    m17:{temel:1.80,agirlik:4470,taban:570,tepe:315},
    m18:{temel:1.90,agirlik:4880,taban:585,tepe:315},
    m19:{temel:2.0, agirlik:5530,taban:600,tepe:315},
    m20:{temel:2.0, agirlik:5770,taban:615,tepe:315},
    m21:{temel:2.10,agirlik:6180,taban:630,tepe:315},
    m22:{temel:2.10,agirlik:6560,taban:630,tepe:315},
    m23:{temel:2.20,agirlik:7800,taban:630,tepe:360},
    m24:{temel:2.20,agirlik:8240,taban:630,tepe:360},
    m25:{temel:2.30,agirlik:8750,taban:630,tepe:360}
}
BETONDİREK[5]={tepekuvvet:2000, 
    m12:{temel:1.60,agirlik:2670,taban:495,tepe:315},
    m13:{temel:1.60,agirlik:3000,taban:510,tepe:315},
    m14:{temel:1.70,agirlik:3380,taban:525,tepe:315},
    m15:{temel:1.80,agirlik:3760,taban:540,tepe:315},
    m16:{temel:1.80,agirlik:4100,taban:555,tepe:315},
    m17:{temel:1.90,agirlik:4510,taban:570,tepe:315},
    m18:{temel:1.90,agirlik:4930,taban:585,tepe:315},
    m19:{temel:2.0, agirlik:5380,taban:600,tepe:315},
    m20:{temel:2.10,agirlik:5820,taban:615,tepe:315},
    m21:{temel:2.10,agirlik:6230,taban:630,tepe:315},
    m22:{temel:2.20,agirlik:6610,taban:630,tepe:315},
    m23:{temel:2.20,agirlik:7860,taban:630,tepe:360},
    m24:{temel:2.30,agirlik:8300,taban:630,tepe:360},
    m25:{temel:2.30,agirlik:8820,taban:630,tepe:360}
}
BETONDİREK[6]={tepekuvvet:2100, 
    m12:{temel:1.60,agirlik:2690,taban:495,tepe:315},
    m13:{temel:1.70,agirlik:3020,taban:510,tepe:315},
    m14:{temel:1.70,agirlik:3410,taban:525,tepe:315},
    m15:{temel:1.80,agirlik:3800,taban:540,tepe:315},
    m16:{temel:1.90,agirlik:4130,taban:555,tepe:315},
    m17:{temel:1.90,agirlik:4550,taban:570,tepe:315},
    m18:{temel:2.0, agirlik:4980,taban:585,tepe:315},
    m19:{temel:2.10,agirlik:5420,taban:600,tepe:315},
    m20:{temel:2.10,agirlik:6380,taban:630,tepe:360},
    m21:{temel:2.20,agirlik:6900,taban:630,tepe:360},
    m22:{temel:2.20,agirlik:7380,taban:630,tepe:360},
    m23:{temel:2.30,agirlik:7920,taban:630,tepe:360},
    m24:{temel:2.30,agirlik:8360,taban:630,tepe:360},
    m25:{temel:2.40,agirlik:8890,taban:630,tepe:360}
}
BETONDİREK[7]={tepekuvvet:2200, 
    m12:{temel:1.60,agirlik:2710,taban:495,tepe:315},
    m13:{temel:1.70,agirlik:3040,taban:510,tepe:315},
    m14:{temel:1.80,agirlik:3440,taban:525,tepe:315},
    m15:{temel:1.90,agirlik:3840,taban:540,tepe:315},
    m16:{temel:1.90,agirlik:4160,taban:555,tepe:315},
    m17:{temel:2.0, agirlik:4580,taban:570,tepe:315},
    m18:{temel:2.10,agirlik:5030,taban:585,tepe:315},
    m19:{temel:2.10,agirlik:5480,taban:600,tepe:315},
    m20:{temel:2.20,agirlik:6440,taban:630,tepe:360},
    m21:{temel:2.20,agirlik:6960,taban:630,tepe:360},
    m22:{temel:2.30,agirlik:7440,taban:630,tepe:360},
    m23:{temel:2.30,agirlik:7980,taban:630,tepe:360},
    m24:{temel:2.40,agirlik:8420,taban:630,tepe:360},
    m25:{temel:2.40,agirlik:8960,taban:630,tepe:360}
}
BETONDİREK[8]={tepekuvvet:2300, 
    m12:{temel:1.70,agirlik:2730,taban:495,tepe:315},
    m13:{temel:1.80,agirlik:3060,taban:510,tepe:315},
    m14:{temel:1.80,agirlik:3470,taban:525,tepe:315},
    m15:{temel:1.90,agirlik:3860,taban:540,tepe:315},
    m16:{temel:2.0, agirlik:4190,taban:555,tepe:315},
    m17:{temel:2.0, agirlik:4620,taban:570,tepe:315},
    m18:{temel:2.10,agirlik:5180,taban:585,tepe:315},
    m19:{temel:2.20,agirlik:5530,taban:600,tepe:315},
    m20:{temel:2.20,agirlik:6500,taban:630,tepe:360},
    m21:{temel:2.30,agirlik:7020,taban:630,tepe:360},
    m22:{temel:2.30,agirlik:7500,taban:630,tepe:360},
    m23:{temel:2.40,agirlik:8040,taban:630,tepe:360},
    m24:{temel:2.40,agirlik:8480,taban:630,tepe:360},
    m25:{temel:2.50,agirlik:9030,taban:630,tepe:360}
}
BETONDİREK[8]={tepekuvvet:2400, 
    m12:{temel:1.70,agirlik:2750,taban:495,tepe:315},
    m13:{temel:1.80,agirlik:3080,taban:510,tepe:315},
    m14:{temel:1.90,agirlik:3500,taban:525,tepe:315},
    m15:{temel:1.90,agirlik:3900,taban:540,tepe:315},
    m16:{temel:2.0, agirlik:4220,taban:555,tepe:315},
    m17:{temel:2.10,agirlik:4660,taban:570,tepe:315},
    m18:{temel:2.10,agirlik:5230,taban:585,tepe:315},
    m19:{temel:2.20,agirlik:5580,taban:600,tepe:315},
    m20:{temel:2.30,agirlik:6560,taban:630,tepe:360},
    m21:{temel:2.30,agirlik:7080,taban:630,tepe:360},
    m22:{temel:2.40,agirlik:7560,taban:630,tepe:360},
    m23:{temel:2.40,agirlik:8100,taban:630,tepe:360},
    m24:{temel:2.50,agirlik:8550,taban:630,tepe:360},
    m25:{temel:2.50,agirlik:9110,taban:630,tepe:360}
}
BETONDİREK[8]={tepekuvvet:2500, 
    m12:{temel:1.80,agirlik:2770,taban:495,tepe:315},
    m13:{temel:1.80,agirlik:3100,taban:510,tepe:315},
    m14:{temel:1.90,agirlik:3530,taban:525,tepe:315},
    m15:{temel:2.0, agirlik:3930,taban:540,tepe:315},
    m16:{temel:2.10,agirlik:4250,taban:555,tepe:315},
    m17:{temel:2.10,agirlik:4700,taban:570,tepe:315},
    m18:{temel:2.20,agirlik:5280,taban:585,tepe:315},
    m19:{temel:2.20,agirlik:5630,taban:600,tepe:315},
    m20:{temel:2.30,agirlik:6620,taban:630,tepe:360},
    m21:{temel:2.40,agirlik:7140,taban:630,tepe:360},
    m22:{temel:2.40,agirlik:7620,taban:630,tepe:360},
    m23:{temel:2.50,agirlik:8160,taban:630,tepe:360},
    m24:{temel:2.50,agirlik:8620,taban:630,tepe:360},
    m25:{temel:2.50,agirlik:9190,taban:630,tepe:360}
}

BETONDİREK[9]={tepekuvvet:2600, 
    m12:{temel:1.80,agirlik:3220,taban:540,tepe:360},
    m13:{temel:1.80,agirlik:3610,taban:555,tepe:360},
    m14:{temel:1.90,agirlik:4050,taban:570,tepe:360},
    m15:{temel:2.0, agirlik:4550,taban:585,tepe:360},
    m16:{temel:2.10,agirlik:4950,taban:600,tepe:360},
    m17:{temel:2.20,agirlik:5410,taban:615,tepe:360},
    m18:{temel:2.20,agirlik:5710,taban:630,tepe:360},
    m19:{temel:2.30,agirlik:6050,taban:630,tepe:360},
    m20:{temel:2.30,agirlik:6680,taban:630,tepe:360},
    m21:{temel:2.40,agirlik:7200,taban:630,tepe:360},
    m22:{temel:2.40,agirlik:7680,taban:630,tepe:360},
    m23:{temel:2.50,agirlik:8220,taban:630,tepe:360},
    m24:{temel:2.50,agirlik:8690,taban:630,tepe:360},
    m25:{temel:2.60,agirlik:9270,taban:630,tepe:360}
}






// KESİCİLER 
// ANAHTARLI OTOMATİK SİGORTALAR
export const TMS=[];
export const AOS=[];
export const NH=[];
export const KAKKOMPAKT=[];
export const KAKR=[];
export const EMO_KOMPAKT=[];
export const KAKMODUL=[];
export const TROID=[];

export  const OGHatIletken=[];
OGHatIletken[0]={AmericanStnd:"3 AWG",Adi:"Swallow",ATK1:120,ATK2:160,ATK3:180,R20_m:1.0742,Xl10kV_m:0.371,Xl35kV_m:0.420,EqCu:13.31}
OGHatIletken[1]={AmericanStnd:"1/0 AWG",Adi:"Raven",ATK1:195,ATK2:230,ATK3:280,R20_m:0.5362,Xl10kV_m:0.347,Xl35kV_m:0.387,EqCu:33.73}
OGHatIletken[2]={AmericanStnd:"3/0 AWG",Adi:"Pigeon",ATK1:275,ATK2:300,ATK3:360,R20_m:0.3366,Xl10kV_m:0.335,Xl35kV_m:0.373,EqCu:53.52}
OGHatIletken[3]={AmericanStnd:"266.8 MCM",Adi:"Patridge",ATK1:345,ATK2:460,ATK3:510,R20_m:0.215,Xl10kV_m:0.318,Xl35kV_m:0.335,EqCu:85.17}
OGHatIletken[4]={AmericanStnd:"477 MCM",Adi:"Hawk",ATK1:540,ATK2:670,ATK3:740,R20_m:0.1194,Xl10kV_m:0.300,Xl35kV_m:0.337,EqCu:152}
OGHatIletken[5]={AmericanStnd:"25mm2 XLPE",Adi:"25mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.387,Xl10kV_m:0.300,Xl35kV_m:0.379,EqCu:0}
OGHatIletken[6]={AmericanStnd:"35mm2 XLPE",Adi:"35mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.387,Xl10kV_m:0.300,Xl35kV_m:0.379,EqCu:0}
OGHatIletken[7]={AmericanStnd:"50mm2 XLPE",Adi:"50mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.387,Xl10kV_m:0.300,Xl35kV_m:0.379,EqCu:0}
OGHatIletken[8]={AmericanStnd:"70mm2 XLPE",Adi:"70mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.268,Xl10kV_m:0.300,Xl35kV_m:0.284,EqCu:0}
OGHatIletken[9]={AmericanStnd:"95mm2 XLPE",Adi:"95mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.193,Xl10kV_m:0.300,Xl35kV_m:0.225,EqCu:0}
OGHatIletken[10]={AmericanStnd:"120mm2 XLPE",Adi:"120mm2 XLPE",ATK1:0,ATK2:0,ATK3:0,R20_m:0.153,Xl10kV_m:0.300,Xl35kV_m:0.192,EqCu:0}

NH[0]=		{Akim:6,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'6A NH-00C'};
NH[1]=		{Akim:10,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'10A NH-00C'};
NH[2]=		{Akim:16,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'16A NH-00C'};
NH[3]=		{Akim:20,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'20A NH-00C'};
NH[4]=		{Akim:25,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'25A NH-00C'};
NH[5]=		{Akim:32,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'32A NH-00C'};
NH[6]=		{Akim:40,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'40A NH-00C'};
NH[7]=		{Akim:50,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'50A NH-00C'};
NH[8]=		{Akim:63,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'63A NH-00C'};
NH[9]=		{Akim:80,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-00C'};
NH[10]=	    {Akim:100,	Boyut:"00C",    Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-00C'};
NH[11]=		{Akim:16,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'16A NH-00'};
NH[12]=		{Akim:20,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'20A NH-00'};
NH[13]=		{Akim:25,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'25A NH-00'};
NH[14]=		{Akim:32,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'32A NH-00'};
NH[15]=		{Akim:40,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'40A NH-00'};
NH[16]=		{Akim:50,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'50A NH-00'};
NH[17]=		{Akim:63,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'63A NH-00'};
NH[18]=		{Akim:80,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-00'};
NH[19]=	    {Akim:100,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-00'};
NH[20]=	    {Akim:160,	Boyut:"00",     Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-00'}; 
NH[21]=		{Akim:80,	Boyut:"1",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-1'};
NH[22]=	    {Akim:100,	Boyut:"1",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-1'};
NH[23]=	    {Akim:160,	Boyut:"1",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-1'};
NH[24]=		{Akim:200,	Boyut:"1",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'200A NH-1'};
NH[25]=	    {Akim:250,	Boyut:"1",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'250A NH-1'};
NH[26]=	    {Akim:160,	Boyut:"2",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-2'};
NH[27]=		{Akim:200,	Boyut:"2",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'200A NH-2'};
NH[28]=	    {Akim:250,	Boyut:"2",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'250A NH-2'};
NH[29]=	    {Akim:315,	Boyut:"2",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'315A NH-2'};
NH[30]=	    {Akim:400,	Boyut:"2",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'400A NH-2'};
NH[31]=		{Akim:315,	Boyut:"3",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'315A NH-3'};
NH[32]=	    {Akim:400,	Boyut:"3",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'400A NH-3'};
NH[33]=	    {Akim:500,	Boyut:"3",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'500A NH-3'};
NH[34]=		{Akim:630,	Boyut:"3",      Kutup:"1P",     KisaDevreAkim:120,Tur:"NH", Etiket:'630A NH-3'};
NH[35]=		{Akim:6,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'6A NH-00C'};
NH[36]=		{Akim:10,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'10A NH-00C'};
NH[37]=		{Akim:16,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'16A NH-00C'};
NH[38]=		{Akim:20,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'20A NH-00C'};
NH[39]=		{Akim:25,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'25A NH-00C'};
NH[40]=		{Akim:32,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'32A NH-00C'};
NH[41]=		{Akim:40,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'40A NH-00C'};
NH[42]=		{Akim:50,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'50A NH-00C'};
NH[43]=		{Akim:63,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'63A NH-00C'};
NH[44]=		{Akim:80,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-00C'};
NH[45]=	    {Akim:100,	Boyut:"00C",    Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-00C'};
NH[46]=		{Akim:16,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'16A NH-00'};
NH[47]=		{Akim:20,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'20A NH-00'};
NH[48]=		{Akim:25,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'25A NH-00'};
NH[49]=		{Akim:32,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'32A NH-00'};
NH[50]=		{Akim:40,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'40A NH-00'};
NH[51]=		{Akim:50,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'50A NH-00'};
NH[52]=		{Akim:63,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'63A NH-00'};
NH[53]=		{Akim:80,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-00'};
NH[54]=	    {Akim:100,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-00'};
NH[55]=	    {Akim:160,	Boyut:"00",     Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-00'}; 
NH[56]=		{Akim:80,	Boyut:"1",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'80A NH-1'};
NH[57]=	    {Akim:100,	Boyut:"1",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'100A NH-1'};
NH[58]=	    {Akim:160,	Boyut:"1",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-1'};
NH[59]=		{Akim:200,	Boyut:"1",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'200A NH-1'};
NH[60]=	    {Akim:250,	Boyut:"1",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'250A NH-1'};
NH[61]=	    {Akim:160,	Boyut:"2",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'160A NH-2'};
NH[62]=		{Akim:200,	Boyut:"2",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'200A NH-2'};
NH[63]=	    {Akim:250,	Boyut:"2",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'250A NH-2'};
NH[64]=	    {Akim:315,	Boyut:"2",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'315A NH-2'};
NH[65]=	    {Akim:400,	Boyut:"2",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'400A NH-2'};
NH[66]=		{Akim:315,	Boyut:"3",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'315A NH-3'};
NH[67]=	    {Akim:400,	Boyut:"3",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'400A NH-3'};
NH[69]=	    {Akim:500,	Boyut:"3",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'500A NH-3'};
NH[70]=		{Akim:630,	Boyut:"3",      Kutup:"3P",     KisaDevreAkim:120,Tur:"NH", Etiket:'630A NH-3'};




AOS[0]=		{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'Sigorta Amper'};
AOS[1]=		{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,1A,3kA'};
AOS[2]=		{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,2A,3kA'};
AOS[3]=		{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,3A,3kA'};
AOS[4]=		{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,4A,3kA'};
AOS[5]=		{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,5A,3kA'};
AOS[6]=		{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,6A,3kA'};
AOS[7]=		{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,10A,3kA'};
AOS[8]=		{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,16A,3kA'};
AOS[9]=		{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,20A,3kA'};
AOS[10]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,25A,3kA'};
AOS[11]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,32A,3kA'};
AOS[12]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,40A,3kA'};
AOS[13]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,50A,3kA'};
AOS[14]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,63A,3kA'};
AOS[15]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,80A,3kA'};
AOS[16]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,100A,3kA'};
AOS[17]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,125A,3kA'};
AOS[18]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,1A,3kA'};
AOS[19]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,2A,3kA'};
AOS[20]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,3A,3kA'};
AOS[21]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,4A,3kA'};
AOS[22]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,5A,3kA'};
AOS[23]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,6A,3kA'};
AOS[24]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,10A,3kA'};
AOS[25]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,16A,3kA'};
AOS[26]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,20A,3kA'};
AOS[27]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,25A,3kA'};
AOS[28]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,32A,3kA'};
AOS[29]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,40A,3kA'};
AOS[30]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,50A,3kA'};
AOS[31]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,63A,3kA'};
AOS[32]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,80A,3kA'};
AOS[33]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,100A,3kA'};
AOS[34]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,125A,3kA'};
AOS[35]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,1A,3kA'};
AOS[36]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,2A,3kA'};
AOS[37]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,3A,3kA'};
AOS[38]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,4A,3kA'};
AOS[39]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,5A,3kA'};
AOS[40]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,6A,3kA'};
AOS[41]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,10A,3kA'};
AOS[42]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,16A,3kA'};
AOS[43]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,20A,3kA'};
AOS[44]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,25A,3kA'};
AOS[45]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,32A,3kA'};
AOS[46]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,40A,3kA'};
AOS[47]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,50A,3kA'};
AOS[48]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,63A,3kA'};
AOS[49]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,80A,3kA'};
AOS[50]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,100A,3kA'};
AOS[51]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,125A,3kA'};
AOS[52]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,1A,3kA'};
AOS[53]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,2A,3kA'};
AOS[54]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,3A,3kA'};
AOS[55]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,4A,3kA'};
AOS[56]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,5A,3kA'};
AOS[57]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,6A,3kA'};
AOS[58]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,10A,3kA'};
AOS[59]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,16A,3kA'};
AOS[60]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,20A,3kA'};
AOS[61]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,25A,3kA'};
AOS[62]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,32A,3kA'};
AOS[63]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,40A,3kA'};
AOS[64]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,50A,3kA'};
AOS[65]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,63A,3kA'};
AOS[66]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,80A,3kA'};
AOS[67]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,100A,3kA'};
AOS[68]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,125A,3kA'};
AOS[69]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,1A,3kA'};
AOS[70]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,2A,3kA'};
AOS[71]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,3A,3kA'};
AOS[72]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,4A,3kA'};
AOS[73]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,5A,3kA'};
AOS[74]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,6A,3kA'};
AOS[75]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,10A,3kA'};
AOS[76]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,16A,3kA'};
AOS[77]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,20A,3kA'};
AOS[78]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,25A,3kA'};
AOS[79]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,32A,3kA'};
AOS[80]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,40A,3kA'};
AOS[81]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,50A,3kA'};
AOS[82]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,63A,3kA'};
AOS[83]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,80A,3kA'};
AOS[84]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,100A,3kA'};
AOS[85]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,125A,3kA'};
AOS[86]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,1A,3kA'};
AOS[87]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,2A,3kA'};
AOS[88]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,3A,3kA'};
AOS[89]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,4A,3kA'};
AOS[90]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,5A,3kA'};
AOS[91]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,6A,3kA'};
AOS[92]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,10A,3kA'};
AOS[93]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,16A,3kA'};
AOS[94]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,20A,3kA'};
AOS[95]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,25A,3kA'};
AOS[96]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,32A,3kA'};
AOS[97]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,40A,3kA'};
AOS[98]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,50A,3kA'};
AOS[99]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,63A,3kA'};
AOS[100]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,80A,3kA'};
AOS[101]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,100A,3kA'};
AOS[102]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,125A,3kA'};
AOS[103]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,1A,3kA'};
AOS[104]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,2A,3kA'};
AOS[105]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,3A,3kA'};
AOS[106]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,4A,3kA'};
AOS[107]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,5A,3kA'};
AOS[108]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,6A,3kA'};
AOS[109]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,10A,3kA'};
AOS[110]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,16A,3kA'};
AOS[111]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,20A,3kA'};
AOS[112]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,25A,3kA'};
AOS[113]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,32A,3kA'};
AOS[114]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,40A,3kA'};
AOS[115]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,50A,3kA'};
AOS[116]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,63A,3kA'};
AOS[117]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,80A,3kA'};
AOS[118]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,100A,3kA'};
AOS[119]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,125A,3kA'};
AOS[120]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,1A,3kA'};
AOS[121]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,2A,3kA'};
AOS[122]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,3A,3kA'};
AOS[123]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,4A,3kA'};
AOS[124]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,5A,3kA'};
AOS[125]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,6A,3kA'};
AOS[126]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,10A,3kA'};
AOS[127]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,16A,3kA'};
AOS[128]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,20A,3kA'};
AOS[129]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,25A,3kA'};
AOS[130]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,32A,3kA'};
AOS[131]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,40A,3kA'};
AOS[132]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,50A,3kA'};
AOS[133]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,63A,3kA'};
AOS[134]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,80A,3kA'};
AOS[135]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,100A,3kA'};
AOS[136]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,125A,3kA'};
AOS[137]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,1A,3kA'};
AOS[138]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,2A,3kA'};
AOS[139]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,3A,3kA'};
AOS[140]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,4A,3kA'};
AOS[141]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,5A,3kA'};
AOS[142]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,6A,3kA'};
AOS[143]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,10A,3kA'};
AOS[144]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,16A,3kA'};
AOS[145]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,20A,3kA'};
AOS[146]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,25A,3kA'};
AOS[147]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,32A,3kA'};
AOS[148]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,40A,3kA'};
AOS[149]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,50A,3kA'};
AOS[150]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,63A,3kA'};
AOS[151]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,80A,3kA'};
AOS[152]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,100A,3kA'};
AOS[153]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,125A,3kA'};
AOS[154]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,1A,3kA'};
AOS[155]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,2A,3kA'};
AOS[156]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,3A,3kA'};
AOS[157]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,4A,3kA'};
AOS[158]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,5A,3kA'};
AOS[159]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,6A,3kA'};
AOS[160]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,10A,3kA'};
AOS[161]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,16A,3kA'};
AOS[162]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,20A,3kA'};
AOS[163]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,25A,3kA'};
AOS[164]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,32A,3kA'};
AOS[165]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,40A,3kA'};
AOS[166]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,50A,3kA'};
AOS[167]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,63A,3kA'};
AOS[168]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,80A,3kA'};
AOS[169]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,100A,3kA'};
AOS[170]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,125A,3kA'};
AOS[171]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,1A,3kA'};
AOS[172]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,2A,3kA'};
AOS[173]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,3A,3kA'};
AOS[174]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,4A,3kA'};
AOS[175]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,5A,3kA'};
AOS[176]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,6A,3kA'};
AOS[177]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,10A,3kA'};
AOS[178]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,16A,3kA'};
AOS[179]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,20A,3kA'};
AOS[180]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,25A,3kA'};
AOS[181]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,32A,3kA'};
AOS[182]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,40A,3kA'};
AOS[183]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,50A,3kA'};
AOS[184]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,63A,3kA'};
AOS[185]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,80A,3kA'};
AOS[186]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,100A,3kA'};
AOS[187]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,125A,3kA'};
AOS[188]=	{Akim:1,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,1A,3kA'};
AOS[189]=	{Akim:2,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,2A,3kA'};
AOS[190]=	{Akim:3,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,3A,3kA'};
AOS[191]=	{Akim:4,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,4A,3kA'};
AOS[192]=	{Akim:5,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,5A,3kA'};
AOS[193]=	{Akim:6,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,6A,3kA'};
AOS[194]=	{Akim:10,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,10A,3kA'};
AOS[195]=	{Akim:16,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,16A,3kA'};
AOS[196]=	{Akim:20,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,20A,3kA'};
AOS[197]=	{Akim:25,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,25A,3kA'};
AOS[198]=	{Akim:32,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,32A,3kA'};
AOS[199]=	{Akim:40,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,40A,3kA'};
AOS[200]=	{Akim:50,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,50A,3kA'};
AOS[201]=	{Akim:63,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,63A,3kA'};
AOS[202]=	{Akim:80,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,80A,3kA'};
AOS[203]=	{Akim:100,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,100A,3kA'};
AOS[204]=	{Akim:125,	KisaDevreAkim:3,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,125A,3kA'};
AOS[205]=	{Akim:1,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,1A,6kA'};
AOS[206]=	{Akim:2,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,2A,6kA'};
AOS[207]=	{Akim:3,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,3A,6kA'};
AOS[208]=	{Akim:4,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,4A,6kA'};
AOS[209]=	{Akim:5,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,5A,6kA'};
AOS[210]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,6A,6kA'};
AOS[211]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,10A,6kA'};
AOS[212]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,16A,6kA'};
AOS[213]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,20A,6kA'};
AOS[214]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,25A,6kA'};
AOS[215]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,32A,6kA'};
AOS[216]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,40A,6kA'};
AOS[217]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,50A,6kA'};
AOS[218]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,63A,6kA'};
AOS[219]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,80A,6kA'};
AOS[220]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,100A,6kA'};
AOS[221]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,125A,6kA'};
AOS[222]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,6A,6kA'};
AOS[223]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,10A,6kA'};
AOS[224]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,16A,6kA'};
AOS[225]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,20A,6kA'};
AOS[226]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,25A,6kA'};
AOS[227]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,32A,6kA'};
AOS[228]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,40A,6kA'};
AOS[229]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,50A,6kA'};
AOS[230]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,63A,6kA'};
AOS[231]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,80A,6kA'};
AOS[232]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,100A,6kA'};
AOS[233]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,125A,6kA'};
AOS[234]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,6A,6kA'};
AOS[235]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,10A,6kA'};
AOS[236]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,16A,6kA'};
AOS[237]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,20A,6kA'};
AOS[238]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,25A,6kA'};
AOS[239]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,32A,6kA'};
AOS[240]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,40A,6kA'};
AOS[241]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,50A,6kA'};
AOS[242]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,63A,6kA'};
AOS[243]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,80A,6kA'};
AOS[244]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,100A,6kA'};
AOS[245]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,125A,6kA'};
AOS[246]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,6A,6kA'};
AOS[247]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,10A,6kA'};
AOS[248]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,16A,6kA'};
AOS[249]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,20A,6kA'};
AOS[250]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,25A,6kA'};
AOS[251]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,32A,6kA'};
AOS[252]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,40A,6kA'};
AOS[253]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,50A,6kA'};
AOS[254]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,63A,6kA'};
AOS[255]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,80A,6kA'};
AOS[256]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,100A,6kA'};
AOS[257]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,125A,6kA'};
AOS[258]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,6A,6kA'};
AOS[259]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,10A,6kA'};
AOS[260]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,16A,6kA'};
AOS[261]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,20A,6kA'};
AOS[262]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,25A,6kA'};
AOS[263]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,32A,6kA'};
AOS[264]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,40A,6kA'};
AOS[265]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,50A,6kA'};
AOS[266]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,63A,6kA'};
AOS[267]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,80A,6kA'};
AOS[268]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,100A,6kA'};
AOS[269]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,125A,6kA'};
AOS[270]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,6A,6kA'};
AOS[271]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,10A,6kA'};
AOS[272]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,16A,6kA'};
AOS[273]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,20A,6kA'};
AOS[274]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,25A,6kA'};
AOS[275]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,32A,6kA'};
AOS[276]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,40A,6kA'};
AOS[277]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,50A,6kA'};
AOS[278]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,63A,6kA'};
AOS[279]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,80A,6kA'};
AOS[280]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,100A,6kA'};
AOS[281]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,125A,6kA'};
AOS[282]=	{Akim:1,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,1A,6kA'};
AOS[283]=	{Akim:2,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,2A,6kA'};
AOS[284]=	{Akim:3,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,3A,6kA'};
AOS[285]=	{Akim:4,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,4A,6kA'};
AOS[286]=	{Akim:5,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,5A,6kA'};
AOS[287]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,6A,6kA'};
AOS[288]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,10A,6kA'};
AOS[289]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,16A,6kA'};
AOS[290]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,20A,6kA'};
AOS[291]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,25A,6kA'};
AOS[292]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,32A,6kA'};
AOS[293]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,40A,6kA'};
AOS[294]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,50A,6kA'};
AOS[295]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,63A,6kA'};
AOS[296]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,80A,6kA'};
AOS[297]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,100A,6kA'};
AOS[298]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P',		Etiket:'TipC,1P,125A,6kA'};
AOS[299]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,6A,6kA'};
AOS[300]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,10A,6kA'};
AOS[301]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,16A,6kA'};
AOS[302]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,20A,6kA'};
AOS[303]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,25A,6kA'};
AOS[304]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,32A,6kA'};
AOS[305]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,40A,6kA'};
AOS[306]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,50A,6kA'};
AOS[307]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,63A,6kA'};
AOS[308]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,80A,6kA'};
AOS[309]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,100A,6kA'};
AOS[310]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'1P+N',	Etiket:'TipC,1P+N,125A,6kA'};
AOS[311]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,6A,6kA'};
AOS[312]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,10A,6kA'};
AOS[313]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,16A,6kA'};
AOS[314]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,20A,6kA'};
AOS[315]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,25A,6kA'};
AOS[316]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,32A,6kA'};
AOS[317]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,40A,6kA'};
AOS[318]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,50A,6kA'};
AOS[319]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,63A,6kA'};
AOS[320]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,80A,6kA'};
AOS[321]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,100A,6kA'};
AOS[322]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'2P',		Etiket:'TipC,2P,125A,6kA'};
AOS[323]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,6A,6kA'};
AOS[324]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,10A,6kA'};
AOS[325]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,16A,6kA'};
AOS[326]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,20A,6kA'};
AOS[327]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,25A,6kA'};
AOS[328]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,32A,6kA'};
AOS[329]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,40A,6kA'};
AOS[330]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,50A,6kA'};
AOS[331]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,63A,6kA'};
AOS[332]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,80A,6kA'};
AOS[333]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,100A,6kA'};
AOS[334]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P',		Etiket:'TipC,3P,125A,6kA'};
AOS[335]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,6A,6kA'};
AOS[336]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,10A,6kA'};
AOS[337]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,16A,6kA'};
AOS[338]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,20A,6kA'};
AOS[339]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,25A,6kA'};
AOS[340]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,32A,6kA'};
AOS[341]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,40A,6kA'};
AOS[342]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,50A,6kA'};
AOS[343]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,63A,6kA'};
AOS[344]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,80A,6kA'};
AOS[345]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,100A,6kA'};
AOS[346]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'3P+N',	Etiket:'TipC,3P+N,125A,6kA'};
AOS[347]=	{Akim:6,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,6A,6kA'};
AOS[348]=	{Akim:10,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,10A,6kA'};
AOS[349]=	{Akim:16,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,16A,6kA'};
AOS[350]=	{Akim:20,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,20A,6kA'};
AOS[351]=	{Akim:25,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,25A,6kA'};
AOS[352]=	{Akim:32,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,32A,6kA'};
AOS[353]=	{Akim:40,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,40A,6kA'};
AOS[354]=	{Akim:50,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,50A,6kA'};
AOS[355]=	{Akim:63,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,63A,6kA'};
AOS[356]=	{Akim:80,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,80A,6kA'};
AOS[357]=	{Akim:100,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,100A,6kA'};
AOS[358]=	{Akim:125,	KisaDevreAkim:6,Tur:"AOS",	Tip:'C',Kutup:'4P',		Etiket:'TipC,4P,125A,6kA'};
AOS[359]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,6A,10kA'};
AOS[360]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,10A,10kA'};
AOS[361]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,16A,10kA'};
AOS[362]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,20A,10kA'};
AOS[363]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,25A,10kA'};
AOS[364]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,32A,10kA'};
AOS[365]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,40A,10kA'};
AOS[366]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,50A,10kA'};
AOS[367]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,63A,10kA'};
AOS[368]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,80A,10kA'};
AOS[369]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,100A,10kA'};
AOS[370]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P',		Etiket:'TipB,1P,125A,10kA'};
AOS[371]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,6A,10kA'};
AOS[372]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,10A,10kA'};
AOS[373]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,16A,10kA'};
AOS[374]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,20A,10kA'};
AOS[375]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,25A,10kA'};
AOS[376]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,32A,10kA'};
AOS[377]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,40A,10kA'};
AOS[378]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,50A,10kA'};
AOS[379]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,63A,10kA'};
AOS[380]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,80A,10kA'};
AOS[381]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,100A,10kA'};
AOS[382]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'1P+N',	Etiket:'TipB,1P+N,125A,10kA'};
AOS[383]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,6A,10kA'};
AOS[384]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,10A,10kA'};
AOS[385]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,16A,10kA'};
AOS[386]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,20A,10kA'};
AOS[387]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,25A,10kA'};
AOS[388]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,32A,10kA'};
AOS[389]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,40A,10kA'};
AOS[390]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,50A,10kA'};
AOS[391]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,63A,10kA'};
AOS[392]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,80A,10kA'};
AOS[393]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,100A,10kA'};
AOS[394]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'2P',		Etiket:'TipB,2P,125A,10kA'};
AOS[395]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,6A,10kA'};
AOS[396]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,10A,10kA'};
AOS[397]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,16A,10kA'};
AOS[398]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS",	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,20A,10kA'};
AOS[399]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS",Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,25A,10kA'};
AOS[400]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,32A,10kA'};
AOS[401]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,40A,10kA'};
AOS[402]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,50A,10kA'};
AOS[403]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,63A,10kA'};
AOS[404]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,80A,10kA'};
AOS[405]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,100A,10kA'};
AOS[406]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipB,3P,125A,10kA'};
AOS[407]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,6A,10kA'};
AOS[408]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,10A,10kA'};
AOS[409]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,16A,10kA'};
AOS[410]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,20A,10kA'};
AOS[411]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,25A,10kA'};
AOS[412]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,32A,10kA'};
AOS[413]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,40A,10kA'};
AOS[414]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,50A,10kA'};
AOS[415]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,63A,10kA'};
AOS[416]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,80A,10kA'};
AOS[417]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,100A,10kA'};
AOS[418]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipB,3P+N,125A,10kA'};
AOS[419]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,6A,10kA'};
AOS[420]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,10A,10kA'};
AOS[421]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,16A,10kA'};
AOS[422]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,20A,10kA'};
AOS[423]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,25A,10kA'};
AOS[424]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,32A,10kA'};
AOS[425]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,40A,10kA'};
AOS[426]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,50A,10kA'};
AOS[427]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,63A,10kA'};
AOS[428]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,80A,10kA'};
AOS[429]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,100A,10kA'};
AOS[430]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipB,4P,125A,10kA'};
AOS[431]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,6A,10kA'};
AOS[432]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,10A,10kA'};
AOS[433]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,16A,10kA'};
AOS[434]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,20A,10kA'};
AOS[435]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,25A,10kA'};
AOS[436]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,32A,10kA'};
AOS[437]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,40A,10kA'};
AOS[438]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,50A,10kA'};
AOS[439]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,63A,10kA'};
AOS[440]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,80A,10kA'};
AOS[441]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,100A,10kA'};
AOS[442]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P',		Etiket:'TipC,1P,125A,10kA'};
AOS[443]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,6A,10kA'};
AOS[444]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,10A,10kA'};
AOS[445]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,16A,10kA'};
AOS[446]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,20A,10kA'};
AOS[447]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,25A,10kA'};
AOS[448]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,32A,10kA'};
AOS[449]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,40A,10kA'};
AOS[450]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,50A,10kA'};
AOS[451]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,63A,10kA'};
AOS[452]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,80A,10kA'};
AOS[453]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,100A,10kA'};
AOS[454]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'1P+N',	Etiket:'TipC,1P+N,125A,10kA'};
AOS[455]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,6A,10kA'};
AOS[456]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,10A,10kA'};
AOS[457]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,16A,10kA'};
AOS[458]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,20A,10kA'};
AOS[459]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,25A,10kA'};
AOS[460]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,32A,10kA'};
AOS[461]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,40A,10kA'};
AOS[462]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,50A,10kA'};
AOS[463]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,63A,10kA'};
AOS[464]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,80A,10kA'};
AOS[465]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,100A,10kA'};
AOS[466]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'2P',		Etiket:'TipC,2P,125A,10kA'};
AOS[467]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,6A,10kA'};
AOS[468]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,10A,10kA'};
AOS[469]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,16A,10kA'};
AOS[470]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,20A,10kA'};
AOS[471]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,25A,10kA'};
AOS[472]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,32A,10kA'};
AOS[473]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,40A,10kA'};
AOS[474]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,50A,10kA'};
AOS[475]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,63A,10kA'};
AOS[476]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,80A,10kA'};
AOS[477]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,100A,10kA'};
AOS[478]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P',		Etiket:'TipC,3P,125A,10kA'};
AOS[479]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,6A,10kA'};
AOS[480]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,10A,10kA'};
AOS[481]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,16A,10kA'};
AOS[482]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,20A,10kA'};
AOS[483]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,25A,10kA'};
AOS[484]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,32A,10kA'};
AOS[485]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,40A,10kA'};
AOS[486]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,50A,10kA'};
AOS[487]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,63A,10kA'};
AOS[488]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,80A,10kA'};
AOS[489]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,100A,10kA'};
AOS[490]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'3P+N',	Etiket:'TipC,3P+N,125A,10kA'};
AOS[491]=	{Akim:6,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,6A,10kA'};
AOS[492]=	{Akim:10,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,10A,10kA'};
AOS[493]=	{Akim:16,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,16A,10kA'};
AOS[494]=	{Akim:20,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,20A,10kA'};
AOS[495]=	{Akim:25,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,25A,10kA'};
AOS[496]=	{Akim:32,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,32A,10kA'};
AOS[497]=	{Akim:40,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,40A,10kA'};
AOS[498]=	{Akim:50,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,50A,10kA'};
AOS[499]=	{Akim:63,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,63A,10kA'};
AOS[500]=	{Akim:80,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,80A,10kA'};
AOS[501]=	{Akim:100,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,100A,10kA'};
AOS[502]=	{Akim:125,	KisaDevreAkim:10,Tur:"AOS", 	Tip:'B',Kutup:'4P',		Etiket:'TipC,4P,125A,10kA'};




EMO_KOMPAKT[0]={Akim:16,		AyarSaha:"12-16",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'16A 1P'};
EMO_KOMPAKT[1]={Akim:25,		AyarSaha:"18-25",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'25A 1P'};
EMO_KOMPAKT[2]={Akim:40,		AyarSaha:"30-40",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'40A 1P'};
EMO_KOMPAKT[3]={Akim:63,		AyarSaha:"45-63",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'63A 1P'};
EMO_KOMPAKT[4]={Akim:80,		AyarSaha:"60-80",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'80A 1P'};
EMO_KOMPAKT[5]={Akim:100,		AyarSaha:"75-100",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'100A 1P'};
EMO_KOMPAKT[6]={Akim:125,		AyarSaha:"90-125",		Kutup:'1P',Tur:'KOMPAKT',		Etiket:'125A 1P'};
EMO_KOMPAKT[7]={Akim:16,		AyarSaha:"12-16",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'16A 3P'};
EMO_KOMPAKT[8]={Akim:25,		AyarSaha:"18-25",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'25A 3P'};
EMO_KOMPAKT[9]={Akim:40,		AyarSaha:"30-40",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'40A 3P'};
EMO_KOMPAKT[10]={Akim:63,		AyarSaha:"45-63",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'63A 3P'};
EMO_KOMPAKT[11]={Akim:80,		AyarSaha:"60-80",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'80A 3P'};
EMO_KOMPAKT[12]={Akim:100,		AyarSaha:"75-100",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'100A 3P'};
EMO_KOMPAKT[13]={Akim:125,		AyarSaha:"90-125",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'125A 3P'};
EMO_KOMPAKT[14]={Akim:160,		AyarSaha:"120-160",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'160A 3P'};
EMO_KOMPAKT[15]={Akim:200,		AyarSaha:"150-200",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'200A 3P'};
EMO_KOMPAKT[16]={Akim:250,		AyarSaha:"180-250",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'250A 3P'};
EMO_KOMPAKT[17]={Akim:320,		AyarSaha:"250-320",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'320A 3P'};
EMO_KOMPAKT[18]={Akim:400,		AyarSaha:"320-400",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'400A 3P'};
EMO_KOMPAKT[19]={Akim:500,		AyarSaha:"400-500",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'500A 3P'};
EMO_KOMPAKT[20]={Akim:630,		AyarSaha:"500-630",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'630A 3P'};
EMO_KOMPAKT[21]={Akim:800,		AyarSaha:"630-800",		Kutup:'3P',Tur:'KOMPAKT',		Etiket:'800A 3P'};
EMO_KOMPAKT[22]={Akim:1000,		AyarSaha:"800-1000",	Kutup:'3P',Tur:'KOMPAKT',		Etiket:'1000A 3P'};
EMO_KOMPAKT[23]={Akim:1250,		AyarSaha:"1000-1250",	Kutup:'3P',Tur:'KOMPAKT',		Etiket:'1250A 3P'};
EMO_KOMPAKT[24]={Akim:16,		AyarSaha:"12-16",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'16A 4P'};
EMO_KOMPAKT[25]={Akim:25,		AyarSaha:"18-25",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'25A 4P'};
EMO_KOMPAKT[26]={Akim:40,		AyarSaha:"30-40",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'40A 4P'};
EMO_KOMPAKT[27]={Akim:63,		AyarSaha:"45-63",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'63A 4P'};
EMO_KOMPAKT[28]={Akim:80,		AyarSaha:"60-80",		Kutup:'4P',Tur:'KOMPAKT',	    Etiket:'80A 4P'};
EMO_KOMPAKT[29]={Akim:100,		AyarSaha:"75-100",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'100A 4P'};
EMO_KOMPAKT[30]={Akim:125,		AyarSaha:"90-125",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'125A 4P'};
EMO_KOMPAKT[31]={Akim:160,		AyarSaha:"120-160",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'160A 4P'};
EMO_KOMPAKT[32]={Akim:200,		AyarSaha:"150-200",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'200A 4P'};
EMO_KOMPAKT[33]={Akim:250,		AyarSaha:"180-250",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'250A 4P'};
EMO_KOMPAKT[34]={Akim:320,		AyarSaha:"250-320",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'320A 4P'};
EMO_KOMPAKT[39]={Akim:1000,		AyarSaha:"800-1000",	Kutup:'4P',Tur:'KOMPAKT',		Etiket:'1000A 4P'};
EMO_KOMPAKT[35]={Akim:400,		AyarSaha:"320-400",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'400A 4P'};
EMO_KOMPAKT[36]={Akim:500,		AyarSaha:"400-500",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'500A 4P'};
EMO_KOMPAKT[37]={Akim:630,		AyarSaha:"500-630",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'630A 4P'};
EMO_KOMPAKT[38]={Akim:800,		AyarSaha:"630-800",		Kutup:'4P',Tur:'KOMPAKT',		Etiket:'800A 4P'};
EMO_KOMPAKT[40]={Akim:1250,		AyarSaha:"1000-1250",	Kutup:'4P',Tur:'KOMPAKT',		Etiket:'1250A 4P'};




TMS[0]={Akim:16,		KisaDevreAkim:16,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'16kA 16A 3P'};    // LEGRAND   
TMS[1]={Akim:16,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 16A 3P'};    // LEGRAND 
TMS[2]={Akim:16,		KisaDevreAkim:36,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'36kA 16A 3P'};    // LEGRAND   
TMS[3]={Akim:16,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 16A 3P'};    // LEGRAND   
TMS[4]={Akim:16,		KisaDevreAkim:16,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'16kA 16A 4P'};    // LEGRAND   
TMS[5]={Akim:16,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 16A 4P'};    // LEGRAND 
TMS[6]={Akim:16,		KisaDevreAkim:36,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'36kA 16A 4P'};    // LEGRAND   
TMS[7]={Akim:16,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 16A 4P'};    // LEGRAND 

TMS[8]={Akim:25,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 25A 3P'};    // MAKEL   
TMS[9]={Akim:25,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 25A 3P'};    // MAKEL 
TMS[10]={Akim:25,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 25A 3P'};    // MAKEL   
TMS[11]={Akim:25,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 25A 3P'};    // MAKEL   
TMS[12]={Akim:25,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 25A 4P'};    // MAKEL   
TMS[13]={Akim:25,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 25A 4P'};    // MAKEL 
TMS[14]={Akim:25,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 25A 4P'};    // MAKEL   
TMS[15]={Akim:25,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 25A 4P'};    // MAKEL   

TMS[16]={Akim:32,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 32A 3P'};    // MAKEL   
TMS[17]={Akim:32,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 32A 3P'};    // MAKEL   
TMS[18]={Akim:32,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 32A 3P'};    // MAKEL   
TMS[19]={Akim:32,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 32A 3P'};    // MAKEL   
TMS[20]={Akim:32,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 32A 4P'};    // MAKEL   
TMS[21]={Akim:32,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 32A 4P'};    // MAKEL   
TMS[22]={Akim:32,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 32A 4P'};    // MAKEL   
TMS[23]={Akim:32,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 32A 4P'};    // MAKEL   

TMS[24]={Akim:40,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 40A 3P'};    // MAKEL  
TMS[25]={Akim:40,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 40A 3P'};    // MAKEL   
TMS[26]={Akim:40,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 40A 3P'};    // MAKEL   
TMS[27]={Akim:40,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 40A 3P'};    // MAKEL   
TMS[28]={Akim:40,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 40A 4P'};    // MAKEL  
TMS[29]={Akim:40,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 40A 4P'};    // MAKEL   
TMS[30]={Akim:40,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 40A 4P'};    // MAKEL   
TMS[31]={Akim:40,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 40A 4P'};    // MAKEL 

TMS[32]={Akim:50,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 50A 3P'};    // MAKEL   
TMS[33]={Akim:50,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 50A 3P'};    // MAKEL   
TMS[34]={Akim:50,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 50A 3P'};    // MAKEL   
TMS[35]={Akim:50,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 50A 3P'};    // MAKEL   
TMS[36]={Akim:50,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 50A 4P'};    // MAKEL   
TMS[37]={Akim:50,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 50A 4P'};    // MAKEL   
TMS[38]={Akim:50,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 50A 4P'};    // MAKEL   
TMS[39]={Akim:50,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 50A 4P'};    // MAKEL 

TMS[40]={Akim:63,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 63A 3P'};    // MAKEL   
TMS[41]={Akim:63,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 63A 3P'};    // MAKEL   
TMS[42]={Akim:63,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 63A 3P'};    // MAKEL   
TMS[43]={Akim:63,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 63A 3P'};    // MAKEL   
TMS[44]={Akim:63,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 63A 4P'};    // MAKEL   
TMS[45]={Akim:63,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 63A 4P'};    // MAKEL   
TMS[46]={Akim:63,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 63A 4P'};    // MAKEL   
TMS[47]={Akim:63,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 63A 4P'};    // MAKEL  

TMS[48]={Akim:80,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 80A 3P'};    // MAKEL   
TMS[49]={Akim:80,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 80A 3P'};    // MAKEL   
TMS[50]={Akim:80,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 80A 3P'};    // MAKEL   
TMS[51]={Akim:80,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 80A 3P'};    // MAKEL   
TMS[52]={Akim:80,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 80A 4P'};    // MAKEL   
TMS[53]={Akim:80,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 80A 4P'};    // MAKEL   
TMS[54]={Akim:80,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 80A 4P'};    // MAKEL   
TMS[55]={Akim:80,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 80A 4P'};    // MAKEL   

TMS[56]={Akim:100,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 100A 3P'};  // MAKEL  
TMS[57]={Akim:100,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 100A 3P'};  // MAKEL   
TMS[58]={Akim:100,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 100A 3P'};  // MAKEL   
TMS[59]={Akim:100,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 100A 3P'};  // MAKEL   
TMS[60]={Akim:100,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 100A 3P'};  // LEGRAND   
TMS[61]={Akim:100,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 100A 4P'};  // MAKEL  
TMS[62]={Akim:100,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 100A 4P'};  // MAKEL   
TMS[63]={Akim:100,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 100A 4P'};  // MAKEL   
TMS[64]={Akim:100,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 100A 4P'};  // MAKEL   
TMS[65]={Akim:100,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 100A 4P'};  // LEGRAND  

TMS[66]={Akim:125,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 125A 3P'};  // MAKEL 
TMS[67]={Akim:125,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 125A 3P'};  // MAKEL   
TMS[68]={Akim:125,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 125A 3P'};  // MAKEL   
TMS[69]={Akim:125,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 125A 3P'};  // MAKEL   
TMS[70]={Akim:125,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 125A 4P'};  // MAKEL 
TMS[71]={Akim:125,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 125A 4P'};  // MAKEL   
TMS[72]={Akim:125,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 125A 4P'};  // MAKEL   
TMS[73]={Akim:125,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 125A 4P'};  // MAKEL 

TMS[74]={Akim:160,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 160A 3P'};  // MAKEL 
TMS[75]={Akim:160,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 160A 3P'};  // MAKEL   
TMS[76]={Akim:160,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 160A 3P'};  // MAKEL   
TMS[77]={Akim:160,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 160A 3P'};  // MAKEL   
TMS[78]={Akim:160,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 160A 3P'};  // LEGRAND   
TMS[79]={Akim:160,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 160A 4P'};  // MAKEL 
TMS[80]={Akim:160,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 160A 4P'};  // MAKEL   
TMS[81]={Akim:160,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 160A 4P'};  // MAKEL   
TMS[82]={Akim:160,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 160A 4P'};  // MAKEL   
TMS[83]={Akim:160,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 160A 4P'};  // LEGRAND 

TMS[84]={Akim:200,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 200A 3P'};  // MAKEL  
TMS[85]={Akim:200,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 200A 3P'};  // MAKEL   
TMS[86]={Akim:200,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 200A 3P'};  // MAKEL   
TMS[87]={Akim:200,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 200A 3P'};  // MAKEL   
TMS[88]={Akim:200,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 200A 3P'};  // LEGRAND   
TMS[89]={Akim:200,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 200A 4P'};  // MAKEL  
TMS[90]={Akim:200,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 200A 4P'};  // MAKEL   
TMS[91]={Akim:200,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 200A 4P'};  // MAKEL   
TMS[92]={Akim:200,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 200A 4P'};  // MAKEL   
TMS[93]={Akim:200,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 200A 4P'};  // LEGRAND 

TMS[94]={Akim:225,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 225A 3P'};  // MAKEL 
TMS[95]={Akim:225,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 225A 3P'};  // MAKEL   
TMS[96]={Akim:225,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 225A 3P'};  // MAKEL   
TMS[97]={Akim:225,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 225A 3P'};  // MAKEL   
TMS[98]={Akim:225,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 225A 4P'};  // MAKEL 
TMS[99]={Akim:225,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 225A 4P'};  // MAKEL   
TMS[100]={Akim:225,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 225A 4P'};  // MAKEL   
TMS[101]={Akim:225,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 225A 4P'};  // MAKEL

TMS[102]={Akim:250,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 250A 3P'}; // MAKEL  
TMS[103]={Akim:250,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 250A 3P'}; // MAKEL    
TMS[104]={Akim:250,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 250A 3P'}; // MAKEL    
TMS[105]={Akim:250,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 250A 3P'}; // MAKEL    
TMS[106]={Akim:250,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 250A 3P'}; // LEGRAND    
TMS[107]={Akim:250,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 250A 4P'}; // MAKEL  
TMS[108]={Akim:250,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 250A 4P'}; // MAKEL    
TMS[109]={Akim:250,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 250A 4P'}; // MAKEL    
TMS[110]={Akim:250,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 250A 4P'}; // MAKEL    
TMS[111]={Akim:250,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 250A 4P'}; // LEGRAND 

TMS[112]={Akim:315,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 315A 3P'}; // MAKEL 
TMS[113]={Akim:315,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 315A 3P'}; // MAKEL    
TMS[114]={Akim:315,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 315A 3P'}; // MAKEL    
TMS[115]={Akim:315,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 315A 3P'}; // MAKEL    
TMS[116]={Akim:315,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 315A 4P'}; // MAKEL 
TMS[117]={Akim:315,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 315A 4P'}; // MAKEL    
TMS[118]={Akim:315,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 315A 4P'}; // MAKEL    
TMS[119]={Akim:315,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 315A 4P'}; // MAKEL 

TMS[120]={Akim:320,		KisaDevreAkim:36,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'36kA 320A 3P'}; // LEGRAND 
TMS[121]={Akim:320,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 320A 3P'}; // LEGRAND    
TMS[122]={Akim:320,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 320A 3P'}; // LEGRAND 
TMS[123]={Akim:320,		KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 320A 3P'}; // LEGRAND    
TMS[124]={Akim:320,		KisaDevreAkim:36,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'36kA 320A 4P'}; // LEGRAND    
TMS[125]={Akim:320,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 320A 4P'}; // LEGRAND    
TMS[126]={Akim:320,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 320A 4P'}; // LEGRAND    
TMS[127]={Akim:320,		KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 320A 4P'}; // LEGRAND

TMS[128]={Akim:400,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 400A 3P'}; // MAKEL 
TMS[129]={Akim:400,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 400A 3P'}; // MAKEL 
TMS[130]={Akim:400,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 400A 3P'}; // MAKEL 
TMS[131]={Akim:400,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 400A 3P'}; // MAKEL 
TMS[132]={Akim:400,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 400A 3P'}; // LEGRAND 
TMS[133]={Akim:400,		KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 400A 3P'}; // LEGRAND 
TMS[134]={Akim:400,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 400A 4P'}; // MAKEL 
TMS[135]={Akim:400,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 400A 4P'}; // MAKEL 
TMS[136]={Akim:400,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 400A 4P'}; // MAKEL 
TMS[137]={Akim:400,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 400A 4P'}; // MAKEL 
TMS[138]={Akim:400,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 400A 4P'}; // LEGRAND 
TMS[139]={Akim:400,		KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 400A 4P'}; // LEGRAND 

TMS[140]={Akim:500,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 500A 3P'}; // MAKEL 
TMS[141]={Akim:500,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 500A 3P'}; // MAKEL 
TMS[142]={Akim:500,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 500A 3P'}; // MAKEL 
TMS[143]={Akim:500,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 500A 3P'}; // MAKEL 
TMS[144]={Akim:500,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 500A 3P'}; // LEGRAND 
TMS[145]={Akim:500,		KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 500A 3P'}; // LEGRAND 
TMS[146]={Akim:500,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 500A 4P'}; // MAKEL 
TMS[147]={Akim:500,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 500A 4P'}; // MAKEL 
TMS[148]={Akim:500,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 500A 4P'}; // MAKEL 
TMS[149]={Akim:500,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 500A 4P'}; // MAKEL 
TMS[150]={Akim:500,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 500A 4P'}; // LEGRAND 
TMS[151]={Akim:500,		KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 500A 4P'}; // LEGRAND 

TMS[152]={Akim:630,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 630A 3P'}; // MAKEL 
TMS[153]={Akim:630,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 630A 3P'}; // MAKEL 
TMS[154]={Akim:630,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 630A 3P'}; // MAKEL 
TMS[155]={Akim:630,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 630A 3P'}; // MAKEL 
TMS[156]={Akim:630,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 630A 3P'}; // LEGRAND 
TMS[157]={Akim:630,		KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 630A 3P'}; // LEGRAND 
TMS[158]={Akim:630,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 630A 4P'}; // MAKEL 
TMS[159]={Akim:630,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 630A 4P'}; // MAKEL 
TMS[160]={Akim:630,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 630A 4P'}; // MAKEL 
TMS[161]={Akim:630,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 630A 4P'}; // MAKEL 
TMS[162]={Akim:630,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 630A 4P'}; // LEGRAND 
TMS[163]={Akim:630,		KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 630A 4P'}; // LEGRAND 

TMS[164]={Akim:800,		KisaDevreAkim:25,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'25kA 800A 3P'}; // MAKEL 
TMS[165]={Akim:800,		KisaDevreAkim:35,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'35kA 800A 3P'}; // MAKEL 
TMS[166]={Akim:800,		KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 800A 3P'}; // MAKEL 
TMS[167]={Akim:800,		KisaDevreAkim:65,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'65kA 800A 3P'}; // MAKEL 
TMS[168]={Akim:800,		KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 800A 3P'}; // LEGRAND 
TMS[169]={Akim:800,		KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 800A 3P'}; // LEGRAND 
TMS[170]={Akim:800,		KisaDevreAkim:25,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'25kA 800A 4P'}; // MAKEL 
TMS[171]={Akim:800,		KisaDevreAkim:35,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'35kA 800A 4P'}; // MAKEL 
TMS[172]={Akim:800,		KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 800A 4P'}; // MAKEL 
TMS[173]={Akim:800,		KisaDevreAkim:65,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'65kA 800A 4P'}; // MAKEL 
TMS[174]={Akim:800,		KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 800A 4P'}; // LEGRAND 
TMS[175]={Akim:800,		KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 800A 4P'}; // LEGRAND 

TMS[176]={Akim:1000,	KisaDevreAkim:36,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'36kA 1000A 3P'}; // LEGRAND 
TMS[177]={Akim:1000,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 1000A 3P'}; // LEGRAND 
TMS[178]={Akim:1000,	KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 1000A 3P'}; // LEGRAND 
TMS[179]={Akim:1000,	KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 1000A 3P'}; // LEGRAND 
TMS[180]={Akim:1000,	KisaDevreAkim:36,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'36kA 1000A 4P'}; // LEGRAND 
TMS[181]={Akim:1000,	KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 1000A 4P'}; // LEGRAND 
TMS[182]={Akim:1000,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 1000A 4P'}; // LEGRAND 
TMS[183]={Akim:1000,	KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 1000A 4P'}; // LEGRAND 

TMS[184]={Akim:1250,	KisaDevreAkim:36,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'36kA 1250A 3P'}; // LEGRAND 
TMS[185]={Akim:1250,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 1250A 3P'}; // LEGRAND 
TMS[186]={Akim:1250,	KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 1250A 3P'}; // LEGRAND 
TMS[187]={Akim:1250,	KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 1250A 3P'}; // LEGRAND 
TMS[188]={Akim:1250,	KisaDevreAkim:36,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'36kA 1250A 4P'}; // LEGRAND 
TMS[189]={Akim:1250,	KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 1250A 4P'}; // LEGRAND 
TMS[190]={Akim:1250,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 1250A 4P'}; // LEGRAND 
TMS[191]={Akim:1250,	KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 1250A 4P'}; // LEGRAND 

TMS[192]={Akim:1600,	KisaDevreAkim:36,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'36kA 1600A 3P'}; // LEGRAND 
TMS[193]={Akim:1600,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 1600A 3P'}; // LEGRAND 
TMS[194]={Akim:1600,	KisaDevreAkim:70,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'70kA 1600A 3P'}; // LEGRAND 
TMS[195]={Akim:1600,	KisaDevreAkim:100,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'100kA 1600A 3P'}; // LEGRAND 
TMS[196]={Akim:1600,	KisaDevreAkim:36,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'36kA 1600A 4P'}; // LEGRAND 
TMS[197]={Akim:1600,	KisaDevreAkim:50,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'50kA 1600A 4P'}; // LEGRAND 
TMS[198]={Akim:1600,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 1600A 4P'}; // LEGRAND 
TMS[199]={Akim:1600,	KisaDevreAkim:100,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'100kA 1600A 4P'}; // LEGRAND 

TMS[200]={Akim:2000,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 2000A 3P'}; // ABB 
TMS[201]={Akim:2000,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 2000A 3P'}; // ABB 
TMS[202]={Akim:2000,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 2000A 4P'}; // ABB 
TMS[203]={Akim:2000,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 2000A 4P'}; // ABB 

TMS[204]={Akim:2500,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 2500A 3P'}; // ABB 
TMS[205]={Akim:2500,	KisaDevreAkim:50,	Tip:'C',Kutup:'3P',Tur:'TMŞ',Etiket:'50kA 2500A 3P'}; // ABB 
TMS[206]={Akim:2500,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 2500A 4P'}; // ABB 
TMS[207]={Akim:2500,	KisaDevreAkim:70,	Tip:'C',Kutup:'4P',Tur:'TMŞ',Etiket:'70kA 2500A 4P'}; // ABB 










KAKKOMPAKT[0]=		{Akim:40,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"40A 30mA 4P"};		//MAKEL
KAKKOMPAKT[1]=		{Akim:40,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"40A 100mA 4P"};		//MAKEL
KAKKOMPAKT[2]=		{Akim:40,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"40A 300mA 4P"};		//MAKEL
KAKKOMPAKT[3]=		{Akim:50,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"50A 30mA 4P"};		//MAKEL
KAKKOMPAKT[4]=		{Akim:50,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"50A 100mA 4P"};		//MAKEL
KAKKOMPAKT[5]=		{Akim:50,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"50A 300mA 4P"};		//MAKEL
KAKKOMPAKT[6]=		{Akim:63,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"63A 30mA 4P"};		//MAKEL
KAKKOMPAKT[7]=		{Akim:63,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"63A 100mA 4P"};		//MAKEL
KAKKOMPAKT[8]=		{Akim:63,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"63A 300mA 4P"};		//MAKEL
KAKKOMPAKT[9]=		{Akim:80,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"80A 30mA 4P"};		//MAKEL
KAKKOMPAKT[10]=		{Akim:80,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"80A 100mA 4P"};		//MAKEL
KAKKOMPAKT[11]=		{Akim:80,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"80A 300mA 4P"};		//MAKEL
KAKKOMPAKT[12]=		{Akim:100,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"100A 30mA 4P"};		//MAKEL
KAKKOMPAKT[13]=		{Akim:100,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"100A 100mA 4P"};	//MAKEL
KAKKOMPAKT[14]=		{Akim:100,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"100A 300mA 4P"};	//MAKEL
KAKKOMPAKT[15]=		{Akim:125,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"125A 30mA 4P"};		//MAKEL
KAKKOMPAKT[16]=		{Akim:125,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"125A 100mA 4P"};		//MAKEL
KAKKOMPAKT[17]=		{Akim:125,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"125A 300mA 4P"};		//MAKEL
KAKKOMPAKT[18]=		{Akim:160,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"160A 30mA 4P"};		//MAKEL
KAKKOMPAKT[19]=		{Akim:160,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"160A 100mA 4P"};		//MAKEL
KAKKOMPAKT[20]=		{Akim:160,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"160A 300mA 4P"};		//MAKEL
KAKKOMPAKT[21]=		{Akim:200,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"200A 30mA 4P"};		//MAKEL
KAKKOMPAKT[22]=		{Akim:200,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"200A 100mA 4P"};		//MAKEL
KAKKOMPAKT[23]=		{Akim:200,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"200A 300mA 4P"};		//MAKEL
KAKKOMPAKT[24]=		{Akim:225,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"225A 30mA 4P"};		//MAKEL
KAKKOMPAKT[25]=		{Akim:225,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"225A 100mA 4P"};	//MAKEL
KAKKOMPAKT[26]=		{Akim:225,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"225A 300mA 4P"};	//MAKEL
KAKKOMPAKT[27]=		{Akim:250,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"250A 30mA 4P"};		//MAKEL
KAKKOMPAKT[28]=		{Akim:250,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"250A 100mA 4P"};	//MAKEL
KAKKOMPAKT[29]=		{Akim:250,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"250A 300mA 4P"};	//MAKEL
KAKKOMPAKT[30]=		{Akim:320,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"315A 30mA 4P"};		//MAKEL
KAKKOMPAKT[31]=		{Akim:320,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"315A 100mA 4P"};	//MAKEL
KAKKOMPAKT[32]=		{Akim:320,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"315A 300mA 4P"};	//MAKEL
KAKKOMPAKT[33]=		{Akim:400,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"400A 30mA 4P"};		//MAKEL
KAKKOMPAKT[34]=		{Akim:400,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"400A 100mA 4P"};	//MAKEL
KAKKOMPAKT[35]=		{Akim:400,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"400A 300mA 4P"};	//MAKEL
KAKKOMPAKT[36]=		{Akim:500,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"500A 30mA 4P"};		//MAKEL
KAKKOMPAKT[37]=		{Akim:500,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"500A 100mA 4P"};	//MAKEL
KAKKOMPAKT[38]=		{Akim:500,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"500A 300mA 4P"};	//MAKEL
KAKKOMPAKT[39]=		{Akim:630,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"630A 30mA 4P"};		//MAKEL
KAKKOMPAKT[40]=		{Akim:630,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"630A 100mA 4P"};	//MAKEL
KAKKOMPAKT[41]=		{Akim:630,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"630A 300mA 4P"};	//MAKEL
KAKKOMPAKT[42]=		{Akim:800,	KisaDevreAkim:35,	Duyarlilik:30,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"800A 30mA 4P"};		//MAKEL
KAKKOMPAKT[43]=		{Akim:800,	KisaDevreAkim:35,	Duyarlilik:100,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"800A 100mA 4P"};	//MAKEL
KAKKOMPAKT[44]=		{Akim:800,	KisaDevreAkim:35,	Duyarlilik:300,		Kutup:"4P",		EsikZaman:"0.1-0.3-0.5",	Etiket:"800A 300mA 4P"};	//MAKEL
 

///  Kaçak Akım Koruma Röleleri 

KAKR[0]= {Akim:16, Duyarlilik:10, 		Tip:'A',	Kutup:'2P',  Etiket:'10mA 16A 2P'};//LEGRAND
KAKR[1]= {Akim:16, Duyarlilik:10, 		Tip:'AC',	Kutup:'2P',  Etiket:'10mA 16A 2P'};//LEGRAND

KAKR[2]= {Akim:25, Duyarlilik:30, 		Tip:'A',	Kutup:'2P',  Etiket:'30mA 25A 2P'};//MAKEL
KAKR[3]= {Akim:25, Duyarlilik:30, 		Tip:'A',	Kutup:'4P',  Etiket:'30mA 25A 4P'};//MAKEL
KAKR[4]= {Akim:25, Duyarlilik:30, 		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 25A 2P'};//MAKEL
KAKR[5]= {Akim:25, Duyarlilik:30, 		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 25A 4P'};//MAKEL
KAKR[6]= {Akim:25, Duyarlilik:30, 		Tip:'F',	Kutup:'2P',  Etiket:'30mA 25A 2P'};//LEGRAND
KAKR[7]= {Akim:25, Duyarlilik:30, 		Tip:'F',	Kutup:'4P',  Etiket:'30mA 25A 4P'};//LEGRAND
KAKR[8]= {Akim:25, Duyarlilik:300,		Tip:'A',	Kutup:'2P',  Etiket:'300mA 25A 2P'};//MAKEL
KAKR[9]= {Akim:25, Duyarlilik:300,		Tip:'A',	Kutup:'4P',  Etiket:'300mA 25A 4P'};//MAKEL
KAKR[10]= {Akim:25, Duyarlilik:300,		Tip:'AC',	Kutup:'2P',  Etiket:'300mA 25A 2P'};//MAKEL
KAKR[11]= {Akim:25, Duyarlilik:300,		Tip:'AC',	Kutup:'4P',  Etiket:'300mA 25A 4P'};//MAKEL

KAKR[12]= {Akim:32, Duyarlilik:30, 		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 32A 2P'};//MAKEL
KAKR[13]= {Akim:32, Duyarlilik:30, 		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 32A 4P'};//MAKEL
KAKR[14]= {Akim:32, Duyarlilik:300, 	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 32A 2P'};//MAKEL
KAKR[15]= {Akim:32, Duyarlilik:300, 	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 32A 4P'};//MAKEL

KAKR[16]= {Akim:40, Duyarlilik:30, 		Tip:'A',	Kutup:'2P',  Etiket:'30mA 40A 2P'};//MAKEL
KAKR[17]= {Akim:40, Duyarlilik:30, 		Tip:'A',	Kutup:'4P',  Etiket:'30mA 40A 4P'};//MAKEL
KAKR[18]= {Akim:40, Duyarlilik:30, 		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 40A 2P'};//MAKEL
KAKR[19]= {Akim:40, Duyarlilik:30, 		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 40A 4P'};//MAKEL
KAKR[20]= {Akim:40, Duyarlilik:30, 		Tip:'F',	Kutup:'2P',  Etiket:'30mA 40A 2P'};//LEGRAND
KAKR[21]= {Akim:40, Duyarlilik:30, 		Tip:'F',	Kutup:'4P',  Etiket:'30mA 40A 4P'};//LEGRAND
KAKR[22]= {Akim:40, Duyarlilik:300, 	Tip:'A',	Kutup:'2P',  Etiket:'300mA 40A 2P'};//MAKEL
KAKR[23]= {Akim:40, Duyarlilik:300, 	Tip:'A',	Kutup:'4P',  Etiket:'300mA 40A 4P'};//MAKEL
KAKR[24]= {Akim:40, Duyarlilik:300, 	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 40A 2P'};//MAKEL
KAKR[25]= {Akim:40, Duyarlilik:300, 	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 40A 4P'};//MAKEL
KAKR[26]= {Akim:40, Duyarlilik:300, 	Tip:'F',	Kutup:'2P',  Etiket:'300mA 40A 2P'};//MAKEL
KAKR[27]= {Akim:40, Duyarlilik:300, 	Tip:'F',	Kutup:'4P',  Etiket:'300mA 40A 4P'};//MAKEL

KAKR[28]= {Akim:50, Duyarlilik:30, 		Tip:'A',	Kutup:'2P',  Etiket:'30mA 50A 2P'};//MAKEL
KAKR[29]= {Akim:50, Duyarlilik:30, 		Tip:'A',	Kutup:'4P',  Etiket:'30mA 50A 4P'};//MAKEL
KAKR[30]= {Akim:50, Duyarlilik:30, 		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 50A 2P'};//MAKEL
KAKR[31]= {Akim:50, Duyarlilik:30, 		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 50A 4P'};//MAKEL
KAKR[32]= {Akim:50, Duyarlilik:300, 	Tip:'A',	Kutup:'2P',  Etiket:'300mA 50A 2P'};//MAKEL
KAKR[33]= {Akim:50, Duyarlilik:300, 	Tip:'A',	Kutup:'4P',  Etiket:'300mA 50A 4P'};//MAKEL
KAKR[34]= {Akim:50, Duyarlilik:300, 	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 50A 2P'};//MAKEL
KAKR[35]= {Akim:50, Duyarlilik:300, 	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 50A 4P'};//MAKEL

KAKR[36]= {Akim:63, Duyarlilik:30,  	Tip:'A',	Kutup:'2P',  Etiket:'30mA 63A 2P'};//MAKEL
KAKR[37]= {Akim:63, Duyarlilik:30,  	Tip:'A',	Kutup:'4P',  Etiket:'30mA 63A 4P'};//MAKEL
KAKR[38]= {Akim:63, Duyarlilik:30,  	Tip:'AC',	Kutup:'2P',  Etiket:'30mA 63A 2P'};//MAKEL
KAKR[39]= {Akim:63, Duyarlilik:30,  	Tip:'AC',	Kutup:'4P',  Etiket:'30mA 63A 4P'};//MAKEL
KAKR[40]= {Akim:63, Duyarlilik:30,  	Tip:'F',	Kutup:'2P',  Etiket:'30mA 63A 2P'};//LEGRAND
KAKR[41]= {Akim:63, Duyarlilik:30,  	Tip:'F',	Kutup:'4P',  Etiket:'30mA 63A 4P'};//LEGRAND
KAKR[42]= {Akim:63, Duyarlilik:300, 	Tip:'A',	Kutup:'2P',  Etiket:'300mA 63A 2P'};//MAKEL
KAKR[43]= {Akim:63, Duyarlilik:300, 	Tip:'A',	Kutup:'4P',  Etiket:'300mA 63A 4P'};//MAKEL
KAKR[44]= {Akim:63, Duyarlilik:300, 	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 63A 2P'};//MAKEL
KAKR[45]= {Akim:63, Duyarlilik:300, 	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 63A 4P'};//MAKEL
KAKR[46]= {Akim:63, Duyarlilik:300, 	Tip:'F',	Kutup:'2P',  Etiket:'300mA 63A 2P'};//LEGRAND
KAKR[47]= {Akim:63, Duyarlilik:300, 	Tip:'F',	Kutup:'4P',  Etiket:'300mA 63A 4P'};//LEGRAND

KAKR[48]= {Akim:80, Duyarlilik:30, 		Tip:'A',	Kutup:'2P',  Etiket:'30mA 80A 2P'};//MAKEL
KAKR[49]= {Akim:80, Duyarlilik:30,  	Tip:'A',	Kutup:'4P',  Etiket:'30mA 80A 4P'};//MAKEL
KAKR[50]= {Akim:80, Duyarlilik:30, 		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 80A 2P'};//MAKEL
KAKR[51]= {Akim:80, Duyarlilik:30,  	Tip:'AC',	Kutup:'4P',  Etiket:'30mA 80A 4P'};//MAKEL
KAKR[52]= {Akim:80, Duyarlilik:30, 		Tip:'F',	Kutup:'2P',  Etiket:'30mA 80A 2P'};//LEGRAND
KAKR[53]= {Akim:80, Duyarlilik:30,  	Tip:'F',	Kutup:'4P',  Etiket:'30mA 80A 4P'};//LEGRAND
KAKR[54]= {Akim:80, Duyarlilik:300, 	Tip:'A',	Kutup:'2P',  Etiket:'300mA 80A 2P'};//MAKEL
KAKR[55]= {Akim:80, Duyarlilik:300, 	Tip:'A',	Kutup:'4P',  Etiket:'300mA 80A 4P'};//MAKEL
KAKR[56]= {Akim:80, Duyarlilik:300, 	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 80A 2P'};//MAKEL
KAKR[57]= {Akim:80, Duyarlilik:300, 	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 80A 4P'};//MAKEL
KAKR[58]= {Akim:80, Duyarlilik:300, 	Tip:'F',	Kutup:'2P',  Etiket:'300mA 80A 2P'};//LEGRAND
KAKR[59]= {Akim:80, Duyarlilik:300, 	Tip:'F',	Kutup:'4P',  Etiket:'300mA 80A 4P'};//LEGRAND

KAKR[60]= {Akim:100, Duyarlilik:30,		Tip:'A',	Kutup:'2P',  Etiket:'30mA 100A 2P'};//MAKEL
KAKR[61]= {Akim:100, Duyarlilik:30,		Tip:'A',	Kutup:'4P',  Etiket:'30mA 100A 4P'};//MAKEL
KAKR[62]= {Akim:100, Duyarlilik:30,		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 100A 2P'};//MAKEL
KAKR[63]= {Akim:100, Duyarlilik:30,		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 100A 4P'};//MAKEL
KAKR[64]= {Akim:100, Duyarlilik:300,	Tip:'A',	Kutup:'2P',  Etiket:'300mA 100A 2P'};//MAKEL
KAKR[65]= {Akim:100, Duyarlilik:300,	Tip:'A',	Kutup:'4P',  Etiket:'300mA 100A 4P'};//MAKEL
KAKR[66]= {Akim:100, Duyarlilik:300,	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 100A 2P'};//MAKEL
KAKR[67]= {Akim:100, Duyarlilik:300,	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 100A 4P'};//MAKEL

KAKR[68]= {Akim:125, Duyarlilik:30,		Tip:'A',	Kutup:'2P',  Etiket:'30mA 125A 2P'};//MAKEL
KAKR[69]= {Akim:125, Duyarlilik:30,		Tip:'A',	Kutup:'4P',  Etiket:'30mA 125A 4P'};//MAKEL
KAKR[70]= {Akim:125, Duyarlilik:30,		Tip:'AC',	Kutup:'2P',  Etiket:'30mA 125A 2P'};//MAKEL
KAKR[71]= {Akim:125, Duyarlilik:30,		Tip:'AC',	Kutup:'4P',  Etiket:'30mA 125A 4P'};//MAKEL
KAKR[72]= {Akim:125, Duyarlilik:300,	Tip:'A',	Kutup:'2P',  Etiket:'300mA 125A 2P'};//MAKEL
KAKR[73]= {Akim:125, Duyarlilik:300,	Tip:'A',	Kutup:'4P',  Etiket:'300mA 125A 4P'};//MAKEL
KAKR[74]= {Akim:125, Duyarlilik:300,	Tip:'AC',	Kutup:'2P',  Etiket:'300mA 125A 2P'};//MAKEL
KAKR[75]= {Akim:125, Duyarlilik:300,	Tip:'AC',	Kutup:'4P',  Etiket:'300mA 125A 4P'};//MAKEL

//  KAÇAK AKIM KORUMA MODULÜ 
KAKMODUL[0]={Hassasiyet:30,		ZamanAyari:"0-0,3-1-5"};			//LEGRAND
KAKMODUL[1]={Hassasiyet:300,	ZamanAyari:"0-0,3-1-5"};			//LEGRAND
KAKMODUL[2]={Hassasiyet:1000,	ZamanAyari:"0-0,3-1-5"};			//LEGRAND
KAKMODUL[3]={Hassasiyet:30000,	ZamanAyari:"0-0,3-1-5"};			//LEGRAND

// TROİD AKIM TRAFOSU
TROID[0]={Kesit:35,		Imax:150,	Hassasiyet:[0.03,0.3,1,30]};	//LEGRAND
TROID[1]={Kesit:80,		Imax:400,	Hassasiyet:[0.03,0.3,1,30]};	//LEGRAND
TROID[2]={Kesit:110,	Imax:630,	Hassasiyet:[0.3,1,30]};			//LEGRAND
TROID[3]={Kesit:140,	Imax:1200,	Hassasiyet:[0.3,1,30]};			//LEGRAND
TROID[4]={Kesit:210,	Imax:1800,	Hassasiyet:[0.3,1,30]};			//LEGRAND
TROID[5]={Kesit:150,	Imax:1200,	Hassasiyet:[1,30]};				//LEGRAND
TROID[6]={Kesit:300,	Imax:2000,	Hassasiyet:[1,30]};				//LEGRAND













export const Iletken=[];
//Iletken[0]=0
Iletken[0]={Kesit:0.75, 	NotrKesit:0.76, KesitSayi:1, DCDirenc:24.5, ToprakAK:0, BoruAK:0, HavaAK:15, DisCap:2.2, Agirlik:11, Malzeme:'Cu', Tur:'NYA', Etiket:'Misal  NYA'};
Iletken[1]={Kesit:0.75, 	NotrKesit:0.76, KesitSayi:1, DCDirenc:24.5, ToprakAK:0, BoruAK:0, HavaAK:15, DisCap:2.2, Agirlik:11, Malzeme:'Cu', Tur:'NYA', Etiket:'1x0.75mm² NYA'};
Iletken[2]={Kesit:1, 		NotrKesit:2, KesitSayi:1, DCDirenc:18.1, ToprakAK:0, BoruAK:11, HavaAK:19, DisCap:2.3, Agirlik:13, Malzeme:'Cu', Tur:'NYA', Etiket:'1x1mm² NYA'};
Iletken[3]={Kesit:1.5, 		NotrKesit:1.6, KesitSayi:1, DCDirenc:12.1, ToprakAK:0, BoruAK:14, HavaAK:24, DisCap:2.8, Agirlik:18, Malzeme:'Cu', Tur:'NYA', Etiket:'1x1.5mm² NYA'};
Iletken[4]={Kesit:2.5, 		NotrKesit:2.6, KesitSayi:1, DCDirenc:7.41, ToprakAK:0, BoruAK:19, HavaAK:32, DisCap:3.4, Agirlik:29, Malzeme:'Cu', Tur:'NYA', Etiket:'1x2.5mm² NYA'};
Iletken[5]={Kesit:4, 		NotrKesit:4, KesitSayi:1, DCDirenc:4.61, ToprakAK:0, BoruAK:26, HavaAK:42, DisCap:3.8, Agirlik:45, Malzeme:'Cu', Tur:'NYA', Etiket:'1x4mm² NYA'};
Iletken[6]={Kesit:6, 		NotrKesit:6, KesitSayi:1, DCDirenc:3.08, ToprakAK:0, BoruAK:34, HavaAK:54, DisCap:4.3, Agirlik:60, Malzeme:'Cu', Tur:'NYA', Etiket:'1x6mm² NYA'};
Iletken[7]={Kesit:10, 		NotrKesit:10, KesitSayi:1, DCDirenc:1.83, ToprakAK:0, BoruAK:46, HavaAK:73, DisCap:5.9, Agirlik:105, Malzeme:'Cu', Tur:'NYA', Etiket:'1x10mm² NYA'};
Iletken[8]={Kesit:16, 		NotrKesit:16, KesitSayi:1, DCDirenc:1.15, ToprakAK:0, BoruAK:61, HavaAK:95, DisCap:7, Agirlik:160, Malzeme:'Cu', Tur:'NYA', Etiket:'1x16mm² NYA'};
Iletken[9]={Kesit:25, 		NotrKesit:25, KesitSayi:1, DCDirenc:0.727, ToprakAK:0, BoruAK:80, HavaAK:129, DisCap:8.5, Agirlik:250, Malzeme:'Cu', Tur:'NYA', Etiket:'1x25mm² NYA'};
Iletken[10]={Kesit:35, 		NotrKesit:35, KesitSayi:1, DCDirenc:0.524, ToprakAK:0, BoruAK:99, HavaAK:158, DisCap:9.5, Agirlik:350, Malzeme:'Cu', Tur:'NYA', Etiket:'1x35mm² NYA'};
Iletken[11]={Kesit:50, 		NotrKesit:50, KesitSayi:1, DCDirenc:0.387, ToprakAK:0, BoruAK:119, HavaAK:198, DisCap:11, Agirlik:450, Malzeme:'Cu', Tur:'NYA', Etiket:'1x50mm² NYA'};
Iletken[12]={Kesit:70, 		NotrKesit:70, KesitSayi:1, DCDirenc:0.268, ToprakAK:0, BoruAK:151, HavaAK:245, DisCap:13, Agirlik:650, Malzeme:'Cu', Tur:'NYA', Etiket:'1x70mm² NYA'};
Iletken[13]={Kesit:95, 		NotrKesit:95, KesitSayi:1, DCDirenc:0.193, ToprakAK:0, BoruAK:182, HavaAK:292, DisCap:15, Agirlik:900, Malzeme:'Cu', Tur:'NYA', Etiket:'1x95mm² NYA'};
Iletken[14]={Kesit:120, 	NotrKesit:120, KesitSayi:1, DCDirenc:0.153, ToprakAK:0, BoruAK:210, HavaAK:344, DisCap:16, Agirlik:1140, Malzeme:'Cu', Tur:'NYA', Etiket:'1x120mm² NYA'};
Iletken[15]={Kesit:150, 	NotrKesit:150, KesitSayi:1, DCDirenc:0.124, ToprakAK:0, BoruAK:0, HavaAK:391, DisCap:18, Agirlik:1380, Malzeme:'Cu', Tur:'NYA', Etiket:'1x150mm² NYA'};
Iletken[16]={Kesit:185, 	NotrKesit:185, KesitSayi:1, DCDirenc:0.0991, ToprakAK:0, BoruAK:0, HavaAK:448, DisCap:20, Agirlik:1760, Malzeme:'Cu', Tur:'NYA', Etiket:'1x185mm² NYA'};
Iletken[17]={Kesit:240, 	NotrKesit:240, KesitSayi:1, DCDirenc:0.0754, ToprakAK:0, BoruAK:0, HavaAK:528, DisCap:23, Agirlik:2250, Malzeme:'Cu', Tur:'NYA', Etiket:'1x240mm² NYA'};
Iletken[18]={Kesit:300, 	NotrKesit:300, KesitSayi:1, DCDirenc:0.0601, ToprakAK:0, BoruAK:0, HavaAK:608, DisCap:26, Agirlik:2930, Malzeme:'Cu', Tur:'NYA', Etiket:'1x300mm² NYA'};
Iletken[19]={Kesit:400, 	NotrKesit:400, KesitSayi:1, DCDirenc:0.0470, ToprakAK:0, BoruAK:0, HavaAK:726, DisCap:29, Agirlik:3750, Malzeme:'Cu', Tur:'NYA', Etiket:'1x400mm² NYA'};
Iletken[20]={Kesit:0.75, 	NotrKesit:0.76, KesitSayi:1, DCDirenc:26, ToprakAK:0, BoruAK:0, HavaAK:15, DisCap:2.4, Agirlik:11, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x0.75mm² NYAF'};
Iletken[21]={Kesit:1, 		NotrKesit:2, KesitSayi:1, DCDirenc:19.5, ToprakAK:0, BoruAK:11, HavaAK:19, DisCap:2.6, Agirlik:14, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x1mm² NYAF'};
Iletken[22]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:1, DCDirenc:13.3, ToprakAK:0, BoruAK:14, HavaAK:24, DisCap:2.8, Agirlik:18, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x1.5mm² NYAF'};
Iletken[23]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:1, DCDirenc:7.98, ToprakAK:0, BoruAK:19, HavaAK:32, DisCap:3.5, Agirlik:29, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x2.5mm² NYAF'};
Iletken[24]={Kesit:4, 		NotrKesit:4, KesitSayi:1, DCDirenc:4.95, ToprakAK:0, BoruAK:26, HavaAK:42, DisCap:4.5, Agirlik:45, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x4mm² NYAF'};
Iletken[25]={Kesit:6, 		NotrKesit:6, KesitSayi:1, DCDirenc:3.3, ToprakAK:0, BoruAK:34, HavaAK:54, DisCap:5, Agirlik:60, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x6mm² NYAF'};
Iletken[26]={Kesit:10, 		NotrKesit:10, KesitSayi:1, DCDirenc:1.91, ToprakAK:0, BoruAK:46, HavaAK:73, DisCap:6.5, Agirlik:105, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x10mm² NYAF'};
Iletken[27]={Kesit:16, 		NotrKesit:16, KesitSayi:1, DCDirenc:1.21, ToprakAK:0, BoruAK:61, HavaAK:95, DisCap:8, Agirlik:160, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x16mm² NYAF'};
Iletken[28]={Kesit:25, 		NotrKesit:25, KesitSayi:1, DCDirenc:0.780, ToprakAK:0, BoruAK:80, HavaAK:129, DisCap:9.5, Agirlik:250, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x25mm² NYAF'};
Iletken[29]={Kesit:35, 		NotrKesit:35, KesitSayi:1, DCDirenc:0.554, ToprakAK:0, BoruAK:99, HavaAK:158, DisCap:11, Agirlik:350, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x35mm² NYAF'};
Iletken[30]={Kesit:50, 		NotrKesit:50, KesitSayi:1, DCDirenc:0.386, ToprakAK:0, BoruAK:119, HavaAK:198, DisCap:13, Agirlik:450, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x50mm² NYAF'};
Iletken[31]={Kesit:70, 		NotrKesit:70, KesitSayi:1, DCDirenc:0.272, ToprakAK:0, BoruAK:151, HavaAK:245, DisCap:15.5, Agirlik:650, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x70mm² NYAF'};
Iletken[32]={Kesit:95, 		NotrKesit:95, KesitSayi:1, DCDirenc:0.206, ToprakAK:0, BoruAK:182, HavaAK:292, DisCap:18, Agirlik:900, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x95mm² NYAF'};
Iletken[33]={Kesit:120, 	NotrKesit:120, KesitSayi:1, DCDirenc:0.161, ToprakAK:0, BoruAK:210, HavaAK:344, DisCap:20, Agirlik:1140, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x120mm² NYAF'};
Iletken[34]={Kesit:150, 	NotrKesit:150, KesitSayi:1, DCDirenc:0.129, ToprakAK:0, BoruAK:0, HavaAK:391, DisCap:22, Agirlik:1380, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x150mm² NYAF'};
Iletken[35]={Kesit:185, 	NotrKesit:185, KesitSayi:1, DCDirenc:0.106, ToprakAK:0, BoruAK:0, HavaAK:448, DisCap:25, Agirlik:1760, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x185mm² NYAF'};
Iletken[36]={Kesit:240, 	NotrKesit:240, KesitSayi:1, DCDirenc:0.0801, ToprakAK:0, BoruAK:0, HavaAK:528, DisCap:28, Agirlik:2250, Malzeme:'Cu', Tur:'NYAF', Etiket:'1x240mm² NYAF'};
Iletken[37]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:2, DCDirenc:12.1, ToprakAK:0, BoruAK:0, HavaAK:22, DisCap:8.8, Agirlik:105, Malzeme:'Cu', Tur:'NYM', Etiket:'2x1.5mm² NYM'};
Iletken[38]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:2, DCDirenc:7.41, ToprakAK:0, BoruAK:0, HavaAK:30, DisCap:10, Agirlik:145, Malzeme:'Cu', Tur:'NYM', Etiket:'2x2.5mm² NYM'};
Iletken[39]={Kesit:4, 		NotrKesit:4, KesitSayi:2, DCDirenc:4.61, ToprakAK:0, BoruAK:0, HavaAK:40, DisCap:11, Agirlik:195, Malzeme:'Cu', Tur:'NYM', Etiket:'2x4mm² NYM'};
Iletken[40]={Kesit:6, 		NotrKesit:6, KesitSayi:2, DCDirenc:3.08, ToprakAK:0, BoruAK:0, HavaAK:50, DisCap:12, Agirlik:250, Malzeme:'Cu', Tur:'NYM', Etiket:'2x6mm² NYM'};
Iletken[41]={Kesit:10, 		NotrKesit:10, KesitSayi:2, DCDirenc:1.83, ToprakAK:0, BoruAK:0, HavaAK:70, DisCap:16, Agirlik:420, Malzeme:'Cu', Tur:'NYM', Etiket:'2x10mm² NYM'};
Iletken[42]={Kesit:16, 		NotrKesit:16, KesitSayi:2, DCDirenc:1.15, ToprakAK:0, BoruAK:0, HavaAK:93, DisCap:18, Agirlik:590, Malzeme:'Cu', Tur:'NYM', Etiket:'2x16mm² NYM'};
Iletken[43]={Kesit:25, 		NotrKesit:25, KesitSayi:2, DCDirenc:0.727, ToprakAK:0, BoruAK:0, HavaAK:108, DisCap:21, Agirlik:860, Malzeme:'Cu', Tur:'NYM', Etiket:'2x25mm² NYM'};
Iletken[44]={Kesit:35, 		NotrKesit:35, KesitSayi:2, DCDirenc:0.524, ToprakAK:0, BoruAK:0, HavaAK:135, DisCap:23, Agirlik:1150, Malzeme:'Cu', Tur:'NYM', Etiket:'2x35mm² NYM'};
Iletken[45]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:3, DCDirenc:12.1, ToprakAK:0, BoruAK:0, HavaAK:22, DisCap:9, Agirlik:125, Malzeme:'Cu', Tur:'NYM', Etiket:'3x1.5mm² NYM'};
Iletken[46]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:3, DCDirenc:7.41, ToprakAK:0, BoruAK:0, HavaAK:30, DisCap:10.5, Agirlik:175, Malzeme:'Cu', Tur:'NYM', Etiket:'3x2.5mm² NYM'};
Iletken[47]={Kesit:4, 		NotrKesit:4, KesitSayi:3, DCDirenc:4.61, ToprakAK:0, BoruAK:0, HavaAK:40, DisCap:11.5, Agirlik:240, Malzeme:'Cu', Tur:'NYM', Etiket:'3x4mm² NYM'};
Iletken[48]={Kesit:6, 		NotrKesit:6, KesitSayi:3, DCDirenc:3.08, ToprakAK:0, BoruAK:0, HavaAK:50, DisCap:13, Agirlik:325, Malzeme:'Cu', Tur:'NYM', Etiket:'3x6mm² NYM'};
Iletken[49]={Kesit:10, 		NotrKesit:10, KesitSayi:3, DCDirenc:1.83, ToprakAK:0, BoruAK:0, HavaAK:70, DisCap:16.5, Agirlik:520, Malzeme:'Cu', Tur:'NYM', Etiket:'3x10mm² NYM'};
Iletken[50]={Kesit:16, 		NotrKesit:16, KesitSayi:3, DCDirenc:1.15, ToprakAK:0, BoruAK:0, HavaAK:93, DisCap:19, Agirlik:740, Malzeme:'Cu', Tur:'NYM', Etiket:'3x16mm² NYM'};
Iletken[51]={Kesit:25, 		NotrKesit:25, KesitSayi:3, DCDirenc:0.727, ToprakAK:0, BoruAK:0, HavaAK:108, DisCap:22, Agirlik:1100, Malzeme:'Cu', Tur:'NYM', Etiket:'3x25mm² NYM'};
Iletken[52]={Kesit:35, 		NotrKesit:35, KesitSayi:3, DCDirenc:0.524, ToprakAK:0, BoruAK:0, HavaAK:135, DisCap:25, Agirlik:1460, Malzeme:'Cu', Tur:'NYM', Etiket:'3x35mm² NYM'};
Iletken[53]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:4, DCDirenc:12.1, ToprakAK:0, BoruAK:0, HavaAK:18, DisCap:10, Agirlik:145, Malzeme:'Cu', Tur:'NYM', Etiket:'4x1.5mm² NYM'};
Iletken[54]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:4, DCDirenc:7.41, ToprakAK:0, BoruAK:0, HavaAK:24, DisCap:11.5, Agirlik:215, Malzeme:'Cu', Tur:'NYM', Etiket:'4x2.5mm² NYM'};
Iletken[55]={Kesit:4, 		NotrKesit:4, KesitSayi:4, DCDirenc:4.61, ToprakAK:0, BoruAK:0, HavaAK:32, DisCap:13, Agirlik:300, Malzeme:'Cu', Tur:'NYM', Etiket:'4x4mm² NYM'};
Iletken[56]={Kesit:6, 		NotrKesit:6, KesitSayi:4, DCDirenc:3.08, ToprakAK:0, BoruAK:0, HavaAK:42, DisCap:14, Agirlik:410, Malzeme:'Cu', Tur:'NYM', Etiket:'4x6mm² NYM'};
Iletken[57]={Kesit:10, 		NotrKesit:10, KesitSayi:4, DCDirenc:1.83, ToprakAK:0, BoruAK:0, HavaAK:60, DisCap:18.5, Agirlik:645, Malzeme:'Cu', Tur:'NYM', Etiket:'4x10mm² NYM'};
Iletken[58]={Kesit:16, 		NotrKesit:16, KesitSayi:4, DCDirenc:1.15, ToprakAK:0, BoruAK:0, HavaAK:80, DisCap:21, Agirlik:930, Malzeme:'Cu', Tur:'NYM', Etiket:'4x16mm² NYM'};
Iletken[59]={Kesit:25, 		NotrKesit:25, KesitSayi:4, DCDirenc:0.727, ToprakAK:0, BoruAK:0, HavaAK:100, DisCap:24.5, Agirlik:1400, Malzeme:'Cu', Tur:'NYM', Etiket:'4x25mm² NYM'};
Iletken[60]={Kesit:35, 		NotrKesit:35, KesitSayi:4, DCDirenc:0.524, ToprakAK:0, BoruAK:0, HavaAK:124, DisCap:27.5, Agirlik:1900, Malzeme:'Cu', Tur:'NYM', Etiket:'4x35mm² NYM'};
Iletken[61]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:5, DCDirenc:12.1, ToprakAK:0, BoruAK:0, HavaAK:18, DisCap:11, Agirlik:180, Malzeme:'Cu', Tur:'NYM', Etiket:'5x1.5mm² NYM'};
Iletken[62]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:5, DCDirenc:7.41, ToprakAK:0, BoruAK:0, HavaAK:24, DisCap:12, Agirlik:250, Malzeme:'Cu', Tur:'NYM', Etiket:'5x2.5mm² NYM'};
Iletken[63]={Kesit:4, 		NotrKesit:4, KesitSayi:5, DCDirenc:4.61, ToprakAK:0, BoruAK:0, HavaAK:32, DisCap:14, Agirlik:360, Malzeme:'Cu', Tur:'NYM', Etiket:'5x4mm² NYM'};
Iletken[64]={Kesit:6, 		NotrKesit:6, KesitSayi:5, DCDirenc:3.08, ToprakAK:0, BoruAK:0, HavaAK:42, DisCap:16, Agirlik:480, Malzeme:'Cu', Tur:'NYM', Etiket:'5x6mm² NYM'};
Iletken[65]={Kesit:10, 		NotrKesit:10, KesitSayi:5, DCDirenc:1.83, ToprakAK:0, BoruAK:0, HavaAK:60, DisCap:20, Agirlik:800, Malzeme:'Cu', Tur:'NYM', Etiket:'5x10mm² NYM'};
Iletken[66]={Kesit:16, 		NotrKesit:16, KesitSayi:5, DCDirenc:1.15, ToprakAK:0, BoruAK:0, HavaAK:80, DisCap:23, Agirlik:1150, Malzeme:'Cu', Tur:'NYM', Etiket:'5x16mm² NYM'};
Iletken[67]={Kesit:25, 		NotrKesit:25, KesitSayi:5, DCDirenc:0.727, ToprakAK:0, BoruAK:0, HavaAK:100, DisCap:27.5, Agirlik:1700, Malzeme:'Cu', Tur:'NYM', Etiket:'5x25mm² NYM'};
Iletken[68]={Kesit:35, 		NotrKesit:35, KesitSayi:5, DCDirenc:0.524, ToprakAK:0, BoruAK:0, HavaAK:124, DisCap:31, Agirlik:2300, Malzeme:'Cu', Tur:'NYM', Etiket:'5x35mm² NYM'};
Iletken[69]={Kesit:4, 		NotrKesit:4, KesitSayi:1, DCDirenc:4.61, ToprakAK:56, BoruAK:0, HavaAK:32, DisCap:7.5, Agirlik:90, Malzeme:'Cu', Tur:'NYY', Etiket:'1x4mm² NYY'};
Iletken[70]={Kesit:6, 		NotrKesit:6, KesitSayi:1, DCDirenc:3.08, ToprakAK:70, BoruAK:0, HavaAK:40, DisCap:8, Agirlik:110, Malzeme:'Cu', Tur:'NYY', Etiket:'1x6mm² NYY'};
Iletken[71]={Kesit:10, 		NotrKesit:10, KesitSayi:1, DCDirenc:1.83, ToprakAK:95, BoruAK:0, HavaAK:60, DisCap:9.5, Agirlik:160, Malzeme:'Cu', Tur:'NYY', Etiket:'1x10mm² NYY'};
Iletken[72]={Kesit:16, 		NotrKesit:16, KesitSayi:1, DCDirenc:1.15, ToprakAK:123, BoruAK:0, HavaAK:80, DisCap:10, Agirlik:220, Malzeme:'Cu', Tur:'NYY', Etiket:'1x16mm² NYY'};
Iletken[73]={Kesit:25, 		NotrKesit:25, KesitSayi:1, DCDirenc:0.727, ToprakAK:160, BoruAK:0, HavaAK:110, DisCap:11.5, Agirlik:320, Malzeme:'Cu', Tur:'NYY', Etiket:'1x25mm² NYY'};
Iletken[74]={Kesit:35, 		NotrKesit:35, KesitSayi:1, DCDirenc:0.524, ToprakAK:190, BoruAK:0, HavaAK:165, DisCap:13, Agirlik:425, Malzeme:'Cu', Tur:'NYY', Etiket:'1x35mm² NYY'};
Iletken[75]={Kesit:50, 		NotrKesit:50, KesitSayi:1, DCDirenc:0.387, ToprakAK:225, BoruAK:0, HavaAK:165, DisCap:14, Agirlik:550, Malzeme:'Cu', Tur:'NYY', Etiket:'1x50mm² NYY'};
Iletken[76]={Kesit:70, 		NotrKesit:70, KesitSayi:1, DCDirenc:0.268, ToprakAK:272, BoruAK:0, HavaAK:210, DisCap:16, Agirlik:760, Malzeme:'Cu', Tur:'NYY', Etiket:'1x70mm² NYY'};
Iletken[77]={Kesit:95, 		NotrKesit:95, KesitSayi:1, DCDirenc:0.193, ToprakAK:320, BoruAK:0, HavaAK:260, DisCap:18, Agirlik:1020, Malzeme:'Cu', Tur:'NYY', Etiket:'1x95mm² NYY'};
Iletken[78]={Kesit:120, 	NotrKesit:120, KesitSayi:1, DCDirenc:0.153, ToprakAK:375, BoruAK:0, HavaAK:300, DisCap:19.5, Agirlik:1280, Malzeme:'Cu', Tur:'NYY', Etiket:'1x120mm² NYY'};
Iletken[79]={Kesit:150, 	NotrKesit:150, KesitSayi:1, DCDirenc:0.124, ToprakAK:420, BoruAK:0, HavaAK:350, DisCap:22, Agirlik:1525, Malzeme:'Cu', Tur:'NYY', Etiket:'1x150mm² NYY'};
Iletken[80]={Kesit:185, 	NotrKesit:185, KesitSayi:1, DCDirenc:0.0991, ToprakAK:475, BoruAK:0, HavaAK:400, DisCap:24, Agirlik:1950, Malzeme:'Cu', Tur:'NYY', Etiket:'1x185mm² NYY'};
Iletken[81]={Kesit:240, 	NotrKesit:240, KesitSayi:1, DCDirenc:0.0754, ToprakAK:550, BoruAK:0, HavaAK:480, DisCap:26.5, Agirlik:2450, Malzeme:'Cu', Tur:'NYY', Etiket:'1x240mm² NYY'};
Iletken[82]={Kesit:300, 	NotrKesit:300, KesitSayi:1, DCDirenc:0.0601, ToprakAK:632, BoruAK:0, HavaAK:549, DisCap:30, Agirlik:3185, Malzeme:'Cu', Tur:'NYY', Etiket:'1x300mm² NYY'};
Iletken[83]={Kesit:400, 	NotrKesit:400, KesitSayi:1, DCDirenc:0.0470, ToprakAK:730, BoruAK:0, HavaAK:657, DisCap:33, Agirlik:4050, Malzeme:'Cu', Tur:'NYY', Etiket:'1x400mm² NYY'};
Iletken[84]={Kesit:500, 	NotrKesit:500, KesitSayi:1, DCDirenc:0.0366, ToprakAK:823, BoruAK:0, HavaAK:749, DisCap:38, Agirlik:5150, Malzeme:'Cu', Tur:'NYY', Etiket:'1x500mm² NYY'};
Iletken[85]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:2, DCDirenc:12.1, ToprakAK:32, BoruAK:0, HavaAK:22, DisCap:10.5, Agirlik:150, Malzeme:'Cu', Tur:'NYY', Etiket:'2x1.5mm² NYY'};
Iletken[86]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:2, DCDirenc:7.41, ToprakAK:42, BoruAK:0, HavaAK:30, DisCap:11.5, Agirlik:190, Malzeme:'Cu', Tur:'NYY', Etiket:'2x2.5mm² NYY'};
Iletken[87]={Kesit:4, 		NotrKesit:4, KesitSayi:2, DCDirenc:4.61, ToprakAK:52, BoruAK:0, HavaAK:40, DisCap:13, Agirlik:260, Malzeme:'Cu', Tur:'NYY', Etiket:'2x4mm² NYY'};
Iletken[88]={Kesit:6, 		NotrKesit:6, KesitSayi:2, DCDirenc:3.08, ToprakAK:66, BoruAK:0, HavaAK:50, DisCap:14, Agirlik:320, Malzeme:'Cu', Tur:'NYY', Etiket:'2x6mm² NYY'};
Iletken[89]={Kesit:10, 		NotrKesit:10, KesitSayi:2, DCDirenc:1.83, ToprakAK:90, BoruAK:0, HavaAK:69, DisCap:16, Agirlik:460, Malzeme:'Cu', Tur:'NYY', Etiket:'2x10mm² NYY'};
Iletken[90]={Kesit:16, 		NotrKesit:16, KesitSayi:2, DCDirenc:1.15, ToprakAK:115, BoruAK:0, HavaAK:93, DisCap:18, Agirlik:620, Malzeme:'Cu', Tur:'NYY', Etiket:'1x16mm² NYY'};
Iletken[91]={Kesit:25, 		NotrKesit:25, KesitSayi:2, DCDirenc:0.727, ToprakAK:150, BoruAK:0, HavaAK:118, DisCap:22, Agirlik:920, Malzeme:'Cu', Tur:'NYY', Etiket:'1x25mm² NYY'};
Iletken[92]={Kesit:35, 		NotrKesit:35, KesitSayi:2, DCDirenc:0.524, ToprakAK:180, BoruAK:0, HavaAK:147, DisCap:24, Agirlik:1160, Malzeme:'Cu', Tur:'NYY', Etiket:'1x35mm² NYY'};
Iletken[93]={Kesit:50, 		NotrKesit:50, KesitSayi:2, DCDirenc:0.387, ToprakAK:215, BoruAK:0, HavaAK:180, DisCap:27, Agirlik:1550, Malzeme:'Cu', Tur:'NYY', Etiket:'1x50mm² NYY'};
Iletken[94]={Kesit:70, 		NotrKesit:70, KesitSayi:2, DCDirenc:0.268, ToprakAK:264, BoruAK:0, HavaAK:224, DisCap:31, Agirlik:2100, Malzeme:'Cu', Tur:'NYY', Etiket:'1x70mm² NYY'};
Iletken[95]={Kesit:95, 		NotrKesit:95, KesitSayi:2, DCDirenc:0.193, ToprakAK:318, BoruAK:0, HavaAK:271, DisCap:35, Agirlik:2800, Malzeme:'Cu', Tur:'NYY', Etiket:'1x95mm² NYY'};
Iletken[96]={Kesit:120, 	NotrKesit:120, KesitSayi:2, DCDirenc:0.153, ToprakAK:360, BoruAK:0, HavaAK:314, DisCap:39, Agirlik:3450, Malzeme:'Cu', Tur:'NYY', Etiket:'1x120mm² NYY'};
Iletken[97]={Kesit:150, 	NotrKesit:150, KesitSayi:2, DCDirenc:0.124, ToprakAK:406, BoruAK:0, HavaAK:361, DisCap:43, Agirlik:4200, Malzeme:'Cu', Tur:'NYY', Etiket:'1x150mm² NYY'};
Iletken[98]={Kesit:185, 	NotrKesit:185, KesitSayi:2, DCDirenc:0.0991, ToprakAK:458, BoruAK:0, HavaAK:412, DisCap:47, Agirlik:5200, Malzeme:'Cu', Tur:'NYY', Etiket:'1x185mm² NYY'};
Iletken[99]={Kesit:240, 	NotrKesit:240, KesitSayi:2, DCDirenc:0.0751, ToprakAK:537, BoruAK:0, HavaAK:484, DisCap:53, Agirlik:6800, Malzeme:'Cu', Tur:'NYY', Etiket:'1x240mm² NYY'};
Iletken[100]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:3, DCDirenc:12.1, ToprakAK:27, BoruAK:0, HavaAK:19, DisCap:11, Agirlik:160, Malzeme:'Cu', Tur:'NYY', Etiket:'3x1.5mm² NYY'};
Iletken[101]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:3, DCDirenc:7.41, ToprakAK:36, BoruAK:0, HavaAK:25, DisCap:12, Agirlik:215, Malzeme:'Cu', Tur:'NYY', Etiket:'3x2.5mm² NYY'};
Iletken[102]={Kesit:4, 		NotrKesit:4, KesitSayi:3, DCDirenc:4.61, ToprakAK:47, BoruAK:0, HavaAK:34, DisCap:14, Agirlik:310, Malzeme:'Cu', Tur:'NYY', Etiket:'3x4mm² NYY'};
Iletken[103]={Kesit:6, 		NotrKesit:6, KesitSayi:3, DCDirenc:3.08, ToprakAK:59, BoruAK:0, HavaAK:43, DisCap:15, Agirlik:380, Malzeme:'Cu', Tur:'NYY', Etiket:'3x6mm² NYY'};
Iletken[104]={Kesit:10, 	NotrKesit:10, KesitSayi:3, DCDirenc:1.83, ToprakAK:79, BoruAK:0, HavaAK:59, DisCap:17.5, Agirlik:550, Malzeme:'Cu', Tur:'NYY', Etiket:'3x10mm² NYY'};
Iletken[105]={Kesit:16, 	NotrKesit:16, KesitSayi:3, DCDirenc:1.15, ToprakAK:102, BoruAK:0, HavaAK:79, DisCap:20, Agirlik:780, Malzeme:'Cu', Tur:'NYY', Etiket:'3x16mm² NYY'};
Iletken[106]={Kesit:25, 	NotrKesit:25, KesitSayi:3, DCDirenc:0.727, ToprakAK:133, BoruAK:0, HavaAK:100, DisCap:23, Agirlik:1150, Malzeme:'Cu', Tur:'NYY', Etiket:'3x25mm² NYY'};
Iletken[107]={Kesit:35, 	NotrKesit:35, KesitSayi:3, DCDirenc:0.524, ToprakAK:159, BoruAK:0, HavaAK:125, DisCap:26, Agirlik:1500, Malzeme:'Cu', Tur:'NYY', Etiket:'3x35mm² NYY'};
Iletken[108]={Kesit:50, 	NotrKesit:50, KesitSayi:3, DCDirenc:0.387, ToprakAK:188, BoruAK:0, HavaAK:153, DisCap:29.5, Agirlik:1960, Malzeme:'Cu', Tur:'NYY', Etiket:'3x50mm² NYY'};
Iletken[109]={Kesit:70, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.268, ToprakAK:232, BoruAK:0, HavaAK:195, DisCap:33, Agirlik:2700, Malzeme:'Cu', Tur:'NYY', Etiket:'3x70mm² NYY'};
Iletken[110]={Kesit:95, 	NotrKesit:95, KesitSayi:3, DCDirenc:0.193, ToprakAK:280, BoruAK:0, HavaAK:238, DisCap:38, Agirlik:3650, Malzeme:'Cu', Tur:'NYY', Etiket:'3x95mm² NYY'};
Iletken[111]={Kesit:120, 	NotrKesit:120, KesitSayi:3, DCDirenc:0.153, ToprakAK:318, BoruAK:0, HavaAK:275, DisCap:41.5, Agirlik:4500, Malzeme:'Cu', Tur:'NYY', Etiket:'3x120mm² NYY'};
Iletken[112]={Kesit:150, 	NotrKesit:150, KesitSayi:3, DCDirenc:0.124, ToprakAK:359, BoruAK:0, HavaAK:320, DisCap:45, Agirlik:5450, Malzeme:'Cu', Tur:'NYY', Etiket:'3x150mm² NYY'};
Iletken[113]={Kesit:185, 	NotrKesit:185, KesitSayi:3, DCDirenc:0.0991, ToprakAK:406, BoruAK:0, HavaAK:354, DisCap:50, Agirlik:6900, Malzeme:'Cu', Tur:'NYY', Etiket:'3x185mm² NYY'};
Iletken[114]={Kesit:240, 	NotrKesit:240, KesitSayi:3, DCDirenc:0.0751, ToprakAK:473, BoruAK:0, HavaAK:430, DisCap:56, Agirlik:8700, Malzeme:'Cu', Tur:'NYY', Etiket:'3x240mm² NYY'};
Iletken[115]={Kesit:300, 	NotrKesit:300, KesitSayi:3, DCDirenc:0.0601, ToprakAK:535, BoruAK:0, HavaAK:510, DisCap:63, Agirlik:11250, Malzeme:'Cu', Tur:'NYY', Etiket:'3x300mm² NYY'};
Iletken[116]={Kesit:400, 	NotrKesit:400, KesitSayi:3, DCDirenc:0.0470, ToprakAK:613, BoruAK:0, HavaAK:595, DisCap:71, Agirlik:14300, Malzeme:'Cu', Tur:'NYY', Etiket:'3x400mm² NYY'};
Iletken[117]={Kesit:16, 	NotrKesit:10, KesitSayi:3, DCDirenc:1.15, ToprakAK:102, BoruAK:0, HavaAK:79, DisCap:21.5, Agirlik:900, Malzeme:'Cu', Tur:'NYY', Etiket:'3x16/10mm² NYY'};
Iletken[118]={Kesit:25, 	NotrKesit:16, KesitSayi:3, DCDirenc:0.727, ToprakAK:133, BoruAK:0, HavaAK:100, DisCap:24.5, Agirlik:1350, Malzeme:'Cu', Tur:'NYY', Etiket:'3x25/16mm² NYY'};
Iletken[119]={Kesit:35, 	NotrKesit:16, KesitSayi:3, DCDirenc:0.524, ToprakAK:159, BoruAK:0, HavaAK:125, DisCap:26.5, Agirlik:1700, Malzeme:'Cu', Tur:'NYY', Etiket:'3x35/16mm² NYY'};
Iletken[120]={Kesit:50, 	NotrKesit:25, KesitSayi:3, DCDirenc:0.387, ToprakAK:188, BoruAK:0, HavaAK:153, DisCap:31, Agirlik:2250, Malzeme:'Cu', Tur:'NYY', Etiket:'3x50/25mm² NYY'};
Iletken[121]={Kesit:70, 	NotrKesit:35, KesitSayi:3, DCDirenc:0.268, ToprakAK:232, BoruAK:0, HavaAK:195, DisCap:34.5, Agirlik:3050, Malzeme:'Cu', Tur:'NYY', Etiket:'3x70/35mm² NYY'};
Iletken[122]={Kesit:95, 	NotrKesit:50, KesitSayi:3, DCDirenc:0.193, ToprakAK:280, BoruAK:0, HavaAK:238, DisCap:40, Agirlik:4050, Malzeme:'Cu', Tur:'NYY', Etiket:'3x95/50mm² NYY'};
Iletken[123]={Kesit:120, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.153, ToprakAK:318, BoruAK:0, HavaAK:275, DisCap:43, Agirlik:5150, Malzeme:'Cu', Tur:'NYY', Etiket:'3x120/70mm² NYY'};
Iletken[124]={Kesit:150, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.124, ToprakAK:359, BoruAK:0, HavaAK:320, DisCap:47.5, Agirlik:6050, Malzeme:'Cu', Tur:'NYY', Etiket:'3x150/70mm² NYY'};
Iletken[125]={Kesit:185, 	NotrKesit:95, KesitSayi:3, DCDirenc:0.0991, ToprakAK:406, BoruAK:0, HavaAK:354, DisCap:52.5, Agirlik:7750, Malzeme:'Cu', Tur:'NYY', Etiket:'3x185/95mm² NYY'};
Iletken[126]={Kesit:240, 	NotrKesit:120, KesitSayi:3, DCDirenc:0.0751, ToprakAK:473, BoruAK:0, HavaAK:430, DisCap:59, Agirlik:9800, Malzeme:'Cu', Tur:'NYY', Etiket:'3x240/120mm² NYY'};
Iletken[127]={Kesit:300, 	NotrKesit:150, KesitSayi:3, DCDirenc:0.0601, ToprakAK:535, BoruAK:0, HavaAK:510, DisCap:66, Agirlik:12700, Malzeme:'Cu', Tur:'NYY', Etiket:'3x300/150mm² NYY'};
Iletken[128]={Kesit:400, 	NotrKesit:185, KesitSayi:3, DCDirenc:0.0470, ToprakAK:613, BoruAK:0, HavaAK:595, DisCap:74, Agirlik:16000, Malzeme:'Cu', Tur:'NYY', Etiket:'3x400/185mm² NYY'};
Iletken[129]={Kesit:1.5, 	NotrKesit:1.6, KesitSayi:4, DCDirenc:12.1, ToprakAK:27, BoruAK:0, HavaAK:19, DisCap:12, Agirlik:200, Malzeme:'Cu', Tur:'NYY', Etiket:'4x1.5mm² NYY'};
Iletken[130]={Kesit:2.5, 	NotrKesit:2.6, KesitSayi:4, DCDirenc:7.41, ToprakAK:36, BoruAK:0, HavaAK:25, DisCap:13, Agirlik:260, Malzeme:'Cu', Tur:'NYY', Etiket:'4x2.5mm² NYY'};
Iletken[131]={Kesit:4, 		NotrKesit:4, KesitSayi:4, DCDirenc:4.61, ToprakAK:47, BoruAK:0, HavaAK:34, DisCap:15, Agirlik:350, Malzeme:'Cu', Tur:'NYY', Etiket:'4x4mm² NYY'};
Iletken[132]={Kesit:6, 		NotrKesit:6, KesitSayi:4, DCDirenc:3.08, ToprakAK:59, BoruAK:0, HavaAK:43, DisCap:16, Agirlik:460, Malzeme:'Cu', Tur:'NYY', Etiket:'4x6mm² NYY'};
Iletken[133]={Kesit:10, 	NotrKesit:10, KesitSayi:4, DCDirenc:1.83, ToprakAK:79, BoruAK:0, HavaAK:59, DisCap:19, Agirlik:680, Malzeme:'Cu', Tur:'NYY', Etiket:'4x10mm² NYY'};
Iletken[134]={Kesit:16, 	NotrKesit:16, KesitSayi:4, DCDirenc:1.15, ToprakAK:102, BoruAK:0, HavaAK:79, DisCap:21, Agirlik:960, Malzeme:'Cu', Tur:'NYY', Etiket:'4x16mm² NYY'};
Iletken[135]={Kesit:25, 	NotrKesit:25, KesitSayi:4, DCDirenc:0.727, ToprakAK:133, BoruAK:0, HavaAK:100, DisCap:25.5, Agirlik:1450, Malzeme:'Cu', Tur:'NYY', Etiket:'4x25mm² NYY'};
Iletken[136]={Kesit:35, 	NotrKesit:35, KesitSayi:4, DCDirenc:0.524, ToprakAK:159, BoruAK:0, HavaAK:125, DisCap:28.5, Agirlik:1900, Malzeme:'Cu', Tur:'NYY', Etiket:'4x35mm² NYY'};
Iletken[137]={Kesit:50, 	NotrKesit:50, KesitSayi:4, DCDirenc:0.387, ToprakAK:188, BoruAK:0, HavaAK:153, DisCap:32.5, Agirlik:2500, Malzeme:'Cu', Tur:'NYY', Etiket:'4x50mm² NYY'};
Iletken[138]={Kesit:70, 	NotrKesit:70, KesitSayi:4, DCDirenc:0.268, ToprakAK:232, BoruAK:0, HavaAK:195, DisCap:36.5, Agirlik:3420, Malzeme:'Cu', Tur:'NYY', Etiket:'4x70mm² NYY'};
Iletken[139]={Kesit:95, 	NotrKesit:95, KesitSayi:4, DCDirenc:0.193, ToprakAK:280, BoruAK:0, HavaAK:238, DisCap:42, Agirlik:4650, Malzeme:'Cu', Tur:'NYY', Etiket:'4x95mm² NYY'};
Iletken[140]={Kesit:120, 	NotrKesit:120, KesitSayi:4, DCDirenc:0.153, ToprakAK:318, BoruAK:0, HavaAK:275, DisCap:46, Agirlik:5720, Malzeme:'Cu', Tur:'NYY', Etiket:'4x120mm² NYY'};
Iletken[141]={Kesit:150, 	NotrKesit:150, KesitSayi:4, DCDirenc:0.124, ToprakAK:359, BoruAK:0, HavaAK:320, DisCap:51, Agirlik:7000, Malzeme:'Cu', Tur:'NYY', Etiket:'4x150mm² NYY'};
Iletken[142]={Kesit:185, 	NotrKesit:185, KesitSayi:4, DCDirenc:0.0991, ToprakAK:406, BoruAK:0, HavaAK:354, DisCap:56, Agirlik:8800, Malzeme:'Cu', Tur:'NYY', Etiket:'4x185mm² NYY'};
Iletken[143]={Kesit:240, 	NotrKesit:240, KesitSayi:4, DCDirenc:0.0751, ToprakAK:473, BoruAK:0, HavaAK:430, DisCap:63, Agirlik:11200, Malzeme:'Cu', Tur:'NYY', Etiket:'4x240mm² NYY'};
Iletken[144]={Kesit:300, 	NotrKesit:300, KesitSayi:4, DCDirenc:0.0601, ToprakAK:535, BoruAK:0, HavaAK:510, DisCap:70, Agirlik:14200, Malzeme:'Cu', Tur:'NYY', Etiket:'4x300mm² NYY'};
Iletken[145]={Kesit:400, 	NotrKesit:400, KesitSayi:4, DCDirenc:0.0470, ToprakAK:613, BoruAK:0, HavaAK:595, DisCap:78, Agirlik:18000, Malzeme:'Cu', Tur:'NYY', Etiket:'4x400mm² NYY'};
Iletken[146]={Kesit:4, 		NotrKesit:4, KesitSayi:5, DCDirenc:4.61, ToprakAK:47, BoruAK:0, HavaAK:34, DisCap:16, Agirlik:420, Malzeme:'Cu', Tur:'NYY', Etiket:'5x4mm² NYY'};
Iletken[147]={Kesit:6, 		NotrKesit:6, KesitSayi:5, DCDirenc:3.08, ToprakAK:59, BoruAK:0, HavaAK:43, DisCap:17.5, Agirlik:540, Malzeme:'Cu', Tur:'NYY', Etiket:'5x6mm² NYY'};
Iletken[148]={Kesit:10, 	NotrKesit:10, KesitSayi:5, DCDirenc:1.83, ToprakAK:79, BoruAK:0, HavaAK:59, DisCap:21, Agirlik:825, Malzeme:'Cu', Tur:'NYY', Etiket:'5x10mm² NYY'};
Iletken[149]={Kesit:16, 	NotrKesit:16, KesitSayi:5, DCDirenc:1.15, ToprakAK:102, BoruAK:0, HavaAK:79, DisCap:23.5, Agirlik:1160, Malzeme:'Cu', Tur:'NYY', Etiket:'5x16mm² NYY'};
Iletken[150]={Kesit:25, 	NotrKesit:25, KesitSayi:5, DCDirenc:0.727, ToprakAK:133, BoruAK:0, HavaAK:100, DisCap:28, Agirlik:1740, Malzeme:'Cu', Tur:'NYY', Etiket:'5x25mm² NYY'};
Iletken[151]={Kesit:35, 	NotrKesit:35, KesitSayi:5, DCDirenc:0.524, ToprakAK:159, BoruAK:0, HavaAK:125, DisCap:31.5, Agirlik:2320, Malzeme:'Cu', Tur:'NYY', Etiket:'5x35mm² NYY'};
Iletken[152]={Kesit:50, 	NotrKesit:50, KesitSayi:5, DCDirenc:0.387, ToprakAK:188, BoruAK:0, HavaAK:153, DisCap:36, Agirlik:3020, Malzeme:'Cu', Tur:'NYY', Etiket:'5x50mm² NYY'};
Iletken[153]={Kesit:70, 	NotrKesit:70, KesitSayi:5, DCDirenc:0.268, ToprakAK:232, BoruAK:0, HavaAK:195, DisCap:40.5, Agirlik:4160, Malzeme:'Cu', Tur:'NYY', Etiket:'5x70mm² NYY'};
Iletken[154]={Kesit:95, 	NotrKesit:95, KesitSayi:5, DCDirenc:0.193, ToprakAK:280, BoruAK:0, HavaAK:238, DisCap:47, Agirlik:5750, Malzeme:'Cu', Tur:'NYY', Etiket:'5x95mm² NYY'};
Iletken[155]={Kesit:120, 	NotrKesit:120, KesitSayi:5, DCDirenc:0.153, ToprakAK:318, BoruAK:0, HavaAK:275, DisCap:50, Agirlik:7400, Malzeme:'Cu', Tur:'NYY', Etiket:'5x120mm² NYY'};
Iletken[156]={Kesit:150, 	NotrKesit:150, KesitSayi:5, DCDirenc:0.124, ToprakAK:359, BoruAK:0, HavaAK:320, DisCap:55, Agirlik:9000, Malzeme:'Cu', Tur:'NYY', Etiket:'5x150mm² NYY'};
Iletken[157]={Kesit:185, 	NotrKesit:185, KesitSayi:5, DCDirenc:0.0991, ToprakAK:406, BoruAK:0, HavaAK:354, DisCap:62, Agirlik:11500, Malzeme:'Cu', Tur:'NYY', Etiket:'5x185mm² NYY'};
Iletken[158]={Kesit:240, 	NotrKesit:240, KesitSayi:5, DCDirenc:0.0754, ToprakAK:473, BoruAK:0, HavaAK:430, DisCap:69, Agirlik:14500, Malzeme:'Cu', Tur:'NYY', Etiket:'5x240mm² NYY'};
Iletken[159]={Kesit:25, 	NotrKesit:25, KesitSayi:1, DCDirenc:1.2, ToprakAK:106, BoruAK:0, HavaAK:87, DisCap:11.1, Agirlik:175, Malzeme:'Al', Tur:'NAYY', Etiket:'1x25mm² NAYY'};
Iletken[160]={Kesit:35, 	NotrKesit:35, KesitSayi:1, DCDirenc:0.868, ToprakAK:127, BoruAK:0, HavaAK:107, DisCap:12.1, Agirlik:210, Malzeme:'Al', Tur:'NAYY', Etiket:'1x35mm² NAYY'};
Iletken[161]={Kesit:50, 	NotrKesit:50, KesitSayi:1, DCDirenc:0.641, ToprakAK:151, BoruAK:0, HavaAK:131, DisCap:13.7, Agirlik:270, Malzeme:'Al', Tur:'NAYY', Etiket:'1x50mm² NAYY'};
Iletken[162]={Kesit:70, 	NotrKesit:70, KesitSayi:1, DCDirenc:0.443, ToprakAK:185, BoruAK:0, HavaAK:166, DisCap:15.5, Agirlik:355, Malzeme:'Al', Tur:'NAYY', Etiket:'1x70mm² NAYY'};
Iletken[163]={Kesit:95, 	NotrKesit:95, KesitSayi:1, DCDirenc:0.32, ToprakAK:222, BoruAK:0, HavaAK:205, DisCap:17.8, Agirlik:475, Malzeme:'Al', Tur:'NAYY', Etiket:'1x95mm² NAYY'};
Iletken[164]={Kesit:120, 	NotrKesit:120, KesitSayi:1, DCDirenc:0.253, ToprakAK:253, BoruAK:0, HavaAK:239, DisCap:19.2, Agirlik:560, Malzeme:'Al', Tur:'NAYY', Etiket:'1x120mm² NAYY'};
Iletken[165]={Kesit:150, 	NotrKesit:150, KesitSayi:1, DCDirenc:0.206, ToprakAK:284, BoruAK:0, HavaAK:273, DisCap:21.3, Agirlik:680, Malzeme:'Al', Tur:'NAYY', Etiket:'1x150mm² NAYY'};
Iletken[166]={Kesit:185, 	NotrKesit:185, KesitSayi:1, DCDirenc:0.164, ToprakAK:322, BoruAK:0, HavaAK:317, DisCap:23.3, Agirlik:830, Malzeme:'Al', Tur:'NAYY', Etiket:'1x185mm² NAYY'};
Iletken[167]={Kesit:240, 	NotrKesit:240, KesitSayi:1, DCDirenc:0.125, ToprakAK:375, BoruAK:0, HavaAK:378, DisCap:26.2, Agirlik:1080, Malzeme:'Al', Tur:'NAYY', Etiket:'1x240mm² NAYY'};
Iletken[168]={Kesit:300, 	NotrKesit:300, KesitSayi:1, DCDirenc:0.1, ToprakAK:425, BoruAK:0, HavaAK:437, DisCap:28.8, Agirlik:1330, Malzeme:'Al', Tur:'NAYY', Etiket:'1x300mm² NAYY'};
Iletken[169]={Kesit:400, 	NotrKesit:400, KesitSayi:1, DCDirenc:0.0778, ToprakAK:487, BoruAK:0, HavaAK:513, DisCap:32.2, Agirlik:1630, Malzeme:'Al', Tur:'NAYY', Etiket:'1x400mm² NAYY'};
Iletken[170]={Kesit:500, 	NotrKesit:500, KesitSayi:1, DCDirenc:0.0605, ToprakAK:558, BoruAK:0, HavaAK:600, DisCap:36, Agirlik:2060, Malzeme:'Al', Tur:'NAYY', Etiket:'1x500mm² NAYY'};
Iletken[171]={Kesit:630, 	NotrKesit:630, KesitSayi:1, DCDirenc:0.0469, ToprakAK:635, BoruAK:0, HavaAK:701, DisCap:40.4, Agirlik:2570, Malzeme:'Al', Tur:'NAYY', Etiket:'1x630mm² NAYY'};
Iletken[172]={Kesit:25, 	NotrKesit:25, KesitSayi:2, DCDirenc:1.2, ToprakAK:104, BoruAK:0, HavaAK:85, DisCap:21, Agirlik:630, Malzeme:'Al', Tur:'NAYY', Etiket:'2x25mm² NAYY'};
Iletken[173]={Kesit:35, 	NotrKesit:35, KesitSayi:2, DCDirenc:0.868, ToprakAK:125, BoruAK:0, HavaAK:104, DisCap:23, Agirlik:760, Malzeme:'Al', Tur:'NAYY', Etiket:'2x35mm² NAYY'};
Iletken[174]={Kesit:50, 	NotrKesit:50, KesitSayi:2, DCDirenc:0.641, ToprakAK:148, BoruAK:0, HavaAK:125, DisCap:26.2, Agirlik:990, Malzeme:'Al', Tur:'NAYY', Etiket:'2x50mm² NAYY'};
Iletken[175]={Kesit:70, 	NotrKesit:70, KesitSayi:2, DCDirenc:0.443, ToprakAK:182, BoruAK:0, HavaAK:159, DisCap:30.6, Agirlik:1360, Malzeme:'Al', Tur:'NAYY', Etiket:'2x70mm² NAYY'};
Iletken[176]={Kesit:95, 	NotrKesit:95, KesitSayi:2, DCDirenc:0.32, ToprakAK:219, BoruAK:0, HavaAK:196, DisCap:35, Agirlik:1790, Malzeme:'Al', Tur:'NAYY', Etiket:'2x95mm² NAYY'};
Iletken[177]={Kesit:120, 	NotrKesit:120, KesitSayi:2, DCDirenc:0.253, ToprakAK:249, BoruAK:0, HavaAK:228, DisCap:38, Agirlik:2130, Malzeme:'Al', Tur:'NAYY', Etiket:'2x120mm² NAYY'};
Iletken[178]={Kesit:150, 	NotrKesit:150, KesitSayi:2, DCDirenc:0.206, ToprakAK:280, BoruAK:0, HavaAK:260, DisCap:42.6, Agirlik:2650, Malzeme:'Al', Tur:'NAYY', Etiket:'2x150mm² NAYY'};
Iletken[179]={Kesit:185, 	NotrKesit:185, KesitSayi:2, DCDirenc:0.164, ToprakAK:318, BoruAK:0, HavaAK:301, DisCap:46.8, Agirlik:3230, Malzeme:'Al', Tur:'NAYY', Etiket:'2x185mm² NAYY'};
Iletken[180]={Kesit:240, 	NotrKesit:240, KesitSayi:2, DCDirenc:0.125, ToprakAK:370, BoruAK:0, HavaAK:358, DisCap:52.6, Agirlik:4160, Malzeme:'Al', Tur:'NAYY', Etiket:'2x240mm² NAYY'};
Iletken[181]={Kesit:300, 	NotrKesit:300, KesitSayi:2, DCDirenc:0.1, ToprakAK:422, BoruAK:0, HavaAK:419, DisCap:57.8, Agirlik:5060, Malzeme:'Al', Tur:'NAYY', Etiket:'2x300mm² NAYY'};
Iletken[182]={Kesit:400, 	NotrKesit:400, KesitSayi:2, DCDirenc:0.0778, ToprakAK:486, BoruAK:0, HavaAK:493, DisCap:64.6, Agirlik:6260, Malzeme:'Al', Tur:'NAYY', Etiket:'2x400mm² NAYY'};
Iletken[183]={Kesit:25, 	NotrKesit:25, KesitSayi:3, DCDirenc:1.2, ToprakAK:102, BoruAK:0, HavaAK:82, DisCap:22.4, Agirlik:720, Malzeme:'Al', Tur:'NAYY', Etiket:'3x25mm² NAYY'};
Iletken[184]={Kesit:35, 	NotrKesit:35, KesitSayi:3, DCDirenc:0.868, ToprakAK:123, BoruAK:0, HavaAK:100, DisCap:24.5, Agirlik:870, Malzeme:'Al', Tur:'NAYY', Etiket:'3x35mm² NAYY'};
Iletken[185]={Kesit:50, 	NotrKesit:50, KesitSayi:3, DCDirenc:0.641, ToprakAK:144, BoruAK:0, HavaAK:119, DisCap:28.2, Agirlik:1150, Malzeme:'Al', Tur:'NAYY', Etiket:'3x50mm² NAYY'};
Iletken[186]={Kesit:70, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.443, ToprakAK:179, BoruAK:0, HavaAK:152, DisCap:32.8, Agirlik:1590, Malzeme:'Al', Tur:'NAYY', Etiket:'3x70mm² NAYY'};
Iletken[187]={Kesit:95, 	NotrKesit:95, KesitSayi:3, DCDirenc:0.32, ToprakAK:215, BoruAK:0, HavaAK:186, DisCap:37.6, Agirlik:2100, Malzeme:'Al', Tur:'NAYY', Etiket:'3x95mm² NAYY'};
Iletken[188]={Kesit:120, 	NotrKesit:120, KesitSayi:3, DCDirenc:0.253, ToprakAK:245, BoruAK:0, HavaAK:216, DisCap:40.8, Agirlik:2500, Malzeme:'Al', Tur:'NAYY', Etiket:'3x120mm² NAYY'};
Iletken[189]={Kesit:150, 	NotrKesit:150, KesitSayi:3, DCDirenc:0.206, ToprakAK:275, BoruAK:0, HavaAK:246, DisCap:45.7, Agirlik:3090, Malzeme:'Al', Tur:'NAYY', Etiket:'3x150mm² NAYY'};
Iletken[190]={Kesit:185, 	NotrKesit:185, KesitSayi:3, DCDirenc:0.164, ToprakAK:313, BoruAK:0, HavaAK:285, DisCap:50.2, Agirlik:3770, Malzeme:'Al', Tur:'NAYY', Etiket:'3x185mm² NAYY'};
Iletken[191]={Kesit:240, 	NotrKesit:240, KesitSayi:3, DCDirenc:0.125, ToprakAK:364, BoruAK:0, HavaAK:338, DisCap:56.5, Agirlik:4890, Malzeme:'Al', Tur:'NAYY', Etiket:'3x240mm² NAYY'};
Iletken[192]={Kesit:300, 	NotrKesit:300, KesitSayi:3, DCDirenc:0.1, ToprakAK:419, BoruAK:0, HavaAK:400, DisCap:62, Agirlik:5940, Malzeme:'Al', Tur:'NAYY', Etiket:'3x300mm² NAYY'};
Iletken[193]={Kesit:400, 	NotrKesit:400, KesitSayi:3, DCDirenc:0.0778, ToprakAK:484, BoruAK:0, HavaAK:472, DisCap:69.6, Agirlik:7390, Malzeme:'Al', Tur:'NAYY', Etiket:'3x400mm² NAYY'};
Iletken[194]={Kesit:25, 	NotrKesit:16, KesitSayi:3, DCDirenc:1.2, ToprakAK:102, BoruAK:0, HavaAK:82, DisCap:23.7, Agirlik:810, Malzeme:'Al', Tur:'NAYY', Etiket:'3x25+16mm² NAYY'};
Iletken[195]={Kesit:35, 	NotrKesit:16, KesitSayi:3, DCDirenc:0.868, ToprakAK:123, BoruAK:0, HavaAK:100, DisCap:25.5, Agirlik:940, Malzeme:'Al', Tur:'NAYY', Etiket:'3x35+16mm² NAYY'};
Iletken[196]={Kesit:50, 	NotrKesit:25, KesitSayi:3, DCDirenc:0.641, ToprakAK:144, BoruAK:0, HavaAK:119, DisCap:29.5, Agirlik:1270, Malzeme:'Al', Tur:'NAYY', Etiket:'3x50+25mm² NAYY'};
Iletken[197]={Kesit:70, 	NotrKesit:35, KesitSayi:3, DCDirenc:0.443, ToprakAK:179, BoruAK:0, HavaAK:152, DisCap:34.2, Agirlik:1730, Malzeme:'Al', Tur:'NAYY', Etiket:'3x70+35mm² NAYY'};
Iletken[198]={Kesit:95, 	NotrKesit:50, KesitSayi:3, DCDirenc:0.32, ToprakAK:215, BoruAK:0, HavaAK:186, DisCap:39.3, Agirlik:2290, Malzeme:'Al', Tur:'NAYY', Etiket:'3x95+50mm² NAYY'};
Iletken[199]={Kesit:120, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.253, ToprakAK:245, BoruAK:0, HavaAK:216, DisCap:43.5, Agirlik:2840, Malzeme:'Al', Tur:'NAYY', Etiket:'3x120+70mm² NAYY'};
Iletken[200]={Kesit:150, 	NotrKesit:70, KesitSayi:3, DCDirenc:0.206, ToprakAK:275, BoruAK:0, HavaAK:246, DisCap:47.1, Agirlik:3300, Malzeme:'Al', Tur:'NAYY', Etiket:'3x150+70mm² NAYY'};
Iletken[201]={Kesit:185, 	NotrKesit:95, KesitSayi:3, DCDirenc:0.164, ToprakAK:313, BoruAK:0, HavaAK:285, DisCap:52.4, Agirlik:4120, Malzeme:'Al', Tur:'NAYY', Etiket:'3x185+95mm² NAYY'};
Iletken[202]={Kesit:240, 	NotrKesit:120, KesitSayi:3, DCDirenc:0.125, ToprakAK:364, BoruAK:0, HavaAK:338, DisCap:58.5, Agirlik:5260, Malzeme:'Al', Tur:'NAYY', Etiket:'3x240+120mm² NAYY'};
Iletken[203]={Kesit:300, 	NotrKesit:150, KesitSayi:3, DCDirenc:0.1, ToprakAK:419, BoruAK:0, HavaAK:400, DisCap:64.5, Agirlik:6440, Malzeme:'Al', Tur:'NAYY', Etiket:'3x300+150mm² NAYY'};
Iletken[204]={Kesit:25, 	NotrKesit:25, KesitSayi:4, DCDirenc:1.2, ToprakAK:102, BoruAK:0, HavaAK:82, DisCap:24.5, Agirlik:870, Malzeme:'Al', Tur:'NAYY', Etiket:'4x25mm² NAYY'};
Iletken[205]={Kesit:35, 	NotrKesit:35, KesitSayi:4, DCDirenc:0.868, ToprakAK:123, BoruAK:0, HavaAK:100, DisCap:27.2, Agirlik:1080, Malzeme:'Al', Tur:'NAYY', Etiket:'4x35mm² NAYY'};
Iletken[206]={Kesit:50, 	NotrKesit:50, KesitSayi:4, DCDirenc:0.641, ToprakAK:144, BoruAK:0, HavaAK:119, DisCap:32, Agirlik:1490, Malzeme:'Al', Tur:'NAYY', Etiket:'4x50mm² NAYY'};
Iletken[207]={Kesit:70, 	NotrKesit:70, KesitSayi:4, DCDirenc:0.443, ToprakAK:179, BoruAK:0, HavaAK:152, DisCap:36.3, Agirlik:1950, Malzeme:'Al', Tur:'NAYY', Etiket:'4x70mm² NAYY'};
Iletken[208]={Kesit:95, 	NotrKesit:95, KesitSayi:4, DCDirenc:0.32, ToprakAK:215, BoruAK:0, HavaAK:186, DisCap:42.2, Agirlik:2650, Malzeme:'Al', Tur:'NAYY', Etiket:'4x95mm² NAYY'};
Iletken[209]={Kesit:120, 	NotrKesit:120, KesitSayi:4, DCDirenc:0.253, ToprakAK:245, BoruAK:0, HavaAK:216, DisCap:45.8, Agirlik:3160, Malzeme:'Al', Tur:'NAYY', Etiket:'4x120mm² NAYY'};
Iletken[210]={Kesit:150, 	NotrKesit:150, KesitSayi:4, DCDirenc:0.206, ToprakAK:275, BoruAK:0, HavaAK:246, DisCap:50.6, Agirlik:3810, Malzeme:'Al', Tur:'NAYY', Etiket:'4x150mm² NAYY'};
Iletken[211]={Kesit:185, 	NotrKesit:185, KesitSayi:4, DCDirenc:0.164, ToprakAK:313, BoruAK:0, HavaAK:285, DisCap:55.8, Agirlik:4680, Malzeme:'Al', Tur:'NAYY', Etiket:'4x185mm² NAYY'};
Iletken[212]={Kesit:240, 	NotrKesit:240, KesitSayi:4, DCDirenc:0.125, ToprakAK:364, BoruAK:0, HavaAK:338, DisCap:62.7, Agirlik:6060, Malzeme:'Al', Tur:'NAYY', Etiket:'4x240mm² NAYY'};
Iletken[213]={Kesit:300, 	NotrKesit:300, KesitSayi:4, DCDirenc:0.1, ToprakAK:419, BoruAK:0, HavaAK:400, DisCap:68.9, Agirlik:7390, Malzeme:'Al', Tur:'NAYY', Etiket:'4x300mm² NAYY'};
Iletken[214]={Kesit:400, 	NotrKesit:400, KesitSayi:4, DCDirenc:0.0778, ToprakAK:484, BoruAK:0, HavaAK:472, DisCap:77.3, Agirlik:9180, Malzeme:'Al', Tur:'NAYY', Etiket:'4x400mm² NAYY'};
Iletken[215]={Kesit:25, 	NotrKesit:16, KesitSayi:4, DCDirenc:1.2, ToprakAK:102, BoruAK:0, HavaAK:82, DisCap:26.1, Agirlik:990, Malzeme:'Al', Tur:'NAYY', Etiket:'4x25+16mm² NAYY'};
Iletken[216]={Kesit:35, 	NotrKesit:16, KesitSayi:4, DCDirenc:0.868, ToprakAK:123, BoruAK:0, HavaAK:100, DisCap:28.5, Agirlik:1180, Malzeme:'Al', Tur:'NAYY', Etiket:'4x35+16mm² NAYY'};
Iletken[217]={Kesit:50, 	NotrKesit:25, KesitSayi:4, DCDirenc:0.641, ToprakAK:144, BoruAK:0, HavaAK:119, DisCap:33.9, Agirlik:1680, Malzeme:'Al', Tur:'NAYY', Etiket:'4x50+25mm² NAYY'};
Iletken[218]={Kesit:70, 	NotrKesit:35, KesitSayi:4, DCDirenc:0.443, ToprakAK:179, BoruAK:0, HavaAK:152, DisCap:38.1, Agirlik:2160, Malzeme:'Al', Tur:'NAYY', Etiket:'4x70+35mm² NAYY'};
Iletken[219]={Kesit:95, 	NotrKesit:50, KesitSayi:4, DCDirenc:0.32, ToprakAK:215, BoruAK:0, HavaAK:186, DisCap:44.3, Agirlik:2930, Malzeme:'Al', Tur:'NAYY', Etiket:'4x95+50mm² NAYY'};
Iletken[220]={Kesit:120, 	NotrKesit:70, KesitSayi:4, DCDirenc:0.253, ToprakAK:245, BoruAK:0, HavaAK:216, DisCap:48.6, Agirlik:3560, Malzeme:'Al', Tur:'NAYY', Etiket:'4x120+70mm² NAYY'};
Iletken[221]={Kesit:150, 	NotrKesit:70, KesitSayi:4, DCDirenc:0.206, ToprakAK:275, BoruAK:0, HavaAK:246, DisCap:52.9, Agirlik:4170, Malzeme:'Al', Tur:'NAYY', Etiket:'4x150+70mm² NAYY'};
Iletken[222]={Kesit:185, 	NotrKesit:95, KesitSayi:4, DCDirenc:0.164, ToprakAK:313, BoruAK:0, HavaAK:285, DisCap:58.7, Agirlik:5190, Malzeme:'Al', Tur:'NAYY', Etiket:'4x185+95mm² NAYY'};
Iletken[223]={Kesit:240, 	NotrKesit:120, KesitSayi:4, DCDirenc:0.125, ToprakAK:364, BoruAK:0, HavaAK:338, DisCap:65.7, Agirlik:6660, Malzeme:'Al', Tur:'NAYY', Etiket:'4x240+120mm² NAYY'};
Iletken[224]={Kesit:300, 	NotrKesit:150, KesitSayi:4, DCDirenc:0.1, ToprakAK:419, BoruAK:0, HavaAK:400, DisCap:72.3, Agirlik:8130, Malzeme:'Al', Tur:'NAYY', Etiket:'4x300+150mm² NAYY'};
Iletken[225]={Kesit:25, 	NotrKesit:25, KesitSayi:5, DCDirenc:1.2, ToprakAK:102, BoruAK:0, HavaAK:82, DisCap:27.1, Agirlik:1070, Malzeme:'Al', Tur:'NAYY', Etiket:'5x25mm² NAYY'};
Iletken[226]={Kesit:35, 	NotrKesit:35, KesitSayi:5, DCDirenc:0.868, ToprakAK:123, BoruAK:0, HavaAK:100, DisCap:30.8, Agirlik:1390, Malzeme:'Al', Tur:'NAYY', Etiket:'5x35mm² NAYY'};
Iletken[227]={Kesit:50, 	NotrKesit:50, KesitSayi:5, DCDirenc:0.641, ToprakAK:144, BoruAK:0, HavaAK:119, DisCap:35.3, Agirlik:1820, Malzeme:'Al', Tur:'NAYY', Etiket:'5x50mm² NAYY'};
Iletken[228]={Kesit:70, 	NotrKesit:70, KesitSayi:5, DCDirenc:0.443, ToprakAK:179, BoruAK:0, HavaAK:152, DisCap:40, Agirlik:2380, Malzeme:'Al', Tur:'NAYY', Etiket:'5x70mm² NAYY'};
Iletken[229]={Kesit:95, 	NotrKesit:95, KesitSayi:5, DCDirenc:0.32, ToprakAK:215, BoruAK:0, HavaAK:186, DisCap:46.5, Agirlik:3230, Malzeme:'Al', Tur:'NAYY', Etiket:'5x95mm² NAYY'};
Iletken[230]={Kesit:120, 	NotrKesit:120, KesitSayi:5, DCDirenc:0.253, ToprakAK:245, BoruAK:0, HavaAK:216, DisCap:50.4, Agirlik:3840, Malzeme:'Al', Tur:'NAYY', Etiket:'5x120mm² NAYY'};
Iletken[231]={Kesit:150, 	NotrKesit:150, KesitSayi:5, DCDirenc:0.206, ToprakAK:275, BoruAK:0, HavaAK:246, DisCap:56, Agirlik:4670, Malzeme:'Al', Tur:'NAYY', Etiket:'5x150mm² NAYY'};
Iletken[232]={Kesit:185, 	NotrKesit:185, KesitSayi:5, DCDirenc:0.164, ToprakAK:313, BoruAK:0, HavaAK:285, DisCap:61.8, Agirlik:5750, Malzeme:'Al', Tur:'NAYY', Etiket:'5x185mm² NAYY'};
Iletken[233]={Kesit:240, 	NotrKesit:240, KesitSayi:5, DCDirenc:0.125, ToprakAK:364, BoruAK:0, HavaAK:338, DisCap:69.7, Agirlik:7500, Malzeme:'Al', Tur:'NAYY', Etiket:'5x240mm² NAYY'};


   
 



var Lamba=[];
Lamba[0]=0
Lamba[1]={Guc:8,IsikAkisi:475,RenkSicak:6500};
Lamba[2]={Guc:8,IsikAkisi:500,RenkSicak:2700};
Lamba[3]={Guc:12,IsikAkisi:700,RenkSicak:6500};
Lamba[4]={Guc:12,IsikAkisi:741,RenkSicak:2700};
Lamba[5]={Guc:8,IsikAkisi:475,RenkSicak:6500};      
Lamba[6]={Guc:8,IsikAkisi:500,RenkSicak:2700};
Lamba[7]={Guc:12,IsikAkisi:700,RenkSicak:6500};
Lamba[8]={Guc:12,IsikAkisi:741,RenkSicak:2700};
Lamba[9]={Guc:15,IsikAkisi:900,RenkSicak:6500};
Lamba[10]={Guc:15,IsikAkisi:970,RenkSicak:2700};
Lamba[11]={Guc:20,IsikAkisi:1200,RenkSicak:6500};
Lamba[12]={Guc:20,IsikAkisi:1250,RenkSicak:2700};
Lamba[13]={Guc:23,IsikAkisi:1450,RenkSicak:6500};
Lamba[14]={Guc:23,IsikAkisi:1570,RenkSicak:2700};
Lamba[15]={Guc:45,IsikAkisi:3080,RenkSicak:6500};
Lamba[16]={Guc:45,IsikAkisi:3080,RenkSicak:2700};
Lamba[17]={Guc:60,IsikAkisi:4300,RenkSicak:6500};
Lamba[18]={Guc:60,IsikAkisi:4300,RenkSicak:2700};
Lamba[19]={Guc:32,IsikAkisi:2160,RenkSicak:6500};
Lamba[20]={Guc:32,IsikAkisi:2255,RenkSicak:2700};
Lamba[21]={Guc:75,IsikAkisi:5550,RenkSicak:6500};
Lamba[22]={Guc:75,IsikAkisi:5550,RenkSicak:2700};

