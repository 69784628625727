// Tüm yüklerin bulunduğu dosya
// 03.01.2023 ÇARŞAMBA 
 // React projesine aktarma
 //-----------------DEVRE KESİCİLER---------------------------------------------

 export const  YUK=[];

 YUK[0]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 1x300W",Faz:1,CosFi:0.8,Guc:300,Gerilim:220};
 YUK[1]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 2x300W",Faz:1,CosFi:0.8,Guc:600,Gerilim:220};
 YUK[2]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 3x300W",Faz:1,CosFi:0.8,Guc:900,Gerilim:220};
 YUK[3]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 4x300W",Faz:1,CosFi:0.8,Guc:1200,Gerilim:220};
 YUK[4]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 5x300W",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 YUK[5]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 6x300W",Faz:1,CosFi:0.8,Guc:1800,Gerilim:220};
 YUK[6]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 1x600W",Faz:3,CosFi:0.8,Guc:600,Gerilim:380};
 YUK[7]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 2x600W",Faz:3,CosFi:0.8,Guc:1200,Gerilim:380};
 YUK[8]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 3x600W",Faz:3,CosFi:0.8,Guc:1800,Gerilim:380};
 
 YUK[9]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"300W 1F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:300,Gerilim:220};
 YUK[10]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"600W 1F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:600,Gerilim:220};
 YUK[11]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:1000,Gerilim:380};
 YUK[12]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1500W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:1500,Gerilim:380};
 YUK[13]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"2000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:2000,Gerilim:380};
 YUK[14]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"2500W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:2500,Gerilim:380};
 YUK[15]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 YUK[16]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 YUK[17]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 YUK[18]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 YUK[19]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 YUK[20]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3000W 3F Kombinasyon Kutusu",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 
 //------------------MOTOR YÜKLERİ ------------------------------
 YUK[21]={Tur:"Motor",Aciklama:"500W 1F MOTOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 YUK[22]={Tur:"Motor",Aciklama:"750W 1F MOTOR",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 YUK[23]={Tur:"Motor",Aciklama:"1100W 1F MOTOR",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 YUK[24]={Tur:"Motor",Aciklama:"1500W 1F MOTOR",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 YUK[25]={Tur:"Motor",Aciklama:"2200W 1F MOTOR",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 YUK[26]={Tur:"Motor",Aciklama:"3700W 1F MOTOR",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 YUK[27]={Tur:"Motor",Aciklama:"500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 YUK[28]={Tur:"Motor",Aciklama:"7500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 YUK[29]={Tur:"Motor",Aciklama:"1100W 3F MOTOR",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 YUK[30]={Tur:"Motor",Aciklama:"1500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 YUK[31]={Tur:"Motor",Aciklama:"2200W 3F MOTOR",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 YUK[32]={Tur:"Motor",Aciklama:"3700W 3F MOTOR",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 YUK[33]={Tur:"Motor",Aciklama:"5500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 YUK[34]={Tur:"Motor",Aciklama:"750W 3F MOTOR",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 YUK[35]={Tur:"Motor",Aciklama:"11kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 YUK[36]={Tur:"Motor",Aciklama:"15kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 YUK[37]={Tur:"Motor",Aciklama:"18.5kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 YUK[38]={Tur:"Motor",Aciklama:"22kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 YUK[39]={Tur:"Motor",Aciklama:"30kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 YUK[40]={Tur:"Motor",Aciklama:"37kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 YUK[41]={Tur:"Motor",Aciklama:"45kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 YUK[42]={Tur:"Motor",Aciklama:"55kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 YUK[43]={Tur:"Motor",Aciklama:"75kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 YUK[44]={Tur:"Motor",Aciklama:"90kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 YUK[45]={Tur:"Motor",Aciklama:"110kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 YUK[46]={Tur:"Motor",Aciklama:"150kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 YUK[47]={Tur:"Motor",Aciklama:"185kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 YUK[48]={Tur:"Motor",Aciklama:"220kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 YUK[49]={Tur:"Motor",Aciklama:"250kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 YUK[50]={Tur:"Motor",Aciklama:"300kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 YUK[51]={Tur:"Motor",Aciklama:"330kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 YUK[52]={Tur:"Motor",Aciklama:"370kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 
  
export const PRIZ=[];
 
 PRIZ[0]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 1x300W",Faz:1,CosFi:0.8,Guc:300,Gerilim:220};
 PRIZ[1]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 2x300W",Faz:1,CosFi:0.8,Guc:600,Gerilim:220};
 PRIZ[2]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 3x300W",Faz:1,CosFi:0.8,Guc:900,Gerilim:220};
 PRIZ[3]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 4x300W",Faz:1,CosFi:0.8,Guc:1200,Gerilim:220};
 PRIZ[4]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 5x300W",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 PRIZ[5]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"1F Priz 6x300W",Faz:1,CosFi:0.8,Guc:1800,Gerilim:220};
 PRIZ[6]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 1x600W",Faz:3,CosFi:0.8,Guc:600,Gerilim:380};
 PRIZ[7]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 2x600W",Faz:3,CosFi:0.8,Guc:1200,Gerilim:380};
 PRIZ[8]={Kullanim:["Genel","Priz"],Tur:"Priz",Aciklama:"3F Priz 3x600W",Faz:3,CosFi:0.8,Guc:1800,Gerilim:380};
 
 
 export const  MOTOR=[];
 MOTOR[0]={Tur:"Motor",Aciklama:"500W 1F MOTOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 MOTOR[1]={Tur:"Motor",Aciklama:"750W 1F MOTOR",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 MOTOR[2]={Tur:"Motor",Aciklama:"1100W 1F MOTOR",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 MOTOR[3]={Tur:"Motor",Aciklama:"1500W 1F MOTOR",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 MOTOR[4]={Tur:"Motor",Aciklama:"2200W 1F MOTOR",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 MOTOR[5]={Tur:"Motor",Aciklama:"3700W 1F MOTOR",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 MOTOR[6]={Tur:"Motor",Aciklama:"500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 MOTOR[7]={Tur:"Motor",Aciklama:"7500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 MOTOR[8]={Tur:"Motor",Aciklama:"1100W 3F MOTOR",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 MOTOR[9]={Tur:"Motor",Aciklama:"1500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 MOTOR[10]={Tur:"Motor",Aciklama:"2200W 3F MOTOR",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 MOTOR[11]={Tur:"Motor",Aciklama:"3700W 3F MOTOR",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 MOTOR[12]={Tur:"Motor",Aciklama:"5500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 MOTOR[13]={Tur:"Motor",Aciklama:"7500W 3F MOTOR",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 MOTOR[14]={Tur:"Motor",Aciklama:"11kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 MOTOR[15]={Tur:"Motor",Aciklama:"15kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 MOTOR[16]={Tur:"Motor",Aciklama:"18.5kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 MOTOR[17]={Tur:"Motor",Aciklama:"22kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 MOTOR[18]={Tur:"Motor",Aciklama:"30kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 MOTOR[19]={Tur:"Motor",Aciklama:"37kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 MOTOR[20]={Tur:"Motor",Aciklama:"45kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 MOTOR[21]={Tur:"Motor",Aciklama:"55kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 MOTOR[22]={Tur:"Motor",Aciklama:"75kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 MOTOR[23]={Tur:"Motor",Aciklama:"90kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 MOTOR[24]={Tur:"Motor",Aciklama:"110kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 MOTOR[25]={Tur:"Motor",Aciklama:"150kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 MOTOR[26]={Tur:"Motor",Aciklama:"185kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 MOTOR[27]={Tur:"Motor",Aciklama:"220kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 MOTOR[28]={Tur:"Motor",Aciklama:"250kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 MOTOR[29]={Tur:"Motor",Aciklama:"300kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 MOTOR[30]={Tur:"Motor",Aciklama:"330kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 MOTOR[31]={Tur:"Motor",Aciklama:"370kW 3F MOTOR",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  KOMPRESOR=[];
 KOMPRESOR[0]={Tur:"Kompresor",Aciklama:"500W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 KOMPRESOR[1]={Tur:"Kompresor",Aciklama:"750W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 KOMPRESOR[2]={Tur:"Kompresor",Aciklama:"1100W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 KOMPRESOR[3]={Tur:"Kompresor",Aciklama:"1500W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 KOMPRESOR[4]={Tur:"Kompresor",Aciklama:"2200W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 KOMPRESOR[5]={Tur:"Kompresor",Aciklama:"3700W 1F KOMPRESOR",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 KOMPRESOR[6]={Tur:"Kompresor",Aciklama:"500W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 KOMPRESOR[7]={Tur:"Kompresor",Aciklama:"7500W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 KOMPRESOR[8]={Tur:"Kompresor",Aciklama:"1100W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 KOMPRESOR[9]={Tur:"Kompresor",Aciklama:"1500W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 KOMPRESOR[10]={Tur:"Kompresor",Aciklama:"2200W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 KOMPRESOR[11]={Tur:"Kompresor",Aciklama:"3700W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 KOMPRESOR[12]={Tur:"Kompresor",Aciklama:"5500W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 KOMPRESOR[13]={Tur:"Kompresor",Aciklama:"7500W 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 KOMPRESOR[14]={Tur:"Kompresor",Aciklama:"11kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 KOMPRESOR[15]={Tur:"Kompresor",Aciklama:"15kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 KOMPRESOR[16]={Tur:"Kompresor",Aciklama:"18.5kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 KOMPRESOR[17]={Tur:"Kompresor",Aciklama:"22kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 KOMPRESOR[18]={Tur:"Kompresor",Aciklama:"30kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 KOMPRESOR[19]={Tur:"Kompresor",Aciklama:"37kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 KOMPRESOR[20]={Tur:"Kompresor",Aciklama:"45kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 KOMPRESOR[21]={Tur:"Kompresor",Aciklama:"55kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 KOMPRESOR[22]={Tur:"Kompresor",Aciklama:"75kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 KOMPRESOR[23]={Tur:"Kompresor",Aciklama:"90kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 KOMPRESOR[24]={Tur:"Kompresor",Aciklama:"110kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 KOMPRESOR[25]={Tur:"Kompresor",Aciklama:"150kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 KOMPRESOR[26]={Tur:"Kompresor",Aciklama:"185kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 KOMPRESOR[27]={Tur:"Kompresor",Aciklama:"220kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 KOMPRESOR[28]={Tur:"Kompresor",Aciklama:"250kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 KOMPRESOR[29]={Tur:"Kompresor",Aciklama:"300kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 KOMPRESOR[30]={Tur:"Kompresor",Aciklama:"330kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 KOMPRESOR[31]={Tur:"Kompresor",Aciklama:"370kW 3F KOMPRESOR",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 export const  KAYNAK=[];
 KAYNAK[0]= {Tur:"Kaynak",Aciklama:"500W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 KAYNAK[1]= {Tur:"Kaynak",Aciklama:"750W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 KAYNAK[2]= {Tur:"Kaynak",Aciklama:"1100W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 KAYNAK[3]= {Tur:"Kaynak",Aciklama:"1500W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 KAYNAK[4]= {Tur:"Kaynak",Aciklama:"2200W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 KAYNAK[5]= {Tur:"Kaynak",Aciklama:"3700W 1F KAYNAK",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 KAYNAK[6]= {Tur:"Kaynak",Aciklama:"500W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 KAYNAK[7]= {Tur:"Kaynak",Aciklama:"7500W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 KAYNAK[8]= {Tur:"Kaynak",Aciklama:"1100W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 KAYNAK[9]= {Tur:"Kaynak",Aciklama:"1500W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 KAYNAK[10]={Tur:"Kaynak",Aciklama:"2200W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 KAYNAK[11]={Tur:"Kaynak",Aciklama:"3700W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 KAYNAK[12]={Tur:"Kaynak",Aciklama:"5500W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 KAYNAK[13]={Tur:"Kaynak",Aciklama:"7500W 3F KAYNAK",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 KAYNAK[14]={Tur:"Kaynak",Aciklama:"11kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 KAYNAK[15]={Tur:"Kaynak",Aciklama:"15kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 KAYNAK[16]={Tur:"Kaynak",Aciklama:"18.5kW 3 KAYNAK",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 KAYNAK[17]={Tur:"Kaynak",Aciklama:"22kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 KAYNAK[18]={Tur:"Kaynak",Aciklama:"30kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 KAYNAK[19]={Tur:"Kaynak",Aciklama:"37kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 KAYNAK[20]={Tur:"Kaynak",Aciklama:"45kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 KAYNAK[21]={Tur:"Kaynak",Aciklama:"55kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 KAYNAK[22]={Tur:"Kaynak",Aciklama:"75kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 KAYNAK[23]={Tur:"Kaynak",Aciklama:"90kW 3F  KAYNAK",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 KAYNAK[24]={Tur:"Kaynak",Aciklama:"110kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 KAYNAK[25]={Tur:"Kaynak",Aciklama:"150kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 KAYNAK[26]={Tur:"Kaynak",Aciklama:"185kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 KAYNAK[27]={Tur:"Kaynak",Aciklama:"220kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 KAYNAK[28]={Tur:"Kaynak",Aciklama:"250kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 KAYNAK[29]={Tur:"Kaynak",Aciklama:"300kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 KAYNAK[30]={Tur:"Kaynak",Aciklama:"330kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 KAYNAK[31]={Tur:"Kaynak",Aciklama:"370kW 3F KAYNAK",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 export const  TORNA=[];
 TORNA[0]= {Tur:"Torna",Aciklama:"500W 1F TORNA",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 TORNA[1]= {Tur:"Torna",Aciklama:"750W 1F TORNA",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 TORNA[2]= {Tur:"Torna",Aciklama:"1100W 1F TORNA",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 TORNA[3]= {Tur:"Torna",Aciklama:"1500W 1F TORNA",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 TORNA[4]= {Tur:"Torna",Aciklama:"2200W 1F TORNA",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 TORNA[5]= {Tur:"Torna",Aciklama:"3700W 1F TORNA",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 TORNA[6]= {Tur:"Torna",Aciklama:"500W 3F TORNA",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 TORNA[7]= {Tur:"Torna",Aciklama:"7500W 3F TORNA",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 TORNA[8]= {Tur:"Torna",Aciklama:"1100W 3F TORNA",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 TORNA[9]= {Tur:"Torna",Aciklama:"1500W 3F TORNA",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 TORNA[10]={Tur:"Torna",Aciklama:"2200W 3F TORNA",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 TORNA[11]={Tur:"Torna",Aciklama:"3700W 3F TORNA",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 TORNA[12]={Tur:"Torna",Aciklama:"5500W 3F TORNA",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 TORNA[13]={Tur:"Torna",Aciklama:"7500W 3F TORNA",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 TORNA[14]={Tur:"Torna",Aciklama:"11kW 3F TORNA",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 TORNA[15]={Tur:"Torna",Aciklama:"15kW 3F TORNA",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 TORNA[16]={Tur:"Torna",Aciklama:"18.5kW 3F TORNA",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 TORNA[17]={Tur:"Torna",Aciklama:"22kW 3F TORNA",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 TORNA[18]={Tur:"Torna",Aciklama:"30kW 3F TORNA",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 TORNA[19]={Tur:"Torna",Aciklama:"37kW 3F TORNA",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 TORNA[20]={Tur:"Torna",Aciklama:"45kW 3F TORNA",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 TORNA[21]={Tur:"Torna",Aciklama:"55kW 3F TORNA",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 TORNA[22]={Tur:"Torna",Aciklama:"75kW 3F TORNA",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 TORNA[23]={Tur:"Torna",Aciklama:"90kW 3F TORNA",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 TORNA[24]={Tur:"Torna",Aciklama:"110kW 3F TORNA",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 TORNA[25]={Tur:"Torna",Aciklama:"150kW 3F TORNA",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 TORNA[26]={Tur:"Torna",Aciklama:"185kW 3F TORNA",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 TORNA[27]={Tur:"Torna",Aciklama:"220kW 3F TORNA",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 TORNA[28]={Tur:"Torna",Aciklama:"250kW 3F TORNA",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 TORNA[29]={Tur:"Torna",Aciklama:"300kW 3F TORNA",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 TORNA[30]={Tur:"Torna",Aciklama:"330kW 3F TORNA",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 TORNA[31]={Tur:"Torna",Aciklama:"370kW 3F TORNA",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  PRESS=[];
 PRESS[0]= {Tur:"Press",Aciklama:"500W 1F PRESS",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 PRESS[1]= {Tur:"Press",Aciklama:"750W 1F PRESS",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 PRESS[2]= {Tur:"Press",Aciklama:"1100W 1F PRESS",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 PRESS[3]= {Tur:"Press",Aciklama:"1500W 1F PRESS",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 PRESS[4]= {Tur:"Press",Aciklama:"2200W 1F PRESS",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 PRESS[5]= {Tur:"Press",Aciklama:"3700W 1F PRESS",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 PRESS[6]= {Tur:"Press",Aciklama:"500W 3F PRESS",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 PRESS[7]= {Tur:"Press",Aciklama:"7500W 3F PRESS",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 PRESS[8]= {Tur:"Press",Aciklama:"1100W 3F PRESS",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 PRESS[9]= {Tur:"Press",Aciklama:"1500W 3F PRESS",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 PRESS[10]={Tur:"Press",Aciklama:"2200W 3F PRESS",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 PRESS[11]={Tur:"Press",Aciklama:"3700W 3F PRESS",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 PRESS[12]={Tur:"Press",Aciklama:"5500W 3F PRESS",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 PRESS[13]={Tur:"Press",Aciklama:"7500W 3F PRESS",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 PRESS[14]={Tur:"Press",Aciklama:"11kW 3F PRESS",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 PRESS[15]={Tur:"Press",Aciklama:"15kW 3F PRESS",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 PRESS[16]={Tur:"Press",Aciklama:"18.5kW 3F PRESS",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 PRESS[17]={Tur:"Press",Aciklama:"22kW 3F PRESS",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 PRESS[18]={Tur:"Press",Aciklama:"30kW 3F PRESS",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 PRESS[19]={Tur:"Press",Aciklama:"37kW 3F PRESS",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 PRESS[20]={Tur:"Press",Aciklama:"45kW 3F PRESS",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 PRESS[21]={Tur:"Press",Aciklama:"55kW 3F PRESS",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 PRESS[22]={Tur:"Press",Aciklama:"75kW 3F PRESS",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 PRESS[23]={Tur:"Press",Aciklama:"90kW 3F PRESS",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 PRESS[24]={Tur:"Press",Aciklama:"110kW 3F PRESS",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 PRESS[25]={Tur:"Press",Aciklama:"150kW 3F PRESS",Faz:3,CosFi:0.8,Guc:150000,Gerilim:380};
 PRESS[26]={Tur:"Press",Aciklama:"185kW 3F PRESS",Faz:3,CosFi:0.8,Guc:185000,Gerilim:380};
 PRESS[27]={Tur:"Press",Aciklama:"220kW 3F PRESS",Faz:3,CosFi:0.8,Guc:220000,Gerilim:380};
 PRESS[28]={Tur:"Press",Aciklama:"250kW 3F PRESS",Faz:3,CosFi:0.8,Guc:250000,Gerilim:380};
 PRESS[29]={Tur:"Press",Aciklama:"300kW 3F PRESS",Faz:3,CosFi:0.8,Guc:300000,Gerilim:380};
 PRESS[30]={Tur:"Press",Aciklama:"330kW 3F PRESS",Faz:3,CosFi:0.8,Guc:330000,Gerilim:380};
 PRESS[31]={Tur:"Press",Aciklama:"370kW 3F PRESS",Faz:3,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  KESICI=[];
 KESICI[0]= {Tur:"Kesici",Aciklama:"500W 1F Kesici",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 KESICI[1]= {Tur:"Kesici",Aciklama:"750W 1F Kesici",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 KESICI[2]= {Tur:"Kesici",Aciklama:"1100W 1F Kesici",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 KESICI[3]= {Tur:"Kesici",Aciklama:"1500W 1F Kesici",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 KESICI[4]= {Tur:"Kesici",Aciklama:"2200W 1F Kesici",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 KESICI[5]= {Tur:"Kesici",Aciklama:"3700W 1F Kesici",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 KESICI[6]= {Tur:"Kesici",Aciklama:"500W 3F Kesici",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 KESICI[7]= {Tur:"Kesici",Aciklama:"7500W 3F Kesici",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 KESICI[8]= {Tur:"Kesici",Aciklama:"1100W 3F Kesici",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 KESICI[9]= {Tur:"Kesici",Aciklama:"1500W 3F Kesici",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 KESICI[10]={Tur:"Kesici",Aciklama:"2200W 3F Kesici",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 KESICI[11]={Tur:"Kesici",Aciklama:"3700W 3F Kesici",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 KESICI[12]={Tur:"Kesici",Aciklama:"5500W 3F Kesici",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 KESICI[13]={Tur:"Kesici",Aciklama:"7500W 3F Kesici",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 KESICI[14]={Tur:"Kesici",Aciklama:"11kW 3F Kesici",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 KESICI[15]={Tur:"Kesici",Aciklama:"15kW 3F Kesici",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 KESICI[16]={Tur:"Kesici",Aciklama:"18.5kW 3F Kesici",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 KESICI[17]={Tur:"Kesici",Aciklama:"22kW 3F Kesici",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 KESICI[18]={Tur:"Kesici",Aciklama:"30kW 3F Kesici",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 KESICI[19]={Tur:"Kesici",Aciklama:"37kW 3F Kesici",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 KESICI[20]={Tur:"Kesici",Aciklama:"45kW 3F Kesici",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 KESICI[21]={Tur:"Kesici",Aciklama:"55kW 3F Kesici",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 KESICI[22]={Tur:"Kesici",Aciklama:"75kW 3F Kesici",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 KESICI[23]={Tur:"Kesici",Aciklama:"90kW 3F Kesici",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 KESICI[24]={Tur:"Kesici",Aciklama:"110kW 3F Kesici",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 
 export const  ZIMPARA=[];
 ZIMPARA[0]= {Tur:"Zımpara",Aciklama:"500W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 ZIMPARA[1]= {Tur:"Zımpara",Aciklama:"750W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 ZIMPARA[2]= {Tur:"Zımpara",Aciklama:"1100W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 ZIMPARA[3]= {Tur:"Zımpara",Aciklama:"1500W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 ZIMPARA[4]= {Tur:"Zımpara",Aciklama:"2200W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 ZIMPARA[5]= {Tur:"Zımpara",Aciklama:"3700W 1F ZIMPARA",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 ZIMPARA[6]= {Tur:"Zımpara",Aciklama:"500W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:500,Gerilim:380};
 ZIMPARA[7]= {Tur:"Zımpara",Aciklama:"7500W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 ZIMPARA[8]= {Tur:"Zımpara",Aciklama:"1100W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 ZIMPARA[9]= {Tur:"Zımpara",Aciklama:"1500W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 ZIMPARA[10]={Tur:"Zımpara",Aciklama:"2200W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 ZIMPARA[11]={Tur:"Zımpara",Aciklama:"3700W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 ZIMPARA[12]={Tur:"Zımpara",Aciklama:"5500W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 ZIMPARA[13]={Tur:"Zımpara",Aciklama:"7500W 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 ZIMPARA[14]={Tur:"Zımpara",Aciklama:"11kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 ZIMPARA[15]={Tur:"Zımpara",Aciklama:"15kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 ZIMPARA[16]={Tur:"Zımpara",Aciklama:"18.5kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 ZIMPARA[17]={Tur:"Zımpara",Aciklama:"22kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 ZIMPARA[18]={Tur:"Zımpara",Aciklama:"30kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 ZIMPARA[19]={Tur:"Zımpara",Aciklama:"37kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 ZIMPARA[20]={Tur:"Zımpara",Aciklama:"45kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 ZIMPARA[21]={Tur:"Zımpara",Aciklama:"55kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 ZIMPARA[22]={Tur:"Zımpara",Aciklama:"75kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 ZIMPARA[23]={Tur:"Zımpara",Aciklama:"90kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 ZIMPARA[24]={Tur:"Zımpara",Aciklama:"110kW 3F ZIMPARA",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
   
 export const  PAKETLEME=[];
 PAKETLEME[0]= {Tur:"Paketleme",Aciklama:"2200W 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 PAKETLEME[1]= {Tur:"Paketleme",Aciklama:"3700W 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 PAKETLEME[2]= {Tur:"Paketleme",Aciklama:"5500W 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 PAKETLEME[3]= {Tur:"Paketleme",Aciklama:"7500W 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 PAKETLEME[4]= {Tur:"Paketleme",Aciklama:"11kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 PAKETLEME[5]= {Tur:"Paketleme",Aciklama:"15kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 PAKETLEME[6]= {Tur:"Paketleme",Aciklama:"18.5kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 PAKETLEME[7]= {Tur:"Paketleme",Aciklama:"22kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 PAKETLEME[8]= {Tur:"Paketleme",Aciklama:"30kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 PAKETLEME[9]= {Tur:"Paketleme",Aciklama:"37kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 PAKETLEME[10]={Tur:"Paketleme",Aciklama:"45kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 PAKETLEME[11]={Tur:"Paketleme",Aciklama:"55kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 PAKETLEME[12]={Tur:"Paketleme",Aciklama:"75kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 PAKETLEME[13]={Tur:"Paketleme",Aciklama:"90kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 PAKETLEME[14]={Tur:"Paketleme",Aciklama:"110kW 3F PAKETLEME",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
   
 export const  EBATLAMA=[];
 EBATLAMA[0]= {Tur:"Ebatlama",Aciklama:"2200W 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 EBATLAMA[1]= {Tur:"Ebatlama",Aciklama:"3700W 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 EBATLAMA[2]= {Tur:"Ebatlama",Aciklama:"5500W 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 EBATLAMA[3]= {Tur:"Ebatlama",Aciklama:"7500W 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 EBATLAMA[4]= {Tur:"Ebatlama",Aciklama:"11kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 EBATLAMA[5]= {Tur:"Ebatlama",Aciklama:"15kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 EBATLAMA[6]= {Tur:"Ebatlama",Aciklama:"18.5kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 EBATLAMA[7]= {Tur:"Ebatlama",Aciklama:"22kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 EBATLAMA[8]= {Tur:"Ebatlama",Aciklama:"30kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 EBATLAMA[9]= {Tur:"Ebatlama",Aciklama:"37kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 EBATLAMA[10]={Tur:"Ebatlama",Aciklama:"45kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 EBATLAMA[11]={Tur:"Ebatlama",Aciklama:"55kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 EBATLAMA[12]={Tur:"Ebatlama",Aciklama:"75kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 EBATLAMA[13]={Tur:"Ebatlama",Aciklama:"90kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 EBATLAMA[14]={Tur:"Ebatlama",Aciklama:"110kW 3F EBATLAMA",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
   
 
 export const  VINC=[];
 
 VINC[0]= {Tur:"Vinc",Aciklama:"2200W 3F VİNÇ",Faz:1,CosFi:0.8,Guc:2200,Gerilim:380};
 VINC[1]= {Tur:"Vinc",Aciklama:"3700W 3F VİNÇ",Faz:1,CosFi:0.8,Guc:3700,Gerilim:380};
 VINC[2]= {Tur:"Vinc",Aciklama:"5500W 3F VİNÇ",Faz:1,CosFi:0.8,Guc:5500,Gerilim:380};
 VINC[3]= {Tur:"Vinc",Aciklama:"7500W 3F VİNÇ",Faz:1,CosFi:0.8,Guc:7500,Gerilim:380};
 VINC[4]= {Tur:"Vinc",Aciklama:"11kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:11000,Gerilim:380};
 VINC[5]= {Tur:"Vinc",Aciklama:"15kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:15000,Gerilim:380};
 VINC[6]= {Tur:"Vinc",Aciklama:"18.5kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:18500,Gerilim:380};
 VINC[7]= {Tur:"Vinc",Aciklama:"22kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:22000,Gerilim:380};
 VINC[8]= {Tur:"Vinc",Aciklama:"30kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:30000,Gerilim:380};
 VINC[9]= {Tur:"Vinc",Aciklama:"37kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:37000,Gerilim:380};
 VINC[10]={Tur:"Vinc",Aciklama:"45kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:45000,Gerilim:380};
 VINC[11]={Tur:"Vinc",Aciklama:"55kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:55000,Gerilim:380};
 VINC[12]={Tur:"Vinc",Aciklama:"75kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:75000,Gerilim:380};
 VINC[13]={Tur:"Vinc",Aciklama:"90kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:90000,Gerilim:380};
 VINC[14]={Tur:"Vinc",Aciklama:"110kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:110000,Gerilim:380};
 VINC[15]={Tur:"Vinc",Aciklama:"150kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:150000,Gerilim:380};
 VINC[16]={Tur:"Vinc",Aciklama:"185kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:185000,Gerilim:380};
 VINC[17]={Tur:"Vinc",Aciklama:"220kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:220000,Gerilim:380};
 VINC[18]={Tur:"Vinc",Aciklama:"250kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:250000,Gerilim:380};
 VINC[19]={Tur:"Vinc",Aciklama:"300kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:300000,Gerilim:380};
 VINC[20]={Tur:"Vinc",Aciklama:"330kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:330000,Gerilim:380};
 VINC[21]={Tur:"Vinc",Aciklama:"370kW 3F VİNÇ",Faz:1,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  KURUTMA=[];
 KURUTMA[0]= {Tur:"Kurutma",Aciklama:"2200W 3F KURUTMA",Faz:1,CosFi:0.8,Guc:2200,Gerilim:380};
 KURUTMA[1]= {Tur:"Kurutma",Aciklama:"3700W 3F KURUTMA",Faz:1,CosFi:0.8,Guc:3700,Gerilim:380};
 KURUTMA[2]= {Tur:"Kurutma",Aciklama:"5500W 3F KURUTMA",Faz:1,CosFi:0.8,Guc:5500,Gerilim:380};
 KURUTMA[3]= {Tur:"Kurutma",Aciklama:"7500W 3F KURUTMA",Faz:1,CosFi:0.8,Guc:7500,Gerilim:380};
 KURUTMA[4]= {Tur:"Kurutma",Aciklama:"11kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:11000,Gerilim:380};
 KURUTMA[5]= {Tur:"Kurutma",Aciklama:"15kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:15000,Gerilim:380};
 KURUTMA[6]= {Tur:"Kurutma",Aciklama:"18.5kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:18500,Gerilim:380};
 KURUTMA[7]= {Tur:"Kurutma",Aciklama:"22kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:22000,Gerilim:380};
 KURUTMA[8]= {Tur:"Kurutma",Aciklama:"30kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:30000,Gerilim:380};
 KURUTMA[9]= {Tur:"Kurutma",Aciklama:"37kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:37000,Gerilim:380};
 KURUTMA[10]={Tur:"Kurutma",Aciklama:"45kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:45000,Gerilim:380};
 KURUTMA[11]={Tur:"Kurutma",Aciklama:"55kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:55000,Gerilim:380};
 KURUTMA[12]={Tur:"Kurutma",Aciklama:"75kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:75000,Gerilim:380};
 KURUTMA[13]={Tur:"Kurutma",Aciklama:"90kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:90000,Gerilim:380};
 KURUTMA[14]={Tur:"Kurutma",Aciklama:"110kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:110000,Gerilim:380};
 KURUTMA[15]={Tur:"Kurutma",Aciklama:"150kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:150000,Gerilim:380};
 KURUTMA[16]={Tur:"Kurutma",Aciklama:"185kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:185000,Gerilim:380};
 KURUTMA[17]={Tur:"Kurutma",Aciklama:"220kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:220000,Gerilim:380};
 KURUTMA[18]={Tur:"Kurutma",Aciklama:"250kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:250000,Gerilim:380};
 KURUTMA[19]={Tur:"Kurutma",Aciklama:"300kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:300000,Gerilim:380};
 KURUTMA[20]={Tur:"Kurutma",Aciklama:"330kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:330000,Gerilim:380};
 KURUTMA[21]={Tur:"Kurutma",Aciklama:"370kW 3F KURUTMA",Faz:1,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  OGUTUCU=[];
 OGUTUCU[0]= {Tur:"Ogutucu",Aciklama:"2200W 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:2200,Gerilim:380};
 OGUTUCU[1]= {Tur:"Ogutucu",Aciklama:"3700W 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:3700,Gerilim:380};
 OGUTUCU[2]= {Tur:"Ogutucu",Aciklama:"5500W 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:5500,Gerilim:380};
 OGUTUCU[3]= {Tur:"Ogutucu",Aciklama:"7500W 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:7500,Gerilim:380};
 OGUTUCU[4]= {Tur:"Ogutucu",Aciklama:"11kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:11000,Gerilim:380};
 OGUTUCU[5]= {Tur:"Ogutucu",Aciklama:"15kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:15000,Gerilim:380};
 OGUTUCU[6]= {Tur:"Ogutucu",Aciklama:"18.5kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:18500,Gerilim:380};
 OGUTUCU[7]= {Tur:"Ogutucu",Aciklama:"22kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:22000,Gerilim:380};
 OGUTUCU[8]= {Tur:"Ogutucu",Aciklama:"30kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:30000,Gerilim:380};
 OGUTUCU[9]= {Tur:"Ogutucu",Aciklama:"37kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:37000,Gerilim:380};
 OGUTUCU[10]={Tur:"Ogutucu",Aciklama:"45kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:45000,Gerilim:380};
 OGUTUCU[11]={Tur:"Ogutucu",Aciklama:"55kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:55000,Gerilim:380};
 OGUTUCU[12]={Tur:"Ogutucu",Aciklama:"75kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:75000,Gerilim:380};
 OGUTUCU[13]={Tur:"Ogutucu",Aciklama:"90kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:90000,Gerilim:380};
 OGUTUCU[14]={Tur:"Ogutucu",Aciklama:"110kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:110000,Gerilim:380};
 OGUTUCU[15]={Tur:"Ogutucu",Aciklama:"150kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:150000,Gerilim:380};
 OGUTUCU[16]={Tur:"Ogutucu",Aciklama:"185kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:185000,Gerilim:380};
 OGUTUCU[17]={Tur:"Ogutucu",Aciklama:"220kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:220000,Gerilim:380};
 OGUTUCU[18]={Tur:"Ogutucu",Aciklama:"250kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:250000,Gerilim:380};
 OGUTUCU[19]={Tur:"Ogutucu",Aciklama:"300kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:300000,Gerilim:380};
 OGUTUCU[20]={Tur:"Ogutucu",Aciklama:"330kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:330000,Gerilim:380};
 OGUTUCU[21]={Tur:"Ogutucu",Aciklama:"370kW 3F ÖĞÜTÜCÜ",Faz:1,CosFi:0.8,Guc:375000,Gerilim:380};
 
 export const  BANT=[];
 BANT[0]= {Tur:"Bant",Aciklama:"500W 1F BANT",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 BANT[1]= {Tur:"Bant",Aciklama:"750W 1F BANT",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 BANT[2]= {Tur:"Bant",Aciklama:"1100W 1F BANT",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 BANT[3]= {Tur:"Bant",Aciklama:"1500W 1F BANT",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 BANT[4]= {Tur:"Bant",Aciklama:"2200W 1F BANT",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 BANT[5]= {Tur:"Bant",Aciklama:"3700W 1F BANT",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 BANT[6]= {Tur:"Bant",Aciklama:"500W 1F BANT",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 BANT[7]= {Tur:"Bant",Aciklama:"7500W 3F BANT",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 BANT[8]= {Tur:"Bant",Aciklama:"1100W 3F BANT",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 BANT[9]= {Tur:"Bant",Aciklama:"1500W 3F BANT",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 BANT[10]={Tur:"Bant",Aciklama:"2200W 3F BANT",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 BANT[11]={Tur:"Bant",Aciklama:"3700W 3F BANT",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 BANT[12]={Tur:"Bant",Aciklama:"5500W 3F BANT",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 BANT[13]={Tur:"Bant",Aciklama:"7500W 3F BANT",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 BANT[14]={Tur:"Bant",Aciklama:"11kW 3F BANT",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 BANT[15]={Tur:"Bant",Aciklama:"15kW 3F BANT",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 BANT[16]={Tur:"Bant",Aciklama:"18.5kW 3F BANT",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 BANT[17]={Tur:"Bant",Aciklama:"22kW 3F BANT",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 BANT[18]={Tur:"Bant",Aciklama:"30kW 3F BANT",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 BANT[19]={Tur:"Bant",Aciklama:"37kW 3F BANT",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 BANT[20]={Tur:"Bant",Aciklama:"45kW 3F BANT",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 BANT[21]={Tur:"Bant",Aciklama:"55kW 3F BANT",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 BANT[22]={Tur:"Bant",Aciklama:"75kW 3F BANT",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 BANT[23]={Tur:"Bant",Aciklama:"90kW 3F BANT",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 export const  HIDROFOR=[];
 HIDROFOR[0]= {Tur:"Hidrofor",Aciklama:"500W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 HIDROFOR[1]= {Tur:"Hidrofor",Aciklama:"750W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 HIDROFOR[2]= {Tur:"Hidrofor",Aciklama:"1100W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 HIDROFOR[3]= {Tur:"Hidrofor",Aciklama:"1500W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 HIDROFOR[4]= {Tur:"Hidrofor",Aciklama:"2200W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 HIDROFOR[5]= {Tur:"Hidrofor",Aciklama:"3700W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 HIDROFOR[6]= {Tur:"Hidrofor",Aciklama:"500W 1F HİDROFOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 HIDROFOR[7]= {Tur:"Hidrofor",Aciklama:"7500W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 HIDROFOR[8]= {Tur:"Hidrofor",Aciklama:"1100W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 HIDROFOR[9]= {Tur:"Hidrofor",Aciklama:"1500W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 HIDROFOR[10]={Tur:"Hidrofor",Aciklama:"2200W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 HIDROFOR[11]={Tur:"Hidrofor",Aciklama:"3700W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 HIDROFOR[12]={Tur:"Hidrofor",Aciklama:"5500W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 HIDROFOR[13]={Tur:"Hidrofor",Aciklama:"7500W 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 HIDROFOR[14]={Tur:"Hidrofor",Aciklama:"11kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 HIDROFOR[15]={Tur:"Hidrofor",Aciklama:"15kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 HIDROFOR[16]={Tur:"Hidrofor",Aciklama:"18.5kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 HIDROFOR[17]={Tur:"Hidrofor",Aciklama:"22kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 HIDROFOR[18]={Tur:"Hidrofor",Aciklama:"30kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 HIDROFOR[19]={Tur:"Hidrofor",Aciklama:"37kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 HIDROFOR[20]={Tur:"Hidrofor",Aciklama:"45kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 HIDROFOR[21]={Tur:"Hidrofor",Aciklama:"55kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 HIDROFOR[22]={Tur:"Hidrofor",Aciklama:"75kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 HIDROFOR[23]={Tur:"Hidrofor",Aciklama:"90kW 3F HİDROFOR",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 export const  HELEZON=[];
 HELEZON[0]= {Tur:"Helezon",Aciklama:"500W 1F HELEZON",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 HELEZON[1]= {Tur:"Helezon",Aciklama:"750W 1F HELEZON",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 HELEZON[2]= {Tur:"Helezon",Aciklama:"1100W 1F HELEZON",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 HELEZON[3]= {Tur:"Helezon",Aciklama:"1500W 1F HELEZON",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 HELEZON[4]= {Tur:"Helezon",Aciklama:"2200W 1F HELEZON",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 HELEZON[5]= {Tur:"Helezon",Aciklama:"3700W 1F HELEZON",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 HELEZON[6]= {Tur:"Helezon",Aciklama:"500W 1F HELEZON",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 HELEZON[7]= {Tur:"Helezon",Aciklama:"7500W 3F HELEZON",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 HELEZON[8]= {Tur:"Helezon",Aciklama:"1100W 3F HELEZON",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 HELEZON[9]= {Tur:"Helezon",Aciklama:"1500W 3F HELEZON",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 HELEZON[10]={Tur:"Helezon",Aciklama:"2200W 3F HELEZON",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 HELEZON[11]={Tur:"Helezon",Aciklama:"3700W 3F HELEZON",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 HELEZON[12]={Tur:"Helezon",Aciklama:"5500W 3F HELEZON",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 HELEZON[13]={Tur:"Helezon",Aciklama:"7500W 3F HELEZON",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 HELEZON[14]={Tur:"Helezon",Aciklama:"11kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 HELEZON[15]={Tur:"Helezon",Aciklama:"15kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 HELEZON[16]={Tur:"Helezon",Aciklama:"18.5kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 HELEZON[17]={Tur:"Helezon",Aciklama:"22kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 HELEZON[18]={Tur:"Helezon",Aciklama:"30kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 HELEZON[19]={Tur:"Helezon",Aciklama:"37kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 HELEZON[20]={Tur:"Helezon",Aciklama:"45kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 HELEZON[21]={Tur:"Helezon",Aciklama:"55kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 HELEZON[22]={Tur:"Helezon",Aciklama:"75kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 HELEZON[23]={Tur:"Helezon",Aciklama:"90kW 3F HELEZON",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 export const  ELEVATOR=[];
 ELEVATOR[0]= {Tur:"Elevator",Aciklama:"500W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 ELEVATOR[1]= {Tur:"Elevator",Aciklama:"750W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 ELEVATOR[2]= {Tur:"Elevator",Aciklama:"1100W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 ELEVATOR[3]= {Tur:"Elevator",Aciklama:"1500W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 ELEVATOR[4]= {Tur:"Elevator",Aciklama:"2200W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 ELEVATOR[5]= {Tur:"Elevator",Aciklama:"3700W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 ELEVATOR[6]= {Tur:"Elevator",Aciklama:"500W 1F ELEVATOR",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 ELEVATOR[7]= {Tur:"Elevator",Aciklama:"7500W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 ELEVATOR[8]= {Tur:"Elevator",Aciklama:"1100W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 ELEVATOR[9]= {Tur:"Elevator",Aciklama:"1500W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 ELEVATOR[10]={Tur:"Elevator",Aciklama:"2200W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 ELEVATOR[11]={Tur:"Elevator",Aciklama:"3700W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 ELEVATOR[12]={Tur:"Elevator",Aciklama:"5500W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 ELEVATOR[13]={Tur:"Elevator",Aciklama:"7500W 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 ELEVATOR[14]={Tur:"Elevator",Aciklama:"11kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 ELEVATOR[15]={Tur:"Elevator",Aciklama:"15kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 ELEVATOR[16]={Tur:"Elevator",Aciklama:"18.5kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 ELEVATOR[17]={Tur:"Elevator",Aciklama:"22kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 ELEVATOR[18]={Tur:"Elevator",Aciklama:"30kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 ELEVATOR[19]={Tur:"Elevator",Aciklama:"37kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 ELEVATOR[20]={Tur:"Elevator",Aciklama:"45kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 ELEVATOR[21]={Tur:"Elevator",Aciklama:"55kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 ELEVATOR[22]={Tur:"Elevator",Aciklama:"75kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 ELEVATOR[23]={Tur:"Elevator",Aciklama:"90kW 3F ELEVATOR",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 export const  TERMOSIFON=[];
 TERMOSIFON[0]= {Tur:"Termosifon",Aciklama:"500W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 TERMOSIFON[1]= {Tur:"Termosifon",Aciklama:"750W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 TERMOSIFON[2]= {Tur:"Termosifon",Aciklama:"1100W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 TERMOSIFON[3]= {Tur:"Termosifon",Aciklama:"1500W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 TERMOSIFON[4]= {Tur:"Termosifon",Aciklama:"2200W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 TERMOSIFON[5]= {Tur:"Termosifon",Aciklama:"3700W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 TERMOSIFON[6]= {Tur:"Termosifon",Aciklama:"500W 1F TERMOSIFON",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 TERMOSIFON[7]= {Tur:"Termosifon",Aciklama:"7500W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 TERMOSIFON[8]= {Tur:"Termosifon",Aciklama:"1100W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 TERMOSIFON[9]= {Tur:"Termosifon",Aciklama:"1500W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 TERMOSIFON[10]={Tur:"Termosifon",Aciklama:"2200W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 TERMOSIFON[11]={Tur:"Termosifon",Aciklama:"3700W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 TERMOSIFON[12]={Tur:"Termosifon",Aciklama:"5500W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 TERMOSIFON[13]={Tur:"Termosifon",Aciklama:"7500W 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 TERMOSIFON[14]={Tur:"Termosifon",Aciklama:"11kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 TERMOSIFON[15]={Tur:"Termosifon",Aciklama:"15kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 TERMOSIFON[16]={Tur:"Termosifon",Aciklama:"18.5kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 TERMOSIFON[17]={Tur:"Termosifon",Aciklama:"22kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 TERMOSIFON[18]={Tur:"Termosifon",Aciklama:"30kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 TERMOSIFON[19]={Tur:"Termosifon",Aciklama:"37kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 TERMOSIFON[20]={Tur:"Termosifon",Aciklama:"45kW 3F TERMOSIFON",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 
 export const  TASIYICI=[];
 TASIYICI[0]= {Tur:"Tasiyici",Aciklama:"500W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 TASIYICI[1]= {Tur:"Tasiyici",Aciklama:"750W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 TASIYICI[2]= {Tur:"Tasiyici",Aciklama:"1100W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 TASIYICI[3]= {Tur:"Tasiyici",Aciklama:"1500W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 TASIYICI[4]= {Tur:"Tasiyici",Aciklama:"2200W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 TASIYICI[5]= {Tur:"Tasiyici",Aciklama:"3700W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 TASIYICI[6]= {Tur:"Tasiyici",Aciklama:"500W 1F TASIYICI",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 TASIYICI[7]= {Tur:"Tasiyici",Aciklama:"7500W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 TASIYICI[8]= {Tur:"Tasiyici",Aciklama:"1100W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 TASIYICI[9]= {Tur:"Tasiyici",Aciklama:"1500W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 TASIYICI[10]={Tur:"Tasiyici",Aciklama:"2200W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 TASIYICI[11]={Tur:"Tasiyici",Aciklama:"3700W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 TASIYICI[12]={Tur:"Tasiyici",Aciklama:"5500W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 TASIYICI[13]={Tur:"Tasiyici",Aciklama:"7500W 3F TASIYICI",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 TASIYICI[14]={Tur:"Tasiyici",Aciklama:"11kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 TASIYICI[15]={Tur:"Tasiyici",Aciklama:"15kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 TASIYICI[16]={Tur:"Tasiyici",Aciklama:"18.5kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 TASIYICI[17]={Tur:"Tasiyici",Aciklama:"22kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 TASIYICI[18]={Tur:"Tasiyici",Aciklama:"30kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 TASIYICI[19]={Tur:"Tasiyici",Aciklama:"37kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 TASIYICI[20]={Tur:"Tasiyici",Aciklama:"45kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 TASIYICI[21]={Tur:"Tasiyici",Aciklama:"55kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 TASIYICI[22]={Tur:"Tasiyici",Aciklama:"75kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 TASIYICI[23]={Tur:"Tasiyici",Aciklama:"90kW 3F TASIYICI",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 export const  KARISTIRICI=[];
 KARISTIRICI[0]= {Tur:"Karıştırıcı",Aciklama:"500W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 KARISTIRICI[1]= {Tur:"Karıştırıcı",Aciklama:"750W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:750,Gerilim:220};
 KARISTIRICI[2]= {Tur:"Karıştırıcı",Aciklama:"1100W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:1100,Gerilim:220};
 KARISTIRICI[3]= {Tur:"Karıştırıcı",Aciklama:"1500W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 KARISTIRICI[4]= {Tur:"Karıştırıcı",Aciklama:"2200W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:2200,Gerilim:220};
 KARISTIRICI[5]= {Tur:"Karıştırıcı",Aciklama:"3700W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:3700,Gerilim:220};
 KARISTIRICI[6]= {Tur:"Karıştırıcı",Aciklama:"500W 1F KARIŞTIRICI",Faz:1,CosFi:0.8,Guc:500,Gerilim:380};
 KARISTIRICI[7]= {Tur:"Karıştırıcı",Aciklama:"7500W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:750,Gerilim:380};
 KARISTIRICI[8]= {Tur:"Karıştırıcı",Aciklama:"1100W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:1100,Gerilim:380};
 KARISTIRICI[9]= {Tur:"Karıştırıcı",Aciklama:"1500W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:1500,Gerilim:380};
 KARISTIRICI[10]={Tur:"Karıştırıcı",Aciklama:"2200W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:2200,Gerilim:380};
 KARISTIRICI[11]={Tur:"Karıştırıcı",Aciklama:"3700W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:3700,Gerilim:380};
 KARISTIRICI[12]={Tur:"Karıştırıcı",Aciklama:"5500W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:5500,Gerilim:380};
 KARISTIRICI[13]={Tur:"Karıştırıcı",Aciklama:"7500W 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 KARISTIRICI[14]={Tur:"Karıştırıcı",Aciklama:"11kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 KARISTIRICI[15]={Tur:"Karıştırıcı",Aciklama:"15kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 KARISTIRICI[16]={Tur:"Karıştırıcı",Aciklama:"18.5kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:18500,Gerilim:380};
 KARISTIRICI[17]={Tur:"Karıştırıcı",Aciklama:"22kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 KARISTIRICI[18]={Tur:"Karıştırıcı",Aciklama:"30kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 KARISTIRICI[19]={Tur:"Karıştırıcı",Aciklama:"37kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:37000,Gerilim:380};
 KARISTIRICI[20]={Tur:"Karıştırıcı",Aciklama:"45kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:45000,Gerilim:380};
 KARISTIRICI[21]={Tur:"Karıştırıcı",Aciklama:"55kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:55000,Gerilim:380};
 KARISTIRICI[22]={Tur:"Karıştırıcı",Aciklama:"75kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:75000,Gerilim:380};
 KARISTIRICI[23]={Tur:"Karıştırıcı",Aciklama:"90kW 3F KARIŞTIRICI",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 
 
 export const  AYDINLATMA=[];
 AYDINLATMA[0]={Tur:"Aydinlatma",Aciklama:"50W Aydınlatma",Faz:1,CosFi:0.8,Guc:50,Gerilim:220};
 AYDINLATMA[1]={Tur:"Aydinlatma",Aciklama:"100W Aydınlatma",Faz:1,CosFi:0.8,Guc:100,Gerilim:220};
 AYDINLATMA[2]={Tur:"Aydinlatma",Aciklama:"150W Aydınlatma",Faz:1,CosFi:0.8,Guc:150,Gerilim:220};
 AYDINLATMA[3]={Tur:"Aydinlatma",Aciklama:"200W Aydınlatma",Faz:1,CosFi:0.8,Guc:200,Gerilim:220};
 AYDINLATMA[4]={Tur:"Aydinlatma",Aciklama:"250W Aydınlatma",Faz:1,CosFi:0.8,Guc:250,Gerilim:220};
 AYDINLATMA[5]={Tur:"Aydinlatma",Aciklama:"300W Aydınlatma",Faz:1,CosFi:0.8,Guc:300,Gerilim:220};
 AYDINLATMA[6]={Tur:"Aydinlatma",Aciklama:"350W Aydınlatma",Faz:1,CosFi:0.8,Guc:350,Gerilim:220};
 AYDINLATMA[7]={Tur:"Aydinlatma",Aciklama:"400W Aydınlatma",Faz:1,CosFi:0.8,Guc:400,Gerilim:220};
 AYDINLATMA[8]={Tur:"Aydinlatma",Aciklama:"450W Aydınlatma",Faz:1,CosFi:0.8,Guc:450,Gerilim:220};
 AYDINLATMA[9]={Tur:"Aydinlatma",Aciklama:"500W Aydınlatma",Faz:1,CosFi:0.8,Guc:500,Gerilim:220};
 AYDINLATMA[10]={Tur:"Aydinlatma",Aciklama:"600W Aydınlatma",Faz:1,CosFi:0.8,Guc:600,Gerilim:220};
 AYDINLATMA[11]={Tur:"Aydinlatma",Aciklama:"700W Aydınlatma",Faz:1,CosFi:0.8,Guc:700,Gerilim:220};
 AYDINLATMA[12]={Tur:"Aydinlatma",Aciklama:"800W Aydınlatma",Faz:1,CosFi:0.8,Guc:800,Gerilim:220};
 AYDINLATMA[13]={Tur:"Aydinlatma",Aciklama:"900W Aydınlatma",Faz:1,CosFi:0.8,Guc:900,Gerilim:220};
 AYDINLATMA[14]={Tur:"Aydinlatma",Aciklama:"1000W Aydınlatma",Faz:1,CosFi:0.8,Guc:1000,Gerilim:220};
 AYDINLATMA[15]={Tur:"Aydinlatma",Aciklama:"2000W Aydınlatma",Faz:1,CosFi:0.8,Guc:2000,Gerilim:220};
 AYDINLATMA[16]={Tur:"Aydinlatma",Aciklama:"3 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:3000,Gerilim:380};
 AYDINLATMA[17]={Tur:"Aydinlatma",Aciklama:"4 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:4000,Gerilim:380};
 AYDINLATMA[18]={Tur:"Aydinlatma",Aciklama:"5 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:5000,Gerilim:380};
 AYDINLATMA[19]={Tur:"Aydinlatma",Aciklama:"6 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:6000,Gerilim:380};
 AYDINLATMA[20]={Tur:"Aydinlatma",Aciklama:"7 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:7000,Gerilim:380};
 AYDINLATMA[21]={Tur:"Aydinlatma",Aciklama:"8 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:8000,Gerilim:380};
 AYDINLATMA[22]={Tur:"Aydinlatma",Aciklama:"9 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:9000,Gerilim:380};
 AYDINLATMA[23]={Tur:"Aydinlatma",Aciklama:"10 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:10000,Gerilim:380};
 AYDINLATMA[24]={Tur:"Aydinlatma",Aciklama:"15 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 AYDINLATMA[25]={Tur:"Aydinlatma",Aciklama:"20 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:20000,Gerilim:380};
 AYDINLATMA[26]={Tur:"Aydinlatma",Aciklama:"25 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:25000,Gerilim:380};
 AYDINLATMA[27]={Tur:"Aydinlatma",Aciklama:"30 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 AYDINLATMA[28]={Tur:"Aydinlatma",Aciklama:"35 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:35000,Gerilim:380};
 AYDINLATMA[29]={Tur:"Aydinlatma",Aciklama:"40 kW Aydınlatma PANO",Faz:3,CosFi:0.8,Guc:40000,Gerilim:380};
  
 export const  KAZAN=[];
 KAZAN[0]= {Tur:"Kazan",Aciklama:"1500W 1F KAZAN",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 KAZAN[1]= {Tur:"Kazan",Aciklama:"2000W 1F KAZAN",Faz:1,CosFi:0.8,Guc:2000,Gerilim:220};
 KAZAN[2]= {Tur:"Kazan",Aciklama:"2500W 1F KAZAN",Faz:1,CosFi:0.8,Guc:2500,Gerilim:220};
 KAZAN[3]= {Tur:"Kazan",Aciklama:"3000W 1F KAZAN",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 KAZAN[4]= {Tur:"Kazan",Aciklama:"3500W 3F KAZAN",Faz:3,CosFi:0.8,Guc:3500,Gerilim:380};
 KAZAN[5]= {Tur:"Kazan",Aciklama:"4000W 3F KAZAN",Faz:3,CosFi:0.8,Guc:4000,Gerilim:380};
 KAZAN[6]= {Tur:"Kazan",Aciklama:"5000W 3F KAZAN",Faz:3,CosFi:0.8,Guc:5000,Gerilim:380};
 KAZAN[7]= {Tur:"Kazan",Aciklama:"7500W 3F KAZAN",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 KAZAN[8]= {Tur:"Kazan",Aciklama:"10kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:10000,Gerilim:380};
 KAZAN[9]= {Tur:"Kazan",Aciklama:"11kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 KAZAN[10]={Tur:"Kazan",Aciklama:"12kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:12000,Gerilim:380};
 KAZAN[11]={Tur:"Kazan",Aciklama:"13kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:13000,Gerilim:380};
 KAZAN[12]={Tur:"Kazan",Aciklama:"14kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:14000,Gerilim:380};
 KAZAN[13]={Tur:"Kazan",Aciklama:"15kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 KAZAN[14]={Tur:"Kazan",Aciklama:"18kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:18000,Gerilim:380};
 KAZAN[15]={Tur:"Kazan",Aciklama:"20kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:20000,Gerilim:380};
 KAZAN[16]={Tur:"Kazan",Aciklama:"22kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 KAZAN[17]={Tur:"Kazan",Aciklama:"25kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:25000,Gerilim:380};
 KAZAN[18]={Tur:"Kazan",Aciklama:"30kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 KAZAN[19]={Tur:"Kazan",Aciklama:"35kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:35000,Gerilim:380};
 KAZAN[20]={Tur:"Kazan",Aciklama:"40kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:40000,Gerilim:380};
 KAZAN[21]={Tur:"Kazan",Aciklama:"50kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:50000,Gerilim:380};
 KAZAN[22]={Tur:"Kazan",Aciklama:"60kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:60000,Gerilim:380};
 KAZAN[23]={Tur:"Kazan",Aciklama:"70kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:70000,Gerilim:380};
 KAZAN[24]={Tur:"Kazan",Aciklama:"80kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:80000,Gerilim:380};
 KAZAN[25]={Tur:"Kazan",Aciklama:"90kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 KAZAN[26]={Tur:"Kazan",Aciklama:"100kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:100000,Gerilim:380};
 KAZAN[27]={Tur:"Kazan",Aciklama:"110kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 KAZAN[28]={Tur:"Kazan",Aciklama:"120kW 3F KAZAN",Faz:3,CosFi:0.8,Guc:120000,Gerilim:380};
 
 export const  FIRIN=[];
 FIRIN[0]= {Tur:"Fırın",Aciklama:"1500W 1F FIRIN",Faz:1,CosFi:0.8,Guc:1500,Gerilim:220};
 FIRIN[1]= {Tur:"Fırın",Aciklama:"2000W 1F FIRIN",Faz:1,CosFi:0.8,Guc:2000,Gerilim:220};
 FIRIN[2]= {Tur:"Fırın",Aciklama:"2500W 1F FIRIN",Faz:1,CosFi:0.8,Guc:2500,Gerilim:220};
 FIRIN[3]= {Tur:"Fırın",Aciklama:"3000W 1F FIRIN",Faz:1,CosFi:0.8,Guc:3000,Gerilim:380};
 FIRIN[4]= {Tur:"Fırın",Aciklama:"3500W 3F FIRIN",Faz:3,CosFi:0.8,Guc:3500,Gerilim:380};
 FIRIN[5]= {Tur:"Fırın",Aciklama:"4000W 3F FIRIN",Faz:3,CosFi:0.8,Guc:4000,Gerilim:380};
 FIRIN[6]= {Tur:"Fırın",Aciklama:"5000W 3F FIRIN",Faz:3,CosFi:0.8,Guc:5000,Gerilim:380};
 FIRIN[7]= {Tur:"Fırın",Aciklama:"7500W 3F FIRIN",Faz:3,CosFi:0.8,Guc:7500,Gerilim:380};
 FIRIN[8]= {Tur:"Fırın",Aciklama:"10kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:10000,Gerilim:380};
 FIRIN[9]= {Tur:"Fırın",Aciklama:"11kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:11000,Gerilim:380};
 FIRIN[10]={Tur:"Fırın",Aciklama:"12kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:12000,Gerilim:380};
 FIRIN[11]={Tur:"Fırın",Aciklama:"13kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:13000,Gerilim:380};
 FIRIN[12]={Tur:"Fırın",Aciklama:"14kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:14000,Gerilim:380};
 FIRIN[13]={Tur:"Fırın",Aciklama:"15kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:15000,Gerilim:380};
 FIRIN[14]={Tur:"Fırın",Aciklama:"18kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:18000,Gerilim:380};
 FIRIN[15]={Tur:"Fırın",Aciklama:"20kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:20000,Gerilim:380};
 FIRIN[16]={Tur:"Fırın",Aciklama:"22kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:22000,Gerilim:380};
 FIRIN[17]={Tur:"Fırın",Aciklama:"25kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:25000,Gerilim:380};
 FIRIN[18]={Tur:"Fırın",Aciklama:"30kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:30000,Gerilim:380};
 FIRIN[19]={Tur:"Fırın",Aciklama:"35kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:35000,Gerilim:380};
 FIRIN[20]={Tur:"Fırın",Aciklama:"40kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:40000,Gerilim:380};
 FIRIN[21]={Tur:"Fırın",Aciklama:"50kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:50000,Gerilim:380};
 FIRIN[22]={Tur:"Fırın",Aciklama:"60kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:60000,Gerilim:380};
 FIRIN[23]={Tur:"Fırın",Aciklama:"70kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:70000,Gerilim:380};
 FIRIN[24]={Tur:"Fırın",Aciklama:"80kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:80000,Gerilim:380};
 FIRIN[25]={Tur:"Fırın",Aciklama:"90kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:90000,Gerilim:380};
 FIRIN[26]={Tur:"Fırın",Aciklama:"100kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:100000,Gerilim:380};
 FIRIN[27]={Tur:"Fırın",Aciklama:"110kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:110000,Gerilim:380};
 FIRIN[28]={Tur:"Fırın",Aciklama:"120kW 3F FIRIN",Faz:3,CosFi:0.8,Guc:120000,Gerilim:380};
 
 export const SARJUNITE=[]
 SARJUNITE[0]={Tur:"SarjUnitesi",Aciklama:"3.3kW 1F AC ŞARJ ÜNİTESİ",Faz:1,CosFi:0.8,Guc:3300,Gerilim:230};
 SARJUNITE[1]={Tur:"SarjUnitesi",Aciklama:"7kW 1F AC ŞARJ ÜNİTESİ",Faz:1,CosFi:0.8,Guc:7000,Gerilim:230};
 SARJUNITE[2]={Tur:"SarjUnitesi",Aciklama:"10kW 3F AC ŞARJ ÜNİTESİ",Faz:3,CosFi:0.8,Guc:10000,Gerilim:400};
 SARJUNITE[3]={Tur:"SarjUnitesi",Aciklama:"24kW 3F AC ŞARJ ÜNİTESİ",Faz:3,CosFi:0.8,Guc:24000,Gerilim:400};
 SARJUNITE[4]={Tur:"SarjUnitesi",Aciklama:"43kW 3F AC ŞARJ ÜNİTESİ",Faz:3,CosFi:0.8,Guc:43000,Gerilim:400};
 SARJUNITE[5]={Tur:"SarjUnitesi",Aciklama:"50kW DC ŞARJ ÜNİTESİ",Faz:3,CosFi:0.8,Guc:50000,Gerilim:400};

 
 
 
 
 
 
 