
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0;
 
let  denemeCizim=[]

export default function AGAkimHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetay  = useSelector((state) => state.PanoDetay)

    let U=400
    let Guc=trafoDetaylar.trafoGuckVA
    let kap=panoDetay.InisIletken.kap
    let Akim=(Guc*1000/(U*1.732)).toFixed(2)
    let InisIletken=panoDetay.InisIletken.Etiket
    let Text1="AG AKIM HESABI"
    let Text2= "İniş İletkeni=> Güç:"+Guc+" KVA  Akim:"+Akim+"A iletken:"+InisIletken+" AkimKap:"+kap+"A<"+Akim+"A \n"
    let Text3= " "
    let cikislarK=panoDetay.Cikislar

    const objectsMap = new Map();
 
    
    cikislarK.forEach((object) => {
          objectsMap.set(object.Akim, object);
        });
    const cikislar=Array.from(objectsMap)
    
        if(cikislar.length>0){
    for (var a in cikislar){
     
        Text3+=  cikislar[a][1].Aciklama+"=> Güç:"+cikislar[a][1].Guc+"W  Akim:"+cikislar[a][1].Akim+"A iletken:"+cikislar[a][1].Iletken+" AkimKap:"+cikislar[a][1].TopAkimKap+"A>"+cikislar[a][1].Akim+"A \n"
    }}
    Text2+=Text3
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;

    let hh=100
    
    if(cikislar.length>0){
        hh=131+(cikislar.length+1)*36 
     }
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk
    

    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:hh*Scl,                          
            },
            cizim:[{ Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (0), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (hh), 
                X + Scl * (0), Y + Scl * (hh)
            ] },{ Ad: "Text", layer: "0", Color:TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl * 15,align:"center", TextString:Text1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl * 15,align:"justify", TextString:Text2, TextStyleName: "  1", TextArc: 0,width:400*Scl }]
            
            
        })); 

    }
    useEffect(() => {       
        artirW(400)
        
    }, [cizimOzellik,trafoDetaylar,panoDetay]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
 
    return ( <></>   )

}
