
import React, { useEffect, useState } from 'react'
import { Form, Table, Modal, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {UpdateOGHatMetre,UpdateOGHat,UpdateDirek,UpdateDirekTur} from '../reducers/direkDetaySlice'
import {DEMIRDIREK} from '../Data/Malzeme'
import { DataGrid } from '@mui/x-data-grid';


export default function TrafoDirekDetay(props) {
  const direkDetay=useSelector((state)=>state.direkDetay)
  const trafoDetaylar = useSelector((state) => state.trafoDetay)

  const dispatch=useDispatch()
  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");
  const [parafudr,setParafudr]=useState("yok");
  const [seksiyoner,setSeksiyoner]=useState(direkDetay.seksiyoner);
  const [direktur,setDirekTur]=useState("demir");
  const [direk,setDirek]=useState("T15");
  const [direkList,setDirekList]=useState([0,1,2,3,4,5,6,7]);
  
 
 
  function Show() { setToolShow(!toolshow) }

  useEffect(() => {

  }, [])
  useEffect(() => {
    switch(trafoDetaylar.trafoGuckVA){
      case 50: setDirekList([0,1,2,3,4,5,6,7]); break;
      case 100: setDirekList([0,1,2,3,4,5,6,7]); break;
      case 160: setDirekList([1,2,3,5,6,7]); break;
      case 200: setDirekList([1,2,3,5,6,7]); break;
      case 250: setDirekList([1,2,3,5,6,7]); break;
      case 315: setDirekList([2,3,6,7]); break;
      case 400: setDirekList([3,7]); break;
      default:
    }
    
  }, [trafoDetaylar])
  useEffect(() => {
    setDirek(DEMIRDIREK[direkList[0]].Ad)
    
  }, [direkList])
  useEffect(() => {
    dispatch(UpdateDirek(direk))
  }, [direk])
  useEffect(() => {
    dispatch(UpdateDirekTur(direktur))
  }, [direktur])

  useEffect(() => {
    setSeksiyoner(direkDetay.seksiyoner)
  }, [direkDetay])

  return (

    <React.Fragment >



      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Direk Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>
          <tr>
            <td>
              Direk Tipi
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={'demir'} onChange={e=>setDirekTur(e.target.value)}  >
                <option value="demir">Demir Direk</option>
                <option value="beton">Beton Direk</option>          
              </Form.Select>
            </td>
          </tr>
          {direktur==="demir"?
          <tr>
            <td>
              Direk  
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={'NYY'} onChange={e=>setDirek(e.currentTarget.value)} >
              {direkList.map((direk,i)=>(
                seksiyoner==="yok"?<option key={i} value={DEMIRDIREK[direkList[i]].Ad}>{DEMIRDIREK[direkList[i]].Ad}</option>:(i<=3?<option key={i} value={DEMIRDIREK[direkList[i]].Ad}>{DEMIRDIREK[direkList[i]].Ad}</option>:null)              

              ))}             
               
            </Form.Select>
            </td>
          </tr>       :null}
 
    

     
        </tbody>
      </Table>




    </React.Fragment>





  )

}
