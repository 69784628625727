import { createSlice } from '@reduxjs/toolkit'

export const KompSlice = createSlice({
  name: 'KompDetay',
  initialState:  {
  X:0,
  Y:-50,
  KompVarlik:"true",
  TalepGuc:1,
  CosFi1:0.8 ,
  CosFi2:0.99 ,
  K:0.61,
  KademeSayi:12,
  GrupSayi:4,
  Kademeler:[],
  Iletken:0,
  Kesici:{No: 2, Etiket: '3x16 A TMŞ'},
  KompGuc:1,
  SeciliKompGuc:1,
  Bara:"20x3",
  CikisAra:100,
  NHSinir:32
   
  
   
  } 
  ,
  reducers: {   
    
    UpdateTalepGuc: (state, action ) => {state.TalepGuc = action.payload},
    UpdateCosFi1: (state, action ) => {state.CosFi1 = action.payload},
    UpdateCosFi2: (state, action ) => {state.CosFi2 = action.payload},
    UpdateK: (state, action ) => {state.K = action.payload},
    UpdateKademeSayi: (state, action ) => {state.KademeSayi = action.payload},
    UpdateGrupSayi: (state, action ) => {state.GrupSayi = action.payload},
    UpdateKademeler: (state, action ) => {state.Kademeler = action.payload},
    UpdateIletken: (state, action ) => {state.Iletken = action.payload},
    UpdateKesici: (state, action ) => {state.Kesici = action.payload},
    UpdateKompGuc : (state, action ) => {state.KompGuc = action.payload},
    UpdateSecKompGuc : (state, action ) => {state.SeciliKompGuc = action.payload},
    UpdateBara: (state, action ) => {state.Bara = action.payload},
    UpdateCikisAra: (state, action ) => {state.CikisAra = action.payload},
    UpdateKompVarlik: (state, action ) => {state.KompVarlik = action.payload},
    UpdateNHSinir: (state, action ) => {state.NHSinir = action.payload},
   
     
    }
})

export const { UpdateTalepGuc,UpdateCosFi1,UpdateCosFi2,UpdateK,UpdateKademeSayi,UpdateGrupSayi,UpdateKademeler,UpdateIletken,UpdateBara,UpdateKesici,UpdateKompGuc,UpdateSecKompGuc,UpdateCikisAra,UpdateKompVarlik,UpdateNHSinir } = KompSlice.actions
export default KompSlice.reducer