
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0;
let  denemeCizim=[]



export default function AGgerDusHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetay  = useSelector((state) => state.PanoDetay)

    let U=400
    let Guc=trafoDetaylar.trafoGuckVA
    let mesafe=panoDetay.InisMesafe // trafoDetaylar.AGAkim;
    let sigma=56
    let kesit=panoDetay.InisIletken.Kesit
    let e1= ((200000*Guc*mesafe)/(sigma*U*U*kesit)).toFixed(4)

    let Text1="AG GERİLİM DÜŞÜMÜ HESABI"
    let Text2= "3Faz %e=(200xNxL)/(σxU²xS)    \n1Faz %e=(100xNxL)/(σxU²xS)    \nN         :Güç(W)\nL          :iletken uzunluğu(m)\nσ          :iletkenlik katsayısı (Bakır 56)\nU          :Gerilim(V)\nS          :Kesit(mm²)\nİniş İletkeni için N:"+Guc+"kW L:"+mesafe+"m  U:"+U+"V   S:"+kesit+"mm²\n %e1=(200x"+Guc+"x"+mesafe+")/("+sigma+"x"+U+"²x"+kesit+")\n %e1="+e1+"\n"
    let Text3= " "
   
     
    let cikislarK = panoDetay.Cikislar
 
    const objectsMap = new Map(); 
 
    
cikislarK.forEach((object) => {
      objectsMap.set(object.Guc*object.Mesafe/object.iletkenKesit, object);
    });
    const cikislar=Array.from(objectsMap)

    if(cikislar.length>0){

    for (var a in cikislar){       
        let faz=cikislar[a][1].Faz;
        let Uc=400;
        let fazKatSayi=200
        let mesafeCikis=cikislar[a][1].Mesafe
        if(faz==="3"){
            Uc=400;
            fazKatSayi=200
        }else{
            Uc=220;
            fazKatSayi=100
        }
       let No= (parseInt(a)+1)
       let e1x= ((fazKatSayi*1000*Guc*mesafeCikis)/(sigma*U*U*kesit)).toFixed(4)

    Text3+=  No+"-"+cikislar[a][1].Aciklama+"     N:"+cikislar[a][1].Guc+"W     L:"+mesafeCikis+"m     U:"+Uc+"V     S:"+cikislar[a][1].iletkenKesit+"mm²         \n %e1_"+No+"=("+fazKatSayi+"x"+cikislar[a][1].Guc+"x"+mesafeCikis+")/("+sigma+"x"+Uc+"²x"+cikislar[a][1].iletkenKesit+")  %e1="+e1x+"\nHat Sonu Toplam %E =%e1+%e1_"+No+"="+(parseFloat(e1)+parseFloat(e1x)).toFixed(4)+"\n\n"
    }
}

    Text2+=Text3
    const cizimOzellik = useSelector((state) => state.cizimOzellik)    
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
    let hh=300   
    if(cikislar.length>=1){
        hh=300+(cikislar.length)*72 
     }
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})  
   
    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:hh*Scl,                          
            },
            cizim:[{ Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (0), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (hh), 
                X + Scl * (0), Y + Scl * (hh)
            ] },{ Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (0.00), y: Y + Scl * (50), TextH: Scl * 15,align:"center", TextString:Text1, TextStyleName: "  1", TextArc: 0,width:500*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (30.00), y: Y + Scl * (100), TextH: Scl * 15,align:"justify", TextString:Text2, TextStyleName: "  1", TextArc: 0,width:485*Scl }]
            
            
        })); 

    }
    useEffect(() => {       
        artirW(400)        
    }, [cizimOzellik,trafoDetaylar,panoDetay]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
 
    return (        <> </>    )

}
