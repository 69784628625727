import React, { useEffect, useState } from 'react'
import { Tab, Row, Col, Nav, Table } from 'react-bootstrap'
import CheckIcon from '@mui/icons-material/Check';
 
import { useSelector, useDispatch } from 'react-redux'
import icerik from './icerikler'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
export default function Financials() {

  

  const lisan = useSelector((state) => state.admin.dil)

  const [brut, setBrut] = useState(40000);


  const [sigPirCalPay, setSigPirCalPay] = useState(5600);
  const [issizlikCalPay, setIssizlikCalPay] = useState(400);
  const [brutCalMaliyet, setBrutCalMaliyet] = useState(6000);
  const [netMaas, setNetMaas] = useState(34000);
  const [sigPirIsveren, setSigPirIsveren] = useState(8200);
  const [issizlikSigPrim, setIssizlikSigPrim] = useState(800);
  const [sgkTesvik, setSgkTesvik] = useState(2000);
  const [perMaliyet, setPerMaliyet] = useState(47000);
  const [yemekGun, setYemekGun] = useState(200);
  const [yemekAylik, setYemekAylik] = useState(4400);
  const [perToplamMaliyet, setPerToplamMaliyet] = useState(51400);
  const [ofis, setOfis] = useState(100000);
  const [bilgisayar, setBilgisayar] = useState(50000);
  const [bilAdet, setBilAdet] = useState(5);

  const [basDiger, setBasDiger] = useState(0);
  const [baslangicMaliyeti, setBaslangicMaliyeti] = useState(51400);
  const [aylikPersonel, setaylikPersonel] = useState(51400);
  const [kira, setKira] = useState(15000);
  const [fatura, setFatura] = useState(5000);
  const [aylikDiger, setAylikDiger] = useState(0);
  const [toplamAylikGider, setToplamAylikGider] = useState(277000);
  const [personelSayi, setPersonelSayi] = useState(5);
  const [ilkYilMaliyet, setIlkYilMaliyet] = useState(4271000);
 

  const personelMaas = (brut) => {
    setBrut(brut)
    setSigPirCalPay((brut * 0.14).toFixed(2))
    setIssizlikCalPay((brut * 0.01).toFixed(2))
    setBrutCalMaliyet((brut * 0.15).toFixed(2))
    setNetMaas((brut * 0.85).toFixed(2))
    setSigPirIsveren((brut * 0.205).toFixed(2))
    setIssizlikSigPrim((brut * 0.02).toFixed(2))
    setSgkTesvik((brut * 0.05).toFixed(2))
    setPerMaliyet(parseFloat((brut * 1.175).toFixed(2)))
    setPerToplamMaliyet(parseFloat((brut * 1.175).toFixed(2)) + parseFloat(yemekAylik))



  }

  useEffect(() => {

   }, [lisan])

  useEffect(() => {
    setYemekAylik(parseFloat((yemekGun * 22).toFixed(2)))
    setPerToplamMaliyet(perMaliyet + parseFloat((yemekGun * 22).toFixed(2)))

  }, [yemekGun])

  useEffect(() => {
    setBaslangicMaliyeti(ofis + bilgisayar * personelSayi + basDiger)

  }, [ofis, bilgisayar, basDiger, personelSayi])
  useEffect(() => {
    setToplamAylikGider(kira + fatura + aylikDiger + perToplamMaliyet * personelSayi)
    setIlkYilMaliyet((kira + fatura + aylikDiger + perToplamMaliyet * personelSayi) * 12 + (ofis + bilgisayar * personelSayi + basDiger))

  }, [kira, fatura, aylikDiger, personelSayi, perToplamMaliyet])

  const handleDropdownChange = (value) => {
    setPersonelSayi(value);
  };

  return (
    <div  ><br />
      {lisan === 'TR' ?
       <>  
 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
          <Nav   className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="maliyet" style={{color:"black",borderBottom:"1px solid #678"}}><h2>COST</h2></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="kazancmodeli"  style={{color:"black",borderBottom:"1px solid #678"}}><h2>REVENUE MODEL</h2></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} style={{ marginLeft: '25%' }}>
          <Tab.Content>
            <Tab.Pane eventKey="maliyet" style={{margin:"30px"}}>
            <h5>Startup Cost &nbsp;&nbsp;&nbsp;&nbsp;{baslangicMaliyeti} tl</h5>
                      <Row>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">  Office Startup Cost</InputGroup.Text>
                        <Form.Control
                          aria-label="Ofis Başlangıç Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setOfis(parseInt(e.target.value))}
                          value={ofis}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                        </Col>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">Cost of a Computer</InputGroup.Text>
                        <Form.Control
                          aria-label="Bir Bilgisayar Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setBilgisayar(parseInt(e.target.value))}
                          value={bilgisayar}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>

                        </Col>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">Other Startup Costs</InputGroup.Text>
                        <Form.Control
                          aria-label="Diğer Başlangıç Maliyetleri"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setBasDiger(parseInt(e.target.value))}
                          value={basDiger}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                        </Col>
                      </Row>
                      <Table striped bordered hover size="sm" >
                        <thead>
                        <tr><th style={{ textAlign: "left" }}>Expense type</th><th>Amount  </th></tr>
                        </thead>
                        <tbody>
                          <tr><td style={{ textAlign: "left" }}>Office (furniture etc.)</td><td>{ofis} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Computer{personelSayi}x{bilgisayar}tl</td><td>{personelSayi * bilgisayar} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Other</td><td>{basDiger} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Total Startup Cost</td><td><b>{baslangicMaliyeti} tl</b></td>   </tr>

                        </tbody>
                      </Table>

                      <hr/>
                <h5>Personnel Cost &nbsp;&nbsp;&nbsp;&nbsp; {perToplamMaliyet}tl </h5>
                Personnel Costs as software developer costs.<br/>
                <Row className="mb-3">
  <Col sm={2}>
    <InputGroup size="sm" className="mb-3">
      <InputGroup.Text id="inputGroup-sizing-sm">Gross salary(tl)</InputGroup.Text>
      <Form.Control
        aria-label="Brüt Ücret"
        aria-describedby="inputGroup-sizing-sm"
        onChange={(e) => personelMaas(e.target.value)}
        value={brut}
        style={{ width: "50px" }}
      />
    </InputGroup>
  </Col>
  <Col sm={3}>
    <InputGroup size="sm" className="mb-3">
      <InputGroup.Text id="inputGroup-sizing-sm">Daily Meal Fee</InputGroup.Text>
      <Form.Control
        aria-label="Yemek  Ücreti"
        aria-describedby="inputGroup-sizing-sm"
        onChange={(e) => setYemekGun(e.target.value)}
        value={yemekGun}
        style={{ width: "50px" }}
      />
    </InputGroup>
  </Col>
  <Col sm={3}>            
  <InputGroup className="mb-3" style={{ width: "250px" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Personel"
                    id="input-group-dropdown-1"
                  >

                    <Dropdown.Item onClick={() => handleDropdownChange(3)}>3</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(4)}>4</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(5)}>5</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(6)}>6</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(7)}>7</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(8)}>8</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(9)}>9</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(10)}>10</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control value={"Personel Sayısı " + personelSayi} />

                </InputGroup></Col>
</Row>
<Table striped bordered hover size="sm" >
                        <thead>
                          <tr><th style={{ textAlign: "left" }}>Expense type</th>
                          <th> Amount </th></tr>
                        </thead>
                        <tbody>
                          <tr><td style={{ textAlign: "left" }}>Gross salary</td>
                          <td>{brut} tl</td></tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Employee Contribution to Insurance Premium (14%)</td>
                            <td>{sigPirCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Employee Contribution to Unemployment Insurance (1%)</td>
                            <td>{issizlikCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Total Cost to Employee of Gross Salary
                            </td>
                            <td>{brutCalMaliyet} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Net salary</td><td><b>{netMaas} tl</b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Employer Contribution to Insurance Premium (20.5%)</td>
                            <td>{sigPirIsveren} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Unemployment Insurance Premium (2%)</td>
                            <td>{issizlikCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Employer Incentive for Social Security Contributions (5%)</td><td>{sgkTesvik} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Cost to Employer for 1 Employee</td><td>{perMaliyet} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Daily Food Cost {yemekGun} tl x 22 day</td><td>{yemekAylik} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Total Employer Cost for 1 Employee</td><td><b>{perToplamMaliyet}tl</b></td></tr>
                        </tbody>
                      </Table>
                      <hr/>

                      <h5>Monthly Expenses &nbsp;&nbsp;&nbsp;&nbsp;{toplamAylikGider} tl</h5>
                    <Row>
                      <Col xs={3}>                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm"> Monthly rent </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylık Kira "
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setKira(parseInt(e.target.value))}
                          value={kira}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                      </Col>
                      <Col xs={3}>           
                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm"> Monthly Bill Cost </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylik Fatura Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setFatura(parseInt(e.target.value))}
                          value={fatura}
                          style={{ width: "50px" }}
                        /> </InputGroup>
                        </Col>
                      <Col xs={3}>
                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">Other Monthly Costs  </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylık Diğer Maliyetler"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setAylikDiger(parseInt(e.target.value))}
                          value={aylikDiger}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>

                      </Col>
                    </Row>
                    <Table striped bordered hover size="sm" >
                        <thead>
                        <tr><th style={{ textAlign: "left" }}>Expense type</th>
                        <th>Amount</th>
                        <th>piece </th>
                        <th>total </th></tr>

                         </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "left" }}>Employee</td>
                          <td>{perToplamMaliyet} tl</td><td>{personelSayi}</td>
                          <td>{perToplamMaliyet * personelSayi}tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Rent</td><td>{kira} tl</td><td>1</td><td>{kira}tl</td>
                            </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Invoice</td><td>{fatura} tl</td><td>1</td><td>{fatura}tl</td></tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Other</td>
                            <td> {aylikDiger} tl </td><td>1</td>
                            <td>{aylikDiger}tl</td>   
                          </tr>
                          <tr><td style={{ textAlign: "left" }}>Total Monthly Expense</td><td><b> </b></td><td> </td><td><b>{toplamAylikGider}tl</b></td>   
                          </tr>

                        </tbody>
                      </Table>
                      <hr/>
                      <h5>First Year &nbsp;&nbsp;&nbsp;&nbsp;{ilkYilMaliyet} tl</h5>
                      <b> A Personnel Cost:{perToplamMaliyet}</b>tl 
                      &nbsp;&nbsp;&nbsp;&nbsp;Gross Salary:{brut} tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Daily Meal cost:{yemekGun} tl 
                      &nbsp;&nbsp;&nbsp;&nbsp;Total Number of Staff:{personelSayi}  
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>Other Startup Costs:{basDiger} tl</b>  
                      &nbsp;&nbsp;&nbsp;&nbsp;Startup Cost:{baslangicMaliyeti} tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Office Expenses:{ofis}  tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Computer Cost:{bilgisayar}  tl
                    
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>Total Monthly Expenses:{toplamAylikGider}tl</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;Rent:{kira}  tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Bills:{fatura}  tl
                       &nbsp;&nbsp;&nbsp;&nbsp;Other Monthly Expenses:{aylikDiger}tl
                      <Table striped bordered hover size="sm" >
                        <thead>
                          <tr><th style={{ textAlign: "left" }}>Month</th>
                          <th>Amound </th></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "left" }}>First Month</td>
                          <td>{toplamAylikGider + baslangicMaliyeti}tl</td>  
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Second Month </td>
                            <td>{toplamAylikGider} tl</td> 
                            </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>3rd month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>4th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>5th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>6th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>7th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>8th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>9th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>10th month</td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>11th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>12th month </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                
                          <tr>
                            <td style={{ textAlign: "left" }}>Total</td>
                            <td><b>{ilkYilMaliyet} tl</b></td>
                          </tr>


                        </tbody>
                      </Table>
            </Tab.Pane>
            <Tab.Pane eventKey="kazancmodeli" style={{margin:"30px"}}>
            <> Since ProjeHUB will host different disciplines and member groups, several different income methods are evaluated. We can list these from most likely to least expected.</>
            <p style={{ textAlign: "left" }}>
                    <ul>

                    <li><b>Project Membership sales:</b>Automatic Project Drawing Editors will be offered to the user with different features in the form of monthly or annual memberships. Different membership packages will be created for members according to different corporate structures such as Independent Engineer, Municipality, Building Inspection Company or Distribution Company, or there will be different packages according to the Project Drawing Editors used by the members. For example, a Project Company may want to use Project Drawing Editors in all disciplines. On the other hand, an architect or engineer working alone will want to use only Project Drawing Editors in his field. This also applies to HUB features. </li>
                       <li><b>HUB space (WAREHOUSE) sales:</b> WAREHOUSE space above a certain usage area will be offered to the user for a fee.</li>
                       <li><b>HUB Process Control System sales:</b> Apart from the basic Version Control Systems, more professional "Version Control Systems" can be developed and sold according to the specific needs of the users.</li>
                       <li><b> Advertisements :</b> There will be many different advertising opportunities on the application. <b>ProjeHUB</b> will be a suitable platform for manufacturers to reach customers during the project phase, especially in the free versions</li>
                     </ul>
                     Although the main sources of income are the ones listed above, e-signature, metadata, etc. will be required when the project is implemented. It will be possible to add many issues such as income sources. On the other hand, since <b>ProjeHUB</b> is a platform that plans to accommodate different engineering disciplines, it is necessary to consider its income sources according to different disciplines. Finally, <b>ProjeHUB</b> can be developed according to other country standards and global service sales can be made in foreign markets.
                     <p>In order to calculate the estimated income, only the Sale of Project Drawing Editors for the Electrical Discipline will be discussed here. </p>
                    </p>
                  <Table striped bordered hover size="sm" >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left",verticalAlign: "middle" }}>Member Group </th>
                      <th style={{  verticalAlign: "middle" }}>Estimated Number</th>
                      <th style={{ width: "130px" }}>Using Automatic Project Drawing Editor</th>
                      <th style={{ width: "130px", verticalAlign: "middle"  }}>HUB-STORE Use</th>
                      <th style={{ width: "130px" }}>HUB-PROCESS CONTROL Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Electrical Engineers</b></td>
                        <td> 5000+</td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Elektrik Fen Adamları</b></td>
                        <td> 5000 den çok daha fazla</td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>Elektrik Dağıtım Firmaları</b></td>
                        <td> 21</td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Belediyeler</b></td>
                        <td> yaklaşık 1000 </td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Yapı Denetim Firmaları</b></td>
                        <td> 2600</td>
                        <td style={{backgroundColor:"red"}}> --</td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                        <td style={{backgroundColor:"greenyellow"}}> <CheckIcon/></td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Diğer</b></td>
                        <td>bilinmiyor</td>
                        <td>  </td>
                        <td>  </td>
                        <td style={{backgroundColor:"red"}}> --</td>

                        </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b><h4>TOPLAM</h4></b></td>
                        <td> 13621'den fazla</td>
                        <td> 11000 </td><td> 13621 </td>
                        <td> 13621 </td>
                      </tr>


                       


                    </tbody>
                  </Table>
                  <hr/>
                  <h5>Purchasing Power, Estimated Price </h5>
                   Purchasing Power, Estimated Price
                   Let's consider a membership package that includes Drawing editors that automatically draw the Electrical Projects that are thought to make the most sales for Purchasing Power, Estimated Price. Let's look at the other expenses and payments of an engineer who is planned to receive this package.
                       <ul style={{ textAlign: "left" }}>
                         <li>EMO annual certificate renewal and dues (BT: 4000 TL, SMM: 4000 TL, annual dues: 540 TL) Total: 8540 TL / year</li>
                         <li>EMO project visas: 100 TL for projects under 50kW, 500 TL for projects above 50 kW (All Transformer Projects 500TL) </li>
                         <li>Municipal annual fees vary by municipality. </li>
                         <li>Blueprint expenses; A residential project has a total of 12 outputs in the Electricity, Telecom and Fire sheets. 400-500 TL for an average project. </li>
                         <li>Internet, phone, Youtube, Netflix etc. platform memberships.</li>
                       </ul>
                       The list in question is ordered from top to bottom according to their benefits to an engineer. Especially expenses such as EMO and Municipalities have no return on the engineer's side and are completely mandatory. The benefits of others can be mentioned relatively. If we make an estimate of the project costs based on this list alone, it is seen that the projector costs at least <b>1,000</b> TL per project outside of working hours. Considering other expenses and the benefits of these expenses, <b>1,000 TL/Month</b> may seem like a very reasonable fee for the basic membership package that will prepare the project and control the project processes. It should be kept in mind that this fee will be diversified with higher fees within the framework of different membership packages. However, to give an idea about the income estimate, calculations will be made based on <b>1,000</b> TL per month.                   <hr/>
                   <h5>Estimated Sales </h5>
                   <p style={{ textAlign: "justify" }}>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The total number of members who can receive Electricity Project packages is more than 13,000. In the worst case scenario, when paid membership is sold to 1,000 people, a monthly turnover of 1,000 people x 1,000 TL = 1 Million TL is achieved. If we consider the monthly expenses of 335,750 TL as 400,000 TL, including the expenses that have not been taken into account yet, such as server fees, the monthly net profit will be 600,000 TL in the worst-case scenario.</p>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only in the sale of Electrical Project Packages, every 1,000 paid members after the first 400 members means 1 Million TL income. If expenses such as server and customer representative are calculated as 100,000 TL for each 1,000 members, a profit of 900,000 TL is obtained from each 1,000 members.</p>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sadece Elektrik Proje Paketleri satışında pazarın yarısına satış yapıldığında bu 6.000 ücretli üye anlamına gelmektedir. Buradaki aylık kar; ilk bin üye için 600.000 TL diğer 5 bin üye için 5x900.000 = 4.500.0000 TL, toplamda ise 5.100.000 TL Kar anlamına gelmektedir.</p>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sales of HUB properties are not considered in the calculation here. Only 2,600 building inspection companies will be able to sell Automated Process Control and WAREHOUSE areas at prices much higher than 1,000 TL per month, between 5,000 and 10,000 TL per month. For this, ProjeHUB needs to become widespread. It should not be forgotten that building inspection companies are much more advantageous than other member groups in terms of purchasing power. </p>
                   <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To give an idea about pricing, the "B-pro" program used by 21 electricity distribution companies in our country and the contractor companies that do network manufacturing work for these companies to prepare their network projects costs 170,000 TL for the first purchase and 15,000 TL annually. They pay dues. This program is an application that can be developed with the “TrafoProje” editor infrastructure. Different pricing packages may be applied to members in this group. </p>
                     <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When the project editors are fully prepared and sold to all possible members such as municipalities, project designers, distribution companies, contractors, sales between 5,000,000 and 10,000,000 TL can be made in the electrical discipline. When HUB features are added here, this sales amount is expected to easily reach 10,000,000 TL. </p>
                     <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If ProjectHUB also provides services in other engineering disciplines such as Architecture and Mechanical, construction, etc., it seems likely that the 10M monthly profit in the Electrical Discipline alone will increase 4-5 times to the range of 40-50M. </p>
                     </p>
                     <hr></hr>
                     <p style={{ textAlign: "justify" }}>
                   
                     
                     <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A one-year development period is planned for ProjectHUB. </p>
                     
                    <Table striped bordered hover size="sm" >
                    <thead>
                      <tr><th style={{ textAlign: "left",verticalAlign: "middle" }}> </th><th style={{  verticalAlign: "middle" }}>transactions</th><th style={{ width: "130px" }}>Running Cost per Month</th><th style={{ width: "130px", verticalAlign: "middle"  }}>Monthly income</th><th style={{ width: "130px" }}>Monthly Profit</th></tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>first month</b></td>
                        <td> Establishment of Office and Team. Explaining the ProjeHUB concept to the team. Infrastructure and system design. Examination of CAD system and transformer project editor. </td>
                        <td> {toplamAylikGider + baslangicMaliyeti}tl</td>
                        <td> 0 tl</td>
                        <td> 0tl</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}> <b>Months 2-6</b></td>
                        <td>Development of CAD system and basic HUB features. Creation of first demos (Project Drawing Editors such as residences, transformer, construction site and irrigation) (Except floor plan drawings). Development of basic form and graphics infrastructure.</td>
                        <td>   {toplamAylikGider}tl</td>
                        <td> 0tl</td>
                        <td> 0tl</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Second 6 months</b></td>
                        <td> The first demos start to be released. Floor plan drawing editors began to be developed. Starting to receive user feedback. Beginning of Advertising and Marketing activities. Conducting pricing studies based on user data.</td>
                        <td>  {toplamAylikGider}tl</td>
                        <td> 0tl</td><td> 0tl</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Second Year </b></td>
                        <td> Integration of floor plan drawing editors with other editors. Start of paid sales of Project Drawing Editors. Increasing the number of project drawing editors. Conducting evaluations for developments in other disciplines. </td>
                        <td> {toplamAylikGider}tl</td>
                        <td> 1-5 M</td>
                        <td> 0.5-5 M tl</td>
                      </tr>        
                    </tbody>
                  </Table>
                  <b>ProjeHUB</b>'s Architecture and Mechanical, construction etc. If it also provides services in other engineering disciplines, it seems likely that the monthly profit of 10,000,000 TL in the electrical discipline alone will increase 4-5 times to 40,000,000-50,000,000 TL.
                     </p>

            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
      
       </> : 
      
      <>
 
 <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
          <Nav   className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="maliyet" style={{color:"black",borderBottom:"1px solid #678"}}><h2>MALİYET</h2></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="kazancmodeli"  style={{color:"black",borderBottom:"1px solid #678"}}><h2>KAZANÇ MODELİ</h2></Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9} style={{ marginLeft: '25%' }}>
          <Tab.Content>
            <Tab.Pane eventKey="maliyet" style={{margin:"30px"}}>



            <hr/>
                      <h5>Başlangıç Maliyeti &nbsp;&nbsp;&nbsp;&nbsp;{baslangicMaliyeti} tl</h5>
                      <Row>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">  Ofis Başlangıç Maliyeti </InputGroup.Text>
                        <Form.Control
                          aria-label="Ofis Başlangıç Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setOfis(parseInt(e.target.value))}
                          value={ofis}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                        </Col>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">  Bir Bilgisayar Maliyeti </InputGroup.Text>
                        <Form.Control
                          aria-label="Bir Bilgisayar Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setBilgisayar(parseInt(e.target.value))}
                          value={bilgisayar}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>

                        </Col>
                        <Col xs={3}>
                        <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm">Diğer Başlangıç Maliyetleri </InputGroup.Text>
                        <Form.Control
                          aria-label="Diğer Başlangıç Maliyetleri"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setBasDiger(parseInt(e.target.value))}
                          value={basDiger}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                        </Col>
                      </Row>
                      <Table striped bordered hover size="sm" >
                        <thead>
                        <tr><th style={{ textAlign: "left" }}>Gider türü</th><th>Miktar  </th></tr>
                        </thead>
                        <tbody>
                          <tr><td style={{ textAlign: "left" }}>Ofis (mobilyalar vb)</td><td>{ofis} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Bilgisayar{personelSayi}x{bilgisayar}tl</td><td>{personelSayi * bilgisayar} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Diğer</td><td>{basDiger} tl</td></tr>
                          <tr><td style={{ textAlign: "left" }}>Toplam Başlangıç Maliyeti</td><td><b>{baslangicMaliyeti} tl</b></td>   </tr>

                        </tbody>
                      </Table>




                      <hr/>


                <h5>Personel Maliyeti &nbsp;&nbsp;&nbsp;&nbsp; {perToplamMaliyet}tl </h5>
                Yazılımcı maliyeti olarak Personel Maliyetleri.<br/>
                <Row className="mb-3">
  <Col sm={2}>
    <InputGroup size="sm" className="mb-3">
      <InputGroup.Text id="inputGroup-sizing-sm">Brüt maaş(tl)</InputGroup.Text>
      <Form.Control
        aria-label="Brüt Ücret"
        aria-describedby="inputGroup-sizing-sm"
        onChange={(e) => personelMaas(e.target.value)}
        value={brut}
        style={{ width: "50px" }}
      />
    </InputGroup>
  </Col>
  <Col sm={3}>
    <InputGroup size="sm" className="mb-3">
      <InputGroup.Text id="inputGroup-sizing-sm">Günlük Yemek Ücreti(tl)</InputGroup.Text>
      <Form.Control
        aria-label="Yemek  Ücreti"
        aria-describedby="inputGroup-sizing-sm"
        onChange={(e) => setYemekGun(e.target.value)}
        value={yemekGun}
        style={{ width: "50px" }}
      />
    </InputGroup>
  </Col>
  <Col sm={3}>  
            <InputGroup className="mb-3" style={{ width: "250px" }}>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Personel"
                    id="input-group-dropdown-1"


                  >
                    <Dropdown.Item onClick={() => handleDropdownChange(3)}>3</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(4)}>4</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(5)}>5</Dropdown.Item>

                    <Dropdown.Item onClick={() => handleDropdownChange(6)}>6</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(7)}>7</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(8)}>8</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(9)}>9</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDropdownChange(10)}>10</Dropdown.Item>
                  </DropdownButton>
                  <Form.Control value={"Personel Sayısı " + personelSayi} />

                </InputGroup> </Col>
</Row>
<Table striped bordered hover size="sm" >
                        <thead>
                          <tr><th style={{ textAlign: "left" }}> Gider türü </th>
                          <th> Miktar </th></tr>
                        </thead>
                        <tbody>
                          <tr><td style={{ textAlign: "left" }}>Brüt maaş</td>
                          <td>{brut} tl</td></tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Sigorta Primi Çalışan Payı (%14)</td>
                            <td>{sigPirCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>İşsizlik Sigortası Çalışan Payı (%1)</td>
                            <td>{issizlikCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Brüt maaşın Çalışana maliyeti
                            </td>
                            <td>{brutCalMaliyet} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Net maaş</td><td><b>{netMaas} tl</b>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Sigorta Primi İşveren Payı (%20,5)</td>
                            <td>{sigPirIsveren} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>İşsizlik Sigortası Primi (%2)</td>
                            <td>{issizlikCalPay} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>SGK İşveren Teşviki (%5)</td><td>{sgkTesvik} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>1 personel işveren Maliyeti</td><td>{perMaliyet} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Yemek maliyeti günlük {yemekGun} tl x 22 gün</td><td>{yemekAylik} tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>1 personel işveren Toplam Maliyet</td><td><b>{perToplamMaliyet}tl</b></td></tr>
                        </tbody>
                      </Table>



                      <hr/>
                      <h5>Aylık Giderler &nbsp;&nbsp;&nbsp;&nbsp;{toplamAylikGider} tl</h5>
                    <Row>
                      <Col xs={3}>                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm"> Aylık Kira   </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylık Kira "
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setKira(parseInt(e.target.value))}
                          value={kira}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>
                      </Col>
                      <Col xs={3}>           
                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm"> Aylik Fatura Maliyeti </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylik Fatura Maliyeti"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setFatura(parseInt(e.target.value))}
                          value={fatura}
                          style={{ width: "50px" }}
                        /> </InputGroup>
                        </Col>
                      <Col xs={3}>
                      <InputGroup size="sm" className="mb-3" style={{ width: "250px" }}>
                        <InputGroup.Text id="inputGroup-sizing-sm"> Aylık Diğer Maliyetler  </InputGroup.Text>
                        <Form.Control
                          aria-label="Aylık Diğer Maliyetler"
                          aria-describedby="inputGroup-sizing-sm"
                          onChange={(e) => setAylikDiger(parseInt(e.target.value))}
                          value={aylikDiger}
                          style={{ width: "50px" }}
                        />
                      </InputGroup>

                      </Col>
                    </Row>
                    <Table striped bordered hover size="sm" >
                        <thead>
                        <tr><th style={{ textAlign: "left" }}> Gider türü </th>
                        <th>Miktar</th>
                        <th>adet </th>
                        <th>toplam </th></tr>

                         </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "left" }}>Personel</td>
                          <td>{perToplamMaliyet} tl</td><td>{personelSayi}</td>
                          <td>{perToplamMaliyet * personelSayi}tl</td>
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Kira</td><td>{kira} tl</td><td>1</td><td>{kira}tl</td>
                            </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Fatura</td><td>{fatura} tl</td><td>1</td><td>{fatura}tl</td></tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>Diğer</td>
                            <td> {aylikDiger} tl </td><td>1</td>
                            <td>{aylikDiger}tl</td>   
                          </tr>
                          <tr><td style={{ textAlign: "left" }}>Toplam Aylik Gider </td><td><b> </b></td><td> </td><td><b>{toplamAylikGider}tl</b></td>   
                          </tr>

                        </tbody>
                      </Table>
                      <hr/>
                      <h5>İlk Yıl   &nbsp;&nbsp;&nbsp;&nbsp;{ilkYilMaliyet} tl</h5>
                      <b> Bir Personel Maliyeti:{perToplamMaliyet}</b>tl 
                      &nbsp;&nbsp;&nbsp;&nbsp;Brüt Maaş:{brut} tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Günlük Yemek maliyeti:{yemekGun} tl 
                      &nbsp;&nbsp;&nbsp;&nbsp;Toplam Personel Sayısı:{personelSayi}  
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>Diğer Başlangıç Maliyetleri:{basDiger} tl</b>  
                      &nbsp;&nbsp;&nbsp;&nbsp;Başlangıç Maliyeti:{baslangicMaliyeti} tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Ofis Masrafları:{ofis}  tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Bilgisayar Maliyeti:{bilgisayar}  tl
                    
                      &nbsp;&nbsp;&nbsp;&nbsp;<b>Toplam Aylık Giderler:{toplamAylikGider}tl</b>
                      &nbsp;&nbsp;&nbsp;&nbsp;Kira:{kira}  tl
                      &nbsp;&nbsp;&nbsp;&nbsp;Faturalar:{fatura}  tl
                       &nbsp;&nbsp;&nbsp;&nbsp;Diğer Aylık Giderler:{aylikDiger}tl
                      <Table striped bordered hover size="sm" >
                        <thead>
                          <tr><th style={{ textAlign: "left" }}>Ay</th>
                          <th>Miktar </th></tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ textAlign: "left" }}>1.Ay</td>
                          <td>{toplamAylikGider + baslangicMaliyeti}tl</td>  
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>2.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                            </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>3.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>4.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>5.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>6.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>7.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>8.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>9.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>10.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>11.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                          <tr>
                            <td style={{ textAlign: "left" }}>12.Ay </td>
                            <td>{toplamAylikGider} tl</td> 
                          </tr>
                
                          <tr>
                            <td style={{ textAlign: "left" }}>Toplam</td>
                            <td><b>{ilkYilMaliyet} tl</b></td>
                          </tr>


                        </tbody>
                      </Table>
            </Tab.Pane>
            <Tab.Pane eventKey="kazancmodeli" style={{margin:"30px"}}>
            <> ProjeHUB farklı disiplinleri ve üye gruplarını barındıracağı için birkaç farklı gelir yöntemi olabileceği değerlendirilmektedir. Bunları en olası olandan en az beklenene doğru şu şekilde sıralayabiliriz.</>
            <p style={{ textAlign: "left" }}>
                    <ul>

                      <li><b>Proje Üyelik satışları :</b>Otomatik Proje Çizim Editörleri aylık ya da yıllık üyelikler şeklinde farklı özelliklerle kullanıcıya sunulacaktır. Üyelerin Serbest Mühendis, Belediye, Yapı Denetim Firması ya da Dağıtım Firması gibi farklı kurumsal yapılara göre farklı üyelik paketleri oluşturulacak ya da üyelerin kullandıkları Proje Çizim Editörlerine göre farklı paketler olacaktır. Örneğin Bir Proje Firması tüm disiplinlerdeki Proje Çizim Editörlerini kullanmak isteyebilir. Diğer yandan yalnız çalışan bir mimar ya da mühendis sadece kendi alanındaki Proje Çizim Editörlerini Kullanmak isteyecektir. Bu durum HUB özellikleri içinde geçerlidir. </li>
                      <li><b>HUB alan (DEPO) satışları:</b> Belirli bir kullanım alanının üzerindeki DEPO alanı ücretli olarak kullanıcıya sunulacaktır.</li>
                      <li><b>HUB Süreç Kontrol Sistemi satışları:</b> Temel Sürüm Kontrol Sistemleri dışında kullanıcıların özel ihtiyaçlarına göre daha profesyonel “Sürüm Kontrol Sistemleri” geliştirilerek satılabilir.</li>
                      <li><b> Reklamlar :</b> Uygulama üzerinde pek çok farklı şekilde reklam imkânı gelişecektir. Özellikle ücretsiz versiyonlarda üretici firmalar için proje aşamasında müşteriye ulaşmak için <b>ProjeHUB</b> uygun bir platform olacaktır</li>
                    </ul>
                    Temel gelir kaynakları yukarıda sıralananlar olsa da proje hayata geçirildiğinde e-imza, meta veri vb. gibi pek çok konunun gelir kaynaklarına eklenmesi mümkün olacaktır. Diğer yandan <b>ProjeHUB</b>'ın farklı mühendislik disiplinlerini içinde barındırmayı planlayan bir platform olduğu için gelir kaynaklarını farklı disiplinlere göre de düşünmek gerekir. Son olarak <b>ProjeHUB</b> yurtdışı pazarlara göre diğer ülke standartlarına göre de geliştirilerek küresel hizmet satışı da yapılabilir.
                    <p>Tahmini gelir hesabı yapılabilmesi için burada sadece Elektrik Disiplini için Proje Çizim Editörlerinin Satışı maddesi ele alınacaktır. </p>
                    <p><b>Elektrik Proje Pazarı (Projeciler + HUB Kullanıcıları)</b></p>
                
                  </p>
                  <Table striped bordered hover size="sm" >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "left",verticalAlign: "middle" }}> Üye Grubu  </th>
                      <th style={{  verticalAlign: "middle" }}>Tahmini Sayı</th>
                      <th style={{ width: "130px" }}>Otomatik Proje Çizim Editör Kullanımı</th>
                      <th style={{ width: "130px", verticalAlign: "middle"  }}>HUB-DEPO Kullanımı</th>
                      <th style={{ width: "130px" }}>HUB-SÜREÇ KONTROL Kullanımı</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Elektrik Mühendisleri</b></td>
                        <td> 5000+</td>
                        <td> VAR</td>
                        <td> VAR</td>
                        <td> VAR</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Elektrik Fen Adamları</b></td>
                        <td> 5000 den çok daha fazla</td>
                        <td> VAR</td>
                        <td> VAR</td>
                        <td> VAR</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>Elektrik Dağıtım Firmaları</b></td>
                        <td> 21</td>
                        <td> VAR</td>
                        <td> VAR</td>
                        <td> VAR</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Belediyeler</b></td>
                        <td> yaklaşık 1000 </td>
                        <td> VAR</td>
                        <td> VAR</td>
                        <td> VAR</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Yapı Denetim Firmaları</b></td>
                        <td> 2600</td>
                        <td> YOK</td>
                        <td> VAR</td>
                        <td> VAR</td>
                      </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>Diğer</b></td>
                        <td>bilinmiyor</td>
                        <td>  </td>
                        <td>  </td>
                        <td> YOK</td>
                        </tr>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b><h4>TOPLAM</h4></b></td>
                        <td> 13621'den fazla</td>
                        <td> 11000 </td><td> 13621 </td>
                        <td> 13621 </td>
                      </tr>


                       


                    </tbody>
                  </Table>
                  <hr/>
                  <h5>Alım Gücü, Tahmini Fiyat </h5>
                  Alım Gücü, Tahmini Fiyat için en çok satışın yapılacağı düşünülen Elektrik Projelerini Otomatik Çizen Çizim editörlerinin bulunduğu bir üyelik paketini ele alalım. Bu paketi alması planlanan bir mühendisin diğer harcama ve ödemelerine bakalım.
                      <ul  style={{ textAlign: "left" }}>
                        <li>EMO yıllık Belge yenileme ve aidatlar (BT:4000tl, SMM:4000tl , yıllık Aidat:540 tl) Toplam :8540 tl / yıl</li>
                        <li>EMO proje vizeleri 50kW altı projeler 100 tl , 50 kw üstü projeler 500 tl (Tüm Trafo Projeleri 500tl) </li>
                        <li>Belediye yıllık ücretleri belediyelere göre değişiklik gösteriyor. </li>
                        <li>Ozalit masrafları ; Bir mesken projesi Elektrik,Telekom,Yangın paftalarında toplam 12 çıktı olur. Ortalama bir proje için 400-500 tl . </li>
                        <li>internet, telefon, Youtube,Netflix vb platform üyelikleri.</li>
                      </ul>
                      
                      Söz konusu liste yukarıdan aşağıya doğru bir mühendise olan faydalarına göre sıralanmıştır. Özellikle EMO ve Belediyeler gibi masrafların mühendis tarafında hiçbir geri dönüşü olmamakta ve tamamen zorunludur. Diğerlerinin faydalarından görece bahsedilebilir. Sadece bu liste üzerinden proje maliyetleri konusunda bir tahminde bulunacak olunursa projecinin mesai dışında en az proje başı <b>1.000</b> TL maliyeti görülmektedir. Diğer harcamalar ve bu harcamaların kendisine olan faydaları ele alındığında proje hazırlamak ve proje süreçlerini kontrol edecek temel üyelik paketi için <b>1.000 TL/Ay</b>  gayet makul bir ücret görünebilir. Bu ücretin farklı üyelik paketleri çerçevesinde daha yüksek ücretler ile çeşitlendirileceği göz önünde tutulmalıdır. Fakat temel olarak gelir tahmini için fikir vermesi açısından aylık <b>1.000</b> TL üzerinden hesaplamalar yapılacaktır.
                   <hr/>
                   <h5>Tahmini Satış </h5>
                   <p style={{ textAlign: "justify" }}>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Elektrik Projesi paketlerini alabilecek üye grubunun toplam sayısı 13.000’den fazladır. En kötü senaryo 1.000 kişiye ücretli üyelik satıldığında aylık 1.000 kişi x 1.000 TL = 1 Milyon TL ciro elde edilir. Server ücretleri gibi şu ana kadar hesaba alınmamış olan masraflar ile birlikte aylık 335.750 TL olan giderleri 400.000 TL olarak ele alırsak en kötü senaryoda aylık net kar 600.000 TL olur.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sadece Elektrik Proje Paketleri satışında ilk 400 üyeden sonraki her 1.000 ücretli üye 1 Milyon TL gelir anlamına gelmektedir. Her 1.000 üye için server ve müşteri temsilcisi gibi masraflar 100.000 TL hesap edilirse her 1.000 üyeden 900.000 TL kar elde edilir.</p>                   
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sadece Elektrik Proje Paketleri satışında pazarın yarısına satış yapıldığında bu 6.000 ücretli üye anlamına gelmektedir. Buradaki aylık kar; ilk bin üye için 600.000 TL diğer 5 bin üye için 5x900.000 = 4.500.0000 TL, toplamda ise 5.100.000 TL Kar anlamına gelmektedir.</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Buradaki hesaplamada HUB özelliklerinin satışı ele alınmamıştır. Sadece 2.600 adet yapı denetim firmasına Otomatik Süreç Kontrol ve DEPO alanları satımı için aylık 1.000 TL’nin çok daha üzerinde, aylık 5.000 ila 10.000 TL arasında fiyatlarla satış yapılabilecektir. Bunun için ProjeHUB'ın yaygınlık kazanması gerekmektedir. Yapı denetim firmalarının alım gücü bakımından diğer üye gruplarına göre çok daha avantajlı olduğu unutulmamalıdır. </p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fiyatlandırmada fikir vermesi açısından ülkemizdeki 21 elektrik dağıtım firmasının ve bu firmalara şebeke imalat işi yapan yüklenici firmaların şebeke projelerini hazırlamakta kullandıkları “B-pro” programı için ilk alımda 170.000 TL ve yıllık 15.000 TL aidat ödemektedirler. Bu program “TrafoProje” editörü alt yapısı ile geliştirilebilecek bir uygulamadır. Bu gruptaki üyeler içinde farklı ücretlendirme paketleri uygulanabilecektir. </p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Proje editörlerinin tam anlamı ile hazırlanıp belediyeler, proje müellifleri, dağıtım firmaları, yükleniciler gibi tüm olası üyelere satışları yapıldığında elektrik disiplininde 5.000.000 ile 10.000.000 TL arasında satış yapılabilir. Buraya HUB özellikleri de eklendiğinde bu satış miktarının 10.000.000 TL’yi kolaylıkla bulması beklenmektedir. </p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ProjeHUB'ın Mimari ve Makina, inşaat vb diğer mühendislik disiplinlerinde de hizmet vermesi halinde Sadece Elektrik Disiplinindeki 10M aylık karın 4-5 kat daha artarak 40-50M aralığına çıkması muhtemel görünmektedir. </p>
                    </p>
                    <hr></hr>
                    <p style={{ textAlign: "justify" }}>
                    
                     
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ProjeHUB için bir yıllık bir geliştirme süresi planlanmaktadır.   </p>
                     
                    <Table striped bordered hover size="sm" >
                    <thead>
                      <tr><th style={{ textAlign: "left",verticalAlign: "middle" }}> </th><th style={{  verticalAlign: "middle" }}>işlemler</th><th style={{ width: "130px" }}>Maliyet/Ay</th><th style={{ width: "130px", verticalAlign: "middle"  }}>Gelir/Ay</th><th style={{ width: "130px" }}>Kar/Ay</th></tr>
                    </thead>
                    <tbody>
                      <tr> 
                        <td style={{ textAlign: "left" }}><b>ilk Ay</b></td>
                        <td> Ofis ve Ekibin Oluşturulması. ProjeHUB konseptinin ekibe anlatılması.AltYapı ve sistem tasarımı. Cad sistemi ve trafoproje editörünün incelenmesi. </td>
                        <td> {toplamAylikGider + baslangicMaliyeti}tl</td>
                        <td> 0 tl</td>
                        <td> 0tl</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}> <b>2-6.Aylar</b></td>
                        <td>CAD sisteminin ve temel HUB özelliklerinin geliştirilmesi. İlk demoların (Meskenler, trafo, şantiye ve sulama gibi Proje Çizim Editörleri)oluşturulması (Kat planı çizimleri hariç). Temel form ve grafik alt yapısının geliştirilmesi.</td>
                        <td>   {toplamAylikGider}tl</td>
                        <td> 0tl</td>
                        <td> 0tl</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>İkinci 6 ay</b></td>
                        <td> ilk demoların yayınlanmaya başlaması. Kat planı çizim editörlerinin geliştirilmeye başlaması. Kullanıcı dönüşlerinin alınmaya başlaması . Reklam ve Pazarlama faaliyetlerinin başlaması. Kullanıcı verilerine göre fiyatlandırma çalışmalarının yapılması.</td>
                        <td>  {toplamAylikGider}tl</td><td> 0tl</td>
                        <td> 0tl</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}><b>2. Yıl </b></td>
                        <td> Kat planı çizim editörlerinin diğer editörlere entegre edilmesi. Proje Çizim Editörlerinin ücretli olarak satışının başlaması. Proje çizim editörlerinin sayısının arttırılması. Diğer disiplinlerdeki geliştirmeler için değerlendirmelerin yapılaması. </td>
                        <td> {toplamAylikGider}tl</td>
                        <td> 1-5 M</td><td> 0.5-5 M tl</td>
                      </tr>
 
                    </tbody>
                  </Table>
                  <b>ProjeHUB</b>'’ın Mimari ve Makina, inşaat vb. diğer mühendislik disiplinlerinde de hizmet vermesi halinde sadece elektrik disiplinindeki 10.000.000 TL aylık karın 4-5 kat daha artarak 40.000.000- 50.000.000 TL aralığına çıkması muhtemel görünmektedir.
                    </p>

            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>

        </>}
 
    </div>
  )
}
