
import React, { useEffect, useState } from 'react'
import { Form, Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
//import { sayacdata } from '../reducers/sayacbolmeSlice'
import { dortgen } from '../Fonksiyonlar/genelfonksiyonlar'
import YuklemeCetveliModal from './YuklemeCetveliModal'
import { Akim, iletkenBul, kesiciTMS, kesiciAOS, kesiciNH, trafoGucGuncelle_EMO, kesiciEMO_KOMPAKT } from '../Fonksiyonlar/Elektricals'
import { PRIZ, FIRIN, AYDINLATMA, KOMPRESOR, PRESS, ZIMPARA, MOTOR, TORNA, KESICI, PAKETLEME, EBATLAMA, VINC, KURUTMA, OGUTUCU, BANT, HIDROFOR, HELEZON, ELEVATOR, TERMOSIFON, KARISTIRICI, TASIYICI, KAZAN, KAYNAK, SARJUNITE } from '../Data/Yukler'

import { UpdateKuruluGuc, UpdateAkimAmperaj, UpdateTalepGuc, UpdateTalepGuckVA, UpdateTrafoGuckVA, UpdateAGAkim, UpdateOGAkim, UpdateKesifNo } from '../reducers/trafoDetaySlice'
import { UpdateCikisIletkenTop, UpdateSabitGrupkVAR, UpdateKompakt, UpdateAkimTrafolari, UpdateKAKKompakt, UpdateSayacDizi, UpdateSayac, UpdateCikislar, UpdateBara, UpdateInisIletkenDizi, UpdateAkimTrafoOran, UpdateCikisIletken } from '../reducers/PanoDetaySlice'

let Yukler = ["Aydınlatma ", "Priz ", "Motor ", "SarjUnitesi ", "Kompresör ", "Press ", "Zımpara ", "Kaynak ", "Ebatlama ", "Bant ", "Kazan ", "Fırın ", "Vinç ", "Torna ", "Kesici ", "Paketleme ", "Karıştırıcı ", "Kurutma ", "Taşıyıcı ", "Helezon ", "Kompresör ", "Ogutucu ", "Hidrofor ", "Elevator ", "Termosifon "]

let satirNo = 0
export default function YuklerDetay(props) {

    const [en, setEn] = useState(false);
    let X = 0;
    let Y = 0;
    let Scl = 1;

    let yuklist = []

    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const PanoDetay = useSelector((state) => state.PanoDetay)

    const dispatch = useDispatch()


    const [showSayacBolme, setShowSayacBolme] = useState(true);
    const [yuklerShow, setYuklerShow] = useState(false);
    const [bgcolor, setBgcolor] = useState("white");
    const [yuk, setYuk] = useState()
    const [seciliyuk, setSeciliYuk] = useState([{ id: 0, No: 0, Faz: 1, Guc: 0, Akim: 0, Aciklama: 0, AkimKap: 0, Iletken: 0, Kesici: 0, Mesafe: 0 }])
    const [seciliguc, setSeciliGuc] = useState([])
    const [secCikguc, setSecCikGuc] = useState(0)
    const [yukler, setYukler] = useState([])
    const [satNo, setSatNo] = useState(1)
    const [cikisMesafe, setCikisMesafe] = useState(5)
    const [yuklemeCetvelShow, setYuklemeCetvelShow] = useState(true)
    const [GucSecili, setGucSecili] = useState("1, 1, Aciklama")
    const [iletkenTur, setIletkenTur] = useState("NYY")
    const [iletkenToprak, setIletkenToprak] = useState(PanoDetay.CikisIletkenTop)
    const [Iletken, setIletken] = useState(PanoDetay.CikisIletken)
    const [notrKesme, setNotrKesme] = useState(true)
    const [cikKesTur, setCikKesTur] = useState("KOMPAKT")
    let toprak = "toprak-true"

    function sayacBolmeShow() { setShowSayacBolme(!showSayacBolme) }
    function YuklemeCetvelModalShow() { setYuklemeCetvelShow(!yuklemeCetvelShow) }
    function yuklistShow() { setYuklerShow(!yuklerShow) }

    useEffect(() => { }, []);
    useEffect(() => { YukGucSec(GucSecili) }, [cikisMesafe, iletkenTur]);
    useEffect(() => {
        setSatNo(yukler.length + 1);
        TrafoDetayGuncelle();
        dispatch(UpdateCikislar(yukler))
    }, [yukler]);

    useEffect(() => {
        YukTurSec(yuk);
        YukGucSec(seciliyuk[0].Faz + "," + seciliyuk[0].Guc + "," + seciliyuk[0].Aciklama)

    }, [yuk, seciliyuk]);

    useEffect(() => {
        YukGucSec(GucSecili)
    }, [cikKesTur, notrKesme]);

    useEffect(() => {
        let toprak1 = "toprak-true"
        if (iletkenToprak === true) {
            toprak = "toprak-true"
        } else if (iletkenToprak === false) {
            toprak = "toprak-false"
        }
        YukGucSec(GucSecili)
    }, [iletkenToprak]);


    useEffect(() => {
        if (secCikguc > 65000 && cikKesTur === "AOS") {
            setCikKesTur("KOMPAKT")
            document.getElementById("kesicitur").value = "KOMPAKT"
        }
        YukGucSec(GucSecili)
    }, [secCikguc]);

    useEffect(() => {
        setSecCikGuc(parseInt(GucSecili.split(",")[1]))
    }, [GucSecili]);

    function YukEkle() {
        let kuruluguc = KuruluGuc()
         if(parseInt(kuruluguc)+parseInt(seciliguc.Guc)<=400000){

     
        setSatNo(satNo + 1)
        yuklist.push(seciliguc)
        setYukler(oldArray => [...oldArray, seciliguc]);
        satirNo = yukler.length + 1;
        setSeciliGuc(prevState => ({
            ...prevState,
            id: satNo + 1,
            No: satNo + 1,
            Mesafe: cikisMesafe
        }))
    }else{
        alert("Toplam Kurulu Gücünüz 400kVA yı geçmemelidir.")
    }
    }
    function TrafoDetayGuncelle() {
        let kuruluguc = KuruluGuc()
        let talepFak = trafoDetaylar.talepFak
        let talepGuc = kuruluguc * talepFak / 100
        let cosFi = trafoDetaylar.cosFi
        let talepGuckVA = (talepGuc / cosFi).toFixed(2)
        talepGuckVA = talepGuc / cosFi;
        let trafo = trafoGucGuncelle_EMO(talepGuckVA)

        dispatch(UpdateKuruluGuc(kuruluguc))
        dispatch(UpdateTalepGuc(talepGuc))
        dispatch(UpdateTalepGuckVA(talepGuckVA))
        dispatch(UpdateTrafoGuckVA(trafo.GucKVA))
        dispatch(UpdateKompakt(trafo.EmoKompakt))
        dispatch(UpdateKAKKompakt(trafo.KAKKompakt))
        dispatch(UpdateSabitGrupkVAR(trafo.SabitKond))
        dispatch(UpdateAkimTrafolari(trafo.AkimTrf))
        dispatch(UpdateAkimTrafoOran(trafo.AkimTrfOran))
        dispatch(UpdateInisIletkenDizi(trafo.inisdiziCu))
        dispatch(UpdateBara(trafo.AGBaraEtiket))
        dispatch(UpdateAGAkim(trafo.Akimlar.A04))
        dispatch(UpdateOGAkim(trafo.Akimlar.A34_5))
        dispatch(UpdateAkimAmperaj(trafo.AkimAmperaj))
        dispatch(UpdateKesifNo(trafo.KesifNo))

        if (trafo.GucKVA <= 25) {
            dispatch(UpdateSayacDizi(["yok", "Düz Sayaç", "X5 Kombi"]))
        } else {
            dispatch(UpdateSayacDizi(["yok", "X5 Kombi"]))
            dispatch(UpdateSayac("X5 Kombi"))
        }
    }
    function KuruluGuc() {
        let kuruluguc = 0;
        yukler.map((yuk) => {
            kuruluguc += parseInt(yuk.Guc)
        })
        return kuruluguc
    }
    function YukTurSec(val) {
        switch (yuk) {
            case "YÜKLER": setSeciliYuk([ZIMPARA]); break;
            case "Aydınlatma ": setSeciliYuk(AYDINLATMA); break;

            case "Priz ": setSeciliYuk(PRIZ); break;
            case "Motor ": setSeciliYuk(MOTOR); break;
            case "SarjUnitesi ": setSeciliYuk(SARJUNITE); break;
            case "Press ": setSeciliYuk(PRESS); break;
            case "Zımpara ": setSeciliYuk(ZIMPARA); break;
            case "Kaynak ": setSeciliYuk(KAYNAK); break;
            case "Ebatlama ": setSeciliYuk(EBATLAMA); break;
            case "Bant ": setSeciliYuk(BANT); break;
            case "Kazan ": setSeciliYuk(KAZAN); break;
            case "Fırın ": setSeciliYuk(FIRIN); break;
            case "Vinç ": setSeciliYuk(VINC); break;
            case "Torna ": setSeciliYuk(TORNA); break;
            case "Kesici ": setSeciliYuk(KESICI); break;
            case "Paketleme ": setSeciliYuk(PAKETLEME); break;
            case "Karıştırıcı ": setSeciliYuk(KARISTIRICI); break;
            case "Kurutma ": setSeciliYuk(KURUTMA); break;
            case "Taşıyıcı ": setSeciliYuk(TASIYICI); break;
            case "Helezon ": setSeciliYuk(HELEZON); break;

            case "Kompresör ": setSeciliYuk(KOMPRESOR); break;
            case "Ogutucu ": setSeciliYuk(OGUTUCU); break;
            case "Hidrofor ": setSeciliYuk(HIDROFOR); break;
            case "Elevator ": setSeciliYuk(ELEVATOR); break;
            case "Termosifon ": setSeciliYuk(TERMOSIFON); break;
            default:
        }

    }
    function IletkenSec(val, toprak1) {
        val = val.split(",")
        const guc = val[1];
        const faz = val[0];
        const akim = Akim(guc, faz)

        const ilet = iletkenBul(akim, faz, toprak1, iletkenTur, "HavaAK")
        if (ilet.adet <= 1) {
            setIletken(ilet.iletken.Etiket)
        } else {
            setIletken(ilet.adet + "x(" + ilet.iletken.Etiket + ")");
        }
    }

    function YukGucSec(val) {
        val = val.split(",")
        const guc = val[1];
        const faz = val[0];
        const aciklama = val[2];
        const akim = Akim(guc, faz)

        const ilet = iletkenBul(akim, faz, toprak, iletkenTur, "HavaAK")
        let CikisIletken = ""
        if (ilet.adet <= 1) {
            CikisIletken = (ilet.iletken.Etiket)
            setIletken(ilet.iletken.Etiket)
        } else {
            CikisIletken = (ilet.adet + "x(" + ilet.iletken.Etiket + ")");
            setIletken(ilet.adet + "x(" + ilet.iletken.Etiket + ")");
        }
        let kesici = {};
        let CikisKesiciTuru = cikKesTur
        if (secCikguc > 65000 && cikKesTur === "AOS") {
            CikisKesiciTuru = "KOMPAKT"
        }
        switch (CikisKesiciTuru) {
            case "AOS":
                kesici = kesiciAOS(ilet.iletken.HavaAK, faz, 25, notrKesme);
                break;
            case "TMŞ":
                kesici = kesiciTMS(ilet.iletken.HavaAK, faz, 25, notrKesme);
                break;
            case "KOMPAKT":
                kesici = kesiciEMO_KOMPAKT(ilet.iletken.HavaAK, faz, 25, notrKesme);
                break;
            case "NH":
                kesici = kesiciNH(ilet.iletken.HavaAK, faz);
                break;
            default:
        }
        setSeciliGuc({ id: satNo, No: satNo, Faz: faz, Guc: guc, Akim: akim, Aciklama: aciklama, HavaAkimKap: ilet.iletken.HavaAK, TopAkimKap: ilet.iletken.ToprakAK, iletkenKesit: ilet.iletken.Kesit, Iletken: CikisIletken, KesiciOb: kesici, Kesici: kesici.Etiket, Mesafe: cikisMesafe })
    }
    return (

        <React.Fragment >
            <YuklemeCetveliModal show={yuklemeCetvelShow} list={yukler} yuklergerial={(yuk) => setYukler(yuk)} />
            <Table
                responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1   '
                style={{ width: "98%", background: bgcolor }}
            >
                <thead style={{ width: "100%", background: bgcolor }}  >
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={sayacBolmeShow}>Trafo Çıkış Yükleri</th>
                    </tr>
                </thead>
                <tbody style={showSayacBolme ? { display: "block" } : { display: "none" }}>
                    <tr>
                        <td>
                            <Form.Select aria-label="example" onChange={e => setYuk(e.target.value)}  >
                                <option >YÜKLER</option>
                                {Yukler.map((yuk, y) => (
                                    <option key={y} value={yuk}>{yuk}</option>
                                ))}  
                            </Form.Select>
                        </td>
                        <td>
                            <Button variant="outline-success" size="sm" className='mx-1' onClick={YuklemeCetvelModalShow}>YüklemeC</Button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Form.Select aria-label="example" onChange={e => setGucSecili(e.target.value)} defaultValue={0} >
                                {seciliyuk.map((yuk, y) => (
                                    <option key={y} value={[yuk.Faz, yuk.Guc, yuk.Aciklama]}>{yuk.Aciklama}</option>
                                ))}
                            </Form.Select>
                        </td>

                    </tr>
                    <tr>
                        <td>
                            <Form.Select aria-label="example" defaultValue={'NYY'} onChange={e => setIletkenTur(e.target.value)}>
                                <option>Çıkış İletkeni Türü</option>
                                <option value="NYY">NYY </option>
                                <option value="NAYY">NAYY </option>
                                <option value="NYM">NYM </option>
                                <option value="NYAF">NYAF </option>
                            </Form.Select>
                        </td>
                        <td>
                            Mesafe(m)
                            <input style={{ width: "50px", background: bgcolor }} className="my-auto border border-0" type='number' min="10" placeholder={cikisMesafe} onChange={e => setCikisMesafe(e.target.value)} />
                        </td>
                    </tr>

                    <tr>
                        <td>{Iletken}
                        </td>
                        <td>
                            Toprak &nbsp;<Form.Check inline defaultChecked={iletkenToprak} type="checkbox"
                                onChange={(e) => setIletkenToprak(e.target.checked)} />
                        </td>
                    </tr>
                    <tr >
                        <td>
                            Kesici Türü
                        </td>
                        <td>
                            <Form.Select aria-label="example" id="kesicitur" defaultValue={'NotrKesmesiz'} onChange={e => setCikKesTur(e.target.value)}>
                                {seciliguc.Faz !== "1" ? <option value="TMŞ">TMŞ</option> : null}
                                <option value="KOMPAKT">Kompakt Şalter</option>
                                {secCikguc < 65000 ? <option value="AOS">AOS</option> : null}
                                <option value="NH">NH Bıçaklı</option>
                            </Form.Select>
                        </td>
                    </tr>
                    <tr >
                        <td>
                            Nötr Kesme
                        </td>
                        <td>
                            <Form.Check inline defaultChecked={notrKesme} type="checkbox"
                                onChange={(e) => setNotrKesme(e.target.checked)} id={`1`} />
                        </td>
                    </tr>
                    <tr >
                        <td>
                            <Button variant="outline-success" size="sm" className='mx-1' onClick={YukEkle}>Ekle</Button>
                        </td>
                    </tr>
                </tbody>
            </Table>

            <Table responsive
                size="sm"
                striped
                className='rounded-3 mt-1 mb-0 ms-1 '
                style={{ width: "98%", background: bgcolor }} >
                <thead style={{ width: "100%", background: bgcolor }}  >
                    <tr>
                        <th colSpan="2" className='pe-1' onClick={yuklistShow}>Yükler</th>
                    </tr>
                </thead>
                <tbody style={yuklerShow ? { display: "block" } : { display: "none" }}>
                    {yukler.map((yuk, y) => (
                        <tr key={y}>
                            <td>{y + 1} </td>
                            <td>{yuk.Faz} Faz</td>
                            <td>{yuk.Guc}W </td>
                            <td>{yuk.Aciklama} </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
        </React.Fragment>
    )

}
