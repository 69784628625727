import React, { useEffect, useState } from 'react'
import { Tab, Row, Col, Nav, Table } from 'react-bootstrap'
import CheckIcon from '@mui/icons-material/Check';
import kulzam from "../kullanicizaman.png"
import { useSelector, useDispatch } from 'react-redux'
import icerik from './icerikler'

export default function ExecusiveSummer() {



  const lisan = useSelector((state) => state.admin.dil)

  useEffect(() => {

  }, [lisan])


  return (
    <div  ><br />
      {lisan === 'TR' ?
        <>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="yonetim" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Management team</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="musteriSorun" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Customer Problem</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="urun" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Products and Services</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pazar" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Target Market</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ismodel" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Business model</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="musteriTurleri" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Customer Types</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Satis" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Sales and Market Strategy</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rakipler" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Competitors  </h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rekabet" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Competitive Advantage</h2></Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} style={{ marginLeft: '25%' }}>
                <Tab.Content>
                  <Tab.Pane eventKey="yonetim" style={{ margin: "30px" }}>
                    The idea owner and manager of the project is Electrical-Electronics Engineer İrfan BADUR, who has been working in the sector as a Freelance Consultant Engineer since 2008, has a good command of the problems in the Project side of the construction industry, and also works as a business planner. The project is being developed as a prototype on http://trafoproje.com. After the necessary investment is received, it is planned to include expert software developers in the team in the first stage. Later, teammates who will deal with the product marketing side will be added to the team and a suitable team will be formed systematically.
                  </Tab.Pane>
                  <Tab.Pane eventKey="musteriSorun" style={{ margin: "30px" }}>
                    It takes an average of one day for Project Authors to draw, or prepare, a project. Calculation, drawing or control errors are very common in projects due to reasons such as the presence of any input in more than one place of the project during project drawing and the slightest change affecting the entire project. Due to these errors, projects are often rejected by approval or control authorities and are requested to be redrawn. Such undesirable updates during the process mean loss of time, work and money for project developers. With ProjeHUB, workload that would take days is reduced to minutes.
                  </Tab.Pane>
                  <Tab.Pane eventKey="urun" style={{ margin: "30px" }}>
                  <b>ProjeHUB</b> içinde elektrik, makine, inşaat mühendisliği gibi farklı disiplinlerdeki proje müelliflerinin projelerini otomatik olarak hazırlamaları için her bir disiplin için farklılaştırılmış <b>Proje Çizim Editörü</b> bulunacaktır. Kullanıcı hazırlayacağı proje türüne göre istediği Editörü kullanarak projesini çizecektir.
                    <br/>
                    Bunun yanı sıra kullanıcı <b>Proje Hazırlama Editörü</b> ile hazırladığı projesini <b>DEPO</b> alanında saklayabilir ve istediği kişiler ile istediği kısımlarını paylaşabilir ve onay-kontrol işlemlerine gönderebilir.
                    Yapıların tamamlanarak iskanlarının alınmasına kadar gerçekleşen proje süreçleri çok karmaşıktır. Müellifler tarafından hazırlanan projeleri onaylayan kontrol eden kurumlar ve yükleniciler de ProjeHUB üzerinden süreci takip edebilir zaman ve iş kaybını önleyecek şekilde sürecin parçası olabilir. Tüm bu süreçleri otomatik yönetmek için <a>ProjeHUB</a> da <a>Süreç Kontrol özelliği</a> olacaktır.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th> Customer Group </th>
                          <th> Project Drawing Editors <br />

                          </th>

                          <th> DEPO (Repository) </th>
                          <th> Process Control</th>
                          <th> Automatic Project Control  </th>
                          <th> Advertising </th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>   Electrical Project Designers  </td>
                          <td>
                            <ul>

                              <li>Internal Installation</li>
                              <li>Residence-Office-workplace</li>
                              <li>School Hospital Hotel</li>
                              <li>Factory- Industrial Building</li>
                              <li>Transformer</li>
                              <li>Network</li>
                              <li>Construction Site/Irrigation etc..</li>

                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}> <CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}  ><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td>Architecture</td>
                          <td>
                            <ul>
                              <li>Residence-Office-workplace</li>
                              <li>School Hospital Hotel</li>
                              <li>Factory- Industrial Building</li>
                              <li>High Rise Building </li>
                              <li>Shopping mall</li>
                              <li>etc...</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Machinery Project Authors </td>
                          <td>
                            <ul>
                              <li>Plumbing </li>
                              <li>Heating and Cooling Installation </li>
                              <li>Fire extinguishing Project </li>
                              <li>Elevator etc... </li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Civil Engineers </td>
                          <td>
                            <ul>
                              <li>Static Calculation Editors</li>
                              <li>Residence-Office-workplace</li>
                              <li>School Hospital Hotel</li>
                              <li>Factory- Industrial Building</li>
                              <li>Roof Projects</li>
                              <li>Steel Projects</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Municipalities </td>
                          <td> Project Drawing Editors used by all Freelance architects and engineers </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Electricity Distribution Companies </td>
                          <td> Project Drawing Editors used by electrical engineers
                            <ul>
                              <li>Lighting Project</li>
                              <li>Transformer Project</li>
                              <li>Network Project</li>
                              <li>etc Project</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Building Inspection Companies </td>
                          <td>  --    </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Contractor Companies</td>
                          <td> Project drawing editors, partly in their own field of work  </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                        </tr>
                        <tr>
                          <td> Manufacturer Companies </td>
                          <td> -- </td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pazar" style={{ margin: "30px" }}>
                  Our target market is primarily engineers working on projects in the construction industry. For this population, the conflicting demands of project approval and inspection institutions and the legislation require additional work. Projects that are constantly revised and require changes cause authors to lose a lot of time and effort. In the future, approval and control institutions and most of the construction industry will be included in the target market.
                    <br />
                    <br />
                    <h4>INDIVIDUAL CUSTOMER BASE 55,000</h4>
                    <div style={{ margin: "20px" }}>
                      <h3>Number of Electricity Group Potential Customers 10,000</h3>
                      <ul>
                        <li>The number of members of the Chamber of Electrical Engineers (EMO) is 65,895</li>
                        <li>5000 of these members are registered as SMM.</li>
                        <li>According to the Central Black Sea Region Electricity Distribution Company and EMO data, the number of electrical project designers in this region is around 650. 420 of them are scientists and 230 are electrical engineers. The number of scientists is 1.8 times the number of engineers.</li>
                        <li>The ratio of the Central Black Sea region to the entire population is 81/5.7 = 14</li>
                        <li>Number of Authors throughout the country compared to the population = 650x14=9100</li>
                        <li>Number of Authors throughout the country compared to the Number of Distribution Companies = 650x21=1350</li>
                        <li>According to the Engineer-Scientist ratio, there are 5000x1.8=9000 scientists. Total number of Authors 5000+9000= 14,000 people</li>
                        <li>According to the above data, the number of Electrical Project Designers can be taken as at least 10,000.</li>
                      </ul>

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Architectural Group Potential Customers 20,000</h3>
                      The number of members of the Chamber of Architects is 68,478. About 20,000 of these members work freelance. It is normal for the number of architects to be more than the number of electrical engineers. Because the architectural project preparation process is much longer. While architects work on one or two projects per month, electrical engineers may work on up to ten or twenty projects per month. Usually an electrical engineer works with several different architects.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Number of Potential Customers of the Machinery Group is 15,000</h3>
                      The number of members of the Chamber of Mechanical Engineers is 121,676. The number of mechanical engineers working freelance is around 15,000 people.
                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Static/Construction Group Number of Potential Customers 10,000</h3>
                      The number of members of the Chamber of Civil Engineers is 137,365. The number of mechanical engineers working independently is around 10,000.
                    </div>
                    <h4>CORPORATE CUSTOMER BASE Public organizations: 4.048 & Private Sector Companies: 200K </h4>
                    <div style={{ margin: "20px" }}>
                      <h3>Municipalities 1,389</h3>
                      There are a total of 1,389 municipalities in Turkey, including 30 metropolitan municipalities, 51 provincial municipalities (central district), 922 district municipalities and 386 town municipalities. Municipalities carry out project review and approval procedures for building permits. On the other hand, they were also preparing many projects within their own structure. They can use ProjeHUB's features such as both project Drawing Editors and Automatic Process Tracking, Automatic project review, etc.


                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Building Inspection Companies 2,476</h3>
                      Building Inspection Companies must examine whether building projects in all disciplines are technically feasible and comply with legislation and standards. They can use features such as Automatic Project Control and WAREHOUSE.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Telecom Directorates 81</h3> Telecom Directorates, like Electricity Distribution Companies, carry out both project control and project manufacturing.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Fire Departments 81</h3>
                      Fire detection, fire extinguishing and architectural projects prepared for buildings are examined in fire departments, especially in big cities.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Contractor Companies 150K+++ </h3>
                      Most Contractor companies in different disciplines within the construction industry are potential customers. Because these companies generally produce projects prepared by authors. When this is not the case, they often have to project their own production. For example, contractor companies that build networks for Electricity Distribution companies must design their networks. Since it is difficult to determine the exact number of this customer group, to give an idea, the number of contractor companies registered with TOBB can be taken as 150,000.
                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Manufacturers 50K+++</h3>
                      ProjeHUB can be a very good advertisement for manufacturers. On the other hand, much of the data that manufacturers will need will be automatically generated on the projectHUB. According to research conducted on the internet, it is seen that the number of manufacturing companies in Turkey is around 50,000.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Static/Construction Group Number of Potential Customers 10,000</h3>
                      The number of members of the Chamber of Civil Engineers is 137,365. The number of mechanical engineers working independently is around 10,000.
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ismodel" style={{ margin: "30px" }}>
                    There are three basic models for selling the product to users.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>Business Model</th>
                          <th>Description <br /> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Monthly Membership Sales</td>
                          <td>
                            Packages will be sold to users as monthly or annual subscriptions according to the features they use.
                          </td>
                        </tr>
                        <tr>
                          <td>Project based </td>
                          <td>Users will pay separately for the projects they prepare.
                          </td>
                        </tr>
                        <tr>
                          <td>Corporate Model</td>
                          <td> In the form of annual subscriptions with higher prices and more features for corporate users or at special prices determined for specially developed features.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="musteriTurleri" style={{ margin: "30px" }}>
                    We can group customers according to their areas of expertise (architect, electrical, statics, etc.) and whether they are corporate or individual customers.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>Customer Group</th>
                          <th>Individual Customers<br />
                          </th>
                          <th>Corporate Customers</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Electrical Group</td>
                          <td  >
                            <ul>
                              <li>Electrical Engineers</li>
                              <li>Electrical Technicians</li>
                              <li>Electrical Technicians</li>
                              <li>Electricians </li>
                            </ul>
                          </td>
                          <td rowSpan={4}>

                            <ul>
                              <li>Electricity Distribution Companies</li>
                              <li>Municipalities</li>
                              <li>Telecom</li>
                              <li>Building Inspection Companies </li>
                              <li>Contractor Companies </li>
                              <li>Manufacturers </li>
                              <li>Fire Departments </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Architectural Group </td>
                          <td>Architects </td>
                        </tr>
                        <tr>
                          <td>Machine Group</td>
                          <td>
                            <ul>
                              <li>Mechanical Engineers </li>
                              <li>Technicians </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Construction Group</td>
                          <td>
                            <ul>
                              <li>Civil Engineers </li>
                              <li>Technicians </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Satis" style={{ margin: "30px" }}>
                  ProjeHUB increases productivity immeasurably. The important thing is to reach a sufficient customer base at an affordable price. For this purpose, internet advertisements, the Chamber of Electrical Engineers, studies for senior university students of relevant engineering faculties and our personal connections will be used. We hope to create a new market with ProjeHUB. As the first company in this new market, we believe that we can maintain our competitive advantage with affordable prices and quality service.
                    <ul>
                      <li><b>first 6 months:</b> demo products will be developed.</li>
                      <li><b>During the second 6 months:</b> demo products will be available free of charge and efforts will be made to increase the number of users during this period. Pricing studies will be carried out according to the customer portfolio and customer data.</li>
                      <li><b>From the beginning of the second year:</b> completed products will be offered for sale for a fee.</li>
                    </ul>
                    Even non-member users will be able to prepare their projects free of charge with Project Drawing Editors. They can only download these projects to their computers and only view them on the screen. This allows everyone to experience ProjeHUB's project Editors and see how quickly and accurately they prepare projects.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>Period</th>
                          <th>Transaction</th>
                          <th>Sales</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>First Month</td>
                          <td > In the first month, a team will be established and the necessary working environment will be arranged. Afterwards, the project will be explained to the team. The team will be trained on CAD systems and electrical projects and the projectHUB concept will be taught to the team. ProjeHUB's basic infrastructure and scenarios will be designed.
                          </td>
                          <td   > --</td>

                        </tr>
                        <tr>
                          <td>Next 5 Months</td>
                          <td>Project drawing editors will begin to be developed. Drawing Editors that prepare Residential Projects, excluding floor plan drawing features, will be developed and Process Control Features will also be developed. During this period, demo products will be developed and at the end of the 6th month, demo products will be offered to the user free of charge.
                          </td>
                          <td> --</td>
                        </tr>
                        <tr>
                          <td>Second 6 Months</td>
                          <td>
                            Floor Plan Drawing features, especially in residential installation projects etc., will be completed. Once the demo products are completed, the development of products that will be offered to the user for a fee will be completed. Efforts will be made to increase the number of users during this period. Pricing studies will be carried out based on data received from users.
                          </td>
                          <td   > --</td>
                        </tr>
                        <tr>
                          <td>Later years</td>
                          <td>
                            During this period, product use will be charged. ProjeHUB's growth scenarios will be developed by conducting user feedback and market research. The ProjeHUB ecosystem will be developed by conducting the necessary research to either expand globally in the electricity group or to improve the features in other groups.
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}> <CheckIcon /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="rakipler" style={{ margin: "30px" }}>
                  According to our research, we believe that our product has no competitors yet. Because our product is a web-based product that is unique in the market and also offers a community service that can appeal to all engineering disciplines. The closest products on the market offer solutions in only one specific branch of a single engineering discipline, and all of these products are desktop applications. These programs are in the form of program sales and are very costly in terms of price.
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Competitors</th>
                          <th>Discipline</th>
                          <th>Area</th>
                          <th>Web</th>
                          <th>Cloud</th>
                          <th>Price</th>
                          <th>Process Control</th>
                          <th>Auto Drawing</th>
                          <th>HUB</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ProjeHUB</td>
                          <td>Electricity</td>
                          <td>Interior Installation (Residence-Office-School-Hospital-Industrial Building etc.), Network (Low-Low-O.G.), Transformer (pole-Building type),</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>1000tl/Ay</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                        </tr>
                        <tr>
                          <td>B-Pro</td>
                          <td>Electricity</td>
                          <td>Network</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}>170.000tl +15.000tl annually</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                        </tr>
                        <tr>
                          <td>AutoCAD</td>
                          <td>----</td>
                          <td>----</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}> 6.374 TL/Month </td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                          <td style={{ backgroundColor: "red" }}>none</td>
                        </tr>

                      </tbody>
                    </Table>

                  </Tab.Pane>
                  <Tab.Pane eventKey="rekabet" style={{ margin: "30px" }}>
                  There is no equivalent product on the market yet. The products on the market either only partially draw simple residential projects (1 product) or only draw network projects (1 product). Both of these products require installation and offer partial solutions in a special area that belongs only to electrical engineers. Our product will be web-based and will not require installation. It can be used from anywhere and will offer a community infrastructure.
                    <br />
                    <ul>

                      <li>ProjeHUB will have the infrastructure to address all Engineering disciplines in the Project market.</li>
                      <li>ProjeHUB will be designed to appeal to all parties in the Project Market.</li>
                      <li>ProjeHUB does not only consist of automatic project preparation editors. It includes a community system with features such as <b>Process Control System</b> and <b>Warehouse</b> area, which have never been developed in the project market until now.</li>
                    </ul>
                    There are desktop drawing applications on the market that prepare only one type of projects in a single engineering discipline. On the other hand, these applications are also quite expensive in terms of price.

                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </> :

        <>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="yonetim" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Yönetim Ekibi</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="musteriSorun" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Müşteri Sorunu</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="urun" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Ürün ve Servisler</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="pazar" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Hedef Pazar</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="ismodel" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>İş Modeli</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="musteriTurleri" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Müşteri Türleri</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Satis" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Satış & Pazar Stratejisi</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rakipler" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Rakipler  </h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rekabet" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>Rekabet Avantajı </h2></Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} style={{ marginLeft: '25%' }}>
                <Tab.Content>
                  <Tab.Pane eventKey="yonetim" style={{ margin: "30px" }}>
                    Projenin fikir sahibi ve yöneticisi, 2008 yılından beri Serbest Müşavir Mühendis olarak sektörde çalışan, özellikle inşaat sektörünün Proje ayağındaki sorunlara hakim olan, iş planlamacılığı da yapan Elektrik-Elektronik Mühendisi İrfan BADUR'dur. Proje prototip olarak http://trafoproje.com üzerinden geliştirilmektedir. Gerekli yatırımın alınmasından sonra ilk etapta alanında uzman yazılımcıların ekibe dahil edilmesi planlanmaktadır. Daha sonra ise ürün pazarlama tarafı ile ilgilenecek takım arkadaşları da ekibe eklenerek sistematik bir şekilde uygun bir ekip oluşturulacaktır.
                  </Tab.Pane>
                  <Tab.Pane eventKey="musteriSorun" style={{ margin: "30px" }}>
                    Proje Müelliflerinin bir projeyi çizmesi yani hazırlaması ortalama bir günlük bir süre almaktadır. Proje çizimi sırasında herhangi bir girdinin projenin birden çok yerinde bulunması, en ufak bir değişikliğin tüm projeyi etkilemesi gibi nedenlerden dolayı projelerde hesap, çizim ya da kontrol hatalarına çok sık rastlanmaktadır. Bu hatalardan dolayı projeler çoğu zaman onay ya da kontrol mercileri tarafından reddedilir ve tekrar çizilmesi talep edilir. Süreç içerisindeki bu gibi istenmeyen güncellemeler projeciler için zaman, iş ve para kaybı demektir. ProjeHUB ile günlerce sürecek iş yükü dakikalara indirgenmektedir.
                  </Tab.Pane>
                  <Tab.Pane eventKey="urun" style={{ margin: "30px" }}>
                    <b>ProjeHUB</b> içinde elektrik, makine, inşaat mühendisliği gibi farklı disiplinlerdeki proje müelliflerinin projelerini otomatik olarak hazırlamaları için her bir disiplin için farklılaştırılmış <b>Proje Çizim Editörü</b> bulunacaktır. Kullanıcı hazırlayacağı proje türüne göre istediği Editörü kullanarak projesini çizecektir.
                    <br/>
                    Bunun yanı sıra kullanıcı <b>Proje Hazırlama Editörü</b> ile hazırladığı projesini <b>DEPO</b> alanında saklayabilir ve istediği kişiler ile istediği kısımlarını paylaşabilir ve onay-kontrol işlemlerine gönderebilir.
                    Yapıların tamamlanarak iskanlarının alınmasına kadar gerçekleşen proje süreçleri çok karmaşıktır. Müellifler tarafından hazırlanan projeleri onaylayan kontrol eden kurumlar ve yükleniciler de ProjeHUB üzerinden süreci takip edebilir zaman ve iş kaybını önleyecek şekilde sürecin parçası olabilir. Tüm bu süreçleri otomatik yönetmek için <a>ProjeHUB</a> da <a>Süreç Kontrol özelliği</a> olacaktır.

                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th> Müşteri Grubu </th>
                          <th> Proje Çizim Editörleri <br />
                            (PÇE)
                          </th>

                          <th> DEPO  </th>
                          <th> Süreç Kontrol </th>
                          <th> Otomatik Proje Kontrol   </th>
                          <th> Reklam </th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>   Elektrik Proje Müellifleri   </td>
                          <td>
                            <ul>

                              <li>İç Tesisat</li>
                              <li>Mesken-Buro-işyeri</li>
                              <li>Okul Hastane Otel</li>
                              <li>Fabrika- Endüstriyel Yapı</li>
                              <li>Trafo</li>
                              <li>Şebeke</li>
                              <li>Şantiye/Sulama vb..</li>

                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}> <CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}  ><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Mimari</td>
                          <td>
                            <ul>
                              <li>Mesken-Buro-işyeri</li>
                              <li>Okul Hastane Otel</li>
                              <li>Fabrika- Endüstriyel Yapı</li>
                              <li>Yüksek Yapı </li>
                              <li>AVM</li>
                              <li>vb...</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Makina Proje Müellifleri   </td>
                          <td>
                            <ul>
                              <li>Sıhhi Tesisat </li>
                              <li>Isıtma Soğutma Tesisatı </li>
                              <li>Yangın söndürme Projesi </li>
                              <li>Asansör vb... </li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> İnşaat Mühendisleri  </td>
                          <td>
                            <ul>
                              <li>Statik Hesap Editörleri</li>
                              <li>Mesken-Buro-işyeri</li>
                              <li>Okul Hastane Otel</li>
                              <li>Fabrika- Endüstriyel Yapı</li>
                              <li>Çatı Projeleri</li>
                              <li>Çelik Projeleri</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Belediyeler  </td>
                          <td>  Serbest Çalışan tüm mimar ve mühendislerin kullandıkları Proje Çizim Editörleri    </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td>   Elektrik Dağıtım Firmaları  </td>
                          <td>  Elektrik mühendislerin kullandıkları Proje Çizim Editörleri
                            <ul>
                              <li>Aydınlatma Proje</li>
                              <li>Trafo Proje</li>
                              <li>Şebeke Proje</li>
                              <li>vb Proje</li>
                            </ul>
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Yapı Denetim Firmaları  </td>
                          <td>  --    </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                        </tr>
                        <tr>
                          <td> Yüklenici  Firmaları  </td>
                          <td> Kısmen kendi iş alanlarındaki Proje çizim editörleri   </td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                        </tr>
                        <tr>
                          <td> Üretici  Firmaları  </td>
                          <td> -- </td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "beige" }}>--</td>
                          <td style={{ backgroundColor: "greenyellow" }}><CheckIcon /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="pazar" style={{ margin: "30px" }}>
                  Hedef pazarımız öncelikli olarak inşaat sektöründe projecilik yapan mühendislerdir. Bu kitle için proje onayı ve denetleme kurumlarının çelişkili istekleri ve mevzuat arasında kalmaları ek çalışma gerektirmektedir. Sürekli revize edilen ve değişiklik istenilen projeler müelliflerde çok fazla zaman ve emek kaybına sebep olmaktadır. İlerleyen zamanlarda onay ve kontrol kurumları ile inşaat sektörünün çoğu hedef pazara dahil edilebilecektir.
                    <br />
                    <br />
                    <h4>BİREYSEL MÜŞTERİ HAVUZU    55.000 </h4>
                    <div style={{ margin: "20px" }}>
                      <h3>Elektrik Grubu Potansiyel Müşteri Sayısı      10.000</h3>
                      <ul>
                        <li>Elektrik Mühendisleri Odası (EMO) üye sayısı 65.895</li>
                        <li> Bu üyelerden 5000 i SMM olarak kayıtlı.</li>
                        <li>Orta Karadeniz Bölgesi Elektrik Dağıtım firması ve EMO verilerine göre bu bölgedeki elektrik proje müellifi sayısı 650 civarında. Bunların 420 si fen adamı 230 u elektrik mühendisi. Fen adamları sayısı mühendis sayısının 1.8 katı.</li>
                        <li>Orta Karadenizin bölgesinin tüm nüfusa oranı 81/5.7 =14</li>
                        <li>Nüfusa oranla Ülke geneli Müellif sayısı = 650x14=9100</li>
                        <li>Dağıtım Firması Sayısına oranla Ülke geneli Müellif sayısı = 650x21=1350</li>
                        <li>Mühendis-Fen adamı oranına göre 5000x1.8=9000 fen adamı var. Toplam Müellif sayısı 5000+9000= 14.000 kişi</li>
                        <li>Yukarıdaki verilere göre Elektrik Proje Müelliflerinin sayısı en az 10.000 olarak alınabilir.</li>
                      </ul>

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Mimari Grubu Potansiyel Müşteri Sayısı      20.000</h3>
                      Mimarlar Odası üye sayısı 68.478 . Bu üyelerin 20.ooo kadarı serbest olarak çalışıyor. Mimar sayısının elektrik mühendisi sayısından fazla olması normaldir. Çünkü mimari proje hazırlanma süreci çok daha uzundur. Mimarlar aylık bir- iki proje üzerinde çalışırlarken elektrik mühendisleri bir ayda on yada yirmi projeye kadar çıkabilir. Genellikle bir elektrik mühendisi bir kaç farklı mimarla çalışmaktadır.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Makina Grubu Potansiyel Müşteri Sayısı      15.000</h3>
                      Makina mühendisleri odası üye sayısı 121.676 dır. Serbest olarak çalışan makina mühendisleri sayısı ise 15.000 kişi civarındadır.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Static/İnşaat Grubu Potansiyel Müşteri Sayısı      10.000</h3>
                      İnşaat mühendisleri odası üye sayısı 137.365 dır. Serbest olarak çalışan makina mühendisleri sayısı ise 10.000 kişi civarındadır.

                    </div>
                    <h4>KURUMSAL MÜŞTERİ HAVUZU    Kamu:4.048 & Özel Sektör:200K </h4>
                    <div style={{ margin: "20px" }}>
                      <h3>Belediyeler       1.389</h3>
                      Türkiye'de 30 büyükşehir belediyesi, 51 il belediyesi (merkez ilçe), 922 ilçe belediyesi ve 386 belde belediyesi olmak üzere toplam 1.389 belediye bulunmaktadır. Belediyeler yapı ruhsatları için proje inceleme ve onaylama işlemleri yapmaktadırlar. Diğer yandan kendi bünyelerinde de pek çok proje hazırlamaktadılar. ProjeHUB'ın hem proje Çizim Editörleri hemde Otomatik Süreç Tak ip, Otomatik proje inceleme vb gibi özelliklerini kullanabilirler.


                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Yapı Denetim Firmaları     2.476</h3>
                      Yapı Denetim Firmaları tüm disiplinlerdeki yapı projelerinin Teknik açıdan uygulanabilir olup olmadığını ve Mevzuata ve standartlara uygunluğunu incelemek zorundadırlar. Otomatik Proje Kontrol ve DEPO gibi özellikleri kullanabilirler.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Telekom Müdürlükleri         81</h3>
                      Telekom Müdürlükleri de Eletrik Dağıtım Firmaları gibi hem proje kontrolü hemde proje imalatı yapmaktadırlar.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>İtfaiye Müdürlükleri         81</h3>
                      Özellikle büyük şehirlerde itfaiye müdürlüklerinde yapılar için hazırlanan Yangın algılama , Yangın söndürme ve Mimari projeler incelenmektedir.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Yüklenici Firmalar       150K+++ </h3>
                      İnşaat sektörü içindeki farklı disiplinlerdeki Yüklenici firmaların çoğu potansiyel müşteridir. Çünkü bu firmalar genellikle müellifler tarafından hazırlananan projelerin üretimini yaparlar. Böyle olmadığında ise çoğu zaman kendi üretimlerini projelendirmek zorundadırlar. Örneğin Elektrik Dağıtım firmalarına şebeke yapan yüklenici firmalar yaptıkları şebekelerini projelendirmek zorundadırlar. Bu müşteri grubunun tam sayısını tesbit etmek zor olduğu için fikir ver mesi açısından TOBB ye kayıtlı yüklenici firmaların sayısı 150.000 olarak alınabilir.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Üretici Firmalar       50K+++</h3>
                      Üretici firmalar için ProjeHUB çok iyi bir reklam olanı olabilir. Diğer yandan üretici firmaların ihtiyaç duyacağı pek çok veri projeHUB üzerinde otomatik olarak oluşacaktır. İnternette yapılan araştırmalara göre Türkiye'deki Üretici firma sayısının 50.000 civarında olduğu görülmektedir.

                    </div>
                    <div style={{ margin: "20px" }}>
                      <h3>Static/İnşaat Grubu Potansiyel Müşteri Sayısı      10.000</h3>
                      İnşaat mühendisleri odası üye sayısı 137.365 dır. Serbest olarak çalışan makina mühendisleri sayısı ise 10.000 kişi civarındadır.

                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="ismodel" style={{ margin: "30px" }}>
                    Kullanıcılara ürünün satılması için ön görülen 3 temel model vardır.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>İş Modeli</th>
                          <th>Açıklama <br /> </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Aylık Üyelik Satışları</td>
                          <td>
                            Aylık yada yıllık abonelikler olarak kullanıcılara kullandıkları özelliklere göre paket satışları yapılacak
                          </td>
                        </tr>
                        <tr>
                          <td>Proje bazlı  </td>
                          <td>Kullanıcılar hazırladıkları projeler için ayrı ayrı ödeme yapacaklar.
                          </td>
                        </tr>
                        <tr>
                          <td>Kurumsal Model</td>
                          <td> Kurumsal kullanıcılar için daha yüksek fiyatlı ve çok daha fazla özellikli yada özel geliştirilen özellikler için belirlenen özel fiyatlarla yıllık abonelikler şeklinde.
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="musteriTurleri" style={{ margin: "30px" }}>
                    Müşterileri hem uzmanlık alanlarına (mimar, Eletrik,statik vb..) hemde kurumsal yada bireysel müşteri olmalarına göre gruplandırabiliriz.
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>Müşteri Grubu</th>
                          <th>Bireysel Müşteriler<br />
                          </th>
                          <th>Kurumsal Müşteriler</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Elektrik   Grubu</td>
                          <td  >
                            <ul>
                              <li>Elektrik Mühendisleri</li>
                              <li>Elektrik Teknikerlei</li>
                              <li>Elektrik Teknisyenleri</li>
                              <li>Elektrikçiler </li>
                            </ul>
                          </td>
                          <td rowSpan={4}>

                            <ul>
                              <li>Elektrik Dağıtım Firmaları</li>
                              <li>Belediyeler</li>
                              <li>Telekom</li>
                              <li>Yapı Denetim Firmaları </li>
                              <li>Yüklenici Firmalar </li>
                              <li>Üretici Firmalar </li>
                              <li>İtfaiyeler     </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>Mimari Grup  </td>
                          <td>Architects   </td>
                        </tr>
                        <tr>
                          <td>Makina Grubu</td>
                          <td>
                            <ul>
                              <li>Makina Mühendisleri </li>
                              <li>Teknisyenler </li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>İnşaat Grubu</td>
                          <td>
                            <ul>
                              <li>İnşaat Mühendisleri </li>
                              <li>Teknisyenler </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="Satis" style={{ margin: "30px" }}>
                  ProjeHUB, verimliliği ölçülemez oranda arttırmaktadır. Önemli olan uygun bir fiyat ile yeterli müşteri kitlesine ulaşmaktır. Bunun için internet reklamları, Elektrik Mühendisleri Odası, ilgili mühendislik fakültelerinin son sınıf üniversite öğrencilerine yönelik çalışmalar ve kişisel bağlantılarımız kullanılacaktır. ProjeHUB ile yeni bir piyasa oluşmasını umuyoruz. Oluşacak bu yeni piyasanın ilk firması olarak uygun fiyat ve kaliteli hizmet ile rekabetteki avantajı koruyabileceğimizi düşünüyoruz.
                    <br />Satış stratejimiz kısaca şu adımları izleyecektir.
                    <ul>
                      <li><b>ilk 6 ay:</b> demo ürünler geliştirilecektir.</li>
                      <li><b>ikinci 6 ay:</b> boyunca demo ürünler bedava olarak kullanıma sunulacak ve bu süre boyunca kullanıcı sayısı arttırılmaya çalışılacaktır. Oluşan müşteri portföyüne ve müşterileri verilerine göre fiyatlandırma çalışmaları yapılacaktır.</li>
                      <li><b>ikinci yılın başından itibaren:</b> tamamlanmış ürünler ücretli olarak satışa sunulmaya başlanacaktır.</li>
                    </ul>
                    Proje Çizim Editörleri ile üye olmayan kullanıcılar dahi ücretsiz bir şekilde projelerini hazırlayabileceklerdir. Sadece bu projeleri bilgisayarlarına indiremez sadece ekranda görüntüleyebilirler. Bu durum herkesin ProjeHUB ın proje Editörlerini deneyimlemesine, ne kadar hızlı ve doğru bir şekilde projeleri hazırladığını görmesine olanak tanır.
                    <img src={kulzam}/> 
                    <Table striped bordered hover responsive style={{ textAlign: "center", verticalAlign: "middle" }}>
                      <thead>
                        <tr>
                          <th>Periyod</th>
                          <th>İşlem</th>
                          <th>Satış</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1. ay</td>
                          <td> İlk ay ekip kurulacak ve gerekli çalışma ortamı düzenlenecektir. Daha sonrasında proje ekibe anlatılacak. CAD sistemleri ve elektrik projeleri hakkında ekip eğitilerek projeHUB konseti ekibe öğretilecektir. ProjeHUB'ın temel alt yapısı ve senaryoları tasarlanacaktır.
                          </td>
                          <td> --</td>

                        </tr>
                        <tr>
                          <td>Sonraki 5 Ay</td>
                          <td>Proje çizim editörleri geliştirilmeye başlanacaktır. Kat planı çizim özellikleri hariç Mesken Projelerini hazırlayan Çizim Editörleri geliştirilecek ve aynı zamanda Süreç Kontrol Özellikleri geliştirilecektir. Bu süre boyunca demo ürünler geliştirilip 6. ayın sonunda demo ürünler ücretsiz olarak kullanıcıya sunulacaktır.
                          </td>
                          <td   > --</td>
                        </tr>
                        <tr>
                          <td>İkinci 6 Ay</td>
                          <td>
                            Özellikle Mesken vb iç tesisat projelerindeki Kat Planı Çizim özellikleri tamamlanacaktır. Demo ürünler tamamlanarak kullanıcıya ücretli olarak sunulacak ürünlerin geliştirilmesi tamamlanacaktır. Bu dönem içinde kullanıcı sayısı arttırılmaya çalışılacaktır. Kullanıcılardan gelen verilere göre fiyatlandırma çalışmaları yapılacaktır.
                          </td>
                          <td   > --</td>
                        </tr>
                        <tr>
                          <td>Sonraki yıllar</td>
                          <td>
                            Bu dönemde ürün kullanımları ücretli hale gelecektir. Kullanıcı dönüşleri ve piyasa araştımaları yapılarak ProjeHUB'ın büyüme senaryoları geliştirilecektir. Ya elektrik grubunda globale açılma yada diğer gruplardaki özelliklerin geliştirilmesi için gerekli araştırmalar yapılarak ProjeHUB ekosistemi geliştirilecektir.
                          </td>
                          <td style={{ backgroundColor: "greenyellow" }}> <CheckIcon /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </Tab.Pane>
                  <Tab.Pane eventKey="rakipler" style={{ margin: "30px" }}>
                  Yaptığımız araştırmada henüz ürünümüzün bir rakibi olmadığını düşünüyoruz. Çünkü ürünümüz piyasada benzeri olmayan web tabanlı bir ürün ve aynı zamanda tüm mühendislik disiplinlerine hitap edebilecek bir topluluk hizmeti sunmaktadır. Piyasadaki en yakın ürünler tek bir mühendislik disiplinin sadece özel bir dalında çözüm sunmaktadır ve bu ürünlerin tamamı masa üstü uygulamalardır. Bu programlar program satışı şeklinde olup fiyat bakımından ise çok maliyetlidirler.
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Rakipler</th>
                          <th>Disiplin</th>
                          <th>Alan</th>
                          <th>Web</th>
                          <th>Bulut</th>
                          <th>Fiyat</th>
                          <th>Süreç Kontrol</th>
                          <th>Otomatik Çizim</th>
                          <th>HUB</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>ProjeHUB</td>
                          <td>Elektrik</td>
                          <td>İçtesisat (Mesken-işyeri-Okul-Hastahane-Endüstriyel Yapı vb.),Şebeke (A.G.-O.G.), Trafo (direk-Bina tipi),</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>1000tl/Ay</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                        </tr>
                        <tr>
                          <td>B-Pro</td>
                          <td>Elektrik</td>
                          <td>Şebeke</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}>170.000tl +yıllık 15.000tl</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "greenyellow" }}>var</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                        </tr>
                        <tr>
                          <td>AutoCAD</td>
                          <td>----</td>
                          <td>----</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}> 6.374 tl/Ay </td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                          <td style={{ backgroundColor: "red" }}>yok</td>
                        </tr>

                      </tbody>
                    </Table>

                  </Tab.Pane>
                  <Tab.Pane eventKey="rekabet" style={{ margin: "30px" }}>
                  Geliştirmekte olduğumuz ürünün muadili piyasada henüz yoktur. Piyasa da ki ürünler ya sadece basit mesken projelerini kısmen çizer (1 ürün) yada sadece şebeke projeleri çizer (1 ürün) . Bu iki üründe kurulum gerektirir ve sadece elektrik mühendislerine ait özel bir alanda kısmı çözümler sunar. Ürünümüz web tabanlı olacak ve kurulum gerektirmeyecektir. Her yerden kullanılabilecek ve bir topluluk alt yapısı sunacaktır.


                    <br />
                    <ul>

                      <li>ProjeHUB, Proje piyasasındaki tüm Mühendislik disiplinlerine hitap edebilecek alt yapıya sahip olacaktır.</li>
                      <li>ProjeHUB, Proje Piyasasındaki tüm taraflara hitap edebilecek şekilde tasarlanacaktır.</li>
                      <li>ProjeHUB, sadece otomatik proje hazırlama editörlerinden oluşmaz. Şu ana kadar proje piyasasında hiç geliştirilmemiş olan <b>Süreç Kontrol Sistemi</b> ve  <b>Depo</b> alanı gibi özellikleri olan bir topluluk sistemini içinde barındırır.</li>
                    </ul>
                    Piyasada sadece tek bir mühendislik disiplininde tek tür projeleri hazırlayan masa üstü çizim uygulamaları vardır. Diğer yandan bu uygulamalar fiyat yönünden de oldukça pahalıdırlar.

                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </>}

    </div>
  )
}
