import { configureStore } from '@reduxjs/toolkit'
import cizimOzellikReducer from '../reducers/cizimOzellik'
//import membersReducer from '../reducers/membersSlice'
import userReducer from '../reducers/userSlice'
import usersProjectsReducer from '../reducers/usersProjectsSlice'
import sayacbolmeReducer from '../reducers/sayacbolmeSlice'
import adminReducer from '../reducers/adminSlice'
import direktipitrafoReducer from '../reducers/direktipiTrafoSlice'
import trafoDetayReducer from '../reducers/trafoDetaySlice'
import PanoDetayReducer from '../reducers/PanoDetaySlice'
import direkDetayReducer from '../reducers/direkDetaySlice'
import KompDetayReducer from '../reducers/KompDetaySlice'
import OGHatDetayReducer from '../reducers/ogHatDetaySlice'
import RapCetvelReducer from '../reducers/RaporCetvelCizimSlice'
import SemaReducer from '../reducers/SemaCizimSlice'
import GenelBilgilerReducer from '../reducers/GenelBilgilerSlice'

export default configureStore({
  reducer: {
    cizimOzellik: cizimOzellikReducer,
   // members:membersReducer,
    user:userReducer,
    usersProjects:usersProjectsReducer,
    sayacbolme:sayacbolmeReducer,
    admin:adminReducer,
    direktrafo:direktipitrafoReducer,
    trafoDetay:trafoDetayReducer,
    PanoDetay:PanoDetayReducer,
    direkDetay:direkDetayReducer,
    KompDetay:KompDetayReducer,
    OGHatDetay:OGHatDetayReducer,
    RapCetCiz:RapCetvelReducer,
    SemaCizim:SemaReducer,
    genelBilgiler:GenelBilgilerReducer,

  },
})