
import React, { useEffect, useState } from 'react'
import { Form, Table, Modal, Row, Col } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import {DEMIRDIREK} from '../Data/Malzeme'
import {DataGrid} from '@mui/x-data-grid';
import { UpdateParafudr,UpdateParafudrInis,UpdateParafudrGiris,UpdateParafudrKonum,UpdateParafudrTopraklama } from '../reducers/direkDetaySlice'
 

export default function TrafoDirekDetay(props) {

  const direkDetaylar = useSelector((state) => state.direkDetay)
  const dispatch = useDispatch()
  const [toolshow, setToolShow] = useState(props.show);
  const [bgcolor, setBgcolor] = useState("white");
  const [parafudr,setParafudr]=useState(direkDetaylar.parafudr);
  const [konum,setKonum]=useState(direkDetaylar.parafudrKonum);
  const [inis,setInis]=useState(direkDetaylar.parafudrInis);
  const [giris,setGiris]=useState(direkDetaylar.parafudrGiris);
  const [topraklama,setToprak]=useState(direkDetaylar.parafudrTopraklama);
 

  function Show() { setToolShow(!toolshow) }

  useEffect(() => {
  }, [])
  useEffect(() => {    
    dispatch(UpdateParafudrKonum(konum))
  }, [konum])
  useEffect(() => {    
    dispatch(UpdateParafudr(parafudr))
   }, [parafudr])
   useEffect(() => {    
    dispatch(UpdateParafudrInis(inis))
   }, [inis])
   useEffect(() => {    
    dispatch(UpdateParafudrGiris(giris))
   }, [giris])

   useEffect(() => {    
    dispatch(UpdateParafudrTopraklama(topraklama))
   }, [topraklama])


  return (
    <React.Fragment >
      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Parafudr Detayları</th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block" } : { display: "none" }}>
          <tr>
            <td>
              Parafudr
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={parafudr} onChange={e=>setParafudr(e.target.value)}  >
                <option value="yok">Yok</option>
                <option value="36kVA 10kA">36kVA 10kA</option>          
                <option value="36kVA 16kA">36kVA 16kA</option>                    
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>
              Konum
            </td>
            <td style={{ width: "50%", background: bgcolor }} >
            <Form.Select aria-label="example" defaultValue={konum} onChange={e=>setKonum(e.target.value)}  >
                <option value="once">Seksiyonerden Önce</option>          
                <option value="sonra">Seksiyonerden Sonra</option>          
              </Form.Select>
            </td>
          </tr>  
          <tr>
            <td> 
              Hat İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} onChange={e=>setGiris(e.target.value)}>
            <Form.Select aria-label="example" defaultValue={giris}  >
              <option  value="Ф6">Φ6</option> 
              <option  value="Ф8">Φ8</option>       
            </Form.Select>
            </td>
            </tr>
 
          <tr>
            <td>
             Toprak İniş İletkeni
            </td>
            <td style={{ width: "50%", background: bgcolor }} onChange={e=>setInis(e.target.value)}>
            <Form.Select aria-label="example" defaultValue={inis}  >
              <option  value="1x50mm2 NYY">1x50mm2 NYY</option> 
              <option  value="1x70mm2 NYY">1x70mm2 NYY</option> 
              <option  value="1x95mm2 NYY">1x95mm2 NYY</option> 
              <option  value="1x95mm2 ÇelikÖrgü">1x95mm2 ÇelikÖrgü</option>     
            </Form.Select>
            </td>
            </tr>
          <tr>
            <td>
             Topraklama
            </td>
            <td style={{ width: "50%", background: bgcolor }} onChange={e=>setToprak(e.target.value)}>
            <Form.Select aria-label="example" defaultValue={topraklama}  >
              <option  value="Galvaniz Kazık">Galvaniz Kazık</option>     
              <option  value="Müşterek">Müşterek</option>     
            </Form.Select>
            </td>
            </tr>
 
 
  
        </tbody>
      </Table>




    </React.Fragment>





  )

}
