
import React, { useEffect, useState } from 'react'
import { Form, Table, Row, InputGroup } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { iller } from '../Data/Ayarlar'
import { UpdateM_AdSoyad, UpdateM_OdaSiciNo, UpdateM_DiplomaNo, UpdateM_BuroNo, UpdateM_TelNo, UpdateM_FirmaAdi, UpdateM_FirmaAdresi, UpdateM_VergiDairesi, UpdateM_VergiNo, UpdatePY_AdSoyad, UpdatePY_Adres, UpdatePY_VergiDairesi, UpdatePY_VergiNo, UpdatePY_Telefon, UpdatePY_Mail, UpdateA_AdSoyad, UpdateA_Il, UpdateA_Ilce, UpdateA_Mahalle, UpdateA_Sokak, UpdateA_No, UpdateA_Adres, UpdateA_Ada, UpdateA_Pafta, UpdateA_Parsel, UpdateA_Alan, UpdateYO_Kullanim, UpdateYO_Alan, UpdateYO_KatSayi, UpdateYO_BagBolSay, UpdateYO_CizimTarih, UpdateYO_Cizen, UpdateYO_YapiSinifi, UpdateYO_ProjeNo, UpdateYO_Olcek, UpdateO_Kurum, UpdateYO_Sure } from '../reducers/GenelBilgilerSlice'


export default function GenelBilgiler(props) {
  
  const MuellifBilgiler = useSelector((state) => state.user).activeUser


 
  const dispatch = useDispatch()
  const [toolshow, setToolShow] = useState(false);   //props.show 
  const [toolshowMuellif, setToolShowMuellif] = useState(false);
  const [toolshowYaptiran, setToolShowYaptiran] = useState(false);
  const [toolshowArsa, setToolShowArsa] = useState(true);
  const [toolshowYapi, setToolShowYapi] = useState(false);
  const [toolshowOnayK, setToolShowOnayK] = useState(false);
  const [bgcolor, setBgcolor] = useState("white");
  const [il, setIl] = useState(6);
  const [secimil, setSecimIl] = useState(6);
  const [secimilce, setSecimIlce] = useState("Akyurt");
  const [ilceler, setIlceler] = useState(null);
  const [mah, setMah] = useState("");
  const [sok, setSok] = useState(null);
  const [arsaAd, setArsaAd] = useState(null);
  const [adresNo, setAdresNo] = useState(null);
  const [ada, setAda] = useState(null);
  const [pafta, setPafta] = useState(null);
  const [parsel, setParsel] = useState(null);
  const [adres, setAdres] = useState("");

  const [muAdsoyad, setMuAdsoyad] = useState(MuellifBilgiler.MuellifAdiSoyad);//MuellifBilgiler.MuellifAdiSoyad
  const [muOdaNo, setMuOdaNo] = useState("");//MuellifBilgiler.DiplomaNo
  const [muDiplomaNo, setMuDiplomaNo] = useState("");//MuellifBilgiler.DiplomaNo
  const [muBuroNo, setMuBuroNo] = useState("");//MuellifBilgiler.DiplomaNo
  const [muTelNo, setMuTelNo] = useState("");//MuellifBilgiler.DiplomaNo
  const [muFirmaAd, setMuFirmaAd] = useState("");//MuellifBilgiler.DiplomaNo
  const [muFirmaAdres, setMuFirmaAdres] = useState("");//MuellifBilgiler.DiplomaNo
  const [muVergiDaire, setMuVergiDaire] = useState("");//MuellifBilgiler.DiplomaNo
  const [muVergiNo, setMuVergiNo] = useState("");//MuellifBilgiler.DiplomaNo

  const [pyAdsoyad, setPyAdsoyad] = useState("");
  const [pyAdres, setPyAdres] = useState("");
  const [pyVergiDaire, setPyVergiDaire] = useState("");
  const [pyVergiNo, setPyVergiNo] = useState("");

  const [yKatSayi, setKatSayi] = useState("");
  const [yBagBol, setYBagBol] = useState("");
  const [ySinif, setYSinif] = useState("");
  const [ySure, setYSure] = useState("18");
  const [yAlan, setYAlan] = useState("");
  const [yKullanim, setYKullanim] = useState("");
  const [yCizen, setYCizen] = useState("");
  const [yTarih, setYTarih] = useState( "");
  const [yOlcek, setYOlcek] = useState("");
  const [yProjeNo, setYProjeNo] = useState("");

  const [kurumTur, setKurumTur] = useState("");
  const [onayKurum, setOnayKurum] = useState("");
  const [ilOsbler, setIlOsbler] = useState(iller[il].osb);


  function Show() { setToolShow(!toolshow) }
  function ShowMuellif() { setToolShowMuellif(!toolshowMuellif) }
  function ShowYaptiran() { setToolShowYaptiran(!toolshowYaptiran) }
  function ShowArsa() { setToolShowArsa(!toolshowArsa) }
  function ShowYapi() { setToolShowYapi(!toolshowYapi) }
  function ShowOnayK() { setToolShowOnayK(!toolshowOnayK) }

  useEffect(() => {
    dispatch(UpdateM_AdSoyad(MuellifBilgiler.MuellifAdiSoyad))   
    dispatch(UpdateM_OdaSiciNo(MuellifBilgiler.OdaNo))   
    dispatch(UpdateM_DiplomaNo(MuellifBilgiler.DiplomaNo))   
    dispatch(UpdateM_BuroNo(MuellifBilgiler.BuroNo))   
    dispatch(UpdateM_TelNo(MuellifBilgiler.FirmaTelefonNo))   
    dispatch(UpdateM_FirmaAdi(MuellifBilgiler.Firma))   
    dispatch(UpdateM_FirmaAdresi(MuellifBilgiler.Adresi))   
    dispatch(UpdateM_VergiDairesi(MuellifBilgiler.vergiDairesi))   
    dispatch(UpdateM_VergiNo(MuellifBilgiler.vergiNo))   
  
  }, [])

  useEffect(() => {

  }, [adres, sok, adresNo])
  /* 
  useEffect(() => {
    setMuAdsoyad(MuellifBilgiler.MuellifAdiSoyad);

  }, [MuellifBilgiler])
 */
  useEffect(() => {
    setIlceler(iller[il].ilceler)
    setSecimIl(iller[il].il)

  
  }, [il])
  
  useEffect(() => {    
    dispatch(UpdateO_Kurum(onayKurum))
  }, [onayKurum])

  useEffect(() => {
    if(onayKurum==="OSB"){
      setIlOsbler(iller[il].osb)
    }else{
      setOnayKurum(iller[il].dagitim)
    }
    
  }, [kurumTur])

  useEffect(() => {
    AdresOlustur()
  }, [mah, sok, adresNo])

  useEffect(() => {     
      setIlOsbler(iller[il].osb)       
      setOnayKurum(iller[il].dagitim)    
    AdresOlustur()
    dispatch(UpdateA_Il(secimil))
  }, [secimil])
  useEffect(() => {
    AdresOlustur()
    dispatch(UpdateA_Ilce(secimilce))
  }, [secimilce])

  const MuAdSoyadSec = (e) => { e.preventDefault(); setMuAdsoyad(e.target.value); dispatch(UpdateM_AdSoyad(e.target.value)) };
  const MuOdaNoSec = (e) => { e.preventDefault(); setMuOdaNo(e.target.value); dispatch(UpdateM_OdaSiciNo(e.target.value)) };
  const MuDiplomaNoSec = (e) => { e.preventDefault(); setMuDiplomaNo(e.target.value); dispatch(UpdateM_DiplomaNo(e.target.value)) };
  const MuBuroNoSec = (e) => { e.preventDefault(); setMuBuroNo(e.target.value); dispatch(UpdateM_BuroNo(e.target.value)) };
  const MuTelNoSec = (e) => { e.preventDefault(); setMuTelNo(e.target.value); dispatch(UpdateM_TelNo(e.target.value)) };
  const MuFirmaAdSec = (e) => { e.preventDefault(); setMuFirmaAd(e.target.value); dispatch(UpdateM_FirmaAdi(e.target.value)) };
  const MuFirmaAdresSec = (e) => { e.preventDefault(); setMuFirmaAdres(e.target.value); dispatch(UpdateM_FirmaAdresi(e.target.value)) };
  const MuVergiDairesiSec = (e) => { e.preventDefault(); setMuVergiDaire(e.target.value); dispatch(UpdateM_VergiDairesi(e.target.value)) };
  const MuVergiNoSec = (e) => { e.preventDefault(); setMuVergiNo(e.target.value); dispatch(UpdateM_VergiNo(e.target.value)) };

  //----------------------------------------------------------------------------------
  const PYAdSoyadSec = (e) => { e.preventDefault(); setPyAdsoyad(e.target.value); dispatch(UpdatePY_AdSoyad(e.target.value)) };
  const PYAdres = (e) => { e.preventDefault(); setPyAdres(e.target.value); dispatch(UpdatePY_Adres(e.target.value)) };
  const PYVergiDaire = (e) => { e.preventDefault(); setPyVergiDaire(e.target.value); dispatch(UpdatePY_VergiDairesi(e.target.value)) };
  const PYVergiNo = (e) => { e.preventDefault(); setPyVergiNo(e.target.value); dispatch(UpdatePY_VergiNo(e.target.value)) };

  //---------ARSA------------------------------------------
  const MahalleSec = (e) => { e.preventDefault(); setMah(e.target.value); dispatch(UpdateA_Mahalle(e.target.value)) };
  const SokakSec = (e) => { e.preventDefault(); setSok(e.target.value); dispatch(UpdateA_Sokak(e.target.value)) };
  const AdresNoSec = (e) => { e.preventDefault(); setAdresNo(e.target.value); dispatch(UpdateA_No(e.target.value)) };
  const A_AdSoyad = (e) => { e.preventDefault(); setArsaAd(e.target.value); dispatch(UpdateA_AdSoyad(e.target.value)) };
  const A_Ada = (e) => { e.preventDefault(); setAda(e.target.value); dispatch(UpdateA_Ada(e.target.value)) };
  const A_Pafta = (e) => { e.preventDefault(); setPafta(e.target.value); dispatch(UpdateA_Pafta(e.target.value)) };
  const A_Parsel = (e) => { e.preventDefault(); setParsel(e.target.value); dispatch(UpdateA_Parsel(e.target.value)) };

  //---------YAPI------------------------------------------

  const Y_KatSayi = (e) => { e.preventDefault(); setKatSayi(e.target.value); dispatch(UpdateYO_KatSayi(e.target.value)) };
  const Y_BagBol = (e) => { e.preventDefault(); setYBagBol(e.target.value); dispatch(UpdateYO_BagBolSay(e.target.value)) };
  const Y_Sinif = (e) => { e.preventDefault(); setYSinif(e.target.value); dispatch(UpdateYO_YapiSinifi(e.target.value)) };
  const Y_Sure = (e) => { e.preventDefault(); setYSure(e.target.value); dispatch(UpdateYO_Sure(e.target.value)) };
  const Y_Alan = (e) => { e.preventDefault(); setYAlan(e.target.value); dispatch(UpdateYO_Alan(e.target.value)) };
  const Y_Kullanim = (e) => { e.preventDefault(); setYKullanim(e.target.value); dispatch(UpdateYO_Kullanim(e.target.value)) };
  const Y_Cizen = (e) => { e.preventDefault(); setYCizen(e.target.value); dispatch(UpdateYO_Cizen(e.target.value)) };
  const Y_Tarih = (e) => { e.preventDefault(); setYTarih(e.target.value); dispatch(UpdateYO_CizimTarih(e.target.value)) };
  const Y_Olcek = (e) => { e.preventDefault(); setYOlcek(e.target.value); dispatch(UpdateYO_Olcek(e.target.value)) };
  const Y_ProjeNo = (e) => { e.preventDefault(); setYProjeNo(e.target.value); dispatch(UpdateYO_ProjeNo(e.target.value)) };




  function AdresOlustur() {
    let Adres = mah + " mah.  " + sok + " Sok. No:" + adresNo + "  " + secimilce + "/" + secimil
    if (adresNo === null) {
      Adres = mah + " mah.  " + sok + " Sok.    " + secimilce + "/" + secimil
    }
    setAdres(Adres)
    dispatch(UpdateA_Adres(Adres))
  }
  return (

    <React.Fragment >



      <Table
        responsive
        size="sm"
        striped
        className='rounded-3 mt-1 mb-0 ms-1   '
        style={{ width: "98%", background: bgcolor }}
      >
        <thead style={{ width: "100%", background: bgcolor }}  >
          <tr>
            <th colSpan="2" className='pe-1' onClick={Show}>Genel Bilgiler  </th>
          </tr>
        </thead>
        <tbody style={toolshow ? { display: "block", maxHeight: "300px", overflowY: "scroll", } : { display: "none" }}>
          <tr  >
            <td>
              {/* ------------------------MÜELLİF BİLGİLERİ------------------------------- */}

              <Row onClick={ShowMuellif} className="m-1 w-100" style={{ width: "100%" }}>Müellif Bigileri</Row>
              <Row style={toolshowMuellif ? { display: "block" } : { display: "none" }}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">İsim Soyisim</InputGroup.Text>
                  <Form.Control onChange={MuAdSoyadSec}
                    placeholder={MuellifBilgiler.MuellifAdiSoyad}

                  /> </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Oda No </InputGroup.Text>
                  <Form.Control onChange={MuOdaNoSec}
                    placeholder={MuellifBilgiler.OdaNo}
                  /> </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Buro No </InputGroup.Text>
                  <Form.Control onChange={MuBuroNoSec}
                    placeholder={MuellifBilgiler.BuroNo}
                  /> </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Diploma No </InputGroup.Text>
                  <Form.Control onChange={MuDiplomaNoSec}
                    placeholder={MuellifBilgiler.DiplomaNo}
                  /> </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Firma </InputGroup.Text>
                  <Form.Control onChange={MuFirmaAdSec}
                    placeholder={MuellifBilgiler.Firma}
                  /> </InputGroup>

                <InputGroup>
                  <InputGroup.Text>Firma Adresi</InputGroup.Text>
                  <Form.Control as="textarea" aria-label="With textarea" onChange={MuFirmaAdresSec}
                    placeholder={MuellifBilgiler.Adresi} />
                </InputGroup>

                <InputGroup>
                  <InputGroup.Text>Firma TelefonNo</InputGroup.Text>
                  <Form.Control as="textarea" aria-label="With textarea" onChange={MuTelNoSec}
                    placeholder={MuellifBilgiler.MuelllifTelefonNo} />
                </InputGroup>

                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Vergi Dairesi</InputGroup.Text>
                  <Form.Control onChange={MuVergiDairesiSec}
                    placeholder={MuellifBilgiler.vergiDairesi}

                  /> </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Vergi No</InputGroup.Text>
                  <Form.Control onChange={MuVergiNoSec}
                    placeholder={MuellifBilgiler.vergiNo}

                  /> </InputGroup>
              </Row>
              {/* ------------------------PROJEYİ YAPTIRAN BİLGİLERİ------------------------------- */}
              <hr></hr>
              <Row onClick={ShowYaptiran} className="m-1 w-100" >Projeyi Yaptiran</Row>
              <Row style={toolshowYaptiran ? { display: "block" } : { display: "none" }}>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">İsim Soyisim</InputGroup.Text>
                  <Form.Control onChange={PYAdSoyadSec}
                    placeholder=""
                  /> </InputGroup>




                <InputGroup>
                  <InputGroup.Text>Adres</InputGroup.Text>
                  <Form.Control as="textarea" onChange={PYAdres} placeholder={"Adres"} />
                </InputGroup>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">Vergi Dairesi</InputGroup.Text>
                  <Form.Control onChange={PYVergiDaire}
                    placeholder={"Vergi Dairesi"}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">Vergi No</InputGroup.Text>
                  <Form.Control onChange={PYVergiNo}
                    placeholder={"Vergi No"}
                  /> </InputGroup>

              </Row>
              <hr></hr>
              {/* ------------------------ARSA  BİLGİLERİ------------------------------- */}

              <Row onClick={ShowArsa} className="m-1 w-100" >Arsa Özellikleri</Row>
              <Row style={toolshowArsa ? { display: "block" } : { display: "none" }}>

                <InputGroup >
                  <InputGroup.Text id="basic-addon1">İsim Soyisim</InputGroup.Text>
                  <Form.Control onChange={A_AdSoyad}
                    placeholder="İsim Soyisim"
                  /> </InputGroup>
                <InputGroup>
                  <InputGroup.Text>İl</InputGroup.Text>
                  <Form.Select aria-label="example" defaultValue={6} onChange={e => setIl(e.target.value)}  >
                    {iller.map((il, i) =>
                      (<option value={i} key={i}>{il.il}</option>)
                    )}
                  </Form.Select>
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text>İlçe</InputGroup.Text>

                  {ilceler !== null ?
                    <Form.Select aria-label="example" onChange={e => setSecimIlce(e.target.value)}   >
                      {ilceler.map((ilce, i) =>
                        (<option value={ilce} key={i}>{ilce}</option>)
                      )}
                    </Form.Select> : null}

                </InputGroup>
                <InputGroup  >
                  <InputGroup.Text     >Mahalle</InputGroup.Text>
                  <Form.Control onChange={MahalleSec}
                    placeholder="Mahalle"

                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Sokak</InputGroup.Text>
                  <Form.Control onChange={SokakSec}
                    placeholder="Sokak"

                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >No</InputGroup.Text>
                  <Form.Control onChange={AdresNoSec}
                    placeholder="No"

                  /> </InputGroup>
                <InputGroup>
                  <InputGroup.Text>Adres</InputGroup.Text>
                  <Form.Control as="textarea" aria-label="With textarea" defaultValue={adres} />
                </InputGroup>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">Ada</InputGroup.Text>
                  <Form.Control onChange={A_Ada}
                    placeholder="Ada"

                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">Pafta</InputGroup.Text>
                  <Form.Control onChange={A_Pafta}
                    placeholder="Pafta"

                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text id="basic-addon1">Parsel</InputGroup.Text>
                  <Form.Control onChange={A_Parsel}
                    placeholder="Parsel"
                  /> </InputGroup>
              </Row>
              <hr></hr>
              {/* ------------------------YAPI ÖZELLİKLERİ------------------------------- */}

              <Row onClick={ShowYapi} className="m-1 w-100" >Yapı Özellikleri</Row>
              <Row style={toolshowYapi ? { display: "block" } : { display: "none" }}>
                <InputGroup >
                  <InputGroup.Text  >Kullanım</InputGroup.Text>
                  <Form.Control onChange={Y_Kullanim}
                    placeholder={ "Kullanım"}
                  /> </InputGroup>

                <InputGroup >
                  <InputGroup.Text  >Alan (m²)</InputGroup.Text>
                  <Form.Control onChange={Y_Alan}
                    placeholder={"Alan"}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Kat Sayısı</InputGroup.Text>
                  <Form.Control onChange={Y_KatSayi}
                    placeholder={"Kat sayısı"}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Bağımsız Böl. Say.</InputGroup.Text>
                  <Form.Control onChange={Y_BagBol}
                    placeholder={"B.B. Say"}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Çizim Tarihi</InputGroup.Text>
                  <Form.Control onChange={Y_Tarih}
                    placeholder={" "}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Yapım Süresi</InputGroup.Text>
                  <Form.Control onChange={Y_Sure}
                    placeholder={ySure}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Yapı Sınıfı</InputGroup.Text>
                  <Form.Control onChange={Y_Sinif}
                    placeholder={"3A"}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Proje No</InputGroup.Text>
                  <Form.Control onChange={Y_ProjeNo}
                    placeholder={GenelBilgiler.YO_ProjeNo}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Çizen</InputGroup.Text>
                  <Form.Control onChange={Y_Cizen}
                    placeholder={" "}
                  /> </InputGroup>
                <InputGroup >
                  <InputGroup.Text  >Ölçek</InputGroup.Text>
                  <Form.Control onChange={Y_Olcek}
                    placeholder="Ölçek"
                  /> </InputGroup>
              </Row>
              <hr></hr>
              {/* ------------------------ONAY YAPACAK KURUM BİLGİLERİ------------------------------- */}

              <Row onClick={ShowOnayK} className="m-1 w-100" >Onay Kurumu</Row>
              <Row style={toolshowOnayK ? { display: "block" } : { display: "none" }}>
                <InputGroup>
                  <InputGroup.Text>Kurum  </InputGroup.Text>
                  <Form.Select aria-label="example" onChange={(e)=>setKurumTur(e.target.value)} defaultValue={'dağıtımfirması'}   >

                    <option value={"dağıtımfirması"} >İl Dağıtım Firması</option>
                    <option value={"OSB"} >İl OSB leri</option>

                  </Form.Select>
                </InputGroup>
                 
{kurumTur==="OSB"?
                <InputGroup>
                  <InputGroup.Text>İl</InputGroup.Text>
                  <Form.Select aria-label="example"    >
                    {ilOsbler.map((osb, o) =>
                      (<option value={osb} onChange={(e)=>{setOnayKurum(e.target.value)}} key={o}>{osb}</option>)
                    )}
                  </Form.Select>
                </InputGroup>:"_   " + onayKurum}
  

              </Row>


            </td>
          </tr>


        </tbody>
      </Table>




    </React.Fragment>





  )

}
