
import React, {  useEffect, useState } from 'react'
import {  Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { UpdateSecim } from '../reducers/cizimOzellik'



let  denemeCizim=[ ]

export default function KompSema(props) {
    const KompDetaylar = useSelector((state) => state.KompDetay)
    const SemalOzellik = useSelector((state) => state.SemaCizim)

    const [bgcolor, setBgcolor] = useState("white");
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let Scl=cizimOzellik.Scl;
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:861*Scl},cizim:denemeCizim})
    

    const [toolshow, setToolShow] = useState(false);
    function Show() { setToolShow(!toolshow) }
    const dispatch = useDispatch() 
    
    let cerceveKompRenk=SemalOzellik.OGTHCerRenkKomp
    let AOSRenk=SemalOzellik.KAOSRenk
    let AOSTeRenk=SemalOzellik.KAOSTRenk 
    let KontRenk=SemalOzellik.KKontRenk
    let KontTeRenk=SemalOzellik.KKontTeRenk 
    let iletkenTeRenk=SemalOzellik.KIletTeRenk
    let iletkenRenk=SemalOzellik.KIletRenk
    let AkimTrfRenk=SemalOzellik.KAkimTrfRenk
    let AkimTrfTeRenk=SemalOzellik.KAkimTrfTeRenk
    let AkimTrfIletRenk=SemalOzellik.KAkimTrfIletRenk
    let NoTeRenk=SemalOzellik.KNoTeRenk
    let BaraRenk=SemalOzellik.KBaraRenk
    let BaraTeRenk=SemalOzellik.KBaraTeRenk
    let KondRenk=SemalOzellik.KKondRenk
    let KondTeRenk=SemalOzellik.KKondTeRenk
    let GirisIletTeRenk=SemalOzellik.KGirisIletTeRenk
    let KompIletTeRenk=SemalOzellik.KKompIletTeRenk
    let AnaHatRenk=SemalOzellik.KAnaHatRenk
    let AnaHatTeRenk=SemalOzellik.KAnaHatTeRenk
    useEffect(() => {    
     
        
    }, [KompDetaylar,cizimOzellik,SemalOzellik ]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]);
    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }
 
    return (
        <React.Fragment>
     <Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
            style={{ width: "98%", background: bgcolor }}
          >
         <thead>  
            <tr> 
                <th colSpan="2" className='pe-1' onClick={Show}> KOMPANZASYON ŞEMASI GÖRSEL AYARLARI</th>
            </tr> 
            </thead>
            <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "360px"}:{ display: "none" }}>                         
                <tr style={{ width:"100%" }}><td style={{ width:"100%" }}>Çerçeve Renk<Button  size="sm" style={{width:"30px",height:"20px", background: cerceveKompRenk ,float:"right" }} onClick={()=>Sec("OGTHCerRenkKomp","Çerçeve Renk",cerceveKompRenk)}></Button></td><td></td>
              </tr>  
{/*               <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Text Renk<Button  size="sm" style={{width:"30px",height:"20px",background: textRenk ,float:"right" }} onClick={()=>Sec("KTextRenk","Text ",textRenk)}></Button></td><td></td></tr>  
              
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Çizgiler<Button  size="sm" style={{width:"30px",height:"20px",background: cizimRenk ,float:"right" }} onClick={()=>Sec("OGTHCizimRenk","Çizgiler",cizimRenk)}></Button></td><td></td></tr>  
            */} 
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >No Text<Button  size="sm" style={{width:"30px",height:"20px",background: NoTeRenk ,float:"right" }} onClick={()=>Sec("KNoTeRenk","No ",NoTeRenk)}></Button></td><td></td></tr>   
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >AOS<Button  size="sm" style={{width:"30px",height:"20px",background: AOSRenk ,float:"right" }} onClick={()=>Sec("KAOSRenk","AOS ",AOSRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >AOS Text<Button  size="sm" style={{width:"30px",height:"20px",background: AOSTeRenk ,float:"right" }} onClick={()=>Sec("KAOSTeRenk","AOS Text ",AOSTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kontaktör<Button  size="sm" style={{width:"30px",height:"20px",background: KontRenk ,float:"right" }} onClick={()=>Sec("KKontRenk","Kontaktör ",KontRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kontaktör Text<Button  size="sm" style={{width:"30px",height:"20px",background: KontTeRenk ,float:"right" }} onClick={()=>Sec("KKontTeRenk","Kontaktör Text ",KontTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İletken<Button  size="sm" style={{width:"30px",height:"20px",background: iletkenRenk ,float:"right" }} onClick={()=>Sec("KIletRenk","İletken ",iletkenRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: iletkenTeRenk ,float:"right" }} onClick={()=>Sec("KIletTeRenk","İletken Text ",iletkenTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Akım Trafo<Button  size="sm" style={{width:"30px",height:"20px",background: AkimTrfRenk ,float:"right" }} onClick={()=>Sec("KAkimTrfRenk","Akım Trafo",AkimTrfRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Akım Trafo Text<Button  size="sm" style={{width:"30px",height:"20px",background: AkimTrfTeRenk ,float:"right" }} onClick={()=>Sec("KAkimTrfTeRenk","Akım Trafo Text",AkimTrfTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Akım Trafo iletkeni<Button  size="sm" style={{width:"30px",height:"20px",background: AkimTrfIletRenk ,float:"right" }} onClick={()=>Sec("KAkimTrfIletRenk","Akım Trafo İletkeni",AkimTrfIletRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kondansatör <Button  size="sm" style={{width:"30px",height:"20px",background: KondRenk ,float:"right" }} onClick={()=>Sec("KKondRenk","Kondansatör",KondRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kondansatör Text<Button  size="sm" style={{width:"30px",height:"20px",background: KondTeRenk ,float:"right" }} onClick={()=>Sec("KKondTeRenk","Kondansatör Text",KondTeRenk)}></Button></td><td></td></tr>  

              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Bara<Button  size="sm" style={{width:"30px",height:"20px",background: BaraRenk ,float:"right" }} onClick={()=>Sec("KBaraRenk","Bara ",BaraRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Bara Text<Button  size="sm" style={{width:"30px",height:"20px",background: BaraTeRenk ,float:"right" }} onClick={()=>Sec("KBaraTeRenk","Bara Text Renk",BaraTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Giriş İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: GirisIletTeRenk ,float:"right" }} onClick={()=>Sec("KGirisIletTeRenk","Giriş İletken ",GirisIletTeRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Kompanz. İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: KompIletTeRenk ,float:"right" }} onClick={()=>Sec("KKompIletTeRenk","Giriş İletken Text",KompIletTeRenk)}></Button></td><td></td></tr>  
              
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Besleme İletken <Button  size="sm" style={{width:"30px",height:"20px",background: AnaHatRenk ,float:"right" }} onClick={()=>Sec("KAnaHatRenk","Besleme İletken",AnaHatRenk)}></Button></td><td></td></tr>  
              <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Besleme İletken Text<Button  size="sm" style={{width:"30px",height:"20px",background: AnaHatTeRenk ,float:"right" }} onClick={()=>Sec("KAnaHatTeRenk","Besleme İletken Text",AnaHatTeRenk)}></Button></td><td></td></tr>  
              </tbody>    
          </Table>      
          
            </React.Fragment>
    )

}
