import React, { useEffect, useState } from 'react'
import { Tab, Row, Col, Nav, Collapse } from 'react-bootstrap'

import { useSelector, useDispatch } from 'react-redux'
import icerik from './icerikler'

export default function Overview() {

  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);


  const lisan = useSelector((state) => state.admin.dil)

  useEffect(() => {

  }, [lisan])


  return (
    <div  ><br />
      {lisan === 'TR' ?
        <>
               <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="proje" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>PROJE</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="hub" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>HUB</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="yapilabilirlik" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>FEASIBILITY</h2></Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} style={{ marginLeft: '25%' }}>
                <Tab.Content>
                  <Tab.Pane eventKey="proje" style={{ margin: "30px" }}>

                  <b>Project</b> is an infrastructure that includes <b>Automatic Drawing Editors</b> that enable the automatic preparation of many different types of projects belonging to different engineering disciplines and can interact with each other. With the <b>Project</b> infrastructure, it will be easier for project designers from different disciplines to work on the same project. The speed of preparation of projects will increase and project errors will be minimized.
                   <br/>
                  <br/>
                  <h5>Electricity</h5>
                   Within the Electrical group, there are Automatic Project Drawing Editors to quickly prepare many electrical projects such as High Current, Weak Current, Internal Installation Projects. There are around 30 different types of electrical projects in the electrical project industry.
                   <ul>
                       <li><b>Installation Projects:</b> Preparation of strong current and weak current projects of residences, offices, workplaces, schools, hospitals, hotels, factories, etc.</li>
                       <li><b>Transformer Projects:</b> Preparation of projects of pole type or building type transformer facilities. The first prototype developed for pole type transformer projects can be examined at <a href="https://trafoproje.com"><span style={{color:"blue"}} >trafoproje.com</span></a>.</li>
                       <li><b>Network Projects:</b> A.G. and O.G. Preparation of network projects...</li>
                       <li><b>Weak Current Projects:</b> Preparation of all kinds of weak current projects such as telephone, TV, data, camera and sound system projects, fire detection projects.</li>
                       <li><b>Lighting Projects:</b> Preparation of various lighting projects, from indoor lighting such as residences and hotels to outdoor lighting such as streets, roads and tunnels..</li>
                       <li><b>and others:</b>The necessary editors can be prepared for automatic drawing of almost all kinds of electrical projects used in the construction industry.</li>
                 
                     </ul>
                     <h5>Architecture</h5>
                   Drawing Editors for almost all types of architectural projects, from simple single-storey buildings to multi-storey high-rise buildings, from residences and offices to industrial buildings.
                     <ul>
                       <li><b>Housing Projects</b> </li>
                       <li><b>Commercial Projects</b> </li>
                       <li><b>Industrial Projects</b> </li>
                       <li><b>and others </b></li>
                 
                     </ul>
                     <h5>Mechanical</h5>
                   Project preparation editors to quickly prepare projects for plumbing, heating and cooling installations, fire extinguishing and many other types of mechanical projects.
       
                   <h5>Construction</h5>
                   Project Preparation Editors for different types of static projects with reinforced concrete or steel carriers, which are the subject of Civil Engineering.
       

                  </Tab.Pane>
                  <Tab.Pane eventKey="hub" style={{ margin: "30px" }}>
                     
                  <b>HUB</b> is a community infrastructure with very different and advanced features that brings together all parties of the project process, which starts with the project process of a building and continues throughout its existence, and enables them to prepare all projects of the building in the same environment. Thanks to <b>HUB</b>, project developers can share their projects, either in full or in certain parts, with whomever they want. By establishing new teams for new projects, different disciplines can work on the same project at the same time.
                   <br/>
                  <br/>
                  <h5>KEEP, CREATE, SHARE IN THE CLOUD</h5>
                   Users can save the projects they prepared with Project in the cloud, share them with other users, send them for preliminary review and approval, and develop the projects together. Project Authors, approval or control bodies can create their own project templates and share them with other users.
                   <h5>PROCESS MANAGEMENT SYSTEM</h5>
                   The project process is a process that begins with the client giving the job to the architect and continues as long as the building exists. The proliferation of Controls and Audits and confusion of authority make the already complex project process even more complex. Process Management System, just like Version Control Systems in the software world, is a control system that facilitates the management of the entire process by following the correct order during the creation of a project and ensures coordination between the parties of the project.
                   <h5>WAREHOUSE</h5>
                   Users can store their projects or various project documents such as project templates in the DEPO. They can share their projects in the DEPO with different users with different sharing features. For example, while the entire project is shared with the relevant building inspection company, the Project Title can be stored and shared with other users. Thanks to the repository, users and ProjeHUB can access a very large project archive. Especially in the near future, the necessary dataset for artificial intelligence to be trained and project operations to be carried out with 100% automation can be created thanks to DEPO.
                   <h5>TEMPLATES</h5>
                   Users can prepare their own project templates and automatically prepare their projects in line with the conditions they predetermined. A project designer can develop different project templates according to the project's address, contractor or other criteria. By developing different templates according to different approval or control institutions, it avoids manually making the necessary parameters for each project. Likewise, institutions such as Distribution Companies, Municipalities and Building Inspection Companies can create their own templates and share them with users.
                  </Tab.Pane>
                  <Tab.Pane eventKey="yapilabilirlik" style={{ margin: "30px" }}>
                  <b>PROJECT</b>basically consists of Project Drawing Editors that enable a CAD system to automatically draw with rules created according to the relevant project type. The thing to note here is that the CAD editor is web-based. <a href='https://trafoproje.com/#/direktipitrafoeditor.'></a>When **TrafoProje Editor** is examined, it will be seen that this infrastructure is ready. Of course, the infrastructure here has a few points that need to be improved. The most important of these are the features that enable the user to make manual drawings for installation projects. The issues that need to be developed in the CAD infrastructure have not been prepared yet, not because of technical difficulties and impossibilities, but because they require coding that requires a long time and labor. With the necessary team and equipment, the CAD infrastructure required for the PROJECT can be easily developed. Before developing this infrastructure, all features and usage scenarios of ProjeHUB must be fully designed. It is planned that demo versions that will be available to users can be developed in a short period of six months, and a product that generates income / can be sold after a year of work.
                   <br/>
                   <br/>
                   There are no technical difficulties in implementing <b>HUB </b>. Technical operations (CRUD) that most applications do will be performed software-wise. In order to develop the HUB well, it is necessary to know the project sector and project processes well. In this way, the relationship between the HUB and the Project and ProjectHUB scenarios can be accurately defined.
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </> :

        <>

          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3} style={{ position: 'fixed', height: '100%', overflowY: 'auto' }} >
                <Nav className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="proje" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>PROJE</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="hub" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>HUB</h2></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="yapilabilirlik" style={{ color: "black", borderBottom: "1px solid #678" }}><h2>YAPILABİLİRLİK</h2></Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} style={{ marginLeft: '25%' }}>
                <Tab.Content>
                  <Tab.Pane eventKey="proje" style={{ margin: "30px" }}>

                  <b>Proje</b>, Farklı mühendislik disiplinlerine ait pek çok farklı tipte projelerin otomatik olarak hazırlanmasını sağlayan, birbirleri ile etkileşim halinde olabilen <b>Otomatik Çizim Editörlerinin</b> bir arada bulunduğu alt yapıdır. <b>Proje </b> alt yapısı ile farklı disiplinlerdeki projecilerin aynı proje üzerinde çalışması kolaylaşacaktır. Projelerin hazırlanma hızı artacak ve proje hataları en aza indirilecektir.
                  <br/>
                  <br/>
                  <h5>Elektrik</h5>
                  Elektrik grubu içinde Kuvvetli Akım, Zayıf Akım, İç Tesisat Projesi gibi pek çok elektrik projesini hızlı bir şekilde hazırlamak için Otomatik Proje Çizim Editörleri bulunur. Elektrik proje sektöründe 30 civarında farklı elektrik projesi tipi vardır.
                    <ul>
                      <li><b>Tesisat Projeleri:</b> Mesken, Ofis-Büro, İşyeri , Okul, Hastahe, Otel, Fabrika vb yapıların kuvvetli akım ve zayıf akım projelerinin hazırlanması.</li>
                      <li><b>Trafo Projeleri:</b> Direk tipi yada Bina tipi trafo tesislerinin projelerinin hazırlanması. Direk tipi trafo projeleri için geliştirilen ilk prototip <a href="https://trafoproje.com"><span style={{color:"blue"}} >trafoproje.com</span></a> incelenebilir.</li>
                      <li><b>Şebeke Projeleri:</b> A.G. ve O.G. şebeke projelerinin hazırlanması...</li>
                      <li><b>Zayıf Akım Projeleri:</b> Telefon , Tv , data , kamera ve ses sistemi projeleri ,yangın algılama projeleri gibi her çeşit zayıf akım projelerinin hazırlanması.</li>
                      <li><b>Aydınlatma Projeleri:</b> Mesken, Otel gibi iç mekan aydınlatmalarından sokak, yol ve tünel gibi dış mekan aydınlatmalarına kadar değişik aydınlatma projelerinin  hazırlanması ..</li>
                      <li><b>ve diğerleri :</b>inşaat sektöründe kullanılan hemen her türlü elektrik projelerinin otomatik çizimi için gerekli editörler hazırlanabilir.</li>
                 
                    </ul>
                  <h5>Mimari</h5>
                  Tek katlı basit yapılardan , Çok katlı yüksek yapılara, mesken ve ofislerden Endüstriyel yapılara kadar hemen her türlü Mimari proje türünde Çizim Editörleri
                    <ul>
                      <li><b>Konut Projeleri</b> </li>
                      <li><b>Ticari Projeler</b>  </li>
                      <li><b>Endğstriyel Projeleri</b> </li>                   
                      <li><b>ve diğerleri </b></li>
                 
                    </ul>
                  <h5>Mekanik</h5>
                  Sıhhi Tesisat, ısıtma ve soğutma tesisatları , yangın söndürme ve benzeri diğer pek çok Mekanik proje tipinde projeleri hızlıca hazırlamak için proje hazırlama editörleri.
       
                  <h5>İnşaat</h5>
                  İnşaat Mühendisliği konusu olan betonarme yada çelik taşıyıcılı farklı statik proje türleri için Proje Hazırlama Editörleri.
       

                  </Tab.Pane>
                  <Tab.Pane eventKey="hub" style={{ margin: "30px" }}>
                     
                  <b>HUB</b>  bir yapının proje süreci ile başlayarak yapı varlığı boyunca süren proje sürecinin, tüm taraflarını bir araya getirerek yapının tüm projelerini aynı ortamda hazırlayabilmelerini sağlayan, çok farklı ve gelişmiş özellikleri olan bir topluluk alt yapısıdır. <b>HUB</b> sayeyinde projeciler projelerini ister tamamen ister belirli kısımlarını istedikleri kişiler ile paylaşabilirler. Yeni projeler için yeni ekipler kurularak, aynı anda aynı proje üzerinde farklı disiplinlerin çalışması sağlanabilir. 
                  <br/>
                  <br/>
                  <h5>BULUTTA TUT, OLUŞTUR, PAYLAŞ</h5>
                  Kullanıcılar Proje ile hazırladıkları projelerini bulutta saklayabilir, diğer kullanıcılar ile paylaşabilir, ön inceleme ve onay işlemlerine yollayabilir ve projeler üzerinde birlikte geliştirmeler yapabilirler. Proje Müellifleri , onay yada kontrol kurumları kendi proje şablonlarını oluşturabilir ve diğer kullanıcılar ile paylaşabilirler.
                  <h5>SÜREÇ YÖNETİM SİSTEMi</h5>
                  Proje süreci müşterinin mimara işi vermesi ile başlayıp yapının var olduğu sürece devam eden bir süreçtir. Kontrol ve Denetimlerin çoğalması ve yetki karmaşaları zaten karmaşık olan proje sürecini daha da karmaşık hale getirmektedir. Süreç Yönetim Sistemi tıpkı yazılım dünyasındaki Sürüm Kontrol Sistemleri gibi bir projenin oluşturulması sırasında doğru bir sıralamanın takip edilmesi ve tüm sürecin yönetilmesini kolaylaştıran, projenin tarafları arasındaki koordinasyonu sağlayan bir kontrol sistemidir.
                  <h5>DEPO</h5>
                  Kullanıcılar projelerini yada proje şablonları gibi değişik proje dökümanlarını DEPOda saklayabilirler.DEPOdaki projelerini farklı paylaşım özellikleri ile farklı kullanıcılarla paylaşabilirler. Mesela projenin tamamı ilgili yapı denetim firması ile paylaşılırken Proje Başlığı saklanarak diğer kullanıcılar ile paylaşılabilir. Depo sayesinde kullanıcılar ve ProjeHUB çook büyük bir proje arşivine ulaşabilirler. Özellikle yakın gelecekte yapay zekanın eğitilerek proje işlemlerinin 100% otomasyonla yapılabilmesi için gerekli dataseti DEPO sayesinde oluşturulabilir.
                  <h5>ŞABLONLAR</h5>
                  Kullanıcılar kendi proje şablonlarını hazırlayarak projelerini önceden belirledikleri koşullar doğrultusunda otomatik olarak hazırlayabilirler. Bir proje müellifi projenin adresine , müteahhidine yada başka kritelere göre farklı proje şablonları geliştirebilir. Farklı onay yada kontrol kurumlarına göre farklı şablonlar geliştirerek her proje için gerekli parametreleri manuel olarak yapmaktan kurtulur. Aynı şekilde Dağıtım firmaları, Belediyeler ve Yapı Denetim Firmaları gibi kurumlarda kendi şablonlarını oluşturarak kullanıcılar ile paylaşabilirler.
                  </Tab.Pane>
                  <Tab.Pane eventKey="yapilabilirlik" style={{ margin: "30px" }}>
                  <b>PROJE </b>temel olarak bir CAD sisteminin ilgili proje türüne göre oluşturulan kurallar ile otomatik olarak çizim yapmasını sağlayan Proje Çizim Editörlerinden oluşur. Burada dikkat edilmesi gereken şey CAD editörünün web tabanlı olmasıdır. <a href='https://trafoproje.com/#/direktipitrafoeditor.'></a>**TrafoProje Editörü** incelendiğinde bu alt yapının hazır olduğu görülecektir. Tabiiki buradaki alt yapının daha geliştirilmesi gereken bir kaç noktası vardır. Bunların en önemlisi tesisat projeleri için kullanıcının manuel çizim yapmasını sağlayacak özelliklerdir. CAD alt yapısında geliştirilmesi gereken konular teknik zorluklardan ve imkansızlıklardan dolayı değil uzun zaman ve iş gücü gerektiren kodlama gerektirdiği için şimdiye kadar hazırlanamamıştır. Gerekli ekip ve ekipman ile PROJE için gerekli CAD alt yapısı kolay bir şekilde geliştirilebilir. Bu alt yapıyı geliştirmeden önce ProjeHUB'ın tüm özelliklkeri ve kullanım senaryoları tamamen tasarlanmalıdır. Altı ay gibi kısa bir süre içinde kullanıcıların hizmetine sunulacak demo sürümler geliştirilebilir ve bir yıllık bir çalışma neticesinde gelir elde eden / satışı yapılan bir ürün ortaya çıkarılabileceği planlanmaktadır.
                  <br/>
                  <b>HUB </b>'ı gerçekleştirmenin teknik bir zorluğu yoktur. Yazılımsal olarak çoğu uygulamanın yaptığı teknik işlemler (CRUD) yapılacaktır. HUB 'ın iyi bir şekilde geliştirilebilmesi için proje sektörünü ve proje süreçlerini iyi bilmek gereklidir. Bu şekilde HUB ile Proje arasındaki ilişki ve ProjeHUB senaryoları doğru bir şekilde tanımlanabilir.
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>

        </>}

    </div>
  )
}
