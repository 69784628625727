export function ColorIndex(index1,index2){
return "#261313"
}
export function ColorIndex1(index){
    let colorindex=[];
    colorindex[0]='#000000 ';
    colorindex[1]='#FF0000 ';
    colorindex[2]='#FFFF00 ';
    colorindex[3]='#00FF00 ';
    colorindex[4]='#00FFFF ';
    colorindex[5]='#0000FF ';
    colorindex[6]='#FF00FF ';
    colorindex[7]='#FFFFFF ';
    colorindex[8]='#808080 ';
    colorindex[9]='#C0C0C0 ';
    colorindex[10]='#FF0000 ';
    colorindex[11]='#FF7F7F ';
    colorindex[12]='#A50000 ';
    colorindex[13]='#A55252 ';
    colorindex[14]='#7F0000 ';
    colorindex[15]='#803F3F ';
    colorindex[16]='#4C0000 ';
    colorindex[17]='#4C2626 ';
    colorindex[18]='#260000 ';
    colorindex[19]='#261313 ';
    colorindex[20]='#FF3F00 ';
    colorindex[21]='#FF9F7F ';
    colorindex[22]='#CC3300 ';
    colorindex[23]='#CC7F66 ';
    colorindex[24]='#992600 ';
    colorindex[25]='#995F4C ';
    colorindex[26]='#7F1F00 ';
    colorindex[27]='#7F4F3F ';
    colorindex[28]='#4C1300 ';
    colorindex[29]='#4C2F26 ';
    colorindex[30]='#FF7F00 ';
    colorindex[31]='#FFBF7F ';
    colorindex[32]='#CC6600 ';
    colorindex[33]='#CC9966 ';
    colorindex[34]='#994C00 ';
    colorindex[35]='#99724C ';
    colorindex[36]='#7F3F00 ';
    colorindex[37]='#7F5F3F ';
    colorindex[38]='#4C2600 ';
    colorindex[39]='#4C3926 ';
    colorindex[40]='#FFBF00 ';
    colorindex[41]='#FFDF7F ';
    colorindex[42]='#CC9900 ';
    colorindex[43]='#CCB266 ';
    colorindex[44]='#997200 ';
    colorindex[45]='#99854C ';
    colorindex[46]='#7F5F00 ';
    colorindex[47]='#7F6F3F ';
    colorindex[48]='#4C3900 ';
    colorindex[49]='#4C4226 ';
    colorindex[50]='#FFFF00 ';
    colorindex[51]='#FFFF7F ';
    colorindex[52]='#CCCC00 ';
    colorindex[53]='#CCCC66 ';
    colorindex[54]='#989800 ';
    colorindex[55]='#98984C ';
    colorindex[56]='#7F7F00 ';
    colorindex[57]='#7F7F3F ';
    colorindex[58]='#4C4C00 ';
    colorindex[59]='#4C4C26 ';
    colorindex[60]='#BFFF00 ';
    colorindex[61]='#DFFF7F ';
    colorindex[62]='#99CC00 ';
    colorindex[63]='#B2CC66 ';
    colorindex[64]='#729800 ';
    colorindex[65]='#85984C ';
    colorindex[66]='#5F7F00 ';
    colorindex[67]='#6F7F3F ';
    colorindex[68]='#394C00 ';
    colorindex[69]='#424C26 ';
    colorindex[70]='#7FFF00 ';
    colorindex[71]='#BFFF7F ';
    colorindex[72]='#66CC00 ';
    colorindex[73]='#99CC66 ';
    colorindex[74]='#4C9800 ';
    colorindex[75]='#72984C ';
    colorindex[76]='#3F7F00 ';
    colorindex[77]='#5F7F3F ';
    colorindex[78]='#264C00 ';
    colorindex[79]='#394C26 ';
    colorindex[80]='#3FFF00 ';
    colorindex[81]='#9FFF7F ';
    colorindex[82]='#33CC00 ';
    colorindex[83]='#7FCC66 ';
    colorindex[84]='#269800 ';
    colorindex[85]='#5F984C ';
    colorindex[86]='#1F7F00 ';
    colorindex[87]='#4F7F3F ';
    colorindex[88]='#134C00 ';
    colorindex[89]='#2F4C26 ';
    colorindex[90]='#00FF00 ';
    colorindex[91]='#7FFF7F ';
    colorindex[92]='#00CC00 ';
    colorindex[93]='#66CC66 ';
    colorindex[94]='#009800 ';
    colorindex[95]='#4C984C ';
    colorindex[96]='#007F00 ';
    colorindex[97]='#3F7F3F ';
    colorindex[98]='#004C00 ';
    colorindex[99]='#264C26 ';
    colorindex[100]='#00FF3F ';
    colorindex[101]='#7FFF9F ';
    colorindex[102]='#00CC33 ';
    colorindex[103]='#66CC7F ';
    colorindex[104]='#009826 ';
    colorindex[105]='#4C985F ';
    colorindex[106]='#007F1F ';
    colorindex[107]='#3F7F4F ';
    colorindex[108]='#004C13 ';
    colorindex[109]='#264C2F ';
    colorindex[110]='#00FF7F ';
    colorindex[111]='#7FFFBF ';
    colorindex[112]='#00CC66 ';
    colorindex[113]='#66CC99 ';
    colorindex[114]='#00984C ';
    colorindex[115]='#4C9872 ';
    colorindex[116]='#007F3F ';
    colorindex[117]='#3F7F5F ';
    colorindex[118]='#004C26 ';
    colorindex[119]='#264C39 ';
    colorindex[120]='#00FFBF ';
    colorindex[121]='#7FFFDF ';
    colorindex[122]='#00CC99 ';
    colorindex[123]='#66CCB2 ';
    colorindex[124]='#009872 ';
    colorindex[125]='#4C9885 ';
    colorindex[126]='#007F5F ';
    colorindex[127]='#3F7F6F ';
    colorindex[128]='#004C39 ';
    colorindex[129]='#264C42 ';
    colorindex[130]='#0019FF ';
    colorindex[131]='#7FFFFF ';
    colorindex[132]='#00CCCC ';
    colorindex[133]='#66CCCC ';
    colorindex[134]='#009898 ';
    colorindex[135]='#4C9898 ';
    colorindex[136]='#007F7F ';
    colorindex[137]='#3F7F7F ';
    colorindex[138]='#004C4C ';
    colorindex[139]='#264C4C ';
    colorindex[140]='#00BFFF ';
    colorindex[141]='#7FDFFF ';
    colorindex[142]='#0099CC ';
    colorindex[143]='#66B2CC ';
    colorindex[144]='#007298 ';
    colorindex[145]='#4C8598 ';
    colorindex[146]='#005F7F ';
    colorindex[147]='#3F0B7F ';
    colorindex[148]='#00394C ';
    colorindex[149]='#26424C ';
    colorindex[150]='#007FFF ';
    colorindex[151]='#7FBFFF ';
    colorindex[152]='#0066CC ';
    colorindex[153]='#6699CC ';
    colorindex[154]='#004C98 ';
    colorindex[155]='#4C7298 ';
    colorindex[156]='#003F7F ';
    colorindex[157]='#3F5F7F ';
    colorindex[158]='#00264C ';
    colorindex[159]='#26394C ';
    colorindex[160]='#003FFF ';
    colorindex[161]='#7F9FFF ';
    colorindex[162]='#0033CC ';
    colorindex[163]='#667FCC ';
    colorindex[164]='#002698 ';
    colorindex[165]='#4C5F98 ';
    colorindex[166]='#001F7F ';
    colorindex[167]='#3F4F7F ';
    colorindex[168]='#00134C ';
    colorindex[169]='#262F4C ';
    colorindex[170]='#0000FF ';
    colorindex[171]='#7F7FFF ';
    colorindex[172]='#0000CC ';
    colorindex[173]='#6666CC ';
    colorindex[174]='#000098 ';
    colorindex[175]='#4C4C98 ';
    colorindex[176]='#00007F ';
    colorindex[177]='#3F3F7F ';
    colorindex[178]='#00004C ';
    colorindex[179]='#26264C ';
    colorindex[180]='#3F00FF ';
    colorindex[181]='#9F7FFF ';
    colorindex[182]='#3300CC ';
    colorindex[183]='#7F66CC ';
    colorindex[184]='#260098 ';
    colorindex[185]='#5F4C98 ';
    colorindex[186]='#1F007F ';
    colorindex[187]='#4F3F7F ';
    colorindex[188]='#13004C ';
    colorindex[189]='#131758 ';
    colorindex[190]='#7F00FF ';
    colorindex[191]='#BF7FFF ';
    colorindex[192]='#6600CC ';
    colorindex[193]='#9966CC ';
    colorindex[194]='#4C0098 ';
    colorindex[195]='#724C98 ';
    colorindex[196]='#3F007F ';
    colorindex[197]='#5F3F7F ';
    colorindex[198]='#26004C ';
    colorindex[199]='#39264C ';
    colorindex[200]='#BF00FF ';
    colorindex[201]='#DF7FFF ';
    colorindex[202]='#9900CC ';
    colorindex[203]='#B266CC ';
    colorindex[204]='#720098 ';
    colorindex[205]='#854C98 ';
    colorindex[206]='#5F007F ';
    colorindex[207]='#6F3F7F ';
    colorindex[208]='#39004C ';
    colorindex[209]='#42264C ';
    colorindex[210]='#9B00FF ';
    colorindex[211]='#9B7FFF ';
    colorindex[212]='#CC00CC ';
    colorindex[213]='#CC66CC ';
    colorindex[214]='#980098 ';
    colorindex[215]='#984C98 ';
    colorindex[216]='#7F007F ';
    colorindex[217]='#7F3F7F ';
    colorindex[218]='#4C004C ';
    colorindex[219]='#4C264C ';
    colorindex[220]='#FF00BF ';
    colorindex[221]='#FF7FDF ';
    colorindex[222]='#CC0099 ';
    colorindex[223]='#CC66B2 ';
    colorindex[224]='#980072 ';
    colorindex[225]='#984C85 ';
    colorindex[226]='#7F005F ';
    colorindex[227]='#7F28DB ';
    colorindex[228]='#4C0039 ';
    colorindex[229]='#4C2642 ';
    colorindex[230]='#FF007F ';
    colorindex[231]='#FF7FBF ';
    colorindex[232]='#CC0066 ';
    colorindex[233]='#CC6699 ';
    colorindex[234]='#98004C ';
    colorindex[235]='#984C72 ';
    colorindex[236]='#7F003F ';
    colorindex[237]='#7F3F5F ';
    colorindex[238]='#4C0026 ';
    colorindex[239]='#4C2639 ';
    colorindex[240]='#FF003F ';
    colorindex[241]='#FF7F9F ';
    colorindex[242]='#CC0033 ';
    colorindex[243]='#CC667F ';
    colorindex[244]='#980026 ';
    colorindex[245]='#984C5F ';
    colorindex[246]='#7F001F ';
    colorindex[247]='#7F3F4F ';
    colorindex[248]='#4C0013 ';
    colorindex[249]='#4C262F ';
    colorindex[250]='#333333 ';
    colorindex[251]='#5F5B5B ';
    colorindex[252]='#848484 ';
    colorindex[253]='#ADADAD ';
    colorindex[254]='#D6D6D6 ';
    colorindex[255]='#FFFFFF ';
    

     
     return colorindex[index];
    }


export  const colorindex = [];
    colorindex[0] = '#000000';
    colorindex[1] = '#FF0000';
    colorindex[2] = '#FFFF00';
    colorindex[3] = '#00FF00';
    colorindex[4] = '#00FFFF'; 
    colorindex[5] = '#0000FF';
    colorindex[6] = '#FF00FF';
    colorindex[7] = '#FFFFFF';
    colorindex[8] = '#808080';
    colorindex[9] = '#C0C0C0';
    colorindex[10] = '#FF0000';
    colorindex[11] = '#FF7F7F';
    colorindex[12] = '#A50000';
    colorindex[13] = '#A55252';
    colorindex[14] = '#7F0000';
    colorindex[15] = '#803F3F';
    colorindex[16] = '#4C0000';
    colorindex[17] = '#4C2626';
    colorindex[18] = '#260000';
    colorindex[19] = '#261313';
    colorindex[20] = '#FF3F00';
    colorindex[21] = '#FF9F7F';
    colorindex[22] = '#CC3300';
    colorindex[23] = '#CC7F66';
    colorindex[24] = '#992600';
    colorindex[25] = '#995F4C';
    colorindex[26] = '#7F1F00';
    colorindex[27] = '#7F4F3F';
    colorindex[28] = '#4C1300';
    colorindex[29] = '#4C2F26';
    colorindex[30] = '#FF7F00';
    colorindex[31] = '#FFBF7F';
    colorindex[32] = '#CC6600';
    colorindex[33] = '#CC9966';
    colorindex[34] = '#994C00';
    colorindex[35] = '#99724C';
    colorindex[36] = '#7F3F00';
    colorindex[37] = '#7F5F3F';
    colorindex[38] = '#4C2600';
    colorindex[39] = '#4C3926';
    colorindex[40] = '#FFBF00';
    colorindex[41] = '#FFDF7F';
    colorindex[42] = '#CC9900';
    colorindex[43] = '#CCB266';
    colorindex[44] = '#997200';
    colorindex[45] = '#99854C';
    colorindex[46] = '#7F5F00';
    colorindex[47] = '#7F6F3F';
    colorindex[48] = '#4C3900';
    colorindex[49] = '#4C4226';
    colorindex[50] = '#FFFF00';
    colorindex[51] = '#FFFF7F';
    colorindex[52] = '#CCCC00';
    colorindex[53] = '#CCCC66';
    colorindex[54] = '#989800';
    colorindex[55] = '#98984C';
    colorindex[56] = '#7F7F00';
    colorindex[57] = '#7F7F3F';
    colorindex[58] = '#4C4C00';
    colorindex[59] = '#4C4C26';
    colorindex[60] = '#BFFF00';
    colorindex[61] = '#DFFF7F';
    colorindex[62] = '#99CC00';
    colorindex[63] = '#B2CC66';
    colorindex[64] = '#729800';
    colorindex[65] = '#85984C';
    colorindex[66] = '#5F7F00';
    colorindex[67] = '#6F7F3F';
    colorindex[68] = '#394C00';
    colorindex[69] = '#424C26';
    colorindex[70] = '#7FFF00';
    colorindex[71] = '#BFFF7F';
    colorindex[72] = '#66CC00';
    colorindex[73] = '#99CC66';
    colorindex[74] = '#4C9800';
    colorindex[75] = '#72984C';
    colorindex[76] = '#3F7F00';
    colorindex[77] = '#5F7F3F';
    colorindex[78] = '#264C00';
    colorindex[79] = '#394C26';
    colorindex[80] = '#3FFF00';
    colorindex[81] = '#9FFF7F';
    colorindex[82] = '#33CC00';
    colorindex[83] = '#7FCC66';
    colorindex[84] = '#269800';
    colorindex[85] = '#5F984C';
    colorindex[86] = '#1F7F00';
    colorindex[87] = '#4F7F3F';
    colorindex[88] = '#134C00';
    colorindex[89] = '#2F4C26';
    colorindex[90] = '#00FF00';
    colorindex[91] = '#7FFF7F';
    colorindex[92] = '#00CC00';
    colorindex[93] = '#66CC66';
    colorindex[94] = '#009800';
    colorindex[95] = '#4C984C';
    colorindex[96] = '#007F00';
    colorindex[97] = '#3F7F3F';
    colorindex[98] = '#004C00';
    colorindex[99] = '#264C26';
    colorindex[100] = '#00FF3F';
    colorindex[101] = '#7FFF9F';
    colorindex[102] = '#00CC33';
    colorindex[103] = '#66CC7F';
    colorindex[104] = '#009826';
    colorindex[105] = '#4C985F';
    colorindex[106] = '#007F1F';
    colorindex[107] = '#3F7F4F';
    colorindex[108] = '#004C13';
    colorindex[109] = '#264C2F';
    colorindex[110] = '#00FF7F';
    colorindex[111] = '#7FFFBF';
    colorindex[112] = '#00CC66';
    colorindex[113] = '#66CC99';
    colorindex[114] = '#00984C';
    colorindex[115] = '#4C9872';
    colorindex[116] = '#007F3F';
    colorindex[117] = '#3F7F5F';
    colorindex[118] = '#004C26';
    colorindex[119] = '#264C39';
    colorindex[120] = '#00FFBF';
    colorindex[121] = '#7FFFDF';
    colorindex[122] = '#00CC99';
    colorindex[123] = '#66CCB2';
    colorindex[124] = '#009872';
    colorindex[125] = '#4C9885';
    colorindex[126] = '#007F5F';
    colorindex[127] = '#3F7F6F';
    colorindex[128] = '#004C39';
    colorindex[129] = '#264C42';
    colorindex[130] = '#00FFFF';
    colorindex[131] = '#7FFFFF';
    colorindex[132] = '#00CCCC';
    colorindex[133] = '#66CCCC';
    colorindex[134] = '#009898';
    colorindex[135] = '#4C9898';
    colorindex[136] = '#007F7F';
    colorindex[137] = '#3F7F7F';
    colorindex[138] = '#004C4C';
    colorindex[139] = '#264C4C';
    colorindex[140] = '#00BFFF';
    colorindex[141] = '#7FDFFF';
    colorindex[142] = '#0099CC';
    colorindex[143] = '#66B2CC';
    colorindex[144] = '#007298';
    colorindex[145] = '#4C8598';
    colorindex[146] = '#005F7F';
    colorindex[147] = '#264C4C';
    colorindex[148] = '#00394C';
    colorindex[149] = '#26424C';
    colorindex[150] = '#007FFF';
    colorindex[151] = '#7FBFFF';
    colorindex[152] = '#0066CC';
    colorindex[153] = '#6699CC';
    colorindex[154] = '#004C98';
    colorindex[155] = '#4C7298';
    colorindex[156] = '#003F7F';
    colorindex[157] = '#3F5F7F';
    colorindex[158] = '#00264C';
    colorindex[159] = '#26394C';
    colorindex[160] = '#003FFF';
    colorindex[161] = '#7F9FFF';
    colorindex[162] = '#0033CC';
    colorindex[163] = '#667FCC';
    colorindex[164] = '#002698';
    colorindex[165] = '#4C5F98';
    colorindex[166] = '#001F7F';
    colorindex[167] = '#3F4F7F';
    colorindex[168] = '#00134C';
    colorindex[169] = '#262F4C';
    colorindex[170] = '#0000FF';
    colorindex[171] = '#7F7FFF';
    colorindex[172] = '#0000CC';
    colorindex[173] = '#6666CC';
    colorindex[174] = '#000098';
    colorindex[175] = '#4C4C98';
    colorindex[176] = '#00007F';
    colorindex[177] = '#3F3F7F';
    colorindex[178] = '#00004C';
    colorindex[179] = '#26264C';
    colorindex[180] = '#3F00FF';
    colorindex[181] = '#9F7FFF';
    colorindex[182] = '#3300CC';
    colorindex[183] = '#7F66CC';
    colorindex[184] = '#260098';
    colorindex[185] = '#5F4C98';
    colorindex[186] = '#1F007F';
    colorindex[187] = '#4F3F7F';
    colorindex[188] = '#13004C';
    colorindex[189] = '#131758';
    colorindex[190] = '#7F00FF';
    colorindex[191] = '#BF7FFF';
    colorindex[192] = '#6600CC';
    colorindex[193] = '#9966CC';
    colorindex[194] = '#4C0098';
    colorindex[195] = '#724C98';
    colorindex[196] = '#3F007F';
    colorindex[197] = '#5F3F7F';
    colorindex[198] = '#26004C';
    colorindex[199] = '#39264C';
    colorindex[200] = '#BF00FF';
    colorindex[201] = '#DF7FFF';
    colorindex[202] = '#9900CC';
    colorindex[203] = '#B266CC';
    colorindex[204] = '#720098';
    colorindex[205] = '#854C98';
    colorindex[206] = '#5F007F';
    colorindex[207] = '#6F3F7F';
    colorindex[208] = '#39004C';
    colorindex[209] = '#42264C';
    colorindex[210] = '#FF00FF';
    colorindex[211] = '#FF80FF';
    colorindex[212] = '#CC00CC';
    colorindex[213] = '#CC66CC';
    colorindex[214] = '#980098';
    colorindex[215] = '#984C98';
    colorindex[216] = '#7F007F';
    colorindex[217] = '#7F3F7F';
    colorindex[218] = '#4C004C';
    colorindex[219] = '#4C264C';
    colorindex[220] = '#FF00BF';
    colorindex[221] = '#FF7FDF';
    colorindex[222] = '#CC0099';
    colorindex[223] = '#CC66B2';
    colorindex[224] = '#980072';
    colorindex[225] = '#984C85';
    colorindex[226] = '#7F005F';
    colorindex[227] = '#7F28DB';
    colorindex[228] = '#4C0039';
    colorindex[229] = '#4C2642';
    colorindex[230] = '#FF007F';
    colorindex[231] = '#FF7FBF';
    colorindex[232] = '#CC0066';
    colorindex[233] = '#CC6699';
    colorindex[234] = '#98004C';
    colorindex[235] = '#984C72';
    colorindex[236] = '#7F003F';
    colorindex[237] = '#7F3F5F';
    colorindex[238] = '#4C0026';
    colorindex[239] = '#4C2639';
    colorindex[240] = '#FF003F';
    colorindex[241] = '#FF7F9F';
    colorindex[242] = '#CC0033';
    colorindex[243] = '#CC667F';
    colorindex[244] = '#980026';
    colorindex[245] = '#984C5F';
    colorindex[246] = '#7F001F';
    colorindex[247] = '#7F3F4F';
    colorindex[248] = '#4C0013';
    colorindex[249] = '#4C262F';
    colorindex[250] = '#333333';
    colorindex[251] = '#5F5B5B';
    colorindex[252] = '#848484';
    colorindex[253] = '#ADADAD';
    colorindex[254] = '#D6D6D6';
    colorindex[255] = '#FFFFFF';
