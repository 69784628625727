
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0; 
let  denemeCizim=[]



export default function TrafoGucHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetaylar = useSelector((state) => state.PanoDetay)
    const OGHatDetay = useSelector((state) => state.OGHatDetay)

    let dilekceText1="TRANSFORMATÖR GÜCÜ HESABI"
    let dilekceText2="Transformator Yükleri:"
    let dilekceText3="Kurulu güc                        :"+trafoDetaylar.topKuruluGuc+" W\n Talep faktörü                    :%"+trafoDetaylar.talepFak+"\n Talepgücü                        :"+trafoDetaylar.talepGuc+" W \n CosFi                               :"+OGHatDetay.cosFi+"\n Tranformatör gücü(VA)    :"+ trafoDetaylar.talepGuckVA+"VA  = ("+ parseInt((trafoDetaylar.talepGuckVA)/1000)+"kVA)\n \nTransformatör Gücü Standart Trafo Gücü olan " +trafoDetaylar.trafoGuckVA +" kVA olarak seçilmiştir."
    
 
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    let Scl=cizimOzellik.Scl;  
    let Scl2=2.25  ; Scl*=Scl2;
    let hh=600*Scl;
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
  
    function artirW(val){

        if(panoDetaylar.Cikislar.length>=10){
            hh=550*Scl+(panoDetaylar.Cikislar.length-10)*Scl*20
         }
        let Cizim=[
        { Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
        [
            X + Scl * (0), Y + Scl * (0), 
            X + Scl * (500), Y + Scl * (0), 
            X + Scl * (500), Y +   (hh), 
            X + Scl * (0), Y +   (hh)
        ] } ,
        { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl *15,align:"center", TextString:dilekceText1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
        { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl *15,align:"justify", TextString:dilekceText2, TextStyleName: "  1", TextArc: 0,width:400*Scl },
        { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y +  170*Scl+ Scl *panoDetaylar.Cikislar.length*(20), TextH: Scl *15,align:"justify", TextString:dilekceText3, TextStyleName: "  1", TextArc: 0,width:400*Scl }]
        if(panoDetaylar.Cikislar.length>0){

   
            for (var a =0;a<=panoDetaylar.Cikislar.length-1;a++){
             //Cikislar.map((cikis,a)=>{
                 let cikisText=a+1+"- "+panoDetaylar.Cikislar[a].Aciklama+"     "+panoDetaylar.Cikislar[a].Guc+"W"
                 Cizim=Cizim.concat([{ Ad: "Text", layer: "0", Color: "#FFFFFF ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y +  150*Scl+ Scl*a*(20), TextH: Scl * 20,align:"justify", TextString:cikisText, TextStyleName: "  1", TextArc: 0,width:400*Scl }])
             } 
         }

        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:hh,                          
            },
            cizim:Cizim 
                })); 
  

    }
    useEffect(() => {       
        artirW(panoDetaylar)
        
    }, [cizimOzellik,panoDetaylar,trafoDetaylar,OGHatDetay ]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
    return (        <>        </>    )

}
