
import React, { Component, useEffect, useState } from 'react'
import { Form, Table, Button, Fade } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
//import {UpdateDOCTextRenk,UpdateDOCCerceveRenk} from '../reducers/cizimOzellik'
import { UpdateSecim } from '../reducers/cizimOzellik'


let  denemeCizim=[]



export default function DOCGorselAyar(props) {
   
  
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
   
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800,h:555},cizim:denemeCizim})
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    const [bgcolor, setBgcolor] = useState("white");
    const [toolshow, setToolShow] = useState(false);

    function Show() { setToolShow(!toolshow) }
    const dispatch = useDispatch()     
  
    useEffect(() => {        
      
        
    }, [cizimOzellik]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
    function Sec(val,val2,val3){
        dispatch(UpdateSecim(val))
        props.name(val2)
        props.renk(val3)

    }
 
    return (

        <React.Fragment>

            <Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1 border border-2   '
            style={{ width: "98%", background: bgcolor }}
          >
         <thead>  
            <tr> 
                <th colSpan="2" className='pe-1' onClick={Show}> DOKÜMAN GÖRSEL AYARLARI</th>
            </tr> 
            </thead>
            <tbody style={toolshow ? { display: "block",overflowY:"scroll" , height: "360px"}:{ display: "none" }}>                         
            <tr style={{ width:"100%" }}><td style={{ width:"100%" }}>Çerçeve Renk<Button  size="sm" style={{width:"30px",height:"20px", background: CerceveRenk ,float:"right" }} onClick={()=>Sec("DOCCerceveRenk","Çerçeve Renk",CerceveRenk)}></Button></td><td></td></tr>
            <tr style={{ width:"100%" }}><td style={{ width:"100%" }} >Text<Button  size="sm" style={{width:"30px",height:"20px",background: TextRenk ,float:"right" }} onClick={()=>Sec("DOCTextRenk","Text ",TextRenk)}></Button></td><td></td></tr>  


              </tbody>    
          </Table> 

        </React.Fragment>



    )

}
