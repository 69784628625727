
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { OGHatIletken } from '../Data/Malzeme'


let X = 0;
let Y = 0;

let denemeCizim = []



export default function OggerDusHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const OGHatDetay = useSelector((state) => state.OGHatDetay)
    //const cizimOzellik = useSelector((state) => state.cizimOzellik)

    let tur = OGHatDetay.OGhatTur
    let mesafe = (OGHatDetay.OGhatMetre / 1000).toFixed(2)
    let hatNo = OGHatDetay.OGhatNo
    let CosFi = parseFloat(OGHatDetay.cosFi);
    let SinFi = (OGHatDetay.sinFi).toFixed(2);
    let R20 = 0
    let Xl35kV = 0
    let U = 34500
    let IletkenAdi = "";
    if (tur == "havai") {
        
        IletkenAdi =" (" +  OGHatIletken[hatNo].Adi+ ") "
    }
    R20 = OGHatIletken[hatNo].R20_m
        Xl35kV = OGHatIletken[hatNo].Xl35kV_m

    

    let IletkenAmercName = OGHatIletken[hatNo].AmericanStnd+ IletkenAdi ;
     

    let AGAkim = trafoDetaylar.AGAkim;
    let gerDus = (1.732 * mesafe * AGAkim * (R20 * CosFi + Xl35kV * SinFi)).toFixed(2)
    let YuzdegerDus = (gerDus * 100 / U).toFixed(2)

    let dilekceText1 = "O.G. GERİLİM DÜŞÜMÜ HESABI"
    let dilekceText2 = "∆U = √3.L. I (R.cosφ + X.sinφ)\nGerilim Seviyesi(U)     :" + (U / 1000).toFixed(1) + "Kv \nİletken Cinsi                :" + IletkenAmercName+" \nİletken Uzunluğu(L)     :" + mesafe + " km \nİletken Direnci (R)       :" + R20 + " Ω/km \nİletken Reaktansı (X)   :" + Xl35kV + " Ω/km\nHat CosΦ                    :" + CosFi + " \nHat SinΦ                     :" + SinFi + "\nGüç                              :" + trafoDetaylar.trafoGuckVA + " kVA\nAkım                            :" + trafoDetaylar.AGAkim + "A\n∆U =    √3." + mesafe + "x" + AGAkim + " (" + R20 + "x" + CosFi + " + " + Xl35kV + "x" + SinFi + ")\n∆U                                =" + gerDus + "\n%e                                =" + gerDus + "x100/" + U + " :\n %e                                =" + YuzdegerDus + "\n%e	                               =" + YuzdegerDus + " < 10 Uygundur."
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let Scl = cizimOzellik.Scl;
    let Scl2 = 2.25; Scl *= Scl2;
    let hh = 450;
    const [cizim, setCizim] = useState({ attr: { x: 0, y: 0, w: 800 * Scl, h: 555 * Scl }, cizim: denemeCizim })

    let CerceveRenk = cizimOzellik.DOCCerceveRenk
    let TextRenk = cizimOzellik.DOCTextRenk


    function artirW(val) {
         
        setCizim(prmt => ({
            ...prmt,
            attr: {
                x: 0, y: 0,
                w: 500 * Scl,
                h: hh * Scl,
            },
            cizim: [{
                Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar:
                    [
                        X + Scl * (0), Y + Scl * (0),
                        X + Scl * (500), Y + Scl * (0),
                        X + Scl * (500), Y + Scl * (hh),
                        X + Scl * (0), Y + Scl * (hh)
                    ]
            }, { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl * 15, align: "center", TextString: dilekceText1, TextStyleName: "  1", TextArc: 0, width: 400 * Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl * 15, align: "justify", TextString: dilekceText2, TextStyleName: "  1", TextArc: 0, width: 400 * Scl }]


        }));

    }
    useEffect(() => {
        artirW(400)

    }, [cizimOzellik, trafoDetaylar, OGHatDetay]);
    useEffect(() => {
        props.cizim(cizim)
    }, [cizim]);


    return (<>       </>)

}
