
import React, {   useEffect, useState } from 'react'
  
import {colorindex} from '../Data/Colors'

export default function BicimEditor(props) {
    const [secimRenk, setSecimRenk] = useState('#ff3'); 
    const [renk, setRenk] = useState(props.renk); 
   
   useEffect(() => {
    props.secimRenk(secimRenk)
  
}, [ secimRenk]);  

  const colorRow1=[]
  const colorRow2=[]
  const colorRow3=[]
  const colorRow4=[]
  const colorRow5=[]
  const colorRow6=[]
  const colorRow7=[]
  const colorRow8=[]
  const colorRow9=[]
  const colorRow10=[]
  const colorMain=['#000000','#FF0000','#FFFF00','#00FF00','#00FFFF','#0000FF','#FF00FF','#FFFFFF','#808080','#C0C0C0']  

  let index0=18;

    for (var a=0 ;a<=23;a++){  colorRow1.push(colorindex[index0+10*a])   }
    index0=16;
    for (var a=0 ;a<=23;a++){  colorRow2.push(colorindex[index0+10*a])   }
    index0=14;
    for (var a=0 ;a<=23;a++){  colorRow3.push(colorindex[index0+10*a])   }
    index0=12;
    for (var a=0 ;a<=23;a++){  colorRow4.push(colorindex[index0+10*a])   }
    index0=10;
    for (var a=0 ;a<=23;a++){  colorRow5.push(colorindex[index0+10*a])   }
    index0=11;
    for (var a=0 ;a<=23;a++){  colorRow6.push(colorindex[index0+10*a])   }
    index0=13;
    for (var a=0 ;a<=23;a++){  colorRow7.push(colorindex[index0+10*a])   }
    index0=15;
    for (var a=0 ;a<=23;a++){  colorRow8.push(colorindex[index0+10*a])   }
    index0=17;
    for (var a=0 ;a<=23;a++){  colorRow9.push(colorindex[index0+10*a])   }
    index0=19;
    for (var a=0 ;a<=23;a++){  colorRow10.push(colorindex[index0+10*a])   }
  
 
    return (
        <React.Fragment>            

    <div  className="rounded rounded-2"  style={{width:'320px',height:'20px',background:props.renk,float:'left' }}>{props.name}{props.renk}</div> 
        <div style={{width:'320px',height:'220px',background:'#fff',padding:"1px",overflowX:'scroll'}}>
        
        <div style={{width:'386px',height:'170px',background:'#fff',padding:"1px"}}>
        {colorRow1.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)} />     ))}
        {colorRow2.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>     )    )}
        {colorRow3.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>  )    )}
        {colorRow4.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/> )    )}
        {colorRow5.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/> )    )}
        <hr></hr>
        {colorRow6.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>    )    )}
        {colorRow7.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>     )    )}
        {colorRow8.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>    )    )}
        {colorRow9.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>     )    )}
        {colorRow10.map((color,i)=> ( <div style={{width:'14px',height:'14px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>     )    )}

        </div>
            
        {renk}<div style={{width:'60px',height:'20px',background:renk ,padding:"1px",float:"left"}}>
        {renk}        </div>

        {colorMain.map((color,i)=> ( <div style={{width:'15px',height:'15px',background:color,margin:"1px" ,float:'left'}} key={i} onClick={e=> setSecimRenk(color)} onMouseOver={e=>setRenk(color)}/>     )    )}
 
        </div>
        </React.Fragment>
    )

}
