import { createSlice } from '@reduxjs/toolkit'

export const RapCetSlice = createSlice({
  name: 'RapCetCiz',
  initialState: {
     
    YCRenk:"#00CC00" ,//direkDetay çerçeve renk
    YCTextRenk:"#FFFFFF" ,//direkDetay zemin renk
     
    K_Renk:"#00CC00" ,//direkDetay çerçeve renk
    K_TextRenk:"#FFFFFF" ,//direkDetay zemin renk
     
   

  },
  reducers: {  
    UpdateYCRenk:     (state, action) =>       {state.YCRenk  = action.payload },
    UpdateYCTextRenk: (state, action) =>       {state.YCTextRenk  = action.payload },
 
    UpdateK_Renk:     (state, action) =>       {state.K_Renk  = action.payload },
    UpdateK_TextRenk: (state, action) =>       {state.K_TextRenk  = action.payload },
 
   

  }, 
})
  
export const { UpdateYCRenk,UpdateYCTextRenk,UpdateK_Renk,UpdateK_TextRenk} = RapCetSlice.actions

export default RapCetSlice.reducer    

 