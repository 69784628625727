import { createSlice } from '@reduxjs/toolkit'

export const PanoSlice = createSlice({
  name: 'PanoDetay',
  initialState:  {TMS:[32,40,48,56],Kompakt:[12,29],KAKKompakt:[14],
  SayacDizi:["yok","Düz Sayaç","X5 Kombi" ],
  PanoTuru:"Ölçüm+Dağıtım+Kompanzasyon",
  AnaKesici:"Kesici+Sayaç+Kesici" ,
  InisIletken:{Etiket:"4x16mm² NYY",Kesit:16,adet:1,kap:80,No:0},
  InisMesafe:12,
  InisIletkenDizi:[0],
  CikisIletken:"",
  CikisIletkenTop:"",
  Kesici1Tur:"Kompakt1",Kesici1:12,Kesici1KompNo:0,Kesici1KAKNo:0,
  Kesici2KompNo:0,Kesici2KAKNo:0,Kesici2Tur:"Kompakt2",Kesici2:12,  
  Bara:"20x3", Sayac:"Düz Sayaç",AkimTrafoOran:"100/5", AkimTrafolari:"yok",TMSAkim:50,SabitGrupkVAR:1,
  SabitGrup:true,IcIhtiyac:true,Gostergeler:true,
  Cikislar:[{id: 1, No: 1, Faz: '3', Guc: 5500, Akim: '10.46',AkimKap:34,
  Iletken  :"5x4mm² NYY", Kesici:"25kA 40A 3P",Mesafe:5,KesiciOb:{Akim: 40, KisaDevreAkim: 25, Tip: 'C', Kutup: '3P', Tur: 'KOMPAKT'}}],
  CikislarTur:"Belirsiz"  ,
  PDolcuRenk:"#808080" ,//direkDetay trafo renk
  PDcerceveRenk:"#FF00FF" ,//direkDetay trafo renk
  PDtextRenk:"#FFFFFF" ,//direkDetay trafo renk
  PDpanoRenk:"#00FF00" ,//direkDetay trafo renk
  PDsayacRenk:"#808080" ,//direkDetay trafo renk
  PDkesiciRenk:"#FF0000" ,//direkDetay trafo renk
  PDkesiciTextRenk:"#FFFFFF" ,//direkDetay trafo renk
  PDkesiciTextGoster:true,//direkDetay trafo renk
  PDkontaktorRenk:"#808080" ,//direkDetay trafo renk
  PDRGKRoleRenk:"#00FFFF" ,//direkDetay trafo renk
  PDaosRenk:"#FFFF00" ,//direkDetay trafo renk
  PDaosTextRenk:"#FFFFFF" ,//direkDetay trafo renk
  PDaosTextGoster:true ,//direkDetay trafo renk
  PDakimTrafoRenk:"#FF7F00" ,//direkDetay trafo renk
  PDakimTrfTextRenk:"#FFFFFF" ,//direkDetay trafo renk
  PDakimTrfTextGoster:true ,//direkDetay trafo renk
  PDbaraRenk:"#FF7F00" ,//direkDetay trafo renk
  PDklavuzRenk: "#808080" ,//direkDetay trafo renk
  PDmesafeRenk: "#808080" ,//direkDetay trafo renk
  PDolcuGoster:true ,//direkDetay trafo renk

 
 
     

  } 
  ,
  reducers: {       
    UpdateTMS: (state, action ) => {state.TMS = action.payload},
    UpdateKompakt: (state, action ) => {state.Kompakt = action.payload},
    UpdateInisMesafe: (state, action ) => {state.InisMesafe = action.payload},
    UpdateKAKKompakt: (state, action ) => {state.KAKKompakt = action.payload},
    UpdatePanoTur: (state, action ) => {state.PanoTuru = action.payload},
    UpdateAnaKesici: (state, action ) => {state.AnaKesici = action.payload},
    UpdateKesici1Tur: (state, action ) => {state.Kesici1Tur = action.payload},
    UpdateKesici1: (state, action ) => {state.Kesici1 = action.payload},
    UpdateKesici1No: (state, action ) => {state.Kesici1KompNo = action.payload},
    UpdateKesici2Tur: (state, action ) => {state.Kesici2Tur = action.payload},
    UpdateKesici2 : (state, action ) => {state.Kesici2 = action.payload},
    UpdateBara: (state, action ) => {state.Bara = action.payload},
    UpdateSayac: (state, action ) => {state.Sayac = action.payload},
    UpdateAkimTrafolari: (state, action ) => {state.AkimTrafolari = action.payload},
    UpdateAkimTrafoOran: (state, action ) => {state.AkimTrafoOran = action.payload},
    UpdateTMSAkim: (state, action ) => {state.TMSAkim = action.payload},
    UpdateSayacDizi: (state, action ) => {state.SayacDizi = action.payload},
    UpdateSabitGrup: (state, action ) => {state.SabitGrup = action.payload},
    UpdateIcIhtiyac: (state, action ) => {state.IcIhtiyac = action.payload},
    UpdateGostergeler: (state, action ) => {state.Gostergeler = action.payload},
    UpdateSabitGrupkVAR: (state, action ) => {state.SabitGrupkVAR = action.payload},
    UpdateCikislar: (state, action ) => {state.Cikislar = action.payload},
    UpdateInisIletken: (state, action ) => {state.InisIletken = action.payload},
    UpdateInisIletkenDizi: (state, action ) => {state.InisIletkenDizi = action.payload},
    UpdateCikislarTur: (state, action ) => {state.CikislarTur = action.payload},
    UpdateCikisIletken: (state, action ) => {state.CikisIletken = action.payload},
    UpdateCikisIletkenTop: (state, action ) => {state.CikisIletkenTop = action.payload},

    UpdatePDolcuRenk: (state, action ) => {state.PDolcuRenk = action.payload},
    UpdatePDcerceveRenk: (state, action ) => {state.PDcerceveRenk = action.payload},
    UpdatePDtextRenk: (state, action ) => {state.PDtextRenk = action.payload},
    UpdatePDpanoRenk: (state, action ) => {state.PDpanoRenk = action.payload},
    UpdatePDsayacRenk: (state, action ) => {state.PDsayacRenk = action.payload},
    UpdatePDkesiciRenk: (state, action ) => {state.PDkesiciRenk = action.payload},
    UpdatePDkesiciTextRenk: (state, action ) => {state.PDkesiciTextRenk = action.payload},
    UpdatePDkontaktorRenk: (state, action ) => {state.PDkontaktorRenk = action.payload},
    UpdatePDRGKRoleRenk: (state, action ) => {state.PDRGKRoleRenk = action.payload},
    UpdatePDaosRenk: (state, action ) => {state.PDaosRenk = action.payload},
    UpdatePDaosTextRenk: (state, action ) => {state.PDaosTextRenk = action.payload},
    UpdatePDakimTrafoRenk: (state, action ) => {state.PDakimTrafoRenk = action.payload},
    UpdatePDakTrfTextRenk: (state, action ) => {state.PDakimTrfTextRenk = action.payload},
    UpdatePDbaraRenk: (state, action ) => {state.PDbaraRenk = action.payload},
    UpdatePDgorselayar: (state, action ) => {state.PDgorselayar = action.payload},
    UpdatePDolcuGoster: (state, action ) => {state.PDolcuGoster = action.payload},
    UpdatePDkesiciTextGoster: (state, action ) => {state.PDkesiciTextGoster = action.payload},
    UpdatePDaosTextGoster: (state, action ) => {state.PDaosTextGoster = action.payload},
    UpdatePDakimTrfTextGoster: (state, action ) => {state.PDakimTrfTextGoster = action.payload},
    UpdatePDmesafeRenk: (state, action ) => {state.PDmesafeRenk = action.payload},
    UpdatePDklavuzRenk: (state, action ) => {state.PDklavuzRenk = action.payload},
     
    }
})

export const {UpdatePDmesafeRenk,UpdateInisMesafe,UpdateCikisIletkenTop, UpdateCikisIletken,UpdatePDklavuzRenk,UpdateTMS,UpdateCikislarTur,UpdateKompakt,UpdateKAKKompakt,UpdatePanoTur,UpdateAnaKesici,UpdateKesici1Tur,UpdateKesici1,UpdateKesici2,UpdateKesici2Tur,UpdateBara,UpdateSayac,UpdateAkimTrafolari,UpdateTMSAkim,UpdateSayacDizi,UpdateSabitGrup,UpdateIcIhtiyac,UpdateGostergeler,UpdateKesici1No,UpdateSabitGrupkVAR,UpdateCikislar,UpdateInisIletken,UpdateInisIletkenDizi,UpdateAkimTrafoOran,UpdatePDolcuRenk,UpdatePDcerceveRenk,UpdatePDtextRenk,UpdatePDpanoRenk,UpdatePDsayacRenk,UpdatePDkesiciRenk,UpdatePDkesiciTextRenk,UpdatePDkontaktorRenk,UpdatePDRGKRoleRenk,UpdatePDaosRenk,UpdatePDaosTextRenk,UpdatePDakimTrafoRenk,UpdatePDakTrfTextRenk,UpdatePDbaraRenk, UpdatePDgorselayar,UpdatePDolcuGoster,UpdatePDakimTrfTextGoster,UpdatePDaosTextGoster,UpdatePDkesiciTextGoster} = PanoSlice.actions
export default PanoSlice.reducer