
var TrafoSecim={
TRF50:{GucKVA:50,Akimlar:{A04:72,A6_3:4.8,A10_5:2.75,A15:1.9,A30:0.95,A31_5:0.91,A34_5:0.83},AGbaraKesit:60,AGBaraEtiket:"20x3 mm² Cu",inisEtiket:["4x16mm² NYY"],inisKesit:[16],TMS:[100],TMSAyar:"80-100",AkimTrf:"3x100/5",SabitKond:"2kVA"},
TRF100:{GucKVA:100,AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisEtiket:["3x50+25mm² NYY"],inisKesit:[50],TMS:160,TMSAyar:"120-160",AkimTrf:"3x160/5",SabitKond:"4kVA"},
TRF160:{GucKVA:160,AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisEtiket:["2x(3x50+25)mm² NYY","3x95+50mm² NYY"],inisKesit:[100,95],TMS:[250,250],TMSAyar:"200-250",AkimTrf:"3x250/5",SabitKond:"7.5kVA"},
TRF250:{GucKVA:250,AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisEtiket:["3x(1x150)+1x70mm² NYY","2x(3x1x70)+1x70mm² NYY"],inisKesit:[150,140],TMS:[320,400],TMSAyar:"80-100",AkimTrf:"3x100/5",SabitKond:"2kVA"},
TRF400:{GucKVA:400,AGbaraKesit:200,AGBaraEtiket:"40x5 mm² Cu",inisEtiket:["3x1x240+1x120mm² NYY"],inisKesit:[16],TMS:100,TMSAyar:"80-100",AkimTrf:"3x100/5",SabitKond:"2kVA"}}
export var InisIletkenleriCU=[
{Etiket:"4x16mm² NYY",Kesit:16,adet:1,kap:80,No:0},//   0
{Etiket:"3x25+16mm² NYY",Kesit:25,adet:1,kap:101,No:1},//   1
{Etiket:"3x35+16mm² NYY",Kesit:35,adet:1,kap:126,No:2},//   2
{Etiket:"3x50+25mm² NYY",Kesit:50,adet:1,kap:153,No:3},//   3
{Etiket:"3x70+25mm² NYY",Kesit:70,adet:1,kap:196,No:4},//   4
{Etiket:"3x95+50mm² NYY",Kesit:95,adet:1,kap:238,No:5},//   5
{Etiket:"2x(3x50+25)mm² NYY",Kesit:100,adet:2,kap:306,No:6},//   6
{Etiket:"3x(3x35+16)mm² NYY",Kesit:105,adet:3,kap:378,No:7},//   7
{Etiket:"3x120+70mm² NYY",Kesit:120,adet:1,kap:276,No:8},//      8 
{Etiket:"2x(3x70+35)mm² NYY",Kesit:140,adet:2,kap:392,No:9},//   9
{Etiket:"4x(3x35+16)mm² NYY",Kesit:140,adet:4,kap:504,No:10},//   10
{Etiket:"3x150+70mm² NYY",Kesit:150,adet:1,kap:319,No:11},//      11
{Etiket:"3x(1x150)+1x70mm² NYY",Kesit:150,adet:3,kap:356,No:12},//12
{Etiket:"3x(3x50+25)mm² NYY",Kesit:150,adet:3,kap:459,No:13},//   13
{Etiket:"3x1x185+1x95mm² NYY",Kesit:185,adet:1,kap:485,No:14},//  14
{Etiket:"2x(3x95+50)mm² NYY",Kesit:190,adet:2,kap:476,No:15},//   15
{Etiket:"4x(3x50+25)mm² NYY",Kesit:200,adet:4,kap:612,No:16},//   16
{Etiket:"3x(3x70+35)mm² NYY",Kesit:210,adet:3,kap:588,No:17},//   17
{Etiket:"2x(3x120+70)mm² NYY",Kesit:240,adet:2,kap:552,No:18},//  18
{Etiket:"3x1x240+1x120mm² NYY",Kesit:240,adet:1,kap:485,No:19},// 19
{Etiket:"3x(3x120+70)mm² NYY",Kesit:360,adet:3,kap:828,No:20}//   20

]

export var InisIletkenleriAL=[
{Etiket:"3x25+16mm² NYAY",Kesit:25,adet:1,kap:82,No:0},//   1
{Etiket:"3x35+16mm² NYAY",Kesit:35,adet:1,kap:100,No:1},//   2
{Etiket:"3x50+25mm² NYAY",Kesit:50,adet:1,kap:119,No:2},//   3
{Etiket:"3x70+25mm² NYAY",Kesit:70,adet:1,kap:152,No:3},//   4
{Etiket:"3x95+50mm² NYAY",Kesit:95,adet:1,kap:189,No:4},//   5
{Etiket:"2x(3x50+25)mm² NYAY",Kesit:100,adet:2,kap:238,No:5},//   6
{Etiket:"3x(3x35+16)mm² NYAY",Kesit:105,adet:3,kap:300,No:6},//   7
{Etiket:"3x120+70mm² NYAY",Kesit:120,adet:1,kap:216,No:7},//      8 
{Etiket:"2x(3x70+35)mm² NYAY",Kesit:120,adet:2,kap:304,No:8},//   9
{Etiket:"4x(3x35+16)mm² NYAY",Kesit:140,adet:4,kap:400,No:9},//   10
{Etiket:"3x150+70mm² NYAY",Kesit:150,adet:1,kap:246,No:10},//      11
{Etiket:"3x(1x150)+1x70mm² NYAY",Kesit:150,adet:3,kap:819,No:11},//12
{Etiket:"3x(3x50+25)mm² NYAY",Kesit:150,adet:3,kap:357,No:12},//   13
{Etiket:"3x1x185+1x95mm² NYAY",Kesit:185,adet:1,kap:951,No:13},//  14
{Etiket:"2x(3x95+50)mm² NYAY",Kesit:190,adet:2,kap:186,No:14},//   15
{Etiket:"4x(3x50+25)mm² NYAY",Kesit:200,adet:4,kap:476,No:15},//   16
{Etiket:"3x(3x70+35)mm² NYAY",Kesit:210,adet:3,kap:456,No:16},//   17
{Etiket:"2x(3x120+70)mm² NYAY",Kesit:240,adet:2,kap:432,No:17},//  18
{Etiket:"3x1x240+1x120mm² NYAY",Kesit:240,adet:1,kap:378,No:18},// 19
{Etiket:"3x(3x120+70)mm² NYAY",Kesit:360,adet:3,kap:648,No:19},//   20
{Etiket:"3x(3x150+70)mm² NYAY",Kesit:450,adet:3,kap:738,No:20}//   20


]
export var TrafoSecim_EMO={   
    TRF50:{GucKVA:50,direklerN:["T-15","T-25","T-35","T-50","Beton Direk"],direklerK:["TK-15","TK-25","TK-35","TK-50"],Akimlar:{A04:72,A6_3:4.8,A10_5:2.75,A15:1.9,A30:0.95,A31_5:0.91,A34_5:0.83},YGSigortalar:{kV6_3:10,kV10_5:6,kV15:6,kV30:6},AGbara:{Kesit:60,Etiket:"20x3 mm² Cu"}, inisdiziCu:[0,1,2],inisdiziAl:[0,1,2],EmoKompakt:[12,29],KAKKompakt:[14],AkimAmperaj:100,AkimTrfOran:"100/5",AkimTrf:"3x100/5",SabitKond:1,KesifNo:2}, 

    TRF100:{GucKVA:100,direklerN:["T-15","T-25","T-35","T-50","Beton Direk"],direklerK:["TK-15","TK-25","TK-35","TK-50"],Akimlar:{A04:144,A6_3:9.6,A10_5:5.5,A15:3.85,A30:1.9,A31_5:1.83,A34_5:1.67},AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisdiziCu:[3,4,5],inisdiziAl:[3,4,5],EmoKompakt:[14,31],KAKKompakt:[20],AkimAmperaj:160,AkimTrfOran:"160/5",AkimTrf:"3x160/5",SabitKond:1,KesifNo:5}, 

    TRF160:{GucKVA:160,direklerN:["T-25","T-35","T-50","Beton Direk"],direklerK:["TK-25","TK-35","TK-50"],Akimlar:{A04:231,A6_3:14.66,A10_5:8.8,A15:6.16,A30:3.1,A31_5:2.93,A34_5:2.68},AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisdiziCu:[5,6,7],inisdiziAl:[7,8,9],EmoKompakt:[16,33],KAKKompakt:[29],AkimAmperaj:250,AkimTrfOran:"250/5",AkimTrf:"3x250/5",SabitKond:1.5,KesifNo:7}, 

    TRF200:{GucKVA:200,direklerN:["T-25","T-35","T-50","Beton Direk"],direklerK:["TK-25","TK-35","TK-50"],Akimlar:{A04:289,A6_3:18.41,A10_5:11,A15:7.7,A30:3.8,A31_5:3.67,A34_5:3.35},AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisdiziCu:[11,12,13,14],inisdiziAl:[13],EmoKompakt:[17,34],KAKKompakt:[32],AkimAmperaj:400,AkimTrfOran:"400/5",AkimTrf:"3x400/5",SabitKond:1.5,KesifNo:8},

    TRF250:{GucKVA:250,direklerN:["T-25","T-35","T-50","Beton Direk"],direklerK:["TK-25","TK-35","TK-50"],Akimlar:{A04:361,A6_3:22.93,A10_5:13.76,A15:9.63,A30:4.8,A31_5:4.58,A34_5:4.18},AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisdiziCu:[12,13,14],inisdiziAl:[13],EmoKompakt:[18,35],KAKKompakt:[35],AkimAmperaj:400,AkimTrfOran:"400/5",AkimTrf:"3x400/5",SabitKond:1.5,KesifNo:9}, 

    TRF315:{GucKVA:315,direklerN:["T-35","T-50","Beton Direk"],direklerK:["TK-35","TK-50"],Akimlar:{A04:455,A6_3:28.9,A10_5:17.32,A15:12.13,A30:6.07,A31_5:5.78,A34_5:5.27},AGbaraKesit:120,AGBaraEtiket:"40x3 mm² Cu",inisdiziCu:[14,15],inisdiziAl:[18],EmoKompakt:[19,36],KAKKompakt:[38],AkimAmperaj:600,AkimTrfOran:"600/5",AkimTrf:"3x600/5",SabitKond:2.5,KesifNo:10},

    TRF400:{GucKVA:400,direklerN:["T-35","T-50","Beton Direk"],direklerK:["TK-35","TK-50"],Akimlar:{A04:578,A6_3:36.7,A10_5:22,A15:15.41,A30:7.7,A31_5:7.34,A34_5:6.70},AGbaraKesit:200,AGBaraEtiket:"40x5 mm² Cu",inisdiziCu:[19],inisdiziAl:[20],EmoKompakt:[20,37],KAKKompakt:[41],AkimAmperaj:600,AkimTrfOran:"600/5",AkimTrf:"3x600/5",SabitKond:2.5,KesifNo:11}}






 
export var KompMalSec=[
    {Kond:0.5,Sigorta:6,Kontaktor:6,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:1,Sigorta:6,Kontaktor:6,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:1.5,Sigorta:6,Kontaktor:6,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:2,Sigorta:10,Kontaktor:6,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:2.5,Sigorta:10,Kontaktor:6,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:5,Sigorta:16,Kontaktor:9,iletken:2.5,iletkenNo:101,İletkenEtiket:'3x2.5mm² NYY'},
    {Kond:7.5,Sigorta:20,Kontaktor:16,iletken:4,iletkenNo:102,İletkenEtiket:'3x4mm² NYY'},
    {Kond:10,Sigorta:25,Kontaktor:16,iletken:4,iletkenNo:102,İletkenEtiket:'3x4mm² NYY'},
    {Kond:16,Sigorta:40,Kontaktor:32,iletken:6,iletkenNo:103,İletkenEtiket:'3x6mm² NYY'},
    {Kond:20,Sigorta:40,Kontaktor:32,iletken:6,iletkenNo:103,İletkenEtiket:'3x6mm² NYY'},
    {Kond:25,Sigorta:50,Kontaktor:40,iletken:6,iletkenNo:103,İletkenEtiket:'3x6mm² NYY'},
    {Kond:30,Sigorta:63,Kontaktor:45,iletken:6,iletkenNo:103,İletkenEtiket:'3x6mm² NYY'},
    {Kond:40,Sigorta:80,Kontaktor:63,iletken:10,iletkenNo:104,İletkenEtiket:'3x10mm² NYY'},
    {Kond:50,Sigorta:100,Kontaktor:80,iletken:16,iletkenNo:105,İletkenEtiket:'3x16mm² NYY'},
    {Kond:60,Sigorta:125,Kontaktor:90,iletken:25,iletkenNo:106,İletkenEtiket:'3x25mm² NYY'},
    {Kond:80,Sigorta:160,Kontaktor:115,iletken:35,iletkenNo:107,İletkenEtiket:'3x35mm² NYY'},
    {Kond:100,Sigorta:250,Kontaktor:160,iletken:50,iletkenNo:108,İletkenEtiket:'3x50mm² NYY'}]


var DokumanListe=["Açıklama_Raporu","Dilekçe","Transformatör_Gücü_Hesabı","Kompanzasyon_Hesabı","Kesif","Yükleme_cetveli","AkimHesaplari","A.G._Gerilim_Düşümü_Hesabı","Akım_Hesabı","Direk_Detayları","Tek_Hat_Şeması","Sembol_Listesi","Başlık"]

//var KullaniciSablonListesi=["Standart","YEDAŞ","OSB"]
var sablonlist1={ad:"Standart",icerikler:["Açıklama_Raporu","Dilekçe","Transformatör_Gücü_Hesabı","Kompanzasyon_Hesabı","AkimHesaplari","Kesif","Yükleme_cetveli","A.G._Gerilim_Düşümü_Hesabı","Direk_Detayları","Tek_Hat_Şeması","Kompanzasyon_Şeması","Sembol_Listesi","Başlık"]}
var sablonlist2={ad:"Dağıtım Firması",   icerikler:["Açıklama_Raporu","Dilekçe","Transformatör_Gücü_Hesabı","Kompanzasyon_Hesabı" ,"A.G._Gerilim_Düşümü_Hesabı","Direk_Detayları","Tek_Hat_Şeması","Kompanzasyon_Şeması","Sembol_Listesi","Başlık"]}
var sablonlist3={ad:"OSB",icerikler:["Dilekçe","Transformatör_Gücü_Hesabı","Direk_Detayları","Tek_Hat_Şeması","Sembol_Listesi","Başlık"]}
var sablonlist4={ad:"boş",icerikler:["Sembol_Listesi","Başlık"]}
var KullaniciSablonListesi=[sablonlist1,sablonlist2,sablonlist3,sablonlist4]
var SeciliSablon="Standart"


 export const iller=[];
iller[0]={il:"Adana",dagitim:"",osb:["Ceyah OSB","ADANA GIDA İHTİSAS OSB","ADANA HACI SABANCI OSB","KOZAN OSB"],ilceler:["Aladağ","Ceyhan","Çukurova","Feke","imamaoğlu","Karaisalı","Karataş","Kozan","Pozantı"]}
iller[1]={il:"Adana",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["Ceyah OSB","ADANA GIDA İHTİSAS OSB","ADANA HACI SABANCI OSB","KOZAN OSB"],ilceler:["Aladağ","Ceyhan","Çukurova","Feke","imamoğlu","Karaisalı","Karataş","Kozan","Pozantı","Saimbeyli","Sarıçam","Seyhan","Tufanbeyli","Yumurtalık","Yüreğir"]}
iller[2]={il:"Adıyaman",dagitim:"Göksu Elektrik Dağıtım A.Ş.",osb:["BESNİ OSB","GÖLBAŞI OSB","KAHTA OSB","ADIYAMAN MERMER İHTİSAS OSB","ADIYAMAN OSB"],ilceler:["Adıyaman","Besni","Çelikhan","Gerger","Gölbaşı","Kâhta","Samsat","Sincik","Tut"]}
iller[3]={il:"Afyonkarahisar",dagitim:"Osmangazi Elektrik Dağıtım A.Ş.",osb:["BOLVADİN OSB","DİNAR OSB","İSCEHİSAR MERMER İHTİSAS OSB","AFYONKARAHİSAR OSB","ŞUHUT OSB","DAZKIRI DOKUMA VE KONFEKSİYON OSB","EMİRDAĞ OSB","AFYONKARAHİSAR MERKEZ 2.OSB","SANDIKLI OSB"],ilceler:["Afyonkarahisar","Başmakçı","Bayat","Bolvadin","Çay","Çobanlar","Dazkırı","Dinar","Emirdağ","Evciller","Hocalar","İhsaniye","İscehisar","Kızılören","Sandıklı","Sinanpaşa","Sultandağı","Şuhut"]}
iller[4]={il:"Ağrı",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["Ağrı OSB"],ilceler:["Ağrı","Diyadin","Doğubayazıt","Eleşkirt","Hamur","Patnos","Taşlıçay","Tutak"]}
iller[5]={il:"Amasya",dagitim:"Yeşilırmak Elektrik Dağıtım A.Ş.",osb:["MERZİFON OSB","AMASYA OSB","SULUOVA OSB","SULUOVA TDİ BESİ OSB"],ilceler:["Amasya","Göynücek","Gümüşhacıköy","Hamamözü","Merzifon","Suluova","Taşova"]}
iller[6]={il:"Ankara",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["ANADOLU OSB","ASO 1 OSB","ASO 2-3 OSB","BAŞKENT OSB","ÇUBUK OSB","DÖKÜMCÜLER OSB","İVEDİK OSB","OSTİM OSB","POLATLI OSB","POLATLI TİCARET ODAS","ŞEREFLİKOÇHİSAR OSB","UZAY VE HAVACILIK İH"],ilceler:["Akyurt","Altındağ","Ayaş","Bala","Beypazarı","Çamlıdere","Çankaya","Çubuk","Elmadağ","Etimesgut","Evren","Gölbaşı","Güdül","Haymana","Kalecik","Kahramankazan","Keçiören","Kızılcahamam","Mamak","Nallıhan","Polatlı","Pursaklar","Sincan","Şereflikoçhisar","Yenimahalle"]}
iller[7]={il:"Antalya",dagitim:"Akdeniz Elektrik Dağıtım A.Ş.",osb:["KUMLUCA GIDA İHTİSAS","ANTALYA OSB"],ilceler:["Akseki","Aksu","Alanya","Demre","Döşemealtı","Elmalı","Finike","Gazipaşa","Gündoğmuş","İbradı","Kaş","Kemer","Kepez","Konyaaltı","Korkuteli","Kumluca","Manavgat","Muratpaşa","Serik"]}
iller[8]={il:"Artvin",dagitim:"Çoruh Elektrik Dağıtım A.Ş.",osb:[],ilceler:["Artvin","Çıldır","Damal","Göle","Hanak","Posof"]}
iller[9]={il:"Aydın",dagitim:"Menderes Elektrik Dağıtım A.Ş.",osb:["ASTİM OSB","ÇİNE OSB","AYDIN OSB","SÖKE OSB","BUHARKENT OSB","NAZİLLİ OSB","ORTAKLAR OSB"],ilceler:["Bozdoğan","Buharkent","Çine","Didim","Efeler","Germencik","İncirliova","Karacasu","Karpuzlu","Koçarlı","Köşk","Kuşadası","Kuyucak","Nazilli","Söke","Sultanhisar","Yenipazar"]}
iller[10]={il:"Balıkesir",dagitim:"Uludağ Elektrik Dağıtım A.Ş.",osb:["BALIKESİR 2 OSB","BANDIRMA OSB","BURHANİYE ZEYTİN OSB","GÖNEN DERİ OSB","BALIKESİR OSB"],ilceler:["Altıeylül","Ayvalık","Balya","Bandırma","Bigadiç","Burhaniye","Dursunbey","Edremit","Erdek","Gömeç","Gönen","Havran","İvrindi","Karesi","Kepsut","Manyas","Marmara","Savaştepe","Sındırgı","Susurluk"]}
iller[11]={il:"Bilecik",dagitim:"Osmangazi Elektrik Dağıtım A.Ş.",osb:["BİLECİK 1 OSB","BİLECİK 2 OSB","BOZÜYÜK OSB","OSMANELİ OSB","PAZARYERİ OSB","SÖĞÜT OSB"],ilceler:["Bilecik","Bozüyük","Gölpazarı","İnhisar","Osmaneli","Pazaryeri","Söğüt","Yenipazar"]}
iller[12]={il:"Bingöl",dagitim:"Fırat Elektrik Dağıtım A.Ş.",osb:[],ilceler:["Bingöl","Adaklı","Genç","Karlıova","Kiğı","Solhan","Yayladere","Yedisu"]}
iller[13]={il:"Bitlis",dagitim:"Vangölü Elektrik Dağıtım A.Ş.",osb:["BİTLİS OSB"],ilceler:["Bitlis","Adilcevaz","Ahlat","Güroymak","Hizan","Mutki","Tatvan"]}
iller[14]={il:"Bolu",dagitim:"Sakarya Elektrik Dağıtım A.Ş.",osb:["GEREDE DERİ İHTİSAS","GEREDE OSB","BOLU KARMA VE TEKSTİ","YENİÇAĞA OSB"],ilceler:["Bolu","Gerede","Göynük","Kıbrıscık","Mengen","Mudurnu","Seben","Yeniçağ"]}
iller[15]={il:"Burdur",dagitim:"Akdeniz Elektrik Dağıtım A.Ş.",osb:["BUCAK OSB","BURDUR OSB","BURDUR II OSB" ],ilceler:["Burdur","Ağlasun","Altımyayla","Bucak","Çavdır","Çeltikçi","Gölhisar","Karamanlı","Kemer","Tefenni","Yeşilova"]}
iller[16]={il:"Bursa",dagitim:"Uludağ Elektrik Dağıtım A.Ş.",osb:["DEMİRTAŞ OSB","GÜRSU OSB","İNEGÖL MOBİLYA İHTİSAS OSB","KAYAPA ISLAH OSB","MUSTAFAKEMALPAŞA MERMERCİLER OSB","NİLÜFER OSB","BURSA TEKSTİL BOYAHANELERİ OSB","YENİŞEHİR OSB","BURSA DERİ İHTİSAS OSB","HASANAĞA OSB","İNEGÖL OSB","KESTEL OSB","MUSTAFAKEMALPAŞA OSB","BURSA OSB","YENİCE ISLAH OSB"],ilceler:["Büyükorhan","Gemlik","Gürsu","Harmancık","İnegöl","İznik","Karacabey","Keles","Kestel","Mudanya","Mustafakemalpaşa","Nilüfer","Orhaneli","Orhangazi","Osmangazi","Yenişehir","Yıldırım"]}
iller[17]={il:"Çanakkale",dagitim:"Uludağ Elektrik Dağıtım A.Ş.",osb:["BİGA OSB","ÇANAKKALE OSB"],ilceler:["Çanakkale","Ayvacık","Bayramiç","Biga","Bozcaada","Çan","Eceabat","Ezine","Gelibolu","Gökçeada","Lapseki","Yenice"]}
iller[18]={il:"Çankırı",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["ÇERKEŞ OSB","KORGUN OSB","KURŞUNLU ÇAVUNDUR OSB","ŞABANÖZÜ OSB","YAKINKENT OSB"],ilceler:["Çankırı","Atkaracalar","Bayramören","Çerkeş","Eldivan","Ilgaz","Kızılırmak","Korgun","Kurşunlu","Orta","Şabanözü","Yapraklı"]}
iller[19]={il:"Çorum",dagitim:"Yeşilırmak Elektrik Dağıtım A.Ş.",osb:["ÇORUM OSB","OSMANCIK OSB","SUNGURLU OSB"],ilceler:["Çorum","Alaca","Bayat","Boğazkale","Dodurga","İskilip","Kargı","Laçin","Mecitözü","Oğuzlar","Ortaköy","Osmancık","Sungurlu","Uğurludağ"]}
iller[20]={il:"Denizli",dagitim:"Menderes Elektrik Dağıtım A.Ş.",osb:["ÇARDAK ÖZDEMİR SABAN","DENİZLİ OSB","DENİZLİ DERİ İHTİSAS","SARAYKÖY TARIMA DAYALI İHTİSAS OSB"],ilceler:["Acıpayam","Babadağ","Baklan","Bekili","Beyağaç","Bozkurt","Buldan","Çal","Çameli","Çardak","Çivril","Güney","Honaz","Kale","Merkezefendi","Pamukkale","Sarayköy","Serinhisar","Tavas"]}
iller[21]={il:"Diyarbakır",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["DİYARBAKIR OSB","DİYARBAKIR TDİ OSB"],ilceler:["Bağlar","Bismil","Çermik","Çınar","Çüngüş","Dicle","Eğil","Ergani","Hani","Hazro","Kayapınar","Kocaköy","Kulp","Lice","Silvan","Sur","YeniŞehir"]}
iller[22]={il:"Edirne",dagitim:"Trakya Elektrik Dağıtım A.Ş.",osb:["EDİRNE OSB"],ilceler:["Edirne Merkez","Enez","Havsa","İpsala","Keşan","Lalapaşa","Meriç","Uzunköprü","Süloğlu"]}
iller[23]={il:"Elazığ",dagitim:"Fırat Elektrik Dağıtım A.Ş.",osb:["ELAZIĞ HAYVAN ÜRÜNLERİ TDİ OSB","ELAZIĞ OSB"],ilceler:["Ağın","Baskil","Elazığ Merkez","Karakoçan","Keban","Maden","Palu","Sivrice","Arıcak","Kovancılar","Alacakaya"]}
iller[24]={il:"Erzincan",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["ERZİNCAN OSB"],ilceler:["Çayırlı","Erzincan Merkez","İliç","Kemah","Kemaliye","Refahiye","Tercan","Üzümlü","Otlukbeli"]}
iller[25]={il:"Erzurum",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["ERZURUM OSB","ERZURUM MERKEZ 2 OSB","OLTU OSB"],ilceler:["Aşkale","Çat","Hınıs","Horasan","İspir","Karayazı","Narman","Oltu","Olur","Pasinler","Şenkaya","Tekman","Tortum","Karaçoban","Uzundere","Pazaryolu","Aziziye","Köprüköy","Palandöken","Yakutiye"]}
iller[26]={il:"Eskişehir",dagitim:"Osmangazi Elektrik Dağıtım A.Ş.",osb:["BEYLİKOVA BESİ İHTİSAS OSB","ESKİŞEHİR OSB","SİVRİHİSAR OSB"],ilceler:["Çifteler","Mahmudiye","Mihalıççık","Sarıcakaya","Seyitgazi","Sivrihisar","Alpu","Beylikova","İnönü","Günyüzü","Han","Mihalgazi","Odunpazarı","Tepebaşı"]}
iller[27]={il:"Gaziantep",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["GAZİANTEP BESİ İHTİSAS OSB","ISLAHİYE OSB","NİZİP OSB","GAZİANTEP OSB"],ilceler:["Araban","İslahiye","Nizip","Oğuzeli","Yavuzeli","Şahinbey","Şehitkamil","Karkamış","Nurdağı"]}
iller[28]={il:"Giresun",dagitim:"Çoruh Elektrik Dağıtım A.Ş.",osb:["GİRESUN 2 OSB","GİRESUN OSB"],ilceler:["Alucra","Bulancak","Dereli","Espiye","Eynesil","Giresun Merkez","Görele","Keşap","Şebinkarahisar","Tirebolu","Piraziz","Yağlıdere","Çamoluk","Çanakçı","Doğankent","Güce"]}
iller[29]={il:"Gümüşhane",dagitim:"Çoruh Elektrik Dağıtım A.Ş.",osb:["GÜMÜŞHANE OSB"],ilceler:["Gümüşhane Merkez","Kelkit","Şiran","Torul","Köse","Kürtün"]}
iller[30]={il:"Hakkari",dagitim:"Vangölü Elektrik Dağıtım A.Ş.",osb:["HAKKARİ OSB"],ilceler:["Çukurca","Hakkari Merkez","Şemdinli","Yüksekova"]}
iller[31]={il:"Hatay",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["ANTAKYA OSB","HATAY ENEK TARIMA DAYALI OSB","ERZİN OSB","İSKENDERUN 2 OSB","İSKENDERUN OSB","PAYAS OSB"],ilceler:["Altınözü","Dörtyol","Hassa","İskenderun","Kırıkhan","Reyhanlı","Samandağ","Yayladağı","Erzin","Belen","Kumlu","Antakya","Arsuz","Defne","Payas"]}
iller[32]={il:"Isparta",dagitim:"Akdeniz Elektrik Dağıtım A.Ş.",osb:["ISPARTA DERİ İHTİSAS OSB","ISPARTA SÜLEYMAN DEMİREL OSB","YALVAÇ OSB"],ilceler:["Atabey","Eğirdir","Gelendost","Isparta Merkez","Keçiborlu","Senirkent","Sütçüler","Şarkikaraağaç","Uluborlu","Yalvaç","Aksu","Gönen","Yenişarbademli"]}
iller[33]={il:"İçel",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["SİLİFKE OSB","MERSİN TARSUS OSB"],ilceler:["Anamur","Erdemli","Gülnar","Mut","Silifke","Tarsus","Aydıncık","Bozyazı","Çamlıyayla","Akdeniz","Mezitli","Toroslar","Yenişehir"]
}
iller[34]={il:"İstanbul",dagitim:"Boğaziçi Elektrik Dağıtım A.Ş.",osb:["İSTANBUL ANADOLU YAKASI OSB","BİRLİK OSB","DUDULLU OSB","TUZLA KİMYA SANAYİCİLERİ OSB","BEYLİKDÜZÜ OSB","İSTANBUL DERİ OSB","İKİTELLİ OSB","TUZLA OSB"],ilceler:["Adalar","Bakırköy","Beşiktaş","Beykoz","Beyoğlu","Çatalca","Eyüp","Fatih","Gaziosmanpaşa","Kadıköy","Kartal","Sarıyer","Silivri","Şile","Şişli","Üsküdar","Zeytinburnu","Büyükçekmece","Kağıthane","Küçükçekmece","Pendik","Ümraniye","Bayrampaşa","Avcılar","Bağcılar","Bahçelievler","Güngören","Maltepe","Sultanbeyli","Tuzla","Esenler","Arnavutköy","Ataşehir","Başakşehir","Beylikdüzü","Çekmeköy","Esenyurt","Sancaktepe","Sultangazi"]}
iller[35]={il:"İzmir",dagitim:"Gediz Elektrik Dağıtım A.Ş.",osb:["ALİAĞA KİMYA İHTİSAS OSB","BAĞYURDU OSB","İZMİR BUCA EGE GİYİM OSB","KEMALPAŞA OSB","MENEMEN PLASTİK İHTİSAS OSB","PANCAR OSB","TORBALI OSB","İZMİR ATATÜRK OSB","BERGAMA OSB","İZMİR İTOB OSB","KINIK OSB","ÖDEMİŞ OSB","TİRE OSB"],ilceler:["Aliağa","Bayındır","Bergama","Bornova","Çeşme","Dikili","Foça","Karaburun","Karşıyaka","Kemalpaşa","Kınık","Kiraz","Menemen","Ödemiş","Seferihisar","Selçuk","Tire","Torbalı","Urla","Beydağ","Buca","Konak","Menderes","Balçova","Çiğli","Gaziemir","Narlıdere","Güzelbahçe","Bayraklı","Karabağlar"]}
iller[36]={il:"Kars",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["KARS OSB","KARS TDİ BESİ OSB"],ilceler:["Arpaçay","Digor","Kağızman","Kars Merkez","Sarıkamış","Selim","Susuz","Akyaka"]}
iller[37]={il:"Kastamonu",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["KASTAMONU OSB","TOSYA OSB","SEYDİLER OSB"],ilceler:["Abana","Araç","Azdavay","Bozkurt","Cide","Çatalzeytin","Daday","Devrekani","İnebolu","Kastamonu Merkez","Küre","Taşköprü","Tosya","İhsangazi","Pınarbaşı","Şenpazar","Ağlı","Doğanyurt","Hanönü","Seydiler"]}
iller[38]={il:"Kayseri",dagitim:"Kayseri ve Civarı Elektrik Türk A.Ş.",osb:["İNCESU OSB","MİMARSİNAN OSB","KAYSERİ OSB"],ilceler:["Bünyan","Develi","Felahiye","İncesu","Pınarbaşı","Sarıoğlan","Sarız","Tomarza","Yahyalı","Yeşilhisar","Akkışla","Talas","Kocasinan","Melikgazi","Hacılar","Özvatan"]}
iller[39]={il:"Kırklareli",dagitim:"Trakya Elektrik Dağıtım A.Ş.",osb:["BÜYÜKKARIŞTIRAN ISLAH OSB","KIRKLARELİ OSB","EVRENSEKİZ ISLAH OSB","PAGDER ASLAN ÖZEL OS"],ilceler:["Babaeski","Demirköy","Kırklareli Merkez","Kofçaz","Lüleburgaz","Pehlivanköy","Pınarhisar","Vize"]}
iller[40]={il:"Kırşehir",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["KAMAN OSB","MUCUR OSB","KIRŞEHİR OSB"],ilceler:["Çiçekdağı","Kaman","Kırşehir Merkez","Mucur","Akpınar","Akçakent","Boztepe"]}
iller[41]={il:"Kocaeli",dagitim:"Sakarya Elektrik Dağıtım A.Ş.",osb:["ALİKAHYA OSB","KOCAELİ ASIM KİBAR OSB","GEBZE GÜZELLER OSB","GEBZE OSB","KOCAELİ GEBZE V KİMYA OSB","KANDIRA GIDA İHTİSAS OSB","TOSB OTOMOTİV YAN SANAYİ İSTİSAS OSB","ARSLANBEY OSB","DİLOVASI OSB","GEBZE KÖMÜRCÜLER İHTİSAS OSB","GEBZE PLASTİKÇİLER OSB","GEBZE VI İMES MAKİNA","MAKİNE İHTİSAS OSB"],ilceler:["Gebze","Gölcük","Kandıra","Karamürsel","Körfez","Derince","Başiskele","Çayırova","Darıca","Dilovası","İzmit","Kartepe"]}
iller[42]={il:"Konya",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["AKŞEHİR OSB","BEYŞEHİR OSB","ÇUMRA OSB","EREĞLİ OSB","KONYA I OSB","KARAPINAR OSB","KULU OSB","KONYA OSB","SEYDİŞEHİR OSB"],ilceler:["Akşehir","Beyşehir","Bozkır","Cihanbeyli","Çumra","Doğanhisar","Ereğli","Hadim","Ilgın","Kadınhanı","Karapınar","Kulu","Sarayönü","Seydişehir","Yunak","Akören","Altınekin","Derebucak","Hüyük","Karatay","Meram","Selçuklu","Taşkent","Ahırlı","Çeltik","Derbent","Emirgazi","Güneysınır","Halkapınar","Tuzlukçu","Yalıhüyük"]}
iller[43]={il:"Kütahya",dagitim:"Osmangazi Elektrik Dağıtım A.Ş.",osb:["GEDİZ OSB","KÜTAHYA MERKEZ I OSB","KÜTAHYA MERKEZ II OSB","SİMAV OSB","TAVŞANLI OSB"],ilceler:["Altıntaş","Domaniç","Emet","Gediz","Kütahya Merkez","Simav","Tavşanlı","Aslanapa","Dumlupınar","Hisarcık","Şaphane","Çavdarhisar","Pazarlar"]}
iller[44]={il:"Malatya",dagitim:"Fırat Elektrik Dağıtım A.Ş.",osb:["AKÇADAĞ MERMER İHTİSAS OSB","DARENDE OSB","MALATYA I OSB","MALATYA II OSB"],ilceler:["Akçadağ","Arapgir","Arguvan","Darende","Doğanşehir","Hekimhan","Pütürge","Yeşilyurt","Battalgazi","Doğanyol","Kale","Kuluncak","Yazıhan"]}
iller[45]={il:"Manisa",dagitim:"Gediz Elektrik Dağıtım A.Ş.",osb:["AKHİSAR OSB","AKHİSAR ZEYTİN İHTİSAS OSB","KULA DERİ İHTİSAS OSB","MANİSA OSB","MANİSA SALİHLİ OSB","SOMA OSB","TURGUTLU OSB"],ilceler:["Akhisar","Alaşehir","Demirci","Gördes","Kırkağaç","Kula","Salihli","Sarıgöl","Saruhanlı","Selendi","Soma","Turgutlu","Ahmetli","Gölmarmara","Köprübaşı","Şehzadeler","Yunusemre"]}
iller[46]={il:"Kahramanmaraş",dagitim:"Göksu Elektrik Dağıtım A.Ş.",osb:["ELBİSTAN OSB","KAHRAMANMARAŞ OSB","KAHRAMANMARAŞ TEKSTİL OSB","TÜRKOĞLU OSB"],ilceler:["Afşin","Andırın","Elbistan","Göksun","Pazarcık","Türkoğlu","Çağlayancerit","Ekinözü","Nurhak","Dulkadiroğlu","Onikişubat"]}
iller[47]={il:"Mardin",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["MARDİN OSB","MARDİN II OSB"],ilceler:["Derik","Kızıltepe","Mazıdağı","Midyat","Nusaybin","Ömerli","Savur","Dargeçit","Yeşilli","Artuklu"]}
iller[48]={il:"Muğla",dagitim:"Menderes Elektrik Dağıtım A.Ş.",osb:[],ilceler:["Bodrum","Datça","Fethiye","Köyceğiz","Marmaris","Milas","Ula","Yatağan","Dalaman","Ortaca","Kavaklıdere","Menteşe","Seydikemer"]}
iller[49]={il:"Muş",dagitim:"Vangölü Elektrik Dağıtım A.Ş.",osb:["MUŞ OSB"],ilceler:["Bulanık","Malazgirt","Muş Merkez","Varto","Hasköy","Korkut"]}
iller[50]={il:"Nevşehir",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["ACIGÖL OSB","NEVŞEHİR ISLAH OSB"],ilceler:["Avanos","Derinkuyu","Gülşehir","Hacıbektaş","Kozaklı","Nevşehir Merkez","Ürgüp","Acıgöl"]}
iller[51]={il:"Niğde",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["NİĞDE BOR KARMA OSB","NİĞDE OSB"],ilceler:["Bor","Çamardı","Niğde Merkez","Ulukışla","Altunhisar","Çiftlik"]}
iller[52]={il:"Ordu",dagitim:"Yeşilırmak Elektrik Dağıtım A.Ş.",osb:["FATSA OSB","ORDU OSB","ÜNYE OSB"],ilceler:["Akkuş","Altınordu","Aybastı","Çamaş","Çatalpınar","Çaybaşı","Fatsa","Gölköy","Gülyalı","Gürgentepe","İkizce","Kabadüz","Kabataş","Korgan","Kumru","Mesudiye","Perşembe","Ulubey","Ünye"]}
iller[53]={il:"Rize",dagitim:"Çoruh Elektrik Dağıtım A.Ş.",osb:["RİZE ARDEŞEN OSB","RİZE OSB"],ilceler:["Ardeşen","Çamlıhemşin","Çayeli","Fındıklı","İkizdere","Kalkandere","Pazar","Rize Merkez","Güneysu","Derepazarı","Hemşin","İyidere"]}
iller[54]={il:"Sakarya",dagitim:"Sakarya Elektrik Dağıtım A.Ş.",osb:["FERİZLİ OSB","SAKARYA II OSB","KARASU OSB","KAYNARCA OSB","SAKARYA I OSB","SAKARYA III OSB","KAYNARCA MAKİNE İMAL"],ilceler:["Akyazı","Geyve","Hendek","Karasu","Kaynarca","Sapanca","Kocaali","Pamukova","Taraklı","Ferizli","Karapürçek","Söğütlü","Adapazarı","Arifiye","Erenler","Serdivan"]}
iller[55]={il:"Samsun",dagitim:"Yeşilırmak Elektrik Dağıtım A.Ş.",osb:["BAFRA OSB","SAMSUN GIDA OSB","HAVZA İHTİSAS OSB","KAVAK OSB","SAMSUN OSB"],ilceler:["19 Mayıs","Alaçam","Asarcık","Atakum","Ayvacık","Bafra","Canik","Çarşamba","Havza","İlkadım","Kavak","Ladik","Salıpazarı","Tekkeköy","Terme","Vezirköprü","Yakakent"]}
iller[56]={il:"Siirt",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["SİİRT OSB"],ilceler:["Baykan","Eruh","Kurtalan","Pervari","Siirt Merkez","Şirvan","Tillo"]}
iller[57]={il:"Sinop",dagitim:"Yeşilırmak Elektrik Dağıtım A.Ş.",osb:["BOYABAT OSB","SİNOP OSB"],ilceler:["Sinop","Ayancık","Boyabat","Dikmen","Durağan","Erfelek","Gerze","Saraydüzü","Türkeli"]}
iller[58]={il:"Sivas",dagitim:"Çamlıbel Elektrik Dağıtım A.Ş.",osb:["GEMEREK OSB","SİVAS MERKEZ I OSB","SİVAS MERKEZ II OSB","ŞARKIŞLA OSB"],ilceler:["Divriği","Gemerek","Gürün","Hafik","İmranlı","Kangal","Koyulhisar","Sivas Merkez","Suşehri","Şarkışla","Yıldızeli","Zara","Akıncılar","Altınyayla","Doğanşar","Gölova","Ulaş"]}
iller[59]={il:"Tekirdağ",dagitim:"Trakya Elektrik Dağıtım A.Ş.",osb:["ÇERKEZKÖY OSB","ÇORLU DERİ İHTİSAS OSB","ERGENE II OSB","KAPAKLI OSB","MALKARA OSB","TEKİRDAĞ OSB","VELİKÖY OSB","ÇERKEZKÖY VELİKÖY YALIBOYU ILSAH OSB","ERGENE 1 OSB","HAYRABOLU OSB","KARAAĞAÇ OSB","MURATLI OSB","TÜRKGÜCÜ OSB","VELİMEŞE OSB"],ilceler:["Çerkezköy","Çorlu","Hayrabolu","Malkara","Muratlı","Saray","Şarköy","Marmaraereğlisi","Ergene","Kapaklı","Süleymanpaşa"]}
iller[60]={il:"Tokat",dagitim:"Çamlıbel Elektrik Dağıtım A.Ş.",osb:["ERBAA OSB","NİKSAR OSB","TOKAT OSB","TURHAL OSB","ZİLE OSB"],ilceler:["Almus","Artova","Erbaa","Niksar","Reşadiye","Tokat Merkez","Turhal","Zile","Pazar","Yeşilyurt","Başçiftlik","Sulusaray"]}
iller[61]={il:"Trabzon",dagitim:"Çoruh Elektrik Dağıtım A.Ş.",osb:["ARSİN OSB","BEŞİKDÜZÜ OSB","ŞİNİK OSB","VAKFIKEBİR OSB"],ilceler:["Akçaabat","Araklı","Arsin","Çaykara","Maçka","Of","Sürmene","Tonya","Vakfıkebir","Yomra","Beşikdüzü","Şalpazarı","Çarşıbaşı","Dernekpazarı","Düzköy","Hayrat","Köprübaşı","Ortahisar"]}
iller[62]={il:"Tunceli",dagitim:"Fırat Elektrik Dağıtım A.Ş.",osb:["TUNCELİ OSB"],ilceler:["Çemişgezek","Hozat","Mazgirt","Nazımiye","Ovacık","Pertek","Pülümür","Tunceli Merkez"]}
iller[63]={il:"Şanlıurfa",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["BİRECİK OSB","ŞANLIURFA II OSB","ŞANLIURFA OSB","ŞANLIURFA TDİ OSB","VİRANŞEHİR OSB"],ilceler:["Akçakale","Birecik","Bozova","Ceylanpınar","Halfeti","Hilvan","Siverek","Suruç","Viranşehir","Harran","Eyyübiye","Haliliye","Karaköprü"]}
iller[64]={il:"Uşak",dagitim:"Osmangazi Elektrik Dağıtım A.Ş.",osb:["UŞAK DERİ KARMA OSB","KARAHALLI OSB","UŞAK OSB"],ilceler:["Banaz","Eşme","Karahallı","Sivaslı","Ulubey","Uşak Merkez"]}
iller[65]={il:"Van",dagitim:"Vangölü Elektrik Dağıtım A.Ş.",osb:["ERCİŞ OSB","VAN OSB"],ilceler:["Başkale","Çatak","Erciş","Gevaş","Gürpınar","Muradiye","Özalp","Bahçesaray","Çaldıran","Edremit","Saray","İpekyolu","Tuşba"]}
iller[66]={il:"Yozgat",dagitim:"Çamlıbel Elektrik Dağıtım A.Ş.",osb:["KALESERAMİK ÖZEL OSB","YOZGAT OSB"],ilceler:["Akdağmadeni","Boğazlıyan","Çayıralan","Çekerek","Sarıkaya","Sorgun","Şefaatli","Yerköy","Yozgat Merkez","Aydıncık","Çandır","Kadışehri","Saraykent","Yenifakılı"]}
iller[67]={il:"Zonguldak",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["ALAPLI OSB","ÇAYCUMA OSB","EREĞLİ OSB"],ilceler:["Çaycuma","Devrek","Ereğli","Zonguldak Merkez","Alaplı","Gökçebey","Kilimli","Kozlu"]}
iller[68]={il:"Aksaray",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["AKSARAY OSB"],ilceler:["Aksaray Merkez","Ortaköy","Ağaçören","Güzelyurt","Sarıyahşi","Eskil","Gülağaç"]}
iller[69]={il:"Bayburt",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["BAYBURT OSB"],ilceler:["Bayburt Merkez","Aydıntepe","Demirözü"]}
iller[70]={il:"Karaman",dagitim:"Meram Elektrik Dağıtım A.Ş.",osb:["KARAMAN OSB"],ilceler:["Ermenek","Karaman Merkez","Ayrancı","Kazımkarabekir","Başyayla","Sarıveliler"]}
iller[71]={il:"Kırıkkale",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["KESKİN OSB","KIRIKKALE OSB","SİLAH OSB"],ilceler:["Delice","Keskin","Kırıkkale Merkez","Sulakyurt","Bahşili","Balışeyh","Çelebi","Karakeçili","Yahşihan"]}
iller[72]={il:"Batman",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["BATMAN OSB"],ilceler:["Batman Merkez","Beşiri","Gercüş","Kozluk","Sason","Hasankeyf"]}
iller[73]={il:"Şırnak",dagitim:"Dicle Elektrik Dağıtım A.Ş.",osb:["CİZRE OSB","ŞIRNAK OSB"],ilceler:["Beytüşşebap","Cizre","İdil","Silopi","Şırnak Merkez","Uludere","Güçlükonak"]}
iller[74]={il:"Bartın",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:["BARTIN 1 OSB"],ilceler:["Bartın Merkez","Kurucaşile","Ulus","Amasra"]}
iller[75]={il:"Ardahan",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["ARDAHAN OSB"],ilceler:["Ardahan Merkez","Çıldır","Göle","Hanak","Posof","Damal"]}
iller[76]={il:"Iğdır",dagitim:"Aras Elektrik Dağıtım A.Ş.",osb:["IĞDIR OSB"],ilceler:["Aralık","Iğdır Merkez","Tuzluca","Karakoyunlu"]}
iller[77]={il:"Yalova",dagitim:"Uludağ Elektrik Dağıtım A.Ş.",osb:["YALOVA ÇİÇEKÇİLİK TDİ OSB","YALOVA KALIP İMALATI","YALOVA GEMİ İHTİSAS","TAŞIT ARAÇLARI YAN SANAYİ İHTİSAS OSB"],ilceler:["Yalova Merkez","Altınova","Armutlu","Çınarcık","Çiftlikköy","Termal"]}
iller[78]={il:"Karabük",dagitim:"Başkent Elektrik Dağıtım A.Ş.",osb:[],ilceler:["Eflani","Eskipazar","Karabük Merkez","Ovacık","Safranbolu","Yenice"]}
iller[79]={il:"Kilis",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["KİLİS OSB"],ilceler:["Kilis Merkez","Elbeyli","Musabeyli","Polateli"]}
iller[80]={il:"Osmaniye",dagitim:"Toroslar Elektrik Dağıtım A.Ş.",osb:["KADİRLİ OSB","OSMANİYE OSB"],ilceler:["Bahçe","Kadirli","Osmaniye Merkez","Düziçi","Hasanbeyli","Sumbas","Toprakkale"]}
iller[81]={il:"Düzce",dagitim:"Sakarya Elektrik Dağıtım A.Ş.",osb:["DÜZCE 2 OSB","DÜZCE ÇİLİMLİ OSB","DÜZCE OSB"],ilceler:["Akçakoca","Düzce Merkez","Yığılca","Cumayeri","Gölkaya","Çilimli","Gümüşova","Kaynaşlı"]}

export default iller ;