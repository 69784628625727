
import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

let X=0;
let Y=0;
let Scl=0.5;
let  denemeCizim=[{ Ad: "Polyline", layer: "0", Color: "#FF00FF ", Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: [X + Scl * (0), Y + Scl * (0), X + Scl * (800), Y + Scl * (0), X + Scl * (800), Y + Scl * (700), X + Scl * (0), Y + Scl * (700)] }]



export default function OGAkimHesap(props) {
    const trafoDetaylar = useSelector((state) => state.trafoDetay)
    const panoDetay  = useSelector((state) => state.PanoDetay)

    
    let U=400
    let Guc=trafoDetaylar.trafoGuckVA
    
    let AkimTrafoOran=panoDetay.AkimTrafoOran
    let Akim=(Guc*1000/(U*1.732)).toFixed(2)
    let nominalGuc=trafoDetaylar.AkimAmperaj
    let dilekceText1="AG AKIM TRAFOLARI HESABI"
    let dilekceText2= "P=I*U*√3*CosΦ		 \n"+Guc+"=I*"+U+"*√3\nI="+Guc+"⁄("+U+"√3)	="+Akim+"A\nI="+Akim+"A olduğu için  "+AkimTrafoOran+" A akım trafosu seçilmiştir.\nÇevirme Oranı			"+AkimTrafoOran+"\nTermik Dayanım Akımı		Ith=15In\nNominal Gücü		               "+nominalGuc+" VA\nSınıfı					Cl:0,5\n"
    const cizimOzellik = useSelector((state) => state.cizimOzellik)
    let CerceveRenk=cizimOzellik.DOCCerceveRenk
    let TextRenk=cizimOzellik.DOCTextRenk

    let Scl=cizimOzellik.Scl;
    let Scl2=2.25  ; Scl*=Scl2;
     
    let hh=400
    const[cizim,setCizim]=useState({attr:{x:0,y:0,w:800*Scl,h:555*Scl},cizim:denemeCizim})
   
    
    function artirW(val){
        setCizim(prmt => ({
            ...prmt,
            attr:{
                x:0,y:0,
                w:500*Scl,  
                h:hh*Scl,                          
            },
            cizim:[{ Ad: "Polyline", layer: "0", Color: CerceveRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, NoktaSay: 4, PolyLineFlag: "     1", Noktalar: 
            [
                X + Scl * (0), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (0), 
                X + Scl * (500), Y + Scl * (hh), 
                X + Scl * (0), Y + Scl * (hh)
            ] },{ Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (50), TextH: Scl * 15,align:"center", TextString:dilekceText1, TextStyleName: "  1", TextArc: 0,width:400*Scl },
            { Ad: "Text", layer: "0", Color: TextRenk, Color24: "", LineScale: 1, LineType: "ByLayer", Kalinlik: 40, x: X + Scl * (50.00), y: Y + Scl * (100), TextH: Scl * 15,align:"justify", TextString:dilekceText2, TextStyleName: "  1", TextArc: 0,width:400*Scl }]
            
        })); 
    }
    useEffect(() => {       
        artirW(400)        
    }, [cizimOzellik,trafoDetaylar]);
    useEffect(() => {
        props.cizim(cizim)
    }, [ cizim]); 
     
 
    return (

        <>
 
        </>
    )

}
