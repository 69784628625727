import React, { useEffect, useState,useRef } from "react";
 
import { Stage, Layer, Circle, Line,   Text, Arc, Group, Image } from "react-konva";

import { Col, Row,  Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {   durumAct } from '../reducers/direktipiTrafoSlice'
import { UpdateScl, UpdateDDCerRenk, UpdateDDZemRenk, UpdateDDdirekRenk, UpdateDDpanoRenk, UpdateDDtrafoTextRenk, UpdateDDtrafoGirisRenk, UpdateDDtrafoInisRenk, UpdateDDtrafoKorRenk, UpdateDDtrafoKorTextRenk, UpdateDDparafRenk, UpdateDDparafTextRenk, UpdateDDparafGirisRenk, UpdateDDparafInisRenk, UpdateDDSekRenk, UpdateDDSekIletkenRenk, UpdateDDSekTextRenk, UpdateDDSekKolRenk, UpdateDDSekGirisRenk, UpdateDDotlRenk, UpdateDDotlTextRenk, UpdateDDtrafoRenk, UpdateDDhatIletkenRenk, UpdateDDHatTextRenk, UpdateDDizolatorRenk, UpdateDDIsletmeRenk, UpdateDDtopKazRenk, UpdateDDKorkulukRenk, UpdateDDpanoTextRenk, UpdateKDAHTextRenk, UpdateKDAHCizimRenk, UpdateKDAHCerceveRenk, UpdateDOCTextRenk, UpdateDOCCerceveRenk } from '../reducers/cizimOzellik'
import { UpdatePDcerceveRenk, UpdatePDtextRenk, UpdatePDpanoRenk, UpdatePDsayacRenk, UpdatePDkesiciRenk, UpdatePDkesiciTextRenk, UpdatePDkontaktorRenk, UpdatePDRGKRoleRenk, UpdatePDaosRenk, UpdatePDaosTextRenk, UpdatePDakimTrafoRenk, UpdatePDakTrfTextRenk, UpdatePDbaraRenk, UpdatePDmesafeRenk, UpdatePDklavuzRenk } from '../reducers/PanoDetaySlice'
import { UpdateOGTHtopTextRenk, UpdateOGTHtopRenk, UpdateOGTHCerRenkOG, UpdateOGTHCerRenkAG, UpdateOGTHCerRenkKomp, UpdateOGTHdirekRenk, UpdateOGTHdirekTextRenk, UpdateOGTHCizimRenk, UpdateOGTHtrafoTextRenk, UpdateOGTHparafRenk, UpdateOGTHparafTextRenk, UpdateOGTHSekRenk, UpdateOGTHSekTextRenk, UpdateOGTHtrafoRenk, UpdateOGTHCizimTextRenk, UpdateOGTHSayacRenk, UpdateOGTHSayacTRenk, UpdateOGTHIcIhtRenk, UpdateOGTHIcIhtTRenk, UpdateOGTHSGrpRenk, UpdateOGTHSGrpTRenk, UpdateOGTHSinyalRenk, UpdateOGTHSinyalTRenk, UpdateOGTHKesiciRenk, UpdateOGTHKesiciTRenk, UpdateOGTHAkimTrfRenk, UpdateOGTHAkimTrfTextRenk, UpdateAGTHBaraRenk, UpdateAGTHNoTeRenk, UpdateAGTHBaraTeRenk, UpdateAGTHKutuRenk, UpdateAGTHKesiciRenk, UpdateAGTHKesiciTeRenk, UpdateAGTHIletRenk, UpdateAGTHIletTeRenk, UpdateAGTHYukTeRenk, UpdateAGTHGirisIletTeRenk, UpdateAGTHKompIletTeRenk, UpdateAGTHTextRenk, UpdateKTextRenk, UpdateKBaraRenk, UpdateKNoTeRenk, UpdateKBaraTeRenk, UpdateKKondRenk, UpdateKKondTeRenk, UpdateKAOSTeRenk, UpdateKAOSRenk, UpdateKKontRenk, UpdateKKontTeRenk, UpdateKIletRenk, UpdateKIletTeRenk, UpdateKYukTeRenk, UpdateKGirisIletTeRenk, UpdateKKIletTeRenk, UpdateKAkimTrfRenk, UpdateKAkimTrfTeRenk, UpdateKAnaHatTeRenk, UpdateKAnaHatRenk, UpdateKAkimTrfIletRenk } from '../reducers/SemaCizimSlice'
import { UpdateYCRenk, UpdateYCTextRenk, UpdateK_Renk, UpdateK_TextRenk } from '../reducers/RaporCetvelCizimSlice'
 

import { dortgen, StatikCizimPDF } from '../Fonksiyonlar/genelfonksiyonlar'
import { DXFOlustur } from '../Fonksiyonlar/CadDXF'
import ToolAGAkimHesap from './ToolAGAkimHesap'
import ToolOGAkimHesap from './ToolOGAkimHesap'
import ToolBaslik from './ToolBaslik'
import ToolDirekDetay from './ToolDirekDetay'
import ToolKisaDevreAkimHesap from './ToolKisaDevreAkimHesap'
import ToolKompSema from './ToolKompSema'
import ToolOGtekhatSema from './ToolOGtekhatSema'
import ToolAGtekhatSema from './ToolAGtekhatSema'
import Tool_TrafoVeriDetay from './Tool_TrafoVeriDetay'
import Tool_DirekVeriDetay from './Tool_DirekVeriDetay'
import Tool_PanoVeriDetay from './Tool_PanoVeriDetay'
import Tool_SeksiyonerVeriDetay from './Tool_SeksiyonerVeriDetay'
import Tool_KompanzasyonVeriDetay from './Tool_KompanzasyonVeriDetay'
import Tool_ParafudrVeriDetay from './Tool_ParafudrVeriDetay'
import Tool_OGHatDetay from './Tool_OGHatVeriDetay'
import Tool_GenelBilgiler from './Tool_GenelBilgiler'


import ToolTrafoCikisYukler from './ToolYukler'
import ToolTrafoGucuHesap from './ToolTrafoGucuHesap'
import ToolKompanzasyonHesap from './ToolKompanzasyonHesap'
import ToolAciklamaRaporu from './ToolAciklamaRaporu'
import ToolDilekce from './ToolDilekce'
import ToolPanoDetay from './ToolPanoDetay'
import ToolSembolList from './ToolSembolList'
import ToolKatPlan from './ToolKatPlan'
import ToolVaziyetPlan from './ToolVaziyetPlan'
import ToolYuklemeCetveli from './ToolYuklemeCetvel'
import ToolOGGerilimDusumu from './ToolOGgerDusHes'
import ToolAGGerilimDusumu from './ToolAGgerDusHes'
import ToolAGAkimTrafo from './ToolAGAkimTrafoHesap'
import EnerjiMusadesi from './ToolEnerjiMusadesi'
import ToolKesif from './ToolKesif'
import ToolDOCGorselAyar from './ToolDOCGorselAyar'

import BicimEditor from './bicimAyar'

import useKonvaImage from 'react-konva-image';
 
const X = 0;
const Y = 0;
const ID = 1;
let cizim = []
let denemeCizim = []
let cerSay = 0

let panoDetayKonum = { x: 34, y: 34 }

 
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
export default function DirekTipiTrafoEditor(props) {

  const editorGoster = props.goster
  const KompDetaylar = useSelector((state) => state.KompDetay)
  const cizimOzellik = useSelector((state) => state.cizimOzellik)
  const direktrafo = useSelector((state) => state.direktrafo)
  const genelBilgiler = useSelector((state) => state.genelBilgiler)
  const currentproject={Kesif:genelBilgiler.Kesif}
  let Scl = cizimOzellik.Scl;
  const dispatch = useDispatch()

  const [name, setName] = useState("OG Tek Hat Şeması")
  const icerik = useSelector((state) =>
    state.direktrafo.find((icerik) => icerik.name === name)
  )
  const defaultCizimObj = { attr: { x: 0, y: 0, w: 500 * Scl, h: 555 * Scl }, cizim: denemeCizim }

  const [agAkimHesC, setAgAkimHesC] = useState(defaultCizimObj)
  const [agTekHatSemaC, setAgTekHatSemaC] = useState(defaultCizimObj)
  const [ogTekHatSemaC, setOgTekHatSemaC] = useState(defaultCizimObj)
  const [kompSemaC, setKompSemaC] = useState(defaultCizimObj)
  const [ogAkimHesC, setOgAkimHesC] = useState(defaultCizimObj)
  const [katPlanC, setKatPlanC] = useState(defaultCizimObj)
  const [vaziyetPlanC, setVaziyetPlanC] = useState(defaultCizimObj)
  const [direkDetayC, setDirekDetayC] = useState(defaultCizimObj)
  const [panoDetayC, setPanoDetayC] = useState(defaultCizimObj)
  const [ogGerDusC, setOgGerDusC] = useState(defaultCizimObj)
  const [agGerDusC, setAgGerDusC] = useState(defaultCizimObj)
  const [agAkimTrfC, setAgAkimTrfC] = useState(defaultCizimObj)
  const [kisaDevreAkimC, setKisaDevreAkimC] = useState(defaultCizimObj)
  const [trafoGucHesC, setTrafoGucHesC] = useState(defaultCizimObj)
  const [kesifC, setKesifC] = useState(defaultCizimObj)
  const [kompHesC, setKompHesC] = useState(defaultCizimObj)
  const [acikRapC, setAcikRapC] = useState(defaultCizimObj)
  const [dilekceC, setDilekceC] = useState(defaultCizimObj)
  const [enerjiMusC, setEnerjiMusC] = useState(defaultCizimObj)
  const [sembolC, setSembolC] = useState(defaultCizimObj)
  const [baslikC, setBaslikC] = useState(defaultCizimObj)
  const [yuklemeCetvelC, setYuklemeCetvelC] = useState(defaultCizimObj)

  const [durum, setDurum] = useState(icerik.durum)
  const [agAkimHesD, setAgAkimHesD] = useState(direktrafo[7].durum)
  const [agTekHatSemaD, setAgTekHatSemaD] = useState(direktrafo[2].durum)
  const [ogTekHatSemaD, setOgTekHatSemaD] = useState(direktrafo[1].durum)
  const [kompSemaD, setKompSemaD] = useState(KompDetaylar.KompVarlik)
  const [ogAkimHesD, setOgAkimHesD] = useState(direktrafo[6].durum)
  const [katPlanD, setKatPlanD] = useState(direktrafo[15].durum)
  const [vaziyetPlanD, setVaziyetPlanD] = useState(direktrafo[16].durum)
  const [direkDetayD, setDirekDetayD] = useState(direktrafo[17].durum)
  const [panoDetayD, setPanoDetayD] = useState(direktrafo[18].durum)
  const [ogGerDusD, setOgGerDusD] = useState(direktrafo[4].durum)
  const [agGerDusD, setAgGerDusD] = useState(direktrafo[5].durum)
  const [agAkimTrfD, setAgAkimTrfD] = useState(direktrafo[21].durum)
  const [kisaDevreAkimD, setKisaDevreAkimD] = useState(direktrafo[8].durum)
  const [trafoGucHesD, setTrafoGucHesD] = useState(direktrafo[9].durum)
  const [kesifD, setKesifD] = useState(direktrafo[10].durum)
  const [kompHesD, setKompHesD] = useState(direktrafo[11].durum)
  const [acikRapD, setAcikRapD] = useState(direktrafo[12].durum)
  const [dilekceD, setDilekceD] = useState(direktrafo[13].durum)
  const [enerjiMusD, setEnerjiMusD] = useState(direktrafo[14].durum)
  const [sembolD, setSembolD] = useState(direktrafo[19].durum)
  const [baslikD, setBaslikD] = useState(direktrafo[20].durum)
  const [yuklemeCetvelD, setYuklemeCetvelD] = useState(direktrafo[0].durum)
  const [ozellikAd, setOzellikAd] = useState("name")
  const [ozellikRenk, setOzellikRenk] = useState("name")

  const imgUrl = '../Desktop/EP/trafo2.png';
  const [image] = useKonvaImage(imgUrl);

  const [showIcerikList, setShowIcerikList] = useState(false);
  const [bgcolor, setBgcolor] = useState("white");
  const [scl, setScl] = useState(Scl);
  const psfAralik = 150 * Scl
  const icerikAralik = 50 * Scl
  function IcerikListShow() { setShowIcerikList(!showIcerikList) }
  const errRef = useRef();

  let CerceveBoy = 6000
  let CerceveW = 0

  let psf = [
    { name: "raporlar", x: 100 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 100 * Scl, st: 100 * Scl, sl: 100 * Scl, sr: 100 * Scl, kolonara: 100 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] },
    { name: "cetveller", x: 100 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 100 * Scl, st: 100 * Scl, sl: 100 * Scl, sr: 100 * Scl, kolonara: 100 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] },
    { name: "planlar", x: 100 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 100 * Scl, st: 100 * Scl, sl: 100, sr: 100 * Scl, kolonara: 100 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] },
    { name: "semalar", x: 200 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 50 * Scl, st: 50 * Scl, sl: 100 * Scl, sr: 50 * Scl, kolonara: 50 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] },
    { name: "detaylar", x: 100 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 100 * Scl, st: 100 * Scl, sl: 100 * Scl, sr: 100 * Scl, kolonara: 100 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] },
    { name: "baslık", x: 100 * Scl, y: 100 * Scl, w: 0 * Scl, h: CerceveBoy * Scl - 2 * psfAralik, sb: 100 * Scl, st: 100 * Scl, sl: 100 * Scl, sr: 100 * Scl, kolonara: 100 * Scl, icerikara: 50 * Scl, yon: "l2rT", kolonlar: [], iceriklist: [] }

  ]     

  let ProjeCizim = [
    { name: "Dilekce", section: "raporlar", durum: dilekceD, yer: 2, w: dilekceC.attr.w, h: dilekceC.attr.h, x: dilekceC.attr.x, y: dilekceC.attr.y, color: "#FF0000", kolonlar: [] },
    //   { name: "Aciklama Raporu", section: "raporlar", durum: acikRapD, yer: 1, w: acikRapC.attr.w, h: acikRapC.attr.h, x: acikRapC.attr.x, y: acikRapC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "Trafo Gücü Hesabı", section: "raporlar", durum: trafoGucHesD, yer: 1, w: trafoGucHesC.attr.w, h: trafoGucHesC.attr.h, x: trafoGucHesC.attr.x, y: trafoGucHesC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "Kompanzasyon Hesabı", section: "raporlar", durum: kompHesD, yer: 1, w: kompHesC.attr.w, h: kompHesC.attr.h, x: kompHesC.attr.x, y: kompHesC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "OG Gerilim Düşümü Hesabı", section: "raporlar", durum: ogGerDusD, yer: 1, w: ogGerDusC.attr.w, h: ogGerDusC.attr.h, x: ogGerDusC.attr.x, y: ogGerDusC.attr.y, color: "#FFFF00", kolonlar: [] },
    { name: "AG Gerilim Düşümü Hesabı", section: "raporlar", durum: agGerDusD, yer: 1, w: agGerDusC.attr.w, h: agGerDusC.attr.h, x: agGerDusC.attr.x, y: agGerDusC.attr.y, color: "#FFFF00", kolonlar: [] },
    { name: "AG Akim Trafo Hesabı", section: "raporlar", durum: agAkimTrfD, yer: 1, w: agAkimTrfC.attr.w, h: agAkimTrfC.attr.h, x: agAkimTrfC.attr.x, y: agAkimTrfC.attr.y, color: "#FFFF00", kolonlar: [] },
    /*     { name: "OG Akım Hesabı", section: "raporlar", durum: ogAkimHesD, yer: 1, w: ogAkimHesC.attr.w, h: ogAkimHesC.attr.h, x: ogAkimHesC.attr.x, y: ogAkimHesC.attr.y, color: "#FF0000", kolonlar: [] },
     */
    { name: "AG Akım Hesabı", section: "raporlar", durum: agAkimHesD, yer: 1, w: agAkimHesC.attr.w, h: agAkimHesC.attr.h, x: agAkimHesC.attr.x, y: agAkimHesC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "Kısa Devre Akım Hesabı", section: "cetveller", durum: kisaDevreAkimD, yer: 1, w: kisaDevreAkimC.attr.w, h: kisaDevreAkimC.attr.h, x: kisaDevreAkimC.attr.x, y: kisaDevreAkimC.attr.y, color: "#FF0000", kolonlar: [] },

    { name: "Yükleme Cetveli", section: "cetveller", durum: yuklemeCetvelD, yer: 1, w: yuklemeCetvelC.attr.w, h: yuklemeCetvelC.attr.h, x: yuklemeCetvelC.attr.x, y: yuklemeCetvelC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "Keşif", section: "cetveller", durum: kesifD, yer: 1, w: kesifC.attr.w, h: kesifC.attr.h, x: kesifC.attr.x, y: kesifC.attr.y, color: "#FF0000", kolonlar: [] },

    { name: "OG Tek Hat Şeması", section: "semalar", durum: ogTekHatSemaD, yer: 1, w: ogTekHatSemaC.attr.w, h: ogTekHatSemaC.attr.h, x: ogTekHatSemaC.attr.x, y: ogTekHatSemaC.attr.y, color: "#ffff00", kolonlar: [] },

    //   { name: "AG Tek Hat Şeması", section: "semalar", durum: agTekHatSemaD, yer: 1, w: agTekHatSemaC.attr.w, h: agTekHatSemaC.attr.h, x: agTekHatSemaC.attr.x, y: agTekHatSemaC.attr.y, color: "#ffff00", kolonlar: [] },
    //   { name: "Kompanzasyon Şeması", section: "semalar", durum: kompSemaD, yer: 1, w: kompSemaC.attr.w, h: kompSemaC.attr.h, x: kompSemaC.attr.x, y: kompSemaC.attr.y, color: "#ffff00", kolonlar: [] },

    { name: "Enerji Musaadesi", section: "raporlar", durum: enerjiMusD, yer: 1, w: enerjiMusC.attr.w, h: enerjiMusC.attr.h, x: enerjiMusC.attr.x, y: enerjiMusC.attr.y, color: "#FF0000", kolonlar: [] },
    { name: "Kat Planı", section: "planlar", durum: katPlanD, yer: 1, w: katPlanC.attr.w, h: katPlanC.attr.h, x: katPlanC.attr.x, y: katPlanC.attr.y, color: "#0000FF", kolonlar: [] },
    { name: "Vaziyet Planı", section: "planlar", durum: vaziyetPlanD, yer: 1, w: vaziyetPlanC.attr.w, h: vaziyetPlanC.attr.h, x: vaziyetPlanC.attr.x, y: vaziyetPlanC.attr.y, color: "#0000FF", kolonlar: [] },

    { name: "Pano Detayları", section: "detaylar", durum: panoDetayD, yer: 1, w: panoDetayC.attr.w, h: panoDetayC.attr.h, x: panoDetayC.attr.x, y: panoDetayC.attr.y, color: "#0FFFF0", kolonlar: [] },
    { name: "Direk Detayları", section: "detaylar", durum: direkDetayD, yer: 1, w: direkDetayC.attr.w, h: direkDetayC.attr.h, x: direkDetayC.attr.x, y: direkDetayC.attr.y, color: "#0FFFF0", kolonlar: [] },
    { name: "Sembol Listesi", section: "baslık", durum: sembolD, yer: 1, w: sembolC.attr.w, h: sembolC.attr.h, x: sembolC.attr.x, y: sembolC.attr.y, color: "#F0000F", kolonlar: [] },
    { name: "Baslik", section: "baslık", durum: baslikD, yer: 2, w: baslikC.attr.w, h: baslikC.attr.h, x: baslikC.attr.x, y: baslikC.attr.y, color: "#F0000F", kolonlar: [] },
    // { name: "son", section: "semalar", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00" , kolonlar: []}
  ]
  //--------------------------------------------------------------------------------------

  let ProjeFrameArray;
  let cizimG = []

  //----------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------
  function ProjeCer(ProjeFrame, Scl) {
    let iceriklist = ProjeFrame[0]
    let psf = ProjeFrame[1]
    let psf0 = [];
    let psf1 = [];
    let psf2 = [];
    let psf3 = [];
    let psf4 = [];
    let psf5 = [];  
   
    let icerik = []

 
    for (var a in iceriklist) {
     
      switch (iceriklist[a].section) {
        case "raporlar":
          if (iceriklist[a].durum === true) {
            psf0.push(iceriklist[a])
          } else {
            psf0.push({ name: "son", section: "raporlar", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        case "cetveller":
          if (iceriklist[a].durum === true) {
            psf1.push(iceriklist[a])
          } else {
            psf1.push({ name: "son", section: "cetveller", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        case "planlar":
          if (iceriklist[a].durum === true) {
            psf2.push(iceriklist[a])
          } else {
            psf2.push({ name: "son", section: "planlar", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        case "semalar":
          if (iceriklist[a].durum === true) {
            psf3.push(iceriklist[a])
          } else {
            psf3.push({ name: "son", section: "semalar", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        case "detaylar":
          if (iceriklist[a].durum === true) {
            psf4.push(iceriklist[a])
          } else {
            psf4.push({ name: "son", section: "detaylar", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        case "baslık":
          if (iceriklist[a].durum === true) {
            psf5.push(iceriklist[a])
          } else {
            psf5.push({ name: "son", section: "baslık", durum: false, yer: 1, w: 1, h: 1, x: 100, y: 100, color: "#ffff00", kolonlar: [] })
          }
          break;
        default:
      }
    }

    //----------------------------------------------------------  
    for (a in psf) {
      let baslikH = 0
      if (a > 0) {
        psf[a].x = psf[a - 1].x + psf[a - 1].w + psfAralik;
      }
      switch (a) {
        case "0": icerik = psf0; break;
        case "1": icerik = psf1; break;
        case "2": icerik = psf2; break;
        case "3": icerik = psf3; break;
        case "4": icerik = psf4; break;
        case "5": icerik = psf5; break;
        default:
      }
      let sx = psf[a].x
      let sy = psf[a].y
      let curW = 250
      let curX = sx
      curW = icerik[0].w

      let psfDurum = false
      for (var i in icerik) {
        psfDurum += icerik[i].durum
      }
      if (psfDurum > 0) {

        psf[a].w = curW

        for (var i=0 ;i<= icerik.length-1;i++) {
          if (i < 1) {
            icerik[i].y = sy
            icerik[i].x = curX
          } else if (i > 0) {
            if (icerik[i].name === "AG Tek Hat Şeması") {
              if (icerik[i].w > curW) {
                curW = icerik[0].w = icerik[i].w
              }
            }
            if (icerik[i].w > curW) { curW = icerik[i].w; psf[a].w = curW + icerikAralik }
            icerik[i].y = icerik[i-1].y + icerik[i-1].h + icerikAralik
            icerik[i].x = curX
            if ((icerik[i].y + icerik[i].h) > psf[a].h) {
              icerik[i].y = sy
              curX += curW + icerikAralik
              icerik[i].x = curX
              psf[a].w += curW
            }
          }


        }
      }

      if (psf[a].name === "baslık" || psf[a].name === "detaylar") {
        for (var i in icerik) {
          baslikH += icerik[i].h
        }
        for (var i in icerik) {
          icerik[i].y += psf[a].h - baslikH
        }

      }
      

    }

    if (a > 0) {
      psf[a].x = psf[a - 1].x + psf[a - 1].w + psfAralik;
    }
    return [iceriklist, psf]
  }
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------
  ProjeCerceveYerlestir()

  function ProjeCerceveYerlestir() {
    let ProjeFrame = [ProjeCizim, psf]
    ProjeFrameArray = ProjeCer(ProjeFrame, Scl)
    if (ProjeFrameArray[0][2].w > ProjeFrameArray[0][1].w) {
      ProjeFrameArray[0][3].x -= ProjeFrameArray[0][2].w - ProjeFrameArray[0][1].w
    }
    ProjeCizim = ProjeFrameArray[0]
    psf = ProjeFrameArray[1]
    for (var a in ProjeCizim) {
      if (ProjeCizim[a].durum === true) {
        switch (ProjeCizim[a].name) {
          case "Yükleme Cetveli": cizimG[a] = yuklemeCetvelC.cizim; break;
          case "OG Tek Hat Şeması": cizimG[a] = ogTekHatSemaC.cizim; break;
          case "AG Tek Hat Şeması": cizimG[a] = agTekHatSemaC.cizim; break;
          case "Kompanzasyon Şeması": cizimG[a] = kompSemaC.cizim; break;
          case "OG Gerilim Düşümü Hesabı": cizimG[a] = ogGerDusC.cizim; break;
          case "AG Gerilim Düşümü Hesabı": cizimG[a] = agGerDusC.cizim; break;
          case "OG Akım Hesabı": cizimG[a] = ogAkimHesC.cizim; break;
          case "AG Akım Hesabı": cizimG[a] = agAkimHesC.cizim; break;
          case "Kısa Devre Akım Hesabı": cizimG[a] = kisaDevreAkimC.cizim; break;
          case "Trafo Gücü Hesabı": cizimG[a] = trafoGucHesC.cizim; break;
          case "Keşif": cizimG[a] = kesifC.cizim; break;
          case "Kompanzasyon Hesabı": cizimG[a] = kompHesC.cizim; break;
          case "AG Akim Trafo Hesabı": cizimG[a] = agAkimTrfC.cizim; break;
          case "Aciklama Raporu": cizimG[a] = acikRapC.cizim; break;
          case "Dilekce": cizimG[a] = dilekceC.cizim; break;
          case "Enerji Musaadesi": cizimG[a] = enerjiMusC.cizim; break;
          case "Kat Planı": cizimG[a] = katPlanC.cizim; break;
          case "Vaziyet Planı": cizimG[a] = vaziyetPlanC.cizim; break;
          case "Direk Detayları": cizimG[a] = direkDetayC.cizim; break;
          case "Pano Detayları": cizimG[a] = panoDetayC.cizim; break;
          case "Sembol Listesi": cizimG[a] = sembolC.cizim; break;
          case "Baslik": cizimG[a] = baslikC.cizim; break;
          default:
        }
      }
    }
  }
  //------------------------------------------------------  
  CerceveW = 0
  for (var a in psf) {
    CerceveW += psf[a].w / Scl + psfAralik * 2
  }
  cerSay++
  if (cerSay === 17) {
    cizim = cizim.concat(dortgen(0, 0, CerceveW * Scl, CerceveBoy * Scl, "#00FFFF"))
  }
  //------------------------------------------------------------------
  const linesG = []
  const polylinesG = []
  const splinesG1 = []
  const splinesG = []
  const circlesG = []
  const textsG = []
  const arcsG = []
  const picturesG = []

  for (var p in ProjeCizim) {
    if (ProjeCizim[p].durum === true) {
      linesG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Line')
      polylinesG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Polyline')
      splinesG1[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Spline')
      splinesG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Spline')
      circlesG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Circle')
      textsG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Text')
      arcsG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Arc')
      picturesG[p] = cizimG[p].filter((nesne) => nesne.Ad === 'Picture')
    }
  }

  const lines = cizim.filter((nesne) => nesne.Ad == 'Line')
  const polylines = cizim.filter((nesne) => nesne.Ad == 'Polyline')
  const splines = cizim.filter((nesne) => nesne.Ad == 'Spline')
  const circles = cizim.filter((nesne) => nesne.Ad == 'Circle')
  const texts = cizim.filter((nesne) => nesne.Ad == 'Text')
  const arcs = cizim.filter((nesne) => nesne.Ad == 'Arc')
  const pictures = cizim.filter((nesne) => nesne.Ad == 'Picture')

  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const [w, setW] = useState(111)
  const [color, setColor] = useState("#00FF00");
  const [goster, setGoster] = useState("#00FF00");
  const [secim, setSecim] = useState(cizimOzellik.Secim);

  const icerikDurum = (name, dd) => {
    setDurum(!durum)
    switch (name) {
      case "AG Akım Hesabı": setAgAkimHesD(dd); break;
      case "OG Akım Hesabı": setOgAkimHesD(dd); break;
      case "AG Tek Hat Şeması": setAgTekHatSemaD(dd); break;
      case "OG Tek Hat Şeması": setOgTekHatSemaD(dd); break;
      case "Kompanzasyon Şeması": setKompSemaD(dd); break;
      case "OG Gerilim Düşümü Hesabı": setOgGerDusD(dd); break;
      case "AG Gerilim Düşümü Hesabı": setAgGerDusD(dd); break;
      case "AG Akim Trafo Hesabı": setAgAkimTrfD(dd); break;
      case "Kısa Devre Akım Hesabı": setKisaDevreAkimD(dd); break;
      case "Trafo Gücü Hesabı": setTrafoGucHesD(dd); break;
      case "Keşif": setKesifD(dd); break;
      case "Kompanzasyon Hesabı": setKompHesD(dd); break;
      case "Aciklama Raporu": setAcikRapD(dd); break;
      case "Dilekce": setDilekceD(dd); break;
      case "Enerji Musaadesi": setEnerjiMusD(dd); break;
      case "Kat Planı": setKatPlanD(dd); break;
      case "Vaziyet Planı": setVaziyetPlanD(dd); break;
      case "Direk Detayları": setDirekDetayD(dd); break;
      case "Pano Detayları": setPanoDetayD(dd); break;
      case "Sembol Listesi": setSembolD(dd); break;
      case "Baslik": setBaslikD(dd); break;
      case "Yükleme Cetveli": setYuklemeCetvelD(dd); break;
      default:

    }
    dispatch(durumAct({ name: name, dd }))
  }

  function handleWheel(e) {
    e.evt.preventDefault();
    const scaleBy = 0.75;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStageScale(newScale)
    setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale)
    setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale)

  };
  //useEffect(() => {  })
  useEffect(() => { dispatch(UpdateScl(scl)) }, [scl])
  useEffect(() => {
    (KompDetaylar.KompVarlik === "true" ? setKompSemaD(true) : setKompSemaD(false))

  }, [kompSemaD, KompDetaylar])

  useEffect(() => {

  }, [agTekHatSemaC, ogTekHatSemaC, yuklemeCetvelC, scl])

  
 
  useEffect(() => {
    setShow(editorGoster)

  }, [editorGoster, name, icerik, w])

  useEffect(() => {
    setSecim(cizimOzellik.Secim)
    ProjeCerceveYerlestir();

  }, [cizimOzellik])

  let X = 0;
  let Y = 0;

  useEffect(() => {
    switch (secim) {
      case "DDCerRenk": dispatch(UpdateDDCerRenk(color)); break;
      case "DDZemRenk": dispatch(UpdateDDZemRenk(color)); break;
      case "DDdirekRenk": dispatch(UpdateDDdirekRenk(color)); break;
      case "DDpanoRenk": dispatch(UpdateDDpanoRenk(color)); break;
      case "DDpanoTextRenk": dispatch(UpdateDDpanoTextRenk(color)); break;
      case "DDtrafoRenk": dispatch(UpdateDDtrafoRenk(color)); break;
      case "DDtrafoTextRenk": dispatch(UpdateDDtrafoTextRenk(color)); break;
      case "DDtrafoGirisRenk": dispatch(UpdateDDtrafoGirisRenk(color)); break;
      case "DDtrafoInisRenk": dispatch(UpdateDDtrafoInisRenk(color)); break;
      case "DDtrafoKorRenk": dispatch(UpdateDDtrafoKorRenk(color)); break;
      case "DDtrafoKorTextRenk": dispatch(UpdateDDtrafoKorTextRenk(color)); break;
      case "DDparafRenk": dispatch(UpdateDDparafRenk(color)); break;
      case "DDparafTextRenk": dispatch(UpdateDDparafTextRenk(color)); break;
      case "DDparafGirisRenk": dispatch(UpdateDDparafGirisRenk(color)); break;
      case "DDparafInisRenk": dispatch(UpdateDDparafInisRenk(color)); break;
      case "DDSekRenk": dispatch(UpdateDDSekRenk(color)); break;
      case "DDSekIletkenRenk": dispatch(UpdateDDSekIletkenRenk(color)); break;
      case "DDSekTextRenk": dispatch(UpdateDDSekTextRenk(color)); break;
      case "DDSekGirisRenk": dispatch(UpdateDDSekGirisRenk(color)); break;
      case "DDKorkulukRenk": dispatch(UpdateDDKorkulukRenk(color)); break;
      case "DDotlRenk": dispatch(UpdateDDotlRenk(color)); break;
      case "DDotlTextRenk": dispatch(UpdateDDotlTextRenk(color)); break;
      case "DDhatIletkenRenk": dispatch(UpdateDDhatIletkenRenk(color)); break;
      case "DDHatTextRenk": dispatch(UpdateDDHatTextRenk(color)); break;
      case "DDizolatorRenk": dispatch(UpdateDDizolatorRenk(color)); break;
      case "DDIsletmeRenk": dispatch(UpdateDDIsletmeRenk(color)); break;
      case "DDtopKazRenk": dispatch(UpdateDDtopKazRenk(color)); break;
      case "DDSekKolRenk": dispatch(UpdateDDSekKolRenk(color)); break;
      //--------------------------------------------------------

      case "PDcerceveRenk": dispatch(UpdatePDcerceveRenk(color)); break;
      case "PDtextRenk": dispatch(UpdatePDtextRenk(color)); break;
      case "PDpanoRenk": dispatch(UpdatePDpanoRenk(color)); break;
      case "PDsayacRenk": dispatch(UpdatePDsayacRenk(color)); break;
      case "PDkesiciRenk": dispatch(UpdatePDkesiciRenk(color)); break;
      case "PDkesiciTextRenk": dispatch(UpdatePDkesiciTextRenk(color)); break;
      case "PDkontaktorRenk": dispatch(UpdatePDkontaktorRenk(color)); break;
      case "PDRGKRoleRenk": dispatch(UpdatePDRGKRoleRenk(color)); break;
      case "PDaosRenk": dispatch(UpdatePDaosRenk(color)); break;
      case "PDaosTextRenk": dispatch(UpdatePDaosTextRenk(color)); break;
      case "PDakimTrafoRenk": dispatch(UpdatePDakimTrafoRenk(color)); break;
      case "PDakimTrfTextRenk": dispatch(UpdatePDakTrfTextRenk(color)); break;
      case "PDbaraRenk": dispatch(UpdatePDbaraRenk(color)); break;
      case "PDmesafeRenk": dispatch(UpdatePDmesafeRenk(color)); break;
      case "PDklavuzRenk": dispatch(UpdatePDklavuzRenk(color)); break;
      //------------------------------------------------------------------
      case "YCKenarRenk": dispatch(UpdateYCRenk(color)); break;
      case "YCTextRenk": dispatch(UpdateYCTextRenk(color)); break;
      //--------------------------------------
      case "K_KenarRenk": dispatch(UpdateK_Renk(color)); break;
      case "K_TextRenk": dispatch(UpdateK_TextRenk(color)); break;
      //---------------------------------------------------------------
      case "OGTHCerRenkOG": dispatch(UpdateOGTHCerRenkOG(color)); break;
      case "OGTHCerRenkAG": dispatch(UpdateOGTHCerRenkAG(color)); break;
      case "OGTHCerRenkKomp": dispatch(UpdateOGTHCerRenkKomp(color)); break;
      case "OGTHCizimRenk": dispatch(UpdateOGTHCizimRenk(color)); break;
      case "OGTHCizimTextRenk": dispatch(UpdateOGTHCizimTextRenk(color)); break;
      case "OGTHdirekRenk": dispatch(UpdateOGTHdirekRenk(color)); break;
      case "OGTHdirekTextRenk": dispatch(UpdateOGTHdirekTextRenk(color)); break;
      case "OGTHtrafoRenk": dispatch(UpdateOGTHtrafoRenk(color)); break;
      case "OGTHtrafoTextRenk": dispatch(UpdateOGTHtrafoTextRenk(color)); break;
      // case "OGTHtrafoGirisRenk":        dispatch(UpdateOGTHtrafoGirisRenk(color));     break;
      //  case "OGTHtrafoInisRenk":        dispatch(UpdateOGTHtrafoInisRenk(color));     break;
      case "OGTHparafRenk": dispatch(UpdateOGTHparafRenk(color)); break;
      case "OGTHparafTextRenk": dispatch(UpdateOGTHparafTextRenk(color)); break;
      //    case "OGTHparafGirisRenk":        dispatch(UpdateOGTHparafGirisRenk(color));     break;
      //   case "OGTHparafInisRenk":        dispatch(UpdateOGTHparafInisRenk(color));     break;
      case "OGTHSekRenk": dispatch(UpdateOGTHSekRenk(color)); break;
      //    case "OGTHSekIletkenRenk":        dispatch(UpdateOGTHSekIletkenRenk(color));     break;
      case "OGTHSekTextRenk": dispatch(UpdateOGTHSekTextRenk(color)); break;
      //   case "OGTHSekGirisRenk":        dispatch(UpdateOGTHSekGirisRenk(color));     break;
      case "OGTHSayacRenk": dispatch(UpdateOGTHSayacRenk(color)); break;
      case "OGTHSayacTRenk": dispatch(UpdateOGTHSayacTRenk(color)); break;
      case "OGTHIcIhtRenk": dispatch(UpdateOGTHIcIhtRenk(color)); break;
      case "OGTHIcIhtTRenk": dispatch(UpdateOGTHIcIhtTRenk(color)); break;
      case "OGTHSGrpRenk": dispatch(UpdateOGTHSGrpRenk(color)); break;
      case "OGTHSGrpTRenk": dispatch(UpdateOGTHSGrpTRenk(color)); break;
      case "OGTHSinyalRenk": dispatch(UpdateOGTHSinyalRenk(color)); break;
      case "OGTHSinyalTRenk": dispatch(UpdateOGTHSinyalTRenk(color)); break;
      case "OGTHKesiciRenk": dispatch(UpdateOGTHKesiciRenk(color)); break;
      case "OGTHKesiciTRenk": dispatch(UpdateOGTHKesiciTRenk(color)); break;
      case "OGTHAkimTrfRenk": dispatch(UpdateOGTHAkimTrfRenk(color)); break;
      case "OGTHAkimTrfTextRenk": dispatch(UpdateOGTHAkimTrfTextRenk(color)); break;
      case "OGTHtopRenk": dispatch(UpdateOGTHtopRenk(color)); break;
      case "OGTHtopTextRenk": dispatch(UpdateOGTHtopTextRenk(color)); break;

      case "AGTHTextRenk": dispatch(UpdateAGTHTextRenk(color)); break;
      case "AGTHNoTeRenk": dispatch(UpdateAGTHNoTeRenk(color)); break;
      case "AGTHKesiciRenk": dispatch(UpdateAGTHKesiciRenk(color)); break;
      case "AGTHKesiciTeRenk": dispatch(UpdateAGTHKesiciTeRenk(color)); break;
      case "AGTHIletRenk": dispatch(UpdateAGTHIletRenk(color)); break;
      case "AGTHIletTeRenk": dispatch(UpdateAGTHIletTeRenk(color)); break;
      case "AGTHYukTeRenk": dispatch(UpdateAGTHYukTeRenk(color)); break;
      case "AGTHBaraRenk": dispatch(UpdateAGTHBaraRenk(color)); break;
      case "AGTHBaraTeRenk": dispatch(UpdateAGTHBaraTeRenk(color)); break;
      case "AGTHGirisIletTeRenk": dispatch(UpdateAGTHGirisIletTeRenk(color)); break;
      case "AGTHKompIletTeRenk": dispatch(UpdateAGTHKompIletTeRenk(color)); break;
      case "AGTHKutuRenk": dispatch(UpdateAGTHKutuRenk(color)); break;
      //-------------------------
      case "KTextRenk": dispatch(UpdateKTextRenk(color)); break;
      case "KNoTeRenk": dispatch(UpdateKNoTeRenk(color)); break;
      case "KAOSRenk": dispatch(UpdateKAOSRenk(color)); break;
      case "KAOSTeRenk": dispatch(UpdateKAOSTeRenk(color)); break;
      case "KKontRenk": dispatch(UpdateKKontRenk(color)); break;
      case "KKontTeRenk": dispatch(UpdateKKontTeRenk(color)); break;
      case "KIletRenk": dispatch(UpdateKIletRenk(color)); break;
      case "KIletTeRenk": dispatch(UpdateKIletTeRenk(color)); break;
      case "KYukTeRenk": dispatch(UpdateKYukTeRenk(color)); break;
      case "KBaraRenk": dispatch(UpdateKBaraRenk(color)); break;
      case "KBaraTeRenk": dispatch(UpdateKBaraTeRenk(color)); break;
      case "KGirisIletTeRenk": dispatch(UpdateKGirisIletTeRenk(color)); break;
      case "KKIletTeRenk": dispatch(UpdateKKIletTeRenk(color)); break;
      case "KKondRenk": dispatch(UpdateKKondRenk(color)); break;
      case "KKondTeRenk": dispatch(UpdateKKondTeRenk(color)); break;
      case "KAkimTrfRenk": dispatch(UpdateKAkimTrfRenk(color)); break;
      case "KAkimTrfTeRenk": dispatch(UpdateKAkimTrfTeRenk(color)); break;
      case "KAnaHatTeRenk": dispatch(UpdateKAnaHatTeRenk(color)); break;
      case "KAnaHatRenk": dispatch(UpdateKAnaHatRenk(color)); break;
      case "KAkimTrfIletRenk": dispatch(UpdateKAkimTrfIletRenk(color)); break;

      case "KDAHTextRenk": dispatch(UpdateKDAHTextRenk(color)); break;
      case "KDAHCizimRenk": dispatch(UpdateKDAHCizimRenk(color)); break;
      case "KDAHCerceveRenk": dispatch(UpdateKDAHCerceveRenk(color)); break;

      case "DOCTextRenk": dispatch(UpdateDOCTextRenk(color)); break;
      case "DOCCerceveRenk": dispatch(UpdateDOCCerceveRenk(color)); break;



      default:
    }
    setOzellikRenk(color)
  }, [color, goster])
 
  const TEST = async (e) => {
    e.preventDefault();

    const project = {
    projectOwnerUser:genelBilgiler.M_AdSoyad,
    cepTel:genelBilgiler.cepTel,
    mail:genelBilgiler.mail,     
    kesif:genelBilgiler.Kesif,   
    arsaAdresi:genelBilgiler.A_Adres  
} 
 
   StatikCizimPDF(cizimG, { ProjeCerceveBoy: CerceveBoy * Scl, ProjeCerceveEn: CerceveW * Scl }, true, ProjeCizim)

 }
 
 
  const [show, setShow] = useState(editorGoster);  
  const PdfButton = React.memo(() => { 
    return (
      <React.Fragment>
        <Button className="bg-primary m-1" onClick={() => StatikCizimPDF(cizimG, { ProjeCerceveBoy: CerceveBoy * Scl, ProjeCerceveEn: CerceveW * Scl }, true, ProjeCizim)} >
          PDF
        </Button>
      </React.Fragment>
    );
  });
  const TEXTBUTTON = React.memo(() => { 
    return (
      <React.Fragment>
        <Button className="bg-primary m-1" onClick={TEST} >
          PDF
        </Button>
      </React.Fragment>
    );
  });

  const CadButton = React.memo(() => {
    return (
      <React.Fragment>
        <Button className="bg-primary m-1" onClick={() => DXFOlustur(cizimG, ProjeCizim, CerceveW * Scl, CerceveBoy * Scl)} >
          DXF
        </Button>
      </React.Fragment>
    ); 
  });
 
  return (
 
    <div>

      <Row>
        <Col xs={2} className="bg-muted-50" style={{ height: '100vh', overflowY: 'scroll' }}>
          <h3>Teknik Ayarlar</h3>
          {/*           <Form.Select aria-label="example" defaultValue={scl} onChange={e=>setScl(e.target.value)}  >
                <option value={1}>1</option>
                <option value={0.5}>0.5</option>
                <option value={0.342}>0.342</option>
                <option value={0.893}>0.893</option>
                <option value={1.253}>1.253</option>
                              
              </Form.Select> */}
          <ToolTrafoCikisYukler parametreler={panoDetayKonum} />
          <Tool_GenelBilgiler />
          <Tool_TrafoVeriDetay />
          <Tool_DirekVeriDetay />
          <Tool_OGHatDetay />
          <Tool_PanoVeriDetay />
          <Tool_SeksiyonerVeriDetay />
          <Tool_ParafudrVeriDetay />
          <Tool_KompanzasyonVeriDetay />

        </Col>
        <Col xs={8} className="bg-dark p-0" id="stateCol" style={{ height: '100vh'}}>
          Çizim Bölgesi
          <Stage
            id="stage"
            width={window.innerWidth/12*8}
            height={window.innerHeight}
            onWheel={handleWheel}
            scaleX={stageScale}
            scaleY={stageScale}
            x={stageX}
            y={stageY}
            draggable={true}
          >

            <Layer backgroundColor='red'  >
              {lines.map((line, i) => (
                <Line
                  key={i}
                  points={[line.x1, line.y1, line.x2, line.y2]}
                  stroke={line.Color24 ? "#" + line.Color24 : line.Color}
                  //stroke="blue"
                  strokeWidth={1}
                  strokeScaleEnabled={false}
                />
              ))
              }
              {polylines.map((polyline, i) => (
                <Line
                  key={i}
                  points={polyline.Noktalar}
                  stroke={polyline.Color24 ? "#" + polyline.Color24 : polyline.Color}
                  strokeScaleEnabled={false}
                  strokeWidth={1}
                  closed={Boolean(Number(polyline.PolyLineFlag.trim()))}
                />
              ))
              }

              {texts.map((text, i) => (
                <Text text={text.TextString}
                  fontSize={text.TextH}
                  key={i}
                  x={text.x}
                  y={text.y}
                  fill={text.Color24 ? "#" + text.Color24 : text.Color}
                  width={text.width}
                  align="center"
                  rotation={text.TextArc}
                  strokeScaleEnabled={false} />
              ))
              }
              {circles.map((cember, i) => (
                <Circle
                  key={i}
                  x={cember.x}
                  y={cember.y}
                  radius={cember.r}
                  stroke={cember.Color24 ? "#" + cember.Color24 : cember.Color}
                  strokeScaleEnabled={false} />
              ))
              }
              {arcs.map((yay, i) => (
                <Arc
                  key={i} x={yay.x} y={yay.y} radius={yay.r} strokeScaleEnabled={false}
                  innerRadius={yay.r * 1}
                  outerRadius={yay.r * 1}
                  angle={yay.EndAngle * 1 - yay.StartAngle * 1}
                  rotation={360 - yay.EndAngle * 1}
                  stroke={yay.Color24 ? "#" + yay.Color24 : yay.Color}
                  strokeWidth={1}
                />
              ))
              }

            </Layer>

            <Layer backgroundColor='red'  >
              {
                cizimG.map((grup, g) => (
                  <Group key={g} x={ProjeCizim[g].x} y={ProjeCizim[g].y} >
                    {linesG[g].map((line, i) => (
                      <Line
                        key={i}
                        points={[line.x1, line.y1, line.x2, line.y2]}
                        stroke={line.Color24 ? "#" + line.Color24 : line.Color}
                        //stroke="blue"
                        strokeWidth={1}
                        strokeScaleEnabled={false}
                      />
                    ))
                    }
                    {polylinesG[g].map((polyline, i) => (
                      <Line
                        key={i}
                        points={polyline.Noktalar}
                        stroke={polyline.Color24 ? "#" + polyline.Color24 : polyline.Color}
                        strokeScaleEnabled={false}
                        strokeWidth={1}
                        closed={Boolean(Number(polyline.PolyLineFlag.trim()))}
                      />
                    ))
                    }
                    {splinesG[g].map((spline, i) => (

                      <Line
                        key={i}
                        points={spline.Noktalar}
                        stroke={spline.Color24 ? "#" + spline.Color24 : spline.Color}
                        strokeScaleEnabled={false}
                        strokeWidth={1}
                        // tension={0.5}
                        bezier={true}
                      // closed={Boolean(Number(spline.PolyLineFlag.trim()))}
                      />
                    ))
                    }
                    {textsG[g].map((text, i) => (

                      <Text text={text.TextString}
                        fontSize={text.TextH}
                        key={i}
                        x={text.x}
                        y={text.y}
                        fill={text.Color24 ? "#" + text.Color24 : text.Color}
                        width={text.width}
                        align={text.align !== undefined ? text.align : "center"}
                        rotation={text.TextArc}
                        lineHeight={1.2}
                        strokeScaleEnabled={false} />

                    ))
                    }
                    {circlesG[g].map((cember, i) => (
                      <Circle
                        key={i}
                        x={cember.x}
                        y={cember.y}
                        radius={cember.r}
                        stroke={cember.Color24 ? "#" + cember.Color24 : cember.Color}
                        strokeScaleEnabled={false} />
                    ))
                    }
                    {arcsG[g].map((yay, i) => (
                      <Arc
                        key={i} x={yay.x} y={yay.y} radius={yay.r} strokeScaleEnabled={false}
                        innerRadius={yay.r * 1}
                        outerRadius={yay.r * 1}
                        angle={yay.EndAngle * 1 - yay.StartAngle * 1}
                        rotation={360 - yay.EndAngle * 1}
                        stroke={yay.Color24 ? "#" + yay.Color24 : yay.Color}
                        strokeWidth={1}
                      />
                    ))
                    }
                    {picturesG[g].map((pic, i) => (
                      <Arc
                        key={i} x={pic.x} y={pic.y} radius={pic.r} strokeScaleEnabled={false}
                        innerRadius={pic.r * 1}
                        outerRadius={pic.r * 1}
                        angle={pic.EndAngle * 1 - pic.StartAngle * 1}
                        rotation={360 - pic.EndAngle * 1}
                        stroke={pic.Color24 ? "#" + pic.Color24 : pic.Color}
                        strokeWidth={1}
                      />
                    ))
                    }
                    <Image image={image} />
                  </Group>


                ))

              }

            </Layer>
          </Stage>

        </Col>

        <Col xs={2} className="bg-body" style={{ height: '100vh' }}> 
          <h3>Görsel Ayarlar</h3>
        {/*   
          {UserName ? <PdfButton /> : null}
          {UserName ? <CadButton /> : null}
 */}
{/*  <PdfButton /> */}
 <CadButton />
 <TEXTBUTTON />
          <Table
            responsive
            size="sm"
            striped
            className='rounded-3 mt-1 mb-0 ms-1  '
            style={{ width: "98%", background: bgcolor }}
          >
            <thead >
              <tr>
                <th colSpan="2" className='pe-1' onClick={IcerikListShow} >İçerik Listesi</th>
              </tr>
            </thead>

            <tbody style={showIcerikList ? { display: "block", height: '20vh', overflowY: 'scroll' } : { display: "none" }}>
              {ProjeCizim.map((icerik, i) => (
                <tr key={i}>
                  <th>
                    <input type="checkbox" defaultChecked={true}
                      id={"input" + i}
                      onChange={(e) => icerikDurum(icerik.name, e.target.checked)}
                    />{icerik.name}</th>
                </tr>
              ))}
            </tbody>
          </Table>

          {/* ---------------------------------------------------------------- */}
          <br></br>
          <BicimEditor color={color} name={ozellikAd} renk={ozellikRenk} secimRenk={c => setColor(c)} TextGoster={c => setGoster(c)} /><br></br>
          <br></br>

          <div className="rounded mt-1" style={{ width: "98%", height: '60vh', overflowY: 'scroll', background: bgcolor }} >


            {yuklemeCetvelD ? <ToolYuklemeCetveli name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setYuklemeCetvelC(p) }} /> : null}
            {ogTekHatSemaD ? <ToolOGtekhatSema name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setOgTekHatSemaC(p) }} /> : null}
            {agTekHatSemaD ? <ToolAGtekhatSema name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setAgTekHatSemaC(p) }} /> : null}
            {kompSemaD ? <ToolKompSema name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setKompSemaC(p) }} /> : null}
            {ogGerDusD ? <ToolDOCGorselAyar name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setOgGerDusC(p) }} /> : null}
            {ogGerDusD ? <ToolOGGerilimDusumu cizim={(p) => { setOgGerDusC(p) }} /> : null}
            {agGerDusD ? <ToolAGGerilimDusumu cizim={(p) => { setAgGerDusC(p) }} /> : null}
            {agAkimTrfD ? <ToolAGAkimTrafo cizim={(p) => { setAgAkimTrfC(p) }} /> : null}
            {dilekceD ? <ToolDilekce cizim={(p) => { setDilekceC(p) }} /> : null}
            {trafoGucHesD ? <ToolTrafoGucuHesap cizim={(p) => { setTrafoGucHesC(p) }} /> : null}
            {kompHesD ? <ToolKompanzasyonHesap cizim={(p) => { setKompHesC(p) }} /> : null}
            {acikRapD ? <ToolAciklamaRaporu cizim={(p) => { setAcikRapC(p) }} /> : null}
            {ogAkimHesD ? <ToolOGAkimHesap cizim={(p) => { setOgAkimHesC(p) }} /> : null}
            {agAkimHesD ? <ToolAGAkimHesap cizim={(p) => { setAgAkimHesC(p) }} /> : null}

            {kisaDevreAkimD ? <ToolKisaDevreAkimHesap name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setKisaDevreAkimC(p) }} /> : null}
            {katPlanD ? <ToolKatPlan cizim={(p) => { setKatPlanC(p) }} /> : null}
            {kesifD ? <ToolKesif name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setKesifC(p) }} /> : null}
            {katPlanD ? <ToolKatPlan cizim={(p) => { setKatPlanC(p) }} /> : null}
            {vaziyetPlanD ? <ToolVaziyetPlan cizim={(p) => { setVaziyetPlanC(p) }} /> : null}
            {direkDetayD ? <ToolDirekDetay name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setDirekDetayC(p) }} /> : null}
            {panoDetayD ? <ToolPanoDetay name={(n) => { setOzellikAd(n) }} renk={(r) => { setOzellikRenk(r) }} cizim={(p) => { setPanoDetayC(p) }} /> : null}
            {enerjiMusD ? <EnerjiMusadesi cizim={(p) => { setEnerjiMusC(p) }} /> : null}
            {sembolD ? <ToolSembolList cizim={(p) => { setSembolC(p) }} /> : null}
            {baslikD ? <ToolBaslik cizim={(p) => { setBaslikC(p) }} /> : null}
          </div>
        </Col>
        
      </Row>
    </div>

  );

}



//onClick={StatikCizimPDF(cizim,{ProjeCerceveBoy:3000,ProjeCerceveEn:3000},true)}